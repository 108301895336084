import styled, { css } from 'styled-components'

const StyledToggle = styled.span`
  width: 3.25rem;
  height: 1.5rem;
  background-color: ${({ theme, yesNo, checked }) => yesNo && checked ? theme.colors.success : theme.colors.greyThree};
  border-radius: 16px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  ${({ theme, yesNo }) => yesNo && css`
    transition: background ${theme.animationSpeed} ease;
  `};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.45;
    `}
`

const yesNoLabel = css`
  position: absolute;
  height: 100%;
  top: 0;
  line-height: 1.5rem;
  font-size: 13px;
  font-weight: 700;
  transition: opacity ${({ theme }) => theme.animationSpeed} ease;
`

const YesLabel = styled.span`
  ${yesNoLabel}
  left: 0.25rem;
  color: ${({ theme }) => theme.colors.white};
  opacity: ${({ checked }) => checked ? 1 : 0};
`
const NoLabel = styled.span`
  ${yesNoLabel}
  right: 0.25rem;
  opacity: ${({ checked }) => checked ? 0 : 1};
`

const StyledToggler = styled.span`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  top: 0.125rem;
  left: ${({ checked }) => (checked ? 'calc(100% - 1.375rem)' : '0.125rem')};
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  transition: left ${({ theme }) => theme.animationSpeed} ease;
  ${({ yesNo, theme, checked }) => !yesNo && css`
    &:before, &:after {
      content: '';
      position: absolute;
      height: 1px;
      background-color: ${theme.colors.blackTwo};
      transition: width ${theme.animationSpeed} ease, transform ${theme.animationSpeed} ease, top ${theme.animationSpeed} ease, left ${theme.animationSpeed} ease;
    }

    &:before {
      top: ${checked ? '65%' : '50%'};
      left: ${checked ? '0.2rem' : '0.15rem'};
      width: ${checked ? '5px' : '15px'};
      transform: rotate(45deg);
    }

    &:after {
      top: 50%;
      left: ${checked ? '0.30rem' : '0.15rem'};
      width: ${checked ? '12px' : '15px'};
      transform: ${checked ? 'rotate(-50deg)' : 'rotate(-45deg)'};
    }
  `}
`

const Label = styled.label`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  user-select: none;

  &:focus {
    outline: none;

    ${StyledToggle}:after {
      position: absolute;
      content: '';
      top: -.1875rem;
      left: -.1875rem;
      border-radius: 16px;
      width: calc(100% + .375rem);
      height: calc(100% + .375rem);
      border: 1px solid ${({theme}) => theme.colors.attention};
    }
  }

  input {
    display: none;
  }
`

const LabelText = styled.span`
  margin-left: 1rem;
`

export { StyledToggle, StyledToggler, Label, LabelText, YesLabel, NoLabel }

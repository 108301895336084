import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { StyledInput } from '@/components/__Inputs/input.styles'
import { CrossButton } from '@/global/GlobalStyle'
import { Container } from './SearchBar.styles'

const SearchBar = ({setSearchText, placeholder}) => {
  const [searchValue, setSearchValue] = useState('')

  const setValue = (value) => {
    setSearchValue(value)
    setSearchText(value)
  }

  return (
    <Container>
      {searchValue.length > 0 && <CrossButton onClick={() => setValue('')} />}
      <StyledInput
        placeholder={placeholder || 'Search...'}
        value={searchValue}
        style={{paddingRight: '30px'}}
        onChange={({target}) => setValue(target.value)}
      />
    </Container>
  )
}

SearchBar.propTypes = {
  setSearchText: PropTypes.func.isRequired,
  searchText: PropTypes.string,
  placeholder: PropTypes.string
}

export default SearchBar

import {
  PRICE_REC_ITEM_INCLUDED_NOVASOL_COLUMN_CELLS_DESKTOP,
  PRICE_REC_ITEM_INCLUDED_AWAZE_COLUMN_CELLS_DESKTOP,
  PRICE_REC_ITEM_INCLUDED_NOVASOL_COLUMNS_MOBILE,
  PRICE_REC_ITEM_INCLUDED_AWAZE_COLUMNS_MOBILE
} from '../PriceRecommendationHelpers/constants'

export const getIncludedColumnsDesktop = (isUserBrandNovasol) => {
  return isUserBrandNovasol
    ? PRICE_REC_ITEM_INCLUDED_NOVASOL_COLUMN_CELLS_DESKTOP
    : PRICE_REC_ITEM_INCLUDED_AWAZE_COLUMN_CELLS_DESKTOP
}

export const getIncludedColumnsMobile = (isUserBrandNovasol) => {
  return isUserBrandNovasol
    ? PRICE_REC_ITEM_INCLUDED_NOVASOL_COLUMNS_MOBILE
    : PRICE_REC_ITEM_INCLUDED_AWAZE_COLUMNS_MOBILE
}

export const showPercentageDifferenceOnPriceRecommendations = (
  proposedPrice,
  previousPrice,
  isUserBrandNovasol
) => {
  return proposedPrice && previousPrice && isUserBrandNovasol
}

import { PricingV2ResponseRows } from '@/hooks/useQuery';
import { AdjustmentPercentage } from './columns.styles';
import { Text } from '@awaze/design-system.components.text';

interface CurrentPriceCellProps {
  value: PricingV2ResponseRows['currentPrice'];
  isExpanded?: boolean;
}

function CurrentPriceCell({ value, isExpanded }: CurrentPriceCellProps) {

  if (isExpanded) {
    return null;
  }

  return value ? (
    <Text sizes={200}>
      {value.value} <AdjustmentPercentage $pct={value.adjustmentPercentage}>{value.adjustmentPercentage}%</AdjustmentPercentage>
    </Text>
  ) : null;
}

export default CurrentPriceCell;
import styled from 'styled-components'

const ItemContainer = styled.div`
  position: relative;
  padding-bottom: 2rem;
`

const Label = styled.p`
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.6rem;
`

export { ItemContainer, Label }

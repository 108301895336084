import React from 'react'
import { NewBookingModal, SuccessfulBookingModal } from '@/components/__Modals'
import { useBookingChartBookingsContext } from '../context/Bookings/BookingChartBookingsProvider'
import PropTypes from 'prop-types'
import { useUser } from '@/context/UserProvider'

const MakeBookingModalContainer = ({
  propertyId,
  propertyName,
  startDate,
  unitCode,
  unitId,
  setParametersForNewBooking,
  successfulBookingData,
  setSuccessfulBookingData
}) => {
  const { setPerformBookingSearch } = useBookingChartBookingsContext()
  const showNewBookingModal = !!unitId
  const showSuccessBookingModal = successfulBookingData && successfulBookingData.successfulBooking
  const property = { propertyId: propertyId, propertyName: propertyName }
  const { hasPermission } = useUser()

  return (
    <>
      {showNewBookingModal && !showSuccessBookingModal && (
        <NewBookingModal
          unitId={unitId}
          unitCode={unitCode}
          property={property}
          bookings={[]}
          closures={[]}
          hasChartPermissions={hasPermission('Chart')}
          selectedDate={startDate}
          managedProperty={false}
          allowPassengers={true}
          onClose={() =>
            setParametersForNewBooking(null)
          }
          onSuccess={data => {
            setSuccessfulBookingData({
              successfulBooking: data,
              bookedUnit: {unitId, unitCode},
              bookedProperty: property
            })
            setPerformBookingSearch()
          }}
        />
      )}
      {
        showSuccessBookingModal && (
          <>
            <SuccessfulBookingModal
              data={successfulBookingData.successfulBooking}
              onClose={() => {
                setSuccessfulBookingData(null)
              }}
            />
          </>
        )
      }
    </>
  )
}

MakeBookingModalContainer.propTypes = {
  propertyId: PropTypes.string,
  propertyName: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  unitCode: PropTypes.string,
  unitId: PropTypes.string,
  setParametersForNewBooking: PropTypes.func,
  successfulBookingData: PropTypes.object,
  setSuccessfulBookingData: PropTypes.func
}

export default MakeBookingModalContainer

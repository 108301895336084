import React from 'react'
import { Svg } from './CurrencyIcon.styles'

const CurrencyIcon = (props) => (
  <div>
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M13.5 17.25C12.259 17.25 11.25 16.241 11.25 15V11.25H10.5C10.086 11.25 9.75 10.914 9.75 10.5C9.75 10.086 10.086 9.75 10.5 9.75H11.25C12.077 9.75 12.75 10.423 12.75 11.25V15C12.75 15.414 13.086 15.75 13.5 15.75H14.25C14.664 15.75 15 16.086 15 16.5C15 16.914 14.664 17.25 14.25 17.25H13.5Z" fill="#0C4BC0"/>
      <path d="M11.625 8.25C12.2463 8.25 12.75 7.74632 12.75 7.125C12.75 6.50368 12.2463 6 11.625 6C11.0037 6 10.5 6.50368 10.5 7.125C10.5 7.74632 11.0037 8.25 11.625 8.25Z" fill="#0C4BC0"/>
      <path d="M12 24C5.383 24 0 18.617 0 12C0 5.383 5.383 0 12 0C18.617 0 24 5.383 24 12C24 18.617 18.617 24 12 24ZM12 1.5C6.21 1.5 1.5 6.21 1.5 12C1.5 17.79 6.21 22.5 12 22.5C17.79 22.5 22.5 17.79 22.5 12C22.5 6.21 17.79 1.5 12 1.5Z" fill="#0C4BC0"/>
    </Svg>
  </div>
)

export default CurrencyIcon

import React from 'react'
import { Units } from './PropertyList.styles'
import { useBookingChartContext } from '../context/BookingChartProvider'
import { useBookingChartBookingsContext } from '../context/Bookings/BookingChartBookingsProvider'
import PetIcon from '@/assets/icons/paw.png'
import DefaultImage from '@/assets//images/default-image.jpg'
import useWindowSize from '@/hooks/useWindowSize'

const Pets = () => (
  <div className='petIcon'>
    <img src={PetIcon} alt="pet" />
  </div>
)


const PropertyList = () => {
  const { width } = useWindowSize()
  const isDesktop = width > 599
  const { filteredProperties, noResults } = useBookingChartContext()
  
  const { bookingsByUnit } = useBookingChartBookingsContext()

  const getUnitCountDisplayMessage = (unitcount) => {
    return unitcount === 1 ? 'unit' : 'units'
  }

  return (
    <Units>
      {bookingsByUnit !== null &&
      <>
      { !noResults && <div className='empty'></div>}

        {filteredProperties?.map(property => {
          return (

            <React.Fragment key={property.propertyId}>
              <div className='siteContainer'>
                <div className='infoSiteContainer'>
                  {
                    isDesktop &&
                  <div className='siteImage'>
                    <img src={property.thumbnail || DefaultImage} alt="img" />
                  </div>
                  }
                  <div className='siteName'>
                    <p className='site'>{property.propertyName}</p>
                    <p className='numberOfUnits'>({property.propertyCode}) {property.propertyUnits.length} {getUnitCountDisplayMessage(property.propertyUnits.length)}</p>
                  </div>
                </div>
              </div>

              {property.propertyUnits.map((propertyUnit, unitId) => {
                return (
                  <React.Fragment key={unitId}>
                    <div className='unitCell'>
                      <div className='propertyUnitContainer'>
                        <div className='propertyUnitName'>
                          {propertyUnit.unitCode}
                        </div>
                        { propertyUnit.maximumPets > 0 ? <Pets /> : null }
                      </div>
                    </div>
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          )
        }
        )}
      </>
      }

    </Units>
  )
}

export default PropertyList

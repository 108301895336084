import styled from 'styled-components'

const LabelContainer = styled.div`
  width: 6rem;
`

const RadioButtonArea = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${({theme}) => theme.colors.white};
  border: 1px solid ${({theme}) => theme.colors.secondary};

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => props.checked && `
    background: ${props.theme.colors.secondary};
    border: 1px solid ${props.theme.colors.secondary};

    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      box-shadow: 1px 3px 3px 1px ${({theme}) => theme.colors.radioBtnBoxShadow};
      background: ${props.theme.colors.white};
    }
  `}
`

const RadioButtonInput = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
`

export { RadioButtonArea, RadioButtonInput, RadioButtonLabel, LabelContainer }

import Cookies from 'js-cookie'

import dayjs from 'dayjs'
import { registerLocale, setDefaultLocale } from 'react-datepicker'

export function getLocale () {
  const userLanguage = Cookies.get('Drupal.visitor.awaze_user_language') || Cookies.get('lang') || 'en'
  return userLanguage
}

/** Ensure we don't return "debug" or other unknown locales */
function normalizeLocale (locale) {
  switch (locale) {
    case 'en-GB':
    case 'hr':
    case 'da':
    case 'es':
    case 'fr':
    case 'it':
    case 'nb':
    case 'sv':
    case 'de':
    case 'nl':
    case 'pl':
    case 'sl':
      return locale
    case 'debug':
    default:
      return 'en'
  }
}

async function importDayjsLocale (localeName) {
  switch (localeName) {
    case 'hr': return await import('dayjs/locale/hr.js')
    case 'da': return await import('dayjs/locale/da.js')
    case 'es': return await import('dayjs/locale/es.js')
    case 'fr': return await import('dayjs/locale/fr.js')
    case 'it': return await import('dayjs/locale/it.js')
    case 'nb': return await import('dayjs/locale/nb.js')
    case 'sv': return await import('dayjs/locale/sv.js')
    case 'de': return await import('dayjs/locale/de.js')
    case 'nl': return await import('dayjs/locale/nl.js')
    case 'pl': return await import('dayjs/locale/pl.js')
    case 'sl': return await import('dayjs/locale/sl.js')
    case 'en-GB':
    case 'debug':
    default: return await import('dayjs/locale/en.js')
  }
}

export async function setDayjsLocale (localeName) {
  await importDayjsLocale(localeName)
  dayjs.locale(localeName === 'en-GB' ? 'en' : localeName)
}

async function importDateFnsLocale (localeName) {
  switch (localeName) {
    case 'hr': return await import('date-fns/locale/hr')
    case 'da': return await import('date-fns/locale/da')
    case 'es': return await import('date-fns/locale/es')
    case 'fr': return await import('date-fns/locale/fr')
    case 'it': return await import('date-fns/locale/it')
    case 'nb': return await import('date-fns/locale/nb')
    case 'sv': return await import('date-fns/locale/sv')
    case 'de': return await import('date-fns/locale/de')
    case 'nl': return await import('date-fns/locale/nl')
    case 'pl': return await import('date-fns/locale/pl')
    case 'sl': return await import('date-fns/locale/sl')
    case 'en-GB':
    case 'debug':
    default:
      return await import('date-fns/locale/en-GB')
  }
}

async function setReactDatePickerLocale(localeName) {
  const locale = await importDateFnsLocale(localeName)
  registerLocale(localeName, locale.default)
  setDefaultLocale(localeName)
}

export const setLocale = async (locale) => {
  let currentLocale = locale || getLocale()

  try {
    currentLocale = normalizeLocale(currentLocale)

    await Promise.all([
      setDayjsLocale(currentLocale),
      setReactDatePickerLocale(currentLocale)
    ])
  } catch (error) {
    console.error('Failed to load locale:', currentLocale, error)
    dayjs.locale('en')
    setDefaultLocale('en-GB')
  }
}

import React, {useState} from 'react'
import PropTypes from 'prop-types'
import toast from '@/helpers/toast'
import convertImageToBase64 from '@/helpers/files/Images/convertImageToBase64'
import checkPortraitImage from '@/helpers/files/Images/checkPortraitImage'
import { Formik, Form as FormikForm } from 'formik'
import { graphQlFetch, UPDATE_PROPERTY_IMAGERY } from '@/hooks/useGraphQl'
import { Textarea, ImageUploader } from '@/components/__Inputs'
import NavigationWarningModal from '@/components/__Modals/NavigationWarningModal'
import {
  StyledModal, Image, ButtonContainer, RemoveButton,
  NewImageWrapper, NewImageButton, SubmitButton, Error,
  CancelButton
} from './PropertyImageEditModal.styles'
import defaultImage from '@/assets/images/default-image.jpg'

const maxFileSizeInMB = 3

const PropertyImageEditModal = ({
  onClose,
  setShowSuccessModal,
  setSubmittedChanges,
  item,
  propertyId,
  newImage
}) => {
  const id = item && item.id
  const url = item && item.url
  const [currentImage, setCurrentImage] = useState(url || '')
  const [submitError, setSubmitError] = useState(false)
  const [imageError, setImageError] = useState(false)
  const [blockNavigation, setBlockNavigation] = useState(false)
  const [loading, setLoading] = useState(false)

  const onCloseModal = (input = true) => {
    if (blockNavigation && input) {
      toast.info('Please submit your changes. Click cancel again to close')
      setBlockNavigation(false)
    } else {
      onClose(false)
    }
  }

  const onFormChange = () => {
    setBlockNavigation(true)
  }

  const getInitialValues = (values) => {
    if (values) return values

    return {
      url: '',
      caption: '',
      comments: ''
    }
  }

  const setNewImage = async (val, setFieldValue) => {
    const image = val[0]
    setSubmitError(false)
    setImageError(false)

    if (image) {
      try {
        await checkPortraitImage(image.blob)
        const base64Image = await convertImageToBase64(image.blob)
        setCurrentImage(base64Image)
        setFieldValue('url', base64Image)
        onFormChange()
      } catch (error) {
        toast.error(`${error}`)
        setImageError(`${error}`)
      }
    } else {
      toast.error('Failed to set image')
    }
  }

  const removeImage = (setFieldValue) => {
    setCurrentImage('')
    setFieldValue('url', 'Remove this Image')
    setFieldValue('caption', 'This image is being removed')
    onFormChange()
  }

  const checkValidData = (val) => {
    if (!item) {
      return val.url !== ''
    } else {
      return val['url'] !== item['url'] || val['caption'] !== item['caption'] || (val['comments'] !== '' && val['comments'] !== undefined)
    }
  }

  const formatData = (val) => {
    const itemUrl = item && item.url
    return {
      image: val.url !== itemUrl ? val.url : '',
      caption: val.caption || '',
      comments: val.comments || ''
    }
  }

  const handleSubmit = async (val) => {
    setLoading(true)
    const isValid = checkValidData(val)

    if (isValid) {
      const imageId = id
      const formattedData = formatData(val)

      try {
        const data = await graphQlFetch({
          query: UPDATE_PROPERTY_IMAGERY,
          variables: {
            propertyId,
            imageId,
            image: formattedData
          }
        })
        if (data.success) {
          setSubmittedChanges && setSubmittedChanges(val)
          setShowSuccessModal && setShowSuccessModal(true)
          setBlockNavigation(false)
          onCloseModal(false)
        } else {
          setSubmitError(true)
        }
      } catch (error) {
        console.error(error)
        setSubmitError(true)
      }
    } else {
      if (newImage) {
        toast.info('Please add an Image to submit.')
      } else {
        toast.info('Please make changes to submit.')
      }
    }
    setLoading(false)
  }

  return (
    <StyledModal open onClose={onCloseModal} strict>
      <Formik
        id="PropertyImageryForm"
        initialValues={getInitialValues(item)}
        onSubmit={handleSubmit}
      >
        {({setFieldValue}) => (
          <FormikForm
            id='PropertyImageryForm'
          >
            <Image src={currentImage === '' ? defaultImage : currentImage} />
            {imageError && <Error style={{margin: '0 0 0.5rem'}}>{imageError}</Error>}
            <ButtonContainer>
              <NewImageWrapper newImage={newImage}>
                <ImageUploader
                  style={{maxWidth: 'none'}}
                  id={'url'}
                  sizeLimit={maxFileSizeInMB}
                  onChange={(val) => setNewImage(val, setFieldValue)}
                >
                  <NewImageButton data-testid="newImageButton" primary >{newImage ? 'Upload new image' : 'Replace image' }</NewImageButton>
                </ImageUploader>
              </NewImageWrapper>
              {!newImage && <RemoveButton data-testid="requestRemovalBtn" error onClick={() => removeImage(setFieldValue)}>Request removal</RemoveButton>}
            </ButtonContainer>
            <Textarea style={{minHeight: '82px', marginBottom: '0'}} name={'caption'} placeholder={'Add caption'} disabled={false} additionalChange={() => onFormChange()} />
            <Textarea style={{minHeight: '82px', marginBottom: '0'}} name={'comments'} placeholder={'Add comments'} disabled={false} additionalChange={() => onFormChange()} />
            <ButtonContainer>
              <CancelButton primaryLine loading={loading} onClick={() => onCloseModal()}>Cancel</CancelButton>
              <SubmitButton data-testid="requestImageChange" type='submit' primary loading={loading}>{`Request ${newImage ? 'upload' : 'change'}`}</SubmitButton>
            </ButtonContainer>
            {submitError && <Error>There has been an issue submitting your response</Error>}
          </FormikForm>
        )}
      </Formik>
      <NavigationWarningModal blockNavigation={blockNavigation} />
    </StyledModal>
  )
}

PropertyImageEditModal.propTypes = {
  propertyId: PropTypes.string,
  showModal: PropTypes.bool,
  newImage: PropTypes.bool,
  onClose: PropTypes.func,
  setShowSuccessModal: PropTypes.func,
  setSubmittedChanges: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    caption: PropTypes.string,
    url: PropTypes.string.isRequired
  })
}

export default PropertyImageEditModal

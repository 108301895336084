import { useMemo } from 'react'
import PropTypes from 'prop-types';
import { Stepper } from '@awaze/design-system.components.stepper';
import { Container, GuestDescription, GuestInfo, StepperContainer } from './OccupancyPicker.styles';
import { useBookingChartContext } from '../../__BookingChartComponents/context/BookingChartProvider';

const OccupancyPicker = ({
  adults,
  setAdults,
  numChildren,
  setNumChildren,
  infants,
  setInfants,
  pets,
  setPets,
}) => { 
  const { filteredSite } = useBookingChartContext()

  const maxAdults = useMemo(()=> {
    if (!filteredSite) return 10
    return Math.max(...filteredSite.siteProperties.map(property => property.propertyCapacity.maxAdults))
  }, [filteredSite])
  const maxPets = useMemo(()=> {
    if (!filteredSite) return 0
    return Math.max(...filteredSite.siteProperties.flatMap(property => property.propertyUnits.map(unit => unit.maximumPets)))
  }, [filteredSite])


  const guests = [
    { name: 'Adults', value: adults, setValue: setAdults, min: 1, max: maxAdults },
    { name: 'Children', value: numChildren, setValue: setNumChildren, min: 0, max: maxAdults },
    { name: 'Infants', value: infants, setValue: setInfants, min: 0, max: maxAdults },
    { name: 'Pets', value: pets, setValue: setPets, min: 0, max: maxPets }
  ];
  
  return (
    <>
      {guests.map((item) => (
        <Container key={item.name}>
          <div>
            <GuestInfo>{item.name}</GuestInfo>
            {item.name !== 'Adults' && (
              <GuestDescription>
                {item.name === 'Children' ? 'Aged 3-17' : item.name === 'Infants' ? 'Aged up to 2' : ''}
              </GuestDescription>
            )}
          </div>
          <StepperContainer>
            <Stepper
              initialValue={item.value}
              minimum={item.min}
              maximum={item.max}
              step={1}
              colourScheme="brand-tertiary"
              translations={{
                ariaLabelName: 'stepper',
                decreaseAriaLabel: 'Decrement',
                increaseAriaLabel: 'Increment'
              }}
              disableStepperInput={false}
              rounded={false}
              callBack={(value) => item.setValue(value)} 
            />
          </StepperContainer>
        </Container>
      ))}
    </>
  );
};
OccupancyPicker.propTypes = {
  adults: PropTypes.number.isRequired,
  setAdults: PropTypes.func.isRequired,
  numChildren: PropTypes.number.isRequired,
  setNumChildren: PropTypes.func.isRequired,
  infants: PropTypes.number.isRequired,
  setInfants: PropTypes.func.isRequired,
  pets: PropTypes.number.isRequired,
  setPets: PropTypes.func.isRequired,
};

export default OccupancyPicker;

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from '@/components/__Inputs/Form'
import { RadioButton } from '@/components/__Inputs'
import { Spinner } from '@/components/__UI'
import useGraphQl, { GET_FEATURE_INFORMATION } from '@/hooks/useGraphQl'
import {
  StyledModal,
  Title,
  ButtonContainer,
  SubmitButton,
  Disclaimer,
  SubFeatures,
  StyledDropdown
} from './PropertyFeaturesDetailsModal.styles'

const PropertyFeaturesDetailsModal = ({
  setShowModal,
  propertyId,
  feature
}) => {
  const DropdownIds = ['6554']
  const selectOptions = [...Array(3).keys()].map((x) => {
    return { label: x + 1, value: x + 1 }
  })
  const [subFeatureValue, setSubFeatureValue] = useState()
  const [dropdownValue, setDropdownValue] = useState(feature.quantity)
  const { setFieldValue } = useForm()
  const [radioValue, setRadioValue] = useState()

  const [featureDetails, loadingFeatureDetails] = useGraphQl({
    query: GET_FEATURE_INFORMATION,
    variables: {
      propertyId,
      featureId: feature.featureId
    },
    onSuccess: (data) => {
      if (data.subFeatures && Array.isArray(data.subFeatures)) {
        const values = data.subFeatures.find(({ enabled }) => {
          return enabled === true
        })
        values && values.id && setRadioValue(values.id)
        setSubFeatureValue(values)
        setDropdownValue(
          selectOptions.find(({ value }) => value === data.quantity)
        )
      }
    }
  })

  const onClose = () => {
    setFieldValue(`${feature.featureId}.enabled`, false)
    setShowModal(false)
  }

  const onAccept = () => {
    if (subFeatureValue) {
      setFieldValue(`${feature.featureId}.additionalDetails`, subFeatureValue)
    }

    if (dropdownValue && dropdownValue.value) {
      const details = {
        id: feature.featureId,
        name: '',
        quantity: dropdownValue.value
      }
      setFieldValue(`${feature.featureId}.additionalDetails`, details)
    }

    setShowModal(false)
  }

  const sortSubFeaturesValues = (e, id, description) => {
    const output = { id, name: description }
    setRadioValue(e.target.value)
    setSubFeatureValue(output)
  }

  return (
    <StyledModal open onClose={onClose}>
      {loadingFeatureDetails ? (
        <Spinner />
      ) : (
        <>
          {feature.description && <Title>{feature.description}</Title>}
          {DropdownIds.includes(feature.featureId) && (
            <>
              <p>Please choose quantity:</p>
              <StyledDropdown
                value={dropdownValue}
                options={selectOptions}
                onChange={setDropdownValue}
              />
            </>
          )}
          {featureDetails.subFeatures && featureDetails.subFeatures.length > 0 && (
            <div>
              <p>Please choose type:</p>
              <SubFeatures>
                {featureDetails.subFeatures.map(({ id, description }, key) => (
                  <RadioButton
                    key={key}
                    id={id}
                    label={description}
                    name="subFeature"
                    value={id}
                    checkedValue={radioValue}
                    onChange={(value) =>
                      sortSubFeaturesValues(value, id, description)
                    }
                  />
                ))}
              </SubFeatures>
            </div>
          )}
          {featureDetails.disclaimer && (
            <Disclaimer
              dangerouslySetInnerHTML={{ __html: featureDetails.disclaimer }}
            />
          )}
          <ButtonContainer>
            <SubmitButton data-testid="cancelFeatureBtn" primaryLine onClick={() => onClose()}>
              Cancel
            </SubmitButton>
            <SubmitButton data-testid="addFeatureBtn" primary onClick={() => onAccept()}>
              Add feature
            </SubmitButton>
          </ButtonContainer>
        </>
      )}
    </StyledModal>
  )
}

PropertyFeaturesDetailsModal.propTypes = {
  feature: PropTypes.shape({
    description: PropTypes.string,
    featureId: PropTypes.string,
    quantity: PropTypes.number
  }),
  propertyId: PropTypes.string,
  setShowModal: PropTypes.func
}

export default PropertyFeaturesDetailsModal

import { ChevronSvg } from '@/components/__SVG';
import { ExpanderButton } from './columns.styles';

interface ExpanderProps {
    isExpanded: boolean;
    toggleExpanded: () => void;
}

export function ExpanderCell({ isExpanded, toggleExpanded }: ExpanderProps) {
  return (
    <ExpanderButton
      onClick={(event) => {
        event.preventDefault()
        event.stopPropagation()
        toggleExpanded()
      }}
      data-testid={
        isExpanded ? 'chevron-180-degrees' : 'chevron-0-degrees'
      }
    >
      <ChevronSvg direction={isExpanded ? 0 : 180} />
    </ExpanderButton>
  );
}

import React, {useState, useRef} from 'react'
import PropTypes from 'prop-types'
import useWindowSize from '@/hooks/useWindowSize'
import { Container, Popup, StyledSvg } from './Tooltip.styles'
import IconQuestionMark from '@/assets/icons/question-mark.svg'

const Tooltip = ({text, testPos, primary, children, popUpWidth, posOverride, onClick}) => {
  const inputRef = useRef()
  const { width } = useWindowSize()
  const [showTooltip, setShowTooltip] = useState(false)
  const boxWidth = popUpWidth || 230

  const calculateIconPosition = (el) => {
    if (el && el.current) {
      const bounding = testPos || el.current.getBoundingClientRect()
      const leftBoundary = width * 0.05
      const rightBoundary = width - (width * 0.05)
      const leftPosition = bounding.left
      const rightPosition = bounding.right
      const overLeftBoundary = (rightPosition - boxWidth) < leftBoundary
      const overRightBoundary = ((leftPosition + boxWidth) > rightBoundary)
      if (posOverride === 'center' || (overLeftBoundary && overRightBoundary)) {
        return {
          left: `-${leftPosition - leftBoundary}px`,
          right: `${rightPosition - rightBoundary}px`
        }
      }
      if (posOverride === 'right' || overRightBoundary) {
        return {
          left: 'auto',
          right: 0,
          arrowRight: '5px',
          arrowLeft: 'auto'
        }
      }
      if (posOverride === 'left' || overLeftBoundary) {
        return {
          left: 0,
          right: 'auto',
          arrowLeft: '5px',
          arrowRight: 'auto'
        }
      }

      return {
        left: `-${boxWidth / 2}px`,
        right: `-${boxWidth / 2}px`
      }
    }
  }

  return (
    <Container
      onClick={(e) => {
        setShowTooltip(x => !x)
        e.stopPropagation()
      }}
      onTouchStart={(e) => {
        setShowTooltip(true)
        e.stopPropagation()
      }}
      onTouchEnd={(e) => {
        setShowTooltip(false)
        e.stopPropagation()
      }}
      onMouseOver={() => setShowTooltip(true)}
      onMouseOut={() => setShowTooltip(false)}
    >
      <div ref={inputRef} >
        {children
          ? <>{children}</>
          : <StyledSvg src={IconQuestionMark} onClick={onClick} alt="?" />

        }
      </div>
      {showTooltip && <Popup primary={primary} iconPosition={calculateIconPosition(inputRef)} boxWidth={boxWidth}>{text}</Popup>}
    </Container>
  )
}

Tooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  primary: PropTypes.bool,
  popUpWidth: PropTypes.number,
  testPos: PropTypes.object,
  posOverride: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func
}

export default Tooltip

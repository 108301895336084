import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  WhatIsAwazeTxt
} from './CompanyInfo.styles'

const CompanyInformation = () => {
  const { t } = useTranslation()

  return (
    <WhatIsAwazeTxt>
      <p>{t('footerText1')}</p>
      <p>{t('footerText2')}</p>
    </WhatIsAwazeTxt>
  )
}

export default CompanyInformation

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useBookingListContext } from '@/context/BookingListProvider'
import { OvalLoadingSpinner } from '@/components/__UI'
import { Text } from '@awaze/design-system.components.text'
import { ErrorContent, StyledIconAndHeader, TextWithMargin, StyledButton } from './CancelledBookingModal.styles'
import { useMutationCancelBooking } from '@/hooks/useQuery'
import {
  ConfirmationMessage,
  ErrorMessage
} from '../bookingModals.styles'
import useWindowSize from '@/hooks/useWindowSize'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  ModalBody,
  ModalContent
} from '@awaze/design-system.components.modal'
import { Check } from '@awaze/design-system.components.icon'

const CancelledBookingModal = ({ data, onCancelled, onClose, onConfirm }) => {
  const [state, setState] = useState({
    hasError: false,
    isCancelled: false,
    isCancelling: true,
    hasNotificationSent: false,
    hasSuppressedNotification: false
  })

  const { reloadBookings } = useBookingListContext()
  const { t } = useTranslation()
  const { width } = useWindowSize()
  const isDesktop = width > 1024

  const { mutate: cancelBooking } = useMutationCancelBooking({
    onSuccess: (response) => {
      setState((prevState) => ({
        ...prevState,
        isCancelled: response.cancelled,
        hasError: false,
        isCancelling: false,
        hasNotificationSent: response.hasNotificationSent,
        hasSuppressedNotification: response.hasSuppressedNotification
      }))

      onCancelled && onCancelled(data)
      onConfirm(data)
      reloadBookings && reloadBookings()
    },
    onError: () => {
      setState((prevState) => ({ ...prevState, isCancelling: false, hasError: true }))
    }
  })

  useEffect(() => {
    if (!state.isCancelled && state.isCancelling) {
      cancelBooking({
        propertyId: data.propertyId,
        reservationId: data.bookingId
      })
    }
  }, [cancelBooking, data, state])

  const buildMessageForSuccessfulNotification = () => {
    if (!state.hasSuppressedNotification) {
      return <Text sizes={300} id="cancelNotificationMessage">{t('cancelNotificationMessage')}</Text>
    }
    return null
  }

  if (state.isCancelling && isDesktop) {
    return (
      <Modal isOpen close={onClose}>
        <ModalContent>
          <ModalBody>
            <OvalLoadingSpinner />
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }

  if (state.hasError) {
    return (
      <Modal isOpen close={onClose}>
        <ModalContent>
          <ModalBody>
            <ErrorContent>
              <ErrorMessage data-testId="error">
                <Text sizes={300}>{t('cancelBookingErrorMessage')}</Text>
              </ErrorMessage>
            </ErrorContent>
            <StyledButton
              colourScheme='brand-primary'
              text={t('tryAgain')}
              onClick={() => setState((prevState) => ({ ...prevState, isCancelling: true }))}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Modal isOpen close={onClose}>
      <ModalContent
        header={
          <StyledIconAndHeader>
            <Check backgroundColour="ColorStatusConstructive500" iconColour="ColorWhiteOpacity100" size="x-small" />
            <TextWithMargin>{t('cancelOwnerBookingMessage')}</TextWithMargin>
          </StyledIconAndHeader>
        }
      >
        <ModalBody>
          {(state.hasNotificationSent || state.hasSuppressedNotification) ? (
            <ConfirmationMessage data-type="success">
              {buildMessageForSuccessfulNotification()}
            </ConfirmationMessage>
          ) : (
            state.isCancelling && !isDesktop ? (
              <OvalLoadingSpinner />
            ) : (
              <ErrorMessage data-type="error">
                <Text sizes={300}>{t('cancelBookingSuccessWithErrorMessage1')}</Text>
                <Text sizes={300}>{t('cancelBookingSuccessWithErrorMessage2', { bookingRef: data.bookingId })}</Text>
              </ErrorMessage>
            )
          )}
          <StyledButton
            onClick={onClose}
            text={t('ok')}
            colourScheme='brand-primary'
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

CancelledBookingModal.propTypes = {
  data: PropTypes.object,
  onCancelled: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
}

export default CancelledBookingModal

import React from 'react'
import FAQsList from '@/components/FAQs/FAQsList'
import HealthAndSafetyFileList from '@/components/HealthAndSafetyFileList'
import { PageWrapper } from '@/components/__UI'
import { ContentContainer, Sidebar, InfoBox } from './HealthAndSafetyFaqPage.style'
import FAQs from '@/constants/faqs/faqs-health-and-safety'

const HealthAndSafetyFaqPage = () => {
  return (
    <PageWrapper
      title={`Health and Safety Information`}
    >
      <ContentContainer>
        <FAQsList
          style={{flex: 1}}
          showSearch
          faqData={FAQs || []}
          filter={'Health & Safety'}
        />
        <Sidebar>
          <HealthAndSafetyFileList />
          <InfoBox>
            <p>As a holiday homeowner, you have a legal obligation to ensure that your property complies with all relevant health and safety legislation, this is also a requirement of your agreement with us.  Even where specific legislation does not exist, you have an overriding duty of care to your guests, which means that a common-sense approach to removing potential safety hazards must be taken.  We recommend that you undertake regular risk assessments to identify and eradicate potential hazards.  Your obligations continue to apply where you use any third-party services (such as a cleaner or caretaker) to help maintain your holiday home on your behalf.</p>

            <p>As your agent, we are not responsible for ensuring that your property meets the requirements of the applicable  laws,  regulations  and  guidelines  however  we  have  provided  this  guidance  on  some  key health  and  safety  topics  for  you  to  consider.  For  more  comprehensive  information  we  suggest  you read the appropriate government guidance.</p>

            <p>Please note that we are not health and safety experts and that you are entirely responsible for the safety and suitability of your property for holiday letting. The information below is not an exhaustive list of your obligations but an indication of some key issues to be aware of.</p>

            <p>This is not an exhaustive guide, nor a definitive statement of the law. If further information on your legal requirements is required or if you have any queries we suggest you consult your legal advisor or another qualified expert.</p>
          </InfoBox>
        </Sidebar>
      </ContentContainer>
    </PageWrapper>
  )
}

export default HealthAndSafetyFaqPage

import styled from 'styled-components'

const ColorLegendContainer = styled.div`
  width: 95.5vw;
  height: 3.5rem;
  margin: 0 auto;
  position: sticky;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.white};

${({ theme }) => theme.mediaQueries.phoneOnly} {
  position: relative;
  width: 10vw;
}

/* iPAD */
  @media (min-width: 600px) and (max-width: 1199px) {
    margin-left: 0.5rem;
  }

  .infoContainer{
    width: fit-content;
    height: 3rem;
    max-height: 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-end;

    .legend{
      margin-top: 0.5rem;
      text-align: center;
      width: auto;
      height: auto;
      border-radius: ${({theme}) => theme.borderRadius};
    }
  }
`
const ColorLegendContainerMobile = styled.div`
    width: 92vw;
    height: auto;
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.white};

  .infoContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

export {ColorLegendContainer, ColorLegendContainerMobile}

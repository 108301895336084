import styled from 'styled-components'
import { Select } from '@awaze/design-system.components.select'

export const Label = styled.label`
  display: block;
  margin-bottom: .5rem;
`

export interface StyledSelectProps {
  hideArrow?: boolean 
  autoWidth?: boolean
}

export const StyledSelect = styled(Select)<StyledSelectProps>`
  ${({ hideArrow }) => hideArrow && `
    background-image: none;
    pointer-events: none;
  `}

  ${({ autoWidth }) => autoWidth && `
    width: auto;
    padding-right: 3rem; /* to give padding to arrow indicator */
  `}
`

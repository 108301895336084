import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './assets/locales/en-GB.json'
import da from './assets/locales/da.json'
import es from './assets/locales/es.json'
import fr from './assets/locales/fr.json'
import it from './assets/locales/it.json'
import hr from './assets/locales/hr.json'
import nb from './assets/locales/nb.json'
import sv from './assets/locales/sv.json'
import de from './assets/locales/de-DE.json'
import nl from './assets/locales/nl-NL.json'
import pl from './assets/locales/pl.json'
import sl from './assets/locales/sl.json'

import { getLocale } from './helpers/locale'

if (initReactI18next) {
  i18n.use(initReactI18next)
}

i18n.init({
  resources: {
    en: { translation: en },
    da: { translation: da },
    es: { translation: es },
    fr: { translation: fr },
    it: { translation: it },
    hr: { translation: hr },
    nb: { translation: nb },
    sv: { translation: sv },
    de: { translation: de },
    nl: { translation: nl },
    pl: {translation: pl},
    sl: { translation: sl }

  },
  lng: getLocale(),
  fallbackLng: {
    debug: ['debug'],
    default: ['en']
  },
  interpolation: {
    escapeValue: false
  },
  missingKeyHandler: (lng, ns, key, fallbackValue) => {
    console.warn(`Translation key missing in language ${lng} and namespace ${ns}: ${key}`)
    return fallbackValue
  },
  keySeparator: false,
  nsSeparator: false
})

export default i18n

import React, { useState } from 'react'
import {RecommendationPropertyChoicesStepTwo} from './PriceRecommendationsFooter.styles'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import AcceptanceModal from '@/components/__PriceRecommendations/AcceptanceModal/AcceptanceModal'
import { Button } from '@awaze/design-system.components.button'

const PriceRecommendationsFooter = ({filteredProperties}) => {
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <RecommendationPropertyChoicesStepTwo>
      {filteredProperties?.length > 0 && <Button colourScheme="brand-primary" onClick={() => setModalOpen(true)} text={t('priceRecommendationsAcceptOrReject')} />}
      {modalOpen && <AcceptanceModal modalOpen={modalOpen} setModalOpen={setModalOpen} filteredProperties={filteredProperties} />}
    </RecommendationPropertyChoicesStepTwo>
  )
}
PriceRecommendationsFooter.propTypes = {
  filteredProperties: PropTypes.array
}

export default PriceRecommendationsFooter

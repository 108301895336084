import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import { SelectDropdown, FormikField, Checkbox } from '@/components/__Inputs'
import { useFormikContext } from 'formik'
import theme from '@/global/Theme'
import Collapse from '@kunukn/react-collapse'
import { Section, SectionData } from '@/components/__Modals/NewBookingModal/NewBookingModal.styles'
import { SectionTitle, SectionContentLabel, SectionContentData, SectionContentDataCompulsory, ExtrasContainer, CollapseContainer, FormikFieldContainer } from './Extras.styles'

const getExtrasOptions = (numberOfOptions) => {
  if (!numberOfOptions) return null
  const optionArray = []
  for (let i = 0; i <= numberOfOptions; i++) {
    optionArray.push(i)
  }

  return optionArray.map(t => ({
    value: t,
    label: t,
    disabled: false
  }))
}

const Extras = ({extrasObj, setExtrasObj, chosenDays, hasHolidayPrice, duration}) => {
  const {values, resetForm} = useFormikContext()
  const passengerToggleExtrasKey = 'passengerToggleForExtras'
  const passengerToggleForExtras = values[passengerToggleExtrasKey]

  const compulsoryExtras = extrasObj.map(extra => {
    if (extra.selectionMode === 'Compulsory' && hasHolidayPrice) {
      return {
        ...extra,
        itemQty: 1,
        amount: extra.amount || 0
      }
    }
    return extra
  })

  const sanitiseString = (flag, str) => {
    if (flag) return str
    return ''
  }

  useEffect(() => {
    const obj = JSON.parse(JSON.stringify(extrasObj))
    if (passengerToggleForExtras) {
      obj.forEach(e => {
        if (e.selectionMode !== 'Compulsory') {
          e.itemQty = values[e.name]
          return e
        }
        return e
      })
      setExtrasObj(obj)
    } else {
      obj.forEach(e => {
        if (e.selectionMode !== 'Compulsory') {
          e.itemQty = null
          return e
        }
        return e
      })
      setExtrasObj(obj)
      const resetValues = {
        values: {
          passengerToggleForExtras: passengerToggleForExtras,
          passengerToggle: values.passengerToggle,
          durationSelect: JSON.parse(JSON.stringify(values.durationSelect)),
          bookingTypeSelect: values.bookingTypeSelect,
          titleSelect: sanitiseString(values.passengerToggle, values.titleSelect),
          forename: sanitiseString(values.passengerToggle, values.forename),
          surname: sanitiseString(values.passengerToggle, values.surname)
        }
      }
      obj.forEach(e => {
        if (e.selectionMode !== 'Compulsory') {
          resetValues.values[e.name] = 0
        }
      })
      resetForm(resetValues)
    }
    // eslint-disable-next-line
  }, [passengerToggleForExtras])

  useEffect(() => {
    const resetValues = {
      values: {
        passengerToggleForExtras: passengerToggleForExtras,
        passengerToggle: values.passengerToggle,
        durationSelect: JSON.parse(JSON.stringify(values.durationSelect)),
        bookingTypeSelect: 'No cleaning',
        titleSelect: sanitiseString(values.passengerToggle, values.titleSelect),
        forename: sanitiseString(values.passengerToggle, values.forename),
        surname: sanitiseString(values.passengerToggle, values.surname)
      }
    }
    extrasObj.forEach(e => {
      if (e.selectionMode !== 'Compulsory') {
        resetValues.values[e.name] = 0
      }
    })
    resetForm(resetValues)
  // eslint-disable-next-line
  }, [duration])

  const selectExtrasNumber = (id, val) => {
    setExtrasObj(extrasObj.map(extra => {
      if (extra.id === id) {
        extra.itemQty = val
        return extra
      }
      return extra
    }))
  }

  return (
    <ExtrasContainer>
      <Section>
        {
          compulsoryExtras
            .filter(e => e.selectionMode === 'Compulsory' && hasHolidayPrice)
            .map(e => {
              const { id, name, amount } = e
              return (
                <Section key={id}>
                  <SectionTitle>{name}</SectionTitle>
                  <SectionContentDataCompulsory>{'£' + amount}</SectionContentDataCompulsory>
                </Section>
              )
            })
        }
      </Section>
      {
        chosenDays && hasHolidayPrice && (extrasObj && extrasObj.length > 0) &&
      <>
        <Section style={ { marginTop: '-1rem' } }>
          <SectionTitle data-testid='ExtrasCheckBox'>Add extras (Optional)</SectionTitle>
          <SectionData>
            <Checkbox
              data-testid='Name'
              name={ passengerToggleExtrasKey }
              color={ theme.colors.secondary }
              tickColor={ theme.colors.white }
              borderColor={ theme.colors.greyThree } />
          </SectionData>
        </Section>

        <Collapse isOpen={ passengerToggleForExtras } data-is-open={ passengerToggleForExtras }>
          <CollapseContainer>
            { extrasObj.map((e) =>
              <Section key={ e.id }>
                { e.selectionMode !== 'Compulsory' &&
                  <>
                    <SectionContentLabel>{ e.name + ' (£' + e.amount + ' each)' }</SectionContentLabel>
                    <SectionContentData>
                      <FormikFieldContainer data-testid='extras-select-container'>
                        <FormikField
                          data-testid='extras-select'
                          disabled={ e.maximumQuantity === 0 }
                          component={ SelectDropdown }
                          inputType="select"
                          name={ e.name }
                          options={ getExtrasOptions(e.maximumQuantity) }
                          onChange={ val => selectExtrasNumber(e.id, val.value) }
                          placeholder="0"
                          isOptionDisabled={ option => option.disabled } />
                      </FormikFieldContainer>
                    </SectionContentData>
                  </>
                }
              </Section>
            ) }
          </CollapseContainer>
        </Collapse></>
      }
    </ExtrasContainer>
  )
}

export { Extras }

Extras.propTypes = {
  setExtrasObj: PropTypes.func,
  extrasObj: PropTypes.array,
  chosenDays: PropTypes.bool,
  hasHolidayPrice: PropTypes.bool,
  duration: PropTypes.number
}

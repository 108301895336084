import { PageWrapper } from '@/components/__UI'
import React from 'react'
import { useTranslation } from 'react-i18next'

export function MaintenancePage () {
  const { t } = useTranslation()

  return (
    <PageWrapper>
      <p data-testid="maintenancePage">
        {t('underMaintenance')}
      </p>
    </PageWrapper>
  )
}

import React from 'react'
import { PageWrapper } from '@/components/__UI'
import BookingAllowanceList from '@/components/__BookingAllowances/BookingAllowanceList'

const BookingAllowancePage = () => {
  const tooltipText = 'Any changes to allowances may take a few days to reflect on this page.'
  return (
    <PageWrapper
      title='Owner Booking Allowances & Charges'
      tooltipText={tooltipText}
    >
      <BookingAllowanceList />
    </PageWrapper>
  )
}

export default BookingAllowancePage

import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

export const NewChartBookingContext = createContext({
  startDate: null,
  propertyId: null,
  unitId: null
})

export const useNewBookingAttributesContext = () => useContext(NewChartBookingContext)

export const NewBookingAttributesProvider = ({ children }) => {
  const [startDate, setStartDate] = useState()
  const [propertyId, setPropertyId] = useState()
  const [propertyName, setPropertyName] = useState()
  const [unitId, setUnitId] = useState()
  const [unitCode, setUnitCode] = useState()
  const [successfulBooking, setSuccessfulBooking] = useState()
  const [bookedProperty, setBookedProperty] = useState()
  const [bookedUnit, setBookedUnit] = useState()

  const updateAttributeValues = ({ newStartDate, newPropertyId, newPropertyName, newUnitId, newUnitCode }) => {
    setStartDate(newStartDate)
    setPropertyId(newPropertyId)
    setUnitId(newUnitId)
    setPropertyName(newPropertyName)
    setUnitCode(newUnitCode)
  }

  return (
    <NewChartBookingContext.Provider value={{
      setNewBookingAttributes: updateAttributeValues,
      propertyId: propertyId,
      propertyName: propertyName,
      startDate: startDate,
      unitId: unitId,
      unitCode: unitCode,
      successfulBooking: successfulBooking,
      setSuccessfulBooking: setSuccessfulBooking,
      bookedProperty: bookedProperty,
      setBookedProperty: setBookedProperty,
      bookedUnit: bookedUnit,
      setBookedUnit: setBookedUnit
    }}>
      {children}
    </NewChartBookingContext.Provider>
  )
}

NewBookingAttributesProvider.propTypes = {
  children: PropTypes.object
}

import React, {useState} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useUser } from '@/context/UserProvider'
import { Spinner, Error } from '@/components/__UI'
import { graphQlFetch, UPDATE_USER_POSTAL_STATEMENTS } from '@/hooks/useGraphQl'
import Button from '@/components/__Inputs/Button'
import { SuccessModal } from '@/components/__Modals'

const Container = styled.div`
  padding: 2rem;

  p {
    margin-bottom: 1rem;
  }
`

const OnlineStatementsPostal = ({currentAccount}) => {
  const { permissions, loading } = useUser()
  const [loadingActivate, setLoadingActivate] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const checkOwnerPermissions = () => {
    const caretakerPermission = permissions.find(x => x.name === 'caretakers')
    const caretakerAuthorised = caretakerPermission && caretakerPermission['authorised']
    const financialPermission = permissions.find(x => x.name === 'financial')
    const financialAuthorised = financialPermission && financialPermission['authorised']

    return caretakerAuthorised === true && financialAuthorised === true
  }

  const isOwner = checkOwnerPermissions()

  const activateOnlineStatements = async () => {
    setLoadingActivate(true)
    setHasError(false)

    await graphQlFetch({
      query: UPDATE_USER_POSTAL_STATEMENTS,
      variables: {
        accountId: currentAccount
      }
    })
      .then((data) => {
        if (data && data.success) {
          setShowSuccessModal(true)
        } else {
          setHasError(true)
        }
      })
      .catch((err) => {
        console.error(err)
        setHasError(true)
      })
      .finally(() => {
        setLoadingActivate(false)
      })
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <Container>
      { isOwner
        ? <>
          <p>You are currently receiving postal statements. Please click the button to change to online statements.</p>
          <Button data-testid="onlineStatementsActivateButton" primary loading={loadingActivate} onClick={() => activateOnlineStatements()}>Activate online statements</Button>
        </>
        : <p>This account currently receives postal statements. Please notify the owner to activate online statements.</p>
      }
      {hasError && <Error>There has been an issue submitting your request. Please try again later.</Error>}
      { showSuccessModal &&
        <SuccessModal
          text='You have successfully submitted your request to receive online statements.'
          onClose={setShowSuccessModal}
        />
      }
    </Container>
  )
}

OnlineStatementsPostal.propTypes = {
  currentAccount: PropTypes.string
}

export default OnlineStatementsPostal

import { v4 as uuidv4 } from 'uuid'

export function splitCamelCase (value) {
  return value.replace(/([^ ])([A-Z])/g, '$1 $2')
    .replace(/^./, str => str.toUpperCase())
}

export function generateRandomKey () {
  return uuidv4()
}

export function toLowerCaseExceptAbbreviations (value) {
  if (typeof value === 'string') {
    return value.split(' ').map(word => {
      if (word === word.toUpperCase()) {
        return word
      }
      return word.toLowerCase()
    }).join(' ')
  }
}

export function capitaliseExceptAbbreviations (value) {
  if (typeof value === 'string') {
    const text = toLowerCaseExceptAbbreviations(value)
    return text.charAt(0).toUpperCase() + text.slice(1)
  }
  return value
}

export function capitalise (value) {
  if (typeof value === 'string') {
    const text = value.toLowerCase()
    return text.charAt(0).toUpperCase() + text.slice(1)
  }
  return value
}

export function pascalToCamelCase (value) {
  if (!value) return ''
  return value.slice(0, 1).toLowerCase() + value.slice(1)
}

export function stripStartAndEndQuotationsFromString (value) {
  const firstChar = value.charAt(0)
  const lastChar = value.charAt(value.length - 1)

  if (firstChar === `"` && lastChar === `"`) {
    return value.slice(1, -1)
  }

  return value
}

export function joinStringArray (array, divider = ', ', withAnd = true) {
  if (Array.isArray(array)) {
    let newArr = array
    if (newArr.length < 2) {
      newArr = newArr.join()
    } else {
      if (withAnd) {
        newArr[newArr.length - 1] = `and ${newArr[newArr.length - 1]}`
      }
      newArr = newArr.join(divider)
    }
    return newArr
  }

  return array
}

export function capitaliseAllWords (value) {
  if (typeof value === 'string') {
    const text = value.toLowerCase()
    return text.replace(/(?:^|\s)(\w)/g, (char) => char.toUpperCase())
  }
  return value
}

const OTC_RGX = /^[0-9]*$/
const OTC_ERROR = 'Please enter a valid code'
const OTC_REQUIRED = 'Please enter a code'

const PASSWORD_RGX = /^.+$/
const PASSWORD_VALIDATOR_RGX = /[~!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/gm
const PASSWORD_ERROR = 'Password does not match minimum requirements'
const PASSWORD_REQUIRED = 'Please enter your password'

const CONFIRM_PASSWORD_MATCH = 'Passwords must match'
const CONFIRM_PASSWORD_REQUIRED = 'Please confirm your new password'

const EMAIL_ERROR = 'Your email address is not valid'
const EMAIL_REQUIRED = 'Please enter an email address'
const EMAIL_LENGTH = 256
const EMAIL_LENGTH_ERROR = 'Your email address is too long'

const APPLICATION_GUID = 'f3f74a50-83fb-47bb-bf3b-72064304ca6a'

const POSTCODE_ERROR = 'Please enter a valid post code'
const POSTCODE_REQUIRED = 'Please enter a post code'

const PHONE_NUMBER_ERROR = 'Please enter a valid phone number'
const PHONE_NUMBER_REQUIRED = 'Please enter a phone number'

export {
  OTC_RGX,
  OTC_ERROR,
  OTC_REQUIRED,
  PASSWORD_RGX,
  PASSWORD_VALIDATOR_RGX,
  PASSWORD_ERROR,
  PASSWORD_REQUIRED,
  CONFIRM_PASSWORD_MATCH,
  CONFIRM_PASSWORD_REQUIRED,
  EMAIL_ERROR,
  EMAIL_REQUIRED,
  EMAIL_LENGTH,
  EMAIL_LENGTH_ERROR,
  APPLICATION_GUID,
  POSTCODE_ERROR,
  POSTCODE_REQUIRED,
  PHONE_NUMBER_ERROR,
  PHONE_NUMBER_REQUIRED
}

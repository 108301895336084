import Link from '@/components/Link'
import styled from 'styled-components'
import Button from '@/components/__Inputs/Button'
import FormBuilder from '@/components/FormBuilder'

const ResponseArea = styled.div`

    @keyframes pop {
        0% {
            transform: scale(1.02)
        }
        30% {
            transform: scale(1.05)
        }
        100% {
            transform: scale(1)
        }
    }

    margin-top: 1rem;
    box-shadow: ${({ theme }) => theme.boxShadowSoft};
    border-radius: ${({ theme }) => theme.borderRadius};
    animation: pop ${({ theme }) => theme.animationSpeed};
    background-color: ${({ theme }) => theme.colors.white};

    p {
        padding: 1rem;
        font-size: 1rem;
        text-align: left;
        line-height: 2.5rem;
    }

    ${Link}, a {
        font-weight: bold;
        color: ${({ theme }) => theme.colors.primary};
    }

    ${({ theme }) => `${theme.mediaQueries.phoneOnly} {
        p {
            font-size: 0.8rem;
            line-height: 1.5rem;
        }
    }`}
`

const HelpButton = styled(Button)`
    width: 12rem;
    margin: 2rem 1rem;

    &:focus {
        outline: none;
    }

    ${({ theme }) => `${theme.mediaQueries.phoneOnly} {
        margin: 1rem;
        width: 10rem;
        font-size: 0.8rem;
    }`}
`

const StyledFormBuilder = styled(FormBuilder)`
    margin: 0;
    background-color: ${({ theme }) => theme.greySix};
    border-radius: ${({ theme }) => theme.borderRadius};

    button[type="submit"] {
        width: 10rem;
        margin-bottom: 0.5rem;
    }
`
export { ResponseArea, HelpButton, StyledFormBuilder }

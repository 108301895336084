import { Checkbox, Form, Textarea } from '@/components/__Inputs'
import React, { useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { StyledReviewResponseForm, StyledButtonIcon, StyledButtonPublish, NonTestPropertyWarning } from './ReviewResponseForm.styles'
import { ChevronLeft } from '@awaze/design-system.components.icon'
import { useMutationRespondToReview } from '@/hooks/useQuery/mutations/useMutationRespondToReview'
import { Heading, Text } from '@awaze/design-system.components.text'
import { useNavigate } from 'react-router-dom'
import { ReviewResponseErrorMessage } from './ReviewResponseErrorMessage'
import { LoadingSpinner } from '@/components/__UI'

export const ReviewResponseForm = ({ propertyId, reviewId }) => {
  const [hasProfanity, setHasProfanity] = useState(false)
  const [hasLegalWords, setHasLegalWords] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [moderationRequired, setModerationRequired] = useState(false)
  const [moderationWords, setModerationWords] = useState([])
  const [isNonTestPropertyInTestEnv, SetIsNonTestPropertyInTestEnv] = useState(false)

  const navigate = useNavigate()
  const textAreaMaxLength = 999

  const SCHEMA = Yup.object({
    responseContent: Yup.string()
      .max(textAreaMaxLength, 'Maximum character limit is 999.')
      .required('Please enter a response'),
    terms: Yup.bool().oneOf([true], 'This field is mandatory'),
    moderationCheck: Yup.bool().oneOf([moderationRequired], 'This field is mandatory')
  })

  const { mutate: responseToReview } = useMutationRespondToReview({
    onSuccess () {
      navigate('/property/reviews')
    },

    async onError (error) {
      const response = await error.response.json()
      const hasProfanityWords = response?.profanityWords?.length > 0
      const hasLegalWords = response?.legalWords?.length > 0

      if (error.response.status === 406) {
        setHasProfanity(hasProfanityWords)
        setHasLegalWords(hasLegalWords)
        setModerationRequired(hasLegalWords)
        setModerationWords(response?.legalWords || [])
      } else if (error.response.status === 405) {
        SetIsNonTestPropertyInTestEnv(true)
      }
    },
    onSettled () {
      setIsLoading(false)
    }
  })

  const handleSubmit = values => {
    setIsLoading(true)
    responseToReview({
      propertyId: propertyId,
      reviewId: reviewId,
      response: {
        response: values.responseContent,
        moderationChecksConfirmed: values.moderationCheck
      }
    })
  }

  const goBack = () => {
    navigate('/property/reviews')
  }

  const handleInputChange = () => {
    setHasLegalWords(false)
    setHasProfanity(false)
  }

  return (
    <StyledReviewResponseForm>
      <StyledButtonIcon
        onClick={goBack}
        icon={<ChevronLeft size="s" />}
        iconPosition="left"
        text={
          <Text sizes={200} color="ColorButtonGhostSecondaryText">
        Go back
          </Text>
        }
        type="button"
        variant="ghost"
        colorScheme="brand-secondary"
      />
      <Heading as="h1" sizes={200} mb={32}>
      Owner Response
      </Heading>
      <Form
        id="reevooResponseForm"
        initialValues={{
          responseContent: '',
          terms: false,
          moderationCheck: false
        }}
        initialErrors={{ responseContent: 'Please enter a review', moderationCheck: 'This field is mandatory' }}
        initiallyTouched={{ responseContent: true }}
        validationSchema={SCHEMA}
        handleSubmit={handleSubmit}
      >
        <Textarea
          name="responseContent"
          showErrorIcon
          placeholder='Add your response'
          counter
          data-testId="responseInput"
          maxLength={textAreaMaxLength}
          textStyles={{color: '#333333'}}
          errorStyles={{position: 'relative', marginBottom: '1rem', fontSize: '0.75rem'}}
          callbackTextResponseChangeEvent={handleInputChange}
        />

        <ReviewResponseErrorMessage
          hasProfanity={hasProfanity}
          hasLegalWords={hasLegalWords}
          moderationWords={moderationWords}
        />

        {isNonTestPropertyInTestEnv &&
         <NonTestPropertyWarning>
           <h1>Possible posting of a non-test property in a test environment.</h1>
         </NonTestPropertyWarning>
        }

        {!moderationRequired && <Checkbox
          name="terms"
          label={
            <Text sizes={200}>I confirm that my response is considered truthful and acknowledge that it will be made public and is not representative of the views of Awaze.</Text>
          }
        />}

        {moderationRequired &&
        <Checkbox
          name="moderationCheck"
          label={
            <Text sizes={200}>I confirm I am happy for this response to be moderated and that my response is considered truthful and acknowledge that it will be made public and is not representative of the views of Awaze.</Text>
          }
        />
        }
        <StyledButtonPublish
          colourScheme="brand-primary"
          type="submit"
          variant="solid"
          text='Publish'
          isDisabled={!!isLoading}
          {...(isLoading ? { icon: <LoadingSpinner /> } : {})}
        />
      </Form>
    </StyledReviewResponseForm>
  )
}

ReviewResponseForm.propTypes = {
  propertyId: PropTypes.string,
  reviewId: PropTypes.string
}

export default ReviewResponseForm

import React, {useState} from 'react'
import PropTypes from 'prop-types'
import useGraphQl, { GET_PROPERTY_IMAGERY } from '@/hooks/useGraphQl'
import useWindowSize from '@/hooks/useWindowSize'
import { Container, Spinner } from '@/components/__UI'
import { ImageContainer, ImageThumbnail } from './PropertyImageThumbnails.style'

const PropertyImageThumbnails = ({propertyId}) => {
  const [imageList, setImageList] = useState([])
  const { width } = useWindowSize()

  const { loading: loadingImageryValues } = useGraphQl({
    query: GET_PROPERTY_IMAGERY,
    asObject: true,
    variables: {
      propertyId
    },
    onSuccess: (data) => {
      setImageList(data.map(x => x.url).filter((image) => image !== ''))
    }
  })

  const getImageCount = (width) => {
    if (width > 1100) return 5
    if (width > 850) return 4
    if (width > 600) return 3
    if (width > 400) return 2
    return 1
  }

  if (loadingImageryValues) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  return (
    imageList.length > 0
      ? <ImageContainer>
        {imageList.slice(0, getImageCount(width)).map((image, i) => <ImageThumbnail key={i} src={image} />)}
      </ImageContainer>
      : <p>No images found</p>
  )
}

PropertyImageThumbnails.propTypes = {
  propertyId: PropTypes.string.isRequired
}

export default PropertyImageThumbnails

import styled from 'styled-components'
import SelectDropdown from '@/components/__Inputs/SelectDropdown'

const StyledSelect = styled(SelectDropdown)`
    display: inline-block;

    &.disabled {
        .react_select__dropdown-indicator {
            display: none !important;
        }
    }

    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        content: "";
        border-top: 2px solid ${({ theme }) => theme.colors.link};
        border-right: 2px solid ${({ theme }) => theme.colors.link};
        border-bottom: 2px solid transparent;
        border-left: 2px solid transparent;
        border-radius: 2px;
        height: 12px;
        width: 12px;
        transform: rotate(135deg);
        transform-origin: center center;
        margin-left: -0.7rem;
        margin-bottom: .4rem;
    }
`

export { StyledSelect }

import { Section } from '../__UI'
import styled, { css } from 'styled-components'

const PageWrapper = styled(Section)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;

  ${({ asColumn }) => asColumn && css`
    flex-direction: column;
  `}

  ${({noBackground}) => noBackground && css`
    background-color: transparent;
  `}
`

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 25;
  .content-wrap {
    min-height: 90vh;
  }
  @media screen and (max-width: 1024px) {
    overflow-x: hidden;
  } 
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 60vh;
`

export {
  PageWrapper,
  PageContainer,
  ContentWrapper
}

import React, { useMemo } from 'react';
import PropTypes from 'prop-types'
import Switcher from '@/components/__UI/Switch'
import { useInfiniteQueryBookings } from '@/hooks/useQuery'
import { useTranslation } from 'react-i18next'

const AvailabilityToggleMobile = ({ year, yearCalendar, propertyId, unitId, showAvailability, setShowAvailability }) => {
  const enabled = !yearCalendar && !!propertyId;
  const { t } = useTranslation()

  const {
    data
  } = useInfiniteQueryBookings({ year, yearCalendar, propertyId, unitId, options: { enabled } });

  const pages = data?.pages;
  const availability = useMemo(() => {
    const bookabilityState = pages?.[0]?.availability?.bookabilityState;
    const availableDays = bookabilityState === 'onlyReturnedDatesBookable';
    return { bookabilityState,  availableDays };
  }, [pages]);

  return (
    <>
      {availability.availableDays && (
        <Switcher
          checked={showAvailability}
          onChange={setShowAvailability}
          label={t('showAvailability')}
        />
      )}
    </>
  );  
};

AvailabilityToggleMobile.propTypes = {
    year: PropTypes.number.isRequired,
    yearCalendar: PropTypes.bool,
    propertyId: PropTypes.string,
    unitId: PropTypes.string,
    showAvailability: PropTypes.bool,
    setShowAvailability: PropTypes.func.isRequired
}
export default AvailabilityToggleMobile;

import styled from 'styled-components'

const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.error};
  margin-bottom: 1rem;
`
const BtnContainer = styled.div`
  max-width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0 auto;
`

export { ErrorText, BtnContainer }

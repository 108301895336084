import styled from 'styled-components'
import { CheckboxArea } from '../Checkbox/Checkbox.styles'

const CheckboxLabel = styled(CheckboxArea)`
    .checkbox-inner {
        flex: 0.5;
    }
`

export { CheckboxLabel }

import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Spinner } from '@/components/__UI'
import Button from '@/components/__Inputs/Button'
import useGraphQl, { GET_PROPERTY_SEASON_DATES } from '@/hooks/useGraphQl'
import { BookingAllowanceSeasonsItem } from '@/components/__BookingAllowances'
import { StyledModal, SeasonsContainer } from './BookingAllowanceSeasons.styles'

const BookingAllowanceSeasons = ({propertyId, inputYear}) => {
  const currentYear = dayjs().year()
  const [seasonsList, setSeasonsList] = useState()
  const [modalOpen, setModalOpen] = useState(false)

  const [ propertySeasonDates, loadingSeasonDates ] = useGraphQl({
    query: GET_PROPERTY_SEASON_DATES,
    isEnabled: propertyId !== null,
    variables: {
      propertyId: propertyId
    }
  })

  useEffect(() => {
    filterSeasons(propertySeasonDates, inputYear)
    return () => {
      setSeasonsList()
      setModalOpen(false)
    }
    // eslint-disable-next-line
  }, [propertySeasonDates, inputYear])

  const filterSeasons = (list, filterYear) => {
    let newList
    if (list && Array.isArray(list) && filterYear) {
      if (filterYear === 'all') {
        newList = list.filter(({year}) => (
          year === (currentYear - 1).toString() ||
          year === (currentYear).toString() ||
          year === (currentYear + 1).toString()
        ))
      } else {
        newList = list.filter((item) => item.year === filterYear.toString())
      }
      newList = newList.sort((a, b) => parseInt(b.year) - parseInt(a.year))
    }
    setSeasonsList(newList)
  }

  if (loadingSeasonDates) {
    return (
      <SeasonsContainer>
        <Spinner />
      </SeasonsContainer>
    )
  }

  return (
    <>
      {seasonsList && Array.isArray(seasonsList) && seasonsList.length > 0 && <>
        <SeasonsContainer><Button data-testid="bookingAllowanceViewSeasonDatesButton" primary onClick={() => setModalOpen(true)}>View season dates</Button></SeasonsContainer>
        <StyledModal open={modalOpen} onClose={setModalOpen}>
          {seasonsList.map((item, key) => <BookingAllowanceSeasonsItem key={key} item={item} />)}
        </StyledModal>
      </>}
    </>
  )
}

BookingAllowanceSeasons.propTypes = {
  propertyId: PropTypes.string,
  inputYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}

export default BookingAllowanceSeasons

import styled, { css } from 'styled-components'

const hasErrorStyles = css`
  border-color: ${({theme}) => theme.colors.error};
  color: ${({theme}) => theme.colors.error};
`

export const StyledErrorMessage = styled.span`
  color: ${({theme}) => theme.colors.error};
  font-size: .9em;
  display: block;
  position: absolute;
  bottom: -0.3rem;
  left: 0;
`

export const StyledInputControl = styled.div`
  position: relative;
  margin-bottom: 0.5rem;

  input {
    margin-bottom: 1.1rem;
  }
`

export const StyledLabel = styled.label`
  ${({ theme }) => theme.fonts.InputTitle300}
  display: flex;
  width: 100%;
  color: ${({theme}) => theme.colors.outlineTertiaryButtonTxt};
  line-height: 1.5;
  padding-bottom: 8px;
`

export const styledInputCss = css`
  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  padding: .625rem .75rem;
  border-radius: ${({theme}) => theme.borderRadius};
  font-size: .938em;
  border: 1px solid ${({theme}) => theme.colors.inputDefault};

  ${({ hasError }) => hasError && hasErrorStyles};

  &[data-validation='correct'] {
    border-color: ${({theme}) => theme.colors.success};
  }

  &::-webkit-input-placeholder {
  color: ${({theme}) => theme.colors.inputDefault};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 2px 0 ${({theme}) => theme.colors.tertiaryButtonBorderHover};
  }

  &:disabled {
    color: ${({theme}) => theme.colors.tertiaryButtonBorder};
  }
`

export const StyledInput = styled.input`
  ${styledInputCss}
`

export const HiddenInput = styled.input`
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
`

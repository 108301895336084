import styled from 'styled-components'

/* stylelint-disable-line block-no-empty */
const MainHeaderSelectContainer = styled.article``

const ExplanationText = styled.div`
  margin-bottom: 1rem;
  text-align: justify;
`

export { MainHeaderSelectContainer, ExplanationText }

import styled from 'styled-components'

const ArrowBtn = styled.div`

  position: relative;
  display: block;
  
  .arrow{
    position: absolute;
  }

  .arrow::before,
  .arrow::after {
    position: relative;
    content: '';
    display: block;
    width: 12px;
    height: 1.5px;
    background-color: ${({ theme }) => theme.colors.secondary};

    transition: 0.3s ease-in-out;
  }

  .arrow::before {
    transform: rotate(45deg);
  }

  .arrow::after {
    left: 8px;
    top: -1.5px;
    transform: rotate(-45deg);
  }

  .active.arrow::before {
    transform: rotate(-45deg);
  }

  .active.arrow::after {
    transform: rotate(45deg);
  }
`

export {ArrowBtn}

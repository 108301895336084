import { usePricingDataContext } from '../context/PricingDataProvider';
import { YearPickerChevrons } from '@/components/__Inputs';

export function PricingListTableYearPicker() {
  const { year, setYear, availableYears } = usePricingDataContext();
  return (
    <YearPickerChevrons
      year={year}
      onChange={setYear}
      validYears={availableYears}
    />
  );
}

import styled from 'styled-components'
import SelectDropdown from '@/components/__Inputs/SelectDropdown'

const Header = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.colors.blackTwo};

  ${({theme}) => theme.mediaQueries.tabletLandscapeUp} {
    display: flex;
    justify-content: space-between;

    & > * {
      flex: 1;
    }
  }
`

const StyledDropdown = styled(SelectDropdown)`
  ${({theme}) => theme.mediaQueries.tabletLandscapeUp} {
    width: ${({width}) => width || '180px'};
  }
`

const DropdownContainer = styled.div`
  margin-bottom: 1rem;

  ${({theme}) => theme.mediaQueries.tabletLandscapeUp} {
    &:first-of-type {
      margin-right: 1rem;
    }
  }

`

export { Header, StyledDropdown, DropdownContainer }

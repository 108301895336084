import React from 'react'
import PropTypes from 'prop-types'
import { Container, Header, HeaderInner, Title, Icon, Content, StyledButton } from './PropertyAmendsSection.styles'

const PropertyAmendsSection = ({title, icon, iconMargin, viewMoreLink, viewMoreText, children, ...props}) => {
  return (
    <Container data-testid={props['data-testid']}>
      <Header>
        <HeaderInner>
          {icon && <Icon iconMargin={iconMargin} src={icon} />}
          {title && <Title>{title}</Title>}
        </HeaderInner>
        {viewMoreLink && <StyledButton to={viewMoreLink}>{viewMoreText || 'Edit section'}</StyledButton>}
      </Header>
      <Content>
        {children}
      </Content>
    </Container>
  )
}

PropertyAmendsSection.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconMargin: PropTypes.string,
  viewMoreLink: PropTypes.string,
  viewMoreText: PropTypes.string,
  children: PropTypes.object,
  'data-testid': PropTypes.string.isRequired
}

export default PropertyAmendsSection

import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { apiFetch } from '../helpers'

// CONTRACT: https://github.com/awazevr/awz-owner--api--contracts/blob/main/contracts/awaze-contracts/reviews/getReviews.yml

type Language = 'en' | 'da' | 'de' | 'es' | 'fr' | 'it' | 'nl' | 'no' | 'pl' | 'sv' | 'hr';

type ReviewSearchInput = {
  propertyCode: string;
  language?: Language;
};

type ReviewResponse = {
  text: string;
  isAwaitingPublish: boolean;
  responseDateTime: string;
  hasResponse: boolean;
  canRespond: boolean;
};

type Reviewer = {
  name: string;
  location: string;
};

type Review = {
  id: string;
  language: Language;
  score: number;
  holidayDate: string;
  review: string;
  reviewedOn: string;
  reviewedBy: Reviewer;
  reviewCategories: OverallCategory[];
  ownerResponse?: ReviewResponse;
};

type OverallCategory = {
  id: string;
  displayText: string;
  score: number;
};

type PropertyReviewDataResponse = {
  provider: string;
  averageScore: number;
  maxReviewScore: number;
  numberOfReviews: number;
  overallCategories: OverallCategory[];
  reviews: Review[];
};

type PropertyReviews = PropertyReviewDataResponse;

function getSearchURL (parameters: ReviewSearchInput): string {
  const url = `v1/properties/${parameters.propertyCode}/customerReviews`
  const searchParams = new URLSearchParams()

  if (parameters.language) searchParams.append('language', parameters.language)

  return `${url}?${searchParams.toString()}`
}

export const queryKeyReviews = (input: ReviewSearchInput) => ['reviews', input]

export const queryFnReviews = (input: ReviewSearchInput) => {
  const url = getSearchURL(input)
  return apiFetch(url)
}

export function useQueryReviews (input: ReviewSearchInput, options?: UseQueryOptions<PropertyReviews>) {
  const queryKey = queryKeyReviews(input)
  const queryFn = () => queryFnReviews(input)

  return useQuery<PropertyReviews>(queryKey, queryFn, {
    staleTime: 4000,
    cacheTime: 0,
    retry: 0,
    enabled: input.propertyCode != null,
    ...options
  })
}

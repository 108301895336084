import React, {useState} from 'react'
import Collapse from '@kunukn/react-collapse'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { Section, SectionData } from '@/components/__Modals/NewBookingModal/NewBookingModal.styles'
import { TextInput, SelectDropdown, FormikField, Checkbox } from '@/components/__Inputs'
import theme from '@/global/Theme'
import { Extras } from '../PassangerForm_extras/Extras'
import { SectionTitle, SectionContentLabel, SectionContentData } from '../PassangerForm_extras/Extras.styles'
import { useTranslation } from 'react-i18next'

const PassengerForm = ({extrasObj, setExtrasObj, chosenDays, hasHolidayPrice, duration}) => {
  const { t } = useTranslation()
  const {values} = useFormikContext()
  const passengerToggleKey = 'passengerToggle'
  const passengerToggleValue = values[passengerToggleKey]
  const [currentTitle, setCurrentTitle] = useState()

  const titles = [t('mr'), t('mrs'), t('miss'), t('dr')].map(t => ({
    value: t,
    label: t,
    disabled: false
  }))

  return (
    <>
      <Section>
        <SectionTitle data-testid="passengerFormGuestInfoTitle">{t('guestInformationOptional')}</SectionTitle>
        <SectionData>
          <Checkbox
            name={passengerToggleKey}
            color={theme.colors.secondary}
            tickColor={theme.colors.white}
            borderColor={theme.colors.greyThree}
          />
        </SectionData>
      </Section>
      <Collapse isOpen={passengerToggleValue} data-is-open={passengerToggleValue}>
        <Section>
          <SectionContentLabel>{t('title')}</SectionContentLabel>
          <SectionContentData>
            <FormikField
              component={SelectDropdown}
              inputType="select"
              name='titleSelect'
              options={titles}
              value={currentTitle}
              onChange={(value) => setCurrentTitle(value)}
              placeholder={t('selectATitle')}
            />
          </SectionContentData>
        </Section>
        <Section style={{marginBottom: '-1rem'}}>
          <SectionContentLabel>{t('forename')}</SectionContentLabel>
          <SectionContentData>
            <TextInput
              name='forename'
              type='text'
              placeholder={t('forename')}
            />
          </SectionContentData>
        </Section>
        <Section>
          <SectionContentLabel>{t('surname')}</SectionContentLabel>
          <SectionContentData>
            <TextInput
              name='surname'
              type='text'
              placeholder={t('surname')}
            />
          </SectionContentData>
        </Section>
      </Collapse>

      <Extras
        duration = {duration}
        extrasObj = {extrasObj}
        setExtrasObj = {setExtrasObj}
        chosenDays = {chosenDays}
        hasHolidayPrice = {hasHolidayPrice}
      />
    </>
  )
}

PassengerForm.propTypes = {
  setExtrasObj: PropTypes.func,
  setExtrasToggle: PropTypes.func,
  extrasObj: PropTypes.array,
  chosenDays: PropTypes.bool,
  hasHolidayPrice: PropTypes.bool,
  duration: PropTypes.number
}

export default PassengerForm

import styled from 'styled-components'

export const AcceptRejectTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  tr {
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.tertiary};
    border-collapse: collapse;
  }

  th {
    font-size: 1rem;
    font-weight: 700;

    ${({ theme }) => theme.mediaQueries.phoneOnly} {
      font-size: 0.9rem;
      font-weight: 600;
    }
  }

  td,
  th {
    padding: 1rem;
    text-align: left;

    &:first-child {
      width: 100%;
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }


  input[type="radio"] {
    appearance: none;
    background-color: ${({ theme }) => theme.colors.white};
    margin: 0;
    font: inherit;
    width: 1.2em;
    height: 1.2em;
    border: 0.1em solid ${({ theme }) => theme.colors.greyThree};
    border-radius: 50%;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }

  input[type="radio"]::before {
    content: "";
    width: 0.7em;
    height: 0.7em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${({ theme }) => theme.colors.secondary};
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }
`

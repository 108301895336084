import React, {useState} from 'react'
import { useParams } from 'react-router-dom'
import { useUser } from '@/context/UserProvider'
import { Container, Spinner } from '@/components/__UI'
import { PropertyImageEditModal, PropertyAmendSuccessModal } from '@/components/__Modals'
import { PropertyAmendsEditLayout, PropertyImagery } from '@/components/__PropertyAmendsComponents'
import { HeaderSection, StyledButton, InfoSection, TitleLink } from './PropertyAmendsImageryPage.styles'
import PhotographyGuideline from '@/assets/photographyGuides/Owner Photography Guidelines.pdf'
import OrderForm from '@/assets/photographyGuides/Owner Order Form.pdf'
import PropertyGuideline from '@/assets/photographyGuides/Owner propped guidelines.pdf'

const PropertyAmendsImageryPage = () => {
  const { id } = useParams()
  const { user, loading, hasPermission } = useUser()
  const [showModal, setShowModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const isPermitted = hasPermission('Bookings')

  if (!isPermitted && !loading) {
    return (
      <Container>
        <p style={{ textAlign: 'center' }}>You do not have permission to view this page</p>
      </Container>
    )
  }

  if (!user || loading) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  return (
    <PropertyAmendsEditLayout propertyId={id}>
      <HeaderSection>
        <h1>Property images</h1>
        <StyledButton data-testid="addNewImageButton" primary onClick={() => setShowModal(true)}>Add new image</StyledButton>
      </HeaderSection>
      <InfoSection>
        <p><TitleLink data-testid="howToPhotoLink" href='https://fast.wistia.com/embed/medias/19we5oc5zz' target='_blank' rel="noopener noreferrer">How to Photograph My Property Video Guide</TitleLink></p>
        <p>Here are the images that we currently have for your property. If you have any extra images that you would like us to consider adding to the website you can upload them here. Subject to suitability, we will add them to the website within 7 working days.</p>
        <p><a href={PhotographyGuideline} target='_blank' rel="noopener noreferrer">Click here</a> to read our photography guidelines. <a href={PropertyGuideline} target='_blank' rel="noopener noreferrer">Click here</a> for hints and tips on how to prepare your property for photographs. For more info about how you can benefit from our partnership with eHouse, <a href={OrderForm} target='_blank' rel="noopener noreferrer">click here</a></p>
      </InfoSection>
      <div>
        <PropertyImagery propertyId={id} showEdit />
      </div>
      {showModal && <PropertyImageEditModal propertyId={id} onClose={setShowModal} setShowSuccessModal={setShowSuccessModal} newImage />}
      {showSuccessModal && <PropertyAmendSuccessModal onClose={setShowSuccessModal} />}
    </PropertyAmendsEditLayout>
  )
}

export default PropertyAmendsImageryPage

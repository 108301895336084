import styled from 'styled-components'

const Container = styled.div`
  margin-top: 1rem;
`

const Year = styled.h3`
  ${({ theme }) => theme.fonts.Heading200}
  text-align: center;
`

const Section = styled.div``

const SectionRow = styled.div`
  display: flex;
  padding: 0.5rem 0;
  border-bottom: 1px solid ${({theme}) => theme.colors.greyThree};

  & > * {
    flex: 1;
  }
`

const SectionHeading = styled.p`
  font-weight: 600;
`

const SeasonType = styled.span`
  display: inline-block;
  padding: 0.25rem;
  background-color: ${({type, theme}) => {
    switch (type) {
      case 'winter':
        return theme.colors.seasonWinter
      case 'peak':
        return theme.colors.seasonPeak
      case 'low':
        return theme.colors.seasonLow
      case 'summer':
        return theme.colors.seasonSummer
      default:
        break
    }
  }};
`

export { Container, Year, Section, SectionRow, SectionHeading, SeasonType }

import DesignTokens from '@/global/DesignTokens'

const selectStyles = {
  container: (_, {selectProps: { hasError, disabled, isDisabled, theme, isMulti }}) => ({
    position: 'relative',
    display: 'flex',
    width: '100%',
    cursor: `${(isDisabled || disabled) ? 'auto' : 'pointer'}`,
    height: `${isMulti ? 'auto' : '3rem'}`,
    border: `1px solid ${hasError ? theme.colors.error : DesignTokens.ColorGrey400}`,
    borderRadius: theme.borderRadius,
    backgroundColor: `${theme.colors.white}`,
    pointerEvents: `${disabled ? 'none' : 'auto'}`
  }),
  control: (_, {selectProps: { isMulti }}) => ({
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    minHeight: `${isMulti ? '3rem' : 'auto'}`,
    padding: '0 1.125rem'
  }),
  dropdownIndicator: (_, { theme }) => ({
    opacity: 1,
    color: theme.colors.grey,
    alignItems: 'center',
    alignSelf: 'stretch',
    display: 'flex',
    flexShrink: '0'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (_, { theme }) => ({
    color: theme.colors.grey
  }),
  valueContainer: (_, {isDisabled, theme, isMulti}) => ({
    position: 'relative',
    display: 'flex',
    flexWrap: `${isMulti ? 'wrap' : 'nowrap'}`,
    width: '100%',
    alignItems: 'center',
    color: isDisabled ? theme.colors.greyThree : theme.colors.blackTwo
  }),
  menu: (_, { theme }) => ({
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    border: `1px solid ${theme.colors.greyThree}`,
    borderRadius: theme.borderRadius,
    backgroundColor: theme.colors.white,
    zIndex: '2'
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    padding: '0.5rem 0rem'
  }),
  option: (_, {isSelected, isDisabled, theme, disabled}) => ({
    color: isDisabled ? theme.colors.disabled : isSelected ? theme.colors.secondary : theme.colors.blackTwo,
    padding: '0.625rem 1rem',
    textAlign: 'left',
    fontWeight: isSelected ? 'bold' : 'normal',
    transition: theme.animationSpeed,
    ':hover': (!isDisabled || !disabled) && {
      cursor: 'pointer',
      color: theme.colors.secondary
    }
  }),
  multiValue: (_, { theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    height: '36px',
    borderRadius: '18px',
    marginRight: '0.5rem',
    marginTop: '0.5rem',
    marginBottom: '0.5rem'
  }),
  input: (_, { theme }) => ({
    height: '19px'
  })
}

const oneOptionStyles = {
  ...selectStyles,
  valueContainer: (_, { theme }) => ({
    position: 'relative',
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    alignItems: 'center',
    color: theme.colors.blackTwo
  }),
  dropdownIndicator: () => ({
    display: 'none'
  })
}

export { selectStyles, oneOptionStyles }

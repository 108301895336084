import React from 'react'
import { useTranslation } from 'react-i18next'
import useUserBrand from '@/hooks/useUserBrand'
import { Text } from '@awaze/design-system.components.text'
import { ModalBody, ModalContent } from '@awaze/design-system.components.modal'
import { Button } from '@awaze/design-system.components.button'

import AcceptanceTable from '../../AcceptanceTable/AcceptanceTable'
import { ApprovalMessage, ButtonSection, PropertyList, AcceptanceTableAndMessageContainer } from '../AcceptanceModal.styles'

interface ChooseStepProps {
    filteredProperties: any;
    setFilteredWithAccept: any;
    gotoNextStep: () => void;
}

export function ChooseStep ({ filteredProperties, setFilteredWithAccept, gotoNextStep }: ChooseStepProps) {
  const { t } = useTranslation()
  const { isUserBrandNovasol } = useUserBrand()
  return (
    <ModalContent header={t('priceRecommendationsHeadingStep2')}>
      <ModalBody>
        <Text sizes={300}>
          <PropertyList>
            <li>{t('priceChooseStep:acceptText')}</li>
            <li>{t('priceChooseStep:rejectText')}</li>
          </PropertyList>
        </Text>
        <AcceptanceTableAndMessageContainer>
          <AcceptanceTable filteredProperties={filteredProperties} setFilteredWithAccept={setFilteredWithAccept} />
          <ApprovalMessage>
            <Text sizes={300}>
              <p>{isUserBrandNovasol ? t('priceFooterTextDefault4') : t('priceFooterTextStart4')}</p>
            </Text>
          </ApprovalMessage>
        </AcceptanceTableAndMessageContainer>
        <ButtonSection style={{ marginTop: '1rem' }}>
          <Button
            data-testid="submitPriceRecButton"
            text={t('submit')}
            onClick={() => gotoNextStep()}
            colourScheme='brand-primary'
          />
        </ButtonSection>
      </ModalBody>
    </ModalContent>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import { StyledTableCell } from '../Table.styles'

const TableCell = ({ className, ...props }) => <StyledTableCell className={`table-cell ${className}`} {...props} />

TableCell.propTypes = {
  align: PropTypes.string,
  block: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.string
}

export default TableCell

import React from 'react'
import SheetSvg from '@/components/__SVG/SheetSvg'
import { SectionTitle,
  SectionContent, Section, SectionIcon, ItemsContainer, ItemTitle, ItemInfo, Item} from '../BookingDetails.styles'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { getLocale } from '@/helpers/locale'
import { DATE_FORMAT_INCLUDE_DAY } from '@/constants/dates'
import { capitaliseAllWords } from '@/helpers/stringUtils'

interface BookingSectionProps {
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs;
  expectedArrival?: dayjs.Dayjs;
  propertyName?: string;
  propertyCode?: string;
  unitCode?: string;
  id?: number;
  reservedOnDate?: Date;
  ownerIncome?: number;
  ownerChargeAmount?: number;
  hideArrivalDate?: boolean;
  showOwnerChargeAmount?: boolean;
  currency?: string;
  duration?: number;
  showBottomBorder?: boolean;
}

export const bookingSectionHasData = ({
  startDate,
  endDate,
  expectedArrival,
  propertyName,
  propertyCode,
  unitCode,
  id,
  reservedOnDate,
  ownerIncome,
  ownerChargeAmount
}: BookingSectionProps): boolean => {
  return !!(startDate || endDate || expectedArrival || propertyName || propertyCode || unitCode || id || reservedOnDate || ownerIncome || ownerChargeAmount)
}

export const BookingSection = ({
  startDate,
  endDate,
  expectedArrival,
  propertyName,
  propertyCode,
  unitCode,
  id,
  reservedOnDate,
  ownerIncome,
  ownerChargeAmount,
  hideArrivalDate,
  showOwnerChargeAmount,
  currency,
  duration,
  showBottomBorder
}: BookingSectionProps) => {
  const { t } = useTranslation()
  if (!bookingSectionHasData({
    startDate,
    endDate,
    expectedArrival,
    propertyName,
    propertyCode,
    unitCode,
    id,
    reservedOnDate,
    ownerIncome,
    ownerChargeAmount
  })) return null

  const formatDate = (date, format = DATE_FORMAT_INCLUDE_DAY) => {
    return date ? capitaliseAllWords(dayjs(date).format(format)) : null
  }

  const formattedStartDate = formatDate(startDate)
  const formattedEndDate = formatDate(endDate)
  const formattedExpectedArrival = formatDate(expectedArrival, 'dddd, D MMMM YYYY [at] hA')
  const formattedReservedOnDate = formatDate(reservedOnDate, 'DD MMMM YYYY')

  return (
    <Section data-testid='booking-section'>
      <SectionIcon><SheetSvg /></SectionIcon>
      <SectionContent>
        <SectionTitle data-testid="bookingDetailsTitle">{t('bookingDetailsTitle')}</SectionTitle>
        <ItemsContainer showBottomBorder={showBottomBorder}>
          { startDate &&
                  <Item data-testid='start-date'>
                    <ItemTitle>{t('arrival')}</ItemTitle>
                    <ItemInfo>{ formattedStartDate }</ItemInfo>
                  </Item> }
          { endDate &&
                <Item data-testid='end-date'>
                  <ItemTitle>{t('departure')}</ItemTitle>
                  <ItemInfo>{ formattedEndDate }</ItemInfo>
                </Item> }
          { duration &&
                <Item data-testid='nights-stayed'>
                  <ItemTitle>{t('nights')}</ItemTitle>
                  <ItemInfo>{t('nightsDuration', { duration })}</ItemInfo>
                </Item> }
          { !hideArrivalDate && expectedArrival &&
                <Item data-testid='expected-arrival'>
                  <ItemTitle>{t('expectedArrival')}</ItemTitle>
                  <ItemInfo>{formattedExpectedArrival}</ItemInfo>
                </Item> }
          { propertyName && propertyCode && (
            <Item data-testid='accommodation'>
              <ItemTitle>{t('accommodation')}</ItemTitle>
              <ItemInfo>{ propertyName } <span>({propertyCode})</span></ItemInfo>
            </Item>
          ) }
          { unitCode && (
            <Item data-testid='unit-no'>
              <ItemTitle>{t('unitNo')}</ItemTitle>
              <ItemInfo>{ unitCode }</ItemInfo>
            </Item>
          ) }
          { id && !isNaN(id) && (
            <Item data-testid='booking-ref'>
              <ItemTitle>{t('bookingRef')}</ItemTitle>
              <ItemInfo>{ id }</ItemInfo>
            </Item>
          ) }

          { reservedOnDate && (
            <Item data-testid='reserved-on'>
              <ItemTitle>{t('bookingCreated')}</ItemTitle>
              <ItemInfo>{ formattedReservedOnDate }</ItemInfo>
            </Item>
          ) }
          { typeof ownerIncome === 'number' && ownerIncome > 0 && (
            <Item data-testid='income'>
              <ItemTitle>{t('income')}</ItemTitle>
              <ItemInfo>{`${ownerIncome.toLocaleString(getLocale())} ${currency}`}</ItemInfo>
            </Item>
          )}

          {showOwnerChargeAmount && (
            <Item data-testid='excess-charges'>
              <ItemTitle>{t('excessCharges')}</ItemTitle>
              <ItemInfo>
                {ownerChargeAmount != null && ownerChargeAmount > 0 ? `${ownerChargeAmount.toLocaleString(getLocale())} ${currency}` : t('noExcessCharges')}
              </ItemInfo>

            </Item>

          )}
        </ItemsContainer>
      </SectionContent>
    </Section>
  )
}

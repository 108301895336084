import React, {useState, useContext, useMemo, useCallback} from 'react'
import PropTypes from 'prop-types'
import { usePermittedNavigation } from '@/constants/navigation'

const MenuContext = React.createContext()

export const MenuProvider = ({children}) => {
  const { NAV_HEADER_ITEMS, NAV_PRE_HEADER_ITEMS } = usePermittedNavigation()

  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false)
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const [location, setLocation] = useState({})
  const [page, setPage] = useState({page: '', links: []})

  const [clickedSubmenu, setClickedSubmenu] = useState([])
  const [submenuIndex, setSubmenuIndex] = useState(-1)

  const [submenuTop, setSubmenuTop] = useState('')
  const [activePage, setActivePage] = useState('')

  const submenuLinkHandler = useCallback((i, page) => {
    if (clickedSubmenu.length === 0) {
      setClickedSubmenu([page])
    } else {
      if (clickedSubmenu.includes(page)) {
        setClickedSubmenu([])
      } else {
        setClickedSubmenu([page])
      }
    }
    setSubmenuIndex(i)
  }, [setClickedSubmenu, setSubmenuIndex, clickedSubmenu])

  const handleOnClick = useCallback(() => {
    setIsMobileMenuOpened(false)
  }, [])

  const openSubmenu = useCallback((text, coordinates, email) => {
    const preHeaderItems = NAV_PRE_HEADER_ITEMS.map(item => {
      if (item.links && item.links[0].label === 'Email') {
        item.links[0].label = email
      }
      return item
    })
    const page = NAV_HEADER_ITEMS.find(link => link.page === text) || preHeaderItems.find(link => link.page === text)
    setPage(page)
    setLocation(coordinates)
    setIsSubmenuOpen(true)
    setSubmenuTop('NavBar')
  }, [setPage, setLocation, setIsSubmenuOpen, setSubmenuTop, NAV_PRE_HEADER_ITEMS, NAV_HEADER_ITEMS])

  const closeSubmenu = useCallback(() => {
    setIsSubmenuOpen(false)
  }, [setIsSubmenuOpen])

  const globalContextValue = useMemo(
    () => ({
      isSubmenuOpen,
      openSubmenu,
      closeSubmenu,
      isMobileMenuOpened,
      location,
      page,
      handleOnClick,
      submenuLinkHandler,
      clickedSubmenu,
      setClickedSubmenu,
      submenuIndex,
      setSubmenuIndex,
      setIsMobileMenuOpened,
      setSubmenuTop,
      submenuTop,
      activePage,
      setActivePage
    }),
    [openSubmenu, closeSubmenu, location, page, isMobileMenuOpened, isSubmenuOpen, handleOnClick,
      submenuLinkHandler, clickedSubmenu, setClickedSubmenu, submenuIndex, setSubmenuIndex, setIsMobileMenuOpened, setSubmenuTop, submenuTop, activePage, setActivePage]
  )

  return (
    <MenuContext.Provider
      value={globalContextValue}>
      {children}
    </MenuContext.Provider>
  )
}

MenuProvider.propTypes = {
  children: PropTypes.object
}

export const useMenuContext = () => {
  return useContext(MenuContext)
}

import { OccupancyFilterContext } from '../Models/OccupancyFilterContext'
import { Property } from '../Models/Property'

export const filterPropertiesByRequestedPetsOccupancy = (context: OccupancyFilterContext): OccupancyFilterContext => {
    const filteredProperties = filterPropertiesAndUnitsByRequiredPetCount(context)

    if (filteredProperties.length === 0) {
      return { ongoingPropertyCollection: filteredProperties, occupancyToFilterOn: context.occupancyToFilterOn }
    }

    return { ongoingPropertyCollection: filteredProperties, occupancyToFilterOn: context.occupancyToFilterOn }
}

const filterPropertiesAndUnitsByRequiredPetCount = (context: OccupancyFilterContext): Property[] => {
  const filteredProperties = context.ongoingPropertyCollection.map((property) => ({
    ...property,
    propertyUnits: property.propertyUnits.filter((unit) => {
      return unit.maximumPets >= context.occupancyToFilterOn.requiredPets
    })
  }))
  

  return filteredProperties.filter((property) => {
    return property.propertyUnits.length > 0
  })
}

import { useUser } from '@/context/UserProvider'

export function useUserBrand () {
  const { user, loading } = useUser()
  const brand = user?.attributes.brand

  let userBrand = null
  let isUserBrandAwaze = false
  let isUserBrandNovasol = false
  const userProductMarkets = user?.attributes?.productMarkets

  if (brand === 'novasol') {
    userBrand = brand
    isUserBrandNovasol = true
  } else if (brand === 'awaze') {
    userBrand = brand
    isUserBrandAwaze = true
  }

  return { userBrand, isUserBrandAwaze, isUserBrandNovasol, userProductMarkets, loading }
}

export default useUserBrand

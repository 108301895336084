import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PricingV2ResponseRows } from '@/hooks/useQuery';
import { usePricingDataContext } from '../../context/PricingDataProvider';
import { ExpanderCell } from './ExpanderCell';
import { SeasonCell } from './SeasonCell';
import CurrentPriceCell from './CurrentPriceCell';
import BookingsDetailsCell from './BookingsDetailsCell';
import { IncomeCell } from './IncomeCell';
import WeekCell from './WeekCell';
import { Text } from '@awaze/design-system.components.text';



export function usePricingTableDataVisibility() {
  const { pricingData } = usePricingDataContext();

  const dataVisibility = useMemo(() => ({
    income: (pricingData?.hasIncome ?? false),
    currentPrice: (pricingData?.isDynamic ?? false)
  }), [pricingData]);

  return dataVisibility;  
}

export function usePricingTableColumnVisibility({ isExpandable }: { isExpandable: boolean }) {
  
  const dataToShow = usePricingTableDataVisibility()

  const columnVisibility: Record<string, boolean> = useMemo(() => ({
    ...dataToShow,
    season: !isExpandable,
    basePrice: !isExpandable,
    income: !isExpandable && dataToShow.income,
    bookingDetails: !isExpandable,
    expandableToggle: isExpandable
  }), [isExpandable, dataToShow])

  return columnVisibility
}

export function usePricingTableColumnDefinitions() {
    const { t } = useTranslation();

    const columns = useMemo<ColumnDef<PricingV2ResponseRows>[]>(
    () => [
      {     
        id: 'week'   ,
        accessorKey: 'week',
        header: t('week'),
        cell: ({ getValue, row }) => <WeekCell value={getValue() as PricingV2ResponseRows['week']}  isExpanded={row.getIsExpanded()} />
      },
      {
        id: 'season',
        accessorKey: 'season',
        header: t('season'),
        cell: ({ getValue }) => <SeasonCell value={getValue() as PricingV2ResponseRows['season']} />
      },
      {
        id: 'basePrice',
        accessorKey: 'basePrice.value',
        header: t('startPrice'),
        cell: ({ getValue }) => <Text sizes={200}>{getValue() as PricingV2ResponseRows['basePrice']['value']}</Text>
      },
      {
        id: 'currentPrice',
        accessorKey: 'currentPrice',
        header: t('dynamicPrice'),
        cell: ({ getValue, row }) => <CurrentPriceCell value={getValue() as PricingV2ResponseRows['currentPrice']} isExpanded={row.getIsExpanded()} />
      },
      {
        id: 'income',
        accessorKey: 'income',
        header: t('yourIncome'),
        cell: ({ getValue }) => <IncomeCell bold value={getValue() as PricingV2ResponseRows['income']} />
      },
      {
        id: 'bookingDetails',
        accessorKey: 'bookingDetails',
        header: t('bookingDetails'),
        cell: ({ getValue }) => <BookingsDetailsCell value={getValue() as PricingV2ResponseRows['bookingDetails']} />,
      },
      {
        id: 'expandableToggle',
        header: '',
        cell: ({ row }) => <ExpanderCell isExpanded={row.getIsExpanded()} toggleExpanded={row.toggleExpanded} />
      }
    ],
    [t]
  );

  return columns;
}

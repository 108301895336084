import React from 'react'
import { AdultsSvg, ChevronSvg, KidsSvg, PetsSvg } from '@/components/__SVG'
import SpecialRequestsIcon from '../../__SVG/SpecialRequestsIcon'
import { AccommodationCell, ChevronContainer, NameCell, CountryCell, ConsolidatedTypeColorIndicator } from './BookingListTable.styles'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

export function ColorTypeCell (row) {
  return <ConsolidatedTypeColorIndicator bookingState={row.original.state} />
}

export function AccommodationCellRenderer (value) {
  return <AccommodationCell>{value.getValue()}</AccommodationCell>
}

export function NameCountryCellRender (original) {
  const { t } = useTranslation()
  return <>
    <NameCell title={original.leadGuest}>
      {original.leadGuest}
    </NameCell>
    <CountryCell data-testid='lead-guest-nationality'>{t(original.leadGuestNationality)}</CountryCell>
  </>
}

export function GuestInfoHeader () {
  const { t } = useTranslation()
  return (
    <div>
      <div style={{ textAlign: 'center', textWrap: 'nowrap' }}>{t('guestInfo')}</div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <AdultsSvg />
        <KidsSvg />
        <PetsSvg />
      </div>
    </div>
  )
}

export function GuestInfoCell (data) {
  const { adultsCount, combinedChildAndInfantsCount, petsCount, hidePartyInformation } = data
  if (hidePartyInformation) {
    return null
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div data-testid='adults-count'>{adultsCount}</div>
      <div data-testid='children-count'>{combinedChildAndInfantsCount}</div>
      <div data-testid='pets-count'>{petsCount}</div>
    </div>
  )
}

export function SpecialRequirementsCell (value) {
  if (value.getValue().length > 0) {
    return <SpecialRequestsIcon data-testid='special request icon'/>
  }
  return null
}

export function ExpanderCell (row) {
  return (
    <ChevronContainer
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          row.toggleExpanded()
        }
      }}
      data-testid={ row.getIsExpanded() ? 'chevron-180-degrees' : 'chevron-0-degrees' }
    >
      {row.getIsExpanded() ? <ChevronSvg /> : <ChevronSvg direction={180} />}
    </ChevronContainer>
  )
}

ColorTypeCell.propTypes = {
  original: PropTypes.shape({
  }).isRequired
}

AccommodationCellRenderer.propTypes = {
  getValue: PropTypes.func.isRequired
}

NameCountryCellRender.propTypes = {
  value: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.object
    }).isRequired
  }).isRequired
}

GuestInfoCell.propTypes = {
  adultsCount: PropTypes.number.isRequired,
  combinedChildAndInfantsCount: PropTypes.number.isRequired,
  petsCount: PropTypes.number.isRequired,
  hidePartyInformation: PropTypes.bool.isRequired
}

SpecialRequirementsCell.propTypes = {
  getValue: PropTypes.func.isRequired
}

ExpanderCell.propTypes = {
  toggleExpanded: PropTypes.func.isRequired,
  getIsExpanded: PropTypes.func.isRequired
}

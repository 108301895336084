import React from 'react'
import PropTypes from 'prop-types'
import { Container, Spinner } from '@/components/__UI'
import { PropertyDescriptionSection } from '@/components/__PropertyAmendsComponents'
import { Content } from './PropertyDescription.styles'
import useGraphQl, { GET_PROPERTY_DESCRIPTION } from '@/hooks/useGraphQl'

const PropertyDescription = ({propertyId}) => {
  const [ descriptionValues, loadingDescriptionValues ] = useGraphQl({
    query: GET_PROPERTY_DESCRIPTION,
    variables: {
      propertyId
    }
  })

  if (loadingDescriptionValues) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  if (!descriptionValues) {
    return (
      <p>{`Unable to find this property's description.`}</p>
    )
  }

  const descriptionKeys = [
    'At-a-Glance',
    'Rooms-description',
    'Property-services',
    'Property-description',
    'Location-services',
    'EPC-Rating',
    'Scottish-Letting-Licence'
  ]

  const filteredDescriptionValues = descriptionValues
    .filter(item => descriptionKeys.includes(item.id))
    .sort((a, b) => descriptionKeys.indexOf(a.id) - descriptionKeys.indexOf(b.id))

  if (filteredDescriptionValues && filteredDescriptionValues.length < 1) {
    return (
      <p>{`This property currently has no description.`}</p>
    )
  }

  return (
    descriptionValues
      .filter(item => descriptionKeys.includes(item.id))
      .sort((a, b) => descriptionKeys.indexOf(a.id) - descriptionKeys.indexOf(b.id))
      .map((item, x) => (
        <PropertyDescriptionSection key={x} item={item}>
          <Content dangerouslySetInnerHTML={{__html: item.description}} />
        </PropertyDescriptionSection>
      ))
  )
}

PropertyDescription.propTypes = {
  propertyId: PropTypes.string,
  showEdit: PropTypes.bool
}

export default PropertyDescription

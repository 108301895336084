import React from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { OvalLoadingSpinner } from '@/components/__UI'
import { PropertyId, PropertyName, BookingType, PropertyContainer } from '../bookingModals.styles'
import { BookingDetailsStyled, StyledMarginLeft, CancelButton } from './BookedModal.styles'
import { useTranslation } from 'react-i18next'
import { pascalToCamelCase } from '@/helpers/stringUtils'
import { DeleteBin } from '@awaze/design-system.components.icon'
import {
  Modal,
  ModalBody,
  ModalContent
} from '@awaze/design-system.components.modal'
import { useUserBrand } from '@/hooks/useUserBrand/useUserBrand'
import { Text } from '@awaze/design-system.components.text'

const BookedModal = ({
  data: bookingDetails,
  canCancel,
  loading,
  onClose,
  onCancel
}) => {
  const { t } = useTranslation()

  const { isUserBrandNovasol } = useUserBrand()

  const selectedType = bookingDetails?.state ? bookingDetails.state : null
  return (
    <Modal isOpen close={onClose}>
      <ModalContent header={<BookingType bookingState={selectedType}>{t(pascalToCamelCase(selectedType))}</BookingType>}>

        <ModalBody>
          {(loading || !bookingDetails)
            ? (<OvalLoadingSpinner />)
            : (
              <>
                <PropertyContainer>
                  {isUserBrandNovasol ? (
                    <Text type='bold' data-testId='property-id'>{bookingDetails?.propertyId}</Text>
                  ) : (
                    <>
                      <PropertyName data-testId='property-name'>{bookingDetails?.propertyName}</PropertyName>
                      <PropertyId data-testId='property-code'>{bookingDetails?.propertyCode}</PropertyId>
                    </>
                  )}
                </PropertyContainer>

                <BookingDetailsStyled booking={bookingDetails} />
                <StyledMarginLeft>
                  {(!dayjs(bookingDetails.startDate).isBefore(dayjs(), 'day') && bookingDetails.cancellable && canCancel) && (
                    <CancelButton
                      data-testid="bookingCancelButton"
                      onClick={() => {
                        onCancel && onCancel(bookingDetails.bookingId)
                        onClose && onClose()
                      }}
                      icon={<DeleteBin />}
                      iconPosition='left'
                      colourScheme='brand-secondary'
                      text={t('cancelBooking')}
                      variant='outline'
                    />
                  )}
                </StyledMarginLeft>

              </>
            )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

BookedModal.displayName = 'BookedModal'

BookedModal.propTypes = {
  data: PropTypes.object,
  canCancel: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  isChart: PropTypes.bool,
  type: PropTypes.string
}

export default BookedModal

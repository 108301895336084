import dayjs from 'dayjs'
import React from 'react'
import PropTypes from 'prop-types'
import PricingListTodayPrice from '../PricingListTodayPrice'
import { StyledTable as Table, Season } from '../PricingList'

const PricingListItem = ({ item, duration, income }) => (
  <Table.Row key={item.bookingId}>
    <Table.Cell>{dayjs(item.weekCommencing).format('DD MMM')}</Table.Cell>
    <Table.Cell>
      <Season className={item.miniSeason ? item.miniSeason.toLowerCase().replace(/\W/g, '-') : ''}>
        {item.miniSeason}
      </Season>
    </Table.Cell>
    <Table.Cell>{item.lowerCorridor}%</Table.Cell>
    <Table.Cell>{item.upperCorridor}%</Table.Cell>
    <Table.Cell>£{parseInt(item.originalCustomerPrice).toFixed(2)}</Table.Cell>
    <Table.Cell>
      <PricingListTodayPrice item={item} duration={duration} />
    </Table.Cell>
    <Table.Cell>{income}</Table.Cell>
  </Table.Row>
)

PricingListItem.propTypes = {
  item: PropTypes.object,
  duration: PropTypes.object,
  income: PropTypes.string
}

export default PricingListItem

import React from 'react'
import PropTypes from 'prop-types'
import helpQuestions from '@/constants/help-questions'
import { StyledSelect } from './QuestionSelector.styles'

const QuestionSelector = ({ disabled, onChange }) => {
  const handleChange = ({ value }) => {
    const question = helpQuestions.find(({ id }) => id === value)

    if (question && onChange) {
      onChange(question)
    }
  }

  return (
    <StyledSelect
      onChange={handleChange}
      isDisabled={disabled}
      options={helpQuestions.map(({ id, question }) => ({
        label: question,
        value: id
      })).slice(0, 10)}
      placeholder="Please choose the type of enquiry you have"
    />
  )
}

QuestionSelector.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

export default QuestionSelector

/* eslint-disable react/prop-types */
import React from 'react'
import GuestInfoSvg from '@/components/__SVG/GuestInfoSvg'
import { SectionTitle, SectionContent, Section, SectionIcon, ItemsContainer, ItemTitle, ItemInfo, Item } from '../BookingDetails.styles'
import { useTranslation } from 'react-i18next'
import { Badge } from '@awaze/design-system.components.badge'

interface Contact {
  phone?: string;
  email?: string;
  cellPhone?: string;
  landLine?: string;
  defaultContactDisplay?: string;
}

interface PartyLead {
  customerId?: string;
  longName?: string;
  nationality?: string;
  contacts?: Contact;
  postCode?: string;
  town?: string;
  isRepeatLeadGuest?: boolean;
}

interface Party {
  adultsCount?: number;
  childrenCount?: number;
  infantsCount?: number;
  petsCount?: number;
  partyLead?: PartyLead;
}

interface GuestSectionProps {
  party: Party | undefined;
  showBottomBorder: boolean;
}

export const guestSectionHasData = ({party}: GuestSectionProps): boolean => {
  const hasGuests = party ? (
    (party.adultsCount || 0) > 0 ||
    (party.childrenCount || 0) > 0 ||
    (party.infantsCount || 0) > 0 ||
    (party.petsCount || 0) > 0
  ) : false

  const hasPartyInfo = party?.partyLead ? (
    !!party.partyLead.longName ||
    !!party.partyLead.contacts?.defaultContactDisplay ||
    !!party.partyLead.postCode ||
    !!party.partyLead.town ||
    !!party.partyLead.nationality
  ) : false

  return hasGuests || hasPartyInfo
}

export const GuestSection = ({ party, showBottomBorder }: GuestSectionProps) => {
  const { t } = useTranslation()
  if (!guestSectionHasData({party, showBottomBorder})) return null

  const printGuests = () => {
    const guestCounts = [
      { type: 'adult', count: party?.adultsCount ?? 0 },
      { type: 'child', count: party?.childrenCount ?? 0, plural: t('children') },
      { type: 'infant', count: party?.infantsCount ?? 0 },
      { type: 'pet', count: party?.petsCount ?? 0 }
    ]

    const formattedGuests = guestCounts
      .filter(guest => guest.count > 0)
      .map(guest => `${guest.count} ${guest.count > 1 ? guest.plural || t(`${guest.type}s`) : t(guest.type)}`)
      .join(', ')

    return formattedGuests ? (
      <Item>
        <ItemTitle>{t('guests')}</ItemTitle>
        <ItemInfo>{formattedGuests}</ItemInfo>
      </Item>
    ) : null
  }

  const printContactDetails = () => {
    const partyLead = party?.partyLead
    if (!partyLead) return null

    return (
      <>
        {partyLead.longName && (
          <Item>
            <ItemTitle>{t('contact')}</ItemTitle>
            <ItemInfo>{partyLead.longName}</ItemInfo>
            <ItemInfo>{partyLead.contacts?.email && <a href={`mailto:${partyLead.contacts.email}`}>{partyLead.contacts.email}</a>}</ItemInfo>
            <ItemInfo>{partyLead.contacts?.defaultContactDisplay && <a href={`tel:${partyLead.contacts.defaultContactDisplay}`}>{partyLead.contacts.defaultContactDisplay}</a>}</ItemInfo>
            {partyLead.isRepeatLeadGuest &&
              <Badge text={t('returningGuest')} colourScheme='tertiary' variant='outline' style={{marginTop: '6px', width: 'fit-content'}}/>
            }

          </Item>
        )}
        {(partyLead.town || partyLead.postCode) && (
          <Item>
            <ItemTitle>{t('address')}</ItemTitle>
            <ItemInfo>{partyLead.town}</ItemInfo>
            <ItemInfo>{partyLead.postCode}</ItemInfo>
            <ItemInfo>{t(partyLead.nationality ?? '')}</ItemInfo>
          </Item>
        )}
      </>
    )
  }

  return (
    <Section data-testid='guest-section'>
      <SectionIcon><GuestInfoSvg /></SectionIcon>
      <SectionContent>
        <SectionTitle>{t('guestDetailsTitle')}</SectionTitle>
        <ItemsContainer showBottomBorder={showBottomBorder}>
          {printGuests()}
          {printContactDetails()}
        </ItemsContainer>
      </SectionContent>
    </Section>
  )
}

export default GuestSection

import { Popover, usePopover } from '@awaze/design-system.components.popover';
import { Text } from '@awaze/design-system.components.text';
import { useTranslation } from 'react-i18next';
import { StyledInfoCircle } from './PricingListTableTooltip.styles';

interface PricingListTableTooltipProps {
  showIncome: boolean;
  showDynamicPrice: boolean;
}

export function PricingListTableTooltip({
  showIncome,
  showDynamicPrice,
}: PricingListTableTooltipProps) {
  const { t } = useTranslation();
  const {
    PopoverContainer,
    togglePopover,
    popoverProps,
    triggerProps,
  } = usePopover();

  return (
    <PopoverContainer>
      <StyledInfoCircle
        size="medium"
        iconColour={'ColorThemeLink'}
        {...triggerProps}
        onClick={togglePopover}
      />
      <Popover {...popoverProps}>
        <div style={{ width: '20rem' }}>
          <Text sizes={200} type="bold" mb={16}>
            {t('pricingListTooltipHeading')}
          </Text>

          {showDynamicPrice && (
            <>
              <Text sizes={200} type="bold" mb={8}>
                {t('dynamicPrice')}
              </Text>
              <Text sizes={200} mb={32}>
                {t('pricingListTooltipDynamicPrice')}
              </Text>
            </>
          )}

          {showIncome && (
            <>
              <Text sizes={200} type="bold" mb={8}>
                {t('yourIncome')}
              </Text>
              <Text sizes={200} mb={32}>
                {t('pricingListTooltipIncome')}
              </Text>
            </>
          )}

          <Text sizes={200} type="bold" mb={8}>
            {t('bookingDetails')}
          </Text>
          <Text sizes={200}>{t('pricingListTooltipBookingDetails')}</Text>
        </div>
      </Popover>
    </PopoverContainer>
  );
}

import styled from 'styled-components'

const Content = styled.div`
  margin-top: 1rem;
  border-radius: 6px;
  background-color: ${({theme}) => theme.colors.white};
  padding: 1.5rem;
`

export { Content }

import styled from 'styled-components'

const StyledContainer = styled.div`
    h2 {
        margin-bottom: 0.5rem;
    }

    p {
        margin-bottom: 1rem;
    }

    ol, ul {
        margin: 1rem 0;
        margin-left: 1rem;
    }

    ol {
        list-style: decimal
    }
`

export { StyledContainer }

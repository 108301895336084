import React, { useRef } from 'react'
import { EditableDivStyledTextBox } from './EditableDiv.styles'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { useField } from 'formik'

export const EditableDivFormik = ({ id, placeholder }) => {
  const [field, , helpers] = useField({ name: id })
  /* Updating the value causes the cursor to jump to the beginning.
   * Using useRef means we can only set the initial defaultValue,
   * but we can still get value changes from onInput. */
  const defaultValueRef = useRef(field.value)

  return (
    <EditableDivStyledTextBox
      contentEditable
      suppressContentEditableWarning
      id={field.name}
      onInput={ e => {
        helpers.setValue(e.target.innerHTML)
      }}
      onBlur={field.onBlur}
      placeholder={ placeholder }
    >
      {defaultValueRef?.current && parse(defaultValueRef.current)}
    </EditableDivStyledTextBox>
  )
}
EditableDivFormik.propTypes = {
  id: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string
}

export const EditableDiv = ({contentEditable, item, handleChange, placeholder}) => (
  <EditableDivStyledTextBox
    contentEditable = {contentEditable}
    suppressContentEditableWarning
    id={ item.id }
    onInput={ e => handleChange(item.id, item.sectionName, JSON.stringify(e.target.innerHTML)) }
    placeholder={ placeholder } >

    {parse(item.description)}

  </EditableDivStyledTextBox>
)

EditableDiv.propTypes = {
  contentEditable: PropTypes.bool,
  item: PropTypes.object,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func
}

export default EditableDiv

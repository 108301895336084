import styled from 'styled-components'

const Head = styled.thead`
  position: sticky;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: ${({theme}) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    position: sticky;
    top: 0;
    left: 0;
    background-color: ${({theme}) => theme.colors.white};
  }

  @media (min-width: 600px) and (max-width: 1199px) {
    position: sticky;
    top: 0;
    left: 0;
    background-color: ${({theme}) => theme.colors.white};
  }
`
export { Head }

import dayjs from 'dayjs'
import React from 'react'
import * as Yup from 'yup'
import { DATE_FORMAT_DISPLAY } from '@/constants/dates'
import { generateRandomKey } from '@/helpers/stringUtils'
import { SCHEMA_FIELD_USERNAME } from '@/constants/fields'
import { DatePicker, SelectDropdown, Textarea } from '@/components/__Inputs'
import { FormRow, StyledPropertyDropdown, StyledFormikField, StyledTextInput, TextareaContainer } from './FormBuilder.styles'

function getFieldName (field) {
  return field.name || field.label.toLowerCase().replace(/[ ]/gm, '')
}

function getFieldLabel (field, label) {
  return `${label || field.label}${field.required ? '*' : ''}`
}

function buildInitialValues (fields) {
  const values = {}

  const buildValue = (field) => {
    if (field) {
      let initialValue

      if (field.type === 'date') {
        initialValue = dayjs().format(DATE_FORMAT_DISPLAY)
      } else if (field.type === 'date-month') {
        initialValue = dayjs().format('MMMM YYYY')
      } else {
        initialValue = ''
      }

      values[getFieldName(field)] = initialValue
    }
  }

  fields.forEach(field => {
    Array.isArray(field)
      ? field.forEach(buildValue)
      : buildValue(field)
  })

  return values
}

function buildSchema (fields) {
  const schemaFields = {}

  const buildObject = (field) => {
    if (field) {
      const name = getFieldName(field)
      const yupObject = field.required ? Yup.string().required(field.required) : Yup.string()

      if (field.type === 'email') {
        schemaFields[name] = SCHEMA_FIELD_USERNAME
      } else if (field.options) {
        schemaFields[name] = Yup.object().shape({
          label: yupObject,
          value: yupObject
        })
      } else {
        schemaFields[name] = yupObject
      }
    }
  }

  fields.forEach(field => {
    Array.isArray(field)
      ? field.forEach(buildObject)
      : buildObject(field)
  })

  return Yup.object().shape(schemaFields)
}

function buildField (field) {
  const key = generateRandomKey()

  // Field is null, add blank div (used to keep FormRow flex layout with one field)
  if (!field) {
    return <div key={key} />
  }

  // Field is array, iterate fields into a flex row to put fields on same row
  if (Array.isArray(field)) {
    return (
      <FormRow key={key}>
        {field.map(buildField)}
      </FormRow>
    )
  }

  // Field is a select dropdown, FormikField turns SelectDropdown into a compatible Formik Select dropdown
  if (field.options) {
    return (
      <StyledFormikField
        component={SelectDropdown}
        inputType="select"
        key={key}
        name={getFieldName(field)}
        {...field}
        label={getFieldLabel(field)}
      />
    )
  }

  // Field is standard text input or email address
  if (field.type === 'text' || field.type === 'email') {
    return (
      <StyledTextInput
        key={key}
        name={getFieldName(field)}
        label={getFieldLabel(field)}
        placeholder={field.placeholder}
        required={field.required}
        type="text"
      />
    )
  }

  // Field is a accommodation dropdown, pass 'withFormik' prop with PropertyDropdown component
  if (field.type === 'property') {
    return (
      <StyledPropertyDropdown
        key={key}
        label={getFieldLabel(field, 'Property')}
        placeholder="Select a property"
        withFormik
      />
    )
  }

  // Field is a date input variant
  if (field.type.indexOf('date') > -1) {
    return buildDateField(field, key)
  }

  // Field is a standard textarea
  if (field.type === 'textarea') {
    return (
      <TextareaContainer key={key}>
        <Textarea
          name={getFieldName(field)}
          placeholder={getFieldLabel(field, field.placeholder)}
        />
      </TextareaContainer>
    )
  }
}

const buildDateField = (field, key) => {
  const minDate = field.dateRange && field.dateRange.minDate
  const maxDate = field.dateRange && field.dateRange.maxDate
  const minimumDate = minDate || dayjs().format('YYYY-MM-DD')
  const maximumDate = maxDate || new Date(dayjs().year() + 3, 11, 31)

  // Field is a standard date picker
  if (field.type === 'date') {
    return (
      <StyledFormikField
        key={key}
        component={DatePicker}
        inputType="date"
        name={getFieldName(field)}
        label={getFieldLabel(field)}
        minDate={minimumDate}
        maxDate={maximumDate}
        showYearDropdown
        closeOnScroll
      />

    )
  }

  // Field is a DatePicker where only months are selectable
  if (field.type === 'date-month') {
    return (
      <StyledFormikField
        key={key}
        component={DatePicker}
        inputType="date-month"
        dateFormat="MMMM yyyy"
        name={getFieldName(field)}
        label={getFieldLabel(field)}
        minDate={minimumDate}
        maxDate={maximumDate}
        showMonthYearPicker
        closeOnScroll
      />
    )
  }
}

export { getFieldName, getFieldLabel, buildInitialValues, buildSchema, buildField }

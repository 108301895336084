import { updatePageInfo } from '../gtm/gtm'
import { ROUTES } from '@/constants/routes'
import { useUser } from '@/context/UserProvider'
import { useEffect, useState, useRef } from 'react'
import { useOnNavigation } from '../NavigationListener'

const pageTitle = document.title

let isAuthenticated = false
let wasAuthenticated = false
let previousUrl = ''

function handlePageChange (url) {
  // react still triggers a history change when the same url is selected,
  // but react will not actually re-render on these occurances
  // as such this is required to prevent data being wiped
  if (url === previousUrl && isAuthenticated === wasAuthenticated) {
    return
  }

  const pageData = [...ROUTES]

  const data = (pageData.find(pd => {
    return pd.path.test ? pd.path.test(url) : pd.path === url
  }) || {})

  let pageInfo = data.pageInfo || {}

  if (typeof pageInfo === 'function') {
    const matchInfo = data.path.exec ? data.path.exec(url) : null
    pageInfo = pageInfo(isAuthenticated, matchInfo)
  }

  const {type, subtype, section, subSection} = pageInfo
  updatePageInfo(data.path, type, subtype, section, subSection)
  const subtitle = subtype || type
  document.title = `${subtitle ? subtitle + ' // ' : ''}${pageTitle}`

  previousUrl = url
  wasAuthenticated = isAuthenticated
}

function initialize () {
  handlePageChange(window.location.pathname)

  return () => {
    previousUrl = ''
    document.title = pageTitle
    updatePageInfo('', '')
  }
}

export default () => {
  const { user, loading } = useUser()
  const [initialized, setInitialized] = useState(null)
  const cleanupMethod = useRef()
  isAuthenticated = !!user
  useEffect(() => {
    if (!loading && !initialized) {
      cleanupMethod.current = initialize()
      setInitialized(true)
    }
  }, [loading, initialized])

  useEffect(() => {
    handlePageChange(window.location.pathname)
  }, [user])

  useOnNavigation(initialized && (({to, isReactNavigation}) => { isReactNavigation && handlePageChange(to) }))

  useEffect(() => {
    return () => cleanupMethod.current && cleanupMethod.current()
  }, [])

  return null
}

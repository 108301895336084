import styled from 'styled-components'
import PropertyDropdown from '@/components/__Inputs/PropertyDropdown'

const StyledPropertyDropdown = styled(PropertyDropdown)``

const PropertyDropdownWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 18rem;
`

export { PropertyDropdownWrapper, StyledPropertyDropdown }

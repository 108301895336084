import { useEffect } from 'react'
import { getCookies } from '@/helpers/cookies'
import TagManager from 'react-gtm-module'
import { getEnvironmentVariable } from '../environment'

const getArgs = () => ({
  gtmId: 'GTM-KKPSZ36',
  environment: getEnvironment()
})

const getEnvironment = () => {
  const url = window.location.href
  const environments = ['localhost', 'dev', 'ppe']
  let env = 'live'

  environments.forEach(e => {
    const match = url.match(e)
    if (match) {
      env = match[0]
    }
  })

  return env
}

let initialised = false
let loading = false
let tagManagerArgs = null

/**
 * Only to be used for testing purposes, gtm cannot be uninitialised once running
 */
export function reset () {
  if (initialised && TagManager.testMode) {
    initialised = false
    loading = false
    window.dataLayer = null
  }
}

export function gtmInitialize () {
  if (initialised) return
  loading = true
  try {
    tagManagerArgs = getArgs()
    if (tagManagerArgs.gtmId) {
      TagManager.initialize({ gtmId: tagManagerArgs.gtmId })
      initialised = true
    }
  } finally {
    loading = false
  }
}

export function updateDataLayer (data) {
  if (initialised || loading) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(data)
  }
}

export function fireEvent (eventName, data) {
  updateDataLayer({
    event: eventName,
    ...data
  })
}

export function updatePageInfo (path, type, subtype, section, subSection) {
  const userId = getCookies('userId')

  updateDataLayer({
    event: 'pageview',
    page: {
      path,
      type,
      subtype: subtype || null,
      siteSection: section || null,
      siteSubSection: (section && subSection) || null
    },
    site: {
      brand: 'cottage owners',
      environment: tagManagerArgs ? tagManagerArgs.environment : null,
      version: getEnvironmentVariable('BUILD_ID', null)
    },
    user: {
      id: userId
    }
  })
}

export function updatePageSection (section, subSection) {
  const page = {
    siteSection: section || null,
    siteSubSection: (section && subSection) || null
  }

  fireEvent('PageSectionUpdate', {page})
}

export function usePageSection (section, subSection) {
  subSection = section && subSection
  useEffect(() => {
    if (section) {
      updatePageSection(section, subSection)
    }
  }, [section, subSection])
}

import React from 'react'
import FileDownloader from '@/components/__UI/FileDownloader'

import NoticeFireSafety from '@/assets/healthAndSafetyFiles/notice-fire-safety.pdf'
import NoticeHotTubSaunaGuide from '@/assets/healthAndSafetyFiles/notice-hottub-sauna.pdf'
import NoticeNoDiving from '@/assets/healthAndSafetyFiles/notice-no-diving.pdf'
import NoticeSepticTank from '@/assets/healthAndSafetyFiles/notice-septic-tank.pdf'

const HealthAndSafetyFileList = () => {
  const fileList = [
    {
      title: 'Notice – Fire safety',
      file: NoticeFireSafety
    },
    {
      title: 'Notice – Hot tub/sauna guide',
      file: NoticeHotTubSaunaGuide
    },
    {
      title: 'Notice – No diving',
      file: NoticeNoDiving
    },
    {
      title: 'Notice – Septic tank',
      file: NoticeSepticTank
    }
  ]
  return (
    fileList.map(({title, file}, x) => <FileDownloader key={x} title={title} file={file} />)
  )
}

export default HealthAndSafetyFileList

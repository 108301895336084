import PropTypes from 'prop-types'
import toast from '@/helpers/toast'
import React, { useEffect, useState } from 'react'
import { Button, Form } from '@/components/__Inputs'
import { Container, Title } from './FormBuilder.styles'
import { buildInitialValues, buildSchema, buildField } from './FormBuilder.utils'

const FormBuilder = ({
  className,
  fields,
  formID,
  onSubmit,
  submitText,
  title
}) => {
  const [ isSubmitting, setSubmitting ] = useState(false)
  const [ submitError, setSubmitError ] = useState(null)
  const [ submittedText, setSubmittedText ] = useState(null)

  const handleSubmit = async (values, { resetForm }) => {
    const reset = () => {
      resetForm({})
      setSubmitError(null)
      setSubmitting(false)
      setSubmittedText(null)
    }

    if (onSubmit && typeof onSubmit === 'function' && !isSubmitting) {
      onSubmit(values, {
        setSubmitting,
        formError: setSubmitError,
        formSuccess: setSubmittedText,
        reset
      })
    }
  }

  useEffect(() => {
    if (submittedText) {
      toast.success(submittedText, null)
    }
  }, [submittedText])

  useEffect(() => {
    if (submitError) {
      toast.error(submitError, null)
    }
  }, [submitError])

  return (
    <Container className={`form-builder--container ${className || ''}`}>
      {title && <Title className="form-builder--title">{title}</Title>}

      <Form
        id={formID}
        enableReinitialize
        initialValues={buildInitialValues(fields)}
        validationSchema={buildSchema(fields)}
        handleSubmit={handleSubmit}
        disabled={isSubmitting}
      >
        {fields.map(buildField)}
        <Button
          data-testid="formSubmitButton"
          className={isSubmitting ? 'isLoading' : ''}
          primary
          type="submit"
          form={formID}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          {submitText || 'Submit'}
        </Button>
      </Form>
    </Container>
  )
}

FormBuilder.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.array.isRequired,
  formID: PropTypes.string.isRequired,
  submitText: PropTypes.string,
  title: PropTypes.string,
  onSubmit: PropTypes.func
}

export default FormBuilder

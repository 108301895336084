
export default {
  ColorBlackOpacity5: 'rgba(0, 0, 0, 0.05)',
  ColorBlackOpacity10: 'rgba(0, 0, 0, 0.1)',
  ColorBlackOpacity20: 'rgba(0, 0, 0, 0.2)',
  ColorBlackOpacity30: 'rgba(0, 0, 0, 0.3)',
  ColorBlackOpacity40: 'rgba(0, 0, 0, 0.4)',
  ColorBlackOpacity50: 'rgba(0, 0, 0, 0.5)',
  ColorBlackOpacity60: 'rgba(0, 0, 0, 0.6)',
  ColorBlackOpacity70: 'rgba(0, 0, 0, 0.7)',
  ColorBlackOpacity80: 'rgba(0, 0, 0, 0.8)',
  ColorBlackOpacity90: 'rgba(0, 0, 0, 0.9)',
  ColorBlackOpacity100: 'rgba(0, 0, 0, 1)',
  ColorWhiteOpacity0: 'rgba(255, 255, 255, 0)',
  ColorWhiteOpacity5: 'rgba(255, 255, 255, 0.05)',
  ColorWhiteOpacity10: 'rgba(255, 255, 255, 0.1)',
  ColorWhiteOpacity20: 'rgba(255, 255, 255, 0.2)',
  ColorWhiteOpacity30: 'rgba(255, 255, 255, 0.3)',
  ColorWhiteOpacity40: 'rgba(255, 255, 255, 0.4)',
  ColorWhiteOpacity50: 'rgba(255, 255, 255, 0.5)',
  ColorWhiteOpacity60: 'rgba(255, 255, 255, 0.6)',
  ColorWhiteOpacity70: 'rgba(255, 255, 255, 0.7)',
  ColorWhiteOpacity80: 'rgba(255, 255, 255, 0.8)',
  ColorWhiteOpacity90: 'rgba(255, 255, 255, 0.9)',
  ColorWhiteOpacity100: 'rgba(255, 255, 255, 1)',
  ColorThemeCtaPrimaryActive: 'rgba(64, 114, 77, 1)',
  ColorThemeLink: 'rgba(56, 77, 97, 1)',
  ColorThemeCtaPrimary: 'rgba(75, 129, 90, 1)',
  ColorThemeCtaSecondary: 'rgba(86, 117, 143, 1)',
  ColorThemeLinkActive: 'rgba(48, 67, 84, 1)',
  ColorThemeCtaSecondaryActive: 'rgba(64, 87, 114, 1)',
  ColorThemeDark100: 'rgba(59, 104, 69, 1)',
  ColorThemeDark200: 'rgba(53, 95, 63, 1)',
  ColorThemeDark300: 'rgba(45, 82, 55, 1)',
  ColorThemeLight100: 'rgba(249, 249, 245, 1)',
  ColorThemeLight200: 'rgba(237, 237, 233, 1)',
  ColorThemeLight300: 'rgba(224, 224, 220, 1)',
  ColorThemeAccent1: 'rgba(172, 194, 128, 1)',
  ColorThemeAccent2: 'rgba(254, 186, 1, 1)',
  ColorThemeAccent3: 'rgba(255, 151, 128, 1)',
  ColorThemeSecondary100: 'rgba(134, 172, 202, 1)',
  ColorThemePrimary700: 'rgba(38, 69, 46, 1)',
  ColorThemePrimary600: 'rgba(45, 82, 55, 1)',
  ColorThemePrimary500: 'rgba(59, 104, 69, 1)',
  ColorThemePrimary400: 'rgba(64, 114, 77, 1)',
  ColorThemePrimary300: 'rgba(76, 133, 90, 1)',
  ColorThemePrimary200: 'rgba(99, 161, 115, 1)',
  ColorThemePrimary100: 'rgba(112, 178, 131, 1)',
  ColorThemeSecondary700: 'rgba(48, 67, 84, 1)',
  ColorThemeSecondary600: 'rgba(56, 77, 97, 1)',
  ColorThemeSecondary500: 'rgba(73, 100, 126, 1)',
  ColorThemeSecondary400: 'rgba(80, 111, 139, 1)',
  ColorThemeSecondary300: 'rgba(95, 126, 155, 1)',
  ColorThemeSecondary200: 'rgba(120, 156, 186, 1)',
  ColorThemeBackgroundHeader: 'rgba(53, 95, 63, 1)',
  ColorThemeBackgroundPage: 'rgba(249, 249, 245, 1)',
  ColorThemeButtonSecondaryAlt: 'rgba(255, 255, 255, 1)',
  ColorThemeButtonSecondaryAltHover: 'rgba(242, 242, 242, 1)',
  ColorThemeTextHeaderLink: 'rgba(255, 255, 255, 1)',
  ColorThemeTextHeaderButtonLink: 'rgba(56, 77, 97, 1)',
  ColorGrey25: 'rgba(247, 247, 247, 1)',
  ColorGrey50: 'rgba(242, 242, 242, 1)',
  ColorGrey100: 'rgba(230, 230, 230, 1)',
  ColorGrey200: 'rgba(204, 204, 204, 1)',
  ColorGrey300: 'rgba(179, 179, 179, 1)',
  ColorGrey400: 'rgba(153, 153, 153, 1)',
  ColorGrey500: 'rgba(128, 128, 128, 1)',
  ColorGrey600: 'rgba(102, 102, 102, 1)',
  ColorGrey700: 'rgba(77, 77, 77, 1)',
  ColorGrey800: 'rgba(51, 51, 51, 1)',
  ColorGrey850: 'rgba(41, 41, 41, 1)',
  ColorGrey900: 'rgba(26, 26, 26, 1)',
  ColorStatusConstructive400: 'rgba(25, 128, 56, 1)',
  ColorStatusAttention400: 'rgba(220, 30, 39, 1)',
  ColorStatusInfo500: 'rgba(12, 75, 192, 1)',
  ColorStatusCaution600: 'rgba(124, 89, 19, 1)',
  ColorStatusCaution500: 'rgba(185, 133, 29, 1)',
  ColorStatusCaution400: 'rgba(247, 178, 38, 1)',
  ColorStatusCaution300: 'rgba(249, 197, 93, 1)',
  ColorStatusCaution200: 'rgba(251, 216, 147, 1)',
  ColorStatusCaution100: 'rgba(253, 236, 201, 1)',
  ColorStatusCaution50: 'rgba(254, 245, 225, 1)',
  ColorStatusCaution25: 'rgba(254, 250, 241, 1)',
  ColorStatusAttention600: 'rgba(112, 15, 20, 1)',
  ColorStatusAttention500: 'rgba(166, 23, 30, 1)',
  ColorStatusAttention300: 'rgba(229, 87, 94, 1)',
  ColorStatusAttention200: 'rgba(238, 144, 149, 1)',
  ColorStatusAttention100: 'rgba(246, 198, 200, 1)',
  ColorStatusAttention50: 'rgba(250, 220, 222, 1)',
  ColorStatusAttention25: 'rgba(252, 238, 239, 1)',
  ColorStatusInfo600: 'rgba(8, 49, 125, 1)',
  ColorStatusInfo400: 'rgba(16, 100, 254, 1)',
  ColorStatusInfo300: 'rgba(77, 139, 254, 1)',
  ColorStatusInfo200: 'rgba(133, 177, 255, 1)',
  ColorStatusInfo100: 'rgba(194, 215, 255, 1)',
  ColorStatusInfo50: 'rgba(219, 232, 255, 1)',
  ColorStatusInfo25: 'rgba(235, 241, 255, 1)',
  ColorStatusConstructive600: 'rgba(13, 63, 28, 1)',
  ColorStatusConstructive500: 'rgba(19, 98, 43, 1)',
  ColorStatusConstructive300: 'rgba(83, 162, 107, 1)',
  ColorStatusConstructive200: 'rgba(141, 191, 156, 1)',
  ColorStatusConstructive100: 'rgba(200, 224, 206, 1)',
  ColorStatusConstructive50: 'rgba(222, 237, 226, 1)',
  ColorStatusConstructive25: 'rgba(239, 246, 241, 1)',
  ColorBrandNovasolRebrandGrey: 'rgba(250, 250, 250, 1)',
  ColorBrandNovasolRebrandBlue: 'rgba(31, 49, 77, 1)',
  ColorBrandNovasolRed: 'rgba(216, 19, 19, 1)',
  ColorBrandNovasolBlack: 'rgba(34, 28, 12, 1)',
  ColorBrandNovasolWhite: 'rgba(255, 255, 255, 1)',
  ColorBrandAwazeMasterGreen: 'rgba(63, 115, 77, 1)',
  ColorBrandAwazeLightGreen: 'rgba(172, 194, 128, 1)',
  ColorBrandAwazeDarkGreen: 'rgba(40, 77, 45, 1)',
  ColorBrandCottagesComCoreGreen: 'rgba(173, 192, 104, 1)',
  ColorBrandCottagesComCoreBlue: 'rgba(80, 111, 139, 1)',
  ColorBrandCottagesComBlack: 'rgba(41, 41, 41, 1)',
  ColorBrandCottagesComWhite: 'rgba(255, 255, 255, 1)',
  ColorBrandHoseasonsLightGreen: 'rgba(137, 186, 38, 1)',
  ColorBrandHoseasonsDarkGreen: 'rgba(50, 97, 41, 1)',
  ColorBrandHoseasonsBlack: 'rgba(0, 0, 0, 1)',
  ColorBrandHoseasonsWhite: 'rgba(255, 255, 255, 1)',
  ColorBrandSecondary: 'rgba(86, 117, 143, 1)',
  ColorBrandPrimary: 'rgba(75, 129, 90, 1)',
  ColorBrandCollectionAutograph: 'rgba(67, 126, 127, 1)',
  ColorBrandCollectionBouja: 'rgba(151, 32, 106, 1)',
  ColorBrandCollectionDaisyDoor: 'rgba(90, 124, 65, 1)',
  ColorBrandCollectionEvermoreDark: 'rgba(79, 83, 54, 1)',
  ColorBrandCollectionEvermoreLight: 'rgba(186, 184, 135, 1)',
  ColorBrandCollectionGoActiveDark: 'rgba(46, 96, 46, 1)',
  ColorBrandCollectionGoActiveLight: 'rgba(137, 187, 37, 1)',
  ColorBrandCollectionStarboard: 'rgba(35, 31, 32, 1)',
  ColorBrandCollectionWanderWoodDark: 'rgba(57, 51, 40, 1)',
  ColorBrandCollectionWanderWoodLight: 'rgba(196, 204, 51, 1)',
  ColorInputDefaultOutline: 'rgba(128, 128, 128, 1)',
  ColorInputActiveHoverOutline: 'rgba(102, 102, 102, 1)',
  ColorInputDisabledOutline: 'rgba(153, 153, 153, 1)',
  ColorComponentStepperBrandedStepper: 'rgba(75, 129, 90, 1)',
  ColorComponentStepperBrandedStepperDisabled: 'rgba(206, 216, 197, 1)',
  ColorComponentSearchBarBorderActive: 'rgba(64, 114, 77, 1)',
  ColorComponentSearchBarDropTypeHover: 'rgba(220, 229, 214, 1)',
  ColorComponentCalendarTextSelected: 'rgba(255, 255, 255, 1)',
  ColorComponentCalendarText: 'rgba(51, 51, 51, 1)',
  ColorComponentCalendarStartEndBackgroundHover: 'rgba(219, 230, 222, 1)',
  ColorComponentCalendarBorderHover: 'rgba(75, 129, 90, 1)',
  ColorComponentCalendarBackgroundSelected: 'rgba(75, 129, 90, 1)',
  ColorComponentCalendarStartEndBackgroundSelected: 'rgba(64, 114, 77, 1)',
  ColorButtonGhostReverseText: 'rgba(255, 255, 255, 1)',
  ColorButtonGhostReverseTextHover: 'rgba(242, 242, 242, 1)',
  ColorButtonGhostTertiaryText: 'rgba(102, 102, 102, 1)',
  ColorButtonGhostTertiaryTextHover: 'rgba(77, 77, 77, 1)',
  ColorButtonGhostSecondaryText: 'rgba(86, 117, 143, 1)',
  ColorButtonGhostSecondaryTextHover: 'rgba(64, 87, 114, 1)',
  ColorButtonGhostPrimaryText: 'rgba(75, 129, 90, 1)',
  ColorButtonGhostPrimaryTextHover: 'rgba(64, 114, 77, 1)',
  ColorButtonOutlineReverseText: 'rgba(255, 255, 255, 1)',
  ColorButtonOutlineReverseTextHover: 'rgba(247, 247, 247, 1)',
  ColorButtonOutlineReverseBorder: 'rgba(255, 255, 255, 1)',
  ColorButtonOutlineReverseBorderHover: 'rgba(245, 245, 245, 1)',
  ColorButtonOutlineTertiaryText: 'rgba(102, 102, 102, 1)',
  ColorButtonOutlineTertiaryTextHover: 'rgba(77, 77, 77, 1)',
  ColorButtonOutlineTertiaryBorder: 'rgba(204, 204, 204, 1)',
  ColorButtonOutlineTertiaryBorderHover: 'rgba(179, 179, 179, 1)',
  ColorButtonOutlineSecondaryText: 'rgba(86, 117, 143, 1)',
  ColorButtonOutlineSecondaryTextHover: 'rgba(64, 87, 114, 1)',
  ColorButtonOutlineSecondaryBorder: 'rgba(86, 117, 143, 1)',
  ColorButtonOutlineSecondaryBorderHover: 'rgba(64, 87, 114, 1)',
  ColorButtonOutlinePrimaryText: 'rgba(75, 129, 90, 1)',
  ColorButtonOutlinePrimaryTextHover: 'rgba(64, 114, 77, 1)',
  ColorButtonOutlinePrimaryBorder: 'rgba(75, 129, 90, 1)',
  ColorButtonOutlinePrimaryBorderHover: 'rgba(64, 114, 77, 1)',
  ColorButtonSolidReverseText: 'rgba(77, 77, 77, 1)',
  ColorButtonSolidReverseBackground: 'rgba(255, 255, 255, 1)',
  ColorButtonSolidReverseBackgroundHover: 'rgba(242, 242, 242, 1)',
  ColorButtonSolidTertiaryText: 'rgba(73, 100, 126, 1)',
  ColorButtonSolidTertiaryBackground: 'rgba(242, 242, 242, 1)',
  ColorButtonSolidTertiaryBackgroundHover: 'rgba(230, 230, 230, 1)',
  ColorButtonSolidSecondaryText: 'rgba(255, 255, 255, 1)',
  ColorButtonSolidSecondaryBackground: 'rgba(86, 117, 143, 1)',
  ColorButtonSolidSecondaryBackgroundHover: 'rgba(64, 87, 114, 1)',
  ColorButtonSolidPrimaryText: 'rgba(255, 255, 255, 1)',
  ColorButtonSolidPrimaryBackground: 'rgba(75, 129, 90, 1)',
  ColorButtonSolidPrimaryBackgroundHover: 'rgba(64, 114, 77, 1)',
  ColorTextBodyOnDark: 'rgba(255, 255, 255, 1)',
  ColorTextBodyOnLight: 'rgba(51, 51, 51, 1)',
  ColorTextBodyOnWhite: 'rgba(51, 51, 51, 1)',
  ColorTextHeadingOnWhite: 'rgba(26, 26, 26, 1)',
  ColorTextHeadingOnLight: 'rgba(26, 26, 26, 1)',
  ColorTextHeadingOnDark: 'rgba(255, 255, 255, 1)',
  GradientGradientBlackTop100GradientType: 'linear',
  GradientGradientBlackTop100Rotation: 180,
  GradientGradientBlackTop100Stops0Position: 0,
  GradientGradientBlackTop100Stops0Color: 'rgba(0, 0, 0, 1)',
  GradientGradientBlackTop100Stops1Position: 1,
  GradientGradientBlackTop100Stops1Color: 'rgba(0, 0, 0, 0)',
  GradientGradientBlackTop100Opacity: 1,
  GradientGradientBlackRight100GradientType: 'linear',
  GradientGradientBlackRight100Rotation: 90,
  GradientGradientBlackRight100Stops0Position: 0,
  GradientGradientBlackRight100Stops0Color: 'rgba(0, 0, 0, 0)',
  GradientGradientBlackRight100Stops1Position: 1,
  GradientGradientBlackRight100Stops1Color: 'rgba(0, 0, 0, 1)',
  GradientGradientBlackRight100Opacity: 1,
  GradientGradientBlackBottom100GradientType: 'linear',
  GradientGradientBlackBottom100Rotation: 180,
  GradientGradientBlackBottom100Stops0Position: 0,
  GradientGradientBlackBottom100Stops0Color: 'rgba(0, 0, 0, 0)',
  GradientGradientBlackBottom100Stops1Position: 1,
  GradientGradientBlackBottom100Stops1Color: 'rgba(0, 0, 0, 1)',
  GradientGradientBlackBottom100Opacity: 1,
  GradientGradientBlackLeft100GradientType: 'linear',
  GradientGradientBlackLeft100Rotation: 90,
  GradientGradientBlackLeft100Stops0Position: 0,
  GradientGradientBlackLeft100Stops0Color: 'rgba(0, 0, 0, 1)',
  GradientGradientBlackLeft100Stops1Position: 1,
  GradientGradientBlackLeft100Stops1Color: 'rgba(0, 0, 0, 0)',
  GradientGradientBlackLeft100Opacity: 1,
  GradientGradientWhiteTop100GradientType: 'linear',
  GradientGradientWhiteTop100Rotation: 180,
  GradientGradientWhiteTop100Stops0Position: 0,
  GradientGradientWhiteTop100Stops0Color: 'rgba(255, 255, 255, 1)',
  GradientGradientWhiteTop100Stops1Position: 1,
  GradientGradientWhiteTop100Stops1Color: 'rgba(255, 255, 255, 0)',
  GradientGradientWhiteTop100Opacity: 1,
  GradientGradientWhiteRight100GradientType: 'linear',
  GradientGradientWhiteRight100Rotation: 90,
  GradientGradientWhiteRight100Stops0Position: 0,
  GradientGradientWhiteRight100Stops0Color: 'rgba(255, 255, 255, 0)',
  GradientGradientWhiteRight100Stops1Position: 1,
  GradientGradientWhiteRight100Stops1Color: 'rgba(255, 255, 255, 1)',
  GradientGradientWhiteRight100Opacity: 1,
  GradientGradientWhiteLeft100GradientType: 'linear',
  GradientGradientWhiteLeft100Rotation: 90,
  GradientGradientWhiteLeft100Stops0Position: 0,
  GradientGradientWhiteLeft100Stops0Color: 'rgba(255, 255, 255, 1)',
  GradientGradientWhiteLeft100Stops1Position: 1,
  GradientGradientWhiteLeft100Stops1Color: 'rgba(255, 255, 255, 0)',
  GradientGradientWhiteLeft100Opacity: 1,
  GradientBrandNovasolRebrandOrangeGradientGradientType: 'linear',
  GradientBrandNovasolRebrandOrangeGradientRotation: 90,
  GradientBrandNovasolRebrandOrangeGradientStops0Position: 0,
  GradientBrandNovasolRebrandOrangeGradientStops0Color: 'rgba(227, 76, 54, 1)',
  GradientBrandNovasolRebrandOrangeGradientStops1Position: 0.36,
  GradientBrandNovasolRebrandOrangeGradientStops1Color: 'rgba(236, 90, 64, 1)',
  GradientBrandNovasolRebrandOrangeGradientStops2Position: 1,
  GradientBrandNovasolRebrandOrangeGradientStops2Color: 'rgba(249, 110, 80, 1)',
  GradientBrandNovasolRebrandOrangeGradientOpacity: 1,
  GradientBrandWhiteBottom100GradientType: 'linear',
  GradientBrandWhiteBottom100Rotation: 180,
  GradientBrandWhiteBottom100Stops0Position: 0,
  GradientBrandWhiteBottom100Stops0Color: 'rgba(255, 255, 255, 0)',
  GradientBrandWhiteBottom100Stops1Position: 1,
  GradientBrandWhiteBottom100Stops1Color: 'rgba(255, 255, 255, 1)',
  GradientBrandWhiteBottom100Opacity: 1,
  GridBaseGridSmall0Pattern: 'columns',
  GridBaseGridSmall0GutterSize: 16,
  GridBaseGridSmall0Alignment: 'stretch',
  GridBaseGridSmall0Count: 12,
  GridBaseGridSmall0Offset: 16,
  GridBaseGridSmall1Pattern: 'rows',
  GridBaseGridSmall1SectionSize: 8,
  GridBaseGridSmall1GutterSize: 0,
  GridBaseGridSmall1Alignment: 'min',
  GridBaseGridSmall1Count: 'auto',
  GridBaseGridSmall1Offset: 0,
  GridBaseGridMedium0Pattern: 'columns',
  GridBaseGridMedium0GutterSize: 24,
  GridBaseGridMedium0Alignment: 'stretch',
  GridBaseGridMedium0Count: 12,
  GridBaseGridMedium0Offset: 24,
  GridBaseGridMedium1Pattern: 'rows',
  GridBaseGridMedium1SectionSize: 8,
  GridBaseGridMedium1GutterSize: 0,
  GridBaseGridMedium1Alignment: 'min',
  GridBaseGridMedium1Count: 'auto',
  GridBaseGridMedium1Offset: 0,
  GridBaseGridLarge0Pattern: 'columns',
  GridBaseGridLarge0SectionSize: 78,
  GridBaseGridLarge0GutterSize: 24,
  GridBaseGridLarge0Alignment: 'center',
  GridBaseGridLarge0Count: 12,
  GridBaseGridLarge1Pattern: 'rows',
  GridBaseGridLarge1SectionSize: 8,
  GridBaseGridLarge1GutterSize: 0,
  GridBaseGridLarge1Alignment: 'min',
  GridBaseGridLarge1Count: 'auto',
  GridBaseGridLarge1Offset: 8,
  FontTextText100FontSize: 12,
  FontTextText100TextDecoration: 'none',
  FontTextText100FontFamily: 'DM Sans',
  FontTextText100FontWeight: 400,
  FontTextText100FontStyle: 'normal',
  FontTextText100FontStretch: 'normal',
  FontTextText100FontStyleOld: 'Regular',
  FontTextText100LetterSpacing: 0,
  FontTextText100LineHeight: 16,
  FontTextText100ParagraphIndent: 0,
  FontTextText100ParagraphSpacing: 0,
  FontTextText100TextCase: 'none',
  FontTextText300MidFontSize: 16,
  FontTextText300MidTextDecoration: 'none',
  FontTextText300MidFontFamily: 'DM Sans',
  FontTextText300MidFontWeight: 500,
  FontTextText300MidFontStyle: 'normal',
  FontTextText300MidFontStretch: 'normal',
  FontTextText300MidFontStyleOld: 'Medium',
  FontTextText300MidLetterSpacing: 0,
  FontTextText300MidLineHeight: 24,
  FontTextText300MidParagraphIndent: 0,
  FontTextText300MidParagraphSpacing: 0,
  FontTextText300MidTextCase: 'none',
  FontTextText300FontSize: 16,
  FontTextText300TextDecoration: 'none',
  FontTextText300FontFamily: 'DM Sans',
  FontTextText300FontWeight: 400,
  FontTextText300FontStyle: 'normal',
  FontTextText300FontStretch: 'normal',
  FontTextText300FontStyleOld: 'Regular',
  FontTextText300LetterSpacing: 0,
  FontTextText300LineHeight: 24,
  FontTextText300ParagraphIndent: 0,
  FontTextText300ParagraphSpacing: 0,
  FontTextText300TextCase: 'none',
  FontTextText200MidFontSize: 14,
  FontTextText200MidTextDecoration: 'none',
  FontTextText200MidFontFamily: 'DM Sans',
  FontTextText200MidFontWeight: 500,
  FontTextText200MidFontStyle: 'normal',
  FontTextText200MidFontStretch: 'normal',
  FontTextText200MidFontStyleOld: 'Medium',
  FontTextText200MidLetterSpacing: 0,
  FontTextText200MidLineHeight: 20,
  FontTextText200MidParagraphIndent: 0,
  FontTextText200MidParagraphSpacing: 0,
  FontTextText200MidTextCase: 'none',
  FontTextText300BoldFontSize: 16,
  FontTextText300BoldTextDecoration: 'none',
  FontTextText300BoldFontFamily: 'DM Sans',
  FontTextText300BoldFontWeight: 700,
  FontTextText300BoldFontStyle: 'normal',
  FontTextText300BoldFontStretch: 'normal',
  FontTextText300BoldFontStyleOld: 'Bold',
  FontTextText300BoldLetterSpacing: 0,
  FontTextText300BoldLineHeight: 24,
  FontTextText300BoldParagraphIndent: 0,
  FontTextText300BoldParagraphSpacing: 0,
  FontTextText300BoldTextCase: 'none',
  FontTextText500FontSize: 24,
  FontTextText500TextDecoration: 'none',
  FontTextText500FontFamily: 'DM Sans',
  FontTextText500FontWeight: 400,
  FontTextText500FontStyle: 'normal',
  FontTextText500FontStretch: 'normal',
  FontTextText500FontStyleOld: 'Regular',
  FontTextText500LetterSpacing: 0,
  FontTextText500LineHeight: 28,
  FontTextText500ParagraphIndent: 0,
  FontTextText500ParagraphSpacing: 0,
  FontTextText500TextCase: 'none',
  FontTextText500MidFontSize: 24,
  FontTextText500MidTextDecoration: 'none',
  FontTextText500MidFontFamily: 'DM Sans',
  FontTextText500MidFontWeight: 500,
  FontTextText500MidFontStyle: 'normal',
  FontTextText500MidFontStretch: 'normal',
  FontTextText500MidFontStyleOld: 'Medium',
  FontTextText500MidLetterSpacing: 0,
  FontTextText500MidLineHeight: 28,
  FontTextText500MidParagraphIndent: 0,
  FontTextText500MidParagraphSpacing: 0,
  FontTextText500MidTextCase: 'none',
  FontTextText500BoldFontSize: 24,
  FontTextText500BoldTextDecoration: 'none',
  FontTextText500BoldFontFamily: 'DM Sans',
  FontTextText500BoldFontWeight: 700,
  FontTextText500BoldFontStyle: 'normal',
  FontTextText500BoldFontStretch: 'normal',
  FontTextText500BoldFontStyleOld: 'Bold',
  FontTextText500BoldLetterSpacing: 0,
  FontTextText500BoldLineHeight: 28,
  FontTextText500BoldParagraphIndent: 0,
  FontTextText500BoldParagraphSpacing: 0,
  FontTextText500BoldTextCase: 'none',
  FontTextText400FontSize: 20,
  FontTextText400TextDecoration: 'none',
  FontTextText400FontFamily: 'DM Sans',
  FontTextText400FontWeight: 400,
  FontTextText400FontStyle: 'normal',
  FontTextText400FontStretch: 'normal',
  FontTextText400FontStyleOld: 'Regular',
  FontTextText400LetterSpacing: 0,
  FontTextText400LineHeight: 28,
  FontTextText400ParagraphIndent: 0,
  FontTextText400ParagraphSpacing: 0,
  FontTextText400TextCase: 'none',
  FontTextText400MidFontSize: 20,
  FontTextText400MidTextDecoration: 'none',
  FontTextText400MidFontFamily: 'DM Sans',
  FontTextText400MidFontWeight: 500,
  FontTextText400MidFontStyle: 'normal',
  FontTextText400MidFontStretch: 'normal',
  FontTextText400MidFontStyleOld: 'Medium',
  FontTextText400MidLetterSpacing: 0,
  FontTextText400MidLineHeight: 28,
  FontTextText400MidParagraphIndent: 0,
  FontTextText400MidParagraphSpacing: 0,
  FontTextText400MidTextCase: 'none',
  FontTextText400BoldFontSize: 20,
  FontTextText400BoldTextDecoration: 'none',
  FontTextText400BoldFontFamily: 'DM Sans',
  FontTextText400BoldFontWeight: 700,
  FontTextText400BoldFontStyle: 'normal',
  FontTextText400BoldFontStretch: 'normal',
  FontTextText400BoldFontStyleOld: 'Bold',
  FontTextText400BoldLetterSpacing: 0,
  FontTextText400BoldLineHeight: 28,
  FontTextText400BoldParagraphIndent: 0,
  FontTextText400BoldParagraphSpacing: 0,
  FontTextText400BoldTextCase: 'none',
  FontTextText300LinkFontSize: 16,
  FontTextText300LinkTextDecoration: 'underline',
  FontTextText300LinkFontFamily: 'DM Sans',
  FontTextText300LinkFontWeight: 500,
  FontTextText300LinkFontStyle: 'normal',
  FontTextText300LinkFontStretch: 'normal',
  FontTextText300LinkFontStyleOld: 'Medium',
  FontTextText300LinkLetterSpacing: 0,
  FontTextText300LinkLineHeight: 24,
  FontTextText300LinkParagraphIndent: 0,
  FontTextText300LinkParagraphSpacing: 0,
  FontTextText300LinkTextCase: 'none',
  FontTextText200FontSize: 14,
  FontTextText200TextDecoration: 'none',
  FontTextText200FontFamily: 'DM Sans',
  FontTextText200FontWeight: 400,
  FontTextText200FontStyle: 'normal',
  FontTextText200FontStretch: 'normal',
  FontTextText200FontStyleOld: 'Regular',
  FontTextText200LetterSpacing: 0,
  FontTextText200LineHeight: 20,
  FontTextText200ParagraphIndent: 0,
  FontTextText200ParagraphSpacing: 0,
  FontTextText200TextCase: 'none',
  FontTextText200LinkFontSize: 14,
  FontTextText200LinkTextDecoration: 'underline',
  FontTextText200LinkFontFamily: 'DM Sans',
  FontTextText200LinkFontWeight: 500,
  FontTextText200LinkFontStyle: 'normal',
  FontTextText200LinkFontStretch: 'normal',
  FontTextText200LinkFontStyleOld: 'Medium',
  FontTextText200LinkLetterSpacing: 0,
  FontTextText200LinkLineHeight: 20,
  FontTextText200LinkParagraphIndent: 0,
  FontTextText200LinkParagraphSpacing: 0,
  FontTextText200LinkTextCase: 'none',
  FontTextText200BoldFontSize: 14,
  FontTextText200BoldTextDecoration: 'none',
  FontTextText200BoldFontFamily: 'DM Sans',
  FontTextText200BoldFontWeight: 700,
  FontTextText200BoldFontStyle: 'normal',
  FontTextText200BoldFontStretch: 'normal',
  FontTextText200BoldFontStyleOld: 'Bold',
  FontTextText200BoldLetterSpacing: 0,
  FontTextText200BoldLineHeight: 20,
  FontTextText200BoldParagraphIndent: 0,
  FontTextText200BoldParagraphSpacing: 0,
  FontTextText200BoldTextCase: 'none',
  FontTextText100LinkFontSize: 12,
  FontTextText100LinkTextDecoration: 'underline',
  FontTextText100LinkFontFamily: 'DM Sans',
  FontTextText100LinkFontWeight: 500,
  FontTextText100LinkFontStyle: 'normal',
  FontTextText100LinkFontStretch: 'normal',
  FontTextText100LinkFontStyleOld: 'Medium',
  FontTextText100LinkLetterSpacing: 0,
  FontTextText100LinkLineHeight: 16,
  FontTextText100LinkParagraphIndent: 0,
  FontTextText100LinkParagraphSpacing: 0,
  FontTextText100LinkTextCase: 'none',
  FontTextText100MidFontSize: 12,
  FontTextText100MidTextDecoration: 'none',
  FontTextText100MidFontFamily: 'DM Sans',
  FontTextText100MidFontWeight: 500,
  FontTextText100MidFontStyle: 'normal',
  FontTextText100MidFontStretch: 'normal',
  FontTextText100MidFontStyleOld: 'Medium',
  FontTextText100MidLetterSpacing: 0,
  FontTextText100MidLineHeight: 16,
  FontTextText100MidParagraphIndent: 0,
  FontTextText100MidParagraphSpacing: 0,
  FontTextText100MidTextCase: 'none',
  FontTextText100BoldFontSize: 12,
  FontTextText100BoldTextDecoration: 'none',
  FontTextText100BoldFontFamily: 'DM Sans',
  FontTextText100BoldFontWeight: 700,
  FontTextText100BoldFontStyle: 'normal',
  FontTextText100BoldFontStretch: 'normal',
  FontTextText100BoldFontStyleOld: 'Bold',
  FontTextText100BoldLetterSpacing: 0,
  FontTextText100BoldLineHeight: 16,
  FontTextText100BoldParagraphIndent: 0,
  FontTextText100BoldParagraphSpacing: 0,
  FontTextText100BoldTextCase: 'none',
  FontHeadingHeading100FontSize: 20,
  FontHeadingHeading100TextDecoration: 'none',
  FontHeadingHeading100FontFamily: 'Poppins',
  FontHeadingHeading100FontWeight: 600,
  FontHeadingHeading100FontStyle: 'normal',
  FontHeadingHeading100FontStretch: 'normal',
  FontHeadingHeading100FontStyleOld: 'SemiBold',
  FontHeadingHeading100LetterSpacing: 0,
  FontHeadingHeading100LineHeight: 28,
  FontHeadingHeading100ParagraphIndent: 0,
  FontHeadingHeading100ParagraphSpacing: 0,
  FontHeadingHeading100TextCase: 'none',
  FontHeadingHeading200FontSize: 24,
  FontHeadingHeading200TextDecoration: 'none',
  FontHeadingHeading200FontFamily: 'Poppins',
  FontHeadingHeading200FontWeight: 600,
  FontHeadingHeading200FontStyle: 'normal',
  FontHeadingHeading200FontStretch: 'normal',
  FontHeadingHeading200FontStyleOld: 'SemiBold',
  FontHeadingHeading200LetterSpacing: 0,
  FontHeadingHeading200LineHeight: 28,
  FontHeadingHeading200ParagraphIndent: 0,
  FontHeadingHeading200ParagraphSpacing: 0,
  FontHeadingHeading200TextCase: 'none',
  FontHeadingHeading300FontSize: 28,
  FontHeadingHeading300TextDecoration: 'none',
  FontHeadingHeading300FontFamily: 'Poppins',
  FontHeadingHeading300FontWeight: 600,
  FontHeadingHeading300FontStyle: 'normal',
  FontHeadingHeading300FontStretch: 'normal',
  FontHeadingHeading300FontStyleOld: 'SemiBold',
  FontHeadingHeading300LetterSpacing: 0,
  FontHeadingHeading300LineHeight: 32,
  FontHeadingHeading300ParagraphIndent: 0,
  FontHeadingHeading300ParagraphSpacing: 0,
  FontHeadingHeading300TextCase: 'none',
  FontHeadingHeading400FontSize: 32,
  FontHeadingHeading400TextDecoration: 'none',
  FontHeadingHeading400FontFamily: 'Poppins',
  FontHeadingHeading400FontWeight: 600,
  FontHeadingHeading400FontStyle: 'normal',
  FontHeadingHeading400FontStretch: 'normal',
  FontHeadingHeading400FontStyleOld: 'SemiBold',
  FontHeadingHeading400LetterSpacing: 0,
  FontHeadingHeading400LineHeight: 36,
  FontHeadingHeading400ParagraphIndent: 0,
  FontHeadingHeading400ParagraphSpacing: 0,
  FontHeadingHeading400TextCase: 'none',
  FontHeadingHeading500FontSize: 40,
  FontHeadingHeading500TextDecoration: 'none',
  FontHeadingHeading500FontFamily: 'Poppins',
  FontHeadingHeading500FontWeight: 600,
  FontHeadingHeading500FontStyle: 'normal',
  FontHeadingHeading500FontStretch: 'normal',
  FontHeadingHeading500FontStyleOld: 'SemiBold',
  FontHeadingHeading500LetterSpacing: 0,
  FontHeadingHeading500LineHeight: 48,
  FontHeadingHeading500ParagraphIndent: 0,
  FontHeadingHeading500ParagraphSpacing: 0,
  FontHeadingHeading500TextCase: 'none',
  FontHeadingHeading600FontSize: 56,
  FontHeadingHeading600TextDecoration: 'none',
  FontHeadingHeading600FontFamily: 'Poppins',
  FontHeadingHeading600FontWeight: 600,
  FontHeadingHeading600FontStyle: 'normal',
  FontHeadingHeading600FontStretch: 'normal',
  FontHeadingHeading600FontStyleOld: 'SemiBold',
  FontHeadingHeading600LetterSpacing: 0,
  FontHeadingHeading600LineHeight: 56,
  FontHeadingHeading600ParagraphIndent: 0,
  FontHeadingHeading600ParagraphSpacing: 0,
  FontHeadingHeading600TextCase: 'none',
  EffectElevation3Bottom0Type: 'dropShadow',
  EffectElevation3Bottom0Radius: 6,
  EffectElevation3Bottom0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation3Bottom0OffsetX: 0,
  EffectElevation3Bottom0OffsetY: 3,
  EffectElevation3Bottom0Spread: 0,
  EffectElevation3Bottom1Type: 'dropShadow',
  EffectElevation3Bottom1Radius: 0.800000011920929,
  EffectElevation3Bottom1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation3Bottom1OffsetX: 0,
  EffectElevation3Bottom1OffsetY: 0.4000000059604645,
  EffectElevation3Bottom1Spread: 0,
  EffectElevation30Type: 'dropShadow',
  EffectElevation30Radius: 6,
  EffectElevation30Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation30OffsetX: 0,
  EffectElevation30OffsetY: 3,
  EffectElevation30Spread: 0,
  EffectElevation31Type: 'dropShadow',
  EffectElevation31Radius: 0.800000011920929,
  EffectElevation31Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation31OffsetX: 0,
  EffectElevation31OffsetY: 0.4000000059604645,
  EffectElevation31Spread: 0,
  EffectElevation2Bottom0Type: 'dropShadow',
  EffectElevation2Bottom0Radius: 4,
  EffectElevation2Bottom0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation2Bottom0OffsetX: 0,
  EffectElevation2Bottom0OffsetY: 2,
  EffectElevation2Bottom0Spread: 0,
  EffectElevation2Bottom1Type: 'dropShadow',
  EffectElevation2Bottom1Radius: 0.5,
  EffectElevation2Bottom1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation2Bottom1OffsetX: 0,
  EffectElevation2Bottom1OffsetY: 0.30000001192092896,
  EffectElevation2Bottom1Spread: 0,
  EffectElevation8Bottom0Type: 'dropShadow',
  EffectElevation8Bottom0Radius: 16,
  EffectElevation8Bottom0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation8Bottom0OffsetX: 0,
  EffectElevation8Bottom0OffsetY: 8,
  EffectElevation8Bottom0Spread: 0,
  EffectElevation8Bottom1Type: 'dropShadow',
  EffectElevation8Bottom1Radius: 2,
  EffectElevation8Bottom1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation8Bottom1OffsetX: 0,
  EffectElevation8Bottom1OffsetY: 1,
  EffectElevation8Bottom1Spread: 0,
  EffectElevation20Type: 'dropShadow',
  EffectElevation20Radius: 4,
  EffectElevation20Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation20OffsetX: 0,
  EffectElevation20OffsetY: 2,
  EffectElevation20Spread: 0,
  EffectElevation21Type: 'dropShadow',
  EffectElevation21Radius: 0.5,
  EffectElevation21Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation21OffsetX: 0,
  EffectElevation21OffsetY: 0.30000001192092896,
  EffectElevation21Spread: 0,
  EffectElevation6Bottom0Type: 'dropShadow',
  EffectElevation6Bottom0Radius: 12,
  EffectElevation6Bottom0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation6Bottom0OffsetX: 0,
  EffectElevation6Bottom0OffsetY: 6,
  EffectElevation6Bottom0Spread: 0,
  EffectElevation6Bottom1Type: 'dropShadow',
  EffectElevation6Bottom1Radius: 1.5,
  EffectElevation6Bottom1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation6Bottom1OffsetX: 0,
  EffectElevation6Bottom1OffsetY: 0.800000011920929,
  EffectElevation6Bottom1Spread: 0,
  EffectElevation1Bottom0Type: 'dropShadow',
  EffectElevation1Bottom0Radius: 2,
  EffectElevation1Bottom0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation1Bottom0OffsetX: 0,
  EffectElevation1Bottom0OffsetY: 1,
  EffectElevation1Bottom0Spread: 0,
  EffectElevation1Bottom1Type: 'dropShadow',
  EffectElevation1Bottom1Radius: 0.30000001192092896,
  EffectElevation1Bottom1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation1Bottom1OffsetX: 0,
  EffectElevation1Bottom1OffsetY: 0.10000000149011612,
  EffectElevation1Bottom1Spread: 0,
  EffectElevation4Bottom0Type: 'dropShadow',
  EffectElevation4Bottom0Radius: 8,
  EffectElevation4Bottom0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation4Bottom0OffsetX: 0,
  EffectElevation4Bottom0OffsetY: 4,
  EffectElevation4Bottom0Spread: 0,
  EffectElevation4Bottom1Type: 'dropShadow',
  EffectElevation4Bottom1Radius: 1,
  EffectElevation4Bottom1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation4Bottom1OffsetX: 0,
  EffectElevation4Bottom1OffsetY: 0.5,
  EffectElevation4Bottom1Spread: 0,
  EffectElevation12Bottom0Type: 'dropShadow',
  EffectElevation12Bottom0Radius: 24,
  EffectElevation12Bottom0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation12Bottom0OffsetX: 0,
  EffectElevation12Bottom0OffsetY: 12,
  EffectElevation12Bottom0Spread: 0,
  EffectElevation12Bottom1Type: 'dropShadow',
  EffectElevation12Bottom1Radius: 3,
  EffectElevation12Bottom1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation12Bottom1OffsetX: 0,
  EffectElevation12Bottom1OffsetY: 1.5,
  EffectElevation12Bottom1Spread: 0,
  EffectElevation16Bottom0Type: 'dropShadow',
  EffectElevation16Bottom0Radius: 32,
  EffectElevation16Bottom0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation16Bottom0OffsetX: 0,
  EffectElevation16Bottom0OffsetY: 16,
  EffectElevation16Bottom0Spread: 0,
  EffectElevation16Bottom1Type: 'dropShadow',
  EffectElevation16Bottom1Radius: 4,
  EffectElevation16Bottom1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation16Bottom1OffsetX: 0,
  EffectElevation16Bottom1OffsetY: 2,
  EffectElevation16Bottom1Spread: 0,
  EffectElevation24Bottom0Type: 'dropShadow',
  EffectElevation24Bottom0Radius: 48,
  EffectElevation24Bottom0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation24Bottom0OffsetX: 0,
  EffectElevation24Bottom0OffsetY: 24,
  EffectElevation24Bottom0Spread: 0,
  EffectElevation24Bottom1Type: 'dropShadow',
  EffectElevation24Bottom1Radius: 6,
  EffectElevation24Bottom1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation24Bottom1OffsetX: 0,
  EffectElevation24Bottom1OffsetY: 3,
  EffectElevation24Bottom1Spread: 0,
  EffectElevation1Top0Type: 'dropShadow',
  EffectElevation1Top0Radius: 2,
  EffectElevation1Top0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation1Top0OffsetX: 0,
  EffectElevation1Top0OffsetY: -1,
  EffectElevation1Top0Spread: 0,
  EffectElevation1Top1Type: 'dropShadow',
  EffectElevation1Top1Radius: 0.30000001192092896,
  EffectElevation1Top1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation1Top1OffsetX: 0,
  EffectElevation1Top1OffsetY: -0.10000000149011612,
  EffectElevation1Top1Spread: 0,
  EffectElevation2Top0Type: 'dropShadow',
  EffectElevation2Top0Radius: 4,
  EffectElevation2Top0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation2Top0OffsetX: 0,
  EffectElevation2Top0OffsetY: -2,
  EffectElevation2Top0Spread: 0,
  EffectElevation2Top1Type: 'dropShadow',
  EffectElevation2Top1Radius: 0.5,
  EffectElevation2Top1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation2Top1OffsetX: 0,
  EffectElevation2Top1OffsetY: -0.30000001192092896,
  EffectElevation2Top1Spread: 0,
  EffectElevation3Top0Type: 'dropShadow',
  EffectElevation3Top0Radius: 6,
  EffectElevation3Top0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation3Top0OffsetX: 0,
  EffectElevation3Top0OffsetY: -3,
  EffectElevation3Top0Spread: 0,
  EffectElevation3Top1Type: 'dropShadow',
  EffectElevation3Top1Radius: 0.800000011920929,
  EffectElevation3Top1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation3Top1OffsetX: 0,
  EffectElevation3Top1OffsetY: -0.4000000059604645,
  EffectElevation3Top1Spread: 0,
  EffectElevation4Top0Type: 'dropShadow',
  EffectElevation4Top0Radius: 8,
  EffectElevation4Top0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation4Top0OffsetX: 0,
  EffectElevation4Top0OffsetY: -4,
  EffectElevation4Top0Spread: 0,
  EffectElevation4Top1Type: 'dropShadow',
  EffectElevation4Top1Radius: 1,
  EffectElevation4Top1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation4Top1OffsetX: 0,
  EffectElevation4Top1OffsetY: -0.5,
  EffectElevation4Top1Spread: 0,
  EffectElevation6Top0Type: 'dropShadow',
  EffectElevation6Top0Radius: 12,
  EffectElevation6Top0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation6Top0OffsetX: 0,
  EffectElevation6Top0OffsetY: -6,
  EffectElevation6Top0Spread: 0,
  EffectElevation6Top1Type: 'dropShadow',
  EffectElevation6Top1Radius: 1.5,
  EffectElevation6Top1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation6Top1OffsetX: 0,
  EffectElevation6Top1OffsetY: -0.800000011920929,
  EffectElevation6Top1Spread: 0,
  EffectElevation8Top0Type: 'dropShadow',
  EffectElevation8Top0Radius: 16,
  EffectElevation8Top0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation8Top0OffsetX: 0,
  EffectElevation8Top0OffsetY: -8,
  EffectElevation8Top0Spread: 0,
  EffectElevation8Top1Type: 'dropShadow',
  EffectElevation8Top1Radius: 2,
  EffectElevation8Top1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation8Top1OffsetX: 0,
  EffectElevation8Top1OffsetY: -1,
  EffectElevation8Top1Spread: 0,
  EffectElevation12Top0Type: 'dropShadow',
  EffectElevation12Top0Radius: 24,
  EffectElevation12Top0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation12Top0OffsetX: 0,
  EffectElevation12Top0OffsetY: -12,
  EffectElevation12Top0Spread: 0,
  EffectElevation12Top1Type: 'dropShadow',
  EffectElevation12Top1Radius: 3,
  EffectElevation12Top1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation12Top1OffsetX: 0,
  EffectElevation12Top1OffsetY: -1.5,
  EffectElevation12Top1Spread: 0,
  EffectElevation16Top0Type: 'dropShadow',
  EffectElevation16Top0Radius: 32,
  EffectElevation16Top0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation16Top0OffsetX: 0,
  EffectElevation16Top0OffsetY: -16,
  EffectElevation16Top0Spread: 0,
  EffectElevation16Top1Type: 'dropShadow',
  EffectElevation16Top1Radius: 4,
  EffectElevation16Top1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation16Top1OffsetX: 0,
  EffectElevation16Top1OffsetY: -2,
  EffectElevation16Top1Spread: 0,
  EffectElevation24Top0Type: 'dropShadow',
  EffectElevation24Top0Radius: 48,
  EffectElevation24Top0Color: 'rgba(0, 0, 0, 0.2)',
  EffectElevation24Top0OffsetX: 0,
  EffectElevation24Top0OffsetY: -24,
  EffectElevation24Top0Spread: 0,
  EffectElevation24Top1Type: 'dropShadow',
  EffectElevation24Top1Radius: 6,
  EffectElevation24Top1Color: 'rgba(0, 0, 0, 0.1)',
  EffectElevation24Top1OffsetX: 0,
  EffectElevation24Top1OffsetY: -3,
  EffectElevation24Top1Spread: 0,
  EffectDividerBottom1Type: 'dropShadow',
  EffectDividerBottom1Radius: 0,
  EffectDividerBottom1Color: 'rgba(0, 0, 0, 0.09)',
  EffectDividerBottom1OffsetX: 0,
  EffectDividerBottom1OffsetY: 1,
  EffectDividerBottom1Spread: 0,
  EffectDividerTopType: 'dropShadow',
  EffectDividerTopRadius: 0,
  EffectDividerTopColor: 'rgba(0, 0, 0, 0.09)',
  EffectDividerTopOffsetX: 0,
  EffectDividerTopOffsetY: -1,
  EffectDividerTopSpread: 0,
  EffectDividerRightType: 'dropShadow',
  EffectDividerRightRadius: 0,
  EffectDividerRightColor: 'rgba(0, 0, 0, 0.09)',
  EffectDividerRightOffsetX: 1,
  EffectDividerRightOffsetY: 0,
  EffectDividerRightSpread: 0,
  EffectDividerLeftType: 'dropShadow',
  EffectDividerLeftRadius: 0,
  EffectDividerLeftColor: 'rgba(0, 0, 0, 0.09)',
  EffectDividerLeftOffsetX: -1,
  EffectDividerLeftOffsetY: 0,
  EffectDividerLeftSpread: 0
}

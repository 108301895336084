import React, { useEffect, useState } from 'react'
import { BookedModal, CancelledBookingModal } from '@/components/__Modals'
import PropTypes from 'prop-types'
import { useBookingChartBookingsContext } from '../context/Bookings/BookingChartBookingsProvider'
import { useBookingChartContext } from '../context/BookingChartProvider'
import { hasAuth } from '@/helpers/authorisations'
import toast from '@/helpers/toast'
import { useQueryBookingSearch } from '@/hooks/useQuery'

const BookingChartModalContainer = ({
  bookingId,
  setBookingByIdParameters,
  cancelledBookingId,
  setCancelledBookingId,
  cancelledPropertyAndUnit,
  setCancelledPropertyAndUnit
}) => {
  const { setPerformBookingSearch } = useBookingChartBookingsContext()
  const { permissions } = useBookingChartContext()
  const [cancelledBookingData, setCancelledBookingData] = useState(null)

  const { data: booking, isLoading: loadingBooking, isError, error } = useQueryBookingSearch(bookingId ? String(bookingId) : null)

  const closingModal = () => {
    setPerformBookingSearch()
    setTimeout(() => {
      setCancelledBookingId(null)
      setCancelledPropertyAndUnit(null)
      setCancelledBookingData(null)
    }, 500)
  }

  useEffect(() => {
    if (isError) {
      toast.error('Failed to load booking', null, error)
    }
  }, [isError, error])

  useEffect(() => {
    if (booking) {
      setCancelledBookingData(booking)
    }
  }, [booking])

  if (bookingId === undefined && cancelledBookingId === null) {
    return <></>
  }

  if (cancelledBookingId === null) {
    return (
      <BookedModal
        loading={loadingBooking}
        data={{ bookingId: bookingId, ...booking }}
        onClose={() => setBookingByIdParameters(null)}
        canCancel={hasAuth('cancelBooking', permissions)}
        onCancel={id => {
          setCancelledBookingId(id)
          setCancelledPropertyAndUnit({
            propertyId: booking.propertyId,
            propertyName: booking.propertyName,
            unitCode: booking.unitCode
          })
        }}
      />
    )
  }

  if (cancelledBookingId) {
    return (
      <CancelledBookingModal
        data={{
          bookingId: cancelledBookingId,
          ...cancelledBookingData,
          propertyId: cancelledPropertyAndUnit.propertyId,
          propertyName: cancelledPropertyAndUnit.propertyName,
          unitCode: cancelledPropertyAndUnit.unitCode
        }}
        onClose={() => {
          closingModal()
        }}
        onConfirm={() => { }}
        attributesToNotShow={[]}
      />
    )
  }
}

BookingChartModalContainer.propTypes = {
  bookingId: PropTypes.string,
  propertyId: PropTypes.string,
  cancelledPropertyAndUnit: PropTypes.object,
  propertyName: PropTypes.string,
  setBookingByIdParameters: PropTypes.func,
  cancelledBookingId: PropTypes.string,
  setCancelledBookingId: PropTypes.func,
  setCancelledPropertyAndUnit: PropTypes.func
}

export default BookingChartModalContainer

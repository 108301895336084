import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { PageWrapper, LoadingSpinner } from '@/components/__UI';
import { redirectTo, hasUrlParam } from '@/helpers/redirect/redirect';
import { useTranslation } from 'react-i18next';

function NotFoundPage({ redirectPath, brand }) {
  const { t } = useTranslation();
  const location = useLocation();

  const parsedRedirectPath = redirectPath || '/';
  const redirectToParam = new URLSearchParams(location.search).get('redirectTo');
  const updatedRedirectPath = redirectToParam
    ? `${parsedRedirectPath}?redirectTo=${redirectToParam}`
    : parsedRedirectPath;

  const shouldRedirect = brand === 'novasol';
  const isAlreadyRedirected = hasUrlParam('isRedirect')
  const willRedirect = shouldRedirect && !isAlreadyRedirected

  useEffect(() => {
    if (willRedirect) {
      redirectTo(window.location.href);
    }
  }, [willRedirect]);

  if (shouldRedirect) {
    if (!willRedirect) {
      return (
        <PageWrapper>
          <p data-testid="pageNotFoundError">{t('pageNotFoundMessage')}</p>
        </PageWrapper>
      )
    }

    return (
      <PageWrapper>
        <LoadingSpinner />
      </PageWrapper>
    )
  }

  return <Navigate to={updatedRedirectPath} replace />;
}

NotFoundPage.propTypes = {
  redirectPath: PropTypes.string.isRequired,
  brand: PropTypes.string,
};

export default NotFoundPage;

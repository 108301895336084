import React, { ReactNode } from 'react'
import Switch, { ReactSwitchProps } from 'react-switch'
import { Text } from '@awaze/design-system.components.text'
import { SwitchContainer } from './Switch.styles'

interface SwitcherProps extends ReactSwitchProps {
  label: ReactNode;
}

const Switcher = ({ label, onChange, checked, ...rest }: SwitcherProps) => {
  return (
    <SwitchContainer>
      <Switch
        data-testid='toggle'
        onChange={onChange}
        checked={checked}
        offColor="#CCCCCC"
        onColor="#4B815A"
        activeBoxShadow="#CCCCCC"
        width={35}
        height={18}
        checkedIcon={false}
        uncheckedIcon={false}
        {...rest}
      />
      <Text sizes={200}>{label}</Text>
    </SwitchContainer>
  )
}

export default Switcher

import DesignTokens from '@/global/DesignTokens'
import styled from 'styled-components'

const Divider = styled.hr`
  ${({ theme, size = 0 }) => `
    width: 100%;
    height: 1px;
    margin-top: ${theme.space[size]};
    margin-bottom: ${theme.space[size]};
    background-color: ${DesignTokens.ColorGrey100};
  `}
`

Divider.displayName = 'Divider'

export default Divider

import { getLocale } from '@/helpers/locale';
import {
  GooglePlayBadgeEn,
  GooglePlayBadgeDa,
  GooglePlayBadgeDe,
  GooglePlayBadgeEs,
  GooglePlayBadgeFr,
  GooglePlayBadgeHr,
  GooglePlayBadgeIt,
  GooglePlayBadgeNb,
  GooglePlayBadgeNl,
  GooglePlayBadgePl,
  GooglePlayBadgeSl,
  GooglePlayBadgeSv,
} from '@/components/__UI/Footer/GooglePlayBadges';

export const getGooglePlayBadgeLocalized = () => {
  const locale = getLocale();

  switch (locale.toLowerCase()) {
    case 'en':
    case 'en-gb':
      return GooglePlayBadgeEn;
    case 'da':
      return GooglePlayBadgeDa;
    case 'de':
      return GooglePlayBadgeDe;
      case 'es':
      return GooglePlayBadgeEs;
    case 'fr':
      return GooglePlayBadgeFr;
    case 'hr':
      return GooglePlayBadgeHr;
    case 'it':
      return GooglePlayBadgeIt;
    case 'nb':
      return GooglePlayBadgeNb;
    case 'nl':
      return GooglePlayBadgeNl;
    case 'pl':
      return GooglePlayBadgePl;
    case 'sl':
      return GooglePlayBadgeSl;
    case 'sv':
      return GooglePlayBadgeSv;
    default:
      throw new Error('Unsupported locale');
  }
};

export const getGooglePlayUrlsLocalized = () => {
    const locale = getLocale();
  
    switch (locale.toLowerCase()) {
      case 'en':
      case 'en-gb':
        return 'https://play.google.com/store/apps/details?id=com.awaze.awaze_owner_app&hl=en';
      case 'da':
        return 'https://play.google.com/store/apps/details?id=com.awaze.awaze_owner_app&hl=da';
      case 'de':
        return 'https://play.google.com/store/apps/details?id=com.awaze.awaze_owner_app&hl=de';
        case 'es':
        return 'https://play.google.com/store/apps/details?id=com.awaze.awaze_owner_app&hl=es';
      case 'fr':
        return 'https://play.google.com/store/apps/details?id=com.awaze.awaze_owner_app&hl=fr';
      case 'hr':
        return 'https://play.google.com/store/apps/details?id=com.awaze.awaze_owner_app&hl=hr';
      case 'it':
        return 'https://play.google.com/store/apps/details?id=com.awaze.awaze_owner_app&hl=it';
      case 'nb':
        return 'https://play.google.com/store/apps/details?id=com.awaze.awaze_owner_app&hl=nb';
      case 'nl':
        return 'https://play.google.com/store/apps/details?id=com.awaze.awaze_owner_app&hl=nl';
      case 'pl':
        return 'https://play.google.com/store/apps/details?id=com.awaze.awaze_owner_app&hl=pl';
      case 'sl':
        return 'https://play.google.com/store/apps/details?id=com.awaze.awaze_owner_app&hl=en'; // No Slovenian google play store
      case 'sv':
        return 'https://play.google.com/store/apps/details?id=com.awaze.awaze_owner_app&hl=sv';
      default:
        throw new Error('No links for this locale');
    }
  };
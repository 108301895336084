import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import DesignTokens from '@/global/DesignTokens'

const Container = styled.span`
    display: block;
    position: relative;
    width: fit-content;
    height: fit-content;

    .date-picker {
        .react-datepicker__header {
            border-bottom: none;
            background-color: ${({ theme }) => theme.colors.white};
        }
        .react-datepicker__current-month,.react-datepicker__day, 
        .react-datepicker__month-text, 
        .react-datepicker__quarter-text, 
        .react-datepicker__year-text,
        .react-datepicker__year-read-view--selected-year,
        .react-datepicker__day-name
        {
            font-family: 'DM-Sans', sans-serif !important;
        }
    }

    ${({ theme }) => `${theme.mediaQueries.tabletDownDS} {
           width: 100%;
    }`}
    
`

const StyledDatePicker = styled(DatePicker)`
    width: 100%;
    cursor: pointer;
    position: relative;
    padding: 0.8rem;
    min-width: 8rem;
    max-width: 10rem;
    font-size: 1rem;
    font-family: inherit;
    margin-bottom: 0 !important;
    color: ${({ theme }) => theme.colors.blackTwo};
    border: 1px solid ${DesignTokens.ColorGrey400};
    border-radius: ${({ theme }) => theme.borderRadius};
    background-color: ${({ isDisabled, theme }) => isDisabled ? theme.colors.datePickerBackground : theme.colors.white};

    &:focus {
        outline: none;
    }
`

const StyledDatePickerLoadingState = styled.div`
    position: relative;
    padding: 0.8rem;
    width: 9.106rem;
    margin-bottom: 0 !important;
    height: 48px;
    border: 1px solid ${DesignTokens.ColorGrey400};
    border-radius: ${({ theme }) => theme.borderRadius};
    background-color: ${({ isDisabled, theme }) => isDisabled ? theme.colors.datePickerBackground : theme.colors.white};

    &:focus {
        outline: none;
    }
`
const CalendarImage = styled.img`
    margin: 0.6rem;
    margin-left: 1rem;
    position: absolute;
    z-index: 1;
    @media screen and (max-width: 360px) {
        display: none;
    }
`

export { Container, StyledDatePicker, CalendarImage, StyledDatePickerLoadingState }


import React, { useState } from 'react'
import propTypes from 'prop-types'
import { LIST_BOOKINGS, LIST_ALL_PROPERTY_BOOKINGS, LIST_ALL_CHART_PROPERTY_BOOKINGS } from '@/hooks/useGraphQl'
import { useRefetchNotify } from '@/context/RefetchProvider/RefetchProvider'
import BookedModal from '@/components/__Modals/BookedModal/BookedModal'
import CancelledBookingModal from '@/components/__Modals/CancelledBookingModal/CancelledBookingModal'
import { hasAuth } from '@/helpers/authorisations'
import { useQueryUserPropertyEntitlements } from '@/hooks/useQuery'

const HeaderBookingSearch = ({ booking, onClose }) => {
  const [bookingToCancel, setBookingToCancel] = useState(null)
  const propertyId = booking?.propertyId ?? bookingToCancel?.propertyId

  const { data: authorisations, isLoading: authorisationsIsLoading } = useQueryUserPropertyEntitlements({ propertyId })

  const canCancel = hasAuth('cancelBooking', authorisations)

  const refetchBookings = useRefetchNotify(LIST_BOOKINGS, LIST_ALL_PROPERTY_BOOKINGS, LIST_ALL_CHART_PROPERTY_BOOKINGS, 'getBookings')

  return (
    <>
      {booking && (
        <BookedModal
          data={booking}
          canCancel={canCancel}
          loading={authorisationsIsLoading}
          onClose={onClose}
          onCancel={() => setBookingToCancel(booking)}
        />
      )}

      {bookingToCancel && <CancelledBookingModal
        data-testid='cancelledModal'
        data={{
          ...bookingToCancel,
          bookingId: bookingToCancel.id
        }}
        onClose={() => setBookingToCancel(null)}
        onConfirm={refetchBookings}
      />}
    </>
  )
}

HeaderBookingSearch.propTypes = {
  booking: propTypes.shape({
    propertyId: propTypes.string
  }),
  onClose: propTypes.func
}

export default HeaderBookingSearch

import React from 'react'
import { AcceptRejectTable } from './AcceptanceTable.styles'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const AcceptanceTable = ({ filteredProperties, setFilteredWithAccept }) => {
  const { t } = useTranslation()

  const onCheckHandler = (index, isChecked) => {
    const items = [...filteredProperties]
    items[index] = { ...items[index], isAccepted: isChecked }
    setFilteredWithAccept(items)
  }

  return (
    <AcceptRejectTable>
      <thead>
        <tr>
          <th>{t('accommodation')}</th>
          <th>{t('accept')}</th>
          <th>{t('reject')}</th>
        </tr>
      </thead>
      <tbody>
        {filteredProperties.map((item, index) => (
          <tr key={index}>
            <td className="label"><label>{item.propertyName}</label></td>
            <td className='radioBtn'>
              <input
                data-testid="accept"
                type="radio"
                name={'recommended' + index}
                value="accept"
                defaultChecked={item.isAccepted}
                onChange={() => onCheckHandler(index, true)}
              />
            </td>
            <td className="radioBtn">
              <input
                data-testid="reject"
                type="radio"
                name={'recommended' + index}
                value="reject"
                onChange={() => onCheckHandler(index, false)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </AcceptRejectTable>
  )
}

AcceptanceTable.propTypes = {
  filteredProperties: PropTypes.array,
  setFilteredWithAccept: PropTypes.func
}

export default AcceptanceTable

import styled, { css } from 'styled-components'

const CheckboxArea = styled.label`
    display: flex;
    margin: 1rem 0;

    .checkbox-inner {
        flex: 1;
        font-size: .8rem;
        
        span:first-of-type {
            color: ${({ theme, disabled }) => disabled ? theme.colors.disabled : theme.colors.black};
        }
    }
`

const checkedStyles = css`
    background-color: ${({ theme, color }) => color || theme.colors.secondary};

    &::before, &::after {
        height: 2px;
        content: " ";
        position: absolute;
        background-color: ${({ theme, tickColor }) => tickColor || theme.colors.white};
    }

    &::before {
        width: 8px;
        left: 3px;
        top: 13px;
        transform: rotate(45deg);
    }

    &::after {
        width: 12px;
        left: 8px;
        top: 10px;
        transform: rotate(-45deg);
    }
`

const StyledCheckbox = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 3px;
    position: relative;
    margin-right: .5rem;
    display: inline-block;
    transition: 0.2s all ease;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ checked, error, theme, borderColor }) => error ? theme.colors.error : checked ? borderColor || theme.colors.secondary : theme.colors.greyThree};
    cursor: pointer;
    ${({ checked }) => checked && checkedStyles}
`

const ErrorMessage = styled.span`
    display: block;
    color: ${({ theme }) => theme.colors.error};
`

export { CheckboxArea, StyledCheckbox, ErrorMessage }

import React, { useState, useEffect } from 'react'
import { PageWrapper } from '@/components/__UI'
import { QuestionArea, QuestionSelector } from '@/components/__ContactComponents'
import { isEnabled } from '@/constants/features'
import chat from './LiveChat'
import { useUser } from '@/context/UserProvider'

const ContactUsPage = () => {
  const [ question, setQuestion ] = useState(null)
  const [ disableSelect, setDisableSelect ] = useState(false)
  const { user } = useUser()

  const chatElement = document.querySelector('.genesys-app')
  const deploymentId = isEnabled('web-chat-test') ? '73645fe5-0b84-47fb-ac3f-05089f039224' : 'b12ae5c8-4aaf-49a3-9125-dfc66f3f4752'

  /* istanbul ignore next */
  if (chatElement && user.attributes) {
    chatElement.removeAttribute('hidden')
  } else {
    chat(
      window,
      'Genesys',
      'https://apps.euw2.pure.cloud/genesys-bootstrap/genesys.min.js',
      {
        environment: 'euw2',
        deploymentId
      },
      undefined,
      user.attributes.email
    )
  }

  useEffect(() => {
    return () => {
      const chatElement = document.querySelector('.genesys-app')
      const chatButtonElement = !!document.querySelector('.genesys-app .genesys-mxg-conversation')
      /* istanbul ignore next */
      if (chatElement && !chatButtonElement) {
        chatElement.hidden = true
      }
    }
  }, [])

  return (
    <PageWrapper
      title='How can we help you?'
    >
      <QuestionSelector disabled={disableSelect} onChange={setQuestion} />
      {question && <QuestionArea disableSelector={setDisableSelect} question={question} />}
    </PageWrapper>
  )
}

export default ContactUsPage

/* stylelint-disable no-descending-specificity */
import styled from 'styled-components'
import { SelectDropdown } from '@/components/__Inputs'
import theme from '@/global/Theme'

const Title = styled.h2`
  text-align: center;
  margin: 1rem 0 1rem;
`

const SubTitle = styled.h2`
  text-align: center;
  margin: 2rem 0;
`

const SectionTitle = styled.h3`
  font-size: 0.875rem;
  margin: 1rem 0 0;
`

const CenterWrapper = styled.div`
  ${({ theme }) => theme.mediaQueries.tabletPortraitUp} {
    display: flex;
    justify-content: 'space-between';

    & > *:first-child {
      flex: 1;
      margin-right: 0.5rem;
    }
    & > *:nth-child(2n) {
      flex: 1;
      margin-left: 0.5rem;
    }
  }
`

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;

  
  button {
    flex: 1;
    max-width: 9rem;

    &:first-child {
      margin-right: 0.5rem;
    }
    &:nth-child(2n) {
      margin-left: 0.5rem;
    }
  }
`

const UserInfoSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: inherit;

  .box1, .box2, .box3{
    width: 47%;
    margin: 0 1rem 0 0;

    ${({theme}) => theme.mediaQueries.phoneOnly} {
      width: 100%;
    }
  }
`
const SitesSection = styled.section`
    width: 97%;

    ${({theme}) => theme.mediaQueries.phoneOnly} {
      width: 96%;
    }
`

const PermissionsSection = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: inherit;
  margin-bottom: 2rem;
  margin-left: 0;
`

const PermissionItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 47%;
  height: 3rem;
  padding: .75rem;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 1rem 0;
  border-radius: ${({theme}) => theme.borderRadius};
  border: 1px solid ${({theme}) => theme.colors.greyThree};
  color: ${theme.colors.black}; 

  label{
    width: 100%;
    font-size: 0.938rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: ${theme.colors.black}; 
  }

    ${({theme}) => theme.mediaQueries.phoneOnly} {
      width: 100%;
    }
`

const StyledSelectDropdown = styled(SelectDropdown)`
  margin-bottom: 1.5rem;
  max-width: none !important;
`

const Text = styled.p`
  text-align: center;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
`

const TextBold = styled.p`
  text-align: center;
  margin-bottom: 2rem;
  font-weight: bold;
`

const UserContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.15rem;

`

const UserDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 1rem;

  @media screen and (max-width: 768px) {
    flex: 2;
    flex-direction: column;
  }
`

const UserTitle = styled.span`
  flex: 0.2;
  margin-left: -1rem;   
  color: ${theme.colors.grey};
  font-weight: normal;
  
  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
`

const UserInfo = styled.span`
  flex: 0.8;
  color: ${({theme}) => theme.colors.userAdminEditInfo};
  font-weight: 600;
  font-size: 0.95rem;

  @media screen and (max-width: 768px) {
    margin-left: -1rem;
  }
`

const AdminTickImg = styled.img`
 margin-top: -1rem;
 margin-left: -5.5rem;

  @media screen and (max-width: 768px) {
    margin-left: 0rem;
  }
`

const UserDetailsContainerAdmin = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex: 1;
  flex-direction: row;
  width: 100%;
  margin: 1rem;
  margin-top: 1.7rem;
  
  @media screen and (max-width: 768px) {
    flex: 1;
    flex-direction: row;
  } 
`
const UserTitleAdmin = styled.span`
  flex: 0.4;
  margin-left: -1rem;  
  color: ${theme.colors.grey};
  font-weight: normal;
  font-size: 0.95rem;
`
const UserInfoAdmin = styled.span`
  flex: 0.8;
  color: ${({theme}) => theme.colors.userAdminEditInfo};
  font-weight: 600;

  @media screen and (max-width: 768px) {
    margin-left: -1rem;
  }
`
const NotAnAdmin = styled.span`
  color: ${({theme}) => theme.colors.notAdmin};
  margin-left: -5.5rem;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
`

export { Title, SubTitle, SectionTitle, CenterWrapper, ButtonSection, PermissionsSection, UserInfoSection, SitesSection, PermissionItem, StyledSelectDropdown, Text, TextBold, UserContent, UserTitle, UserDetailsContainerAdmin, UserTitleAdmin, UserInfoAdmin, UserInfo, UserDetailsContainer, AdminTickImg, NotAnAdmin }

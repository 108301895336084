import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useForm } from '@/components/__Inputs/Form'
import { useUser } from '@/context/UserProvider'
import { FormikField, SelectDropdown } from '@/components/__Inputs'
import { useTranslation } from 'react-i18next'
import { useQueryUserProperties } from '@/hooks/useQuery'
import toast from '@/helpers/toast'

const PropertyDropdown = ({
  filteredProperties,
  setFilteredPropertiesWithNames,
  allOption,
  className,
  isEnabled,
  label,
  onChange,
  onError,
  onSuccess,
  operationID,
  withFormik,
  defaultValue,
  ...props
}) => {
  const { t } = useTranslation()

  const propName = 'property'
  const { user } = useUser()
  const form = useForm()
  const [ property, setProperty ] = useState(null)
  const [ properties, setProperties ] = useState([])

  const allProperties = { propertyName: t('allAccommodations'), propertyId: 'all' }

  const checkPropertyId = (inputId, data) => {
    const id = typeof inputId === 'string' ? inputId : inputId && inputId.propertyId
    const match = id ? data.find(({ propertyId }) => id === propertyId) : null
    return match
  }

  const checkIsEnabled = () => {
    if (user) {
      if (isEnabled === undefined) {
        return true
      } else {
        return isEnabled
      }
    } else {
      return false
    }
  }

  const setFormikValue = (value) => {
    form && form.setFieldValue(propName, value)
  }

  const extractPropertiesFromSites = (sites) => {
    const properties = sites.sites.flatMap(site =>
      site.siteProperties.map(property => ({
        propertyId: property.propertyId,
        propertyCode: property.propertyCode,
        propertyName: property.propertyName,
        managedProperty: property.managedProperty
      }))
    )

    return properties
  }

  const {
    data: sitesWithPropertiesFromQuery,
    isLoading: loadingProperties,
    error
  } = useQueryUserProperties(operationID, checkIsEnabled())

  useEffect(() => {
    if (error) {
      toast.error(t('failedToLoadAccommodations'), null, error)
      onError && onError(error)
    }
  }, [error, t, onError])

  useEffect(() => {
    if (sitesWithPropertiesFromQuery) {
      const properties = extractPropertiesFromSites(sitesWithPropertiesFromQuery)

      let defaultProperty = properties[0]

      let newPropertyList = properties

      if (properties.length > 1 && allOption) {
        defaultProperty = allProperties
        newPropertyList = [allProperties, ...properties]
      }

      if (filteredProperties && filteredProperties.length) {
        newPropertyList = newPropertyList.filter(pr1 => filteredProperties.some(pr2 => {
          if (pr1.propertyId === pr2.propertyId) {
            pr1.years = pr2.years
            return pr1
          }
          return null
        }))

        defaultProperty = newPropertyList[0]
        setFilteredPropertiesWithNames(newPropertyList)
      }

      const defValue = checkPropertyId(defaultValue, properties) || defaultProperty

      // Fixes race condition, dont touch
      setTimeout(() => {
        withFormik && setFormikValue(defValue)
      }, 0)

      setProperty(defValue)
      onChange && onChange(defValue)
      setProperties(newPropertyList)
      onSuccess && onSuccess(properties)
    }

    // eslint-disable-next-line
  }, [sitesWithPropertiesFromQuery])

  const handleChange = (p) => {
    onChange && onChange(p)
    setProperty(p)
  }

  if (!loadingProperties && properties.length > 0 && withFormik) {
    return (
      <FormikField
        {...props}
        className={className}
        component={SelectDropdown}
        inputType="select"
        name={propName}
        label={label && t('selectAccommodation')}
        options={properties}
        placeholder={t('selectAccommodation')}
        getOptionLabel={option => option['propertyName']}
        getOptionValue={option => option['propertyId']}
      />
    )
  }

  return (
    <SelectDropdown
      {...props}
      label={label && t('selectAccommodation')}
      value={property}
      className={className}
      options={properties}
      onChange={handleChange}
      isLoading={loadingProperties}
      loadingText={t('loadingAccommodations')}
      getOptionLabel={option => option['propertyName']}
      getOptionValue={option => option['propertyId']}
    />
  )
}

PropertyDropdown.propTypes = {
  allOption: PropTypes.bool,
  className: PropTypes.string,
  isEnabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  setFilteredPropertiesWithNames: PropTypes.func,
  withFormik: PropTypes.bool,
  operationID: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  filteredProperties: PropTypes.array
}

export default PropertyDropdown

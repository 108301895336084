type Environment = 'dev' | 'ppe' | 'prod' | 'localhost' | '';

export function getEnvironment () {
  const hostname = window.location.hostname

  if (hostname === undefined) {
    return ''
  }

  const novasolUrl = getEnvironmentForNovasolUrl(hostname)

  if (novasolUrl) { return novasolUrl }

  return getEnvironmentForAwazeUrl(hostname)
}

function getEnvironmentForNovasolUrl (hostname): Environment {
  if (hostname.toLocaleLowerCase().includes('test01-')) {
    return 'dev'
  }

  if (hostname.toLocaleLowerCase().includes('staging01-')) {
    return 'ppe'
  }

  if (hostname.toLocaleLowerCase().includes('novasol')) {
    return 'prod'
  }

  return ''
}

function getEnvironmentForAwazeUrl (hostname): Environment {
  if (hostname.toLocaleLowerCase() === 'owners.awaze.com') {
    return 'prod'
  }

  // NOTE - http://bs-local.com is a browserstack localhost proxy
  if (hostname.toLocaleLowerCase() === 'bs-local.com') {
    return 'localhost'
  }

  const hostnameParts = hostname.split('.')

  let environment = hostnameParts[0]
  if (environment.toLowerCase() === 'common') {
    environment = hostnameParts[1]
  }

  return environment
}

export function getEnvironmentVariable (name: string, fallback: string | undefined = undefined): string | undefined {
  return getEnvironmentVariableCreateReactApp(name) ?? getEnvironmentVariableVite(name) ?? fallback
}

function getEnvironmentVariableCreateReactApp (name: string): string | undefined {
  if (typeof process !== 'undefined') {
    return process.env[`REACT_APP_${name}`]
  }
}

function getEnvironmentVariableVite (name) {
  return import.meta.env[`VITE_${name}`]
}

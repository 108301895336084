import { Fragment, useMemo } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import {
  TableContainer,
  StyledTable,
  StyledThead,
  StyledTh,
  StyledTbody,
  StyledTd,
  StyledHeading,
  StyledTr,
  StyledTrExpanded
} from './PricingListTable.styles';
import { Divider } from '@awaze/design-system.components.divider';
import { usePricingTableColumnDefinitions, usePricingTableColumnVisibility } from './columns/columnDefinitions';
import { PricingListTableControls } from '../PricingListTableControls';
import { usePricingDataContext } from '../context/PricingDataProvider';
import { ExpandedRow } from './columns/ExpandedRow';
import useWindowSize from '@/hooks/useWindowSize';

export function PricingListTable() {
  const { t } = useTranslation();
  const { width } = useWindowSize()

  const isExpandable = useMemo(() => width != null && width < 768, [width])

  const columns = usePricingTableColumnDefinitions()
  const columnVisibility = usePricingTableColumnVisibility({ isExpandable })
  const { pricingData } = usePricingDataContext()

  const table = useReactTable({
    data: pricingData?.rows || [],
    columns,
    state: { columnVisibility },  
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    autoResetExpanded: true
  });

  return (
    <>
      <StyledHeading sizes={100}>
        {t('pricingList')}
      </StyledHeading>
      <TableContainer style={{ marginBottom: '20rem'}}>
        <PricingListTableControls />
        <Divider margin={0} />
        <StyledTable>
          <StyledThead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <StyledTh key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </StyledTh>
                ))}
              </tr>
            ))}
          </StyledThead>
          <StyledTbody>
            {table.getRowModel().rows.map((row) => {
              const isExpanded = row.getIsExpanded()
              
              return (              
              <Fragment key={row.id}>
                <StyledTr $expanded={isExpanded} onClick={() => { isExpandable && row.toggleExpanded()}} $expandable={isExpandable}>
                  {row.getVisibleCells().map((cell) => (
                    <StyledTd key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </StyledTd>
                  )
                  )}
                </StyledTr>
                {isExpanded && columnVisibility.expandableToggle && (
                  <StyledTrExpanded onClick={() => { row.toggleExpanded() }}>
                    <td colSpan={table.getVisibleFlatColumns().length}>
                      <ExpandedRow row={row.original} />
                    </td>
                  </StyledTrExpanded>
                )}
              </Fragment>
            )})}
          </StyledTbody>
        </StyledTable>
      </TableContainer>
    </>
  );
}

/* eslint-disable react/prop-types */
import React from 'react'
import { OvalLoadingSpinner } from '@/components/__UI'
import { useTranslation } from 'react-i18next'
import { Quote, QuoteSection, SectionData, SectionTitle, SectionTitleTotal } from '../NewBookingModalConsolidated.styles'

interface QuoteDetails {
    currency: string;
    holidayPrice: number;
    extrasTotal: number;
    total: number;
  }

  interface QuoteSectionComponentProps {
    quoteDetails?: QuoteDetails;
    isDisabled?: boolean;
    isLoading?: boolean;
    isError?: boolean;
  }

const QuoteSectionComponent: React.FC<QuoteSectionComponentProps> = ({
  quoteDetails,
  isDisabled,
  isLoading,
  isError
}) => {
  const { t } = useTranslation()

  if (isError) {
    return <p>{t('errorMessageSomethingWentWrong')}</p>
  }

  if (isLoading) {
    return <OvalLoadingSpinner />
  }

  if (isDisabled) {
    return (
      <Quote>
        <h4>{t('noHolidayPriceAvailable')}</h4>
        <div className='priceContainer'>
          <p>{t('unableToMakeBooking')}</p>
        </div>
      </Quote>
    )
  }

  if (quoteDetails) {
    return (
      <Quote data-testid='quote-data'>
        <h4>{t('quote')}</h4>
        <QuoteSection>
          <SectionTitle>{t('accommodationPrice')}</SectionTitle>
          <SectionData data-testid="quote-accomodation-price">{quoteDetails.holidayPrice.toFixed(2)} {quoteDetails.currency}</SectionData>
        </QuoteSection>

        <QuoteSection>
          <SectionTitle>{t('extras')}</SectionTitle>
          <SectionData data-testid="quote-extras">{quoteDetails.extrasTotal.toFixed(2)} {quoteDetails.currency}</SectionData>
        </QuoteSection>
        <QuoteSection>
          <SectionTitleTotal>{t('total')}</SectionTitleTotal>
          <SectionData data-testid="quote-total">
            <SectionTitleTotal>{quoteDetails.total.toFixed(2)} {quoteDetails.currency}</SectionTitleTotal>
          </SectionData>
        </QuoteSection>
      </Quote>
    )
  }

  return null
}

export default QuoteSectionComponent

import styled from 'styled-components'

export const CaretDownIcon = styled.div`
  margin-top: 50%;
  width: 0;
  height: 0;
  border: 0.5vw solid transparent;
  transition: ${({theme}) => theme.animationSpeed} all ease;
  border-top: 0.5vw solid ${({ theme }) => theme.colors.blackTwo};
  /* change to border-bottom, if caret should face top */

  @media (min-width: 2001px) and (max-width: 3500px) {
    border: 0.25vw solid transparent;
    border-top: 0.25vw solid ${({ theme }) => theme.colors.blackTwo}; 
  }
  @media (min-width: 1601px) and (max-width: 2000px) {
    border: 0.5vw solid transparent;
    border-top: 0.5vw solid ${({ theme }) => theme.colors.blackTwo};
  }
  @media (min-width: 1200px) and (max-width: 1600px) {
    border: 0.6vw solid transparent;
    border-top: 0.6vw solid ${({ theme }) => theme.colors.blackTwo};
  }
  @media (min-width: 600px) and (max-width: 1199px) {
    border: 0.8vw solid transparent;
    border-top: 0.8vw solid ${({ theme }) => theme.colors.blackTwo};
  }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    border: 2vw solid transparent;
    border-top: 2vw solid ${({ theme }) => theme.colors.blackTwo};
  }
`

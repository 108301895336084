import dayjs from 'dayjs'
import newsData from '@/constants/news'
import React, { useEffect } from 'react'
import { NewsItem } from './NewsPage.styles'
import { useLocation } from 'react-router-dom'
import { PageWrapper } from '@/components/__UI'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(advancedFormat)

const NewsPage = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <PageWrapper
      title='Owner news'
    >
      {newsData.length > 0
        ? newsData.map(item => (
          <NewsItem key={item.title}>
            <h2>{item.title}</h2>
            <span>Posted on {dayjs(item.postedOn).format('Do MMM YYYY')}</span>
            {item.content}
          </NewsItem>
        ))
        : <p>No news items found</p>
      }
    </PageWrapper>
  )
}

export default NewsPage

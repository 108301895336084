import React from 'react'
import PropTypes from 'prop-types'
import { ViewProperty, PropertyLink } from './PropertyWebsiteLink.styles'
import { Link } from 'react-router-dom'

const getRedirectUrlForProperty = (property) => {
  const propertyCode = (property && property.propertyCode) || ''
  return `https://www.cottages.com/cottages/${propertyCode}`
}

const PropertyWebsiteLink = (props) => {
  return (
    <PropertyLink>
      <ViewProperty>
          View property
      </ViewProperty>
      <Link
        target="_blank" rel="noopener noreferrer"
        to={getRedirectUrlForProperty(props.property)}>
          on website
      </Link>
    </PropertyLink>
  )
}

PropertyWebsiteLink.propTypes = {
  className: PropTypes.string,
  property: PropTypes.object
}

export default PropertyWebsiteLink


export async function setEmployeeProperty (propertyId) {
  const url = `/rental/${propertyId}`
  try {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`)
    }
  } catch (error) {
    console.error(error.message)
  } finally {
    window.location.reload()
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { PropertyDescriptionSection } from '@/components/__PropertyAmendsComponents'
import { Content } from '../PropertyArrival/PropertyArrival.styles'
import { Container, Spinner } from '@/components/__UI'
import useGraphQl, { GET_PROPERTY_DESCRIPTION } from '@/hooks/useGraphQl'

const PropertyCheckInOutTime = ({propertyId}) => {
  const getCheckinOutTimesCountFromCollection = (descriptionCollection) => {
    return descriptionCollection.filter(description => {
      return (description.id === 'Check-in-time' || description.id === 'Check-out-time')
    }).length
  }

  const [ descriptionValues, loadingDescriptionValues ] = useGraphQl({
    query: GET_PROPERTY_DESCRIPTION,
    variables: {
      propertyId
    }
  })

  if (loadingDescriptionValues) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  if (!descriptionValues) {
    return (
      <p>{`Unable to find this property's check-in / check-out time.`}</p>
    )
  }

  if (descriptionValues && getCheckinOutTimesCountFromCollection(descriptionValues) < 2) {
    return (
      <p>{`Unable to find this property's check-in / check-out time.`}</p>
    )
  }

  return (
    descriptionValues.map((item, x) => {
      if (item.id === 'Check-in-time' || item.id === 'Check-out-time') {
        return (<PropertyDescriptionSection key={x} item={item}>
          <Content dangerouslySetInnerHTML={{__html: item.description}} />
        </PropertyDescriptionSection>)
      }
      return null
    }
    ))
}

PropertyCheckInOutTime.propTypes = {
  propertyId: PropTypes.string,
  showEdit: PropTypes.bool
}

export default PropertyCheckInOutTime

import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@/components/__UI/Modal'

const PricingInfoModal = ({ onClose }) => (
  <Modal open onClose={onClose}>
    <h2 style={{marginBottom: '0.5rem'}}>Pricing</h2>
    <p>
      You can view the price for different durations by selecting 2, 3, 4 or 7 nights from the duration drop down list.
      Even if you do not offer all of these durations, you will still be able to see a selling price.
      This may help you make a decision on whether to offer more flexibility.
      <br /><br />
      The column <b>Your income</b> is calculated from <b>today’s customer price</b>, where the date remains available.
      If the date has been sold, <b>Your Income</b> shows what you will actually receive. A green tick indicates that there is a guest booking in place.
      <br /><br />
      <i>NB: all displayed customer prices are exclusive of our booking fee</i>
    </p>
    <h3 style={{marginTop: '0.5rem'}}>Dynamic prices</h3>
    <p>
        Dynamic pricing works to optimise your property’s pricing to maximise its booking potential.
        This means that for dynamically priced properties, prices can increase or decrease based on key booking trends including supply and demand.
    </p>
    <p style={{marginTop: '0.5rem'}}><a href="https://fast.wistia.com/embed/medias/yxpjuuqg75" target="_blank" rel="noopener noreferrer">Learn more about dynamic prices</a></p>

  </Modal>
)

PricingInfoModal.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default PricingInfoModal

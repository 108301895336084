import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { Container, Spinner, DisplayCheckbox } from '@/components/__UI'
import { CheckboxContainer } from './PropertyFeatures.styles'
import useGraphQl, { GET_PROPERTY_FEATURES } from '@/hooks/useGraphQl'

const PropertyFeaturesDisplay = ({propertyId}) => {
  const [checkedFeatures, setCheckedFeatures] = useState([])

  const [ featuresList, loadingFeatures ] = useGraphQl({
    query: GET_PROPERTY_FEATURES,
    variables: {
      propertyId
    },
    onSuccess: (data) => {
      const list = data.map(({features}) => features).flat(1).filter((feature) => feature.enabled)
      setCheckedFeatures(list)
    }
  })

  if (loadingFeatures) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  if (!featuresList || featuresList.length < 1) {
    return <p>{`Unable to find this property's features`}</p>
  }

  return (
    checkedFeatures && checkedFeatures.length > 0
      ? <CheckboxContainer>{checkedFeatures.map(({description, enabled}, i) => {
        return <DisplayCheckbox key={i} label={description} enabled={enabled} />
      })}</CheckboxContainer>
      : <p>No features selected</p>
  )
}

PropertyFeaturesDisplay.propTypes = {
  propertyId: PropTypes.string.isRequired
}

export default PropertyFeaturesDisplay

import React from 'react'
import {
  PropertyId,
  PropertyName,
  BookingType,
  ConfirmationMessage,
  ErrorMessage
} from '../bookingModals.styles'
import PropTypes from 'prop-types'
import { Modal, OvalLoadingSpinner } from '@/components/__UI'

import { ButtonContent } from '../BookedModal/BookedModal.styles'
import { Button } from '@awaze/design-system.components.button'
import { useQueryBookingSearch } from '@/hooks/useQuery'
import BookingDetails from '@/components/__BookingComponents/BookingDetails'
import { CONSOLIDATED_BOOKING_STATE_OWNER_WITH_CLEAN } from '@/constants/bookings'
import { useTranslation } from 'react-i18next'
import { pascalToCamelCase } from '@/helpers/stringUtils'

const SuccessfulBookingModal = ({ data, onClose }) => {
  const { t } = useTranslation()

  const { data: bookingDetails, isLoading: isLoadingBookingDetails } = useQueryBookingSearch(data.bookingReference)

  const buildMessageForCleaningCharges = (cleaningRequiredOnBooking) => {
    if (cleaningRequiredOnBooking) {
      return (
        <ConfirmationMessage data-type="success">
          <PropertyName>{t('cleaningConfirmation')}</PropertyName>
          <p id='cleaningChargeMessage'>{t('cleaningRate')}</p>
        </ConfirmationMessage>
      )
    }
  }

  const buildMessageForSuccessfulNotification = (hasSuppressedNotification) => {
    if (!hasSuppressedNotification) {
      return (
        <p id='createNotificationMessage'>{t('confirmationEmail')}</p>
      )
    }
  }

  const renderCorrectBookingConfirmationHeader = () => {
    return (
      <PropertyName>{t('bookingConfirmation')}</PropertyName>
    )
  }

  return (<Modal open onClose={onClose}>
    { isLoadingBookingDetails
      ? (<OvalLoadingSpinner />)
      : (
        <>
          <BookingType bookingState={bookingDetails.state}>{ t(pascalToCamelCase(bookingDetails.state))}</BookingType>
          <PropertyName>{bookingDetails?.propertyName}</PropertyName>
          <PropertyId>{bookingDetails?.propertyId}</PropertyId>
          { bookingDetails.unitCode && (<PropertyName>{t('Unit')} : {bookingDetails?.unitCode}</PropertyName>)}
          {
            data.hasNotificationSent || data.hasSuppressedNotification
              ? (<>
                <ConfirmationMessage data-type="success">
                  {renderCorrectBookingConfirmationHeader()}
                  {buildMessageForSuccessfulNotification(data.hasSuppressedNotification)}
                </ConfirmationMessage>
                {buildMessageForCleaningCharges(bookingDetails.state === CONSOLIDATED_BOOKING_STATE_OWNER_WITH_CLEAN)}
              </>)
              : (
                <ErrorMessage data-type="error">
                  <p>
                    {t('bookingConfirmedWithProblem', {reservationId: data.reservationId})}
                  </p>
                </ErrorMessage>)
          }

          <BookingDetails booking={bookingDetails}/>
          <ButtonContent>
            <Button
              data-testid="successfulBookingModalConfirmationButton"
              primary
              onClick={onClose}
              text='OK'
              colourScheme='brand-primary'
              size='medium'
              style={{width: '124px'}}

            />

          </ButtonContent>
        </>
      )
    }
  </Modal>
  )
}

SuccessfulBookingModal.propTypes = {
  data: PropTypes.object,
  isExtrasChecked: PropTypes.bool,
  onClose: PropTypes.func
}

export default SuccessfulBookingModal

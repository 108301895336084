import React from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { DATE_FORMAT_DISPLAY } from '@/constants/dates'
import { Container, Year, Section, SectionRow, SectionHeading, SeasonType } from './BookingAllowanceSeasonsItem.styles'

const BookingAllowanceSeasonsItem = ({item}) => {
  const {year, seasons} = item

  return (
    <Container>
      <Year>{year} Season Dates</Year>
      <Section>
        <SectionRow>
          <SectionHeading>Season</SectionHeading>
          <SectionHeading>Start date</SectionHeading>
          <SectionHeading>End date</SectionHeading>
        </SectionRow>
        { seasons && seasons.map(({seasonType, startDate, endDate}, key) => (
          <SectionRow key={key}>
            <p><SeasonType type={seasonType.toLowerCase()}>{seasonType}</SeasonType></p>
            <p>{dayjs(startDate).format(DATE_FORMAT_DISPLAY)}</p>
            <p>{dayjs(endDate).format(DATE_FORMAT_DISPLAY)}</p>
          </SectionRow>
        ))}
      </Section>
    </Container>
  )
}

BookingAllowanceSeasonsItem.propTypes = {
  item: PropTypes.shape({
    year: PropTypes.string.isRequired,
    seasons: PropTypes.arrayOf(PropTypes.shape({
      seasonType: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired
    }))
  })
}

export default BookingAllowanceSeasonsItem

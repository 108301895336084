import { useQueryUserProperties } from '@/hooks/useQuery'
import { useQueryUserAuthorisations } from '@/hooks/useQuery/queries/useQueryUserAuthorisations'

export function useSelectedPropertyID () {
  const { data: userAuthorisations } = useQueryUserAuthorisations()
  const hasUserAnyValidAuthorisations = userAuthorisations.some(auth => auth.name !== 'home' && auth.authorised)

  const { data, isLoading } = useQueryUserProperties('', hasUserAnyValidAuthorisations)
  const propertyID = data?.sites[0]?.siteProperties[0]?.propertyId ?? 'none'
  return { propertyID, isLoading }
}

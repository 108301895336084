import styled from 'styled-components'
import Collapse from '@kunukn/react-collapse'
import Button from '@/components/__Inputs/Button'

const LoadArea = styled.div`
    padding: 1rem;
    margin: 1rem auto;
    width: fit-content;
    border-radius: 50%;
    box-shadow: ${({ theme }) => theme.boxShadowSoft};
    background-color: ${({ theme }) => theme.colors.white};
`

const StyledTable = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    flex-direction: column;
    border: ${({ theme }) => theme.colors.inputDisabled} 1px solid;
    border-radius: ${({ theme }) => theme.borderRadius};

    &.cols-2 .table-cell, &.cols-2 .table-head-cell { width: 50%; }
    &.cols-3 .table-cell, &.cols-3 .table-head-cell { width: 27.33%; }
    &.cols-4 .table-cell, &.cols-4 .table-head-cell { width: 25%; }
    &.cols-5 .table-cell, &.cols-5 .table-head-cell { width: 20%; }
    &.cols-6 .table-cell, &.cols-6 .table-head-cell { width: 16.6%; }
    &.cols-7 .table-cell, &.cols-7 .table-head-cell { width: 14.27%; }
    &.cols-8 .table-cell, &.cols-8 .table-head-cell { width: 12.5%; }

    @media screen and (max-width: 1025px) {
        padding: 0;
        box-shadow: none;
        font-size: 0.8rem;
        background-color: transparent;
    }

    @media print {
        padding-left: 0;
        padding-right: 0;
    }
`

const StyledTableHeader = styled.div`
    display: flex;
    padding: 1rem 0.7rem 0 0.7rem;
    font-weight: 600;
    align-items: center;
    border-radius: .2rem;
    justify-content: space-evenly;

    ${({ theme }) => theme.mediaQueries.phoneOnly} {
    padding: 1rem 0.7rem 0 1rem;

    }
`

const StyledTableHeadCell = styled.div`
    position: relative;
    text-align: ${({ align }) => align || 'center'};
    width: ${({ width }) => width ? `${width} !important` : 'initial'};
    font-size: 0.875rem;

    &.sortable {
        display: flex;
        padding: .5rem;
        cursor: pointer;
        align-items: center;
        border-radius: .2rem;
        transition: 0.2s background-color ease;
    }

    &.small {
        font-size: .8rem;
    }
`

const StyledTableRow = styled.div`
    width: 100%;
    min-height: 5rem;
    cursor: default;
    position: relative;
    border-radius: .2rem;

    
    &:not(:last-child) {
    border-bottom: 1px ${({ theme }) => theme.colors.buttonSolidTertiaryHover} solid;
  }

    background-color: ${({ theme }) => theme.colors.white};


    &.collapse {
        cursor: pointer;
    }

    .table-row-inner {
        min-height: 5rem;
        display: flex;
        padding: 1rem;
        position: relative;
        align-items: center;
        justify-content: space-evenly;
        transition: ${({ theme }) => `${theme.animationSpeed} background-color ease`};
    
        &:hover {
            background-color: ${({ theme }) => theme.colors.greyFive};
        }

        @media print {
            padding: 0.2rem;
        }
    }
`

const StyledTableCell = styled.div`
    flex-wrap: wrap;
    position: relative;
    align-items: center;
    text-align: ${({ align }) => align || 'center'};
    display: ${({ block }) => block ? 'block' : 'flex'};
    justify-content: ${({ align }) => align || 'center'};
    width: ${({ width }) => width ? `${width} !important` : 'initial'};
    font-size: 0.875rem;


    &.small {
        font-size: .8rem;
    }
`
const StyledTableCollapse = styled(Collapse)`
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);

    .table-collapse-inner {
        padding: 1rem 2rem;
    }

    ${({ theme }) => `${theme.mediaQueries.phoneOnly} {
        .table-collapse-inner {
            padding-top: 0;
        }
    }`}
`

const StyledChevron = styled.span`
    width: 10px;
    height: 10px;
    display: block;
    cursor: pointer;
    position: relative;

    &::before {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        width: 10px;
        margin: auto;
        height: 10px;
        display: block;
        position: absolute;
        transition: 0.2s transform ease;
        border-right: ${({ theme }) => `2px solid ${theme.colors.blackTwo}`};
        border-bottom: ${({ theme }) => `2px solid ${theme.colors.blackTwo}`};
        transform: ${({ isActive }) => `rotate(${isActive ? '-135deg' : '45deg'})`}
    } 
`

const StyledSortButton = styled(Button)`
    width: auto;
    height: auto;
    border: none;
    font-weight: bold;
    position: relative;
    margin-left: -1rem;
    font-family: inherit;
    padding-right: 1.5rem;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.blackTwo};
    border-radius: ${({ theme }) => theme.borderRadius};
    transition: ${({theme}) => theme.animationSpeed} all ease;

    &.off {
        &::after {
            opacity: 0.5;
        }
    }

    &:not(.off) {
        background-color: ${({ theme }) => theme.colors.greySix};
        color: ${({ theme }) => theme.colors.primary};
    }

    &.desc {
        &::after {
            top: -0.2rem;
            transform: rotate(135deg);
        }
    }   

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.greySix};
        color: ${({ theme }) => theme.colors.primary};
    }

    &::after {
        bottom: 0;
        top: 0.2rem;
        content: '';
        margin: auto;
        width: 0.45em;
        right: 0.5rem;
        height: 0.45em;
        position: absolute;
        border-style: solid;
        vertical-align: top;
        display: inline-block;
        transform: rotate(-45deg);
        border-width: 0.15em 0.15em 0 0;
    }
`
export {
  LoadArea,
  StyledTable,
  StyledTableHeader,
  StyledTableHeadCell,
  StyledTableRow,
  StyledTableCell,
  StyledTableCollapse,
  StyledChevron,
  StyledSortButton
}

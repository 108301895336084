import React from 'react'
import { CaretDownIcon } from './CaretIcon.styles'

function CaretIcon () {
  return (
    <CaretDownIcon></CaretDownIcon>
  )
}

export default CaretIcon

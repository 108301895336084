import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 0.5rem 0.5rem;
  background-color: ${({theme}) => theme.colors.white};
  border-radius: ${({theme}) => theme.borderRadius};
  border: 0.5px solid ${({ theme }) => theme.colors.tertiaryButtonBorderHover};
  display: flex;
  flex-wrap: wrap;
  gap: 16px; 

`

const Item = styled.div`
display: inline-flex;
align-items: center;

span {
  margin:0;
  font-size: 0.75rem;


  @media (max-width: 1024px) {
    font-size: 0.875rem;
  }


}
`
const StyledIcon = styled.img`
width: 12px;
height: 12px;
margin-right: 0.5rem;


@media (max-width: 1024px) {
width: 14px;
height: 14px;   
}
`

const ItemColor = styled.div`
display: inline-block;
vertical-align: middle;
width: 12px;
height: 12px;
margin-right: 0.5rem;
background: ${({color}) => color};
border-radius: ${({theme, radius}) => radius || theme.borderRadiusIcon};
${({borderColor}) => `border: 1px dashed ${borderColor}`};
position: relative;


@media (max-width: 1024px) {
width: 14px;
height: 14px;   
}
`

export { Wrapper, Item, ItemColor, StyledIcon }

import styled from 'styled-components'

const ContentContainer = styled.div`
  position: relative;

  ${({ theme }) => theme.mediaQueries.desktopUp} {
    display: flex;
    flex-wrap: nowrap;

    & > * {
      flex: 1;
    }
  }
`

const SideBar = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.greyFive};
  flex: none;

  p {
    margin-bottom: 1rem;
  }

  ${({ theme }) => theme.mediaQueries.desktopUp} {
    width: 400px;
    margin-top: 0;
    margin-left: 2rem;
  }
`

const MainImage = styled.img`
  display: block;
  width: 100%;
`

export { ContentContainer, MainImage, SideBar }

import React, { useState, forwardRef } from 'react'
import PropTypes from 'prop-types'
import useWindowSize from '@/hooks/useWindowSize'
import { useBookingListContext } from '@/context/BookingListProvider'
import DatePicker from '@/components/__Inputs/DatePicker'
import { StyledLabel } from '@/components/__Inputs/input.styles'
import {
  Container,
  LabelContainer,
  Inner,
  Dash,
  DatePickerInput
} from './BookingListDateFilter.styles'
import { useTranslation } from 'react-i18next'

const BookingListDateFilter = () => {
  const { t } = useTranslation()

  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    isLoadingBookings
  } = useBookingListContext()

  const calendarVisible = 1150

  const { width } = useWindowSize()
  const [openEndPicker, setOpenEndPicker] = useState(false)

  const passedYears = 5
  const passedMonths = 11
  const toComeYears = 2

  const thisYear = new Date().getFullYear()
  const thisMonth = new Date().getMonth()
  const thisDay = new Date().getDate()

  const minDate = new Date(
    thisYear - passedYears,
    thisMonth + passedMonths,
    thisDay
  )
  const maxDate = new Date(thisYear + toComeYears, thisMonth, thisDay)

  const handleStartChange = (date) => {
    // 12 hours set on date to avoid ISO string UTC offset
    const datePickerStartDate = new Date(date.$y, date.$M, date.$D, 12)
      .toISOString()
      .split('T')[0]
    setStartDate(datePickerStartDate)
    setOpenEndPicker(true)
  }
  const handleEndChange = (date) => {
    const datePickerEndDate = new Date(date.$y, date.$M, date.$D, 12)
      .toISOString()
      .split('T')[0]
    setEndDate(datePickerEndDate)
    setOpenEndPicker(true)
  }

  return (
    <Container>
      <LabelContainer>
        <StyledLabel>{t('dateRange')}</StyledLabel>
      </LabelContainer>
      <Inner>
        <DatePicker
          name="startPicker"
          calendarClassName="startCalendar"
          date={startDate}
          minDate={minDate}
          maxDate={endDate}
          onChange={handleStartChange}
          showYearDropdown
          closeOnScroll
          onTap
          customInput={<CustomInput name="startPicker" />}
          isLoading={isLoadingBookings}
        />

        <Dash />

        <DatePicker
          name="endPicker"
          calendarClassName="endCalendar"
          date={endDate}
          onChange={handleEndChange}
          minDate={startDate}
          maxDate={maxDate}
          popperModifiers={{
            offset: {
              enabled: width < calendarVisible,
              offset: '-50%'
            }
          }}
          showYearDropdown
          open={openEndPicker}
          onCloseCalendar={() => setOpenEndPicker(false)}
          closeOnScroll
          customInput={<CustomInput name="endPicker" />}
          isLoading={isLoadingBookings}
        />
      </Inner>
    </Container>
  )
}

const CustomInput = forwardRef(({ value, onClick, name }, ref) => (
  <DatePickerInput name={name} onClick={onClick} ref={ref}>
    {value}
  </DatePickerInput>
))

CustomInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func
}

export default BookingListDateFilter

import * as Flags from '@awaze/design-system.components.flags'
import React, { SVGProps } from 'react'

export const getFlagComponent = (lang) => {
  const countries = {
    'en': Flags.Uk,
    'da': Flags.Denmark,
    'de': Flags.Germany,
    'es': Flags.Spain,
    'fr': Flags.France,
    'it': Flags.Italy,
    'be': Flags.Belgium,
    'hr': Flags.Croatia,
    'cz': Flags.CzechRepublic,
    'eu': Flags.Europe,
    'gr': Flags.Greece,
    'hu': Flags.Hungary,
    'fi': Flags.Finland,
    'ir': Flags.Ireland,
    'is': Flags.Island,
    'nl': Flags.Netherlands,
    'no': Flags.Norway,
    'pl': Flags.Poland,
    'sk': Flags.Slovakia,
    'sv': Flags.Sweden,
    'ch': Flags.Switzerland,
    'us': Flags.Usa,
    'bg': Flags.Bulgaria,
    'sl': Flags.Slovenia,
    'at': Flags.Austria
  }

  return countries[lang] || null
}

interface FlagProps extends SVGProps<SVGSVGElement> {
  readonly lang: string;
}

export function Flag ({ lang, ...rest }: FlagProps) {
  const FlagComponent = getFlagComponent(lang)

  if (!FlagComponent) {
    return null
  }

  return (
    <div data-testid="flag">
      <FlagComponent viewBox="0 0 24 24" {...rest} />
    </div>
  )
}

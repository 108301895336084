import styled from 'styled-components'

const StyledDropdownWrapper = styled.div`
    margin-right: 1rem;

  label{
    width: auto;
    min-width: 15rem;
  }

  .react_select__menu {
    z-index: 40;
}

  @media only screen and (max-width: 921px) {
      margin-bottom: 0.5rem;
      margin-right: 1.3rem;

      &>div{
        margin-bottom: 0.5rem;
        pointer-events: ${props => props.disabled ? 'none' : 'auto'};
      }
  }

  @media only screen and (max-width: 659px) {
    label{
        width: 100%;
      }

          width: 100%;
    margin-right: 0rem;

    flex-direction: column;
    margin-bottom: 0.5rem;

    &>div{
      margin-bottom: 0.5rem;
      pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    }
  }

  

`
export default StyledDropdownWrapper

import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import FormikField from '@/components/__Inputs/FormikField'
import SelectDropdown from '@/components/__Inputs/SelectDropdown'
import { useField } from 'formik'

export const PropertyDescriptionTimeSelect = ({ id }) => {
  const [field] = useField({ name: id })

  const options = useMemo(() => {
    let startTime = dayjs().hour(9).minute(0)
    const endTime = dayjs().hour(17).minute(0)
    const options = []

    while (startTime.isSameOrBefore(endTime)) {
      options.push({ label: startTime.format('HH:mm'), value: startTime.format('HH:mm') })
      startTime = startTime.add(30, 'minutes')
    }

    return options
  }, [])

  const selectedValue = useMemo(() =>
    options.find(x => x.value === field.value)
  , [options, field.value]
  )

  return (
    <FormikField
      name={field.name}
      value={selectedValue}
      inputType="select"
      component={SelectDropdown}
      options={options}
    />
  )
}

PropertyDescriptionTimeSelect.propTypes = {
  id: PropTypes.string
}

export default PropertyDescriptionTimeSelect

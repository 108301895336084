import React from 'react'
import { Box } from '@awaze/design-system.components.box'
import { StyledReviewHeader, StyledReviewerInfo, StyledReviews } from './GuestReviewRating.styles'
import { Text, Heading } from '@awaze/design-system.components.text'
import { Reviews } from '@awaze/design-system.components.reviews'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

export const GuestReviewRating = ({reviews}) => {
  const { t } = useTranslation()

  if (reviews?.reviews?.length > 0) {
    return (
      <Box padding={[[0, 8, 24, 4]]}>
        <StyledReviewHeader>
          <StyledReviewerInfo>
            <Heading sizes={200} mb={8} type="bold">{t('overallHolidayExperience')}</Heading>
            <Text>
              {`${t('basedOn')} ${reviews?.numberOfReviews} `}
              {reviews?.numberOfReviews === 1 ? t('rating') : t('ratings')}
            </Text>
          </StyledReviewerInfo>
          <StyledReviews>
            <Reviews score={reviews?.averageScore} translations={{outOf: t('outOf'), rating: 'Feefo rating'}} />
          </StyledReviews>
        </StyledReviewHeader>
      </Box>
    )
  }
  return null
}
GuestReviewRating.propTypes = {
  reviews: PropTypes.shape({
    reviews: PropTypes.array,
    numberOfReviews: PropTypes.number,
    averageScore: PropTypes.number
  })
}

import styled from 'styled-components'
import { Box } from '@awaze/design-system.components.box'
import { Button } from '@awaze/design-system.components.button'

export const MessageWrapperStyled = styled(Box)`
  border-left: 5px solid #F7B226;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 24px;

  gap: 1rem;
  
  @media (max-width: 1024px) { 
    flex-direction: column; 
    align-items: flex-start;
  }
  `
export const ButtonStyled = styled(Button)`
  white-space: nowrap;
  padding: 0;
`

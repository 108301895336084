import Theme from '@/global/Theme'
import React from 'react'

const AdultsSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 8.5C3.208 8.5 1.75 7.042 1.75 5.25C1.75 3.458 3.208 2 5 2C6.792 2 8.25 3.458 8.25 5.25C8.25 7.042 6.792 8.5 5 8.5ZM5 3C3.75933 3 2.75 4.00933 2.75 5.25C2.75 6.49067 3.75933 7.5 5 7.5C6.24067 7.5 7.25 6.49067 7.25 5.25C7.25 4.00933 6.24067 3 5 3Z" fill={Theme.ColorGrey600}/>
    <path d="M9.5 14C9.224 14 9 13.776 9 13.5C9 11.2947 7.20533 9.5 5 9.5C2.79467 9.5 1 11.2947 1 13.5C1 13.776 0.776 14 0.5 14C0.224 14 0 13.776 0 13.5C0 10.7433 2.24333 8.5 5 8.5C7.75667 8.5 10 10.7433 10 13.5C10 13.776 9.776 14 9.5 14Z" fill={Theme.ColorGrey600}/>
    <path d="M11.818 9.5C10.302 9.5 9.068 8.266 9.068 6.75C9.068 5.23333 10.302 4 11.818 4C13.334 4 14.568 5.23333 14.568 6.75C14.568 8.266 13.334 9.5 11.818 9.5ZM11.818 5C10.8527 5 10.068 5.78533 10.068 6.75C10.068 7.71467 10.8527 8.5 11.818 8.5C12.7833 8.5 13.568 7.71467 13.568 6.75C13.568 5.78533 12.7833 5 11.818 5Z" fill={Theme.ColorGrey600}/>
    <path d="M15.5 14C15.224 14 15 13.776 15 13.5C15 13.124 14.934 12.754 14.8033 12.4013C14.5093 11.6033 13.922 10.9673 13.1493 10.6113C12.726 10.416 12.278 10.3167 11.818 10.3167C11.4433 10.3167 11.072 10.3833 10.714 10.5147C10.6587 10.5353 10.6007 10.5453 10.5413 10.5453C10.3327 10.5453 10.1447 10.414 10.072 10.2187C10.026 10.0933 10.0307 9.95733 10.0867 9.83667C10.1427 9.71533 10.2427 9.62267 10.368 9.57667C10.8333 9.40533 11.3187 9.318 11.8113 9.318C13.5587 9.318 15.1373 10.418 15.7407 12.0553C15.9127 12.5193 16 13.0053 16 13.5C16 13.7753 15.776 14 15.5 14Z" fill={Theme.ColorGrey600}/>
  </svg>

)

export default AdultsSvg

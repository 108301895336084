import styled from 'styled-components';
import { Flex, Box } from 'reflexbox/styled-components';
import { Button } from '@awaze/design-system.components.button';
export const GridWrap = styled(Flex)`
  margin: 1em 0;
  flex-wrap: wrap;
  border-radius: ${({ theme }) => theme.borderRadius};

  ${({ theme }) => theme.mediaQueries.phoneDS} {
    margin: 0;
  }
`;

export const GridWrapMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 1em 0;
  border-radius: ${({ theme }) => theme.borderRadius};

  ${({ theme }) => theme.mediaQueries.tabletDS} {
    margin: 0;
    grid-template-columns: 1fr 1fr;
  }
`;

export const StyledPreviousButton = styled(Button)`
  ${({ theme }) => theme.fonts.Link300}

  ${({ theme }) => theme.mediaQueries.laptopDS} {
    margin: 1rem auto;
    display: block;
    margin-top: ${({ hasSwitcher }) => (hasSwitcher ? '0rem' : '1rem')};
  }

  ${({ theme }) => theme.mediaQueries.tabletDownDS} {
    margin: 1rem auto;
    display: block;
    margin-top: ${({ hasSwitcher }) => (hasSwitcher ? '0rem' : '1rem')};
  }

  ${({ theme }) => theme.mediaQueries.phoneDS} {
    margin-bottom: 0.5rem;
  }
`;

export const CalendarWrapper = styled.div`
  ${({ yearCalendar, theme }) =>
    yearCalendar
      ? `
    background-color: ${theme.colors.white};
    padding: 0.625rem; /* 10px */
    border-radius: 0.375rem; /* 6px */
    border: 0.5px solid ${theme.colors.tertiaryButtonBorderHover};

    ${Box} {
      margin-bottom: 0;
    }
  `
      : `
    ${theme.mediaQueries.phoneDS} {
      margin: 0 -1.125rem; /* 0 -18px */
    }
`}
`;

export const Year = styled.h2`
  text-align: center;
  margin: 0 1rem;
`;

export const SwitcherContainer = styled.div`
  margin: 0.5rem 0 0 2.5rem;
  display: flex;
  gap: 1rem;
`;

import React from 'react'
import PropTypes, { array } from 'prop-types'
import Table from '@/components/Table'
import {
  Season,
  MobileItem,
  MobileItemContent,
  MobileItemCollapse,
  MobileItemCollapseRow,
  Chevron,
  StyledPercentage,
  MobileLastCellWrapper
} from './PriceRecommendationItem.styles'
import useWindowSize from '@/hooks/useWindowSize'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import useUserBrand from '@/hooks/useUserBrand/useUserBrand'
import {
  PRICE_REC_ITEM_PROPOSED_PRICE_COLUMN_INDEX,
  PRICE_REC_ITEM_PREVIOUS_PRICE_COLUMN_INDEX
} from '../PriceRecommendationHelpers/constants'

import {
  getIncludedColumnsDesktop,
  getIncludedColumnsMobile,
  showPercentageDifferenceOnPriceRecommendations
} from '../PriceRecommendationHelpers/recommendationItemHelperMethods'

dayjs.extend(customParseFormat)

const PriceRecommendationTableItem = ({ itemData, columnHeaders }) => {
  const { t } = useTranslation()
  const { isUserBrandNovasol } = useUserBrand()
  const { width } = useWindowSize()
  const isDesktop = width > 599
  const [isActive, setIsActive] = React.useState(false)

  const formatWeekCommencingDate = (dateString) => {
    const date = dayjs(dateString)
    return date.format('MMM DD')
  }

  const previousPrice = itemData?.data?.[PRICE_REC_ITEM_PREVIOUS_PRICE_COLUMN_INDEX]
  const proposedPrice = itemData?.data?.[PRICE_REC_ITEM_PROPOSED_PRICE_COLUMN_INDEX]

  let calculatedPercentageDiff = ''

  if (showPercentageDifferenceOnPriceRecommendations(proposedPrice, previousPrice, isUserBrandNovasol)) {
    calculatedPercentageDiff =
      (parseFloat(proposedPrice) / parseFloat(previousPrice)) * 100
    calculatedPercentageDiff = Math.round(calculatedPercentageDiff)
    calculatedPercentageDiff = Math.abs(100 - calculatedPercentageDiff)

    calculatedPercentageDiff =
      proposedPrice - previousPrice < 0
        ? calculatedPercentageDiff * -1
        : calculatedPercentageDiff

    // Add a plus sign for positive numbers
    if (calculatedPercentageDiff > 0) {
      calculatedPercentageDiff = `+${calculatedPercentageDiff}`
    } else {
      calculatedPercentageDiff = `${calculatedPercentageDiff}`
    }
  }

  // For desktop view
  const tableCellsFiltered = itemData?.data?.filter((_, index) =>
    getIncludedColumnsDesktop(isUserBrandNovasol).includes(index)
  )

  const tableCells = tableCellsFiltered.map((value, index, array) => {
    const formattedValue = parseFloat(value)
    const isPercentageDifferenceCell = index === array.length - 1 && isUserBrandNovasol

    return (
      <Table.Cell key={index} data-testid={'prTableItem'}>
        {isDesktop && (
          <>
            {formattedValue}
            {isPercentageDifferenceCell && (
              <StyledPercentage
                calculatedPercentageDiff={calculatedPercentageDiff}
              >
                {calculatedPercentageDiff}%
              </StyledPercentage>
            )}
          </>
        )}
      </Table.Cell>
    )
  })

  // For mobile view
  const mobileIndices = itemData?.data?.filter((_, index) =>
    getIncludedColumnsMobile(isUserBrandNovasol).includes(index)
  )

  const mobileCells = mobileIndices.map((value, index) => {
    const formattedValue = parseFloat(value)
    const isLastMobileCell = index === 1

    return (
      <MobileLastCellWrapper
        key={index}
        className={isLastMobileCell ? 'recommendedPriceContent' : ''}
      >
        <span data-testid='prMobileDataRow'>{formattedValue}</span>
        {isUserBrandNovasol && isLastMobileCell && (
          <StyledPercentage calculatedPercentageDiff={calculatedPercentageDiff}>
            {calculatedPercentageDiff}%
          </StyledPercentage>
        )}
      </MobileLastCellWrapper>
    )
  })

  const isSingleChar = itemData?.season.length === 1

  const getColumnText = (index) =>
    t(
      (columnHeaders?.[index]?.prefixText ?? '') +
        (columnHeaders?.[index]?.suffixText ?? ''),
      { year: columnHeaders?.[index]?.yearText }
    )

  return isDesktop ? (
    <Table.Row data-testid='prTableRow'>
      {itemData?.weekNumber !== 0 ? (
        <Table.Cell data-testid={'prTableItem'}>
          {t('week')} {itemData?.weekNumber}
          <span style={{ margin: '0 0.2rem' }}></span>(
          {formatWeekCommencingDate(itemData?.isoStartWeekDate)} -{' '}
          {formatWeekCommencingDate(itemData?.isoEndWeekDate)})
        </Table.Cell>
      ) : (
        <Table.Cell data-testid={'prTableItem'}>
          {formatWeekCommencingDate(itemData?.isoStartWeekDate)}
        </Table.Cell>
      )}
      <Table.Cell data-testid={'prTableItem'}>
        <Season
          className={itemData?.season.toLowerCase().replace(/\W/g, '-')}
          data-testid='season'
        >
          {itemData?.season}
        </Season>
      </Table.Cell>
      {tableCells}
    </Table.Row>
  ) : (
    <MobileItem
      data-testid='mob-item'
      onClick={() => setIsActive((prev) => !prev)}
    >
      <MobileItemContent isActive={isActive}>
        {itemData?.weekNumber !== 0 ? (
          <p className='mobile-item-date' data-testid='mobile-item-date'>
            <span>
              {t('week')}
              {itemData?.weekNumber}
            </span>
            <br />
            <span>({formatWeekCommencingDate(itemData?.isoStartWeekDate)}</span>
            -<br />
            <span>{formatWeekCommencingDate(itemData?.isoEndWeekDate)})</span>
          </p>
        ) : (
          <p className='mobile-item-date' data-testid='mobile-item-date'>
            <span>{formatWeekCommencingDate(itemData.isoStartWeekDate)}</span>
          </p>
        )}
        {mobileCells}
        <Chevron isActive={isActive} />
      </MobileItemContent>
      <MobileItemCollapse isOpen={isActive} data-testid='collapsedRow'>
        <MobileItemCollapseRow className='season-row'>
          <span>{t(`${columnHeaders && columnHeaders[1]?.prefixText}`)}:</span>
          <Season
            isSingleChar={isSingleChar}
            className={itemData.season.toLowerCase().replace(/\W/g, '-')}
          >
            {itemData.season}
          </Season>
        </MobileItemCollapseRow>
        {!isUserBrandNovasol && (
          <MobileItemCollapseRow>
            <span>{getColumnText(2)}</span>
            <p data-testid='collapsedMobileValue'>{`${parseFloat(itemData.data[0])}`}</p>
          </MobileItemCollapseRow>
        )}
        <MobileItemCollapseRow>
          <span>{getColumnText(3)}</span>
          <p data-testid='collapsedMobileValue'>{`${parseFloat(itemData.data[1])}`}</p>
        </MobileItemCollapseRow>
        <MobileItemCollapseRow>
          <span>{getColumnText(4)}</span>
          <p data-testid='collapsedMobileValue'>{`${parseFloat(itemData.data[2])}`}</p>
        </MobileItemCollapseRow>
        <MobileItemCollapseRow>
          <span>{getColumnText(5)}</span>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <p data-testid='collapsedMobileValue'>{`${parseFloat(itemData.data[3])}`}</p>
            {itemData.data[3] && isUserBrandNovasol && (
              <StyledPercentage data-testid='collapsedMobileValue'
                calculatedPercentageDiff={calculatedPercentageDiff}
              >
                {calculatedPercentageDiff}%
              </StyledPercentage>
            )}
          </div>
        </MobileItemCollapseRow>
      </MobileItemCollapse>
    </MobileItem>
  )
}

PriceRecommendationTableItem.propTypes = {
  itemData: PropTypes.object,
  columnHeaders: array
}

export default PriceRecommendationTableItem

import React from 'react'
import PropTypes from 'prop-types'
import useWindowSize from '@/hooks/useWindowSize'
import Tooltip from '@/components/__UI/Tooltip'
import { TodayPriceWrap, TickIcon } from '../PricingList'

const TodayPrice = ({ item, duration }) => {
  const { width } = useWindowSize()

  if (item.bookedDuration) {
    if (item.bookedDuration === duration.value) {
      return (
        <TodayPriceWrap>
          <p>£{parseInt(item.actualCustomerIncome).toFixed(2)}</p>
          {!(width < 600) && <Tooltip text='Booked for selected duration - Your income shows what you will actually receive' popUpWidth={190}><TickIcon /></Tooltip>}
        </TodayPriceWrap>
      )
    }

    return <p>Booked for {item.bookedDuration} nights</p>
  }

  const percentage = item.percentChange ? Math.ceil(item.percentChange) : null

  return (
    <TodayPriceWrap className={(item.style !== 'none' && !!percentage) ? item.style : ''}>
      {!!percentage && <span className="percentage">{percentage}%</span>}
      <p>£{item.currentCustomerPrice}</p>
    </TodayPriceWrap>
  )
}

TodayPrice.propTypes = {
  item: PropTypes.object,
  duration: PropTypes.object
}

export default TodayPrice

import { useQuery } from '@tanstack/react-query';
import { apiFetch } from '../helpers';

type PricingYearsInput = {
  propertyID: string | null;
}

export type PricingYearsResponse = {
  yearDurations: YearWithDurations[]
}

export type YearWithDurations = {
  year: number;
  durations: number[];
}

async function fetchPricingYearsData(input: PricingYearsInput): Promise<PricingYearsResponse> {
  return await apiFetch(`/v1/pricing/${input.propertyID}/years`);
}

export function useQueryPricingYears(input: PricingYearsInput) {
  const queryKey = ['pricingYears', input];
  const queryFn = () => fetchPricingYearsData(input);

  return useQuery({
    queryKey,
    queryFn,
    enabled: !!input.propertyID,
    cacheTime: 0,
  });
}

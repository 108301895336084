import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { apiFetch } from '@/hooks/useQuery/helpers'
import { baseKeyBookings, baseKeyBookingsInfinite } from '../queries'

type CancelBookingInput = {
  propertyId: string;
  reservationId: string;
}

type CancelBookingResponse = {
  cancelled: boolean;
  hasNotificationSent: boolean;
  hasSuppressedNotification: boolean;
}

export const mutationKeyCancelBooking = ['cancel-booking']

export function useMutationCancelBooking (options?: UseMutationOptions<CancelBookingResponse, any, CancelBookingInput>) {
  const client = useQueryClient()

  const mutationFn = (input: CancelBookingInput) => {
    return apiFetch(`/v1/properties/${input.propertyId}/bookings/${input.reservationId}`, { method: 'DELETE' })
  }

  return useMutation<CancelBookingResponse, any, CancelBookingInput>(mutationFn, {
    ...options,
    onSuccess (data, variables, context) {
      options?.onSuccess && options.onSuccess(data, variables, context)
    },
    onSettled () {
      // on success or error - refetch bookings
      client.invalidateQueries({ queryKey: baseKeyBookings })
      client.invalidateQueries({ queryKey: baseKeyBookingsInfinite })
    }
  })
}

import { useState } from 'react';
import { Drawer, DrawerContent, DrawerBody, DrawerFooter } from '@awaze/design-system.components.drawer';
import { useDisclosure } from '@awaze/design-system.utils.react'
import { StyledButton, StyledButtonApply, ButtonWrapper, StyledButtonClear} from './OccupancyPicker.styles';
import OccupancyPicker from './OccupancyPicker';
import { Text } from '@awaze/design-system.components.text';
import { UserCouple } from '@awaze/design-system.components.icon';
import { useBookingChartContext, DEFAULT_PARTY_PROFILE } from '../../__BookingChartComponents/context/BookingChartProvider';

const OccupancyPickerDrawer = () => {

const {
    partyProfile,
    setPartyProfile,
    } = useBookingChartContext();

  const { open, close, isOpen } = useDisclosure();
  const [adults, setAdults] = useState(partyProfile.adults);
  const [children, setChildren] = useState(partyProfile.children);
  const [infants, setInfants] = useState(partyProfile.infants);
  const [pets, setPets] = useState(partyProfile.pets);

  const handleApply = () => {
    setPartyProfile({ adults, children, infants, pets });
    close();
  };

  const totalGuests = adults + children + infants;

  const handleClear = () => {
    setAdults(DEFAULT_PARTY_PROFILE.adults);
    setChildren(DEFAULT_PARTY_PROFILE.children);
    setInfants(DEFAULT_PARTY_PROFILE.infants);
    setPets(DEFAULT_PARTY_PROFILE.pets);
    setPartyProfile(DEFAULT_PARTY_PROFILE);
    close();
  };

  return (
<>
  <StyledButton
    onClick={open}
    text={<Text>{`${totalGuests} Guests - ${pets} Pet(s)`}</Text>}
    icon={<UserCouple />}
    iconPosition="right"
  />
  <Drawer isOpen={isOpen} close={close} placement="right">
    <DrawerContent closeButtonTitle="Close" header="Guest">
      <DrawerBody>
        <OccupancyPicker
          adults={adults}
          setAdults={setAdults}
          numChildren={children}
          setNumChildren={setChildren}
          infants={infants}
          setInfants={setInfants}
          pets={pets}
          setPets={setPets}
        >
          {children}
        </OccupancyPicker>
      </DrawerBody>
      <DrawerFooter>
        <ButtonWrapper>
          <StyledButtonClear
            variant="ghost"
            colourScheme="brand-primary"
            onClick={handleClear}
            text="Clear"
          />
          <StyledButtonApply
            variant="solid"
            colourScheme="brand-primary"
            onClick={handleApply}
            text="Apply"
          />
        </ButtonWrapper>
      </DrawerFooter>
    </DrawerContent>
  </Drawer>
</>
  );
};

export default OccupancyPickerDrawer;

import React from 'react'
import PropTypes from 'prop-types'
import { Container, Name } from './OnlineStatementsItem.styles'
import OnlineStatementDownload from '../OnlineStatementDownload'

const OnlineStatementsItem = ({item, accountId}) => {
  const {id, monthlyDate, formats, templateId} = item
  const filename = monthlyDate.toLowerCase()

  return (
    <Container>
      <Name>{filename}</Name>
      {Array.isArray(formats) && formats.length > 0
        ? formats.map(({name}, index) => (
          <OnlineStatementDownload key={`${id}-${index}`} filename={filename} templateId={templateId} id={id} type={name} accountId={accountId} />
        ))
        : <p>No Formats found.</p>
      }
    </Container>
  )
}

OnlineStatementsItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    monthlyDate: PropTypes.string.isRequired,
    templateId: PropTypes.string.isRequired,
    formats: PropTypes.array.isRequired
  }),
  accountId: PropTypes.string.isRequired
}

export default OnlineStatementsItem

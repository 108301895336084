import React from 'react';
import { NoResultMessage } from './PropertyTableLayout.styles';
import { useBookingChartContext } from '../context/BookingChartProvider'
import { Text } from '@awaze/design-system.components.text';

export const NoPropertyErrorMessage = () => {

const { noResults } = useBookingChartContext()

  if (!noResults) {
    return null
  }

  return (
      <NoResultMessage>
        <Text>
          There are no properties available for the selected group of guests. Please adjust your guest selection to continue.
        </Text>
      </NoResultMessage>
    );
};

export default NoPropertyErrorMessage;

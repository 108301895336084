import {
  MobileItem,
  MobileItemContent,
  MobileItemCollapse,
  MobileItemCollapseRow,
  Season,
  Chevron
} from '../PricingList'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import PricingListTodayPrice from '../PricingListTodayPrice'

const PricingListMobileItem = ({ item, income, duration }) => {
  const [ isActive, setIsActive ] = useState(false)

  return (
    <MobileItem key={item.weekCommencing} onClick={() => setIsActive(prev => !prev)}>
      <MobileItemContent isActive={isActive}>
        <p className="mobile-item-date">{dayjs(item.weekCommencing).format('DD MMM')}</p>
        <p className="mobile-item-income">{income}</p>
        <PricingListTodayPrice item={item} income={income} duration={duration} />
        <Chevron isActive={isActive} />
      </MobileItemContent>
      <MobileItemCollapse isOpen={isActive}>
        <MobileItemCollapseRow className="season-row">
          <span>Season:</span>
          <Season className={item.miniSeason ? item.miniSeason.toLowerCase().replace(/\W/g, '-') : ''}>
            {item.miniSeason}
          </Season>
        </MobileItemCollapseRow>
        <MobileItemCollapseRow>
          <span>Your income:</span>
          <p>{income}</p>
        </MobileItemCollapseRow>
        <MobileItemCollapseRow>
          <span>{`Today's price`}:</span>
          <PricingListTodayPrice item={item} duration={duration} />
        </MobileItemCollapseRow>
        <MobileItemCollapseRow>
          <span>Max % decrease:</span>
          <p>{item.lowerCorridor}%</p>
        </MobileItemCollapseRow>
        <MobileItemCollapseRow>
          <span>Max % increase:</span>
          <p>{item.upperCorridor}%</p>
        </MobileItemCollapseRow>
      </MobileItemCollapse>
    </MobileItem>
  )
}

PricingListMobileItem.propTypes = {
  item: PropTypes.object,
  duration: PropTypes.object,
  income: PropTypes.string
}

export default PricingListMobileItem

export const handleDiacriticChars = (text) => {
  const diacriticChars = {
    'ę': 'e',
    'ł': 'l',
    'ś': 's',
    'ą': 'a',
    'ć': 'c',
    'ń': 'n',
    'ó': 'o',
    'ź': 'z',
    'ż': 'z'
  }
  return text.replace(/[ę,ł,ś,ą,ć,ń,ó,ź,ż]/g, match => diacriticChars[match] || match)
}

export default handleDiacriticChars

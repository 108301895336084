import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { NotPermittedPage, NotFoundPage, MaintenancePage } from '@/pages'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useUser } from '@/context/UserProvider'
import { useQueryMaintenanceStatus } from '@/hooks/useQuery'
import { isPermittedByBrand, isPermittedByProductMarket } from '@/constants/navigation'

export function isPermitted (route, permissions, user) {
  return isPermittedByPermissions(route, permissions) && isPermittedByBrand(route, user) && isPermittedByProductMarket(route, user)
}

export function isPermittedByPermissions (route, permissions) {
  // route requires no permissions
  if (!route.permissions) {
    return true
  }

  const requiredPermission = route.permissions || route.pageKey
  const foundPermission = permissions?.find(({ name }) => requiredPermission.indexOf(name) > -1)
  return !!(foundPermission?.authorised)
}

const RouteBuilder = ({ routes, redirectPath, localLink }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, permissions, loading } = useUser()
  const brand = user?.attributes?.brand
  const { data: maintenanceStatus } = useQueryMaintenanceStatus()

  // Update current url if previously visited link is given
  useEffect(() => {
    if (localLink) {
      navigate(localLink)
    }
  }, [navigate, localLink])

  const getRoutes = () => {
    const selectedRoutes = routes

    const outputRoutes = []

    selectedRoutes.forEach(route => {
      const permittedByPermissions = isPermittedByPermissions(route, permissions)
      const permittedByBrand = isPermittedByBrand(route, user)
      const permittedByProductMarket = isPermittedByProductMarket(route, user)

      if (permittedByPermissions && permittedByBrand && permittedByProductMarket) {
        // is permitted
        outputRoutes.push(<Route key={route.pageKey} {...route} element={<route.component />} />)
      } else if (permittedByPermissions && route.fallbackComponent) {
        // fallback component
        outputRoutes.push(<Route key={route.pageKey} {...route} element={<route.fallbackComponent />} />)
      } else {
        // not permitted
        outputRoutes.push(<Route key={route.pageKey} {...route} element={
          <NotPermittedPage
            permittedByPermissions={permittedByPermissions}
            permittedByBrand={permittedByBrand}
            permittedByProductMarket={permittedByProductMarket}
            brand={brand}
            path={route.path}
          />} />)
      }
    })

    return outputRoutes
  }

  // NOTE: ignore maintenance for signout
  const ignoredPathForMaintenance = ['/signout', '/user/logout']
  const isIgnoredPath = ignoredPathForMaintenance.includes(location.pathname)
  if (maintenanceStatus?.isUnderMaintenance && !isIgnoredPath) {
    return (<MaintenancePage />)
  }

  if (loading) {
    return null
  }

  return (
    <Routes>
      {getRoutes()}
      <Route path="*" element={<NotFoundPage redirectPath={redirectPath} brand={brand} />} />
    </Routes>
  )
}

RouteBuilder.displayName = 'RouteBuilder'

RouteBuilder.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  redirectPath: PropTypes.string,
  localLink: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.object)
}

export default RouteBuilder

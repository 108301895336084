import React from 'react'
import { Box } from '@awaze/design-system.components.box'
import { CategoriesGrid, CategoryItem } from './GuestReviewCategories.styles'
import { Text } from '@awaze/design-system.components.text'
import PropTypes from 'prop-types'

export const GuestReviewCategories = ({reviews}) => {
  return (
    <Box padding={[[0, 8, 8, 4]]}>
      <CategoriesGrid>
        {reviews?.overallCategories.map(category => (
          <CategoryItem key={category.id}>
            <Text type='mid' sizes={300}>{category.displayText}</Text>
            <Text type='mid' sizes={300}>{category.score}</Text>
          </CategoryItem>
        ))}
      </CategoriesGrid>
    </Box>
  )
}
GuestReviewCategories.propTypes = {
  reviews: PropTypes.shape({
    overallCategories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      displayText: PropTypes.string,
      score: PropTypes.number
    }))
  })
}

import React, {useState} from 'react'
import { useUser } from '@/context/UserProvider'
import { PageWrapper, Spinner } from '@/components/__UI'
import {
  PropertyAmendsSection, PropertyDescription,
  PropertyCheckInOutTime,
  PropertyImageThumbnails, PropertyFeaturesDisplay,
  PropertyArrival, PropertyWebsiteLink
} from '@/components/__PropertyAmendsComponents'
import { PropertyDropdownWrapper, StyledPropertyDropdown } from './PropertyAmendsPage.styles'
import { useLocation } from 'react-router-dom'

import IconPage from '@/assets/icons/icon-text-page.svg'
import IconArrival from '@/assets/icons/icon-traffic-sign.svg'
import IconImages from '@/assets/icons/icon-images.svg'
import IconClipboard from '@/assets/icons/icon-clipboard.svg'
import IconClock from '@/assets/icons/icon-clock.svg'

const PropertyAmendsPage = () => {
  const location = useLocation()
  const { user, loading, hasPermission } = useUser()
  const isPermitted = hasPermission('Bookings')
  const [ property, setProperty ] = useState(null)

  const getAmendsLocation = (property, section) => {
    const base = 'property-amends'
    const propertyId = (property && property.propertyId) || ''
    const propertyName = (property && property.propertyName) || ''

    return `/${base}/${section}/${propertyName}/${propertyId}`
  }

  if (!isPermitted && !loading) {
    return (
      <PageWrapper>
        <p style={{ textAlign: 'center' }}>You do not have permission to view this page</p>
      </PageWrapper>
    )
  }

  if (!user || loading) {
    return (
      <PageWrapper>
        <Spinner />
      </PageWrapper>
    )
  }

  return (

    <PageWrapper
      title='Accommodation amendments'
    >
      <PropertyDropdownWrapper>
        <StyledPropertyDropdown
          label
          onChange={setProperty}
          operationID="ViewPropertyDetail"
          isEnabled={isPermitted}
          defaultValue={location.state?.propertyId}
        />

      </PropertyDropdownWrapper>

      { property && property.propertyId &&
      <>
        <PropertyWebsiteLink property={property}></PropertyWebsiteLink>

        <PropertyAmendsSection
          data-testid="amendImages"
          title='Images'
          icon={IconImages}
          iconMargin={'7px'}
          viewMoreText='Edit images'
          viewMoreLink={getAmendsLocation(property, 'images')}
        >
          <PropertyImageThumbnails propertyId={property && property.propertyId} />
        </PropertyAmendsSection>

        <PropertyAmendsSection
          data-testid="amendFeatures"
          title='Accommodation features'
          icon={IconClipboard}
          viewMoreText='Edit features'
          viewMoreLink={getAmendsLocation(property, 'features')}
        >
          <PropertyFeaturesDisplay propertyId={property && property.propertyId} />
        </PropertyAmendsSection>

        <PropertyAmendsSection
          data-testid="amendInformation"
          title='Arrival information'
          icon={IconArrival}
          viewMoreText='Edit arrival information'
          viewMoreLink={getAmendsLocation(property, 'arrival')}
        >
          <PropertyArrival propertyId={property && property.propertyId} />
        </PropertyAmendsSection>

        <PropertyAmendsSection
          data-testid="amendDescription"
          title='Accommodation description'
          icon={IconPage}
          viewMoreText='Edit descriptions'
          viewMoreLink={getAmendsLocation(property, 'description')}
        >
          <PropertyDescription propertyId={property && property.propertyId} />
        </PropertyAmendsSection>

        <PropertyAmendsSection
          title='Check-in / Check-out time'
          icon={IconClock}
          iconMargin={'12px'}
          viewMoreText='Edit check-in / check-out time'
          // viewMoreLink={getAmendsLocation(property, 'times')}
        >
          <PropertyCheckInOutTime propertyId={property && property.propertyId} />
        </PropertyAmendsSection>
      </>
      }
    </PageWrapper>
  )
}

export default PropertyAmendsPage

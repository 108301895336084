import React, { createContext, useContext, useEffect, useState } from 'react'
import { LIST_ALL_CHART_PROPERTY_BOOKINGS } from '@/hooks/useGraphQl'
import { useBookingChartContext } from '@/components/__BookingChartComponents/context'
import { useBookingChartDatesContext } from '@/components/__BookingChartComponents/context/BookingChartDatesProvider'
import { addDaysToDate } from '@/helpers/dateUtils'
import toast from '@/helpers/toast'
import { useRefetchSubscribe } from '@/context/RefetchProvider'
import PropTypes from 'prop-types'
import { useQueryUnitChartAvailability, useQueryUnitChartBookings } from '@/hooks/useQuery'
import dayjs from 'dayjs'

export const BookingChartBookingsContext = createContext({
  bookingsByUnit: [],
  loadingBookings: true
})
export const useBookingChartBookingsContext = () => useContext(BookingChartBookingsContext)

export const BookingChartBookingsProvider = ({ children }) => {
  const { filteredSite } = useBookingChartContext()
  const { startDate, numberOfDays } = useBookingChartDatesContext()
  const [bookingsByUnit, setBookingsByUnit] = useState(null)
  const [availabilityByUnit, setAvailabilityByUnit] = useState(null)

  const input = {
    siteId: filteredSite.siteId,
    fromDate: startDate && dayjs(startDate).format('YYYY-MM-DD'),
    toDate: startDate && dayjs(addDaysToDate(startDate, numberOfDays)).format('YYYY-MM-DD')
  }
  const {
    data: bookings,
    isLoading: loadingBookings,
    refetch: refreshBookings
  } = useQueryUnitChartBookings(input, {
    onError: () => {
      toast.error('Failed to retrieve bookings')
    }
  })
  const {
    data: availability,
    isLoading: loadingAvailability
  } = useQueryUnitChartAvailability(input, {
    onError: () => {
      toast.error('Failed to retrieve availability')
    }
  })

  useEffect(function mapBookings () {
    if (bookings) {
      const unitMappedBookings = {}

      bookings.forEach(booking => {
        unitMappedBookings[booking.unitId] = booking.bookings
      })

      setBookingsByUnit(unitMappedBookings)
    }
  }, [bookings])

  useEffect(function mapAvailability () {
    if (availability) {
      const unitMappedAvailability = {}

      availability.forEach(a => {
        unitMappedAvailability[a.unitId] = a.unitAvailability.map(x => Number(x.isAvailable))
      })

      setAvailabilityByUnit(unitMappedAvailability)
    }
  }, [availability])

  useRefetchSubscribe(LIST_ALL_CHART_PROPERTY_BOOKINGS, refreshBookings)

  const clearBoth = React.useCallback(() => {
    setBookingsByUnit(null)
    setAvailabilityByUnit(null)
  }, [setBookingsByUnit, setAvailabilityByUnit])

  return (
    <BookingChartBookingsContext.Provider value={{
      bookingsByUnit: bookingsByUnit,
      loadingBookings: loadingBookings,
      unitAvailability: availabilityByUnit,
      loadingAvailability: loadingAvailability,
      setBookingsByUnit: clearBoth,
      setPerformBookingSearch: refreshBookings
    }}>
      {children}
    </BookingChartBookingsContext.Provider>
  )
}

BookingChartBookingsProvider.propTypes = {
  children: PropTypes.array
}

import styled from 'styled-components'

const PasswordInputWrapper = styled.div`
    position: relative;

    span.input-type-toggle {
        cursor: pointer;
        position: absolute;
        top: 40%;
        transform: translateY(-60%);
        right: 10px;
        padding: 0.5em;
    }
`

export { PasswordInputWrapper }

import React, {useState, useCallback} from 'react'
import { useCallToArmsContext } from '../context/CallToArmsProvider'
import { Link } from 'react-router-dom'
import {CallToArmsMessageModal, RecommendedPricesButton, ButtonsContainer} from './CallToArmsModal.styles'
import { Divider } from '@/components/__UI'

const CallToArmsModal = () => {
  const { showRecommendationButton } = useCallToArmsContext()
  const [visible, setVisible] = useState(true)

  const closeMessageHandlerWrapper = useCallback(
    () => {
      const date = new Date()
      date.setFullYear(date.getFullYear() + 1)
      setVisible((prev) => !prev)
      sessionStorage.setItem('messageShownOnce', true)
    }, []
  )

  return (
    <>
      { (showRecommendationButton && visible && !sessionStorage.getItem('messageShownOnce')) &&
        <CallToArmsMessageModal open={ showRecommendationButton } strict>
          <div className='messageRecommendation'>
            <h4>IMPORTANT – please review and respond to our pricing proposal</h4>
            <p>Now is a good time to adjust your pricing for the upcoming season. Setting the right price early will help you secure more bookings up front; please respond straight away to avoid missing out.</p>
          </div>
          <Divider size={ 3 } />
          <ButtonsContainer>
            <RecommendedPricesButton data-testid="callToArmsContinueBrowsingButton" onClick={closeMessageHandlerWrapper} primaryLine>Continue browsing</RecommendedPricesButton>
            {
              <Link to='/pricing-list/price-recommendations'>
                <RecommendedPricesButton data-testid="callToArmsNewPricesButton" primary>Review price recommendations</RecommendedPricesButton>
              </Link>
            }
          </ButtonsContainer>
        </CallToArmsMessageModal>
      }
    </>
  )
}

export default CallToArmsModal

import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 0.5rem;
`

const Icon = styled.img`
  display: inline-block;
  vertical-align: middle;
  height: 1.5rem;
`

const DownloadLink = styled.a`
  display: inline-block;
  vertical-align: middle;
  padding: 2px 0;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.blackTwo};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export { Container, Icon, DownloadLink }

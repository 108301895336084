import { filterPropertiesByRequestedAdultOccupancy } from '../Filters/AdultOccupancyFilter'
import { filterPropertiesByRequestedChildrenOccupancy } from '../Filters/ChildOccupancyFilter'
import { filterPropertiesByRequestedInfantsOccupancy } from '../Filters/InfantOccupancyFilter'
import { filterPropertiesByRequestedPetsOccupancy } from '../Filters/PetOccupancyFilter'
import { OccupancyFilterContext } from '../Models/OccupancyFilterContext'

export function filterPropertiesByRequiredOccupancy (occupancyFilterContext: OccupancyFilterContext) {

  let filteredPropertyContext = { ...occupancyFilterContext }

  filteredPropertyContext = filterPropertiesByRequestedAdultOccupancy(filteredPropertyContext)
  filteredPropertyContext = filterPropertiesByRequestedChildrenOccupancy(filteredPropertyContext)
  filteredPropertyContext = filterPropertiesByRequestedInfantsOccupancy(filteredPropertyContext)
  filteredPropertyContext = filterPropertiesByRequestedPetsOccupancy(filteredPropertyContext)

  return filteredPropertyContext.ongoingPropertyCollection
}

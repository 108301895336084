import { useQuery } from '@tanstack/react-query'
import { apiFetch } from '@/hooks/useQuery/helpers'

export type BookingByIdInput = string
export const queryKeyBookingSearch = (input: BookingByIdInput) => ['booking', input]

export function performBookingSearch (input: BookingByIdInput) {
  return apiFetch('/v1/properties/bookings/search', {
    method: 'POST',
    body: {
      'searchType': 'bookingReference',
      'searchValue': input
    }
  })
}

export function useQueryBookingSearch (input: BookingByIdInput, options) {
  const queryKey = queryKeyBookingSearch(input)
  const queryFn = () => performBookingSearch(input)
  return useQuery(queryKey, queryFn, {
    cacheTime: 0,
    enabled: input != null,
    ...options
  })
}

import styled from 'styled-components'
import { Button } from '@/components/__Inputs'
import SelectDropdown from '@/components/__Inputs/SelectDropdown'
import { Modal } from '@/components/__UI'

const StyledModal = styled(Modal)`
  padding: 2rem 1rem 1.5rem;

  ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
    padding: 3rem 6rem 2.5rem;
    max-width: 800px;
  }

  button[aria-label='close'] {
    right: 1rem;
    background-color: ${({theme}) => theme.colors.propertyImageEditModalBtn};
    padding: 0.75rem;

    &:before, &:after {
      background-color: ${({theme}) => theme.colors.black};
    }
  }
`

const Title = styled.h3`
  ${({ theme }) => theme.fonts.Heading200}
  text-align: center;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 2rem;

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    display: flex;
    flex-direction: column;
  }
`

const SubmitButton = styled(Button)`
  display: block;
  margin: 0.5rem auto 0;
  margin-right: 0.5rem;
`

const Disclaimer = styled.p`
  margin: 1rem 0;
`

const StyledDropdown = styled(SelectDropdown)`
  width: 200px !important;
`

const SubFeatures = styled.div`
  display: flex;
  flex: 0.3;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;

  & > * {
    width: 30%;
  }

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    flex: 0.2;

    & > * {
      width: 50%;
    }
  }
`

export { StyledModal, Title, ButtonContainer, SubmitButton, Disclaimer, SubFeatures, StyledDropdown }

import toast from 'cogo-toast'

const defaultOptions = {
  hideAfter: 5
}

export const messages = {
  authenticationIssue: 'Your session has expired, please sign out and sign back in to continue.',
  permissionsIssue: 'You are lacking the correct permissions.'
}

const appendErrorToMessage = (message, error) => {
  if (error.authenticationIssue) {
    return message + `${message.endsWith('.') ? '' : '.'} ${messages.authenticationIssue}`
  }
  if (error.permissionsIssue) {
    return message + `${message.endsWith('.') ? '' : '.'} ${messages.permissionsIssue}`
  }

  return message
}

const wrapperFunction = (target, name) => (message, options, error) => {
  if (error) {
    message = appendErrorToMessage(message, error)
  }

  return target[name](message, {
    ...defaultOptions,
    ...options
  })
}

/**
 * A proxy handler for cogo toast, if a logging method is requested,
 * return a wrapper instead that will call the function with the default arguments
 * as well as the provided options
 **/
const handler = {
  get: (target, name) => {
    if (typeof target[name] === 'function') {
      return wrapperFunction(target, name)
    }
    return target[name]
  }
}

export default new Proxy(toast, handler)

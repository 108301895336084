import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Modal } from '@awaze/design-system.components.modal'

import { ChooseStep } from './steps/ChooseStep'
import { ConfirmationStep } from './steps/ConfirmationStep'
import { SuccessStep } from './steps/SuccessStep'

const AcceptanceModal = ({modalOpen, setModalOpen, filteredProperties}) => {
  const navigate = useNavigate()

  type Step = 'choose' | 'confirm' | 'success'
  const [step, setStep] = useState<Step>('choose')

  const [filteredWithAccepted, setFilteredWithAccepted] = useState([])
  useEffect(() => {
    console.log('filtered props', filteredProperties)
    if (filteredProperties?.length > 0) {
      const filteredWithAccept = filteredProperties.map(property => ({...property, isAccepted: true}))
      setFilteredWithAccepted(filteredWithAccept)
    }
  }, [filteredProperties])

  const handleModalClose = () => {
    setModalOpen(false)
    return true
  }

  return (
    <Modal isOpen={modalOpen} close={handleModalClose}>
      {step === 'choose' && <ChooseStep filteredProperties={filteredWithAccepted} setFilteredWithAccept={setFilteredWithAccepted} gotoNextStep={() => setStep('confirm')} />}
      {step === 'confirm' && <ConfirmationStep filteredProperties={filteredWithAccepted} gotoNextStep={() => setStep('success')} closeModal={handleModalClose} />}
      {step === 'success' && <SuccessStep gotoNextStep={() => { navigate('/pricing-list') }} />}
    </Modal>
  )
}

AcceptanceModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  filteredProperties: PropTypes.array
}

export default AcceptanceModal

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { apiFetch } from '@/hooks/useQuery/helpers'
import dayjs from 'dayjs'

export type UnitChartAvailabilityInput = { siteId: string; fromDate: string; toDate: string }

export interface UnitChartAvailability {
    unitId: string;
    unitAvailability: {
        isAvailable: boolean;
        date: string;
    }[];
}

export const queryKeyUnitChartAvailability = (input: UnitChartAvailabilityInput) => ['unit-chart-availability', input]

export async function queryFnUnitChartAvailability ({ siteId, fromDate, toDate }: UnitChartAvailabilityInput) {
  const params = new URLSearchParams({ fromDate, toDate })
  const url = `/v1/site/${siteId}/availability?${params.toString()}`
  const data = await apiFetch<UnitChartAvailability[]>(url)
  return data
}

export function useQueryUnitChartAvailability (input: UnitChartAvailabilityInput, options: UseQueryOptions<UnitChartAvailability[]>) {
  input = {
    ...input,
    fromDate: dayjs(input.fromDate).format('YYYY-MM-DD'),
    toDate: dayjs(input.toDate).format('YYYY-MM-DD')
  }
  const queryKey = queryKeyUnitChartAvailability(input)
  const queryFn = () => queryFnUnitChartAvailability(input)
  const hasInput = (input.siteId != null && input.fromDate != null && input.toDate != null)

  return useQuery<UnitChartAvailability[]>(
    queryKey,
    queryFn,
    {
      enabled: hasInput,
      staleTime: 4000,
      cacheTime: 0,
      ...options
    })
}

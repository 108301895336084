import React from 'react'
import { Link } from 'react-router-dom'

import OwnerInsightsJune from '@/assets/news/cdc-owner-insights-june-2021.pdf'
import OwnerInsightsJuly from '@/assets/news/cdc-owner-insights-july-2021.pdf'

export default [
  {
    title: 'August news',
    postedOn: '2021-08-20',
    content:
        <div>
          <p><strong><u>Contacting the Owner Experience Team</u></strong></p>
          <p>We are still receiving an extremely high volume of calls and emails into our team. Please be assured that we’re working really hard to respond to everyone as quickly as we can and your patience is appreciated.  You can contact us via the web chat feature or through the <Link to='/contact-us'>Need Help?</Link> Section of the portal</p>
          <br />
          <br />
          <p><strong><u>Your monthly Owner Insights updates</u></strong></p>
          <p>At the end of every month, keep an eye on your email inbox for the latest edition of our Owner Insights news bulletin.  You can also catch up here:</p>
          <br />
          <p><a href={OwnerInsightsJune} target='_blank' rel='noopener noreferrer'>JUNE</a> & <a href={OwnerInsightsJuly} target='_blank' rel='noopener noreferrer'>JULY</a> PDF’s</p>
          <br />
          <br />
          <p><strong><u>Covid-19 Update</u></strong></p>
          <p>We’ve put together an FAQ document covering the following:</p>
          <ul>
            <li>Booking cancellations and re-bookings due to Covid</li>
            <li>Booking payments, refunds and queries on your rental income statement</li>
            <li>Hot tub guidance</li>
            <li>What you should do if one of your guests develops Covid symptoms during their stay</li>
            <li>NHS track and trace requirements.</li>
          </ul>
          <br />
          <p>If you have a question on any of the above topics please click <Link to='general-faq'>here</Link> to read our advice in full before contacting the team.</p>
          <p>For current information about the latest Covid-19 advice for guests, please click here <a href='https://www.cottages.com/covid-19' target='_blank' rel='noopener noreferrer'>https://www.cottages.com/covid-19</a></p>
          <br />
          <br />
          <p><strong><u>Safer Stays Initiative</u></strong></p>
          <p>Although restrictions have been largely relaxed, virus levels remain high and respective governments continue to advise caution. We are therefore continuing with our Safer Stays initiative for the time being, so if {`you've`} opted in, the feature will remain on your property description. {`We've`} made a few small revisions to our Safer Stays principles which you can take a look at <a href='https://s3.eu-west-2.amazonaws.com/documents.awaze.co.uk/Safer+stays+principles+July+2021.pdf' target='_blank' rel='noopener noreferrer'>here</a>.</p>
          <br />
          <p>{`It's`} worth noting that owners who have opted to follow the Safer Stays principles are currently enjoying an average of 30% more weeks than those who {`haven't`}. If you {`aren't`} opted in yet and would like to find out more, please contact us <a href='mailto:ownermarketing@cottages.com'>here</a>.</p>
          <br />
          <br />
          <p><strong><u>Safer Stays FAQ’s</u></strong></p>
          <ul>
            <li>
              <strong>Can I keep my normal arrival and departure times?</strong>
              <br />
            If you are able to carry out the full cleaning requirements within the time available before the next arrival then you can offer a standard arrival of 3pm and departure of 10am. Please let us know when you opt in so that we can ensure that your arrival instructions are correct.
            </li>
            <li>
              <strong>What do you class as non-essential items?</strong>
              <br />
            If you have opted into Safer Stays you should take a common-sense decision as to which ‘likely to be handled’ items are genuinely needed by guests during their stay.  Items which can be easily cleaned and/or sanitised between bookings can of course remain.  For example reference books, leaflets or maps could be temporarily removed fairly easily, but if you have a full library it might be more practical to leave the bookings in situ but display a notice advising that they have not been incorporated into your fuller cleaning regime.
            </li>
            <li>
              <strong>Should my property description be amended to make guests aware of items which have been removed?</strong>
              <br />
            No, this won’t be necessary as our Safer Stays guest information will make reference to the fact that some items may have been removed
            </li>
            <li>
              <strong>Should I ask guests to bring their own bedding and towels?</strong>
              <br />
            No, please continue to provide household linen as normal but ensure that everything is washed at the hottest possible temperature at every changeover.
            </li>
          </ul>
          <br />
          <br />
          <p><strong><u>Maximising booking opportunities for Christmas and the New Year</u></strong></p>
          <p>The positive news around the great British staycation continues to deliver very strong search demand right through into the Autumn. Where availability is stretched, we’re already seeing signs that guests are looking at alternative dates during periods when they previously may not have considered a holiday, such as Christmas and New Year.</p>
          <p>If you currently offer just one set arrival day option for Christmas and New Year, then we’d strongly recommend offering an <i>any day arrival</i> (except Christmas Day, Boxing Day and New Years’ Day) so that you can be sure that you’re matching the start days that guests are currently looking for.</p>
          <p>If you’d like more information then please let us know <a href='mailto:ownermarketing@cottages.com' target='_blank' rel='noopener noreferrer'>here</a>.</p>
          <br />
          <br />
          <p><strong><u>Looking to book your own holiday?</u></strong></p>
          <p>Don’t forget that you can enjoy a 15% discount on many holidays booked through cottages.com. To make a booking, call <a href='tel:0345 268 1900'>0345 268 1900</a> remembering to quote either your property or owner reference.</p>
        </div>
  },
  {
    title: 'Introducing Safer Stays',
    postedOn: '2021-08-11',
    content:
      <div>
        <p>Whilst Covid-19 restrictions have largely been relaxed, it remains important to reassure guests that the cleanliness of their holiday home is taken very seriously. We can communicate to guests that they can be confident in the cleanliness of the accommodation that we advertise if you are able to ensure that effective cleaning, sanitisation and where appropriate, social distancing measures are in place.</p>
        <br />
        <p><strong>We believe that a clear way of identifying to guests that we (and you) have considered their safety is through our Safer Stays initiative. The Safer Stays principles provide a framework that you may wish to follow. It is meant to serve as a starting point or baseline; it is not an exhaustive checklist, nor does it constitute advice.</strong></p>
        <br />
        <p>You should assess your own risk profile similar to what you would do for other health & safety matters. You should try to identify whether there are other or additional measures that you need to put in place in light of your particular circumstances and setting to ensure that guests are as safe as possible and that any risk of Covid-19 infection is reduced whilst at your accommodation.</p>
        <br />
        <p>It is very important that you familiarise yourself and stay up-to-date with any relevant</p>
        <br />
        <p>Government and industry guidance on the topic (including anything relevant specifically to your region) and you should follow this guidance. Once you are clear on this, please read our <a target='_blank' rel='noopener noreferrer' href='https://s3.eu-west-2.amazonaws.com/documents.awaze.co.uk/Safer+stays+principles+July+2021.pdf'>Safer Stays Principles</a>. If you are willing to adopt these principles, we will flag this on your property/properties on our website to give customers extra reassurance.</p>
        <br />
        <p><strong>If you would like your accommodation to be identified as part of the Safer Stays initiative you will need to agree, adopt and implement each of the Principles applicable to you and your property for every guest arrival by following the steps below:</strong></p>
        <br />
        <br />
        <p><strong><u>STEP 1</u></strong></p>
        <br />
        <p>Click <a target='_blank' rel='noopener noreferrer' href='https://www.gov.uk/government/collections/coronavirus-covid-19-list-of-guidance'>here</a> to review the latest Government and industry guidance</p>
        <br />
        <p><strong><u>STEP 2</u></strong></p>
        <br />
        <p>Review the Safer Stays principles <a target='_blank' rel='noopener noreferrer' href='https://s3.eu-west-2.amazonaws.com/documents.awaze.co.uk/Safer+stays+principles+July+2021.pdf'>here.</a></p>
        <br />
        <p><strong><u>STEP 3</u></strong></p>
        <br />
        <p>Click <a target='_blank' rel='noopener noreferrer' href='https://www.surveymonkey.co.uk/r/7YY7WJN?ORef=%7b%7bRecipient.ORef_Import%7d%7d'>here</a> to participate in SAFER STAYS</p>
        <br />
        <p>Please note that to enable us to gather responses consistently and keep a secure record of your participation, we cannot accept any replies by phone or email, so please do use the online form via the link above in Step 3.</p>
        <br />
        <p>We appreciate some of the Principles may take some time to co-ordinate and may incur additional cost, but we feel that this should be outweighed by the benefits of providing customers with the reassurance they need to book their holiday, enjoy their stay and leave a glowing review.</p>
      </div>
  },
  {
    title: 'Covid-19 Update',
    postedOn: '2020-10-14',
    content:
      <div>
        <p>
            For current information about what the latest Covid-19 advice is likely to mean for your guests and bookings,
            please <a title="covid-19" href="https://www.cottages.com/covid-19?thg=1"><strong>click here</strong></a>.
            For information about your monthly payment dates, the Safer Stays
            initiative and what to do if a guest falls ill please <a href="/assets/owner-covid-faqs.pdf?v=cb40a7c60b70ade4c2445ab1118a9f7a&amp;thg=1" target="_blank" rel="noopener noreferrer"><strong>click here</strong></a>.

          <br /><br />

          <u><strong>Contacting Owner Services</strong></u>
          <br/>
            We are still receiving an extremely high volume of calls and emails into our team. Please be assured that we’re
            working really hard to respond to everyone as quickly as we can and your patience is appreciated. You can
            contact us by phone Monday to Friday 9am – 5pm.

          <br /><br />

          <strong>To make an owner booking</strong>, please use the online calendar <Link to="/bookings/calendar">here</Link>,
            rather than sending your request to us by email. This enables you to check the live calendar straight away to
            see if there is already a guest booking in place. Please remember to check your email inbox for on owner booking
            confirmation email before you confirm to your own guest.

          <br /><br />
            Thank you for your continued support and patience.
        </p>
      </div>
  },
  {
    title: 'An update on your festive short breaks',
    postedOn: '2019-09-10',
    content:
        <div>
          <p>We’ll soon be opening up short breaks for the festive period.</p>
          <p>As an overwhelming 32% of 3 night searches have been for the 23rd and 30th December 2019, we will be opening up short breaks (including 3 night short breaks) for these dates over the next few weeks where your contract permits this.</p>
          <p>Although this will mean that the 26th December becomes a departure day, it will not be an arrival day. If you have any questions, please do not hesitate to contact us as normal.</p>
        </div>
  },
  {
    title: 'Are you signed up to online statements?',
    postedOn: '2019-08-13',
    content:
        <div>
          <p>Did you know that you can receive your monthly statement here on the owner website. {`We'll`} upload it as soon as {`it's`} due meaning you {`don't`} have to wait for the post. {`They're`} also available to download and save if you need to. <Link to="/financial/statements" target="_blank" rel="noopener noreferrer">Click here</Link> to find out more.</p>
        </div>
  },
  {
    title: 'Take advantage of the customer demand for short breaks this summer',
    postedOn: '2019-07-30',
    content:
        <div>
          <p>The summer season is almost upon us and with Brexit continuing to impact on decisions about whether to holiday at home or abroad, the marketplace is once again seeing some significant changes in booking trends and customer behaviour.</p>
          <p>We’re seeing striking levels of demand from customers looking to book a short stay during peak school holidays. A third of searches for July and August are for short break durations.  If your current booking pattern restricts us from being able to open up short breaks tactically throughout the year to make the most of this demand, or if you currently don’t offer short breaks then you could be missing out an additional bookings and income.</p>
          <p>We can quickly and easily adjust your booking pattern to offer short breaks more flexibly so if you’d like to benefit from these potential extra sales, please let us know by replying here: <a href="mailto:ownerservices@cottages.com" >ownerservices@cottages.com</a>.</p>
        </div>
  },
  {
    title: 'Does your accommodation have an interesting story to tell?',
    postedOn: '2019-07-02',
    content:
        <div>
          <p>We know that for many owners, creating the perfect holiday accommodation has been a real labour of love. No matter whether it’s a coastal fisherman’s cottage, a medieval castle, a cosy bolt hole in the heart of town or a super-modern “grand design”, if your holiday home has an interesting story to tell then get in touch and let us know. We’re always on the look-out for new material for our PR and marketing activity so we’d love to hear from you! Email us at <a href="mailto:ownermarketing@cottages.com">ownermarketing@cottages.com</a>.</p>
        </div>
  },
  {
    title: 'Look out for your new style booking confirmations!',
    postedOn: '2019-05-30',
    content:
        <div>
          <p>
            {`We'll`} soon be launching our new email notification system which means that we’ll let you know about any new bookings, amendments or cancellations within 15 minutes of them being made on our reservations system. Check your inbox for more information about this exciting new development.
            <br /><br />
            This brings the opportunity to review our late booking notification process and so we’ll be in touch during early May with a proposal to ring you about any last minute arrivals within 24 hours, rather than 2 days. This brings the significant benefit of enabling late bookings to be confirmed instantaneously, reducing the risk of losing the customer if we’ve not managed to get hold of you by telephone straight away. Your feedback is always welcome and so if you have any concerns or you’d like to opt in straight away, please let us know via ownermarketing@cottages.com.
          </p>
        </div>
  },
  {
    title: 'Boost your off-peak bookings',
    postedOn: '2019-05-30',
    content:
        <div>
          <p>The early months of the year can present different challenges for holiday homeowners. With harsher weather, shorter days and fewer families travelling, bookings can be harder to find.</p>
          <p>But with a little forward planning and the right amenities, you can entice new audiences to your property – like couples, groups, retirees and festive travellers. 62% of all the weeks booked on cottages.com are for non-school holiday dates, after all.</p>
          <p><a href="https://blog.cottages.com/let-your-property/" target="_blank" rel="noopener noreferrer">Here</a> are just some of the ways you can transform your holiday property into the most irresistible winter hideaway.</p>
        </div>
  },
  {
    title: 'News Flash',
    postedOn: '2019-04-11',
    content:
        <div>
          <p>Our parent group has a new name! It’s now Awaze – the leading holiday rentals and holiday resorts business in Europe, providing everyday amazing holiday experiences to more than eight million customers each year, with over 110,000 places to stay across 36 countries.</p>
          <p>There is no change to any of the market-leading brand names that we trade under, or to the terms of your contract with us.</p>
          <p>While you will start to see Awaze mentioned in some of our promotional material, or in the press when we explain our ownership structure, our existing brand marketing campaigns won’t alter otherwise and you don’t need to do anything as a result of the new parent group name.</p>
          <p>Please call or email us if you have any questions – or visit our parent group website at <a href="https://www.awaze.com" target="_blank" rel="noopener noreferrer">www.awaze.com</a></p>
          <p>Thank you for your continuing partnership with us.</p>
        </div>
  },
  {
    title: 'Partnership Plus Update',
    postedOn: '2019-02-25',
    content:
        <div>
          <p>{`We’re`} continuing to grow our partnership offers to give you exclusive access to a wider range of products and services to help you manage your holiday property.</p>
          <p>Are you considering adding to your holiday property portfolio? Staycation Creation offer a bespoke service to help you make the right decisions with buying, establishing and managing a holiday let.  Take a look <Link to="/benefits">here</Link> for more information.</p>
        </div>
  },
  {
    title: 'Looking to book your own holiday?',
    postedOn: '2019-02-25',
    content:
        <div>
          <p>Don’t forget that you can enjoy a 15% discount on all cottage and villa holidays booked through cottages.com.  To make a booking, call 0345 268 1900 remembering to quote either your property or owner reference.</p>
        </div>
  }
]

import React from 'react'
import { Button } from '@awaze/design-system.components.button'
import type { IButtonProps } from '@awaze/design-system.components.button'

import styled from 'styled-components'
import { OvalLoadingSpinner } from '@/components/__UI'

const Spinner = styled(OvalLoadingSpinner)`
    /* counter-styling of design system button icon */
    svg path {
        fill: unset;
        stroke: currentColor;
    }
`

interface ButtonDSProps {
    loading?: boolean;
}

export function ButtonDS ({ loading, text, icon, ...rest }: ButtonDSProps & IButtonProps) {
  return (
    <Button
      {...rest }
      text={loading ? '' : text}
      icon={loading ? (
        <Spinner size="small" />
      ) : icon}
    />
  )
}

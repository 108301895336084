import * as Yup from 'yup'
import {
  EMAIL_ERROR,
  EMAIL_REQUIRED,
  EMAIL_LENGTH,
  EMAIL_LENGTH_ERROR,
  PASSWORD_ERROR,
  PASSWORD_RGX,
  PASSWORD_REQUIRED,
  CONFIRM_PASSWORD_MATCH,
  CONFIRM_PASSWORD_REQUIRED,
  OTC_ERROR,
  OTC_REQUIRED,
  OTC_RGX,
  POSTCODE_REQUIRED,
  PHONE_NUMBER_ERROR,
  PHONE_NUMBER_REQUIRED
} from './auth'

const SCHEMA_FIELD_USERNAME = Yup.string()
  .email(EMAIL_ERROR)
  .required(EMAIL_REQUIRED)
  .max(EMAIL_LENGTH, EMAIL_LENGTH_ERROR)

const SCHEMA_FIELD_PASSWORD = Yup.string()
  .min(5, PASSWORD_ERROR)
  .matches(PASSWORD_RGX, PASSWORD_ERROR, { excludeEmptyString: true })
  .required(PASSWORD_REQUIRED)

const SCHEMA_FIELD_CONFIRM_PASSWORD = Yup.string()
  .oneOf([Yup.ref('newPassword'), null], CONFIRM_PASSWORD_MATCH)
  .required(CONFIRM_PASSWORD_REQUIRED)

const SCHEMA_FIELD_OTC = Yup.string()
  .max(6, OTC_ERROR)
  .matches(OTC_RGX, OTC_ERROR, { excludeEmptyString: true })
  .required(OTC_REQUIRED)

const SCHEMA_FIELD_POSTCODE = Yup.string()
  // .matches(new RegExp(/^(GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\d[\dA-Z]?[ ]?\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\d{1,4})$/, 'gi'), POSTCODE_ERROR)
  // .matches(new RegExp(/[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}/, 'gi'), POSTCODE_ERROR)
  .required(POSTCODE_REQUIRED)

const SCHEMA_FIELD_PHONE_NUMBER = Yup.string()
  .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, PHONE_NUMBER_ERROR)

export {
  SCHEMA_FIELD_USERNAME,
  SCHEMA_FIELD_PASSWORD,
  SCHEMA_FIELD_CONFIRM_PASSWORD,
  SCHEMA_FIELD_OTC,
  SCHEMA_FIELD_POSTCODE,
  SCHEMA_FIELD_PHONE_NUMBER,
  PHONE_NUMBER_REQUIRED
}

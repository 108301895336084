import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import { useForm } from '@/components/__Inputs/Form'
import { StyledInputControl, StyledLabel, StyledInput, StyledErrorMessage } from '../input.styles'

const TextInput = ({ className, disabled, label, ...props }) => {
  const { disabled: formDisabled } = useForm()
  const [field, meta] = useField(props)

  return (
    <StyledInputControl className={className}>
      { label && <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel> }
      <StyledInput
        id={props.id || props.name}
        {...field}
        {...props}
        disabled={formDisabled || disabled}
        hasError={meta.touched && meta.error}
      />
      {meta.touched && meta.error ? (
        <StyledErrorMessage>{meta.error}</StyledErrorMessage>
      ) : null}
    </StyledInputControl>
  )
}

TextInput.displayName = 'TextInput'

TextInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  validation: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'email', 'password', 'tel']),
  id: PropTypes.string,
  error: PropTypes.string
}

export default TextInput

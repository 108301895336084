import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { useUser } from '@/context/UserProvider'
import { PageContainer, SelectContainer, ErrorMessage } from './UnitChartPage.styles'
import { PageWrapper } from '@/components/__UI'
import PageHeader from '@/components/__BookingChartComponents/pageHeader/PageHeader'
import {
  BookingChartProvider,
  BookingChartDatesProvider
} from '@/components/__BookingChartComponents/context'
import { BookingChartBookingsProvider } from '@/components/__BookingChartComponents/context/Bookings'
import { PropertyTableLayout } from '@/components/__BookingChartComponents/propertyTableLayout/PropertyTableLayout'
import { NoPropertyErrorMessage } from '@/components/__BookingChartComponents/propertyTableLayout/NoPropertyErrorMessage'

const UnitChartPage = () => {
  const { hasPermission } = useUser()
  const hasPermissionToView = hasPermission('Chart')

  const GlobalStyle = createGlobalStyle`
  body{
    background-color: ${({theme}) => theme.colors.white}
  }
  `

  if (!hasPermissionToView) {
    return (
      <PageWrapper title="Unit Chart">
        <ErrorMessage>
          You do not have permissions to view this page. If you have the wrong
          permissions, please contact the administration{' '}
        </ErrorMessage>
      </PageWrapper>
    )
  } else {
    return (
      <BookingChartProvider>
        <BookingChartDatesProvider>
          <BookingChartBookingsProvider>
            <GlobalStyle />
            <PageContainer>
              <SelectContainer>
                <PageHeader />
              </SelectContainer>
              <NoPropertyErrorMessage />
              <PropertyTableLayout />
            </PageContainer>
          </BookingChartBookingsProvider>
        </BookingChartDatesProvider>
      </BookingChartProvider>
    )
  }
}

export default UnitChartPage

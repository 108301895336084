import {useEffect} from 'react'

const beforeNavigationListeners = []
const navigationListeners = []

function checkPreventPageChange (to, isReactNavigation) {
  return beforeNavigationListeners.map(l => l({to, isReactNavigation})).find(r => r)
}

function onPageChange (to, isReactNavigation) {
  return navigationListeners.map(l => {
    return l ? l({to, isReactNavigation}) : null
  })
}

export const defaultMessage = 'Are you sure you want to navigate away from this page? You will lose your changes'

function initialize () {
  const {pushState, replaceState} = window.history

  window.history.pushState = (...args) => {
    const prevent = checkPreventPageChange(args[2], true)
    const message = typeof prevent === 'string' ? prevent : defaultMessage
    if (!prevent || window.confirm(message)) {
      onPageChange(args[2], true)
      pushState.apply(window.history, args)
    }
  }

  window.history.replaceState = (...args) => {
    const prevent = checkPreventPageChange(args[2], true)
    const message = typeof prevent === 'string' ? prevent : defaultMessage

    if (!prevent || window.confirm(message)) {
      onPageChange(args[2], true)
      replaceState.apply(window.history, args)
    }
  }

  const handler = event => {
    const prevent = checkPreventPageChange(null, false)
    if (prevent) {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }
    onPageChange(null, false)
  }
  window.addEventListener('beforeunload', handler)

  return () => {
    window.history.pushState = pushState
    window.history.replaceState = replaceState
    window.removeEventListener('beforeunload', handler)
  }
}

export function useOnBeforeNavigation (handler) {
  useEffect(() => {
    beforeNavigationListeners.push(handler)
    return () => beforeNavigationListeners.splice(beforeNavigationListeners.indexOf(beforeNavigationListeners), 1)
  }, [handler])
}

export function useOnNavigation (handler) {
  useEffect(() => {
    navigationListeners.push(handler)
    return () => navigationListeners.splice(navigationListeners.indexOf(navigationListeners), 1)
  }, [handler])
}

export default () => {
  useEffect(() => {
    const cleanup = initialize()
    return () => cleanup()
  }, [])

  return null
}

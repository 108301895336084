
const BOOKING_STATE_CONFIRMED = 'Confirmed'
const BOOKING_STATE_CANCELLED = 'Cancelled'
const BOOKING_STATE_EXPIRED = 'Expired'
const BOOKING_STATE_PROVISIONAL = 'Provisional'
const BOOKING_STATE_OWNER_COMMISSIONABLE = 'Owner Commission'
const BOOKING_STATE_OWNER_RESERVED = 'Owner Reserved'
const BOOKING_STATE_OWNER_UNCHECKED = 'Unchecked Owner'
const BOOKING_TYPE_OWNER = 'Owner'
const BOOKING_TYPE_OWNER_CLEAN = 'Owner - with clean'
const BOOKING_TYPE_CUSTOMER = 'Customer'
const BOOKING_TYPE_CUSTOMER_CLEAN = 'Customer - with clean'
const BOOKING_TYPE_OTHER = 'Other'
const BOOKING_TYPE_UNAVAILABLE = 'Unavailability'
const BOOKING_TYPE_CANCELLED = 'Cancelled'
const BOOKING_STATE_CHANGEOVER = 'Changeover'
const BOOKING_MAX_DURATION = 21

// new consolidated state names
const CONSOLIDATED_BOOKING_STATE_CUSTOMER = 'Customer'
const CONSOLIDATED_BOOKING_STATE_OWNER = 'Owner'
const CONSOLIDATED_BOOKING_STATE_OWNER_WITH_CLEAN = 'OwnerWithClean'
const CONSOLIDATED_BOOKING_STATE_PROVISIONAL = 'Provisional'
const CONSOLIDATED_BOOKING_STATE_UNCHECKED_OWNER = 'UncheckedOwner'
const CONSOLIDATED_BOOKING_STATE_OWNER_RESERVED = 'OwnerReserved'
const CONSOLIDATED_BOOKING_STATE_OWNER_COMMISSION = 'OwnerCommission'
const CONSOLIDATED_BOOKING_STATE_CANCELLED = 'Cancelled'
const CONSOLIDATED_BOOKING_STATE_EXPIRED = 'Expired'
const CONSOLIDATED_BOOKING_STATE_CUSTOMER_WITH_CLEAN = 'CustomerWithClean'
const CONSOLIDATED_BOOKING_STATE_OPTION = 'Option'
const CONSOLIDATED_BOOKING_STATE_GUEST_CONFIRMED = 'GuestConfirmed'
const CONSOLIDATED_BOOKING_STATE_OWNER_CONFIRMED = 'OwnerConfirmed'
const CONSOLIDATED_BOOKING_STATE_OTHER = 'Other'
const CONSOLIDATED_BOOKING_STATE_CHANGEOVER = 'Changeover'
const CONSOLIDATED_BOOKING_STATE_OWNER_AVAILABLE = 'OnlyReturnedDatesBookable'

export {
  BOOKING_STATE_CONFIRMED,
  BOOKING_STATE_CANCELLED,
  BOOKING_STATE_EXPIRED,
  BOOKING_STATE_PROVISIONAL,
  BOOKING_TYPE_OWNER,
  BOOKING_TYPE_OWNER_CLEAN,
  BOOKING_STATE_OWNER_COMMISSIONABLE,
  BOOKING_STATE_OWNER_RESERVED,
  BOOKING_STATE_OWNER_UNCHECKED,
  BOOKING_STATE_CHANGEOVER,
  BOOKING_TYPE_CUSTOMER,
  BOOKING_TYPE_UNAVAILABLE,
  BOOKING_TYPE_CANCELLED,
  BOOKING_MAX_DURATION,
  BOOKING_TYPE_CUSTOMER_CLEAN,
  BOOKING_TYPE_OTHER,
  CONSOLIDATED_BOOKING_STATE_CUSTOMER,
  CONSOLIDATED_BOOKING_STATE_OWNER,
  CONSOLIDATED_BOOKING_STATE_OWNER_WITH_CLEAN,
  CONSOLIDATED_BOOKING_STATE_PROVISIONAL,
  CONSOLIDATED_BOOKING_STATE_UNCHECKED_OWNER,
  CONSOLIDATED_BOOKING_STATE_OWNER_RESERVED,
  CONSOLIDATED_BOOKING_STATE_OWNER_COMMISSION,
  CONSOLIDATED_BOOKING_STATE_CANCELLED,
  CONSOLIDATED_BOOKING_STATE_EXPIRED,
  CONSOLIDATED_BOOKING_STATE_CUSTOMER_WITH_CLEAN,
  CONSOLIDATED_BOOKING_STATE_OPTION,
  CONSOLIDATED_BOOKING_STATE_GUEST_CONFIRMED,
  CONSOLIDATED_BOOKING_STATE_OWNER_CONFIRMED,
  CONSOLIDATED_BOOKING_STATE_OTHER,
  CONSOLIDATED_BOOKING_STATE_CHANGEOVER,
  CONSOLIDATED_BOOKING_STATE_OWNER_AVAILABLE
}

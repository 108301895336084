import React, { useState } from 'react'
import PropTypes from 'prop-types'
import toast from '@/helpers/toast'
import { Form, Button, Checkbox } from '@/components/__Inputs'
import { Container, Spinner } from '@/components/__UI'
import { capitalise } from '@/helpers/stringUtils'
import useGraphQl, {
  graphQlFetch,
  GET_PROPERTY_FEATURES,
  UPDATE_PROPERTY_FEATURES
} from '@/hooks/useGraphQl'
import {
  PropertyAmendSuccessModal,
  PropertyFeaturesDetailsModal
} from '@/components/__Modals'
import {
  CategorySection,
  ErrorText,
  CategoryTitle,
  CategorySectionContainer
} from './PropertyFeatures.styles'
import theme from '@/global/Theme'

const PropertyFeatures = ({ propertyId }) => {
  const [showModal, setShowModal] = useState(false)
  const [currentFeature, setCurrentFeature] = useState('')
  const [featuresCompiledList, setFeaturesCompiledList] = useState([])
  const [hasError, setHasError] = useState(false)
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [loading, setLoading] = useState(false)

  const [featureList, loadingFeatures] = useGraphQl({
    query: GET_PROPERTY_FEATURES,
    variables: {
      propertyId
    },
    onSuccess: (data) => {
      const list = data.map(({ features }) => features).flat()
      setFeaturesCompiledList(list)
    }
  })

  const setDifferentStyleForCategoryIfNeeded = (className) => {
    return className && className === 'eco_category' && 'green_category'
  }

  const getInitialValues = (values) => {
    const initialValues = {}
    values.forEach(({ features }) => {
      features.forEach(({ id, enabled, quantity }) => {
        initialValues[id] = {}
        initialValues[id].enabled = enabled
      })
    })

    return initialValues
  }

  const getChangedContent = (val) => {
    const output = []
    Object.keys(val).forEach((submitId) => {
      const startVal = featuresCompiledList.find(({ id }) => submitId === id)
      const submitVal = val[submitId]
      let name = ''
      if (startVal) {
        name = startVal.description || ''
      }
      if (
        !startVal ||
        startVal.enabled !== submitVal.enabled ||
        submitVal.additionalDetails
      ) {
        const item = { id: submitId, enabled: submitVal.enabled, name }
        if (submitVal.additionalDetails) { item.additionalDetails = submitVal.additionalDetails }
        output.push(item)
      }
    })

    return output
  }

  const handleSubmit = async (val) => {
    const changed = getChangedContent(val)
    setHasError(false)
    setLoading(true)

    if (changed.length > 0) {
      try {
        const data = await graphQlFetch({
          query: UPDATE_PROPERTY_FEATURES,
          variables: {
            propertyId,
            features: changed
          }
        })
        if (data.success) {
          setShowSubmitModal(true)
          setSubmitDisabled(true)
        } else {
          setHasError(true)
          setSubmitDisabled(false)
        }
      } catch (error) {
        console.log(error)
        setHasError(true)
        setSubmitDisabled(false)
      }
    } else {
      toast.info('No Changes detected.')
      setSubmitDisabled(true)
    }
    setLoading(false)
  }

  if (loadingFeatures) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  return featureList && featureList.length > 0 ? (
    <>
      <Form
        id="PropertyFeaturesForm"
        initialValues={getInitialValues(featureList)}
        handleSubmit={handleSubmit}
      >
        <CategorySectionContainer>
          {featureList.map(({ category, features }, index) => (
            <CategorySection
              key={index}
              className={setDifferentStyleForCategoryIfNeeded(
                `${category.toLowerCase().replace(' ', '_')}_category`
              )}
            >
              <CategoryTitle>{capitalise(category)}</CategoryTitle>
              {features.map(({ id, description }, key) => (
                <Checkbox
                  key={key}
                  right={false}
                  name={`${id}.enabled`}
                  label={description}
                  color={theme.colors.white}
                  tickColor={theme.colors.primary}
                  borderColor={theme.colors.primary}
                  onChange={(checked) => {
                    setSubmitDisabled(false)
                    if (checked) {
                      setShowModal(true)
                      setCurrentFeature({ featureId: id, description })
                    }
                  }}
                />
              ))}
            </CategorySection>
          ))}
        </CategorySectionContainer>
        {showModal && (
          <PropertyFeaturesDetailsModal
            feature={currentFeature}
            propertyId={propertyId}
            setShowModal={setShowModal}
          />
        )}
        {hasError && (
          <ErrorText>
            There has been an issue submitting your response.
          </ErrorText>
        )}
        <Button
          data-testid="submitPropertyFeaturesBtn"
          primary
          loading={loading}
          disabled={submitDisabled}
          type="submit"
        >
          Submit
        </Button>
      </Form>
      {showSubmitModal && (
        <PropertyAmendSuccessModal onClose={setShowSubmitModal} />
      )}
    </>
  ) : (
    <p>Unable to find features.</p>
  )
}

PropertyFeatures.propTypes = {
  propertyId: PropTypes.string
}

export default PropertyFeatures

import { useEffect, useRef } from 'react'

/**
 * an extension for useEffect to deeply compare the objects listed in the dependencies,
 * this will only rerun the side effect function if values within the dependency objects
 * have been updated
 * @param {Function} func the side effect function to run
 * @param {Object[]} deps dependencies of the useEffect call
 */

const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b)

const useDeepEffect = (func, deps) => {
  const isFirst = useRef(true)
  const previousDeps = useRef(deps)

  useEffect(() => {
    const isSame = previousDeps.current.every((obj, i) => isEqual(obj, deps[i]))

    if (isFirst.current || !isSame) {
      func()
    }

    isFirst.current = false
    previousDeps.current = deps
  }, [deps, func])
}

export default useDeepEffect

import { getLocale } from '@/helpers/locale';
import {
  AppStoreBadgeEn,
  AppStoreBadgeDa,
  AppStoreBadgeDe,
  AppStoreBadgeEs,
  AppStoreBadgeFr,
  AppStoreBadgeHr,
  AppStoreBadgeIt,
  AppStoreBadgeNb,
  AppStoreBadgeNl,
  AppStoreBadgePl,
  AppStoreBadgeSl,
  AppStoreBadgeSv,
} from '@/components/__UI/Footer/AppStoreBadges';

export const getAppStoreBadgeLocalized = () => {
  const locale = getLocale();

  switch (locale) {
    case 'en':
    case 'en-gb':
      return AppStoreBadgeEn;
    case 'da':
      return AppStoreBadgeDa;
    case 'de':
      return AppStoreBadgeDe;
      case 'es':
      return AppStoreBadgeEs;
    case 'fr':
      return AppStoreBadgeFr;
    case 'hr':
      return AppStoreBadgeHr;
    case 'it':
      return AppStoreBadgeIt;
    case 'nb':
      return AppStoreBadgeNb;
    case 'nl':
      return AppStoreBadgeNl;
    case 'pl':
      return AppStoreBadgePl;
    case 'sl':
      return AppStoreBadgeSl;
    case 'sv':
      return AppStoreBadgeSv;
    default:
      throw new Error('Unsupported locale');
  }
};

export const getAppStoreUrlsLocalized = () => {
  const locale = getLocale();

  switch (locale.toLowerCase()) {
    case 'en':
    case 'en-gb':
      return 'https://apps.apple.com/us/app/awaze/id1603067183';
    case 'da':
      return 'https://apps.apple.com/dk/app/awaze/id1603067183?l=da';
    case 'de':
      return 'https://apps.apple.com/de/app/awaze/id1603067183';
      case 'es':
      return 'https://apps.apple.com/es/app/awaze/id1603067183';
    case 'fr':
      return 'https://apps.apple.com/fr/app/awaze/id1603067183';
    case 'hr':
      return 'https://apps.apple.com/hr/app/awaze/id1603067183?l=hr';
    case 'it':
      return 'https://apps.apple.com/it/app/awaze/id1603067183';
    case 'nb':
      return 'https://apps.apple.com/no/app/awaze/id1603067183?l=nb';
    case 'nl':
      return 'https://apps.apple.com/nl/app/awaze/id1603067183';
    case 'pl':
      return 'https://apps.apple.com/pl/app/awaze/id1603067183?l=pl';
    case 'sl':
      return 'https://apps.apple.com/us/app/awaze/id1603067183'; // No Slovenian app store
    case 'sv':
      return 'https://apps.apple.com/se/app/awaze/id1603067183';
    default:
      throw new Error('Unsupported locale');
  }
};
import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { apiFetch } from '@/hooks/useQuery/helpers'
import { baseKeyBookings } from '../queries'

interface PropertyOwnerBookingRequest {
  startDate: string;
  endDate: string;
  requiresCleaning: boolean;
  unitId: string;
  leadPassenger: {
    title: string;
    forename: string;
    surname: string;
  };
  extras: {
    id: number;
    name: string;
    amount: number;
    quantity: number;
    currencyCode: string;
  }[];
}

interface PropertyCreateOwnerBookingResponse {
  bookingReference: string;
  hasNotificationSent: boolean;
  hasSuppressedNotification: boolean;
}

export const mutationKeyCreateOwnerBooking = ['create-owner-booking']

export function useMutationCreateOwnerBooking (options?: UseMutationOptions<PropertyCreateOwnerBookingResponse, Error, { propertyId: string; bookingData: PropertyOwnerBookingRequest }>) {
  const client = useQueryClient()

  const mutationFn = ({ propertyId, bookingData }: { propertyId: string; bookingData: PropertyOwnerBookingRequest }) => {
    return apiFetch(`/v1/properties/${propertyId}/bookings/owner`, {
      method: 'POST',
      body: bookingData
    })
  }

  return useMutation<PropertyCreateOwnerBookingResponse, Error, { propertyId: string; bookingData: PropertyOwnerBookingRequest }>(mutationFn, {
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context)
      }

      client.invalidateQueries({ queryKey: baseKeyBookings })
    }
  })
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { graphQlFetch, LIST_USER_PROPERTY_COLLECTION } from '@/hooks/useGraphQl'

export const queryKeyListPropertyCollection = ['list-property-collection']

interface PropertyUnit {
  unitId: string;
  unitCode: string;
  maximumPets: number;
}

interface UserProperty {
  propertyId: string;
  propertyCode: string;
  propertyName: string;
  displayImage: string;
  propertyUnits: PropertyUnit[];
}

export interface SiteUserProperty {
  siteName: string;
  siteCode: string;
  siteId: string;
  userProperties: UserProperty[];
}

export type ListPropertyCollection = {
  siteUser: boolean;
  siteUserProperties: SiteUserProperty[];
}

export function useQueryListPropertyCollection (variables: any, options?: UseQueryOptions<ListPropertyCollection>) {
  const queryKey = queryKeyListPropertyCollection

  const queryFn = () => graphQlFetch({ query: LIST_USER_PROPERTY_COLLECTION, variables })

  return useQuery<ListPropertyCollection>(queryKey, queryFn, {
    staleTime: Infinity,
    ...options
  })
}

import styled from 'styled-components'

const NewsItem = styled.div`
    padding: 1rem;
    margin: 1rem 0;
    border-radius: ${({ theme }) => theme.borderRadius};
    background-color: ${({ theme }) => theme.colors.white};

    h2 {
        margin-bottom: 0.2rem;
    }

    span {
        display: block;
        font-size: 0.8rem;
        font-style: italic;
        margin-bottom: 1rem;
    }

    ${({ theme }) => `${theme.mediaQueries.phoneOnly} {
        h2 {
            font-size: 1.2rem;
        }

        p {
            font-size: 0.8rem;
        }
    }`}
`

export { NewsItem }

import { getEnvironment } from '@/helpers/environment'

const FLAGS = {
  defaults: {
    debugBar: false,
    'owner-services-mfe': false,
    'owner-services-mfe-contact-us': true,
    'web-chat-test': false,
    'vite': true
  },
  localhost: {
    debugBar: true,
    'owner-services-mfe': true,
    'owner-services-mfe-contact-us': false,
    'web-chat-test': true
  },
  dev: {
    debugBar: true,
    'owner-services-mfe': false,
    'owner-services-mfe-contact-us': false,
    'web-chat-test': true
  },
  ppe: {
    debugBar: false,
    'owner-services-mfe': false,
    'owner-services-mfe-contact-us': true,
    'web-chat-test': true
  }
}

export function isEnabled (flag, flags = FLAGS) {
  const environment = getEnvironment()

  const environmentFlags = {
    ...flags.defaults,
    ...flags[environment]
  }

  return environmentFlags[flag]
}

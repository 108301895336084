import { OccupancyFilterContext } from '../Models/OccupancyFilterContext'
import { Property } from '../Models/Property'

export const filterPropertiesByRequestedChildrenOccupancy = (context: OccupancyFilterContext) : OccupancyFilterContext => {
  const filteredProperties = filterPropertiesByRequiredChildrenCount(context)

  if (filteredProperties.length === 0) {
    return { ongoingPropertyCollection: filteredProperties, occupancyToFilterOn: context.occupancyToFilterOn }
  }

  return { ongoingPropertyCollection: filteredProperties, occupancyToFilterOn: context.occupancyToFilterOn }
}

const filterPropertiesByRequiredChildrenCount = (context: OccupancyFilterContext): Property[] => {
  const updatedCollection = context.ongoingPropertyCollection
    .filter((property) => {
      return (
        property.propertyCapacity.maxChildren + property.propertyCapacity.maxAdults >= 
        context.occupancyToFilterOn.requiredChildren + context.occupancyToFilterOn.requiredAdults
      )
    })

  return updatedCollection
}

import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { usePermittedNavigation } from '@/constants/navigation'

export const useActiveLink = () => {
  const location = useLocation()
  const { NAV_HEADER_ITEMS, NAV_PRE_HEADER_ITEMS } = usePermittedNavigation()

  const activeLink = useMemo(() => {
    const pathname = location.pathname

    const foundItem =
    NAV_HEADER_ITEMS.find(item => item.url === pathname) ||
    NAV_PRE_HEADER_ITEMS.find(item => item.url === pathname)

    let nestedItem = null
    for (const item of NAV_HEADER_ITEMS) {
      if (item.links) {
        for (const nested of item.links) {
          if (nested.url === pathname) {
            nestedItem = nested
          }
        }
      }
    }

    return foundItem?.page ?? nestedItem?.label
  }, [location.pathname, NAV_HEADER_ITEMS, NAV_PRE_HEADER_ITEMS])

  return activeLink
}

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Container, SelectWrapper, StyledSelect, Title } from './SecondaryQuestions.styles'
import { StyledFormBuilder } from '@/components/__ContactComponents/QuestionArea/QuestionArea.styles'

const SecondaryQuestions = ({ className, data, onSubmit }) => {
  const options = data.questions.map((item, i) => ({ id: i + 1, ...item }))

  const [ question, setQuestion ] = useState(options[0])

  const handleSubmit = (values, funcs) => {
    onSubmit({
      ...values,
      salesforceType: question.salesforceType,
      salesforceSubType: question.salesforceSubType
    }, funcs)
  }

  return (
    <Container className={className}>
      {data.title && <Title>{data.title}</Title>}

      <SelectWrapper>
        <StyledSelect
          label={data.label}
          id={data.name}
          options={options}
          value={question}
          getOptionLabel={option => option['question']}
          getOptionValue={option => option['id']}
          onChange={setQuestion}
        />
      </SelectWrapper>

      <StyledFormBuilder
        onSubmit={handleSubmit}
        formID={`secondary-question-${question.id}`}
        submitText="Submit enquiry"
        {...question.formOptions}
      />
    </Container>
  )
}

SecondaryQuestions.propTypes = {
  className: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  onSubmit: PropTypes.func
}

export default SecondaryQuestions

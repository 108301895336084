import React from 'react'
import { useBookingChartDatesContext } from '../context/BookingChartDatesProvider'
import { getUserFriendlyDateNumberFromDate, getUserFriendlyDayFromDate, getUserFriendlyMonthFromDate, isToday } from '@/helpers/dateUtils'
import dayjs from 'dayjs'
import { getGridRowStyle } from '../shared/ChartFunctions'
import { DateHeader } from './DatesHeader.styles'

const BookingChartDatesHeader = () => {
  const {
    startDate,
    numberOfDays
  } = useBookingChartDatesContext()

  const buildDateHeader = (dateObject, index) => {
    return (
      <DateHeader key={index} scope="col" style={index !== 2 ? getGridRowStyle(index, numberOfDays) : {}}>
        <div className={`fullDatesInfo ${dateObject.renderMonth && index !== 0 ? 'withBorder' : ''}` }>
          {
            dateObject.renderMonth
              ? <div className='month'>
                { getUserFriendlyMonthFromDate(dateObject.date) }
              </div>
              : <div className='emptyMonth'>
              </div>
          }
          <div data-testid='pickedUpDate' className={`daysDate ${iSSaturdayOrSunday(dateObject.date) ? 'weekendDay' : 'otherDay'} ${isToday(dateObject.date) ? 'currentDay' : ''} ${!isToday(dateObject.date) && index === 2 ? 'pickedDate' : ''}`}>
            {getUserFriendlyDayFromDate(dateObject.date)}
            <p className={`dates ${iSSaturdayOrSunday(dateObject.date) ? 'weekendDay' : 'otherDayNumber'}`}>
              {getUserFriendlyDateNumberFromDate(dateObject.date)}
            </p>
          </div>
        </div>
      </DateHeader>
    )
  }

  const iSSaturdayOrSunday = (dayOfWeek) => {
    return getUserFriendlyDayFromDate(dayOfWeek) === 'Sa' || getUserFriendlyDayFromDate(dayOfWeek) === 'Su'
  }

  const buildAllDateHeaders = () => {
    return getAllDatesToRenderHeadersFor().map((date, index) => buildDateHeader(date, index))
  }

  const getAllDatesToRenderHeadersFor = () => {
    const datesToRender = [{ date: startDate, renderMonth: true }]
    const startDateToOffset = dayjs(startDate)

    let offset = 1
    while (offset < numberOfDays) {
      const newHeaderDate = startDateToOffset.add(offset, 'day')
      const isNewMonthDate = newHeaderDate.date() === 1
      datesToRender.push({date: new Date(newHeaderDate.year(), newHeaderDate.month(), newHeaderDate.date()), renderMonth: isNewMonthDate})
      offset++
    }

    return datesToRender
  }

  return (
    <>
      { buildAllDateHeaders() }
    </>
  )
}

export default BookingChartDatesHeader

import styled from 'styled-components'
import SelectDropdown from '@/components/__Inputs/SelectDropdown'

const Container = styled.div`
    padding-top: 1rem;
`

const Title = styled.h2`
    margin: 1rem;
`

const SelectWrapper = styled.div`
    padding: 1rem;

    label {
        text-align: left;
        margin-bottom: .5rem;
    }

`

const StyledSelect = styled(SelectDropdown)`
    .react_select__value-container {
        justify-content: flex-start;
    }

    ${({ theme }) => `${theme.mediaQueries.phoneOnly} {
        z-index: 1;
    }`}
`

export { Container, SelectWrapper, StyledSelect, Title }

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import FormikField from '@/components/__Inputs/FormikField'
import SelectDropdown from '@/components/__Inputs/SelectDropdown'
import { useField } from 'formik'

export const PropertyDescriptionEpcSelect = ({ id }) => {
  const [field] = useField({ name: id })

  const options = useMemo(() =>
    ['A', 'B', 'C', 'D', 'E', 'F', 'G'].map(rating => ({
      label: `EPC Rating = ${rating}`,
      value: `EPC Rating = ${rating}`
    })), [])

  const selectedValue = useMemo(() =>
    options.find(x => x.value === field.value)
  , [options, field.value]
  )

  return (
    <FormikField
      name={field.name}
      value={selectedValue}
      inputType="select"
      component={SelectDropdown}
      options={options}
      placeholder="Please select a rating"
    />
  )
}

PropertyDescriptionEpcSelect.propTypes = {
  startingValue: PropTypes.object,
  id: PropTypes.string,
  onValueChange: PropTypes.func
}

export default PropertyDescriptionEpcSelect

import React from 'react'
import PropTypes from 'prop-types'
import useWindowSize from '@/hooks/useWindowSize'
import Tooltip from '@/components/__UI/Tooltip'
import { ListHeader, StyledSelect, StyledPropertySelect, YearControl, WeeklyStatsMenu } from './PricingListControls.styles'
import { useTranslation } from 'react-i18next'

const PricingListControls = ({
  onPropertiesLoaded,
  onPropertyChange,
  yearData,
  durationData,
  mobileControlDisabled,
  tooltips
}) => {
  const { width } = useWindowSize()
  const { t } = useTranslation()

  const handleYearUpdate = (action) => {
    const { value } = yearData.value
    const { options, onChange } = yearData

    const currentValue = Number(value)
    const index = options.findIndex(({ value }) => value === currentValue)

    if (action === 'previous' && currentValue !== options[0]) {
      onChange(options[index - 1])
    } else if (action === 'next' && currentValue !== options[options.length - 1].value) {
      onChange(options[index + 1])
    }
  }

  const propertySelector = (
    <div className="list-control">
      <StyledPropertySelect
        label
        onSuccess={onPropertiesLoaded}
        operationID="viewFinancialReports"
        onChange={onPropertyChange}
      />
    </div>
  )

  const durationSelector = (
    <div className="list-control">
      <StyledSelect
        label={t('duration')}
        tooltip={t('durationTooltip')}
        loadingText={t('durationsLoading')}
        { ...durationData } />
    </div>
  )

  if (width < 600) {
    const yearValue = yearData.value ? yearData.value.value : null

    return (
      <YearControl
        disabled={mobileControlDisabled}
        topOffset={-32}
        stickyStyle={{
          paddingTop: '2rem',
          backgroundColor: '#F1F4F6',
          transition: ' 0.7s ease-in-out'
        }}
      >
        <div className="year-control-inner">
          {
            (yearData.loading || !yearValue)
              ? <p>Loading...</p>
              : (
                <>
                  <span
                    className="arrow left"
                    disabled={yearValue === yearData.options[0].value}
                    onClick={() => handleYearUpdate('previous')}
                  />

                  <p>{yearValue}</p>

                  <span
                    className="arrow right"
                    disabled={yearValue === yearData.options[yearData.options.length - 1].value}
                    onClick={() => handleYearUpdate('next')}
                  />
                </>
              )
          }
        </div>
        <div className="year-control-items">
          {propertySelector}
          {durationSelector}
        </div>
        <WeeklyStatsMenu>
          <div className="year-control-headers">
            <p> Week com. <br /> (Thursday) </p>
            <p> Your <br /> income <Tooltip text={tooltips.incomeTooltip} /> </p>
            <p> {`Today's`} <br/> customer price <Tooltip text={tooltips.customerPriceTooltip} /> </p>
          </div>
        </WeeklyStatsMenu>
      </YearControl>
    )
  }

  return (
    <>
      <ListHeader>
        <div className="list-header-inner">
          {propertySelector}
          <div className="list-control">
            <StyledSelect
              label={t('year')}
              loadingText={(t('loadingYears'))}
              {...yearData}
            />
          </div>
          {durationSelector}
        </div>
      </ListHeader>
    </>

  )
}

const dataType = PropTypes.exact({
  value: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func,
  loading: PropTypes.bool
})

PricingListControls.propTypes = {
  onPropertiesLoaded: PropTypes.func,
  onPropertyChange: PropTypes.func,
  yearData: dataType,
  durationData: dataType,
  mobileControlDisabled: PropTypes.bool,
  tooltips: PropTypes.object
}

export default PricingListControls

import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useUser } from '@/context/UserProvider'
import { PageWrapper, Spinner } from '@/components/__UI'
import { StyledButton } from './PropertyAmendsEditLayout.styles'

const PropertyAmendsEditLayout = ({propertyId, children}) => {
  const { name } = useParams()
  const { user, loading, hasPermission } = useUser()
  const isPermitted = hasPermission('Bookings')

  if (!isPermitted && !loading) {
    return (
      <PageWrapper>
        <p style={{ textAlign: 'center' }}>You do not have permission to view this page</p>
      </PageWrapper>
    )
  }

  if (!user || loading) {
    return (
      <PageWrapper>
        <Spinner />
      </PageWrapper>
    )
  }

  return (
    <PageWrapper>
      <StyledButton to='/property-amends'
        state={{propertyId: propertyId}}>{`Back to ${name}`}</StyledButton>
      {children}
    </PageWrapper>
  )
}

PropertyAmendsEditLayout.propTypes = {
  propertyId: PropTypes.string,
  children: PropTypes.array
}

export default PropertyAmendsEditLayout

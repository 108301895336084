import React, { useRef, useEffect } from 'react'

export interface InfiniteScrollTriggerProps extends React.HTMLAttributes<HTMLDivElement>{
  onVisible: () => void;
  threshold?: number;
}

export function InfiniteScrollTrigger ({
  onVisible,
  threshold = 1.0,
  ...rest
}: InfiniteScrollTriggerProps) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onVisible && onVisible()
          }
        })
      },
      { threshold }
    )
    if (ref.current) observer.observe(ref.current)

    return () => observer.disconnect()
  }, [onVisible, threshold])

  return <div ref={ref} {...rest} />
}

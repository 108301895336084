import { useFormikContext } from 'formik'
import PropTypes from 'prop-types'

export const ReviewResponseErrorMessage = ({ hasLegalWords, hasProfanity, moderationWords }) => {
  const { setErrors, errors } = useFormikContext()

  if (hasProfanity) {
    setErrors({ ...errors, 'responseContent': 'Your comment include swear words or obscenities. Please alter before you submit.' })
  } else if (hasLegalWords) {
    setErrors({
      ...errors, 'responseContent': `Your response include keywords that will result in a moderation process. Please amend highlighted words or accept that your response will be going through moderation from Awaze before being published. Words in moderation: ${moderationWords.join(', ')}` })
  }

  return null
}

ReviewResponseErrorMessage.propTypes = {
  hasLegalWords: PropTypes.bool,
  hasProfanity: PropTypes.bool,
  moderationWords: PropTypes.arrayOf(PropTypes.string)
}

export default ReviewResponseErrorMessage

import React, {useState} from 'react'
import { AdminBarContainer, SearchSection, SearchInput, SearchButton, ButtonLink, SearchIcon } from './AdminBar.styles'
import { Search } from '@awaze/design-system.components.icon'
import { Text } from '@awaze/design-system.components.text'
import { setEmployeeProperty } from './api/setEmployeeProperty'
import { useQueryUserAuthorisations } from '@/hooks/useQuery/queries/useQueryUserAuthorisations'

const AdminBar = () => {
  const [propertyId, setPropertyId] = useState('')

  const { data: userAuthorisations } = useQueryUserAuthorisations()

  const isEmployee = userAuthorisations?.some(auth => auth.name === 'employee' && auth.authorised)

  const handleInputChange = (event) => {
    setPropertyId(event.target.value)
  }
  const handleSearchClick = () => {
    if (propertyId) {
      setEmployeeProperty(propertyId)
    }
  }

  return (
    isEmployee &&
    <AdminBarContainer>
      <SearchSection>
        <SearchIcon>
          <Search iconColour={'ColorGrey100'}/>
        </SearchIcon>
        <SearchInput
          data-testid='search-input'
          type='text'
          value={propertyId}
          onChange={handleInputChange}
        />
        <SearchButton
          onClick={handleSearchClick}
          text={<Text sizes={[100, 200, 300]}>Search rental</Text>}
          variant='solid'
        />
        <ButtonLink to="/admin" data-testid="admin-link">
          <Text sizes={[100, 300, 300]}>Admin</Text>
        </ButtonLink>
      </SearchSection>
    </AdminBarContainer>
  )
}

export default AdminBar

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Heading } from '@awaze/design-system.components.text';
import type { IconProps } from '@awaze/design-system.components.icon';
import { ChevronButton, StyledChevronLeft, StyledChevronRight, YearPickerContainer } from './YearPickerChevrons.styles';

interface YearPickerChevronsProps {
    year: number;
    onChange: (year: number) => void;
    minYear?: number;
    maxYear?: number;
    /**
     * validYear should be in sorted (ascending order) and without duplicates
     */
    validYears?: number[];

    iconSize?: IconProps['size']
    gap?: string;
}

function getNextValidYear(year: number, validYears: number[]): number | undefined {
    const currentYearIndex = validYears.indexOf(year)
    if (currentYearIndex < validYears.length - 1) {
        return validYears[currentYearIndex + 1]
    }
}

function getPrevValidYear(year: number, validYears: number[]): number | undefined {
    const currentYearIndex = validYears.indexOf(year)
    if (currentYearIndex > 0) {
        return validYears[currentYearIndex - 1]
    }
}

export function YearPickerChevrons({ year, onChange, minYear, maxYear, validYears, iconSize = 'medium', gap }: YearPickerChevronsProps) {
    const  { t } = useTranslation()

    const hasPrevYear = useMemo(() => {
        if (validYears) {
            return getPrevValidYear(year, validYears) !== undefined
        } 
        if (minYear !== undefined) {
            return year > minYear
        }
        return true
    }, [year, minYear, validYears])

    const hasNextYear = useMemo(()=> {
        if (validYears) {
            return getNextValidYear(year, validYears) !== undefined
        }
        if (maxYear !== undefined) {
            return year < maxYear
        }
        return true
    }, [year, maxYear, validYears])

    const handlePrevYear = () => {
        if (validYears) {
            const prevValidYear = getPrevValidYear(year, validYears)
            if (prevValidYear !== undefined) {
                onChange(prevValidYear)
            }
        } else {
            onChange(year - 1)
        }
    }
    const handleNextYear = () => {
        if (validYears) {
            const nextValidYear = getNextValidYear(year, validYears)
            if (nextValidYear !== undefined) {
                onChange(nextValidYear)
            }
        } else {
            onChange(year + 1)
        }
    }

    return (
        <YearPickerContainer $gap={gap}>

            <ChevronButton onClick={handlePrevYear} disabled={!hasPrevYear} data-testid="prevYearChevron" aria-label={t('previousYear')}>
                <StyledChevronLeft iconColour={hasPrevYear ? 'ColorThemeLink' : 'ColorBlackOpacity20'} size={iconSize} />
            </ChevronButton>

            <Heading sizes={100}>{year}</Heading>

            <ChevronButton onClick={handleNextYear} disabled={!hasNextYear} data-testid="nextYearChevron" aria-label={t('nextYear')}>
                <StyledChevronRight iconColour={hasNextYear ? 'ColorThemeLink' : 'ColorBlackOpacity20'} size={iconSize} />
            </ChevronButton>

        </YearPickerContainer>
    )

}
import React from 'react'
import PropTypes from 'prop-types'
import { ContainerCalendar, Tooltip } from '@/components/__UI'
import { useMenuContext } from '../Header/context/context'

const PageWrapperCalendar = ({title, tooltipText, children}) => {
  const {closeSubmenu} = useMenuContext()

  return (
    <ContainerCalendar onMouseOver={closeSubmenu}>
      {title && <h1 data-testid="pageWrapperTitle"> {title} {tooltipText && <Tooltip text={tooltipText} />}</h1>}
      {children}
    </ContainerCalendar>
  )
}

PageWrapperCalendar.propTypes = {
  title: PropTypes.string,
  tooltipText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default PageWrapperCalendar

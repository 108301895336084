import React from 'react'
import { Svg } from './SpecialRequestsIcon.styles'

const SpecialRequestsIcon = (props) => (
  <div>
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21">
      <path d="M10 0.5C4.48333 0.5 0 4.98333 0 10.5C0 16.0167 4.48333 20.5 10 20.5C15.5167 20.5 20 16.0167 20 10.5C20 4.98333 15.5167 0.5 10 0.5ZM9.68333 5.5C10.2 5.5 10.6167 5.91667 10.6167 6.43333C10.6167 6.95 10.2 7.36667 9.68333 7.36667C9.16667 7.36667 8.75 6.95 8.75 6.43333C8.75 5.91667 9.16667 5.5 9.68333 5.5ZM11.875 14.875H11.25C10.2167 14.875 9.375 14.0333 9.375 13V9.875H8.75C8.40833 9.875 8.125 9.59167 8.125 9.25C8.125 8.90833 8.40833 8.625 8.75 8.625H9.375C10.0667 8.625 10.625 9.18333 10.625 9.875V13C10.625 13.3417 10.9083 13.625 11.25 13.625H11.875C12.2167 13.625 12.5 13.9083 12.5 14.25C12.5 14.5917 12.2167 14.875 11.875 14.875Z" fill="#49647E"/>
    </Svg>
  </div>
)

export default SpecialRequestsIcon

import { useMutation } from '@tanstack/react-query'
import { ApiFetchError, apiFetch } from '@/hooks/useQuery/helpers'

type RecommendedPricingUpdateInput = {
  year: number;
  language: string;
  referenceDataToUpdate: {
    propertyId: string;
    propertyCode: string;
    recommendationState: 'approved' | 'rejected';
  }[];
}

type RecommendedPricingUpdateResponse = {
  success: boolean;
}

export function useMutationRecommendedPricingUpdate () {
  const mutationFn = async (input: RecommendedPricingUpdateInput): Promise<RecommendedPricingUpdateResponse> => {
    try {
      await apiFetch(
        `/v1/pricing/recommendations/summary`, { method: 'PUT', body: input }
      )
      return { success: true }
    } catch (err) {
      if (err instanceof ApiFetchError) {
        return { success: err.response.ok }
      }
      return { success: false }
    }
  }

  return useMutation<RecommendedPricingUpdateResponse, any, RecommendedPricingUpdateInput>(mutationFn)
}

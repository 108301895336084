import tokens from './DesignTokens'

const breakpoints = [
  '599px',
  '600px',
  '768px',
  '1200px',
  '1800px',
  '340px',
  '950px',
  '1024px'
]

export const createFontStyles = (font) => {
  return `
    font-family: ${tokens[`${font}FontFamily`]}, Helvetica, Arial, sans-serif;
    font-size: ${tokens[`${font}FontSize`] / 16}rem;
    font-weight: ${tokens[`${font}FontWeight`]};
    letter-spacing: ${tokens[`${font}LetterSpacing`] / 16}rem;
    line-height: ${tokens[`${font}LineHeight`] / 16}rem;
  `
}

export const consts = {
  animationSpeed: '0.35s',
  borderRadius: '4px',
  borderRadiusIcon: '12px',
  colors: {
    primary: 'rgba(64, 115, 77, 1)', // #40734D
    primaryDarken: 'rgba(40, 76, 44, 1)', // #284C2C
    primaryDisabled: 'rgba(194, 210, 198, 1)', // #C2D2C6
    secondary: 'rgba(87, 117, 144, 1)', // #577590
    secondaryDarken: 'rgba(66, 85, 102, 1)', // #425566
    secondaryDisabled: 'rgba(201, 211, 219, 1)', // #C9D3DB
    primaryTwo: 'rgba(255, 255, 255, 1)', // #ffffff
    primaryThree: 'rgba(172, 194, 128, 1)', // #Acc280
    primaryFour: 'rgba(242, 244, 246, 1)', // #F2F4F6
    error: 'rgba(165, 22, 29, 1)', // #A5161D
    attention: 'rgba(165, 22, 29, 1)', // #A5161D
    attentionDarken: 'rgba(110, 15, 20, 1)', // #6E0F14
    attentionDisabled: 'rgba(226, 180, 183, 1)', // #E2B4B7
    success: 'rgba(25, 128, 56, 1)', // #198038
    black: 'rgba(48, 48, 48, 1)', // #303030
    blackTwo: 'rgba(51, 51, 51, 1.0)', // #333333
    grey: 'rgba(102, 102, 102, 1)', // #666666
    greyTwo: 'rgba(144, 144, 144, 1)', // #909090
    greyThree: 'rgba(196, 196, 196, 1)', // #c4c4c4
    greyFour: 'rgba(216, 216, 216, 1)', // #d8d8d8
    greyFive: 'rgba(240, 240, 240, 1)', // #f0f0f0
    greySix: 'rgba(248, 248, 248, 1)', // #f8f8f8
    contrast: 'rgba(255, 255, 255, 1)', // #FFFFFF
    white: 'rgba(255, 255, 255, 1)', // #FFFFFF
    yellow: 'rgba(244, 157, 55, 1)', // #F49D37
    disabled: 'rgba(178, 186, 196, 1)', // #B2BAC4
    activeBookingBtn: 'rgba(222, 222, 222, 1)',

    closed: 'rgba(195, 195, 195, 1)', // #C3C3C3
    owner: 'rgba(175, 91, 119, 1)', // #AF5B77
    customer: 'rgba(172, 194, 128, 1)', // #8EB265
    provisional: 'rgba(244, 203, 122, 1)', // #F4CB7A
    changeOver: 'rgba(247, 178, 38, 1)', // #F7B226
    cancelled: 'rgba(204, 204, 204, 1)', // #CCCCCC

    feefoDarkGrey: '#424243',

    bgColor: 'rgba(245, 245, 245, 1)', // F5F5F5

    seasonWinter: '#D3D0DC',
    seasonPeak: '#FF908E',
    seasonLow: '#C0E2F0',
    seasonSummer: '#FED1E7',

    // Solid button backgrounds
    buttonSolidPrimary: '#4B815A',
    buttonSolidPrimaryHover: '#40724D',
    buttonSolidSecondary: '#56758F',
    buttonSolidSecondaryHover: '#405772',
    buttonSolidTertiary: '#F2F2F2',
    buttonConstructive: '#C8E0CE',
    buttonSolidTertiaryHover: '#E6E6E6',
    buttonSolidReverse: '#E9E9E9',
    buttonSolidReverseHover: '#F2F2F2',
    menuButton: '#1A1A1A',
    hoverPreHeaderMenuButton: 'rgba(255, 255, 255, 0.7)',
    hoverNavBarMenuButton: '#4D4D4D',

    // Borders & outlines
    inputDefault: '#808080',
    inputActiveHover: '#666666',
    inputDisabled: '#999999',

    // Pricing Seasons
    pricingWinter: '#D3D0DC',
    pricingOffPeakJanuaryFebruary: '#E4E0E1',
    pricingFebruaryHt: '#EDEEEA',
    pricingOffPeakNovemberDecember: '#C4D7E3',
    pricingXmasAndNye: '#AEC2D0',
    pricingLow: '#C0E2F0',
    pricingAutumn: '#FED17B',
    pricingOctoberHt: '#FF9D75',
    pricingOffPeakOctober: '#D8B6B6',
    pricingOffPeakSeptember: '#EBA37A',
    pricingSpring: '#A5FBF0',
    pricingEaster: '#E5FDD2',
    pricingPostEaster: '#F0F3A5',
    pricingWhitsun: '#FEF6C4',
    pricingOffPeakSpring: '#BFEDF2',
    pricingOffPeakMay: '#B5D3C3',
    pricingMayday: '#80D7D7',
    pricingSummer: '#FED1E7',
    pricingSummerPeak: '#FF9FB5',
    pricingOffPeakMayJuneJuly: '#FFD3C9',
    pricingPeak: '#FF908E',

    pageBackground: 'rgba(241, 244, 246, 1)', // #F1F4F6,

    background: '#F9F9F5',
    mobileMenuSubitemsBackground: 'rgba(64, 115, 77, 0.1)',
    mobileMenuSubitemsBackgroundHover: 'rgba(64, 115, 77, 0.2)',
    tertiary: 'rgba(204, 204, 204, 1)',
    outlineTertiaryButtonTxt: '#1A1A1A', // Button / Outline Tertiary / Text
    tertiaryButtonBorder: '#CCCCCC', // Button / Outline Tertiary / Border
    tertiaryButtonBorderHover: '#B3B3B3', // Button / Outline Tertiary / Border Hover

    header: '#345E3E', // Theme / Background / Header // primary
    quickNavTitle: '#355F3F',
    quickNavBtn: '#4B815A',
    footerBG: '#2D5237',
    footerBorderBottom: 'rgba(255, 255, 255, 0.2)',

    // SEARCH
    // searchErrorBackground: '#FCEEEF',
    searchErrorBackground: 'rgba(252, 238, 239, 1)',
    searchErrorBorder: '#A6171E',
    searchErrorColor: '#A6171E',

    // Active Link Button from Global Styles
    activeLinkButton: '#686C71',

    // PageHeader (BookingChartComponents)
    txtBodyOnWhite: '#333333', // Text / Body / On White
    borderBottomSideBarMenu: 'rgb(240, 240, 240)',

    // BookingListItem Row Border
    rowBorder: '#ededed',
    // BookingListSortingHeader background on hover
    blHeaderHover: 'rgba(87, 117, 144, 0.05)',

    // DatePicker Background color
    datePickerBackground: '#F5F5F5',

    // Banner background
    bannerBackground: 'rgb(255, 248, 207)',
    bannerSpan: '#d6c86a',

    // Caretakers colors
    canViewPropertyDetails: '#1178AC',
    canNotViewBookings: '#FF0000',
    selectedBookingDetails: '#ff7700',

    // Box Shadows
    editableDivDisabled: '#8C8C8C',
    editableDivBoxShadow: 'rgba(0,0,0,0.3)',
    subMenuBoxShadow: 'rgba(0, 0, 0, 0.1)',
    stickyHeaderBoxShadow: 'rgba(0,0,0,0.5)',
    modalBoxShadow: 'rgba(0,0,0,0.04)',
    modalContentColor: '#2c3e50',
    quickNavBoxShadow: 'rgba(0,0,0,0.15)',
    pricingBoxShadow: 'rgba(0,0,0,0.10)',
    radioBtnBoxShadow: 'rgba(0, 0, 0, 0.1)',
    cellCollectionBorder: '#E0E0DC',

    // Pricing
    pricingRed: '#D1849A',
    pricingRedBackground: '#F5E4E9',
    pricingAmber: '#FFB833',
    pricingAmberBackground: '#FFF1D6',
    pricingGreen: '#21C542',
    pricingGreenBackground: '#E5F9E9',
    pricingLink: '#0087ce',

    // CTA
    messageBackground: '#FEF9EF',
    messageBorder: '#B9851D',

    // React Table borders
    tableBorders: '#F2F2F2',
    // Border. Calendar
    borderCalendar: 'rgba(56, 56, 56, 0.3)',

    // AMENDMENTS
    // PropertyImage Thumbnails
    withOpacity: 'rgba(255,255,255,0)',
    noOpacity: 'rgba(255,255,255,1)',
    // PropertyFeatures
    featureTitle: 'rgb(20, 150, 20)',
    checkboxBorder: 'rgb(8, 228, 63)',

    // MODALS
    bookingModalBackgroundError: '#f46b6b',
    bookingModalBackgroundExcessCharges: 'rgb(255, 248, 207)',
    bookingModalBackgroundExcessChargesBefore: '#d6c86a',
    userAdminEditInfo: 'rgba(66, 85, 102, 1)',
    notAdmin: '#aaa',
    propertyImageEditModalBtn: 'rgba(255,255,255, 0.8)',
    quoteBackground: '#E6E6E6',

    // CALENDAR
    ownerSegmentBackground: 'rgba(175, 91, 119, .6)',
    customerSegmentBackground: 'rgba(172, 194, 128, .6)',
    provisionalSegmentBackground: 'rgba(244, 203, 122, .6)',
    ownerSegmentBorder: 'rgba(137, 53, 88, 1)',
    customerSegmentBorder: 'rgba(147, 176, 87, 1)',
    provisionalSegmentBorder: 'rgba(236, 193, 110, 1)',
    changeOverSegmentBackground: 'rgba(247, 178, 38, 1)',

    // CHART
    pickedDatesHeader: 'rgba(72, 99, 125)',
    fullDatesInfo: '#303030',
    dateHeaderBorder: '#808080', // grey
    arrows: '#304253',
    arrowShadowsFromHover: 'rgba(87, 117, 144, 0.3)',
    arrowShadowsToHover: 'rgba(66, 85, 102, 0.2)',
    propertyListBorders: 'rgb(216, 216, 216)',
    bgImgFunctions: 'rgba(242, 244, 246, 0.2)', // #F2F4F6
    currentDayBorderBG: 'rgba(76, 130, 90, 0.2)',
    pickedDateBG: 'rgba(207, 222, 234,0.8)',
    pickedDateBorder: 'rgba(66, 85, 102, 0.5)',
    bookingTypeProvisional: '#F4CB7A',
    bookingTypeCustomer: '#ACC280',
    bookingTypeOwner: '#AF5B77',

    // ImageUploader
    backgroundHover: '#F5F5F5',
    dropDownShadow: '#00000033', // 'Download as' element
    link: '#384D61' // 'Download as' element
  },
  gradient: {
    level1: 'linear-gradient(180deg, rgba(2,0,36,0.85) 0%, rgba(19,19,19,0.85) 100%)',
    level2: 'rgba(65,65,65,0.65)',
    candyCane: `repeating-linear-gradient(
      -45deg,
      rgba(175,91,119,1),
      rgba(175,91,119,1) 5px,
      rgba(255, 255, 255, 0.8) 5px,
      rgba(255, 255, 255, 0.8) 7px
    )`,
    candyCaneCalendar: `repeating-linear-gradient(
      -45deg,
      rgba(175,91,119,.6),
      rgba(175,91,119,.6) 5px,
      rgba(255, 255, 255, 0.8) 5px,
      rgba(255, 255, 255, 0.8) 7px
    )`
  },
  breakpoints,
  mediaQueries: {
    // We will continuously replace old breakpoints with new ones—please avoid using them going forward.
    phoneOnly: `@media screen and (width < 600px)`, // 140 usages
    tablet: '@media (600px <= width <= 900px)', // 56 usages
    tabletPortraitUp: `@media screen and (width >= 600px)`, // 23 usages
    tabletLandscapeUp: `@media screen and (width >= 768px)`, // 12 usages
    desktopUp: `@media screen and (width >= 1200px)`, // 14 usages
   
    // These are the new breakpoints we want to use moving forward.
    desktopDS: `@media screen and (width >= 1440px))`,
    laptopUpDS: `@media screen and (width >= 1024px)`,
    laptopDS: `@media screen and (1024px <= width < 1440px)`,
    tabletDS: `@media screen and (768px <= width < 1024px)`,
    tabletDownDS: `@media screen and (width < 1024px)`,
    phoneDS: `@media screen and (width < 768px)`,

  },
  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.51)',
  boxShadowSoft: '0 1px 3px rgba(0,0,0,0.12), 0 0 0 rgba(0,0,0,0.24)',
  space: [
    0, '.25rem', '.5rem', '1rem', '1.5rem', '2rem', '4rem', '8rem', '16rem'
  ],
  fonts: {
    Heading500: createFontStyles('FontHeadingHeading500'),
    Heading400: createFontStyles('FontHeadingHeading400'),
    Heading300: createFontStyles('FontHeadingHeading300'),
    Heading200: createFontStyles('FontHeadingHeading200'),
    Heading100: createFontStyles('FontHeadingHeading100'),
    Text400: createFontStyles('FontTextText400'),
    Title400: createFontStyles('FontTextText400Bold'),
    Text300: createFontStyles('FontTextText300'),
    Link300: createFontStyles('FontTextText300Link'),
    InputTitle300: createFontStyles('FontTextText300Mid'),
    Title300: createFontStyles('FontTextText300Bold'),
    Text200: createFontStyles('FontTextText200'),
    Link200: createFontStyles('FontTextText200Link'),
    Title200: createFontStyles('FontTextText200Bold'),
    Text100: createFontStyles('FontTextText100'),
    Link100: createFontStyles('FontTextText100Link'),
    Title100: createFontStyles('FontTextText100Bold')
  }
}

export default {
  ...consts,
  ...tokens
}

import React from 'react'
import FAQsList from '@/components/FAQs/FAQsList'
import { PageWrapper, SideBarContainer, SideBar } from '@/components/__UI'
import { Content, SideBarTitle } from './OnlineStatementsPage.styles'
import FAQs from '@/constants/faqs/faqs-financial-statements'
import OnlineStatementsContainer from '@/components/__OnlineStatements/OnlineStatementsContainer'

const OnlineStatementsPage = () => {
  const toolTipText = 'Your new statement will appear each month as soon as you have been paid'

  return (
    <PageWrapper
      title='Your statements'
      tooltipText={toolTipText}
    >
      <SideBarContainer>
        <Content>
          <OnlineStatementsContainer />
        </Content>
        <SideBar style={{padding: '0'}}>
          <SideBarTitle>Any questions?</SideBarTitle>
          <FAQsList
            smallBar
            faqData={FAQs || []}
            filter={'Financial Statements'}
          />
        </SideBar>
      </SideBarContainer>
    </PageWrapper>
  )
}

export default OnlineStatementsPage

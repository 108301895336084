import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { StyledSelect } from './ExportOptions.styles'
import { createICSFile, createPDFFile, createCSVFromBooking } from '@/helpers/files'
import { useTranslation } from 'react-i18next'

const ExportOptions = ({ className, data, filename, options = {}, siteUser, isUserBrandNovasol }) => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  const attemptDownload = (file, ext) => {
    if (file.error) {
      console.error(`[${ext} FILE] ${file.error}`)
    } else {
      file.download()
    }
    setIsLoading(false)
  }

  const isDisabled = (key) => (options[key] && options[key].disabled)

  const handleOnChange = async ({ value }) => {
    switch (value) {
      case 'pdf':
        if (options.pdf) {
          setIsLoading(true)
          createPDFFile(filename, options.pdf.component)
            .then(pdfFile => attemptDownload(pdfFile, 'pdf'))
        }
        break

      case 'csv':
        setIsLoading(true)
        createCSVFromBooking({ filename, data, siteUser, isUserBrandNovasol }, t)
        setIsLoading(false)
        break
      case 'ics':
        if (options.ics) {
          setIsLoading(true)
          const icsFile = createICSFile(filename, options.ics.events, siteUser)
          attemptDownload(icsFile, 'ICS')
        }
        break
      default: break
    }
  }

  return (
    <StyledSelect
      value=""
      name='exportType'
      isLoading={isLoading}
      onChange={handleOnChange}
      options={[
        { label: 'CSV', value: 'csv', isDisabled: isDisabled('csv') },
        { label: 'PDF', value: 'pdf', isDisabled: isDisabled('pdf') },
        { label: 'iCalendar', value: 'ics', isDisabled: isDisabled('ics') }
      ]}
      className={className}
      placeholder={t('downloadAs')}
    />
  )
}

ExportOptions.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  siteUser: PropTypes.bool.isRequired,
  filename: PropTypes.string,
  options: PropTypes.exact({
    ics: PropTypes.shape({
      events: PropTypes.array,
      disabled: PropTypes.bool
    }),
    pdf: PropTypes.shape({
      component: PropTypes.oneOfType([ PropTypes.node, PropTypes.func ]),
      disabled: PropTypes.bool
    })
  })
}

ExportOptions.displayName = 'ExportOptions'

export default ExportOptions

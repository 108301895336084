import styled from 'styled-components'
import { Modal } from '@/components/__UI'
import { Form } from '@/components/__Inputs'

const BookingSection = styled.div`
  font-size: 0.8rem;
`

const Section = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  margin: 1rem 0;

  & > * {
    flex: 1;
  }
`
export const SectionLabelUnit = styled.div`
  margin-bottom: 2rem;
  font-weight: 600;
`
export const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
`
export const SectionTitleTotal = styled.div`
  font-weight: 700;
  font-size: 1rem;
`

const SectionLabel = styled.div`
  font-weight: 600;
`

const SectionData = styled.div`
  /* padding-left: 1rem;
  margin-right: 1rem; */

  input {
    margin-bottom: 0.6rem;
  }
`
export const QuoteSection = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0.5rem 0 1rem 0;

  & > * {
    flex: 1;
  }
`
const Quote = styled.div`
  height: auto; 
  width: auto; 
  background: ${({ theme }) => theme.colors.quoteBackground};
  display: flex;
  flex-direction: column; 
  margin-bottom: 1.5rem;
  padding-top: .5rem;
  padding-left: .8rem; 
  border-radius: .3rem;

  h4 {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 1.1rem;
  }

  .priceContainer{ 
    height: 2rem; 
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    .priceContainer{ 
      height: 3rem; 
      justify-content: space-between;
      padding-right: 1rem;
    }
  }
`

export const BookingModal = styled(Modal)``

export const BookingForm = styled(Form)``

export { BookingSection, Section, SectionLabel, SectionData, Quote }

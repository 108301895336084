import styled, { keyframes } from 'styled-components'

// Define keyframes for the animations
const rotateAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const halfRotateAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(220deg); }
`

const fluctuateAnimation = keyframes`
  0% { transform: rotate(-140deg); }
  50% { transform: rotate(-160deg); }
  100% { transform: rotate(140deg); }
`

export const LoadingSpinnerWrapper = styled.div`
  height: 24px;
  width: 24px;
  animation: ${rotateAnimation} 4.8s linear infinite;
`

export const Spinner = styled.span`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 24px;
  width: 24px;
  clip: rect(0, 24px, 24px, 12px);
  animation: ${halfRotateAnimation} 1.2s linear infinite;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 24px;
    width: 24px;
    clip: rect(0, 24px, 24px, 12px);
    border: 3px solid #fff;
    border-radius: 50%;
    animation: ${fluctuateAnimation} 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  }
`

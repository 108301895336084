import React from 'react'
import { Head } from './TableHead.styles'
import DatesHeader from '../../datesHeader/DatesHeader'

const TableHead = () => {
  return (
    <Head>
      <tr>
        <DatesHeader />
      </tr>
    </Head>
  )
}

export default TableHead

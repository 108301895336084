/* stylelint-disable no-descending-specificity */
import styled from 'styled-components'
import Modal from '@/components/__UI/Modal'
import Button from '@/components/__Inputs/Button'

const CallToArmsMessageModal = styled(Modal)`
    position: relative;
    width: 55rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 51%);
    margin: auto 0;

    ${({theme}) => theme.mediaQueries.phoneOnly} {
      padding: 1rem 3rem 1.5rem;
      height: auto;
      width: 85vw;
      margin-top: -1rem;
    }

    @media screen and (max-width: 1200px) {
      padding: 1rem 3rem 1.5rem;
      height: auto;
      max-height: 30rem;
      width: 85vw;
      margin: auto 0;
    }

  h4{
    margin-bottom: 1rem;
    font-weight: 700;
    font-size: 1.2em;

    ${({ theme }) => theme.mediaQueries.phoneOnly} {
      margin-bottom: 0.5rem;
      font-weight: 700;
      font-size: 1em;
    }
  }
  
  .messageRecommendation{
    margin: 1rem 0 2rem 0;
  }

   p:nth-child(2) {
    margin: 1rem 0;
  }
`

const ButtonsContainer = styled.div`
  margin-top: 1.3rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    justify-content: center;
    flex-direction: column;
    margin-top: 0;
  }
`

const RecommendedPricesButton = styled(Button)`
  min-width: min(20vw, 18.75rem);
  

  a:link{
    text-decoration: none; 
    font-size: 12vw;
    text-align: center;
  }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    margin-top: 1rem;
    width: 60vw;
    height: 3.5rem !important;
  }
  ${({theme}) => theme.mediaQueries.tablet} {
    margin-top: 1rem;
    width: 35vw;
  }
`

export { CallToArmsMessageModal, RecommendedPricesButton, ButtonsContainer }

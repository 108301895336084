import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
  useMemo
} from 'react'
import PropTypes from 'prop-types'
import { useUser } from '@/context/UserProvider'
import { useRefetchSubscribe } from '@/context/RefetchProvider'
import { useTranslation } from 'react-i18next'
import {
  useQueryUserProperties,
  useQueryBookings,
  queryKeyBookings
} from '@/hooks/useQuery'
import { useQueryClient } from '@tanstack/react-query'

export const bookingListContextDefaults = {
  isLoadingBookings: false,
  filteredProperty: { propertyName: 'All Accommodations', propertyId: 'all' },
  setFilteredProperty: Function,
  filteredBookingType: { label: 'All Bookings', value: 'all' },
  setFilteredBookingType: Function,
  filteredBookings: [],
  setFilteredBookings: Function,
  sortedBy: null,
  setSortedBy: Function,
  startDate: null,
  setStartDate: Function,
  endDate: null,
  setEndDate: Function,
  searchBy: null,
  setSearchBy: Function,
  reloadBookings: Function,
  allBookings: [],
  allSitesWithProperties: {},
  filteredSite: {},
  setFilteredSite: Function,
  setAllBookings: Function,
  hideUnitDisplay: false
}

export const BookingListContext = createContext(bookingListContextDefaults)
export const useBookingListContext = () => useContext(BookingListContext)

export const BookingListProvider = ({ children }) => {
  const { t } = useTranslation()

  const [allBookings, setAllBookings] = useState([])
  const [allSitesWithProperties, setAllSitesWithProperties] = useState(null)
  const { hasPermission } = useUser()
  const [filteredProperty, setFilteredProperty] = useState({
    propertyName: t('allAccommodations'),
    propertyId: 'all'
  })

  const [filteredSite, setFilteredSite] = useState({})
  const [filteredBookingType, setFilteredBookingType] = useState({
    label: t('allBookings'),
    value: 'all'
  })

  const [filteredBookings, setFilteredBookings] = useState([])

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [searchBy, setSearchBy] = useState(null)
  const setSearchByAndClearDates = useCallback((searchBy) => {
    setSearchBy(searchBy)
    setStartDate(null)
    setEndDate(null)
  }, [])
  const [hideUnitDisplay, setHideUnitDisplay] = useState(false)

  const [sortedBy, setSortedBy] = useState()
  const {
    data: sitesWithPropertiesFromQuery,
    isLoading: loadingProperties
  } = useQueryUserProperties('viewBookings')

  useEffect(() => {
    if (!loadingProperties && sitesWithPropertiesFromQuery && sitesWithPropertiesFromQuery.sites) {
      setAllSitesWithProperties(sitesWithPropertiesFromQuery)
    }
  }, [sitesWithPropertiesFromQuery, loadingProperties])

  const bookingSearchVariables = useMemo(() => {
    const variables = {}

    if (startDate && endDate) {
      variables.startDate = startDate
      variables.endDate = endDate
    }

    if (searchBy) {
      variables.searchBy = searchBy
    }

    if (filteredSite.siteId !== undefined) {
      variables.siteId = filteredSite.siteId
    }

    return variables
  }, [startDate, endDate, searchBy, filteredSite])

  const queryClient = useQueryClient()

  const {
    data: bookings,
    isLoading: isLoadingBookings,
    isError: isErrorBookings,
    refetch: reloadBookings
  } = useQueryBookings(
    bookingSearchVariables,
    {
      enabled: hasPermission('Bookings') && !loadingProperties,
      /* istanbul ignore next */
      onSuccess: (data) => {
        const parameters = {
          endDate: data.toDate,
          searchBy: data.searchBy,
          startDate: data.fromDate,
          siteId: filteredSite.siteId
        }

        const queryKey = queryKeyBookings(parameters)
        queryClient.setQueryData(queryKey, data)
      }
    }
  )

  useEffect(() => {
    if (!isLoadingBookings && bookings) {
      const items = bookings.bookings || []
      setAllBookings(items)
      setSearchBy(bookings.searchBy)
      setStartDate(bookings.fromDate)
      setEndDate(bookings.toDate)
      setHideUnitDisplay(bookings.hideUnitDisplay === undefined ? false : bookings.hideUnitDisplay)
    }
  }, [isLoadingBookings, bookings, filteredSite])

  useRefetchSubscribe('getBookings', reloadBookings)

  const hasOnlyOneSite = allSitesWithProperties?.sites?.length === 1
  const hasOnlyOneAccommodation = filteredSite?.siteProperties
    ? filteredSite?.siteProperties?.length === 1
    : allSitesWithProperties?.sites?.[0]?.siteProperties?.length === 1

  return (
    <BookingListContext.Provider
      value={{
        isLoadingBookings,
        isErrorBookings,
        filteredProperty,
        setFilteredProperty,
        filteredBookingType,
        setFilteredBookingType,
        filteredBookings,
        setFilteredBookings,
        sortedBy,
        setSortedBy,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        searchBy,
        setSearchBy: setSearchByAndClearDates,
        reloadBookings,
        allBookings,
        allSitesWithProperties,
        filteredSite: filteredSite,
        setFilteredSite,
        setAllBookings,
        hasOnlyOneSite,
        hasOnlyOneAccommodation,
        hideUnitDisplay
      }}
    >
      {children}
    </BookingListContext.Provider>
  )
}

BookingListProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

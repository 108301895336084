import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { graphQlFetch, CREATE_USER_CASE } from '@/hooks/useGraphQl'
import { ResponseArea, HelpButton, StyledFormBuilder } from './QuestionArea.styles'
import SecondaryQuestions from '@/components/__ContactComponents/SecondaryQuestions'

const QuestionArea = ({ disableSelector, question }) => {
  const [ showForm, setShowForm ] = useState(false)

  useEffect(() => setShowForm(false), [question])

  const buildDescription = (values) => {
    return Object.keys(values)
      .filter(key => values[key])
      .map(key => {
        if (key === 'property') {
          const { propertyName, propertyId } = values[key]
          return `Property name: ${propertyName}, Property ID: ${propertyId}`
        }

        if (typeof values[key] === 'object') {
          return `${key}: ${values[key].label}`
        }

        return `${key}: ${values[key]}`
      })
      .join(`\n`)
  }

  const handleSubmit = (values, { formError, formSuccess, setSubmitting, reset }) => {
    const type = values.salesforceType || question.salesforceType
    const subType = values.salesforceSubType || question.salesforceSubType

    let hasError = false

    // Disable Question Selector and Form fields while submitting
    disableSelector(true)
    setSubmitting(true)

    // Start GQL
    graphQlFetch({
      query: CREATE_USER_CASE,
      variables: {
        applicationId: '1',
        casePropertyId: values.property.propertyId,
        caseQuestionType: type,
        caseQuestionSubType: subType,
        caseDescription: buildDescription(values)
      }
    })
      .then(data => {
        if (data.success) {
          formSuccess('Your enquiry was submitted')
        } else {
          hasError = true
        }
      })
      .catch(err => {
        console.log(err)
        hasError = true
      })
      .finally(() => {
        reset()
        disableSelector(false)
        setSubmitting(false)

        if (hasError) {
          formError('There was a problem submitting your enquiry')
        }
      })
  }

  return (
    <ResponseArea>
      {question.response}

      {(!question.hideButton && !showForm) && (
        <HelpButton data-testid="questionAreaHelpButton" secondary onClick={() => setShowForm(true)}>
            Need more help?
        </HelpButton>
      )}

      {((question.showForm || showForm) && question.formOptions) && (
        <StyledFormBuilder
          onSubmit={handleSubmit}
          formID={`help-form-${question.id}`}
          submitText="Submit enquiry"
          {...question.formOptions || {}}
        />
      )}

      {question.secondaryQuestions && (
        <SecondaryQuestions
          data={question.secondaryQuestions}
          onSubmit={handleSubmit}
        />
      )}
    </ResponseArea>
  )
}

QuestionArea.propTypes = {
  disableSelector: PropTypes.func,
  question: PropTypes.object.isRequired
}

export default QuestionArea

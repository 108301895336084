import styled from 'styled-components'

const CollectionCell = styled.td`
    border: 0.5px solid #E0E0DC;
    vertical-align: middle;

    .tablePopulation{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

        .fullReservation, .startReservation, .endReservation{
            width: 100%;
            height: 1.5rem;
            margin-top: 0.75rem;
            margin-bottom: 0.75rem;
            cursor: pointer;

        }
        .endReservation{
            box-shadow: none;
            width: 55%;
        }
        .startReservation{
            width: 55%;
            margin-left: auto;
        }
        .endPreviousStartNewReservation{
            width: 100%;
            height: 3rem;
        }

        .startClosed{
            width: 100%;
            height: 3rem;
            background: linear-gradient(to bottom right, transparent calc(50% - 1px), transparent calc(50% - 1px),  #E0E0DC 50%, #CCCCCC 50%);
        }      

        .endClosed{
            width: 100%;
            height: 3rem;
            background: linear-gradient(to bottom right, #CCCCCC calc(50% - 1px), #E0E0DC calc(50% - 1px), transparent 50%, transparent 50%);
            cursor: pointer;
        }

        .fullyClosed{
            width: 100%;
            height: 3rem;
            background: linear-gradient(to bottom right, #CCCCCC calc(50% - 2px),  #E0E0DC calc(50% - 2px), #E0E0DC 50%, #CCCCCC 50%);
        }

        .availableToBook{
            height: 3rem;
            width: 100%;
            cursor: pointer;
        }
        .availableToBookWithClosedEnd{
            height: 3rem;
            cursor: pointer;
        }
        .availableToBookWithReservationEnd{
            width: 45%;
            height: 3rem;
            cursor: pointer;
        }
    }
`

export { CollectionCell }

import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import OvalLoadingSpinner from '@/components/__UI/OvalLoadingSpinner'
import { isOwner } from '@/helpers/bookings'
import { DATE_FORMAT_DAY_ABBR_MONTH_YEAR } from '@/constants/dates'
import SpecialRequestsIcon from '@/components/__SVG/SpecialRequestsIcon'
import { CancelButton, BookingListCardBookingType, StyledBookingDetails, HiddenRow, CardContainer,
  CardHeader, CardInfoContainer, CardMainContent, SpecialRequestIcon, ButtonContent } from './BookingListDataCard.styles'
import ArrowButton from '../../__UI/Arrow/ArrowButton'
import { TrashcanSvg } from '@/components/__SVG'
import { useQueryUserPropertyEntitlements, useQueryBookingSearch } from '@/hooks/useQuery'
import BookingDetails from '../BookingDetails'
import { useTranslation } from 'react-i18next'
import { getLocale } from '@/helpers/locale'

const BookingListDataCard = ({ item, onCancel, showUnits, bookingToCancel, confirmedBookingsToBeCancelled }) => {
  const { t } = useTranslation()
  const [ selectedBookingID, setSelectedBookingID ] = useState(null)
  const [ selectedPropertyID, setSelectedPropertyID ] = useState(null)
  const isActive = selectedBookingID === item.bookingId

  // Get details for selected booking
  const { data: bookingDetails, isLoading: isLoadingBookingDetails } = useQueryBookingSearch(selectedBookingID)

  // Get auths to check permission to cancel
  const { data: authorisations } = useQueryUserPropertyEntitlements({ propertyId: selectedPropertyID })

  const canCancel = () => {
    if (!dayjs(item.startDate).isBefore(dayjs(), 'day') && (bookingDetails && bookingDetails.cancellable)) {
      return !!(authorisations && authorisations.length && authorisations.find(({ name }) => name === 'cancelBooking'))
    } else {
      return false
    }
  }

  const hasSpecialRequirement = item.specialRequirements && Array.isArray(item.specialRequirements) && item.specialRequirements.length > 0

  const [show, setShow] = useState(false)

  const showHandler = (bookingID, propertyID) => {
    setSelectedBookingID(s => s === bookingID ? null : bookingID)
    setSelectedPropertyID(s => s === propertyID ? null : propertyID)
    setShow(!show)
  }

  const formatDate = (date) => {
    const today = dayjs()
    date = dayjs(date)
    const isToday = today.isSame(date, 'day')
    if (isToday) return t('today')

    return date.format(DATE_FORMAT_DAY_ABBR_MONTH_YEAR)
  }

  const formatLeftDateHeader = (date) => {
    date = dayjs(date)

    const weekday = date.format('ddd').toUpperCase()
    const dayNo = date.format('DD')

    return (
      <div style={{display: 'block'}}>
        <p>{weekday}</p>
        <p>{dayNo}</p>
      </div>
    )
  }

  const isThisBookingCancelled = (item) => {
    return confirmedBookingsToBeCancelled !== undefined && !!confirmedBookingsToBeCancelled.find(obj => JSON.stringify(obj) === JSON.stringify(item))
  }

  const isThisBookingSelectedToBeCancelled = (bookingToBeCancelled, item) => {
    return JSON.stringify(bookingToBeCancelled) === JSON.stringify(item)
  }

  return (
    <>
      {
        (
          <CardContainer data-testid='mobile-view'>
            <CardHeader onClick={() => showHandler(item.bookingId, item.propertyId)}>
              <CardInfoContainer>
                <div className='dateTypeBox'>
                  <div className='dateBox'>
                    <div>{formatLeftDateHeader(item.startDate)}</div>
                    <div className='endDateColor'>{formatLeftDateHeader(item.endDate)}</div>
                  </div>
                  <BookingListCardBookingType bookingState={item.state}/>
                </div>
                <CardMainContent>
                  <div className='items'>
                    {showUnits
                      ? <p>{item.propertyName} <span>({item.propertyCode} - {item.unitCode})</span></p>
                      : <p>{item.propertyName} <span>({item.propertyCode})</span></p>
                    }
                    <p>{item.translatedUserFriendlyBookingState}</p>
                    <br />
                    <p className='startDate'>{t('arrival')}: {formatDate(item.startDate)}</p>
                    <p className='endDate'>{t('departure')}: {formatDate(item.endDate)}</p>
                    <p>{t('nightsDuration', { duration: item.nights })}</p>
                    {!isOwner(item && item.reservationType) &&
                          <>
                            <p>{item.leadGuest}</p>
                            <p>{t(item.leadGuestNationality)}</p>
                            <p>{item.translatedPartyDescription}</p>
                          </>}
                    {item.ownerIncome > 0 && <p>{item.ownerIncome.toLocaleString(getLocale())} {item.currency}</p>}
                    {item.holidayPrice > 0 && <p>{(item.holidayPrice + (item.extrasSellPrice || 0)).toLocaleString(getLocale())} {item.currency}</p>}
                    { hasSpecialRequirement &&
                      <SpecialRequestIcon>
                        <SpecialRequestsIcon />
                        <p>{t('specialRequest')}</p>
                      </SpecialRequestIcon>
                    }

                  </div>
                  <div className='arrowBtn'><ArrowButton show={show}/></div>
                </CardMainContent>
              </CardInfoContainer>
            </CardHeader>
            { isActive && <HiddenRow className={`hidden-info-container ${show ? 'hidden-info-transition' : ''}`}>
              {isLoadingBookingDetails
                ? <OvalLoadingSpinner style={{marginBottom: '1rem'}} />
                : (
                  <StyledBookingDetails>
                    <BookingDetails
                      booking={{...item, ...bookingDetails}}
                      showProvisionalBanner={true}
                    />

                    {canCancel() && !isThisBookingSelectedToBeCancelled(bookingToCancel, item) && !isThisBookingCancelled(item) &&
                        <CancelButton data-testid='bookingCancelButton' onClick={ () => onCancel(item) }>
                          <ButtonContent>
                            <TrashcanSvg />
                            <p>{t('cancelBooking')}</p>
                          </ButtonContent>
                        </CancelButton>}
                  </StyledBookingDetails>
                )}
            </HiddenRow>}
          </CardContainer>
        )
      }
    </>
  )
}

BookingListDataCard.propTypes = {
  item: PropTypes.object,
  onCancel: PropTypes.func,
  showUnits: PropTypes.bool,
  bookingToCancel: PropTypes.any,
  confirmedBookingsToBeCancelled: PropTypes.array
}

export default BookingListDataCard

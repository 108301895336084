import Select, { components } from 'react-select'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import globalTheme from '@/global/Theme'
import Tooltip from '@/components/__UI/Tooltip'
import { StyledLabel } from '../input.styles'
import { selectStyles, oneOptionStyles } from './SelectDropdown.styles'
import CaretIcon from '../../__UI/CaretIcon/CaretIcon'

// https://react-select.com/props#select-props

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretIcon />
    </components.DropdownIndicator>
  )
}

const SelectDropdown = ({
  className,
  defaultValue,
  hasError,
  isDisabled,
  isLoading,
  label,
  loadingText,
  noItemsText,
  options,
  placeholder,
  value,
  noSingleLock,
  tooltip,
  disabled,
  ...props
}) => {
  const ref = useRef(null)

  const handleLabelClick = () => {
    if (ref.current) {
      ref.current.state.menuIsOpen ? ref.current.onMenuClose() : ref.current.onMenuOpen()
    }
  }

  const hasOneOption = noSingleLock ? false : options && options.length === 1

  return (
    <>
      {label && <StyledLabel>{label} {tooltip && <Tooltip text={tooltip} />}</StyledLabel>}
      <Select
        onClick={handleLabelClick}
        disabled={disabled}
        className={className}
        hasError={hasError}
        defaultValue={isLoading ? null : defaultValue}
        value={isLoading ? null : value}
        isDisabled={isDisabled || hasOneOption || isLoading}
        isLoading={isLoading}
        ref={label ? ref : undefined}
        classNamePrefix="react_select"
        isSearchable={false}
        styles={hasOneOption ? oneOptionStyles : selectStyles}
        theme={globalTheme}
        options={isLoading ? [] : options}
        placeholder={isLoading ? (loadingText || 'Loading...') : (placeholder || 'Select...')}
        components={{ DropdownIndicator }}
        noOptionsMessage={() => noItemsText || 'No options found'}
        {...props}
      />
    </>
  )
}

SelectDropdown.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.object,
  hasError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  noSingleLock: PropTypes.bool,
  label: PropTypes.string,
  loadingText: PropTypes.string,
  noItemsText: PropTypes.string,
  tooltip: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array])
}

SelectDropdown.displayName = 'SelectInput'

export default SelectDropdown

import React from 'react'
import PropTypes from 'prop-types'
import { ArrowBtn } from './ArrowButton.styles'

const ArrowButton = ({show}) => {
  return (
    <ArrowBtn><div className={`arrow ${show ? 'active' : ''}`}></div></ArrowBtn>
  )
}
ArrowButton.displayName = 'ArrowButton'

ArrowButton.propTypes = {
  show: PropTypes.bool
}

export default ArrowButton

import styled from 'styled-components';
import { Text } from '@awaze/design-system.components.text';
import Theme from '@/global/Theme'

function getSeasonColor(season: string) {
    const key = `pricing${season}`
    if (key in Theme.colors) {
        return Theme.colors[key]
    }
}

export const Season = styled(Text)`
    display: flex;
    gap: 6px;
`

export const SeasonIndicator = styled.div<{ $season: string }>`
    background-color: ${({ $season }) => getSeasonColor($season)};
    width: 6px;
    height: 20px;
    border-radius: 3px;
`

export const AdjustmentPercentage = styled.span<{ $pct: number }>`
    border-radius: 4px;
    padding: 0 4px;
    line-height: 1;

    background-color: ${({ $pct, theme }) => $pct > 0 ? theme.colors.buttonConstructive : theme.colors.buttonSolidTertiar};
`

export const BookingsCell = styled(Text)`
    display: flex;
    flex-direction: column;
`

export const ExpandedGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;    
    padding: 1rem;
    row-gap: 1.5rem;
`

export const ExpandedLabel = styled.label`
    font-weight: 700;    
`

export const ExpanderButton = styled.button`
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
`;

import React from 'react'
import { useParams } from 'react-router-dom'
import { useUser } from '@/context/UserProvider'
import { Container, Spinner } from '@/components/__UI'
import { SpecialIcon } from '@/global/GlobalStyle'
import { PropertyAmendsEditLayout, PropertyDescriptionEdit } from '@/components/__PropertyAmendsComponents'
import { ContentContainer, Content, InfoSection } from './PropertyAmendsDescriptionPage.styles'

const PropertyAmendsDescriptionPage = () => {
  const { id } = useParams()
  const { user, loading, hasPermission } = useUser()
  const isPermitted = hasPermission('Bookings')

  if (!isPermitted && !loading) {
    return (
      <Container>
        <p style={{ textAlign: 'center' }}>You do not have permission to view this page</p>
      </Container>
    )
  }

  if (!user || loading) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  return (
    <PropertyAmendsEditLayout propertyId={id}>
      <h1>Accommodation description</h1>
      <ContentContainer>
        <Content>
          <PropertyDescriptionEdit propertyId={id} />
        </Content>
        <InfoSection>
          <SpecialIcon />
          <p>Our expert team of copywriters carefully review your property descriptions to make sure that essential information is included to help optimise web search opportunities.</p>
          <p>Of course, as the property owner, you are best placed to let us know of anything which may have changed both in terms of the property itself, or new local information which may be relevant.</p>
          <p>You can edit the text or tell us about anything that you would like to change or add in the “comments box” at the bottom of the page. We will review all amendments and update your description or if we have any queries we will contact you first.</p>
        </InfoSection>
      </ContentContainer>
    </PropertyAmendsEditLayout>
  )
}

export default PropertyAmendsDescriptionPage


import Theme from '@/global/Theme'
import React from 'react'

const PetsSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 11C0.878667 11 0 9.682 0 8C0 6.318 0.878667 5 2 5C3.12133 5 4 6.318 4 8C4 9.682 3.12133 11 2 11ZM2 6C1.528 6 1 6.85533 1 8C1 9.14467 1.528 10 2 10C2.472 10 3 9.14467 3 8C3 6.85533 2.472 6 2 6Z" fill={Theme.ColorGrey600}/>
    <path d="M5.5 6C4.37867 6 3.5 4.682 3.5 3C3.5 1.318 4.37867 0 5.5 0C6.62133 0 7.5 1.318 7.5 3C7.5 4.682 6.62133 6 5.5 6ZM5.5 1C5.028 1 4.5 1.85533 4.5 3C4.5 4.14467 5.028 5 5.5 5C5.972 5 6.5 4.14467 6.5 3C6.5 1.85533 5.972 1 5.5 1Z" fill={Theme.ColorGrey600}/>
    <path d="M14 10.5C12.8787 10.5 12 9.182 12 7.5C12 5.818 12.8787 4.5 14 4.5C15.1213 4.5 16 5.818 16 7.5C16 9.182 15.1213 10.5 14 10.5ZM14 5.5C13.528 5.5 13 6.35533 13 7.5C13 8.64467 13.528 9.5 14 9.5C14.472 9.5 15 8.64467 15 7.5C15 6.35533 14.472 5.5 14 5.5Z" fill={Theme.ColorGrey600}/>
    <path d="M10.5 6C9.37867 6 8.5 4.682 8.5 3C8.5 1.318 9.37867 0 10.5 0C11.6213 0 12.5 1.318 12.5 3C12.5 4.682 11.6213 6 10.5 6ZM10.5 1C10.028 1 9.5 1.85533 9.5 3C9.5 4.14467 10.028 5 10.5 5C10.972 5 11.5 4.14467 11.5 3C11.5 1.85533 10.972 1 10.5 1Z" fill={Theme.ColorGrey600}/>
    <path d="M8 16C5.51867 16 3.5 14.43 3.5 12.5C3.53333 11.512 4.028 10.5913 4.82267 10.0273C4.93533 9.93933 5.00533 9.78133 4.998 9.62133L4.99733 9.50067C4.99733 7.84533 6.34333 6.5 7.99733 6.5C9.65133 6.5 10.9973 7.846 10.9973 9.5V9.59733C10.9893 9.78 11.0587 9.93733 11.1873 10.0387C11.9707 10.5933 12.4653 11.51 12.5 12.482C12.5 13.4413 12.0227 14.33 11.156 14.994C10.3093 15.6427 9.18867 16 8 16ZM7.99733 7.5C6.89467 7.5 5.99733 8.39733 5.99733 9.5V9.59733C6.02067 10.0653 5.80467 10.5307 5.41867 10.83C4.86267 11.2247 4.52267 11.8527 4.5 12.5167C4.5 13.882 6.07067 14.9993 8 14.9993C9.92933 14.9993 11.5 13.878 11.5 12.4993C11.4767 11.8487 11.138 11.2267 10.5947 10.8433C10.5907 10.8407 10.5773 10.8307 10.574 10.828C10.1913 10.5293 9.97533 10.06 9.998 9.57333L9.99733 9.5C9.99733 8.39733 9.1 7.5 7.99733 7.5Z" fill={Theme.ColorGrey600}/>
  </svg>

)

export default PetsSvg


import DesignTokens from '@/global/DesignTokens'
import styled from 'styled-components'

export const ErrorContainer = styled.div`
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${DesignTokens.ColorGrey300};

    ${({ theme }) => theme.mediaQueries.tabletDownDS} {
        background-color: transparent;
        border: 0;
    }
`

export const NoResultMessage = styled.div`
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 10rem;
    background-color:  ${({ theme }) => theme.colors.white};
    border: 1px solid ${DesignTokens.ColorGrey400};
    border-radius: ${({theme}) => theme.borderRadius};
    ${({ theme }) => theme.fonts.Text300};

${({ theme }) => theme.mediaQueries.phoneDS} {
    width: 95%;
    margin-top: 1rem;
}
`

export const MonthYearHeadline = styled.h2`
    margin-bottom: 1rem;
    margin-top:2rem;
`

import React, {useState} from 'react'
import { getLocale } from '@/helpers/locale'
import { useQueryReviewsTranslation } from '@/hooks/useQuery'
import { StyledLinkBtn, TranslateMargin, TextWithMarginRight, TranslatedReviewMargin } from '../ReviewList.styles'
import { Oval } from 'react-loader-spinner'
import { useTranslation } from 'react-i18next'
import { Text } from '@awaze/design-system.components.text'

interface ReviewProps {
  propertyCode: string;
  reviewId: string;
  targetLanguage: string;
  canTranslate: boolean;
}

const TranslateReview = ({ propertyCode, reviewId, canTranslate }: ReviewProps) => {
  const { t } = useTranslation()
  const [showTranslation, setShowTranslation] = useState(false)

  const targetLanguage = getLocale()

  const { data: translatedReview, isLoading, isSuccess } = useQueryReviewsTranslation({
    propertyCode,
    reviewId,
    targetLanguage
  }, {
    enabled: showTranslation
  })

  const handleCloseTranslation = () => {
    setShowTranslation(false)
  }

  const showTranslateButton = canTranslate && (!showTranslation || isLoading)
  const showSpinner = showTranslation && isLoading
  const showCloseTranslationButton = showTranslation && isSuccess

  return (
    <TranslateMargin>
      <div style={{display: 'flex'}}>
        { showTranslateButton && (
          <StyledLinkBtn
            text={<Text sizes={200}>{t('translate')}</Text>}
            type="button"
            variant="text"
            iconPosition="left"
            onClick={() => setShowTranslation(true)}
          />
        )}

        {showSpinner && (
          <Oval
            wrapperStyle={{ marginLeft: '0.625rem' }}
            visible={true}
            color="#577590"
            secondaryColor="lightgrey"
            height="24"
            width="24"
            ariaLabel="oval-loading"
          />
        )}
        {showCloseTranslationButton &&
          <>
            <TextWithMarginRight sizes={200}>
              {t('translationCompleted')}.
            </TextWithMarginRight>
            <StyledLinkBtn
              text={<Text sizes={200}>{t('closeTranslation')}</Text>}
              type="button"
              variant="text"
              iconPosition="left"
              onClick={handleCloseTranslation} />
          </>
        }
      </div>
      {showTranslation && translatedReview && (
        <TranslatedReviewMargin>
          {translatedReview.review}
        </TranslatedReviewMargin>
      )}
    </TranslateMargin>

  )
}

export default TranslateReview

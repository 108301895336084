const LIST_PROPERTIES = `query ListProperties($input: ListPropertiesInput) {
  listProperties(input: $input) {
    propertyId
    propertyCode
    propertyName
    ownerType
    associatedEmail
    managedProperty
    allowLeadPassenger
  }
}`

const LIST_USER_PROPERTY_COLLECTION = `query ListUserPropertyCollection($input: ListPropertiesInput) {
  listUserPropertyCollection(input: $input) {
    siteUser
    siteUserProperties {
      siteName
      siteCode
      siteId
      userProperties {
        propertyId
        propertyCode
        propertyName
        displayImage 
        propertyUnits {
          unitId
          unitCode
          maximumPets
        }
      }
    }
  }
}`

const LIST_BOOKINGS = `query ListBookingsLight($input: ListBookingsLightInput) {
  listBookingsLight(input: $input) {
    bookingId
    reservationType
    reservationState
    startDate
    endDate
  }
}`

const LIST_ALL_CHART_PROPERTY_BOOKINGS = `query ListPropertyBookings($input: PropertiesBookingListInput) {
  propertiesBookings(input: $input) {
    bookings {
      unitId
      bookingId
      reservationType
      reservationState
      startDate
      endDate
    }
    availability{
      unitId
      unitAvailability
    }
  }
}`

const LIST_ALL_PROPERTY_BOOKINGS = `query ListPropertyBookings($input: PropertiesBookingListInput) {
  propertiesBookings(input: $input) {
    fromDate
    toDate
    searchType
    bookings {
      propertyId
      propertyCode
      propertyName
      unitCode
      siteName
      bookedOnDate
      nights
      specialRequirements
      partyDescription
      ownerIncome
      hideBookingFinancialDetail
      totalAccommodationAndExtrasCost
      pmsReference
      hideCancellationDate
      cancelledDate
      totalExtrasCost
      bookingId
      reservationType
      reservationState
      userFriendlyReservationType
      startDate
      endDate
      leadGuest
      telephone
      mobile
      email
      salesChannel
      ownerCharge
      adultsCount
      childrenCount
      infantsCount
      petsCount
      combinedChildAndInfantsCount
      hidePartyInformation
      extras {
        id
        cost
        name
        itemQty
        currencyCode
        maximumQuantity
        selectionMode
        amount
      }
    }
  }
}`

const LIST_ALL_PROPERTY_BOOKINGS_CONSOLIDATED = `query ListPropertyBookingsConsolidated($input: PropertiesBookingListInput) {
  getBookingList(input: $input) {
    fromDate
    toDate
    searchType
    bookings {
      bookingId
      petsCount
      currency
      leadGuest
      leadGuestNationality
      arrivalDateTime
      hideArrivalDate
      earlierSettled
      extrasOrdered
      hideBookingFinancialDetail
      state
      startDate
      endDate
      propertyId
      propertyCode
      propertyName
      siteName
      unitId
      unitCode
      bookedOnDate
      nights
      specialRequirements
      adultsCount
      childrenCount
      combinedChildAndInfantsCount
      infantsCount
      telephone
      mobile
      email
      extras{
        cost
        name
        quantity
      }
      holidayPrice
      extrasSellPrice
      cancelledDate
      ownerIncome
      ownerChargeAmount
      hideCancellationDate
      hidePartyInformation
    }
  }
}`

const LIST_PROPERTY_BOOKINGS = `query GetPropertyBooking($input: PropertyBookingListInput) {
  propertyBookings(input: $input) {
    propertyId,
    propertyName,
    bookedOnDate,
    nights,
    specialRequirements,
    partyDescription,
    ownerIncome,
    bookingId,
    reservationType,
    reservationState,
    startDate,
    endDate,
    leadGuest
  }
}`

const BOOKING_SEARCH = `query BookingSearch($input: BookingSearchInput) {
  bookingSearch(input: $input) {
    id
    reservationType
    bookingState
    reservedOnDate
    startDate
    endDate
    duration
    siteName
    party {
      partyId
      adultsCount
      childrenCount
      infantsCount
      petsCount
      partyLead {
        customerId
        longName
        contacts {
          channel
          value
        }
        town
        postCode
        defaultContactDisplay
      }
    }
    extras {
      id
      maximumQuantity
      selectionMode
      amount
      itemQty
      name
      cost
      currencyCode
    }
    bookingCosts {
      quantity
      name
      cost
      type
      currency
      }
    specialRequirements
    salesChannel
    unitCode
    propertyCode
    propertyName
    cancellable
    ownerChargeAmount
    holidayPrice
    ownerIncome
  }
}
`

const LIST_BOOKING_BY_ID = `query ListBookingsById($input: ListBookingsByIdInput) {
    listBookingsById(input: $input) {
      id
      reservationType
      bookingState
      reservedOnDate
      startDate
      endDate
      duration
      siteName
      party {
        partyId
        adultsCount
        childrenCount
        infantsCount
        petsCount
        partyLead {
          customerId
          longName
          contacts {
            channel
            value
          }
          town
          postCode
          defaultContactDisplay
        }
      }
      extras {
        id
        maximumQuantity
        selectionMode
        amount
        itemQty
        name
        cost
        currencyCode
      }
      bookingCosts {
        quantity
        name
        cost
        type
        currency
      }
      specialRequirements
      salesChannel
      unitCode
      propertyCode
      propertyName
      cancellable
      ownerChargeAmount
      holidayPrice
      ownerIncome
    }
  }
`

const LIST_CLOSURES = `query ListClosures($input: ListBookingsLightInput) {
  listClosures(input: $input) {
    startDate
    endDate
  }
}
`

const GET_CARETAKERS = `query ListCaretakers {
  listCaretakers {
    id
    name
  }
}
`

const GET_PERMISSIONS = `query ListCaretakersPermissions($input: CaretakerPermissionsInput) {
  listCaretakersPermissions(input: $input) {
    id
    isAllowed
  }
}
`

const GET_QUOTATION = `query Quotation($input: GetQuotationInput) {
  getQuotation(input: $input) {
    charge
    excessCharge
    hasHolidayPrice
    holidayPrice
    allowBooking
    extras{
      id
      cost
      name
      itemQty
      currencyCode
      maximumQuantity
      selectionMode
      amount
    }
  }
}
`

const GET_PROPERTY_PRICING_RECOMMENDATIONS_MULTI_BRAND = `query GetPropertyPricingRecommendations($input: GetPropertyPricingRecommendationsInput) {
  getPropertyPricingRecommendations (input: $input) {
    duration
    columnCount
    columnHeaders {
      columnIndex
      prefixText
      yearText
      suffixText
    }
  }
}`

const GET_USER_PROPERTY_ENTITLEMENTS = `query GetUserPropertyEntitlements($input: GetUserPropertyEntitlementsInput) {
  getUserPropertyEntitlements(input: $input) {
    name
    authorised
  }
}
`

const GET_USER_PROPERTY_PRICING = `query GetPropertyPricingReport($input: CurrentPropertyPriceInput) {
    currentPropertyPrice (input: $input) {
      propertyId,
      startYear,
      endYear,
      numberOfNights,
      floorPrice,
      pricingDataRows {
          weekCommencing,
          miniSeason,
          upperCorridor,
          lowerCorridor,
          originalCustomerPrice,
          currentCustomerPrice,
          potentialIncome,
          actualCustomerIncome,
          bookedDuration,
          percentChange,
          style,
      }
    }
  }
`

const GET_USER_PROPERTY_YEARS_DURATIONS = `query GetUserPropertyYearsDurations($input: PropertyPricingYearDurationInput) {
  currentPropertyPricingYearDuration (input: $input) {
    years,
    durations
  }
}`

const GET_PROPERTY_DESCRIPTION = `query GetPropertyDescription($input: PropertyDescriptionInput) {
  getPropertyDescription (input: $input) {
    id
    sectionName
    description
  }
}`

const GET_PROPERTY_ARRIVAL_DETAILS = `query GetPropertyArrival($input: PropertyArrivalInput) {
  getPropertyArrival (input: $input) {
    id
    sectionName
    description
  }
}`

const GET_PROPERTY_IMAGERY = `query GetPropertyImagery($input: PropertyImageryInput) {
  getPropertyImagery (input: $input) {
    id
    order
    caption
    url
  }
}`

const GET_PROPERTY_FEATURES = `query GetPropertyFeatures($input: PropertyFeaturesInput) {
  getPropertyFeatures (input: $input) {
    category
    features {
      id
      description
      enabled
    }
  }
}`

const GET_FEATURE_INFORMATION = `query GetPropertyFeatureInformation($input: PropertyFeatureInformationInput) {
  getPropertyFeatureInformation (input: $input) {
    disclaimer
    quantity
    subFeatures {
      id
      description
      enabled
    }
  }
}`

const GET_FINANCIAL_STATEMENTS = `query GetFinancialStatements($input: FinancialStatementsInput) {
  getFinancialStatements (input: $input) {
    year
    monthlyStatements {
      id
      monthlyDate
      templateId
      formats {
        id
        name
      }
    }
  }
}`

const GET_USER_ACCOUNTS = `query GetUserAccounts {
  getUserAccounts {
    id
    accountDetail {
      name
      code
      email
      address
      town
      county
      postcode
      country
      phoneNo
      mobileNo
      officeNo
    }
  }
}`

const GET_USER_STATEMENT_ACCOUNTS = `query GetUserStatementAccounts {
  getUserStatementAccounts {
    id
    code
    name
    isPostalStatements
  }
}`

const GET_FINANCIAL_STATEMENT_FILE = `query GetFinancialStatementFile($input: FinancialStatementFileInput) {
  getFinancialStatementFile (input: $input) {
    fileStream
  }
}`

const GET_PROPERTY_SEASON_DATES = `query GetPropertySeasonDates($input: PropertySeasonDatesInput) {
  getPropertySeasonDates (input: $input) {
    year
    seasons {
      seasonType
      startDate
      endDate
    }
  }
}`

const GET_PROPERTY_ALLOWANCES = `query GetPropertyAllowances($input: PropertyAllowancesInput) {
  getPropertyAllowances (input: $input) {
    year
    seasons {
      seasonType
      overallAllowance
      totalUsed
      amountRemaining
    }
  }
}`

const GET_USER_ADMIN_DETAILS = `query GetUserAdminDetails($input: UserAdminDetailsInput) {
  getUserAdminDetails (input: $input) {
    userId
    firstName
    lastName
    jobTitle
    email
    adminRole
    sites{
      id
      nameWithCode
    }
    sitePermissions{
      id
    }
  }
}`

const GET_USER_ADMIN_LIST = `query GetUserAdminList {
  getUserAdminList {
    userId
    firstName
    lastName
    email
    jobTitle
    sites
    adminRole
    canBeEdited
    lastLogin
  }
}`

const GET_USER_ADMIN_SITES = `query GetUserAdminSites {
  getUserAdminSites {
    id
    code
    name
    nameWithCode
  }
}`

const GET_USER_ADMIN_PERMISSIONS = `query GetUserAdminPermissions{
  getUserAdminPermissions {
    id
    description
  }
}`

const GET_FAQS = `query GetFaqs($input: GetFaqsInput) {
  getFaqs(input: $input) {
    ownerPortalFaqsCollection {
      items {
        title
        content {
          json
        }
        tagsCollection {
          items {
            title
          }
        }
      }
    }
  }
}
`

export {
  LIST_PROPERTIES,
  LIST_USER_PROPERTY_COLLECTION,
  LIST_BOOKINGS,
  LIST_ALL_PROPERTY_BOOKINGS,
  LIST_PROPERTY_BOOKINGS,
  LIST_BOOKING_BY_ID,
  LIST_CLOSURES,
  GET_CARETAKERS,
  GET_PERMISSIONS,
  GET_QUOTATION,
  GET_USER_PROPERTY_ENTITLEMENTS,
  GET_USER_PROPERTY_PRICING,
  GET_USER_PROPERTY_YEARS_DURATIONS,
  GET_PROPERTY_DESCRIPTION,
  GET_PROPERTY_ARRIVAL_DETAILS,
  GET_PROPERTY_IMAGERY,
  GET_PROPERTY_FEATURES,
  GET_FEATURE_INFORMATION,
  GET_FINANCIAL_STATEMENTS,
  GET_FINANCIAL_STATEMENT_FILE,
  GET_USER_ACCOUNTS,
  GET_USER_STATEMENT_ACCOUNTS,
  GET_PROPERTY_SEASON_DATES,
  GET_PROPERTY_ALLOWANCES,
  GET_FAQS,
  GET_USER_ADMIN_DETAILS,
  GET_USER_ADMIN_LIST,
  GET_USER_ADMIN_SITES,
  GET_USER_ADMIN_PERMISSIONS,
  LIST_ALL_CHART_PROPERTY_BOOKINGS,
  BOOKING_SEARCH,
  LIST_ALL_PROPERTY_BOOKINGS_CONSOLIDATED,
  GET_PROPERTY_PRICING_RECOMMENDATIONS_MULTI_BRAND
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import AccountDropdown from '@/components/__Inputs/AccountDropdown'
import useGraphQl, { GET_FINANCIAL_STATEMENTS } from '@/hooks/useGraphQl'
import OnlineStatementsList from '../OnlineStatementsList'
import OnlineStatementsPostal from '../OnlineStatementsPostal'
import { Header, StyledDropdown, DropdownContainer } from './OnlineStatementsContainer.styles'

const OnlineStatementsContainer = () => {
  const [accountStatements, setAccountStatements] = useState()
  const [currentAccount, setCurrentAccount] = useState(null)
  const [selectedYear, setSelectedYear] = useState('all')
  const [yearOptions, setYearOptions] = useState(null)
  const [postalStatements, setPostalStatements] = useState()

  const [, loadingStatements] = useGraphQl({
    query: GET_FINANCIAL_STATEMENTS,
    isObject: true,
    isEnabled: currentAccount !== null,
    variables: {
      accountId: currentAccount
    },
    onSuccess: (data) => {
      const currentYearStatements = data.filter(
        function (statementData) {
          return statementData.year === dayjs().year().toString()
        }
      )

      let newStatementData = [...data]
      if (currentYearStatements.length === 0) {
        newStatementData = [...data, {year: dayjs().year().toString(), monthlyStatements: []}]
      }

      if (newStatementData) {
        newStatementData.sort((a, b) => {
          return a.year > b.year ? -1 : 1
        })
      }

      setAccountStatements(newStatementData)
    }
  })

  useEffect(() => {
    if (accountStatements !== undefined) {
      const options = getYearOptions(accountStatements)
      setToCurrentYear(options)
    }
  }, [accountStatements])

  const getYearOptions = (statements) => {
    if (statements) {
      const years = statements.map(({year}) => year)
      const options = years.map(x => { return { label: x, value: x } })
      options.unshift({ label: 'All years', value: 'all' })
      setYearOptions(options)

      return options
    }
  }

  const setToCurrentYear = (options) => {
    if (options) {
      const currentYear = dayjs().year().toString()
      const currentYearOption = options.find(({value}) => value === currentYear)

      if (currentYearOption) {
        setSelectedYear(currentYearOption)
      }
    }
  }

  return (
    <div>
      <Header>
        <DropdownContainer>
          {postalStatements !== true && <>
            <StyledDropdown
              label='Year'
              width={'140px !important'}
              name="yearSelect"
              defaultValue={{ label: 'All years', value: 'all' }}
              value={selectedYear}
              options={yearOptions}
              onChange={(value) => setSelectedYear(value)}
              isLoading={loadingStatements}
            />
          </>}
        </DropdownContainer>
        <DropdownContainer>
          <AccountDropdown
            label
            style={{width: '180px !important'}}
            onChange={({id, isPostalStatements}) => {
              setCurrentAccount(id)
              setPostalStatements(isPostalStatements)
            }}
          />
        </DropdownContainer>
      </Header>
      {postalStatements
        ? <OnlineStatementsPostal currentAccount={currentAccount} />
        : <OnlineStatementsList loading={loadingStatements} currentAccount={currentAccount} selectedYear={selectedYear} accountStatements={accountStatements} />
      }
    </div>
  )
}

OnlineStatementsContainer.propTypes = {
  selectedYear: PropTypes.string,
  list: PropTypes.object
}

export default OnlineStatementsContainer

import styled from 'styled-components'

const Units = styled.div`
  .siteImage{
    display: flex;
    justify-content: center;
    align-items: center;
      img{
        width: 3rem;
        height: auto;
      }
  }

  .siteContainer, .unitCell{
    width: 17vw;
    height: 3rem;

    .infoSiteContainer{
      color: ${({theme}) => theme.colors.black};
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      background-color: ${({ theme }) => theme.colors.primaryFour};
      border-left: 0.5px solid ${({ theme }) => theme.colors.propertyListBorders};
      border-top: 0.5px solid ${({ theme }) => theme.colors.propertyListBorders};
      font-size: 0.8rem;
      line-height: 18px;
      box-shadow: 15px 0px 10px rgb(0 0 0 / 10%);
      display: flex;
      flex-direction: row;
    }
  }

  .siteName, .unitCell{
    height: 3rem;
    margin: auto 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .siteName{
    max-height: calc(3rem - 1px);
  }

  .unitCell{
    height: 49px;
    margin: 0;
    border-top: 0.5px solid ${({ theme }) => theme.colors.propertyListBorders};
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 15px 0px 10px rgb(0 0 0 / 10%);

    &:last-child {
      border-bottom: 0.5px solid ${({ theme }) => theme.colors.propertyListBorders};
    }
  }

  .propertyUnitContainer{
    margin: auto 1rem;
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.8rem;
    line-height: 18px;

    img{
      width: 65%;
    }
  }

  .empty{
    position: sticky !important;
    top: 0;
    height: 5.1rem;
    width: 17vw;
    background-color: ${({theme}) => theme.colors.white};
    box-shadow: 15px 0px 10px rgb(0 0 0 / 10%);
  }

  /* iPAD */
@media (min-width: 600px) and (max-width: 1199px) {
  .empty{
    position: sticky !important;
    top: 0;
    height: 4.38rem;
    width: 29.5vw;
    background-color: ${({theme}) => theme.colors.white};
    box-shadow: 15px 0px 10px rgb(0 0 0 / 10%);
  }
  .siteImage{
    width: 4vw;
  }
  .siteContainer, .unitCell{
    width: 29.5vw;
    height: 3rem;
  }

  .siteName{
    margin-left: 1rem;
  }
  .unitCell{
    height: 49px;
  }
}

  /* MOBILE */
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
  .empty{
    position: sticky !important;
    top: 0;
    height: 4.65rem;
    width: 33.9vw;
    background-color: ${({theme}) => theme.colors.white};
    box-shadow: 15px 0px 10px rgb(0 0 0 / 10%);
  }

  .infoSiteContainer{
    width: 33.9vw;
    color: ${({theme}) => theme.colors.black};
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    background-color: ${({ theme }) => theme.colors.primaryFour};
    border-left: 0.5px solid ${({ theme }) => theme.colors.propertyListBorders};
    border-top: 0.5px solid ${({ theme }) => theme.colors.propertyListBorders};
    box-shadow: 15px 0px 10px rgb(0 0 0 / 10%);
  }
  .siteName, .unitCell{
    width: 33.9vw;
    height: 3rem;
    margin: auto 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .siteName{
    max-height: calc(3rem - 1px);
    .site{
      font-size: 0.75rem;
      line-height: 18px;
    }
    .numberOfUnits{
      font-size: 0.56rem !important;
      line-height: 13.5px;
    }
  }

  .unitCell{
    height: 49px;
    margin: 0;
    border-left: 0.5px solid ${({ theme }) => theme.colors.propertyListBorders};
    border-top: 0.5px solid ${({ theme }) => theme.colors.propertyListBorders};
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 15px 0px 10px rgb(0 0 0 / 10%);

    &:last-child {
      border-bottom: 0.5px solid ${({ theme }) => theme.colors.propertyListBorders};
    }
  }

  .propertyUnitContainer{
    margin: auto 0.6rem;
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.75rem;
    line-height: 18px;

    img{
      width: 65%;
    }
  }
} 
`

export { Units }

import styled from 'styled-components'
import { styledInputCss } from '../input.styles'
import { Text } from '@awaze/design-system.components.text'

const StyledTextarea = styled.textarea`
    ${styledInputCss}
    resize: none;
    min-height: 144px;
    font-size: .938em;
    font-family: inherit;
`

const CharCount = styled(Text)`
    margin-top: 0.5rem;
`

export { StyledTextarea, CharCount }

import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react'
import { useQueryUserProperties } from '@/hooks/useQuery'
import { useTranslation } from 'react-i18next'

import { SelectDropdownDS, SelectDropdownDSProps } from '@/components/__Inputs/SelectDropdown'

export interface PropertyUnitDropdownDSProps extends Omit<SelectDropdownDSProps, 'label' | 'options' | 'isLoading'> {
  label?: boolean;
  onPropertiesLoaded?: (data: any) => void;
  hideSingleOption?: boolean;
  autoSelectFirst?: boolean;
  selectedPropertyID: string;
  operationID: string;
}

export function PropertyUnitDropdownDS ({
  label,
  operationID,
  onPropertiesLoaded,
  hideSingleOption,
  autoSelectFirst,
  selectedPropertyID,
  ...rest
}: Readonly<PropertyUnitDropdownDSProps>) {
  const { data, isLoading } = useQueryUserProperties(operationID)
  const { t } = useTranslation()

  const options = useMemo(() => {
    if (!data?.sites || !selectedPropertyID) return []

    let counter = 1

    return data.sites.flatMap(site =>
      site.siteProperties.flatMap(property => {
        if (property.propertyId !== selectedPropertyID) {
          return []
        }

        return property.propertyUnits?.map(unit => ({
          text: `${t('calendar')} ${counter++}`,
          value: unit.unitId
        })) || []
      })
    )
  }, [data, selectedPropertyID, t])

  const hasSingleOption = options?.length === 1
  const isHidden = hideSingleOption && hasSingleOption

  useEffect(() => {
    if (data && onPropertiesLoaded && !isHidden) {
      onPropertiesLoaded(data)
    }
  }, [data, onPropertiesLoaded, isHidden])

  const simulateChangeEvent = useCallback((value: string) => {
    if (rest.onChange) {
      const event = {
        target: {
          name: rest.name,
          value
        }
      }
      rest.onChange(event as ChangeEvent<HTMLSelectElement>)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.name, rest.onChange])

  useEffect(() => {
    if (autoSelectFirst && options.length >= 1) {
      simulateChangeEvent(options[0].value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSelectFirst, options])

  if (hideSingleOption) {
    if (isLoading || hasSingleOption) {
      return null
    }
  }

  return (
    <SelectDropdownDS
      {...rest}
      label={label && t('calendar')}
      isLoading={isLoading}
      loadingText={t('loading')}
      placeholder={t('loading')}
      options={[{ options }]}
    />
  )
}

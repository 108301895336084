import { useMemo } from 'react'
import { useQueryUserProperties } from './useQuery'

export function usePropertyFromId (propertyId: string) {
  const { data } = useQueryUserProperties()

  const property = useMemo(() =>
    data?.sites?.flatMap(site =>
      site?.siteProperties).find(property =>
        property?.propertyId === propertyId
      )
  , [data, propertyId])

  return property
}

import styled from 'styled-components'

const SideBarContainer = styled.div`
  position: relative;

  ${({ theme }) => theme.mediaQueries.desktopUp} {
    display: flex;
    flex-wrap: nowrap;

    & > * {
      flex: 1;
    }
  }
`

const SideBar = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.white};
  flex: none;

  p {
    margin-bottom: 1rem;
  }

  ${({ theme }) => theme.mediaQueries.desktopUp} {
    width: ${({width}) => width || '400px'};
    margin-top: 0;
    margin-left: 2rem;
  }
`

const Error = styled.p`
  color: ${({theme}) => theme.colors.error};
`

export { SideBarContainer, SideBar, Error }

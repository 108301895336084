import styled from 'styled-components'
import SelectDropdown from '@/components/__Inputs/SelectDropdown'

const StyledSelect = styled(SelectDropdown)`
    border: none !important;
    height: 3.5rem !important;
    max-width: none !important;
    box-shadow: ${({ theme }) => theme.boxShadowSoft};
    transition: ${({ theme }) => `${theme.animationSpeed} background-color ease`};

    &:hover {
        background-color: ${({ theme }) => theme.colors.greySix};
    }

    .react_select__control {
        padding-right: 0;
    }

    .react_select__option--is-selected {
        color: ${({ theme }) => theme.colors.primary};
    }

    .react_select__option {
        font-size: 1rem;
        text-align: left;
        padding-left: 1rem;

        &:hover {
            color: inherit;
            background-color: ${({ theme }) => theme.colors.greySix};
        }
    }

    .react_select__value-container {
        font-size: 1.2rem;
        justify-content: flex-start;

        @media (min-width:600px) and (max-width:900px) {
            font-size: 1rem;
            justify-content: flex-start;
        }
    }

    .react_select__indicator {
        top: 0.2rem;
        padding: 0.8rem 1rem;
        color: ${({ theme }) => theme.colors.white};
        border-radius: ${({ theme }) => theme.borderRadius};
        background-color: ${({ theme }) => theme.colors.primary};

        svg {
            transition: ${({ theme }) => `${theme.animationSpeed} transform ease`};
        }
    }

    .react_select__control--menu-is-open .react_select__indicator svg {
        transform: rotate(180deg);
    }

    .react_select__menu {
        top: 110% !important;
        border: none !important;
        box-shadow: ${({ theme }) => theme.boxShadowSoft};

        .react_select__menu-list {
            max-height: 500px;
        }
    }

    ${({ theme }) => `${theme.mediaQueries.phoneOnly} {
        height: 5rem !important;

        .react_select__control {
            padding: 1rem;
            justify-content: flex-start;
        }

        .react_select__value-container {
            width: 80%;
            font-size: 0.8rem;
        }

        .react_select__single-value {
            white-space: normal;
            text-overflow: unset;
        }

        .react_select__menu-list {
            max-height: 300px !important;
        }

        .react_select__indicator {
            top: 18%;
        }

        .react_select__option {
            font-size: 0.8rem;
        }
    }`}
`

export { StyledSelect }

import styled from 'styled-components';
import { Button } from '@awaze/design-system.components.button';
import { Text } from '@awaze/design-system.components.text';

export const PopoverWrapper = styled.div`
  z-index: 999; /* Hides chevron icon */
`;

export const DurationContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  flex-wrap: wrap;
`;

export const StyledDaysButton = styled(Button)`
  height: 24px;
  padding: 4px 8px;
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.colors.buttonSolidSecondary};
  white-space: nowrap; /* Prevent wrapping */
`;

export const WeeksContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledWeekSelectionButton = styled(Button)`
  height: 24px;
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.colors.buttonSolidSecondary};
`;

export const StyledFilterIconButton = styled(Button)`
  color: ${({ theme }) => theme.colors.link};
  gap: 2px;
`;

export const StyledTextActiveFilters = styled(Text)`
  width: 20px;
  line-height: 20px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.buttonSolidSecondary};
  color: ${({ theme }) => theme.colors.white};
`;

import Table from '@/components/Table'
import styled from 'styled-components'

const StyledHead = styled(Table.Header)`
  background-color: transparent;

  .table-head-cell {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blackTwo};
    text-align: left;
  }

  @media print {
    padding: 0.2rem;
  }
`

export { StyledHead }

import styled from 'styled-components'

const arrowSize = '6px'

const Container = styled.div`
  display: inline-block;
  cursor: pointer;
  position: relative;
`
const StyledSvg = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: block;
`

const Popup = styled.div`
  position: absolute;
  z-index: 100;
  bottom: calc(100% + 0.5rem);
  color: ${({theme, primary}) => primary ? theme.colors.white : theme.colors.black};
  background-color: ${({theme, primary}) => primary ? theme.colors.primary : theme.colors.white};
  border-radius: ${({theme}) => theme.borderRadius};
  box-shadow: ${({theme}) => theme.boxShadow};
  line-height: 1.2;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  max-width: 100vw;
  font-weight: 400;
  margin: 0 auto;
  text-align: left;

  width: ${({boxWidth}) => boxWidth}px;
  right: ${({iconPosition}) => iconPosition.right || 0};
  left: ${({iconPosition}) => iconPosition.left || 0};

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: ${({iconPosition}) => iconPosition.arrowLeft || 0};
    right: ${({iconPosition}) => iconPosition.arrowRight || 0};
    margin: 0 auto;
    width: 0; 
    height: 0;
    border-left: ${arrowSize} solid transparent;
    border-right: ${arrowSize} solid transparent;
    border-top: ${arrowSize} solid ${({theme, primary}) => primary ? theme.colors.primary : theme.colors.white};
  }
`

export { Container, Popup, StyledSvg }

import React from 'react'

export default [
  {
    'tags': ['Data Protection'],
    'title': 'What do you mean by "personal data"?',
    'content': (<p>&quot;Personal data&quot; is the term used to refer to any data which could be used to identify a living individual. This will include obvious things like name, address, date of birth, email address and telephone number. For more information, please take a look at <a href="https://ico.org.uk/for-the-public/personal-information/" target="_blank" rel="noopener noreferrer">https://ico.org.uk/for-the-public/personal-information/</a>.</p>)
  },
  {
    'tags': ['Data Protection'],
    'title': 'What is a "privacy notice"?',
    'content': (<p>A privacy notice explains to people who you are, what you are going to do with their information and who you will share it with.  If you&apos;d like to see an example of a privacy notice you can <a href="http://www.cottages.com/legal/privacy-notice" target="_blank" rel="noopener noreferrer">take a look at ours</a>. Yours may well be a lot simpler than ours. The main point is to explain in clear terms to customers what information you hold on them, how and why you use it and who you share it with (if anyone).  The comprehensive advice on the Information Commissioner’s Office (ICO) website is a great reference point: <a href="https://ico.org.uk/for-organisations/guide-to-data-protection/privacy-notices-transparency-and-control" target="_blank" rel="noopener noreferrer">https://ico.org.uk/for-organisations/guide-to-data-protection/privacy-notices-transparency-and-control</a>.</p>)
  },
  {
    'tags': ['Data Protection'],
    'title': 'What should I do with the personal data of my guests?',
    'content': (<p>The basic principles of GDPR require you to keep any personal data for no longer than is necessary for the purpose you obtained it for and disposing of it safely afterwards. Given accounting and auditing requirements around transactional data, there may well be a strong legitimate and legal basis to retain this data for a number of years. If this is the case, you must ensure the data is held securely.  Again, we&apos;d recommend consulting the ICO website <a href="https://ico.org.uk/for-organisations/guide-to-data-protection/" target="_blank" rel="noopener noreferrer">https://ico.org.uk/for-organisations/guide-to-data-protection/principle-5-retention/</a> for more guidance.</p>)
  },
  {
    'tags': ['Data Protection'],
    'title': 'I forward my guest details on to my keyholder/cleaner – is this still ok?',
    'content': (<p>Yes – we state in our policies that a customer can expect us to share data with accommodation and service providers to fulfil a booking contract. It would be worth checking with your keyholder/cleaner that they handle the data sensibly and dispose of when no longer needed.</p>)
  },
  {
    'tags': ['Data Protection'],
    'title': 'I can write a privacy notice but I don’t have my own website for my cottages – where should I put it so that guests can see it?',
    'content': (<p>If you don’t have a website, we&apos;d suggest adding it to the in house information pack, so that guests can see it alongside other holiday and service related information.</p>)
  },
  {
    'tags': ['Data Protection'],
    'title': 'Why can’t you give owners a template to help us write a privacy notice?',
    'content': (<p>Every accommodation owner is different and every owner will handle and use data in a variety of ways – whether a single property independent owner, or a multi-property letting business. It would be impossible for us to create a one size fits all solution to solve everyone&apos;s requirements.</p>)
  },
  {
    'tags': ['Data Protection'],
    'title': 'I keep all my guest details on file and have done so for many years. I send them a Christmas card every year. Can I still do this?',
    'content': (<p>As outlined above, ideally you should let your guests know about how you will use their data with a Privacy Notice. That said, we think that very few guests will object to receiving a Christmas card and there is probably little risk to doing this.</p>)
  }
]

import React, { useEffect, useState } from 'react'
import { SelectDropdown } from '@/components/__Inputs'
import StyledDropdownWrapper from './BookingListDropdownWrapper.styles'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const BookingListDropdownWrapper = ({
  allSitesWithProperties,
  onPropertyChange,
  selectedPropertyValue,
  isLoadingBookings,
  selectedSiteValue,
  onSiteChange,
  hasOnlyOneSite,
  hasOnlyOneAccommodation
}) => {
  const { t } = useTranslation()
  const [hasDataLoadedOnce, setDataLoadedOnce] = useState(false)

  useEffect(() => {
    if (allSitesWithProperties && allSitesWithProperties.sites) {
      setDataLoadedOnce(true)
    }
  }, [allSitesWithProperties])

  const allProperties = { propertyName: t('allAccommodations'), propertyId: 'all' }

  const getPropertiesToShowBasedOnSelectedSite = () => {
    let propertyCollectionToShow = allSitesWithProperties.sites[0].siteProperties
    if (selectedSiteValue && selectedSiteValue.siteId) {
      const selectedSite = allSitesWithProperties.sites.find(element => element.siteId === selectedSiteValue.siteId)
      propertyCollectionToShow = selectedSite.siteProperties
    }
    return propertyCollectionToShow
  }

  const handleAccommodationChange = (property) => {
    onPropertyChange && onPropertyChange(property)
  }

  const handleSiteChange = (site) => {
    onSiteChange && onSiteChange(site)
  }

  if (allSitesWithProperties === undefined || allSitesWithProperties.siteUser === undefined) {
    return null
  }

  let propertiesToShow = getPropertiesToShowBasedOnSelectedSite()

  if (propertiesToShow.length > 1) {
    propertiesToShow = [allProperties, ...propertiesToShow]
  } else {
    selectedPropertyValue = propertiesToShow[0]
  }

  let sitesToShow = allSitesWithProperties.sites

  if (sitesToShow.length > 1) {
    sitesToShow = [...sitesToShow]
  }

  if (selectedSiteValue === undefined || selectedSiteValue.siteId === undefined) {
    selectedSiteValue = sitesToShow[0]
  }

  return (
    <>
      {allSitesWithProperties.siteUser && !hasOnlyOneSite && (
        <StyledDropdownWrapper>
          {allSitesWithProperties.siteUser && (
            <SelectDropdown
              disabled={hasOnlyOneSite}
              label={t('selectSite')}
              value={selectedSiteValue}
              options={sitesToShow}
              isLoading={isLoadingBookings && !hasDataLoadedOnce}
              onChange={handleSiteChange}
              loadingText={t('loadingSites')}
              getOptionLabel={option => option.siteName}
              getOptionValue={option => option.siteId}
            />
          )}
        </StyledDropdownWrapper>
      )}
      {!hasOnlyOneAccommodation && (
        <StyledDropdownWrapper>
          <SelectDropdown
            disabled={hasOnlyOneAccommodation}
            label={t('selectAccommodation')}
            value={selectedPropertyValue}
            options={propertiesToShow}
            onChange={handleAccommodationChange}
            isLoading={isLoadingBookings && !hasDataLoadedOnce}
            loadingText={t('loadingAccommodations')}
            getOptionLabel={option => option.propertyName}
            getOptionValue={option => option.propertyId}
          />
        </StyledDropdownWrapper>
      )}
    </>
  )
}

BookingListDropdownWrapper.propTypes = {
  allSitesWithProperties: PropTypes.object,
  onPropertyChange: PropTypes.func,
  selectedPropertyValue: PropTypes.object,
  isLoadingBookings: PropTypes.bool,
  selectedSiteValue: PropTypes.object,
  hasOnlyOneSite: PropTypes.bool,
  hasOnlyOneAccommodation: PropTypes.bool,
  onSiteChange: PropTypes.func
}

export default BookingListDropdownWrapper

import 'form-association-polyfill'
import 'react-datepicker/dist/react-datepicker.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import App from '@/components/App'
import UserProvider from '@/context/UserProvider'
import { ModalProvider } from 'styled-react-modal'
import { BrowserRouter as Router } from 'react-router-dom'
import { gtmInitialize } from '@/helpers/gtm'
import NavigationListener from '@/helpers/NavigationListener'
import PageInfoUpdater from '@/helpers/PageInfoUpdater'
import GlobalStyle from './global/GlobalStyle'
import theme from './global/Theme'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import i18n from './i18n'
import { I18nextProvider } from 'react-i18next'
import { errorFilter } from './helpers/errorFiltering/errorFilter'
import { getEnvironmentVariable } from './helpers/environment'

errorFilter()

window.version = getEnvironmentVariable('BUILD_ID')

gtmInitialize()

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

ReactDOM.render(
  <>
    <GlobalStyle/>
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <ThemeProvider theme={theme}>
            <NavigationListener />
            <PageInfoUpdater />
            <ModalProvider>
              <Router>
                <App />
              </Router>
            </ModalProvider>
          </ThemeProvider>
        </UserProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </I18nextProvider>
  </>,

  document.getElementById('root'))

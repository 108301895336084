import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { apiFetch, ApiFetchError } from '@/hooks/useQuery/helpers'

export const queryKeyReports = ['reports']

export type PaymentRecord = {
    paymentDescription: string;
    paymentRunId: string;
  };

  type PaymentYearWithRecords = {
    year: number;
    paymentRecords: PaymentRecord[];
  };

export type IncomeReportData = PaymentYearWithRecords[];

export function useQueryReports (siteId: string, options?: UseQueryOptions<IncomeReportData>) {
  const queryKey = [...queryKeyReports, siteId]

  const queryFn = () => apiFetch(`v1/pricing/reports/${siteId}/income/summary`).catch(async (err) => {
    if (err instanceof ApiFetchError) {
      const status = err.response.status
      if (status === 404) {
        return []
      }
    }
  })

  return useQuery<IncomeReportData>(queryKey, queryFn, {
    enabled: !!siteId,
    ...options
  })
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { apiFetch } from '../helpers'

type BookingAvailabilityInput = {
    propertyId: string;
    fromDate: string;
    toDate: string;
}

export type BookingAvailability = {
    bookabilityState: 'allDatesBookable' | 'noDatesBookable';
} | {
    bookabilityState: 'onlyReturnedDatesBookable';
    bookableDates: string[];
}

export const queryKeyBookingAvailability = (input: BookingAvailabilityInput) => ['booking-availability', input]

export const queryFnBookingAvailability = async (input: BookingAvailabilityInput): Promise<BookingAvailability> => {
  const params = new URLSearchParams({ fromDate: input.fromDate, toDate: input.toDate })
  const url = `v1/properties/${input.propertyId}/bookability?${params.toString()}`

  return apiFetch(url)
}

export function useQueryBookingAvailability (input: BookingAvailabilityInput, options?: UseQueryOptions<BookingAvailability>) {
  const queryKey = queryKeyBookingAvailability(input)
  const queryFn = () => queryFnBookingAvailability(input)

  return useQuery<BookingAvailability>({
    enabled: !!input.propertyId,
    ...options,
    queryKey,
    queryFn
  })
}

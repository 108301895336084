import styled from 'styled-components'
import DesignTokens from '@/global/DesignTokens'

export const FilterButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .5rem;
  width: 100%;
  max-width: 14.875rem;
  height: 2.875rem;
  border: 2px solid ${DesignTokens.ColorButtonSolidPrimaryBackground};
  border-radius: 4px;
  cursor: pointer;

  @media (min-width: 827px) and (max-width: 920px) {
    max-width: 15rem;
  }
  @media (min-width: 779px) and (max-width: 824px) {
    max-width: 12rem;
  }
  @media (min-width: 747px) and (max-width: 778px) {
    max-width: 10rem;
  }
  @media (min-width: 731px) and (max-width: 746px) {
    max-width: 9rem;
  }
  @media (min-width: 660px) and (max-width: 730px) {
    max-width: 15rem;
  }
  @media only screen and (max-width: 659px) {
    max-width: 100%;
  }
  @media (min-width: 921px) and (max-width: 1024px) {
    min-width: 15rem;
    margin-top: 1.5rem;
  }
`
export const FilterButtonContainerCalendar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .5rem;
  width: 100%;
  max-width: 14.875rem;
  height: 2.875rem;
  border: 2px solid ${DesignTokens.ColorButtonSolidPrimaryBackground};

  border-radius: 4px;
  cursor: pointer;

  @media (min-width: 660px) and (max-width: 920px) {
    min-width: 15rem;
  }
  @media (min-width: 921px) and (max-width: 1024px) {
    min-width: 15rem;
    margin-top: 1.5rem;
  }
  @media only screen and (max-width: 659px) {
    max-width: 100%;
  }
`

export const FilterButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  color: ${DesignTokens.ColorButtonSolidPrimaryBackground};

  img{
    margin-right: 0.8rem;
  }
`

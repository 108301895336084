import { OccupancyFilterContext } from '../Models/OccupancyFilterContext'
import { Property } from '../Models/Property'

export const filterPropertiesByRequestedAdultOccupancy = (context: OccupancyFilterContext) : OccupancyFilterContext => {
  const filteredProperties = filterPropertiesByAdultCount(context)

  if (filteredProperties.length === 0) {
    return { ongoingPropertyCollection: filteredProperties, occupancyToFilterOn: context.occupancyToFilterOn }
  }

  return { ongoingPropertyCollection: filteredProperties, occupancyToFilterOn: context.occupancyToFilterOn }
}

const filterPropertiesByAdultCount = (context: OccupancyFilterContext) : Property[] => {
  const updatedCollection = context.ongoingPropertyCollection
    .filter((property) => property.propertyCapacity.maxAdults >= context.occupancyToFilterOn.requiredAdults)

  return updatedCollection
}
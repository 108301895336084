import styled from 'styled-components'
import { Textarea } from '@/components/__Inputs'

const StaticForm = styled.p`
  font-weight: 600;
`

const Container = styled.div`
  padding: 1rem;
  background-color: ${({theme}) => theme.colors.white};
`

const InputWrapper = styled.div`
  margin-bottom: 1.1rem;
`

const StyledTextarea = styled(Textarea)`
  min-height: 100px;
`

export { StaticForm, Container, InputWrapper, StyledTextarea }

import styled from 'styled-components'

const StyledContainer = styled.div`
${({ theme }) => `
h3, h4 {
margin-top: 2rem;
}

p {
margin-bottom: 1rem;
}

h3, h4, p {
font-size: 0.8rem;
}

.table-wrapper {
width: 100%;
overflow-x: auto;
}

table {
border: 1px solid ${theme.colors.greyThree};
width: 100%;

th, td {
font-size: 0.8rem;
padding: 1rem;
border-right: 1px solid ${theme.colors.greyThree};
border-bottom: 1px solid ${theme.colors.greyThree};
}

th, td p b { 
font-weight: bold;
}

td {
width: 20%;
}
}

@media only screen and (max-width: 500px) {
table {
th, td {
padding: .5rem;
}
}
}
`}
`

export { StyledContainer }

import React, { useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { useUser } from '@/context/UserProvider'
import { graphQlFetch, UPDATE_USER_CONTRACT } from '@/hooks/useGraphQl'
import { Form, Button, Checkbox } from '@/components/__Inputs'
import { TermsContent, ButtonContainer, Container, ContractContainer } from './UpdateContractsPage.style'
import { redirectTo } from '@/helpers/redirect/redirect'

const UpdateContractsPage = ({contracts, reloadContracts}) => {
  const [ formError, setFormError ] = useState(null)
  const [ loading, setLoading ] = useState(null)
  const {logout} = useUser()

  const currentContract = contracts && contracts[0]
  const hasContractName = !!currentContract?.contractName
  const hasContractTermsAndConditionsContent = !!currentContract?.termsAndConditionsContent
  const shouldRedirectToContractPage = hasContractName && !hasContractTermsAndConditionsContent

  if(shouldRedirectToContractPage) {
    redirectTo(`/contract/${currentContract.contractName}`)
  }

  const handleSubmit = async () => {
    setLoading(true)
    setFormError(null)
    try {
      const data = await graphQlFetch({
        query: UPDATE_USER_CONTRACT,
        variables: {
          contractId: currentContract.contractId
        }
      })
      if (data.success) {
        reloadContracts()
      } else {
        setFormError('There has been an issue submitting your response. Please try again later.')
      }
    } catch {
      setFormError('There has been an issue submitting your response. Please try again later.')
    }
  }

  if (shouldRedirectToContractPage) {
    return null
  }

  return (
    <Container>
      <ContractContainer>
        <h1 style={{marginBottom: '0px'}}>Updated terms and conditions</h1>
        <Form
          id="UpdatedTermsForm"
          initialValues={{ acceptTerms: false }}
          validationSchema={Yup.object({
            acceptTerms: Yup.bool().oneOf([true], 'Please confirm to continue')
          })}
          handleSubmit={handleSubmit}
          submitError={formError}
        >
          {currentContract && <TermsContent dangerouslySetInnerHTML={{__html: currentContract.termsAndConditionsContent}}></TermsContent>}

          <Checkbox
            name="acceptTerms"
            label="I confirm that I have read and accept the new terms and conditions outlined above."
          />
        </Form>

        <ButtonContainer>
          <Button primary type="submit" form="UpdatedTermsForm" loading={loading}>Accept</Button>
          <Button primaryLine onClick={() => logout()} >Logout</Button>
        </ButtonContainer>
      </ContractContainer>
    </Container>
  )
}

UpdateContractsPage.propTypes = {
  contracts: PropTypes.array,
  reloadContracts: PropTypes.func
}

export default UpdateContractsPage

import styled from 'styled-components'
import { Button } from '@/components/__Inputs'

const InfoSection = styled.div`
  margin: 1rem 0;
`

const TitleLink = styled.a`
  display: block;
  margin-bottom: 0.5rem;
`

const HeaderSection = styled.div`
  position: relative;
  min-height: 48px;
`

const StyledButton = styled(Button)`
  ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
    position: absolute;
    top: 0;
    right: 0;
  }
`

export { HeaderSection, StyledButton, InfoSection, TitleLink }

import React from 'react'
import styled from 'styled-components'

const Container = styled.label`
    display: flex;
    cursor: pointer;
    max-width: 12rem;
    align-items: center;
    box-shadow: ${({ theme }) => theme.boxShadowSoft};
    border-radius: ${({ theme }) => theme.borderRadius};
    background-color: ${({ theme }) => theme.colors.white};
    transition: ${({ theme }) => `${theme.animationSpeed} ease background-color`};
    ${({ style }) => style};

    &:hover {
        background-color: ${({ theme }) => theme.colors.backgroundHover};
    }
`

const Image = styled.img`
    width: 3rem;
    margin: 0.5rem;
    border-radius: ${({ theme }) => theme.borderRadius};
`

const Text = styled.p`
    margin-left: 0.5rem;
`

const Input = styled.input`
    width: 1px;
    height: 1px;
    visibility: hidden;
    position: absolute;
`

const UploadSvg = styled((props) => (
  <svg {...props} enableBackground="new 0 0 50 50" version="1.1" viewBox="0 0 50 50" width="50px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <rect fill="none" height="50" width="50"/>
    <path className="strokes" d="  M32,35c0,0,8.312,0,9.098,0C45.463,35,49,31.463,49,27.099s-3.537-7.902-7.902-7.902c-0.02,0-0.038,0.003-0.058,0.003  c0.061-0.494,0.103-0.994,0.103-1.504c0-6.71-5.439-12.15-12.15-12.15c-5.229,0-9.672,3.309-11.386,7.941  c-1.087-1.089-2.591-1.764-4.251-1.764c-3.319,0-6.009,2.69-6.009,6.008c0,0.085,0.01,0.167,0.013,0.251  C3.695,18.995,1,22.344,1,26.331C1,31.119,4.881,35,9.67,35c0.827,0,8.33,0,8.33,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
    <polyline className="strokes" fill="none" points="20,28 25,23 30,28   " stroke="#000000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"/>
    <line className="strokes" fill="none" stroke="#000000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" x1="25" x2="25" y1="43" y2="23.333"/>
  </svg>
))`
    width: 2.5rem;
    margin: 0.5rem;

    .strokes {
        stroke: ${({ theme }) => theme.colors.secondary};
    }
`

export { Container, Image, Text, Input, UploadSvg }

import React from 'react'
import PropTypes from 'prop-types'
import { capitaliseExceptAbbreviations } from '@/helpers/stringUtils'
import { ItemContainer, Label } from './PropertyDescriptionSection.styles'

const PropertyDescriptionSection = ({item, children}) => {
  const { sectionName } = item

  return (
    <ItemContainer>
      <Label>{capitaliseExceptAbbreviations(sectionName)}</Label>
      {children}
    </ItemContainer>

  )
}

PropertyDescriptionSection.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    sectionName: PropTypes.string,
    description: PropTypes.string
  }),
  children: PropTypes.object
}

export default PropertyDescriptionSection

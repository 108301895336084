import styled from 'styled-components'

const Arrow = styled.div`
    width: 78.5vw;
    display: flex;
    margin-top: -1px;

    .container{
      display: flex;
      flex-direction: row;
      align-items: center;

    .arrowContainer{
      width: 78.5vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 1px;

      .previous, .next{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto 2rem;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-shadow: 0px 0.3px 0.5px ${({theme}) => theme.colors.radioBtnBoxShadow}, 0px 2px 4px ${({theme}) => theme.colors.subMenuBoxShadow};
        background: ${({theme}) => theme.colors.white};
        cursor: pointer;

        .previousImg, .nextImg{
          margin: 0 auto;
          width: 40%;
          height: auto;
          color: ${({theme}) => theme.colors.arrows};
          transition: right 0.5s ease-in-out;
        }

        .nextImg{
          transform: rotate(180deg);
        }

        &:hover{
          box-shadow: 0.5px 0.5px 5px ${({theme}) => theme.colors.arrowShadowsFromHover}, 5px 5px 4px ${({theme}) => theme.colors.arrowShadowsToHover};

          .nextImg{
            width: 45%;
            margin-left: 33%;
          }
          .previousImg{
            width: 45%;
            margin-right: 33%;
          }
        }
      }

      .previousArrowDisabled{
          box-shadow: none;
          background-color:  ${({theme}) => theme.colors.white};
          border: 1px solid ${({theme}) => theme.colors.greyFour};
          pointer-events: none;


          &:hover{
            box-shadow: none;
          }

          img{
            opacity: 0.4;
          }
      }

    }
  }
`

export {Arrow}

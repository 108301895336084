import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  margin: 4rem auto;
  width: 90%;
  max-width: 40rem;
  border-radius: 1rem;
  background-color: ${({theme}) => theme.colors.greySix};
`

const SearchIcon = styled.img``

const Text = styled.div`
  max-width: 11rem;
  font-size: 1.25rem;
  text-align: center;
  margin-top: 1rem;

  strong {
    font-weight: 600;
  }
`

export { Container, SearchIcon, Text }

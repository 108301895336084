import styled from 'styled-components'
import Modal from '@/components/__UI/Modal'

const StyledModal = styled(Modal)`
    max-width: 40rem;
    padding: 1rem 1rem 1.5rem;

    ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
      padding: 1rem 3rem 1.5rem;
    }
`

const SeasonsContainer = styled.div`
  margin-top: 1rem;

  @media screen and (min-width: 900px) {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`

export { StyledModal, SeasonsContainer }

import React, { useEffect, useMemo, useState } from 'react'
import { PageWrapper, Spinner } from '@/components/__UI'
import { useTranslation } from 'react-i18next'
import { ListPropertyCollection, SiteUserProperty, useQueryListPropertyCollection, useQueryReports, useQueryReportsCsv } from '@/hooks/useQuery'
import { Button, SelectDropdown } from '@/components/__Inputs'
import { PaymentRecord } from '@/hooks/useQuery/queries/useQueryReports'
import { DropdownContainer, ErrorText } from './ReportsPage.styles'
import { createCsvFromReport } from '@/helpers/files'

const ReportsPage = () => {
  const { t } = useTranslation()
  const [sites, setSites] = useState<ListPropertyCollection | undefined>()
  const [selectedSite, setSelectedSite] = useState<SiteUserProperty | undefined>()
  const [selectedYear, setSelectedYear] = useState<number | undefined>()
  const [selectedPaymentRecord, setSelectedPaymentRecord] = useState<PaymentRecord | null>(null)
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false)

  const { data: siteData, isLoading: isSitesLoading, error: sitesError } = useQueryListPropertyCollection({ operationId: 'CanAccessOwnerIncomeReport' })

  const { data: reportData, isLoading: isReportLoading, error: reportError } = useQueryReports(selectedSite?.siteId || '', { enabled: !!selectedSite?.siteId })

  const { isError: csvError, refetch, isFetching } = useQueryReportsCsv(selectedSite?.siteId || '', selectedPaymentRecord?.paymentRunId || '', false)

  useEffect(() => {
    if (siteData && siteData.siteUserProperties) {
      setSites(siteData)
      setSelectedSite(siteData?.siteUserProperties[0])
    }
  }, [siteData])

  useEffect(() => {
    setShowErrorMessage(csvError)
  }, [csvError])

  const extractedYears = reportData?.map(record => record.year).sort((a, b) => b - a)

  const paymentRecordsForSelectedYear = useMemo(() =>
    reportData?.find(record => record.year === selectedYear)?.paymentRecords || []
  , [reportData, selectedYear])

  const isReportsDataEmpty = extractedYears?.length === 0

  useEffect(function setInitialYearDropdownState () {
    if (extractedYears?.length === 0) {
      setSelectedYear(undefined)
    }

    if (!selectedYear && extractedYears) {
      setSelectedYear(extractedYears[0])
    }
  }, [reportData, extractedYears, selectedYear])

  useEffect(function setReportsDropdownState () {
    if (paymentRecordsForSelectedYear) {
      setSelectedPaymentRecord(paymentRecordsForSelectedYear[0])
    }
  }, [selectedYear, paymentRecordsForSelectedYear])

  const downloadCsv = async () => {
    try {
      const newCsvData = await refetch()

      if (newCsvData?.data) {
        createCsvFromReport(t('reportsDataTitle', { siteName: selectedSite?.siteName, selectedYear: selectedYear, paymentDescription: selectedPaymentRecord?.paymentDescription }), newCsvData.data, t)
      } else {
        setShowErrorMessage(true)
      }
    } catch (error) {
      setShowErrorMessage(true)
    }
  }

  if (isSitesLoading) {
    return <Spinner />
  }

  if (sitesError || reportError) {
    return <div>{t('errorMessageSomethingWentWrong')}</div>
  }

  return (
    <PageWrapper title={t('reportPageTitle')}>
      {selectedSite && <DropdownContainer>

        <SelectDropdown
          disabled={sites?.siteUserProperties.length === 1}
          value={selectedSite}
          options={sites?.siteUserProperties}
          onChange={(site) => {
            setSelectedSite(site)
            setShowErrorMessage(false)
            setSelectedYear(undefined)
          } }
          loadingText={t('loading')}
          getOptionLabel={(option) => option['siteName']}
          getOptionValue={(option) => option['siteId']}
          className={undefined}
          defaultValue={undefined}
          hasError={undefined}
          isDisabled={undefined}
          isLoading={undefined}
          label={undefined}
          noItemsText={undefined}
          placeholder={undefined}
          noSingleLock={undefined}
          tooltip={undefined} />
        <SelectDropdown
          options={ extractedYears ? extractedYears.map(year => ({ label: t('ownerPaymentsDataLabelPrefix', {year: year, currency: 'Pence'}), value: year })) : []}
          value={isReportsDataEmpty ? undefined : { label: t('ownerPaymentsDataLabelPrefix', {year: selectedYear, currency: 'Pence'}), value: selectedYear }}
          onChange={(option) => {
            setSelectedYear(option.value)
            setShowErrorMessage(false)
          }}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          placeholder={isReportsDataEmpty ? t('noReportsDataAvailable') : t('selectYearPlaceholder')}
          loadingText={t('loading')}
          disabled={isReportLoading || isReportsDataEmpty }
          isLoading={isReportLoading}
          className={undefined}
          defaultValue={undefined}
          hasError={undefined}
          isDisabled={undefined}
          label={undefined}
          noItemsText={undefined}
          noSingleLock={undefined}
          tooltip={undefined}

        />

        <SelectDropdown
          options={paymentRecordsForSelectedYear}
          value={isReportsDataEmpty ? undefined : selectedPaymentRecord}
          onChange={(paymentRecord) => {
            setSelectedPaymentRecord(paymentRecord)
            setShowErrorMessage(false)
          }}
          getOptionLabel={(option) => option.paymentDescription}
          getOptionValue={(option) => option.paymentRunId}
          placeholder={ isReportsDataEmpty ? t('noReportsDataAvailable') : t('selectPaymentRecordPlaceholder')}
          isLoading={isReportLoading}
          loadingText={t('loading')}
          className={undefined}
          defaultValue={undefined}
          hasError={undefined}
          isDisabled={isReportLoading || isReportsDataEmpty}
          label={undefined}
          noItemsText={undefined}
          noSingleLock={undefined}
          tooltip={undefined}
          disabled={undefined} />
        <Button primary onClick={downloadCsv} loading={isFetching} trackable={undefined} disabled={ !(selectedSite?.siteId && selectedPaymentRecord?.paymentRunId)} >{t('downloadCSV')}</Button>
        { showErrorMessage && !isFetching && <ErrorText data-testid='error-text'>{t('errorMessageSomethingWentWrong')}</ErrorText>}
      </DropdownContainer>}
    </PageWrapper>
  )
}

export default ReportsPage

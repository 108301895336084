import React, { useState } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { Spinner } from '@/components/__UI'
import PropertyDropdown from '@/components/__Inputs/PropertyDropdown'
import useGraphQl, { GET_PROPERTY_ALLOWANCES } from '@/hooks/useGraphQl'
import { BookingAllowanceListItem, BookingAllowanceSeasons } from '@/components/__BookingAllowances'
import { Header, StyledDropdown, PropertyDropdownContainer, DropdownContainer, Section } from './BookingAllowanceList.styles'

const BookingAllowanceList = () => {
  const currentYear = dayjs().year()
  const currentYearOption = {label: currentYear, value: currentYear}
  const [selectedProperty, setProperty] = useState(null)
  const [selectedYear, setSelectedYear] = useState(currentYearOption)
  const [propertyAllowances, setPropertyAllowances] = useState()

  React.useEffect(() => {
    return () => {
      setProperty(null)
      setSelectedYear(currentYearOption)
      setPropertyAllowances()
    }
    // eslint-disable-next-line
  }, [])

  const getYearOptions = () => {
    return [
      {label: 'All years', value: 'all'},
      {label: currentYear + 1, value: currentYear + 1},
      currentYearOption,
      {label: currentYear - 1, value: currentYear - 1}
    ]
  }

  const [ propertyAllowanceData, loadingAllowances ] = useGraphQl({
    query: GET_PROPERTY_ALLOWANCES,
    isEnabled: selectedProperty !== null,
    variables: {
      propertyId: selectedProperty && selectedProperty.propertyId
    },
    onSuccess: (data) => {
      filterAllowances(data, currentYearOption)
    }
  })

  const filterAllowances = (list, {value}) => {
    let newList
    if (list) {
      if (value === 'all') {
        newList = list.filter(({year}) => (
          year === (currentYear - 1).toString() ||
          year === (currentYear).toString() ||
          year === (currentYear + 1).toString()
        ))
      } else {
        newList = list.filter(({year}) => year === value.toString())
      }
      newList = newList.sort((a, b) => parseInt(b.year) - parseInt(a.year))
    }
    setPropertyAllowances(newList)
  }

  const onYearSelectChange = (value) => {
    filterAllowances(propertyAllowanceData, value)
    setSelectedYear(value)
  }

  return (
    <>
      <Header>
        <PropertyDropdownContainer>
          <PropertyDropdown
            label
            onChange={(value) => {
              setProperty(value)
              setSelectedYear(currentYearOption)
            }}
            operationID='viewFinancialReports'
          />
        </PropertyDropdownContainer>
        <DropdownContainer>
          <StyledDropdown
            label='Year'
            width={'140px !important'}
            name="yearSelect"
            defaultValue={selectedYear}
            value={selectedYear}
            options={getYearOptions()}
            onChange={(value) => onYearSelectChange(value)}
          />
        </DropdownContainer>

        <BookingAllowanceSeasons propertyId={selectedProperty && selectedProperty.propertyId} inputYear={selectedYear.value} />
      </Header>

      {loadingAllowances
        ? <Section>
          <Spinner />
        </Section>
        : <List propertyAllowances={propertyAllowances} />
      }
    </>
  )
}

const List = ({propertyAllowances}) => {
  if (propertyAllowances && Array.isArray(propertyAllowances)) {
    return <>{propertyAllowances.map((item, key) => <BookingAllowanceListItem key={key} item={item} />)}</>
  }
  return (
    <Section>Unable to find any allowances for this selection.</Section>
  )
}

List.propTypes = {
  propertyAllowances: PropTypes.array
}

export default BookingAllowanceList

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import toast from '@/helpers/toast'
import { stripStartAndEndQuotationsFromString } from '@/helpers/stringUtils'
import { Form, Button } from '@/components/__Inputs'
import { PropertyAmendSuccessModal } from '@/components/__Modals'
import { Container, Spinner } from '@/components/__UI'
import { PropertyDescriptionSection } from '@/components/__PropertyAmendsComponents'
import { BtnContainer } from '../PropertyDescriptionEdit/PropertyDescriptionEdit.styles'
import useGraphQl, {
  graphQlFetch,
  GET_PROPERTY_ARRIVAL_DETAILS,
  UPDATE_PROPERTY_ARRIVAL_DETAILS
} from '@/hooks/useGraphQl'
import { ErrorText } from './PropertyArrivalEdit.styles'
import EditableDiv from '../../__UI/EditableDiv/EditableDiv'

const PropertyArrivalEdit = ({ propertyId }) => {
  const [defaultValues, setDefaultValues] = useState([])
  const [hasError, setHasError] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [changedData, setChangedData] = useState([])

  useEffect(() => {
    setDefaultValues([
      { id: 'directions', description: '', sectionName: 'Directions' },
      { id: 'key-collection', description: '', sectionName: 'Key collection' },
      {
        id: 'additional-information',
        description: '',
        sectionName: 'Additional information',
        placeholder: 'Please write additional information here'
      }
    ])
  }, [propertyId])

  const [arrivalValues, loadingArrivalValues] = useGraphQl({
    query: GET_PROPERTY_ARRIVAL_DETAILS,
    variables: {
      propertyId
    }
  })

  const getInitialValues = (values) => {
    let defaults = []
    values.forEach(({ id, description }) => {
      defaults = defaultValues.map((d) => {
        if (d.id === id) {
          d.description = description
        }
        return d
      })
    })
    return defaults
  }

  const getChanged = (values) => {
    const outputs = []
    const originalData = getInitialValues(arrivalValues)
    values.forEach((objProps) => {
      const submittedTxt = stripStartAndEndQuotationsFromString(objProps.description)
      const item = originalData.find((element) => objProps.id === element.id)
      const sectionItemName = objProps.sectionName

      const originalArrivalDescription = item !== undefined ? item.description : ''

      if (item) {
        if (item.description !== submittedTxt) {
          outputs.push({
            id: objProps.id,
            description: submittedTxt,
            sectionName: item.sectionName,
            originalDescription: originalArrivalDescription
          })
        }
      } else {
        outputs.push({
          id: objProps.id,
          description: submittedTxt,
          sectionName: sectionItemName,
          originalDescription: originalArrivalDescription
        })
      }
    })
    return outputs
  }

  const handleArrSubmit = async () => {
    setHasError(false)
    setSubmitDisabled(true)
    const changed = getChanged(changedData)

    if (changed.length > 0) {
      try {
        const data = await graphQlFetch({
          query: UPDATE_PROPERTY_ARRIVAL_DETAILS,
          variables: {
            propertyId,
            description: changed
          }
        })
        if (data.success) {
          setShowModal(true)
        } else {
          setHasError(true)
          setSubmitDisabled(false)
        }
      } catch (error) {
        setHasError(true)
        setSubmitDisabled(false)
      }
    } else {
      toast.info('No Changes detected.')
      setSubmitDisabled(true)
    }
  }

  if (loadingArrivalValues) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  const handleChange = (id, sectionName, sectionDescription) => {
    if (changedData.find((el) => el.id === id)) {
      const newData = changedData.map((data) => {
        if (data.id === id) {
          data.description = sectionDescription
          return data
        }
        return data
      })
      setChangedData([...newData])
    } else {
      setChangedData([
        ...changedData,
        { id, sectionName, description: sectionDescription }
      ])
    }
  }

  return (
    <>
      <Form
        id="PropertyDescriptionForm"
        initialValues={getInitialValues(arrivalValues)}
        handleSubmit={handleArrSubmit}
      >
        {defaultValues.map((item, x) => (
          <PropertyDescriptionSection key={x} item={item}>
            <EditableDiv
              contentEditable={true}
              item={item}
              handleChange={handleChange}
              placeholder={`type in your ${item.sectionName.toLowerCase()}`}
            />
          </PropertyDescriptionSection>
        ))}
        {hasError && (
          <ErrorText>
            There has been an issue submitting your response.
          </ErrorText>
        )}
        <BtnContainer>
          <Button data-testid="submitArrivalInfoBtn" primary disabled={submitDisabled} type="submit">
            Submit
          </Button>
        </BtnContainer>
      </Form>
      {showModal && <PropertyAmendSuccessModal onClose={setShowModal} />}
    </>
  )
}

PropertyArrivalEdit.propTypes = {
  propertyId: PropTypes.string,
  showEdit: PropTypes.bool
}

export default PropertyArrivalEdit

export function memoize<
    T extends (...args: any[]) => any,
>(fn: T): T {
  const cache = new Map<string, ReturnType<T>>()
  return function (...args: Parameters<T>): ReturnType<T> {
    const stringifiedArgs = JSON.stringify(args)
    if (!cache.has(stringifiedArgs)) {
      const result = fn(...args)
      cache.set(stringifiedArgs, result)
    }
    return cache.get(stringifiedArgs) as ReturnType<T>
  } as T
}

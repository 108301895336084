import styled from 'styled-components';

export const ErrorMessage = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;
  padding: 3rem;
  text-align: center;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.greyThree};
  box-shadow: ${({ theme }) => theme.boxShadowSoft};
`;

export const PageContainer = styled.div`
  max-width: 100vw;
  min-height: 60vh;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  @media (width >= 1200px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const SelectContainer = styled.div`
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.colors.white};
  z-index: 3;

  padding-top: 1.5rem;

  @media (width < 1200px) {
    padding: 1.5rem 1rem;
  }
`;

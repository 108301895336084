export const checkImageDimensions = (self) => {
  const widthRange = 0.1 // 10%
  const minWidth = 1200
  const minHeight = 900
  const width = self.width + (self.width * widthRange)
  const height = self.height

  if (self.width < minWidth || self.height < minHeight) return new Error(`Please upload an image of a higher resolution. The minimum requirement is ${minWidth}x${minHeight}. For further guidance please refer to our video guide.`)
  if (height > width) return new Error('Please upload a landscape image. Portrait images are unsuitable. For further guidance please refer to our video guide.')
}

export default (file) => {
  return new Promise((resolve, reject) => {
    const url = URL.createObjectURL(file)
    const img = new Image()
    img.src = url
    img.onload = function () {
      const result = checkImageDimensions(this)
      result instanceof Error ? reject(result) : resolve()
    }
  })
}

export default function chat (g, e, n, es, ys, customerEmail) {
  g['_genesysJs'] = e
  g[e] = g[e] || function () {
    (g[e].q = g[e].q || []).push(arguments)
  }
  g[e].t = 1 * new Date()
  g[e].c = es
  ys = document.createElement('script')
  ys.async = 1; ys.src = n; ys.charset = 'utf-8'
  document.head.appendChild(ys)

  ys.onload = function () {
    g[e]('command', 'Database.set', {
      messaging: {
        customAttributes: {
          origin: window.location.origin,
          email: customerEmail
        }
      }
    })
  }
}

import React from 'react'
import FAQsList from '@/components/FAQs/FAQsList'
import { PageWrapper } from '@/components/__UI'
import { SideBar, MainImage, ContentContainer } from './DataProtectionPage.style'
import FAQs from '@/constants/faqs/faqs-data-protection'
import mainImage from '@/assets/images/gdpr-notice.png'

const DataProtectionPage = () => {
  return (
    <PageWrapper
      title='Data protection'
    >
      <ContentContainer>
        <div>
          <MainImage src={mainImage} />
        </div>
        <SideBar>
          <h3>Any questions?</h3>
          <p>Please take a look at the following information about data protection.</p>
          <FAQsList
            smallBar
            faqData={FAQs || []}
            filter={'Data Protection'}
          />
        </SideBar>
      </ContentContainer>
    </PageWrapper>
  )
}

export default DataProtectionPage

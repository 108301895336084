import PropTypes from 'prop-types'
import React, { useState } from 'react'
import toast from '@/helpers/toast'
import Spinner from '@/components/__UI/Spinner'
import { Container, Image, Text, Input, UploadSvg } from './ImageUploader.styles'

const ImageUploader = ({ className, id, multiple, onChange, style, children, sizeLimit, ...props }) => {
  const [ image, setImage ] = useState(null)
  const [ fileCount, setFileCount ] = useState(0)
  const [ isLoading, setIsLoading ] = useState(false)

  const handleChange = async (e) => {
    setIsLoading(true)

    const fileData = await Promise.all(Array.from(e.target.files).map(async file => {
      const text = await file.text()
      if (!sizeLimit) {
        return { blob: file, text }
      }

      if (file.name === 'item' || typeof file.name === 'undefined') {
        toast.error(`File name '${file.name}' not valid.`)
        return
      }

      const fileSize = ((file.size / 1024) / 1024).toFixed(4) // MB
      if (fileSize <= sizeLimit) {
        return { blob: file, text }
      } else {
        toast.error(`${file.name}: Exceeds the size limit of ${sizeLimit}mb.`)
      }
    }))

    setIsLoading(false)

    if (fileData !== undefined) {
      setFileCount(fileData.length)
      setImage(fileData.length ? fileData[0] && fileData[0].blob : null)

      onChange && onChange(fileData)
    }
  }

  const getText = () => {
    if (fileCount > 1) {
      return `${fileCount} files`
    } else if (image) {
      return image.name
    } else {
      return multiple ? 'Upload files' : 'Upload a file'
    }
  }

  return (
    <Container htmlFor={id} className={className} style={style}>
      {children}
      {!children &&
        <>
          {!image && <UploadSvg />}
          {image && <Image src={URL.createObjectURL(image)} alt={image.name} />}
          {isLoading ? <Spinner /> : <Text>{getText()}</Text>}
        </>
      }
      <Input
        id={id}
        multiple={multiple}
        {...props}
        type="file"
        onChange={handleChange}
        accept="image/*"
      />
    </Container>
  )
}

ImageUploader.propTypes = {
  className: PropTypes.string,
  sizeLimit: PropTypes.number,
  style: PropTypes.object,
  children: PropTypes.object,
  id: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  onChange: PropTypes.func
}

export default ImageUploader

import React from 'react'

export default [
  {
    'tags': ['Health & Safety'],
    'title': 'General Health and Safety Information',
    'content': (<p className='faq-intro'>There are many things to consider when identifying hazards within your holiday let.  It is always a good idea  to  conduct  a  general  risk  assessment  of  your  property  to  ensure  that  you  have  thought  of everything, and to prevent any accidents from occurring.</p>),
    'moreDetails': (<div><p>Things to consider may include (but are not limited to) the items listed below. Please note that this is not an exhaustive guide, nor a statement of the law, but a suggestion of some key topics to consider:</p><ul><li>Ensure that you have adequate public liability insurance in place.</li><li>Ensure that all stairs have a suitable, sturdy handrail.</li><li>Ensure that any of your stairs which have unusual features are highlighted to customers.</li><li>Ensure that any of your stairs which have unusual features are highlighted to customers</li><li>If your property has an open fire, log burner or multi fuel stove, the chimney should be swept annually  and  there  should  be  an  adequately  located  working  audible  carbon  monoxide detector.</li><li>Whirlpool baths should be managed in accordance with the manufacturer’s recommendations and as a minimum you should keep a record of the written procedures for the cleaning and disinfection of the whirlpool bath.</li><li>Any electrically operated features, such as gates, garage doors, should be clearly signed giving the user clear operating instructions.</li><li>Any activity equipment which your guests can make use of must be regularly maintained and insured for public use.</li><li>First floor windows with a low sill (less than 1 metre from the ground) should be fitted with window restrictors.</li><li>Ensure that all blind cords are tethered for the safety of children staying at your property.</li></ul></div>)
  },
  {
    'tags': ['Health & Safety'],
    'title': 'Electrical Safety',
    'content': (<p className='faq-intro'>All electrical circuits, plugs and appliances throughout the building should be maintained in a safe and serviceable condition. The service information together with details of any corrective action taken to rectify faults should be recorded and retained.</p>),
    'moreDetails': (<div><ul><li>An  Electrical  Safety  Inspection  every  five  years  of  existing  electrical  installations  may  be appropriate  and  should  be  completed  by  a  qualified  electrician.    You  should  choose  an electrical  contractor  who  belongs  either  to  the  Electrical  Contractors’  Association  or  to  the National Inspection Council for Electrical Installation Contracting (NICEIC).</li><li>If you own a property in Scotland then this is mandatory as part of the Scottish Licensing Laws. All owners in Scotland can find information about this by visiting: <a href='https://www.visitscotland.org/supporting-your-business/advice/short-term-lets-legislation' target='_blank' rel='noopener noreferrer'>Short term lets legislation</a></li><li>Portable Appliance Testing (PAT testing) is recommended on an annual basis, however, if you own a property in Scotland then this is mandatory as part of the Scottish Licensing Laws.</li></ul></div>)
  },
  {
    'tags': ['Health & Safety'],
    'title': 'Gas Safety',
    'content': (<p className='faq-intro'>You  are  required  by  law  to  ensure  that  you  hold  an  up-to-date  Landlords  Gas  Safety Certificate, also referred to as a CP12 – this must be carried out annually for any gas appliances in your holiday let, including gas BBQ’s and gas patio heaters.</p>),
    'moreDetails': (<div><ul><li>A copy  of  the  Landlord’s  Gas  Safety  Certificate  must  be  displayed  in  a  prominent  position within your holiday home. You will need to keep copies of the record for at least 2 years. If you have benefited from the new regulations allowing flexibility in timing of gas safety checks, records must be kept until two further gas safety checks have been carried out. The service information,  together  with  details  of  any  corrective  action  to  rectify  faults,  should  also  be kept.</li><li>Working,  audible  carbon  monoxide  detectors  are  advised  to  be  fitted  in  every  room  where there is a gas, oil or solid fuel burning appliance and they should be tested regularly.</li><li>Further detailed guidance for owners can be found on the HSE website: <a href='https://www.hse.gov.uk/gas/domestic/newschemecontract.htm' target='_blank' rel='noopener noreferrer'>www.hse.gov.uk/gas/domestic/newschemecontract.htm</a></li></ul></div>)
  },
  {
    'tags': ['Health & Safety'],
    'title': 'Swimming Pool/Hot Tub Safety',
    'content': (<p className='faq-intro'>When  managing  safety  in  a  swimming  pool  we  suggest  you  read  and  be  familiar  with  HSE guidance and this can be found; <a href='https://www.hse.gov.uk/pubns/priced/hsg179.pdf' target='_blank' rel='noopener noreferrer'>Health and Safety in Swimming Pools</a><p>When managing safety in a hot tub we suggest you read and be familiar with HSE guidance and this can be found; <a href='https://www.hse.gov.uk/pubns/priced/hsg282.pdf' target='_blank' rel='noopener noreferrer'>Health and Safety in Spa Pools</a>.</p></p>)
  },
  {
    'tags': ['Health & Safety'],
    'title': 'Fire Safety',
    'content': (<p className='faq-intro'>You must complete an annual Fire Risk Assessment of your property by law (Regulatory Reform (Fire Safety Order) 2005 and record the findings and corrective action. Guidance on how to complete your Fire Risk Assessment can be obtained from <a href='https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/1147723/A_guide_to_making_your_small_paying-guest-accommodation_safe_from_fire.pdf' target='_blank' rel='noopener noreferrer'>Government Guide</a>. This guide is for guest accommodation with simple layouts, limited fire risks and a small number of bedrooms designated as guest sleeping accommodation for short term lets, such as small bed and breakfast, guest houses and self-catering accommodation.</p>),
    'moreDetails': (<div><ul><li> If your paying guest accommodation falls outside the scope of this guide, then you should base your precautions and your fire risk assessment on Government guidance here <a href="https://www.gov.uk/government/publications/fire-safety-risk-assessment-sleeping-accommodation" target='blank' rel='noopener noreferrer'>Fire safety risk assessment:sleeping-accommodation</a></li>
      <li>If you do not feel comfortable undertaking your own Fire Risk Assessment then you can seek advice from a professional who will conduct this assessment for you. If you need any help in finding the services of a fire safety professional, please take a look at the guide issued by the Fire Sector Federation: <a href="https://www.firesectorfederation.co.uk/wp-content/uploads/2020/10/FSF_Guide_V2.pdf" target='_blank' rel='noopener noreferrer'>https://www.firesectorfederation.co.uk</a></li><li>ALL holiday accommodation must be equipped with smoke alarms, which should be interlinked and connected to the mains. You should seek professional advice on smoke detection needed for your holiday home to ensure you are complying with the law.</li><li>All smoke alarms should be tested on a weekly basis and you should record this.</li></ul></div>)
  },
  {
    'tags': ['Health & Safety'],
    'title': 'Private Water Supply',
    'content': (<p className='faq-intro'>A private water supply is any supply that is not provided by a water utilities company and the water is consumed for domestic or part of a business activity. These supplies have to be tested on an annual basis as part of the Private Water Supply Regulations – more information can be found by clicking on the following link; <a href='https://www.dwi.gov.uk/private-water-supplies/' target='_blank' rel='noopener noreferrer'>www.dwi.gov.uk/private-water-supplies</a>.</p>)
  },
  {
    'tags': ['Health & Safety'],
    'title': 'Oil Safety',
    'content': (<p className='faq-intro'>If you have an oil supply to your property you should ensure that the installation and operation of oil fired systems are safe.</p>),
    'moreDetails': (<div><p>Whilst  there  is  currently  no  legal  requirement  to  obtain  a  Landlords  Safety  Certificate  for  oil  fired appliances within a holiday let, you should follow BS 5410, which requires oil fired appliances to be serviced annually in accordance with manufacturer’s instructions. Any individual carrying out services or checks on oil fired appliances must be OFTEC registered and further details can be found by visiting; <a href='https://www.oftec.org/consumers/why-choose-an-oftec-registered-competent-person' target='_blank' rel='noopener noreferrer'>www.oftec.org/consumers/why-choose-an-oftec-registered-competent-person</a>.</p></div>)
  },
  {
    'tags': ['Health & Safety'],
    'title': 'Outside Areas and Gardens',
    'content': (<p className='faq-intro'>Automatic outside light(s) should be provided to illuminate the area for your guests.</p>),
    'moreDetails': (<div><ul><li>Walls  or  balustrades  on  walkways  should  be  over  1m  high  from  the  floor  to  the  top  of  the wall/balustrade and any gaps should be less than 10cm</li><li>Garden ponds (fenced or unfenced), streams or rivers accessible from the property must be detailed in the property description.  Warning signs must be placed where necessary.</li><li>Any  machinery/farm  vehicles  in  the  vicinity  of  the  property  should  be  highlighted  in  your property description.</li><li>Any  significant  drops  within  the  grounds  must  be  detailed  in  the  property  description  and brought to the attention of the guests on arrival. </li><li>Any   children’s   playground   equipment   must   comply   with   British   Safety   Standards <a href='https://www.bsigroup.com/contentassets/fd0e8cd7dd174774890cd67e03389a99/childrens-playground-stds-hi-res.pdf' target='_blank' rel='noopener noreferrer'>www.bsigroup.com.</a></li></ul></div>)
  }
]

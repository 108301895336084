import cogoToast from 'cogo-toast'
import useDeepEffect from '@/hooks/useDeepEffect'
import { PageWrapper, Spinner } from '@/components/__UI'
import { Button, Toggle } from '@/components/__Inputs'
import React, { useCallback, useRef, useState } from 'react'
import { SelectWrapper, StyledSelect, Warning } from './CaretakerPage.styles'
import { useOnBeforeNavigation } from '@/helpers/NavigationListener'
import useGraphQl, { GET_CARETAKERS, GET_PERMISSIONS, UPDATE_PERMISSIONS, graphQlFetch } from '@/hooks/useGraphQl'
import Theme from '../../global/Theme'

// Permission labels
const LABELS = {
  1: 'Can view booking details',
  2: 'Can access booking financial information',
  3: 'Can create bookings',
  4: 'Can amend bookings',
  5: 'Can cancel bookings',
  6: 'Can view pricing and financial tabs and reports',
  7: 'Can view property details',
  8: 'Can view owner information tab',
  9: 'Can view benefits tab',
  11: 'Can view google analytics tab',
  12: 'Can respond to reviews'
}

const CaretakerPage = () => {
  const initialPermissions = useRef()

  const [ isSaving, setSaving ] = useState(false)
  const [ caretaker, setCaretaker ] = useState(null)
  const [ permissions, setPermissions ] = useState([])
  const [ detectedChange, setDetectedChange ] = useState(false)

  React.useEffect(() => {
    return () => {
      setSaving(false)
      setCaretaker(null)
      setPermissions([])
      setDetectedChange(false)
    }
  }, [])

  const getPermission = id => permissions.find(permission => permission.id === id)

  const [ caretakers, loadingCaretakers ] = useGraphQl({
    query: GET_CARETAKERS,
    defaultValue: [],
    onSuccess: data => {
      if (data.length) {
        setCaretaker(data[0].id)
      }
    }
  })

  const { loading: isLoadingPermissions } = useGraphQl({
    query: GET_PERMISSIONS,
    defaultValue: [],
    variables: { caretakerId: caretaker },
    asObject: true,
    onSuccess: data => {
      if (data.length) {
        setPermissions(data)
        initialPermissions.current = JSON.stringify(data)
      }
    }
  })

  // Watch permissions to check for changes
  useDeepEffect(() => {
    if (permissions.length && caretakers.length) {
      setDetectedChange(JSON.stringify(permissions) !== initialPermissions.current)
    }
  }, [JSON.stringify(permissions)])

  // Disable navigation if changes made
  useOnBeforeNavigation(useCallback(() => detectedChange, [detectedChange]))

  const savePermissions = () => {
    setSaving(true)

    if (detectedChange) {
      graphQlFetch({
        query: UPDATE_PERMISSIONS,
        variables: {
          caretakerId: caretaker,
          permissions: permissions
        }
      })
        .then(() => {
          setDetectedChange(false)
          initialPermissions.current = JSON.stringify(permissions)
          cogoToast.success('Permissions saved')
        })
        .catch(() => cogoToast.error('Failed to save permissions'))
        .finally(() => setSaving(false))
    } else {
      cogoToast.info('Permissions have not been changed')
      setSaving(false)
    }
  }

  const buildPermission = (permission) => {
    const isDisabled = ((permission.id === '3' || permission.id === '5') && !getPermission('1').isAllowed)
    const reviewDisabled = (permission.id === '12' && !getPermission('7').isAllowed)
    const label = LABELS[permission.id]

    return (
      <div key={permission.id} style={{margin: '.5rem 0'}}>
        <Toggle
          id={permission.id}
          name={permission.id}
          disabled={isDisabled || reviewDisabled}
          label={label}
          ariaLabel={label}
          value={permission.isAllowed}
          onChange={value => {
            permission.isAllowed = value

            if (permission.id === '1' && !permission.isAllowed) {
              getPermission('3').isAllowed = false
              getPermission('5').isAllowed = false
            }

            if (permission.id === '7' && !permission.isAllowed) {
              getPermission('12').isAllowed = false
            }

            setPermissions(permissions.map(e => ({...e})))
          }}
          yesNo
        />
        {(permission.id === '1' && !permission.isAllowed) && <Warning color={Theme.colors.canNotViewBookings} inline>*</Warning>}
        {isDisabled && <Warning color={Theme.colors.selectedBookingDetails} inline>*</Warning>}
        {reviewDisabled && <Warning color={Theme.colors.canViewPropertyDetails} inline>*</Warning>}
      </div>
    )
  }

  if (!loadingCaretakers && !caretakers.length) {
    return (
      <PageWrapper
        title='You have no Caretakers'
      >
        <p>
          You currently have no caretakers to manage.
        </p>
      </PageWrapper>
    )
  }

  if (loadingCaretakers) {
    return <Spinner large />
  }

  return (
    <PageWrapper
      title='Caretaker access'
    >
      <p>
        If we hold the name {`&`} email address of your caretaker/keyholder
        they will be able to use the owner website to keep up to date with information about your accommodation and guests.
        You can choose what information they can access by selecting the options below.
      </p>

      <SelectWrapper>
        <StyledSelect
          name="caretakerSelect"
          label='Select caretaker'
          options={caretakers}
          isLoading={loadingCaretakers}
          onChange={({ id }) => setCaretaker(id)}
          defaultValue={caretakers ? caretakers[0] : null}
          getOptionLabel={option => option['name']}
          getOptionValue={option => option['id']}
        />
      </SelectWrapper>

      {(!permissions.length || isLoadingPermissions) ? <p data-testid="loadingPermissionsText">Loading...</p> : (
        <>
          {permissions.map(buildPermission)}

          {!getPermission('1').isAllowed && (
            <>
              <Warning color={Theme.colors.canNotViewBookings}>
                * The caretaker will not be able to see any upcoming booking information, or be able to create or cancel any bookings
              </Warning>

              <Warning color={Theme.colors.selectedBookingDetails}>
                {`* 'Can view booking details' must be selected`}
              </Warning>
            </>
          )}

          {!getPermission('7').isAllowed && (
            <>
              <Warning color={Theme.colors.canViewPropertyDetails}>
                {`* 'Can view property details' must be selected`}
              </Warning>
            </>
          )}

          <Button
            data-testid='CaretakerPageSaveButton'
            primary
            trackable={{
              category: 'CaretakersPage',
              action: 'Save Caretaker Permissions'
            }}
            loading={isSaving}
            onClick={savePermissions}
            style={{ marginTop: '1rem' }}
          >
            Save caretaker permissions
          </Button>

          <p style={{ margin: '1em 0' }}>
            You’ll need to ask your caretaker or key holder to activate their account by visiting owners.awaze.com,
            clicking on the “activate account” button and entering the email address that we have logged on our system for them.
            We’ll send them a verification code which will enable them to start using the site straight away.
          </p>
        </>
      )}

    </PageWrapper>
  )
}

export default CaretakerPage

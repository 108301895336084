import DesignTokens from '@/global/DesignTokens'
import styled from 'styled-components'
import { Text } from '@awaze/design-system.components.text'

export const StyledSpecialIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  svg {
    margin-left: 0.5rem;
  }
`

export const Section = styled.section`
  width: 100%;
  max-width: 80rem;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`
export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
export const ItemsContainer = styled.div<{ showBottomBorder?: boolean }>`
  --item-column-size: 18.5rem;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, var(--item-column-size)), 1fr));
  gap: 1rem;
  border-bottom: ${props => (props.showBottomBorder ? `1px solid ${DesignTokens.ColorGrey100}` : 'none')};
  margin-bottom: ${props => (props.showBottomBorder ? `1.5rem` : 'none')};
  padding-bottom: ${props => (props.showBottomBorder ? `1.5rem` : 'none')};
`

export const SectionIcon = styled.div`
  width: 1.5rem;
  height: auto;
  margin-right: 0.5rem;
`
export const SectionTitle = styled.h2`
  ${({ theme }) => theme.fonts.Heading100}
  width: 100%;
  margin-bottom: 1rem;
`

export const Item = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const ItemTitle = styled.h3`
  ${({ theme }) => theme.fonts.Title300};
  margin-bottom: 0.4rem;
`

export const ItemInfo = styled.div`
  ${({ theme }) => theme.fonts.InputTitle300};
`

export const ColumnsContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  ${({ theme }) => theme.fonts.Text300};

`

export const ColumnsContentCostUnit = styled.div`
  ${({ theme }) => theme.fonts.Text100};
  color: ${({ theme }) => theme.ColorGrey600};
  align-self: flex-end;
`

export const PriceBreakdownContainer = styled.div`
  display: flex;
  gap: 0.375rem;
  flex-direction: column;
  margin-right: 2rem;
`
export const ProvisionalBookingHeadline = styled.p`
  ${({ theme }) => theme.fonts.Title300};
  margin-bottom: 6px;
`

export const ProvisionalBookingText = styled.p`
  ${({ theme }) => theme.fonts.Text300};
`
export const ProvisionalBookingBanner = styled.div`
  width: 100%;
  background-color: ${DesignTokens.ColorStatusInfo25};
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  display: flex;
  margin-bottom: 1.5rem;
  gap: 10px;
`

export const ExtrasItem = styled.div`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: .75rem;
  }
`
export const NoWrapParagraph = styled.p`
  white-space: nowrap;
`
export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
export const ExtrasName = styled(Text)`
padding-right: 1rem;
`

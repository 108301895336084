import PropTypes from 'prop-types'
import { FormError } from './Form.styles'
import { Formik, Form as FormikForm } from 'formik'
import React, { createContext, useContext } from 'react'

const FormContext = createContext(null)

const useForm = () => {
  const context = useContext(FormContext)
  if (context === undefined) {
    throw new Error('`useForm` hook must be used within a Form component')
  }

  return context
}

const Form = ({
  children,
  disabled,
  id,
  initialValues,
  handleSubmit,
  submitError,
  validationSchema,
  className,
  ...props
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={handleSubmit}
    validationSchema={validationSchema}
    {...props}
  >
    {({dirty, isValid, setFieldValue}) => (
      <FormContext.Provider value={{ disabled, setFieldValue, dirty, isValid }}>
        <FormikForm
          id={id}
          className={className}
        >
          {submitError ? <FormError id={`${id}-error`}>{submitError}</FormError> : null}
          { children }
        </FormikForm>
      </FormContext.Provider>
    )}
  </Formik>
)

Form.displayName = 'Form'

Form.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  initialValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitError: PropTypes.string,
  validationSchema: PropTypes.object
}

export { useForm }
export default Form

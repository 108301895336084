import React from 'react'
import PropTypes from 'prop-types'
import { Label, LabelText, StyledToggle, StyledToggler, YesLabel, NoLabel } from './Toggle.styles'

const Toggle = ({ id, name, disabled, label, ariaLabel, value, onChange, className, yesNo }) => {
  const handleToggle = () => {
    if (typeof onChange === 'function') {
      onChange(!value)
    }
  }

  const onKeyDown = keyEvent => {
    if (keyEvent.key !== ' ' && keyEvent.keyCode !== 32) return
    handleToggle()
  }

  return (
    <Label htmlFor={id} tabIndex={0} onKeyDown={onKeyDown} className={className}>
      <input
        type='checkbox'
        id={id}
        name={name}
        disabled={disabled}
        aria-label={ariaLabel}
        checked={value}
        onChange={handleToggle}
      />
      <StyledToggle checked={value} yesNo={yesNo} disabled={disabled}>
        {yesNo && <YesLabel checked={value}>YES</YesLabel>}
        {yesNo && <NoLabel checked={value}>NO</NoLabel>}
        <StyledToggler checked={value} yesNo={yesNo}/>
      </StyledToggle>
      {!!label && <LabelText>{label}</LabelText>}
    </Label>
  )
}

Toggle.displayName = 'Toggle'

Toggle.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  ariaLabel: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  yesNo: PropTypes.bool
}

export default Toggle

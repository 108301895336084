import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CrossButton } from '@/global/GlobalStyle'
import { Wrapper, ButtonContainer } from './Modal.styles'

const Modal = ({ children, onClose, open, strict, ...props }) => {
  const [ isOpen, setOpen ] = useState(open)

  const toggleModal = () => {
    onClose() && setOpen(!isOpen)
  }

  useEffect(() => setOpen(open), [open])

  return (
    <Wrapper
      isOpen={isOpen}
      onBackgroundClick={!toggleModal}
      onEscapeKeydown={toggleModal}
      {...props}
    >
      <ButtonContainer>
        {!strict && <CrossButton aria-label='close' onClick={toggleModal}/>}
      </ButtonContainer>
      {children}
    </Wrapper>
  )
}
Modal.displayName = 'Modal'

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  strict: PropTypes.bool,
  children: PropTypes.node
}

export default Modal

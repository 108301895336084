import styled from 'styled-components'

const Content = styled.div`
  border-radius: ${({theme}) => theme.borderRadius};
  background-color: ${({theme}) => theme.colors.white};
  padding: 1.5rem;
`

const ContentContainer = styled.div`
  position: relative;

  ${({theme}) => theme.mediaQueries.desktopUp} {
    padding-right: 320px;
  }
`

const InfoSection = styled.div`
  margin-top: 2rem;
  background-color: ${({theme}) => theme.colors.greyFive};
  border-radius: 6px;
  padding: 1rem;

  p {
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }

  ${({theme}) => theme.mediaQueries.desktopUp} {
    position: absolute;
    top: 0;
    right: 0;
    width: 280px;
    margin-top: 0;
  }
`

export { ContentContainer, Content, InfoSection }

import React, {useEffect, useState} from 'react'
import FAQsList from '@/components/FAQs/FAQsList'
import { PageWrapper } from '@/components/__UI'
import { StyledDropdown } from './GeneralFaqPage.style'
import allFaqs from '@/constants/faqs/faqs'

const GeneralFaqPage = () => {
  const [faqs, setFaqs] = useState([])
  const [category, setCategory] = useState('')
  const [categories, setCategories] = useState([])

  useEffect(() => {
    setFaqs(allFaqs)
  }, [])

  useEffect(() => {
    setCategories(getCategories(faqs))
  }, [faqs])

  const getCategories = (faqs) => {
    if (Array.isArray(faqs)) {
      let list = []
      faqs.forEach(({tags}) => {
        tags.forEach((tag) => {
          if (!list.includes(tag)) list.push(tag)
        })
      })
      list = list.map((l) => ({label: l, value: l}))
      list.unshift({ label: 'All Categories', value: 'all' })
      return list
    }
    return []
  }

  return (
    <PageWrapper
      title='General FAQs'
    >
      <StyledDropdown
        label='Categories:'
        value={category || categories[0]}
        options={categories}
        onChange={setCategory}
      />
      <FAQsList
        showSearch
        faqData={faqs || []}
        filter={category.value}
      />
    </PageWrapper>
  )
}

export default GeneralFaqPage

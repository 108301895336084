import styled from 'styled-components'
import { Section, SectionLabel, SectionData } from '@/components/__Modals/NewBookingModal/NewBookingModal.styles'
import {Container} from '../../__Inputs/FormikField/FormikField.styles'

export const ExtrasContainer = styled.div`
  margin-top: -2rem;
`
export const SectionTitle = styled(SectionLabel)`
  font-weight: 600;
  font-size: 0.9rem;
`
export const SectionContentLabel = styled(Section)`
  font-weight: normal;
  margin-left: 2rem;
`
export const CompulsorySection = styled(Section)`
  margin-top: 2rem; 
`
export const SectionDataCompulsory = styled(SectionData)`
  font-weight: 500;
  font-size: 0.9rem;
`
export const SectionContentData = styled(SectionData)`
  font-weight: normal;
  font-size: 0.8rem;
`
export const SectionContentDataCompulsory = styled(SectionData)`
  font-weight: normal;
  font-size: 0.85rem;
`
export const CollapseContainer = styled.div`
  /* margin-bottom: 5rem;
  margin-top: -1rem; */
  padding-bottom: 8rem;
      /* overflow-y: none!important; */
  /* height: 15rem !important; */
`
export const FormikFieldContainer = styled(Container)`
  width: 8rem;
`

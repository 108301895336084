import PropTypes from 'prop-types'
import toast from '@/helpers/toast'
import React, { useState } from 'react'
import useGraphQl, { GET_USER_STATEMENT_ACCOUNTS } from '@/hooks/useGraphQl'
import { SelectDropdown } from '@/components/__Inputs'

const AccountDropdown = ({
  className,
  onChange,
  onError,
  onSuccess,
  operationID,
  defaultValue,
  label,
  ...props
}) => {
  const [ account, setAccount ] = useState(null)
  const [ accounts, setAccounts ] = useState([])

  const checkAccountId = (inputId, data) => {
    const newId = typeof inputId === 'string' ? inputId : inputId && inputId.id
    const match = newId ? data.find(({ id }) => id === newId) : null
    return match
  }

  const gqlProps = {
    query: GET_USER_STATEMENT_ACCOUNTS,
    asObject: true,
    onSuccess: (data) => {
      if (data.length) {
        const newAccount = data[0]
        const newAccountList = data

        const defValue = checkAccountId(defaultValue, data) || newAccount

        setAccount(defValue)
        onChange && onChange(defValue)
        setAccounts(newAccountList)
      }

      onSuccess && onSuccess(data)
    },
    onError: (err) => {
      toast.error('Failed to load Accounts', null, err)
      onError && onError(err)
    }
  }

  if (operationID) {
    gqlProps.variables = { operationId: operationID }
  }

  const { loading: isLoadingAccounts } = useGraphQl(gqlProps)

  const handleChange = (p) => {
    onChange && onChange(p)
    setAccount(p)
  }

  return (
    <SelectDropdown
      {...props}
      label={label && 'Select account'}
      value={account}
      className={className}
      options={accounts}
      onChange={handleChange}
      isLoading={isLoadingAccounts}
      loadingText="Loading accounts..."
      getOptionLabel={option => `${option['name']}-${option['code']}`}
      getOptionValue={option => option['id']}
    />
  )
}

AccountDropdown.propTypes = {
  className: PropTypes.string,
  statementAccount: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  operationID: PropTypes.string,
  label: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default AccountDropdown

import { CostType, Extra } from '@/components/__BookingComponents/BookingDetails/sections/PriceSection'

interface ExtrasAccumulator {
  locallyPayableExtras: Extra[];
  notLocallyPayableExtras: Extra[];
}

export const filterBookingExtras = (extras: Extra[]) => {
  return extras.reduce(
    (acc: ExtrasAccumulator, extra: Extra) => {
      extra.costType === CostType.LocallyPayableExtra ? acc.locallyPayableExtras.push(extra) : acc.notLocallyPayableExtras.push(extra)
      return acc
    },
    { locallyPayableExtras: [], notLocallyPayableExtras: [] } as ExtrasAccumulator
  )
}

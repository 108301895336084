import styled from 'styled-components'

const EditableDivStyledTextBox = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 4rem;
  height: auto; 
  padding: .625rem .75rem;
  border-radius: ${({theme}) => theme.borderRadius};
  font-size: .938em;
  border: 1px solid ${({theme}) => theme.colors.greyThree};
  cursor: text;


  &:focus {
    outline: none;
    box-shadow: 0 0 2px 0 ${({theme}) => theme.colors.editableDivBoxShadow};
  }

  &:disabled {
    border-color: ${({theme}) => theme.colors.editableDivDisabled};
  }
`

export { EditableDivStyledTextBox }

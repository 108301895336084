import { useTranslation } from 'react-i18next';
import { Text } from '@awaze/design-system.components.text';
import { Link } from '@awaze/design-system.components.link';
import { PageWrapper } from '@/components/__UI';
import { PropertyDropdownDS } from '@/components/__Inputs/PropertyDropdown';
import { PricingListTable } from '@/components/__PricingComponents';
import {
  PricingDataContext,
  PricingDataProvider,
} from '@/components/__PricingComponents/context/PricingDataProvider';
import { PropertyDropdownWrapper } from './PricingListPage2.styles';

export function PricingListPage2() {
  const { t } = useTranslation();

  return (
    <PricingDataProvider>
      <PageWrapper title="Pricing">
        <Text>{t('pricingPageIntroText')}</Text>
        <Link
          href="/articles/guides/user-guides/article-pricing-top-tips"
          colourScheme="brand-link"
        >
          {t('pricingPageIntroLink')}
        </Link>
        .
        <PricingDataContext.Consumer>
          {({ propertyID, setPropertyID }) => (
            <PropertyDropdownWrapper>
              <PropertyDropdownDS
                label
                name="property"
                operationID="viewFinancialReports"
                value={propertyID}
                onChange={(event) => setPropertyID(event.target.value)}
                autoSelectFirst
                autoWidth
              />
            </PropertyDropdownWrapper>
          )}
        </PricingDataContext.Consumer>
        <PricingListTable />
      </PageWrapper>
    </PricingDataProvider>
  );
}

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { apiFetch } from '@/hooks/useQuery/helpers'

type Unit = {
  unitCode: string;
  unitId: string;
  startDateTime: Date;
  endDateTime: Date;
  maximumPets: number;
}

type Property = {
  propertyId: string;
  propertyCode: string;
  propertyName: string;
  ownerType: string;
  thumbnail: string;
  managedProperty: boolean;
  propertyUnits: Unit[];
}

type Site = {
  siteName: string;
  siteCode: string;
  siteId: string;
  siteProperties: Property[];
}

type UserPropertyCollection = {
  siteUser: boolean;
  sites: Site[];
}

export const queryKeyUserProperties = (operation?: string) => ['user-properties', operation]

export function useQueryUserProperties (operation?: string, enabled = true, options?: UseQueryOptions<UserPropertyCollection>
) {
  let url = 'v1/users/properties'
  const params = new URLSearchParams()

  if (operation) {
    params.append('operation', operation)
  }

  url += `?${params}`

  const removeDuplicates = (data) => {
    if (data && data.sites) {
      data.sites.forEach(site => {
        if (site.siteProperties) {
          site.siteProperties = site.siteProperties.filter((property, index, self) =>
            index === self.findIndex(t => t.propertyId === property.propertyId)
          )
        }
      })
    }
    return data
  }

  const queryFn = () => apiFetch(url).then(removeDuplicates)

  return useQuery<UserPropertyCollection>(queryKeyUserProperties(operation), queryFn, {
    staleTime: Infinity,
    enabled: enabled,
    ...options
  })
}

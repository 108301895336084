import React, {useState} from 'react'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import { useForm } from '@/components/__Inputs/Form'
import { StyledTextarea, CharCount } from './Textarea.styles'
import { StyledInputControl, StyledLabel, StyledErrorMessage } from '../input.styles'
import { ErrorCircleSolid } from '@awaze/design-system.components.icon'

const Textarea = ({ disabled, additionalChange, label, showErrorIcon, errorStyles, textStyles, ...props }) => {
  const maxLength = props.maxLength || 999

  const [field, meta] = useField(props)
  const form = useForm()
  const formDisabled = form && form.disabled ? form.disabled : false
  const [charCount, setCharCount] = useState(maxLength)

  const handleChange = (e) => {
    const count = e.target.value.length
    setCharCount(maxLength - count)
    if (count - maxLength) {
      field.onChange(e)
    }

    if (props.callbackTextResponseChangeEvent || null) {
      props.callbackTextResponseChangeEvent()
    }

    additionalChange && additionalChange()
  }

  return (
    <StyledInputControl>
      { label && <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel> }
      <StyledTextarea
        {...field}
        {...props}
        disabled={disabled || formDisabled}
        id={props.id || props.name}
        hasError={meta.touched && meta.error}
        onChange={e => handleChange(e)}
        style={textStyles}
      />
      {(meta.touched && meta.error) && <StyledErrorMessage style={errorStyles}>{showErrorIcon && <ErrorCircleSolid size="xs" iconColour={'ColorStatusAttention500'}/>} {meta.error}</StyledErrorMessage>}

      {props.counter && <CharCount type="mid" sizes={100}>{`Remaining Characters: ${charCount}`}</CharCount>}
    </StyledInputControl>
  )
}

Textarea.displayName = 'Textarea'

Textarea.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  counter: PropTypes.bool,
  maxLength: PropTypes.number,
  additionalChange: PropTypes.func,
  showErrorIcon: PropTypes.bool,
  errorStyles: PropTypes.object
}

export default Textarea

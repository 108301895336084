import styled from 'styled-components'
import { SelectDropdown } from '@/components/__Inputs'

const StyledSelect = styled(SelectDropdown)``

const SelectWrapper = styled.div`
  margin: 1em 0;
`

const Warning = styled.p`
  ${({ inline, color }) => `
    display: ${inline ? 'inline-block' : 'block'};
    font-size: .8rem; 
    color: ${color};
    font-weight: 700;
    margin-left: 3px;
  `}
`

export { SelectWrapper, StyledSelect, Warning }

import styled from 'styled-components'
import { Link } from 'react-router-dom'

const StyledButton = styled(Link)`
  display: inline-block;
  margin-bottom: 1.5rem;
  color: ${({theme}) => theme.colors.black};
  font-weight: 500;
  
  &:before {
    content: '';
    position: relative;
    display: inline-block;
    top: 0.3em;
    width: 0.6em;
    height: 0.6em;
    transform: rotate(-135deg);
    border-style: solid;
    border-width: 0.15em 0.15em 0 0;
    vertical-align: top;
    margin-right: 0.75rem;
    transition: border-color ${({theme}) => theme.animationSpeed} ease;
  }

  &:hover {
    text-decoration: none;
  }
`

export { StyledButton }

import styled from 'styled-components';
import DesignTokens from '@/global/DesignTokens';

export const MainTableUnitContainer = styled.div`
  overflow: auto;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;

  /** HACK limit chart width to avoid scrollbars */
  max-width: calc(100vw - 17px);

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* MOBILE */
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    height: 70vh;
    flex-direction: row;

    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  /* iPAD */
  @media (min-width: 600px) and (max-width: 1199px) {
    height: 70vh;
    flex-direction: row;

    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export const UnitsAndSitesContainer = styled.div`
  width: 17vw;
  position: sticky;
  left: 0;
  z-index: 2;

  .colorLegendPageContainer {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  /* MOBILE */
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    width: 35%;
  }

  /* iPAD */
  @media (min-width: 600px) and (max-width: 1199px) {
    width: 30%;
  }
`;

export const ColorLegendPageContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const ScrollTableContainer = styled.div`
  width: 100%;

  /* MOBILE */
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    width: 65%;
  }

  /* iPAD */
  @media (min-width: 600px) and (max-width: 1199px) {
    width: 70%;
  }
`;
export const NoResultMessage = styled.div`
  text-align: center;
  padding: 60px;
  background-color:  ${({ theme }) => theme.colors.white};
  border: 1px solid ${DesignTokens.ColorGrey400};
  border-radius: ${({theme}) => theme.borderRadius};
  ${({ theme }) => theme.fonts.Text300};

  @media (width < 1200px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`
import React from 'react'
import PropTypes from 'prop-types'
import { Container, SearchIcon, Text } from './NoItemsFound.styles'
import IconSearch from '@/assets/icons/icon-search.svg'

const NoItemsFound = ({text}) => {
  return (
    <Container>
      <SearchIcon src={IconSearch} />
      <Text>{text || 'No items found'}</Text>
    </Container>
  )
}

NoItemsFound.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default NoItemsFound

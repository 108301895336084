import styled from 'styled-components'
import { Button } from '@/components/__Inputs'
import { Modal } from '@/components/__UI'

const StyledModal = styled(Modal)`
  padding: 1rem 1rem 1.5rem;

  @supports (-webkit-touch-callout: none) {
    ${({theme}) => theme.mediaQueries.phoneOnly} {
      padding-bottom: 5rem;
    }
  }

  ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
    max-width: 400px;
  }

  button[aria-label='close'] {
    right: 1rem;
    background-color: ${({theme}) => theme.colors.propertyImageEditModalBtn};
    padding: 0.75rem;

    &:before, &:after {
      background-color: ${({theme}) => theme.colors.black};
    }
  }
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 275px;
  margin-bottom: 0.5rem;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  & > * {
    flex: 1;
    flex-basis: 50%;
  }

  ${({theme}) => theme.mediaQueries.phoneDS} {
    display: block;
  }
`

const RemoveButton = styled(Button)`
  margin-left: 0.25rem;
  margin-bottom: 0.5rem;

  ${({theme}) => theme.mediaQueries.phoneDS} {
    margin-left: 0;
  }
`

const NewImageWrapper = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;

  ${({newImage}) => !newImage && `margin-right: 0.25rem;`}

  ${({theme}) => theme.mediaQueries.phoneDS} {
    margin-right: 0;
  }
`

const NewImageButton = styled(Button)`
  pointer-events: none;
  max-width: none;
`

const SubmitButton = styled(Button)`
  display: block;
  margin-top: 0.5rem;
  margin-left: 0.25rem;

  ${({theme}) => theme.mediaQueries.phoneDS} {
    margin-left: 0;
  }
`

const CancelButton = styled(Button)`
  margin-top: 0.5rem;
  margin-right: 0.25rem;

  ${({theme}) => theme.mediaQueries.phoneDS} {
    margin-right: 0;
  }
`

const Error = styled.p`
  margin-top: 10px;
  color: ${({theme}) => theme.colors.error};
`

export {
  StyledModal, Image, ButtonContainer, RemoveButton,
  NewImageWrapper, NewImageButton, SubmitButton, Error,
  CancelButton
}

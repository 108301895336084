import React, { useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import toast from '@/helpers/toast'
import { useFormikContext } from 'formik'
import { Modal, Spinner } from '@/components/__UI'
import { Form, TextInput, Button, FormikField } from '@/components/__Inputs'
import PermissionsCheckbox from '@/components/__Inputs/Checkbox_Permissions/PermissionsCheckbox'

import {
  Title,
  ButtonSection,
  CenterWrapper,
  StyledSelectDropdown,
  PermissionsSection,
  PermissionItem
} from './UserAdminModal.styles'
import useGraphQl, {
  graphQlFetch,
  GET_USER_ADMIN_SITES,
  GET_USER_ADMIN_PERMISSIONS,
  ADD_USER_ADMIN
} from '@/hooks/useGraphQl'
import { SCHEMA_FIELD_USERNAME } from '@/constants/fields'
import { selectStyles } from '../../../components/__Inputs/SelectDropdown/SelectDropdown.styles'

const UserAdminModal = ({ userId, onClose, setDeleteUserId, reloadUsers }) => {
  const defaultValues = {
    firstName: '',
    lastName: '',
    jobTitle: '',
    email: '',
    role: '',
    sites: [],
    permissions: []
  }

  const [formError, setFormError] = useState()
  const [currentSites, setCurrentSites] = useState()
  const [currentSiteOptions, setCurrentSiteOptions] = useState([])
  const [isLoading, setIsLoading] = useState()
  const [isAddUserSubmitting, setIsAddUserSubmitting] = useState()
  const [temporaryPermissionOptions, setTemporaryPermissionOptions] = useState([])

  const VALIDATION_SCHEMA = Yup.object({
    firstName: Yup.string().required('Please enter the first name'),
    lastName: Yup.string().required('Please enter the last name'),
    jobTitle: Yup.string().required('Please enter the job title'),
    email: SCHEMA_FIELD_USERNAME,
    sites: Yup.string().required('Please select at least one site')
  })

  const { loading: isLoadingSiteOptions } = useGraphQl({
    asObject: true,
    query: GET_USER_ADMIN_SITES,
    onSuccess: (data) => {
      if (data && Array.isArray(data)) {
        const options = data.map((r) => {
          return { label: r.nameWithCode, value: r.id }
        })
        /* istanbul ignore else */
        if (options) setCurrentSiteOptions((o) => [...o, ...options])
      }
    }
  })

  const [allPermissions] = useGraphQl({ query: GET_USER_ADMIN_PERMISSIONS })
  const allPermissionOptions = allPermissions && Array.isArray(allPermissions) ? allPermissions.map(p => ({ label: p.description, value: p.id })) : []

  /* istanbul ignore next */
  const handleSubmit = (submitValues) => {
    setIsAddUserSubmitting(true)

    const values = {
      ...submitValues,
      role: submitValues.role.value || submitValues.role,
      /* istanbul ignore next */
      sites: Array.isArray(submitValues.sites)
        ? submitValues.sites.map((s) => s.value || s)
        : [submitValues.sites.value],
      permissions: Array.from(temporaryPermissionOptions, a => a.id)

    }
    const errorMessage = `Failed to add user, please try again later.`

    graphQlFetch({ query: ADD_USER_ADMIN, variables: { values } })
      .then(({ success }) => {
        if (success) {
          toast.success(`User successfully added.`)
          reloadUsers()
          onClose && onClose()
        } else {
          toast.error(errorMessage)
          setFormError(errorMessage)
        }
      })
      .catch(() => {
        setFormError(errorMessage)
        toast.error(errorMessage)
      })
      .finally(() => {
        setIsLoading(false)
        setIsAddUserSubmitting(false)
      })
  }

  const registerTempSelection = (value, status) => {
    if (status) {
      const permission = allPermissionOptions.find(o => o.value === value)
      permission && setTemporaryPermissionOptions([{id: value}, ...temporaryPermissionOptions])
    } else {
      setTemporaryPermissionOptions(temporaryPermissionOptions.filter(o => o.id !== value))
    }
  }

  return (
    <Modal open onClose={onClose}>
      <Title data-testid='addUserTitle'>Add user</Title>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Form
            id="UserAdminForm"
            initialValues={defaultValues}
            validationSchema={VALIDATION_SCHEMA}
            handleSubmit={handleSubmit}
            submitError={formError}
            enableReinitialize
          >
            <CenterWrapper>
              <TextInput
                name="firstName"
                type="text"
                label="First name*"
                placeholder="First name"
              />
              <TextInput
                name="lastName"
                type="text"
                label="Last name*"
                placeholder="Last name"
              />
            </CenterWrapper>
            <CenterWrapper>
              <TextInput
                name="email"
                type="email"
                label="Email address*"
                placeholder="Email address"
              />
              <TextInput
                name="jobTitle"
                type="text"
                label="Job title*"
                placeholder="Job title"
              />
            </CenterWrapper>
            <FormikField
              component={StyledSelectDropdown}
              isMulti
              inputType="select"
              name="sites"
              label="Sites*"
              loading={isLoadingSiteOptions}
              options={currentSiteOptions}
              value={currentSites}
              isSearchable={true}
              onChange={setCurrentSites}
              styles={selectStyles}
              noSingleLock={true}
              noItemsText="No matching sites"
            />

            <Title>Permissions </Title>
            <PermissionsSection>
              {allPermissionOptions.map((p, index) => {
                return <PermissionItem key={index}>
                  <PermissionsCheckbox
                    name='permissions'
                    label={p.label}
                    disabled={false}
                    right={true}
                    value={p.value}
                    isPermissionsCheckBox={true}
                    isPermissionSet={temporaryPermissionOptions && temporaryPermissionOptions.find(element => element && element.id === p.value)}
                    registerTemporarySelection={registerTempSelection}
                  />
                </PermissionItem>
              })}
            </PermissionsSection>

            <ButtonSection>
              <SubmitButton
                primary
                fullWidth
                type="submit"
                loading={isAddUserSubmitting}
              >
                Save
              </SubmitButton>
            </ButtonSection>
          </Form>
        </>
      )}
    </Modal>
  )
}

const SubmitButton = ({ loading }) => {
  const { dirty, isValid } = useFormikContext()

  return (
    <Button
      primary
      fullWidth
      data-testid="saveUserButton"
      type="submit"
      disabled={!(isValid && dirty)}
      loading={loading}
    >
      Save
    </Button>
  )
}

UserAdminModal.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClose: PropTypes.func,
  reloadUsers: PropTypes.func,
  setDeleteUserId: PropTypes.func
}

SubmitButton.propTypes = {
  loading: PropTypes.bool
}

export default UserAdminModal

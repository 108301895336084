import styled from 'styled-components'
import { PropertyDropdown, SelectDropdown } from '@/components/__Inputs'
import Sticky from 'react-sticky-el'

const StyledPropertySelect = styled(PropertyDropdown)`
margin: 0.5rem 0 0.5rem 0;
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
  z-index: 3;
  }
`
const StyledSelect = styled(SelectDropdown)`
margin: 0.5rem 0 0.5rem 0;
  z-index: 2;

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
  z-index: 2;
  }
`

const InnerSticky = styled(Sticky)`
z-index: 1;
width: auto;
`

const SelectDropdownContainer = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(min(20rem, 100%), 1fr));
gap: 1rem;
height: auto;
background: ${({ theme }) => theme.colors.bgColor};
z-index: 3;
padding: 1.2rem 0;  

.property{
  z-index: 4;
}
.duration{
  z-index: 3;
}
.year{
  z-index: 2;
}
.header{
  display: flex;
  width: 83.6vw;
  padding: 0.8rem;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  justify-content: space-around;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  background-color: ${({ theme }) => theme.colors.primary};
}
`

const StickyTableHeaderMobile = styled(Sticky)`
      margin-top: 1rem;
      background: ${({theme}) => theme.colors.white};
      padding: 1rem;
      box-shadow: 0 6px 10px ${({ theme }) => theme.colors.greyFour};
      border-bottom-right-radius: 0.4rem;
      border-bottom-left-radius: 0.4rem;
      display: flex;
      flex-direction: row;
      width: 83.3vw;

      .tableItem{
        text-align: left;
        font-size: 0.8rem;
        font-weight: 600;
        flex: 1 1 0;
        width: 0;
        word-break: break-word;
        margin-left: 3vw;
      }

      div:nth-child(1) {
        margin-left: -0.5vw;

      }
      div:nth-child(2) {
        margin-right: -1vw; 

      }
      div:nth-child(3) {
        margin-right: 2vw; 
      }
`

export { SelectDropdownContainer, StyledSelect, StyledPropertySelect, StickyTableHeaderMobile, InnerSticky }

import { Heading } from '@awaze/design-system.components.text';
import styled from 'styled-components';

export const StyledHeading = styled(Heading)`
    margin-top: 2rem;
    margin-bottom: 1rem;
`

export const TableContainer = styled.div`
    border-radius: .25rem;
    border: 1px solid ${({ theme }) => theme.ColorGrey300};
    overflow: hidden;;
` 

export const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
`

export const StyledThead = styled.thead`
    background-color: ${({ theme }) => theme.ColorGrey25};
    ${({ theme }) => theme.fonts.Title200}
    text-align: left;
`

export const StyledTh = styled.th`
    padding: .5rem 1rem .5rem 0rem;
    white-space: nowrap;

    &:first-child {
        padding-left: 1rem;
    }
    &:last-child {
        padding-right: 1rem;
    }
`

export const StyledTbody = styled.tbody`
    background-color: ${({ theme }) => theme.ColorWhiteOpacity100};
`

export const StyledTr = styled.tr<{ $expanded?: boolean, $expandable?: boolean }>`
    
    border-top: 1px solid ${({ theme }) => theme.ColorGrey100};
    ${({ $expanded }) => $expanded && `
        font-weight: 700;
    `}
    ${({ $expandable }) => $expandable && `
        cursor: pointer;
    `}
`

export const StyledTrExpanded = styled.tr`
    cursor: pointer;
`

export const StyledTd = styled.td`
    padding: .5rem 1rem .5rem 0rem;
    vertical-align: middle;

    &:first-child {
        padding-left: 1rem;
    }
    &:last-child {
        padding-right: 1rem;
    }
`
import styled from 'styled-components'

const Container = styled.div`
    position: relative;

    .formik-error {
        bottom: -0.1rem;
        text-align: left;
        position: relative;
    }
`

export { Container }

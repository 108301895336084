import styled from 'styled-components'
import { PropertyDropdownDS } from '@/components/__Inputs/PropertyDropdown'
import SelectDropdown from '@/components/__Inputs/SelectDropdown'
import { PropertyUnitDropdownDS } from '@/components/__Inputs/PropertyDropdown/PropertyUnitDropdownDS'


export const PropertyDropdown = styled(PropertyDropdownDS)`
  margin: 0;
  height: 3rem;
`

export const PropertyUnitDropdown = styled(PropertyUnitDropdownDS)`
  margin: 0;
  height: 3rem;
`

export const YearDropdown = styled(SelectDropdown)``

export const DropdownWrapper = styled.div`
  ${({theme}) => theme.mediaQueries.laptopUpDS} {
    display: flex;
    flex: 1;
    margin: 1rem 0;
    align-items: end;

    & > div {
      flex: 1;
    }
  }

  ${({theme}) => theme.mediaQueries.tabletDS} {
    display: flex;
    flex: 1;
    margin: 1rem 0;
    align-items: end;

    & > div {
      flex: 1;
    }
  }

  ${({theme}) => theme.mediaQueries.tabletDownDS} {
    max-width: 100%;
    width: 100%;
    margin-right: 1rem;
  }
`

export const PropertyDropdownWrapper = styled.div`
  width: 100%;
  margin-right: 1rem;

  ${({ theme }) => theme.mediaQueries.phoneDS} {
  max-width: 100%;
  margin: 1rem 0 1.25rem;
  }
`
export const PropertyUnitDropdownWrapper = styled.div`
  min-width: 10rem;
  ${({ theme }) => theme.mediaQueries.phoneDS} {
  max-width: 100%
  }
`

export const BookingLegend = styled.div`
    display: flex;
    gap: 1rem;

  ${({theme}) => theme.mediaQueries.laptopUpDS} {
    align-items: flex-end;
  }

  ${({theme}) => theme.mediaQueries.tabletDS} {
    align-items: flex-start;
  }

  ${({theme}) => theme.mediaQueries.phoneDS} {
   flex-direction: column;
  }
`

export const LegendWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: .5rem;

  ${({theme}) => theme.mediaQueries.laptopUpDS} {
    max-width: 648px;
  }

  ${({theme}) => theme.mediaQueries.tabletDS} {
    max-width: 285px;
  }
  
  ${({ theme }) => theme.mediaQueries.phoneDS} {
    width: 100%;
  }
`
export const SwitcherContainer = styled.div`
  margin: 0.5rem 0 0 2.5rem;
  
`

import styled from 'styled-components'
import DesignTokens from '@/global/DesignTokens'

export const BookingListFilterMenuItemLaptopAndUp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  padding: 0.25rem;
  gap: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.inputDisabled};
  height: 3rem;

button {
  position: relative;
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.txtBodyOnWhite};
  ${({ theme }) => theme.fonts.Text300};
  cursor: pointer;
  overflow: hidden;
  border: none;
  padding: 0.5rem 1rem;
  background-color: transparent;
  line-height: 1.5rem;

  &:hover {
    background-color: ${DesignTokens.ColorButtonSolidTertiaryBackground};
    
  }
}

button.active{
  background-color: ${DesignTokens.ColorButtonSolidPrimaryBackground};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;

  &:hover {
    background-color: ${DesignTokens.ColorButtonSolidPrimaryBackgroundHover};
    
  }
}
`

export const BigScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
`

export const BookingListFilterMenuItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  height: 3rem;
  margin-top: 1.6rem;

`
/* stylelint-disable-line block-no-empty */
export const FilterContainer = styled.div`
  width: 100%;
  
`

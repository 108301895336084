import styled, { css } from 'styled-components'
import { isWebpSupported } from 'react-image-webp/dist/utils'

const bgImageMixin = css`
  background-image: url(${({ backgroundImage: { url } }) => isWebpSupported() ? url.webp : url.fallback});
  background-position: ${({ backgroundImage }) => backgroundImage.position};
  background-size: ${({ backgroundImage }) => backgroundImage.size || 'cover'};
`

const Component = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  height: 100%;

  ${({ backgroundImage }) => backgroundImage && bgImageMixin};
`

export { Component }

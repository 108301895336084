import { useField } from 'formik'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { PasswordInputWrapper } from './PasswordInput.styles'
import { StyledInput, StyledInputControl, StyledErrorMessage } from '../input.styles'

const PasswordInput = props => {
  const [show, setShow] = useState(false)
  const [field, meta] = useField(props)

  return (
    <PasswordInputWrapper>
      <StyledInputControl>
        <StyledInput
          id={props.id || props.name}
          {...props}
          {...field}
          onChange={e => {
            props.onChange && props.onChange(e.target.value)
            field.onChange(e)
          }}
          hasError={meta.touched && meta.error}
          type={show ? 'text' : 'password'}
        />
        {meta.touched && meta.error ? (
          <StyledErrorMessage>{meta.error}</StyledErrorMessage>
        ) : null}
      </StyledInputControl>
      <span className="input-type-toggle" onClick={() => setShow(!show)}>{show ? 'HIDE' : 'SHOW'}</span>
    </PasswordInputWrapper>
  )
}

PasswordInput.displayName = 'PasswordInput'

PasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  id: PropTypes.string,
  placeholder: PropTypes.string
}

export default PasswordInput

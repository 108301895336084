
import React from 'react'
import PropTypes from 'prop-types'
import Theme from '@/global/Theme'

const TrashcanSvg = ({ fill = Theme.ColorBrandSecondary }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.25 6C4.25 5.58579 4.58579 5.25 5 5.25H18.5C18.9142 5.25 19.25 5.58579 19.25 6V19.5C19.25 20.0967 19.0129 20.669 18.591 21.091C18.169 21.5129 17.5967 21.75 17 21.75H6.5C5.90326 21.75 5.33097 21.5129 4.90901 21.091C4.48705 20.669 4.25 20.0967 4.25 19.5V6ZM5.75 6.75V19.5C5.75 19.6989 5.82902 19.8897 5.96967 20.0303C6.11032 20.171 6.30109 20.25 6.5 20.25H17C17.1989 20.25 17.3897 20.171 17.5303 20.0303C17.671 19.8897 17.75 19.6989 17.75 19.5V6.75H5.75Z" fill={fill}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.5 9.75C9.91421 9.75 10.25 10.0858 10.25 10.5V16.5C10.25 16.9142 9.91421 17.25 9.5 17.25C9.08579 17.25 8.75 16.9142 8.75 16.5V10.5C8.75 10.0858 9.08579 9.75 9.5 9.75Z" fill={fill}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14 9.75C14.4142 9.75 14.75 10.0858 14.75 10.5V16.5C14.75 16.9142 14.4142 17.25 14 17.25C13.5858 17.25 13.25 16.9142 13.25 16.5V10.5C13.25 10.0858 13.5858 9.75 14 9.75Z" fill={fill}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.25 6C1.25 5.58579 1.58579 5.25 2 5.25H21.5C21.9142 5.25 22.25 5.58579 22.25 6C22.25 6.41421 21.9142 6.75 21.5 6.75H2C1.58579 6.75 1.25 6.41421 1.25 6Z" fill={fill}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.90901 2.90901C8.33097 2.48705 8.90326 2.25 9.5 2.25H14C14.5967 2.25 15.169 2.48705 15.591 2.90901C16.0129 3.33097 16.25 3.90326 16.25 4.5V6C16.25 6.41421 15.9142 6.75 15.5 6.75H8C7.58579 6.75 7.25 6.41421 7.25 6V4.5C7.25 3.90326 7.48705 3.33097 7.90901 2.90901ZM9.5 3.75C9.30109 3.75 9.11032 3.82902 8.96967 3.96967C8.82902 4.11032 8.75 4.30109 8.75 4.5V5.25H14.75V4.5C14.75 4.30109 14.671 4.11032 14.5303 3.96967C14.3897 3.82902 14.1989 3.75 14 3.75H9.5Z" fill={fill}/>
  </svg>

)

TrashcanSvg.propTypes = {
  direction: PropTypes.number,
  fill: PropTypes.string
}

export default TrashcanSvg

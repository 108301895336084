import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { OvalLoadingSpinner } from '@/components/__UI'
import useWindowSize from '@/hooks/useWindowSize'
import { useBookingListContext } from '@/context/BookingListProvider'
import { BookingListDataCard, BookingListTable } from '@/components/__BookingComponents'
import Button from '@/components/__Inputs/Button'
import { ErrorContainer, NoResultMessage, MonthYearHeadline } from './BookingListTableContainer.styles'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

const BookingListTableContainer = ({ setBookingToCancel, bookingToCancel, confirmedBookingsToBeCancelled }) => {
  const { width } = useWindowSize()
  const isMobileAndTablet = width < 1024
  const { t } = useTranslation()

  const {
    filteredBookings,
    sortedBy,
    isLoadingBookings,
    isErrorBookings,
    reloadBookings,
    searchBy,
    allSitesWithProperties,
    hasOnlyOneAccommodation,
    hideUnitDisplay
  } = useBookingListContext()

  const getSearchByTextForNoResultsMessage = (searchBy) => {
    switch (searchBy) {
      case 'created':
      case 'departure':
      case 'arrival':
        return t('noActiveBookingsMessage1')
      case 'active':
        return t('noActiveBookingsMessage2')
      default:
        return t('noActiveBookingsMessage1')
    }
  }

  const buildNoResultsMessageForSearchBy = () => {
    return (
      <ErrorContainer style={{border: '0'}}>
        {getSearchByTextForNoResultsMessage(searchBy)}
      </ErrorContainer>
    )
  }

  const sortBookings = (bookings, sortedBy) => {
    const getSortKey = (booking) => {
      if (!sortedBy || sortedBy.name === 'arrival' || sortedBy.name === 'active') {
        return dayjs(booking.startDate).unix()
      } else if (sortedBy.name === 'departureDate') {
        return dayjs(booking.endDate).unix()
      } else if (sortedBy.name === 'bookedOnDate') {
        return dayjs(booking.bookedOnDate).unix()
      }
    }

    return [...bookings].sort((a, b) => getSortKey(a) - getSortKey(b))
  }

  const groupByMonthYear = (sortedBookings) => {
    const grouped = {}

    sortedBookings.forEach(booking => {
      const monthYear = dayjs(booking.startDate).format('MMMM YYYY')
      if (!grouped[monthYear]) {
        grouped[monthYear] = []
      }
      grouped[monthYear].push(booking)
    })

    return grouped
  }

  const groupedBookings = useMemo(() => {
    if (filteredBookings) {
      const sortedBookings = sortBookings(filteredBookings, sortedBy)
      return groupByMonthYear(sortedBookings)
    }
  }, [filteredBookings, sortedBy])

  return (
    <>

      {!isLoadingBookings && !isErrorBookings && filteredBookings?.length > 0 && allSitesWithProperties &&
      (isMobileAndTablet
        ? groupedBookings && (
          <div>
            {Object.keys(groupedBookings).map((monthYear) => (
              <div key={monthYear}>
                <MonthYearHeadline>{monthYear}</MonthYearHeadline>
                {groupedBookings[monthYear].map((item, index) => (
                  <BookingListDataCard
                    key={`booking.${index + monthYear}`}
                    item={item}
                    onCancel={setBookingToCancel}
                    showUnits={!hideUnitDisplay}
                    showAccomodation={!hasOnlyOneAccommodation}
                    bookingToCancel={bookingToCancel}
                    confirmedBookingsToBeCancelled={confirmedBookingsToBeCancelled}
                  />
                ))}
              </div>
            ))}
          </div>
        )
        : (
          <BookingListTable
            data={filteredBookings}
            sortedBy={sortedBy}
            showUnit={!hideUnitDisplay}
            onCancel={setBookingToCancel}
            bookingToCancel={bookingToCancel}
            showAccomodation={!hasOnlyOneAccommodation}
            confirmedBookingsToBeCancelled={confirmedBookingsToBeCancelled}
          />
        )
      )}

      {isLoadingBookings && <OvalLoadingSpinner style={{marginTop: '40px'}}/>}
      {!isLoadingBookings && !isErrorBookings && filteredBookings?.length === 0 && <NoResultMessage>{buildNoResultsMessageForSearchBy()}</NoResultMessage>}
      {!isLoadingBookings && isErrorBookings &&
        <NoResultMessage>
          <span>{t('failedToRetrieveBookingsMessage')}
            <Button link type="button" onClick={reloadBookings}>{t('clickToRetryMessage')}</Button>
          </span>
        </NoResultMessage>}
    </>
  )
}

BookingListTableContainer.propTypes = {
  setBookingToCancel: PropTypes.func,
  bookingToCancel: PropTypes.any,
  confirmedBookingsToBeCancelled: PropTypes.array
}

export default BookingListTableContainer

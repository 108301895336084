import React from 'react'
import PropTypes from 'prop-types'
import { RadioButtonArea, RadioButtonInput, RadioButtonLabel, LabelContainer } from './RadioButton.style'

const RadioButton = ({ className, label, value, onChange, checkedValue, id, name, ...props }) => {
  return (
    <RadioButtonArea className={className}>
      <RadioButtonInput
        type="radio"
        name={name}
        className={className}
        value={value}
        checked={value === checkedValue}
        onChange={onChange}
        id={id || ''}
        {...props}
      />
      <RadioButtonLabel checked={value === checkedValue} />
      {label && <LabelContainer>{label}</LabelContainer>}
    </RadioButtonArea>
  )
}

RadioButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  checkedValue: PropTypes.string,
  onChange: PropTypes.func
}

export default RadioButton

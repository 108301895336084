import styled, { css, keyframes } from 'styled-components'

const Body = styled.tbody`
  max-height: fit-content;
/* MOBILE */
${({ theme }) => theme.mediaQueries.phoneOnly} {
  max-height: fit-content;
}
`
const SiteROW = styled.tr`
background-color: ${({ theme }) => theme.colors.primaryFour};
border: 0.5px solid ${({ theme }) => theme.colors.propertyListBorders};
/* MOBILE */
${({ theme }) => theme.mediaQueries.phoneOnly} {
  background-color: ${({ theme }) => theme.colors.primaryFour};
  border: 0.5px solid ${({ theme }) => theme.colors.propertyListBorders};
}
/* iPAD */
@media (min-width: 600px) and (max-width: 1199px) {
  background-color: ${({ theme }) => theme.colors.primaryFour};
  border: 0.5px solid ${({ theme }) => theme.colors.propertyListBorders};
}
`

const loadingAnimation = keyframes`
  to {
    background-position: 200% center;
  }
`

const UnitROW = styled.tr`
  background-color: ${({ $loadingAvailability, theme }) => $loadingAvailability ? theme.ColorGrey100 : theme.colors.white};

  ${({ $loadingAvailability }) => $loadingAvailability && css`
  /* making the row look like it's loading */
  /* position: relative; renders on top arrow buttons, so we make the fifth td relative as a workaround */
  td:nth-child(5) {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -500%;
      width: 100vw;
      height: 100%;
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.8) 10%,
        rgba(255, 255, 255, 0) 20%
      );
      background-position: -100% center;
      background-size: 40%;
      background-repeat: no-repeat;
      animation-name: ${loadingAnimation};
      animation-duration: 2s;
      animation-delay: 400ms;
      animation-iteration-count: infinite;
    }
  }
  `
}
`

export { Body, SiteROW, UnitROW }

import styled from 'styled-components'

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  overflow: hidden;
  column-gap: 10px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 250px;
    background: linear-gradient(90deg, '${({theme}) => theme.colors.withOpacity}' 0%, '${({theme}) => theme.colors.noOpacity}' 100%);
  }
`

const ImageThumbnail = styled.img`
  border-radius: 8px;
  height: 160px;
`

export { ImageContainer, ImageThumbnail }

import styled from 'styled-components'
import TableHeader from '../../Table/components/TableHeader'
import TableCell from '../../Table/components/TableCell'

export const TableHeaderMobile = styled(TableHeader)`
    font-size: 0.875rem;
`
export const TableHeaderCellMobile = styled(TableCell)`
    min-width: 0;
    flex: 1 1 0px;
    padding: 0;
    > * {
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

export const TableHeaderDesktopDIV = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.buttonSolidTertiaryHover};
   
    ${({ theme }) => theme.mediaQueries.laptopUpDS } {
        padding: 1rem;
    }
`

export const TableHeaderDesktop = styled(TableHeader)`
    text-align: left;
`
export const MobileHeader = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.buttonSolidTertiaryHover};
    padding-bottom: 1rem;
`

export const Currency = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    padding: 8px 12px;
    margin-bottom: 0.625rem;
    background: #EBF1FF;
    border-radius: 4px;

    svg{
        margin-top: 0.3rem;
    }
    span{
        margin-left: 0.5rem;
    }

    
   ${({ theme }) => theme.mediaQueries.tabletDownDS } {
        width: 100%;
        margin-bottom: 0.5rem;
    }
`

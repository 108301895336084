import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { apiFetch } from '@/hooks/useQuery/helpers'

export const queryKeyReportsCsv = ['reports-csv']

export type IncomeRecord = {
  ownerCode: string;
  commissionRate: number;
  serviceName: string;
  unitCode: string;
  start: string;
  finish: string;
  bookingId: string;
  bookingStatus: string;
  title: string;
  forename: string;
  surname: string;
  nettHire: string;
  vat: number;
  fuelSecurity: number;
  extras: number;
  totalCost: number;
  hoseasonsHeld: number;
  outstanding: number;
  amountPaid: number;
  paymentType: 'payment';
  serviceCode: string;
  fuel: string;
  supplierCode: string;
};

export type IncomeReportDataCsv = IncomeRecord[];

export function useQueryReportsCsv (siteId: string, paymentId: string, enabled: boolean, options?: UseQueryOptions<IncomeReportDataCsv>) {
  const queryKey = [...queryKeyReportsCsv, siteId, paymentId]

  const queryFn = () => apiFetch(`v1/pricing/reports/${siteId}/income/${paymentId}`)

  return useQuery<IncomeReportDataCsv>(queryKey, queryFn, {
    enabled: !!siteId && !!paymentId && enabled,
    ...options
  })
}

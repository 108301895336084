import React from 'react'
import { Arrow } from './ForwardArrow.styles'
import ArrowBtn from '@/assets/icons/arrow.png'
import { useBookingChartDatesContext } from '../context/BookingChartDatesProvider'
import {addDaysToDate, deductDaysFromDate, getIsoDate, removeTimeFromIsoDateString} from '@/helpers/dateUtils'
import { useBookingChartBookingsContext } from '../context/Bookings/BookingChartBookingsProvider'
import { useBookingChartContext } from '../context/BookingChartProvider'

const ForwardArrow = () => {
  const { loadingBookings, setBookingsByUnit } = useBookingChartBookingsContext()
  const { isLoadingChart } = useBookingChartContext()
  const {startDate, setStartDate, dateForBoundaries} = useBookingChartDatesContext()
  const minDate = new Date(removeTimeFromIsoDateString(`${dateForBoundaries.getFullYear() - 1}-01-01)`))
  const isPreviousButtonDisabled = getIsoDate(startDate) === getIsoDate(deductDaysFromDate(minDate, 2))

  const previous = (e) => {
    e.preventDefault()
    setBookingsByUnit(null)
    if (deductDaysFromDate(startDate, 27) < deductDaysFromDate(minDate, 2)) {
      setStartDate(minDate)
    } else {
      setStartDate(deductDaysFromDate(startDate, 27))
    }
  }

  const next = (e) => {
    setBookingsByUnit(null)
    e.preventDefault()
    setStartDate(addDaysToDate(startDate, 31))
  }
  return (
    <Arrow>
      {!loadingBookings && !isLoadingChart && <div className='container'>
        <div className='leftEmptyContainer'></div>
        <div className='arrowContainer'>
          <div data-testid='previous' className={`previous ${isPreviousButtonDisabled ? 'previousArrowDisabled' : ''}`} onClick={previous}>
            <img className='previousImg' src={ArrowBtn} alt="arrow" />
          </div>
          <div data-testid='next' className='next' onClick={next}>
            <img className='nextImg' src={ArrowBtn} alt="arrow" />
          </div>
        </div>
      </div>}
    </Arrow>
  )
}

export default ForwardArrow

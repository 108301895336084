import React from 'react'
import PropTypes from 'prop-types'
import { useTable } from '../Table'
import TableHeadCell from './TableHeadCell'
import { StyledTableHeader } from '../Table.styles'

const TableHeader = ({ children, className = '' }) => {
  const { hasCollapsible } = useTable()

  return (
    <StyledTableHeader className={`table-header ${className}`}>
      {children}
      {hasCollapsible && (<TableHeadCell className="no-print" width="2rem" />)}
    </StyledTableHeader>
  )
}

TableHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default TableHeader

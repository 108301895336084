import { useQuery } from '@tanstack/react-query';
import { apiFetch } from '../helpers';

type PricingV2Input = {
  propertyID: string | null;
  year?: number;
  duration?: number;
}

export type PricingV2Response = {
  hasIncome: boolean;
  isDynamic: boolean;
  currency: string;
  rows: PricingV2ResponseRows[]
}

export type PricingV2ResponseRows = {
  week: {
    weekNumber: number
    startDate: string
    endDate: string
  }
  season: {
    value: string
  }
  basePrice: {
    value: number
  }
  currentPrice?: {
    value: number
    canEdit: boolean
    adjustmentPercentage: number
  }
  income?: {
    value: number
  }
  bookingDetails?: {
    bookingsCount: string
    bookingReferences: string[]
  }
}

async function fetchMockData(input: PricingV2Input): Promise<PricingV2Response> {

    return await apiFetch(`/v1/pricing/${input.propertyID}/data?Year=${input.year}&duration=${input.duration}`);
}

export function useQueryPricingDataV2(input: PricingV2Input) {
  const queryKey = ['pricingV2', input]
  const queryFn = () => fetchMockData(input)

  return useQuery({
    queryKey,
    queryFn,
    enabled: !!input.propertyID,
    cacheTime: 0,
  })  

}

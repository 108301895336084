import { useMemo, useRef } from 'react'

const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b)

export function useDeepMemoize (value) {
  const ref = useRef()

  if (!isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export function useDeepMemo (memoFn, deps) {
  const memoDeps = useDeepMemoize(deps)
  const memoValue = useMemo(memoFn, memoDeps)
  return memoValue
}

export default useDeepMemo

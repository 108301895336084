import { useState } from 'react';
import { Popover, usePopover } from '@awaze/design-system.components.popover';
import { StyledButton, StyledButtonApply, ButtonWrapper, StyledButtonClear } from './OccupancyPicker.styles';
import OccupancyPicker from './OccupancyPicker';
import { UserCouple } from '@awaze/design-system.components.icon';
import { useBookingChartContext, DEFAULT_PARTY_PROFILE } from '../../__BookingChartComponents/context/BookingChartProvider';
import { Text } from '@awaze/design-system.components.text';
import OccupancyPickerDrawer from './OccupancyPickerDrawer';
import useWindowSize from '@/hooks/useWindowSize'

const OccupancyPickerPopover = () => {
  const { PopoverContainer, togglePopover, popoverProps, triggerProps } = usePopover();
  const { partyProfile, setPartyProfile } = useBookingChartContext();

  const [adults, setAdults] = useState(partyProfile.adults);
  const [children, setChildren] = useState(partyProfile.children);
  const [infants, setInfants] = useState(partyProfile.infants);
  const [pets, setPets] = useState(partyProfile.pets);

  const { width } = useWindowSize()
  const isMobile = width < 600
  const isTablet = width > 600 && width < 1024

  const totalGuests = adults + children + infants;

  const handleApply = () => {
    setPartyProfile({ adults, children, infants, pets });
    togglePopover();
  };

  
  const handleClear = () => {
    setAdults(DEFAULT_PARTY_PROFILE.adults);
    setChildren(DEFAULT_PARTY_PROFILE.children);
    setInfants(DEFAULT_PARTY_PROFILE.infants);
    setPets(DEFAULT_PARTY_PROFILE.pets);
    setPartyProfile(DEFAULT_PARTY_PROFILE);
    togglePopover();
  };

  // Responsive check
  if (isMobile) {
    return <OccupancyPickerDrawer partyProfile={partyProfile} setPartyProfile={setPartyProfile} />;
  }

  return (
    <PopoverContainer>
      <StyledButton
        onClick={togglePopover}
        text={
          isTablet 
            ? <Text>{`${totalGuests} Guests`}</Text>
            : <Text>{`${totalGuests} Guests - ${pets} Pet(s)`}</Text>
        }
        {...triggerProps}
        icon={<UserCouple />}
        iconPosition="right"
      />
      <Popover popoverWidth={371} {...popoverProps}>
        <OccupancyPicker
          adults={adults}
          setAdults={setAdults}
          numChildren={children}
          setNumChildren={setChildren}
          infants={infants}
          setInfants={setInfants}
          pets={pets}
          setPets={setPets}
        />
        <ButtonWrapper>
          <StyledButtonClear
            variant="ghost"
            colourScheme="brand-primary"
            onClick={handleClear}
            text="Clear"
          />
          <StyledButtonApply
            variant="solid"
            colourScheme="brand-primary"
            onClick={handleApply}
            text="Apply"
          />
        </ButtonWrapper>
      </Popover>
    </PopoverContainer>
  );
};

export default OccupancyPickerPopover;

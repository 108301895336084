import React, {useEffect, useState} from 'react'
import { PageWrapper } from '@/components/__UI'
import { SearchBar } from '@/components/__Inputs'
import { Header, Container, StyledButton, SearchBarWrapper } from './UserAdminPage.styles'
import UsersAdminTable from '@/components/__UsersAdmin/UsersAdminTable'
import UserAdminEditModal from '@/components/__Modals/UserAdminEditModal'
import UserAdminModal from '@/components/__Modals/UserAdminModal'
import useGraphQl, { GET_USER_ADMIN_LIST } from '@/hooks/useGraphQl'

const UserAdminPage = () => {
  const [activeUserId, setActiveUserId] = useState(false)
  const [isAddUserRequest, setIsAddUserRequest] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [filteredUserList, setFilteredUserList] = useState([])

  const [userList, isLoadingUsers, reloadUsers] = useGraphQl({query: GET_USER_ADMIN_LIST})

  useEffect(() => {
    filterUsers()
    return () => {
      setActiveUserId(false)
      setIsAddUserRequest(false)
      setFilteredUserList([])
    }
    // eslint-disable-next-line
  }, [userList, searchText])

  const filterUsers = () => {
    if (userList && Array.isArray(userList)) {
      let list = userList
      if (searchText !== '') {
        list = list.filter(({email, sites, firstName, lastName}) => {
          const searchTerm = `${firstName} ${lastName} ${email} ${Array.isArray(sites) ? sites.join(' ') : sites}`.toLowerCase()
          return searchTerm.toLowerCase().includes(searchText.toLowerCase())
        })
      }
      setFilteredUserList(list)
    }
  }

  return (
    <PageWrapper
      title={`Manage users (${(userList && userList.length) || 0})`}
    >
      <Container>
        <Header>
          <SearchBarWrapper>
            <SearchBar
              name='searchBar'
              placeholder='Search users...'
              setSearchText={setSearchText}
            />
          </SearchBarWrapper>
          <StyledButton primary data-testid='addUserButton' onClick={() => setIsAddUserRequest(true)}>Add User</StyledButton>
        </Header>
      </Container>

      {isAddUserRequest && <UserAdminModal onClose={() => setIsAddUserRequest(false)} reloadUsers={() => reloadUsers()} />}
      {activeUserId && <UserAdminEditModal userId={activeUserId} onClose={() => setActiveUserId(false)} reloadUsers={() => reloadUsers()} />}

      <UsersAdminTable
        isLoadingUsers={isLoadingUsers}
        setFilteredUsers={setFilteredUserList}
        filteredUsers={filteredUserList}
        allUsers={userList}
        setActiveUserId={setActiveUserId}
        searchText={searchText}
      />
    </PageWrapper>
  )
}

export default UserAdminPage

import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Collapse from '@kunukn/react-collapse'
import Button from '@/components/__Inputs/Button'
import { FaqStyling } from '@/global/GlobalStyle'
import { FaqItemWrap, FaqItemTitle, FaqCollapseIcon, FaqContainer } from './FAQs.styles'

const FAQsItem = ({faq, filterToggle, searchToggle, smallBar}) => {
  const [openCollapse, setOpenCollapse] = useState(false)
  const [moreDetailsCollapse, setMoreDetailsCollapse] = useState(false)
  const {title, content, moreDetails} = faq

  useEffect(() => {
    if (searchToggle === 'searchOpen') {
      setOpenCollapse(true)
    } else {
      setOpenCollapse(false)
    }
  }, [filterToggle, searchToggle])

  return (
    <FaqItemWrap smallBar={smallBar}>
      <FaqItemTitle onClick={() => setOpenCollapse(!openCollapse)}>
        {title}<FaqCollapseIcon isOpen={openCollapse}/>
      </FaqItemTitle>
      <Collapse isOpen={openCollapse} data-is-open={openCollapse}>
        <FaqContainer>
          <FaqStyling>{content}</FaqStyling>
          {moreDetails && (
            <>
              <Button link onClick={() => setMoreDetailsCollapse(!moreDetailsCollapse)} style={{margin: '10px 0px'}}>More Details</Button>
              <Collapse isOpen={moreDetailsCollapse} data-is-open={moreDetailsCollapse}>
                <FaqStyling>{moreDetails}</FaqStyling>
              </Collapse>
            </>
          )}
        </FaqContainer>
      </Collapse>
    </FaqItemWrap>
  )
}

FAQsItem.propTypes = {
  faq: PropTypes.object.isRequired,
  filterToggle: PropTypes.bool,
  smallBar: PropTypes.bool,
  searchToggle: PropTypes.string
}

export default FAQsItem

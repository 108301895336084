import PropTypes from 'prop-types'
import React, { useState, useEffect, useMemo } from 'react'
import { parseJwt, triggerLogout, UserContext, hasPermission } from './helpers'
import { createCookie, getCookies, getUserGuidCookieValue } from '@/helpers/cookies'
import { useQueryUser, useQueryUserAuthorisations } from '@/hooks/useQuery'
import { getLogoutUrl } from '@/helpers/authorisations'

const UserProvider = ({ children }) => {
  const idCookie = getCookies('idToken')

  const [userFromJWT, setUserFromJWT] = useState(null)
  const [isLoggingOut, setIsLoggingOut] = useState(false)

  const enableUserQuery = !!idCookie
  const {
    data: userFromQuery,
    refetch: refetchUser,
    isLoading: loadingUser,
    isRefetching: refetchingUser
  } = useQueryUser({
    enabled: enableUserQuery
  })
  const {
    data: permissions,
    isLoading: loadingUserAuth,
    isRefetching: refetchingUserAuth,
    refetch: refetchUserAuth,
    remove: removeUserAuth
  } = useQueryUserAuthorisations()

  // Handle IdToken sign in
  useEffect(() => {
    if (idCookie) {
      const idTokenJWT = parseJwt(idCookie)
      const oneYear = new Date()

      oneYear.setFullYear(oneYear.getFullYear() + 1)
      createCookie('LastAuthUser', idTokenJWT.sub, oneYear, '/')
      getUserGuidCookieValue()
      setUserFromJWT({
        attributes: {
          email: idTokenJWT.email
        }
      })
    }
  }, [idCookie])

  const user = useMemo(() => {
    if (userFromJWT == null && userFromQuery == null) {
      return null
    }

    return {
      attributes: {
        email: userFromJWT?.attributes?.email,
        brand: userFromQuery?.brand,
        productMarkets: userFromQuery?.productMarkets
      }
    }
  }, [userFromJWT, userFromQuery])

  // Handle initial getPermissions once user is created
  useEffect(() => {
    if (userFromJWT) {
      refetchUser()
      refetchUserAuth()
    }
  }, [userFromJWT, refetchUser, refetchUserAuth])

  const login = async (email) => {
    setUserFromJWT({ attributes: { email } })
    refetchUser()
    refetchUserAuth()
  }

  const logout = () => {
    if (userFromJWT) {
      const chatElement = document.querySelector('.genesys-app') ? document.querySelector('.genesys-app') : {}
      chatElement.hidden = true
      triggerLogout()
      setUserFromJWT(null)
      removeUserAuth()
      setIsLoggingOut(true)
      window.location.replace(getLogoutUrl())
    }
  }

  return (
    <UserContext.Provider value={{
      user: user,
      loading: (enableUserQuery && loadingUser) || loadingUserAuth,
      refetching: refetchingUser || refetchingUserAuth,
      permissions: permissions ?? [],
      hasPermission: value => hasPermission(value, permissions),
      login,
      logout,
      isLoggingOut
    }}
    >
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default UserProvider

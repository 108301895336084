import styled from 'styled-components';
import { Button } from '@awaze/design-system.components.button';
import DesignTokens from '@/global/DesignTokens'

export const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.blackTwo};
  border: 1px solid ${DesignTokens.ColorGrey400};
  background: none;
  height: 48px;
  white-space: nowrap;

  &:focus,
  &:active {
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.white};
  }

`;


export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
`;

export const GuestInfo = styled.span`
  font-weight: bold;
`;

export const GuestDescription = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey }
`;

export const StepperContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledButtonApply = styled(Button)`
margin-left: 16px;
width: 140px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between
`;

export const StyledButtonClear = styled(Button)`
width: 140px;
`;

import React from 'react'
import { StickyFooter } from './DebugBar.styles'
import { LanguageSwitcher } from './LanguageSwitcher'
import { isEnabled } from '@/constants/features'

export function DebugBar () {
  if (!isEnabled('debugBar')) {
    return null
  }

  return (
    <StickyFooter>
      <LanguageSwitcher />
    </StickyFooter>
  )
}

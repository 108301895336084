import DesignTokens from '@/global/DesignTokens'
import styled from 'styled-components'


export const HeaderTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 1rem;

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    flex-direction: column;
    align-items: stretch;
  }
`
export const DropDownContainer = styled.div`
  height: 3rem;
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    margin-bottom: 1rem;
  }
`

export const DatePickerContainer = styled.div`
  display: flex;
  gap: 1rem;

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    flex-direction: column;
    align-items: stretch;
    
  }
`

export const DatePickerAndTodayContainer = styled.div`
  display: flex;
  column-gap: 1rem;
`


export const DatePickerCalendar = styled.div`

  img {
    position: absolute;
    margin: 0;
    right: 1rem;
    width: 1.5rem;
    margin-top: 4.5%;
    z-index: 1;
  }

  input {
    height: 3rem;
    max-width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    flex-grow: 2;
  }
`

export const TodayButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8vw;
  height: 3rem;
  color: ${({ theme }) => theme.colors.txtBodyOnWhite};
  border: 1px solid ${DesignTokens.ColorGrey400};
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;

  @media (min-width: 600px) and (max-width: 1199px) {
    width: 12vw;
  }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    flex-grow: 1;
  }
`

export const PropertyDropdownWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  @media (min-width: 1200px) {
    width: 31vw;
    max-width: 20rem;
  }

  @media (min-width: 600px) and (max-width: 1199px) {
    width: 31vw;
    max-width: 20rem;
  }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`

import styled from 'styled-components'
import { Button } from '@/components/__Inputs'

const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background-color: ${({theme}) => theme.colors.white};
  padding: 0.5rem;
  margin-bottom: 2rem;
  min-height: 308px;
  width: 49%;
  margin-right: 1%;

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    display: block;
    width: 100%;
    margin-right: 0;
  }

  ${({theme}) => theme.mediaQueries.tabletLandscapeUp} {
    width: 32%;
  }
  
  ${({theme}) => theme.mediaQueries.desktopUp} {
    width: 24%;
  }

  ${({submittedChanges, theme}) => submittedChanges === true && `
    border: 2px solid ${theme.colors.success};
  `}
`

const Caption = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0.6rem;
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 200px;
  margin-bottom: 0.5rem;
`

const StyledButton = styled(Button)`
  position: relative;

  ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
    align-self: flex-end;
  }
`

export { ItemContainer, Caption, Image, StyledButton }


export const errorFilter = () => {
  const originalWarn = console.warn
  console.warn = (...args) => {
    if (/ which will likely trigger a React console error. If you would like automatic filtering of unknown props, you can opt-into that behavior via/.test(args[0])) {
      return
    }
    originalWarn.call(console, ...args)
  }

  const originalError = console.error
  console.error = (...args) => {
    if (/ If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase/.test(args[0])) {
      return
    }
    originalError.call(console, ...args)
  }
}

import { Popover, usePopover } from '@awaze/design-system.components.popover';
import { Text } from '@awaze/design-system.components.text';
import { useTranslation } from 'react-i18next';
import { FilterControlSettingsAlt } from '@awaze/design-system.components.icon';
import {
  PopoverWrapper,
  DurationContainer,
  StyledDaysButton,
  WeeksContainer,
  StyledWeekSelectionButton,
  StyledFilterIconButton,
  StyledTextActiveFilters,
} from './PricingListTableFilters.styles';
import { usePricingDataContext } from '../context/PricingDataProvider';
import { useUserBrand } from '@/hooks/useUserBrand/useUserBrand'

export function PricingListTableFilters() {
  const {
    weeksToShow,
    setWeeksToShow,
    duration: selectedDuration,
    setDuration,
    durationOptions,
    year,
  } = usePricingDataContext();

  const {
    PopoverContainer,
    togglePopover,
    popoverProps,
    triggerProps,
  } = usePopover();

  const currentYear = new Date().getFullYear();

  const { t } = useTranslation();

  const { isUserBrandNovasol } = useUserBrand() || {}
  
  
  return (
    <PopoverWrapper>
      <PopoverContainer>
        <StyledFilterIconButton
          {...triggerProps}
          variant="ghost"
          text={
            <StyledTextActiveFilters sizes={100}>2</StyledTextActiveFilters>
          }
          icon={<FilterControlSettingsAlt size="medium" />}
          onClick={togglePopover}
          data-testid="filter-icon-button"
        />
        <Popover
          popoverWidth={276}
          placement="bottom-end"
          boxConfig={{
            padding: 16,
          }}
          {...popoverProps}
        >
          <Text sizes={200} type="bold" mb={16} data-testid="filters-header">
            {t('filters')}
          </Text>

          {/* Duration Section */}
          {!isUserBrandNovasol && (
          <div>
            <Text sizes={100} type="mid" mb={8} data-testid="duration-header">
              {t('duration')}
            </Text>
            {durationOptions && durationOptions.length > 0 && (
            <DurationContainer>
              {durationOptions.map((duration) => (
                <StyledDaysButton
                  key={duration}
                  text={
                    <Text
                      sizes={100}
                      type="mid"
                      style={{ display: 'inline' }}
                      data-testid={`duration-${duration}-days`}
                    >
                      {duration} {t('days')}
                    </Text>
                  }
                  colourScheme="brand-secondary"
                  variant={duration === selectedDuration ? 'solid' : 'outline'}
                  onClick={() => setDuration(duration)}
                />
              ))}
            </DurationContainer>
            )}
          </div>
          )}
          {/* Weeks Section */}
          <div>
            <Text sizes={100} type="mid" mb={8} data-testid="weeks-header">
              {t('weeks')}
            </Text>
            <WeeksContainer>
              {year === currentYear && (
                <StyledWeekSelectionButton
                  text={
                    <Text sizes={100} type="mid" data-testid="weeks-upcoming">
                      {t('upcoming')}
                    </Text>
                  }
                  colourScheme="brand-secondary"
                  variant={weeksToShow === 'upcomming' ? 'solid' : 'outline'}
                  onClick={() => setWeeksToShow('upcomming')}
                />
              )}
              <StyledWeekSelectionButton
                text={
                  <Text sizes={100} type="mid" data-testid="weeks-all">
                    {t('all')}
                  </Text>
                }
                colourScheme="brand-secondary"
                variant={weeksToShow === 'all' ? 'solid' : 'outline'}
                onClick={() => setWeeksToShow('all')}
              />
            </WeeksContainer>
          </div>
        </Popover>
      </PopoverContainer>
    </PopoverWrapper>
  );
}

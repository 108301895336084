import styled from 'styled-components'
import { Button } from '@awaze/design-system.components.button'

export const StyledReviewResponseForm = styled.div`
  display: 'flex';
  flex-direction: 'column';
  align-items: 'start';
`
export const StyledButtonIcon = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  width: 100%; 
  margin-bottom: 1.5rem;
  padding: 0;
  color: ${({ theme }) => theme.colors.buttonSolidSecondary}
`
export const StyledButtonPublish = styled(Button)`
  margin-top: 0.5rem;
  width: 144px;

${({ theme }) => theme.mediaQueries.phoneDS} {
  width: 100% 
}
`

export const NonTestPropertyWarning = styled.div`
min-height: 20px;
padding: 19px;
margin-bottom: 20px;
background-color: #f5f5f5;
border: 1px solid #e3e3e3;
border-radius: 4px;
-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
color: red;
`

import React from 'react'

import PropTypes from 'prop-types'
import { OvalLoadingSpinner } from '@/components/__UI'
import { useQueryBookingSearch } from '@/hooks/useQuery'
import { CONSOLIDATED_BOOKING_STATE_OWNER_WITH_CLEAN } from '@/constants/bookings'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  ModalBody,
  ModalContent
} from '@awaze/design-system.components.modal'
import { Heading, Text } from '@awaze/design-system.components.text'
import { Check, ServicesVacoomCleaner } from '@awaze/design-system.components.icon'
import {
  BookingDetailsStyled,
  StyledText,
  StyledIconAndHeader,
  TextWithMargin,
  ErrorMessage,
  FlexWrap,
  OvalLoaderContainer,
  StyledButton,
  ButtonContent
} from './SuccessfulBookingModalConsolidated.styles'

const SuccessfulBookingModalConsolidated = ({ data, onClose }) => {
  const { t } = useTranslation()

  const { data: bookingDetails, isLoading: isLoadingBookingDetails } = useQueryBookingSearch(data.bookingReference)

  const CleaningChargesMessage = (cleaningRequiredOnBooking) => (
    cleaningRequiredOnBooking && (
      <>
        <FlexWrap>
          <ServicesVacoomCleaner iconColour='ColorThemeSecondary400' />
          <Heading sizes={100} mb={8}>{t('cleaningIncluded')}</Heading>
        </FlexWrap>
        <StyledText sizes={300} id='cleaningChargeMessage'>{t('cleaningMeassage')}</StyledText>
      </>
    )
  )

  const SuccessfulNotificationMessage = (hasSuppressedNotification) => (
    !hasSuppressedNotification && (
      <p data-testid='successfulNotificationMessage'>{t('confirmationEmail')}</p>
    )
  )

  return (
    <Modal isOpen close={onClose}>
      <ModalContent
        header={
          <StyledIconAndHeader>
            <Check backgroundColour='ColorStatusConstructive500' iconColour='ColorWhiteOpacity100' size='x-small' />
            <TextWithMargin>{t('bookingSuccessfullyCreated')}</TextWithMargin>
          </StyledIconAndHeader>
        }
      >
        <ModalBody>
          {isLoadingBookingDetails ? (
            <OvalLoaderContainer>
              <OvalLoadingSpinner/>
            </OvalLoaderContainer>
          ) : (
            <>
              {data.hasNotificationSent || data.hasSuppressedNotification ? (
                <Text size={300} mb={24}>{SuccessfulNotificationMessage(data.hasSuppressedNotification)}</Text>
              ) : (
                <ErrorMessage data-testid='error'>
                  <p>
                    {t('bookingConfirmedWithProblem', { reservationId: data.reservationId })}
                  </p>
                </ErrorMessage>
              )}

              <BookingDetailsStyled booking={bookingDetails} />
              {CleaningChargesMessage(bookingDetails.state === CONSOLIDATED_BOOKING_STATE_OWNER_WITH_CLEAN)}
              <ButtonContent>
                <StyledButton
                  onClick={onClose}
                  text={t('ok')}
                  colourScheme='brand-primary'
                />
              </ButtonContent>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

SuccessfulBookingModalConsolidated.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func
}

export default SuccessfulBookingModalConsolidated

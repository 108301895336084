import React from 'react'
import Link from '@/components/Link'

export default [
  {
    'tags': ['Financial Statements'],
    'title': 'Can I continue to receive postal copies of my statements and view them online?',
    'content': (<p>From June 2017 we&apos;ll stop sending you postal copies of your statements if you&apos;ve signed up for our online service. Electronic statements are a much more efficient solution - you&apos;ll be able to see your monthly statement of income as soon as it becomes available rather than waiting several days for the post.</p>)
  },
  {
    'tags': ['Financial Statements'],
    'title': 'Where\'s my statement for this month?',
    'content': (<p>We&apos;ll upload your new statement around the same time each month. We&apos;ll only generate a statement for you if there are payments pending on your account, so if a statement appears to be missing this is normally because no payments are due.</p>)
  },
  {
    'tags': ['Financial Statements'],
    'title': 'Where are my statements for last year?',
    'content': (<p>We can send you copies of 2016 (or earlier) statements if you need them – please contact <Link className="link" to="/contact-us">Owner Services</Link> who will be happy to help. Unless you opt out of online statements, all your statements from January 2017 onwards will be available to view here on the website. After the end of 2017, we’ll archive these on the website for you so there&apos;ll always be a permanent record for you to access as and when you need them.</p>)
  },
  {
    'tags': ['Financial Statements'],
    'title': 'What are the advantages of electronic statements?',
    'content': (<p>Online statements are a fast, efficient and green solution. You&apos;ll be able to see the rental you&apos;re due to receive each month at least three days earlier than waiting for your statement to arrive in the post. Our financial page has been designed so that you have the flexibility to download, save and print your statements as and when you need to and you’ll always have a secure permanent archive stored in one place. Of course, going paper-free is a simple step in becoming more sustainable through the reducing wastage and energy consumption. For more information on our green policy please <a href="http://www.cottages.com/info/green-grading" target="_blank" rel='noopener noreferrer'>click here</a>.</p>)
  },
  {
    'tags': ['Financial Statements'],
    'title': 'Updated information for VAT registered owners',
    'content': (<p>The Government has recently provided updated information around changes to VAT arrangements introduced last year. If your holiday letting business is VAT registered, please take a few moments to read the information <a href='https://www.gov.uk/guidance/vat-reduced-rate-for-hospitality-holiday-accommodation-and-attractions' target='_blank' rel='noopener noreferrer'>here</a>, where you can find more detail on the various rates in place, the applicable date ranges and which rates apply to the differing elements.<br></br>If you are not VAT registered for your holiday let business then this does not apply to you</p>)
  }
]

import React from 'react'
import styled from 'styled-components'

const NewsSvg = styled(props => (
  <svg {...props} enableBackground="new 0 0 48 48" version="1.1" viewBox="0 0 48 48" xmlSpace="preserve" xmlns="https://www.w3.org/2000/svg">
    <path clipRule="evenodd" d="M41,47L41,47H7l0,0c-2.762,0-5-2.238-5-5V2c0-0.552,0.447-1,1-1l0,0h34l0,0  c0.414,0,0.77,0.252,0.922,0.611C37.972,1.73,38,1.862,38,2v0v14h7c0.553,0,1,0.448,1,1v25C46,44.762,43.762,47,41,47z M36,42V3H4  v39l0,0l0,0c0,1.657,1.344,3,3,3h30.022C36.39,44.162,36,43.131,36,42z M44,18h-6v24c0,1.657,1.343,3,3,3s3-1.343,3-3V18z M7,40  c0-0.552,0.447-1,1-1h9c0.553,0,1,0.448,1,1c0,0.553-0.447,1-1,1H8C7.447,41,7,40.553,7,40z M11.666,23.715  c-0.381,0.381-0.999,0.381-1.381,0c-0.381-0.381-0.381-1,0-1.381l5.905-5.905c0.032-0.047,0.053-0.101,0.095-0.143  c0.197-0.197,0.457-0.288,0.715-0.282c0.258-0.006,0.518,0.084,0.715,0.282c0.042,0.042,0.062,0.096,0.095,0.143l4.19,4.19  l2.19-2.19c0.032-0.047,0.053-0.101,0.095-0.143c0.197-0.197,0.457-0.288,0.715-0.281c0.258-0.007,0.518,0.084,0.715,0.281  c0.042,0.042,0.062,0.096,0.095,0.143l3.905,3.905c0.381,0.381,0.381,1,0,1.381c-0.382,0.381-1,0.381-1.381,0L25,20.381L23.381,22  l0.334,0.334c0.381,0.381,0.381,1,0,1.381s-1,0.381-1.381,0L17,18.381L11.666,23.715z M23,29h9c0.553,0,1,0.448,1,1  c0,0.553-0.447,1-1,1h-9c-0.553,0-1-0.447-1-1C22,29.448,22.447,29,23,29z M23,34h9c0.553,0,1,0.448,1,1c0,0.553-0.447,1-1,1h-9  c-0.553,0-1-0.447-1-1C22,34.448,22.447,34,23,34z M23,39h9c0.553,0,1,0.448,1,1c0,0.553-0.447,1-1,1h-9c-0.553,0-1-0.447-1-1  C22,39.448,22.447,39,23,39z M24,15.5c0,0.829-0.672,1.5-1.5,1.5S21,16.329,21,15.5s0.672-1.5,1.5-1.5S24,14.671,24,15.5z M27,10H13  c-0.553,0-1-0.448-1-1s0.447-1,1-1h14c0.553,0,1,0.448,1,1S27.553,10,27,10z M8,34h9c0.553,0,1,0.448,1,1c0,0.553-0.447,1-1,1H8  c-0.553,0-1-0.447-1-1C7,34.448,7.447,34,8,34z M18,30c0,0.553-0.447,1-1,1H8c-0.553,0-1-0.447-1-1c0-0.552,0.447-1,1-1h9  C17.553,29,18,29.448,18,30z" fillRule="evenodd"/>
  </svg>
))`
    ${({ theme }) => `
        fill: ${theme.colors.secondary};
    `}
`

export default NewsSvg

import React from 'react'
import { OvalLoadingSpinner } from '@/components/__UI'
import ReviewList from './ReviewList/ReviewList'
import { StyledText, StyledNoReviewBox } from './GuestReview.styles'
import { useTranslation } from 'react-i18next'

export const GuestReview = ({property, language, reviews, isLoadingReviews}) => {
  const { t } = useTranslation()

  if (isLoadingReviews) {
    return <OvalLoadingSpinner />
  } else if (reviews?.reviews?.length > 0) {
    return <ReviewList reviews={reviews.reviews} property={property} language={language} />
  } else {
    return <StyledNoReviewBox shadow={2} padding={[[60, 24]]}>
      <StyledText as="p">{t('noReviewMessage')}</StyledText>
    </StyledNoReviewBox>
  }
}

import dayjs from 'dayjs'
import React from 'react'
import PropTypes from 'prop-types'
import Table from '@/components/Table'
import Spinner from '@/components/__UI/Spinner'
import PricingListItem from '../PricingListItem'
import useWindowSize from '@/hooks/useWindowSize'
import PricingListMobileItem from '../PricingListMobileItem'
import Tooltip from '@/components/__UI/Tooltip'
import {TableHeaderDesktop, TableHeaderDesktopTooltips, TableHeaderCellDesktop} from './PricingList.styles'

const PricingList = ({ duration, items, loading, year, tooltips }) => {
  const { width } = useWindowSize()

  const getIncomePrice = (item) => {
    if (item.bookedDuration) {
      if (item.bookedDuration !== duration.value) {
        return ''
      }
    }

    return `£${item.potentialIncome === 0 ? item.potentialIncome : item.potentialIncome.toFixed(2)}`
  }

  const renderItems = (toRender) => {
    if (loading) return <Spinner large />
    if (!items.length) return <h1>No data found</h1>
    return toRender()
  }

  if (width < 600) {
    return renderItems(() => {
      return [...Array(12).keys()].map(month => {
        const date = dayjs(`${year.value}-${(month + 1)}-01`)
        const filteredItems = items.filter((item) => dayjs(item.weekCommencing).isSame(date, 'month'))

        if (!filteredItems.length) return null

        return (
          <div key={month}>
            <div style={{ marginTop: '1rem' }}>{date.format('MMMM')}</div>
            {filteredItems.map(item => <PricingListMobileItem key={item.weekCommencing} item={item} duration={duration} income={getIncomePrice(item)} tooltips={tooltips} />)}
          </div>
        )
      })
    })
  }

  return (
    <Table columns={7}>
      <TableHeaderDesktop>
        <TableHeaderCellDesktop>Week commencing<br/>(Thursday)</TableHeaderCellDesktop>
        <TableHeaderCellDesktop>Seasonality</TableHeaderCellDesktop>
        <TableHeaderCellDesktop>Max % decrease</TableHeaderCellDesktop>
        <TableHeaderCellDesktop>Max % increase</TableHeaderCellDesktop>
        <TableHeaderCellDesktop>Start price</TableHeaderCellDesktop>
        <TableHeaderCellDesktop>{`Today's \n customer price`}</TableHeaderCellDesktop>
        <TableHeaderCellDesktop>Your income </TableHeaderCellDesktop>
      </TableHeaderDesktop>
      <TableHeaderDesktopTooltips>
        <Table.HeadCell></Table.HeadCell>
        <Table.HeadCell></Table.HeadCell>
        <Table.HeadCell><Tooltip text={tooltips.decreasePercentTooltip}/></Table.HeadCell>
        <Table.HeadCell><Tooltip text={tooltips.increasePercentTooltip}/></Table.HeadCell>
        <Table.HeadCell></Table.HeadCell>
        <Table.HeadCell><Tooltip text={tooltips.customerPriceTooltip}/></Table.HeadCell>
        <Table.HeadCell><Tooltip text={tooltips.incomeTooltip}/></Table.HeadCell>
      </TableHeaderDesktopTooltips>
      {renderItems(() => items.map(item => (
        <PricingListItem
          key={item.weekCommencing}
          item={item}
          duration={duration}
          income={getIncomePrice(item)}
        />
      ))
      )}
    </Table>
  )
}

PricingList.propTypes = {
  duration: PropTypes.object,
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  year: PropTypes.object,
  tooltips: PropTypes.object
}

export default PricingList

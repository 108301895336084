import React from 'react'
import FilterIcon from '../../../assets/icons/Icon.svg'
import { FilterButtonContainer, FilterButton, FilterButtonContainerCalendar } from './FIlterIconButton.styles'
import PropTypes from 'prop-types'
import { useUser } from '@/context/UserProvider'

const FilterIconButton = ({ onClick, buttonText }) => {
  const { hasPermission } = useUser()
  const chartPermission = hasPermission('Chart')

  const handleClick = () => {
    onClick()
  }

  return (
    <>
      {chartPermission
        ? <FilterButtonContainer onClick={handleClick} data-testid='filterButtonContainer'>
          <FilterButton>
            <img src={FilterIcon} alt="Filter icon" />
            <p>{buttonText}</p>
          </FilterButton>
        </FilterButtonContainer>
        : <FilterButtonContainerCalendar onClick={handleClick} data-testid='filterButtonContainerCalendar'>
          <FilterButton>
            <img src={FilterIcon} alt="Filter icon" />
            <p>{buttonText}</p>
          </FilterButton>
        </FilterButtonContainerCalendar>
      }
    </>
  )
}

FilterIconButton.propTypes = {
  onClick: PropTypes.func,
  isFilterWrapperVisible: PropTypes.bool,
  buttonText: PropTypes.string
}

export default FilterIconButton

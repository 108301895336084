import React, { createContext, useContext, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

export const refetchContextDefault = {
  subscriptions: new Map()
}

export const RefetchContext = createContext(refetchContextDefault)

export const useRefetchContext = () => useContext(RefetchContext)

export function RefetchProvider ({ children }) {
  const value = useRef({ subscriptions: new Map() })

  return (
    <RefetchContext.Provider value={value.current}>
      {children}
    </RefetchContext.Provider>
  )
}

RefetchProvider.propTypes = {
  children: PropTypes.node
}

export function useRefetchNotify (...keys) {
  const { subscriptions } = useRefetchContext()

  const refetch = useCallback(() => {
    keys.forEach(key => {
      const subscription = subscriptions.get(key)
      if (subscription) {
        subscription.forEach(fn => fn())
      }
    })
    // eslint-disable-next-line
  }, [subscriptions, ...keys])

  return refetch
}

export function useRefetchSubscribe (key, callback) {
  const { subscriptions } = useRefetchContext()

  useEffect(() => {
    if (!subscriptions.has(key)) {
      subscriptions.set(key, new Set())
    }

    const subscription = subscriptions.get(key)
    subscription.add(callback)

    return () => {
      subscription.delete(callback)
    }
  }, [key, callback, subscriptions])
}

import React from 'react'
import PropTypes from 'prop-types'
import DesignTokens from '@/global/DesignTokens'

const ChevronSvg = ({ direction = 0, fill = DesignTokens.ColorBlackOpacity100, size = 24 }) => (
  <svg data-testid='chevron' width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" transform={`rotate(${direction})`}>
    <path d="M19.2523 16.5375C19.0623 16.5375 18.8722 16.4675 18.7222 16.3175L12.0001 9.59538L5.27796 16.3175C4.98787 16.6076 4.50772 16.6076 4.21763 16.3175C3.92753 16.0274 3.92753 15.5472 4.21763 15.2571L11.1298 8.34499C11.5999 7.88484 12.4102 7.88484 12.8703 8.34499L19.7825 15.2571C20.0726 15.5472 20.0726 16.0274 19.7825 16.3175C19.6324 16.4675 19.4424 16.5375 19.2523 16.5375Z" fill={fill}/>
  </svg>
)

ChevronSvg.propTypes = {
  direction: PropTypes.number,
  fill: PropTypes.string,
  size: PropTypes.number
}

export default ChevronSvg

import styled from 'styled-components'
import { Button } from '@/components/__Inputs'

const Container = styled.div`
  position: relative;
  margin-bottom: 3rem;

  @media (min-width: 600px) and (max-width: 1080px) {
    margin-top: 4rem;
  }
`

const Header = styled.div`
  
  ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 70%;
    top: -56px;
    right: 0;
  }
`

const StyledButton = styled(Button)`
  flex: 1;
  
  ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
    max-width: 142px;
  }
`

const SearchBarWrapper = styled.div`
  flex: 1;

  ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
    margin-right: 1rem;
    max-width: 400px;
  }
`

export { Container, Header, StyledButton, SearchBarWrapper }

import React from 'react'
import PropTypes from 'prop-types'
import Table from '@/components/Table'
import useWindowSize from '@/hooks/useWindowSize'
import NoItemsFound from '@/components/__UI/NoItemsFound'
import { UsersAdminListSortingHeader, UsersAdminListItem } from '@/components/__UsersAdmin'

const UsersAdminTable = ({ isLoadingUsers, setFilteredUsers, allUsers, filteredUsers, setActiveUserId, searchText }) => {
  const { width } = useWindowSize()
  const isDesktop = width > 920

  const noItemsText = <>Sorry, we {`couldn't`} find any matches{searchText && <> for <strong>{`"${searchText}"`}</strong></>}.</>

  return (
    <Table
      columns={7}
      data={filteredUsers}
      isLoading={isLoadingUsers}
      unsortedData={allUsers}
      noItemsText={<NoItemsFound text={noItemsText} />}
    >
      {isDesktop && <UsersAdminListSortingHeader usersList={filteredUsers} setUsersList={setFilteredUsers} showLastLoginCell={false}/>}

      {filteredUsers.map((item, index) => (
        <UsersAdminListItem
          key={`user.${index}`}
          item={item}
          setActiveUserId={setActiveUserId}
          showLastLoginCell={false}
        />
      ))}
    </Table>
  )
}

UsersAdminTable.propTypes = {
  isLoadingUsers: PropTypes.bool,
  filteredUsers: PropTypes.array,
  allUsers: PropTypes.array,
  searchText: PropTypes.string,
  setFilteredUsers: PropTypes.func,
  setActiveUserId: PropTypes.func
}

export default UsersAdminTable

import React from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import PropTypes from 'prop-types'
import useWindowSize from '@/hooks/useWindowSize'
import Tooltip from '@/components/__UI/Tooltip'
import { StyledRow, MobileCell, StyledTableCell, PopupSite, EditButton, EditButtonEmpty } from './UsersAdminListItem.styles'

import IconAdminUserTick from '@/assets/icons/adminusertick.svg'

dayjs.extend(relativeTime)

const userAdminFriendlyLoginDateTime = (date) => {
  if (date === undefined) {
    return '-'
  }

  const options = { year: 'numeric', month: 'short', day: '2-digit' }
  const dateToReturn = new Date(date)

  return (dateToReturn.getFullYear() === 1)
    ? '-'
    : `${dateToReturn.getHours()}:${(dateToReturn.getMinutes() < 10 ? '0' : '') + dateToReturn.getMinutes()} on ${dateToReturn.toLocaleDateString('en-GB', options)}`
}

const UsersAdminListItem = ({ item, setActiveUserId, showLastLoginCell = true }) => {
  const { width } = useWindowSize()
  const isDesktop = width > 920
  const { userId, firstName, lastName, email, jobTitle, adminRole, sites, lastLogin, canBeEdited } = item

  const getDisplayName = (firstname, lastName) => {
    return firstname[0].toUpperCase() + firstname.substring(1) + ' ' + lastName[0].toUpperCase() + lastName.substring(1)
  }

  const handleClick = () => {
    setActiveUserId(userId)
  }

  const displaySitesList = (sites) => {
    if (Array.isArray(sites)) {
      if (sites.length === 1) return sites

      const multiSiteText = `${sites[0]} +${sites.length - 1}`
      const itemsShown = 7
      const text = sites.slice(1, itemsShown + 1).map((x, i) => <PopupSite key={i}>{x}</PopupSite>)
      if (sites.length > itemsShown + 1) {
        text.push(<PopupSite key='more'>{`+${sites.length - itemsShown - 1}`}</PopupSite>)
      }

      return (<Tooltip
        text={text}
        popUpWidth={200}
      >
        {multiSiteText}
      </Tooltip>)
    }
  }

  return (
    <StyledRow
      key={userId}
    >
      {
        isDesktop
          ? (
            <>
              <StyledTableCell><p className="ellipsis">{getDisplayName(firstName, lastName)}</p></StyledTableCell>
              <StyledTableCell width='13rem'>{email}</StyledTableCell>
              <StyledTableCell>{jobTitle}</StyledTableCell>

              <StyledTableCell>{adminRole && <img alt='Admin' src={IconAdminUserTick}/>}</StyledTableCell>

              <StyledTableCell>{displaySitesList(sites)}</StyledTableCell>
              { showLastLoginCell && <StyledTableCell>{userAdminFriendlyLoginDateTime(lastLogin)}</StyledTableCell>
              }
              {
                canBeEdited &&
                (<StyledTableCell width='4.5rem'><EditButton onClick={() => handleClick()}
                >Edit</EditButton></StyledTableCell>)
              }
              {
                canBeEdited ||
                (<StyledTableCell width='4.5rem'><EditButtonEmpty onClick={() => handleClick()}
                ></EditButtonEmpty></StyledTableCell>)
              }
            </>
          )
          : (
            <MobileCell >
              <div className='infoContainer'>
                <div className='data'>Name: </div>
                <div className='value'>{getDisplayName(firstName, lastName)}</div>
              </div>
              <div className='infoContainer'>
                <div className='data'>Email: </div>
                <div className='value'>{email}</div>
              </div>
              <div className='infoContainer'>
                <div className='data'>Job title: </div>
                <div className='value'>{jobTitle}</div>
              </div>
              <div className='infoContainer'>
                <div className='data'>Admin: </div>
                <div className='value'>{adminRole && <img alt='Admin' src={IconAdminUserTick}/>}</div>
              </div>
              <div className='infoContainer'>
                <div className='data'>Sites: </div>
                <div className='value'>{displaySitesList(sites)}</div>
              </div>
              {showLastLoginCell && <div className='infoContainer'>
                <div className='data'>Last login: </div>
                <div className='value'>{userAdminFriendlyLoginDateTime(lastLogin)}</div>
              </div>}
              <div>
                {
                  canBeEdited && (<EditButton onClick={() => handleClick()}>Edit</EditButton>)
                }
              </div>
            </MobileCell>
          )
      }
    </StyledRow>
  )
}

UsersAdminListItem.propTypes = {
  item: PropTypes.object,
  setActiveUserId: PropTypes.func,
  showLastLoginCell: PropTypes.bool
}

export default UsersAdminListItem

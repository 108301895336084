import React from 'react'
import { useParams } from 'react-router-dom'
import { useUser } from '@/context/UserProvider'
import { Container, Spinner } from '@/components/__UI'
import { PropertyAmendsEditLayout, PropertyFeatures } from '@/components/__PropertyAmendsComponents'
import { Content } from './PropertyAmendsFeaturesPage.styles'

const PropertyAmendsFeaturesPage = () => {
  const { id } = useParams()
  const { user, loading, hasPermission } = useUser()
  const isPermitted = hasPermission('Bookings')

  if (!isPermitted && !loading) {
    return (
      <Container>
        <p style={{ textAlign: 'center' }}>You do not have permission to view this page</p>
      </Container>
    )
  }

  if (!user || loading) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  return (
    <PropertyAmendsEditLayout propertyId={id}>
      <h1>Accommodation features</h1>
      <p>Please select a feature to view the criteria required to list it against your accommodation.</p>
      <p>From here you can agree to the criteria or disregard if your property does not meet the requirements.</p>
      <Content>
        <PropertyFeatures propertyId={id} />
      </Content>
    </PropertyAmendsEditLayout>
  )
}

export default PropertyAmendsFeaturesPage

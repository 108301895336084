/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
  getExpandedRowModel
} from '@tanstack/react-table'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { DATE_FORMAT_DAY_ABBR_MONTH_YEAR } from '@/constants/dates'
import { HeaderContainer, HeaderFlexbox, HeaderHoverElement, RowsContainer, StyledHeaderRow, StyledRow, StyledTable, StyledTableContainer, StyledTd, StyledThead, StyledTr, TableHeader } from './BookingListTable.styles'
import { ArrowSvg } from '@/components/__SVG'
import Theme from '@/global/Theme'
import { ExpandedRow } from './ExpandedRow'
import useWindowSize from '@/hooks/useWindowSize'
import { AccommodationCellRenderer, ColorTypeCell, ExpanderCell, GuestInfoCell, GuestInfoHeader, NameCountryCellRender, SpecialRequirementsCell } from './ColumnsComponents'
import { useTranslation } from 'react-i18next'

const getInitialSorting = (sortedBy) => {
  if (!sortedBy || sortedBy.name === 'arrival' || sortedBy.name === 'active') {
    return { 'id': 'startDate', 'desc': false }
  }

  if (sortedBy.name === 'departureDate') {
    return { 'id': 'endDate', 'desc': false }
  }

  return { 'id': 'bookedOnDate', 'desc': true }
}

export function useDateFormatter () {
  const { t } = useTranslation()

  return useCallback((date) => {
    if (!dayjs(date).isValid()) {
      return ''
    }

    const dateValue = dayjs(date)
    const currentDate = dayjs()

    if (dateValue.isSame(currentDate, 'day')) {
      return t('today')
    }

    return dateValue.format(DATE_FORMAT_DAY_ABBR_MONTH_YEAR)
  }, [t])
}

function BookingListTable ({ data, sortedBy, showUnit, showAccomodation, onCancel, bookingToCancel, confirmedBookingsToBeCancelled }) {
  const { t } = useTranslation()
  const [sorting, setSorting] = useState([getInitialSorting(sortedBy)])
  const [expanded, setExpanded] = useState({})
  const [columnVisibility, setColumnVisibility] = useState({ unitCode: !!showUnit, propertyName: !!showAccomodation })

  const { width } = useWindowSize()

  const dateFormatter = useDateFormatter()

  const onSortingChange = (clickedFunction) => {
    const columnSortingObject = clickedFunction()

    const currentlySortedColumn = sorting.find((col) => col.id === columnSortingObject[0].id)

    let newSortingState

    // This looks to check if the currently sorted column is the one clicked again, if yes it inverts it
    // If its the bookedOnDate column, we set desc true as default for the first sort
    // All other columns sort asc first
    if (currentlySortedColumn) {
      newSortingState = [{ id: columnSortingObject[0].id, desc: !currentlySortedColumn.desc }]
    } else if (columnSortingObject[0].id === 'bookedOnDate') {
      newSortingState = [{ id: columnSortingObject[0].id, desc: true }]
    } else {
      newSortingState = [{ id: columnSortingObject[0].id, desc: false }]
    }

    setSorting(newSortingState)
  }

  useEffect(() => {
    updateCreatedColumnVisibility()
    updateBookingRefColumnVisibility()
    // eslint-disable-next-line
  }, [width])

  const getConsolidatedBookingText = (booking) => {
    return booking.translatedUserFriendlyBookingState
  }

  const updateCreatedColumnVisibility = () => {
    if (width < 1400 && showUnit) {
      setColumnVisibility(prevVisibility => ({
        ...prevVisibility,
        bookedOnDate: false
      }))
    } else if (width < 1230) {
      setColumnVisibility(prevVisibility => ({
        ...prevVisibility,
        bookedOnDate: false
      }))
    } else {
      setColumnVisibility(prevVisibility => ({
        ...prevVisibility,
        bookedOnDate: true
      }))
    }
  }

  const updateBookingRefColumnVisibility = () => {
    if (width < 1260 && showUnit) {
      setColumnVisibility(prevVisibility => ({
        ...prevVisibility,
        bookingId: false
      }))
    } else {
      setColumnVisibility(prevVisibility => ({
        ...prevVisibility,
        bookingId: true
      }))
    }
  }

  const columns = useMemo(() => [
    {
      id: 'colorType',
      cell: ({ cell: { row } }) => {
        return ColorTypeCell(row)
      },
      enableSorting: false
    },
    {
      header: t('arrival'),
      accessorKey: 'startDate',
      cell: (value) => dateFormatter(value.getValue())
    },
    {
      header: t('departure'),
      accessorKey: 'endDate',
      cell: (value) => dateFormatter(value.getValue())
    },
    {
      header: t('accommodation'),
      accessorKey: 'propertyName',
      cell: (value) => {
        return AccommodationCellRenderer(value)
      },
      enableSorting: false
    },
    {
      header: t('unitNoHeader'),
      accessorKey: 'unitCode',
      enableSorting: false
    },
    {
      header: t('nameOrCountry'),
      accessorKey: 'leadGuest',
      cell: ({ row: { original } }) => {
        return NameCountryCellRender(original)
      },
      enableSorting: false
    },
    {
      id: 'guestInfo',
      header: () => (
        GuestInfoHeader()
      ),
      cell: ({ row: { original } }) => {
        return GuestInfoCell(original)
      },
      enableSorting: false
    },
    {
      header: t('type'),
      cell: ({row}) => getConsolidatedBookingText(row.original),
      enableSorting: false
    },
    {
      header: t('bookingRef'),
      accessorKey: 'bookingId',
      enableSorting: false,
      cell: (value) => {
        return !isNaN(value.getValue()) ? value.getValue() : ''
      }
    },
    {
      header: t('created'),
      accessorKey: 'bookedOnDate',
      cell: (value) => dateFormatter(value.getValue())
    },
    {
      id: 'Special requirements',
      header: '',
      accessorKey: 'specialRequirements',
      cell: (value) => {
        return SpecialRequirementsCell(value)
      },
      enableSorting: false
    },
    {
      id: 'expander',
      Header: '',
      cell: ({ row }) => { return ExpanderCell(row) }
    }
  ], [t, dateFormatter])

  const tableInstance = useReactTable({
    columns: columns,
    data,
    state: {
      expanded, sorting, columnVisibility
    },
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: onSortingChange,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowId: row => row.bookingId
  })

  const renderSortingArrow = (header) => {
    if (!header.column.getCanSort()) return null

    const isRowSorted = header.column.getIsSorted()
    if (isRowSorted === 'asc') return <ArrowSvg direction={180} />
    if (isRowSorted === 'desc') return <ArrowSvg />

    return <ArrowSvg style={{marginTop: '3px'}} fill={Theme.ColorGrey400} />
  }

  const renderHeaderContent = (header) => {
    const isGuestInfo = header.id === 'guestInfo'

    if (isGuestInfo) {
      return (
        <HeaderFlexbox>
          <div style={{ flex: 1 }}>
            {flexRender(header.column.columnDef.header, header.getContext())}
            {renderSortingArrow(header)}
          </div>
        </HeaderFlexbox>
      )
    }

    return (
      <HeaderContainer data-testid={`${header.column.id} ${header.column.getIsSorted()}`} canSort={header.column.getCanSort()} useFitContent={isGuestInfo}>
        {header.column.getCanSort() && <HeaderHoverElement />}
        <HeaderFlexbox>
          {flexRender(header.column.columnDef.header, header.getContext())}
          {renderSortingArrow(header)}
        </HeaderFlexbox>
      </HeaderContainer>
    )
  }

  return (
    <StyledTableContainer data-testid='table-view'>
      <StyledTable>
        <StyledThead data-testid='table-header'>
          {tableInstance.getHeaderGroups().map((headerGroup, index) => (
            <StyledHeaderRow key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <TableHeader
                  key={header.id} onClick={header.column.getToggleSortingHandler()}>
                  {renderHeaderContent(header)}
                </TableHeader>

              ))}
            </StyledHeaderRow>
          ))}
        </StyledThead>

        <RowsContainer>
          {tableInstance.getRowModel().rows.map((row, i) => {
            const uniqueKey = `${row.id}-fragment-${i}`
            return (
              <React.Fragment key={uniqueKey}>
                <StyledRow data-testid={`row ${i}`} onClick={() => row.toggleExpanded()} style={{cursor: 'pointer'}} >
                  {row.getVisibleCells().map((cell) => {
                    const key = `${row.id}-${cell.column.id}`
                    return <StyledTd key={key}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</StyledTd>
                  })}
                </StyledRow>
                {row.getIsExpanded() ? (
                  <StyledTr data-testid='expanded-row'>
                    <td colSpan={row.getAllCells().length} style={{padding: '24px 32px'}}>
                      <ExpandedRow item={row.original} onCancel={onCancel}
                        bookingToCancel={bookingToCancel}
                        confirmedBookingsToBeCancelled={confirmedBookingsToBeCancelled}/>
                    </td>
                  </StyledTr>
                ) : null}
              </React.Fragment>
            )
          })}
        </RowsContainer>
      </StyledTable>
    </StyledTableContainer>
  )
}

BookingListTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      propertyName: PropTypes.string,
      leadGuest: PropTypes.string,
      adultsCount: PropTypes.number,
      combinedChildAndInfantsCount: PropTypes.number,
      petsCount: PropTypes.number,
      bookingId: PropTypes.string,
      bookedOnDate: PropTypes.string,
      specialRequirements: PropTypes.arrayOf(PropTypes.string)
    })
  ).isRequired,
  showUnitNo: PropTypes.bool,
  showAccomodation: PropTypes.bool,
  sortedBy: PropTypes.shape({
    name: PropTypes.string
  })
}

export default BookingListTable

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryReviews } from '@/hooks/useQuery'
import { PageWrapper, PropertyDropdown, PropertyDropdownWrapper } from './GuestReviewsPage.styles'
import { Heading } from '@awaze/design-system.components.text'
import { usePropertyFromId } from '@/hooks/usePropertyFromID'
import { getLocale } from '@/helpers/locale'
import { GuestReviewRating } from '@/components/__GuestReviewComponents/GuestReviewRating/GuestReviewRating'
import { GuestReviewCategories } from '@/components/__GuestReviewComponents/GuestReviewCategories/GuestReviewCategories'
import { GuestReview } from '@/components/__GuestReviewComponents/GuestReview/GuestReview'

const GuestReviewsPage = () => {
  const [selectedPropertyID, setSelectedPropertyID] = useState(null)
  const selectedProperty = usePropertyFromId(selectedPropertyID)

  const { t } = useTranslation()

  const targetLanguage = getLocale()

  const { data: reviews, isLoading: isLoadingReviews } = useQueryReviews({
    propertyCode: selectedProperty?.propertyCode,
    language: targetLanguage
  })

  return (
    <PageWrapper>
      <Heading sizes={300} mb={16}>{t('guestReviews')}</Heading>
      {/* DEVELOPER'S NOTE: The following code is commented out because the articles url is not ready yet. */}
      {/* <TextInline as="p">
        {t('guestReviewMessage')} {' '}
        <StyledLink to='/articles/guest-reviews-top-tips'>
          <Text as="span" size='300'>{t('guestReviewTips')}.</Text>
        </StyledLink>
      </TextInline> */}
      <PropertyDropdownWrapper>
        <PropertyDropdown
          label
          operationID="viewPropertyDetail"
          value={selectedPropertyID}
          onChange={event => setSelectedPropertyID(event.target.value)}
          autoSelectFirst
        />
      </PropertyDropdownWrapper>
      <GuestReviewRating reviews={reviews} />
      <GuestReviewCategories reviews={reviews} />
      <GuestReview property = {selectedProperty} language = {targetLanguage} reviews = {reviews} isLoadingReviews = {isLoadingReviews} />
    </PageWrapper>
  )
}

export default GuestReviewsPage

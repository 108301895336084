import styled from 'styled-components'

const DateHeader = styled.td`
  vertical-align: top;

${({ theme }) => theme.mediaQueries.phoneOnly} {
  position: sticky;
  top: 0;
  margin-top: 14rem;
}
  
.withBorder{
  height: auto;
  border-left: 1px solid ${({ theme }) => theme.colors.dateHeaderBorder}; 
}

.fullDatesInfo{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  width: 2.45vw;
  max-width: 2.5625vw;
  vertical-align: top;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  color: ${({ theme }) => theme.colors.fullDatesInfo};

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    max-width: none;
    width: 8.3vw;
    margin-bottom: 0;
  }

  @media (min-width: 600px) and (max-width: 1199px) {
    max-width: none;
    width: 5.05vw;
    margin-bottom: 0;
  }

  .month, .emptyMonth{
    height: 20px;
    margin-bottom: 12px;
  }
  .month{
    width: auto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey};
  }
  .daysDate{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
  }
  .weekendDay {
    font-weight: 600;
  }

  .otherDay{
    font-weight: 400;
  }
  .dates{
    width: auto;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 18px;
    ${({ theme }) => theme.mediaQueries.phoneOnly} {
      margin-bottom: 4px;
    }
  }
  .otherDayNumber{
    font-weight: 500;
    ${({ theme }) => theme.mediaQueries.phoneOnly} {
      width: 5vw;
      text-align: center;
    }
  }
  .currentDay{
    width: 1.7vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 2px;
    margin: 0 auto;
    
    ${({ theme }) => theme.mediaQueries.phoneOnly} {
      margin-bottom: 4px;
      width: 6vw;
      text-align: center;
    }

    @media (min-width: 600px) and (max-width: 1199px) {
      margin-bottom: 4px;
      width: 3.5vw;
      text-align: center;
    }
  }

  .pickedDate{
    width: 1.7vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    margin: 0 auto;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.pickedDatesHeader};

    ${({ theme }) => theme.mediaQueries.phoneOnly} {
      width: 6vw;
      margin-bottom: 4.5px;
      text-align: center;
    }
    @media (min-width: 600px) and (max-width: 1199px) {
      width: 3.5vw;
      margin-bottom: 4px;
      text-align: center;
    }
  }
}
`

export { DateHeader }

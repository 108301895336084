import styled from 'styled-components'
import { Section } from '@/components/__UI'

export const PageWrapper = styled(Section)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${({theme}) => theme.colors.pageBackground};
`

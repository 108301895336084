import styled, { css } from 'styled-components'
import DesignTokens from '@/global/DesignTokens'

const primaryStyles = css`
  color: ${DesignTokens.ColorButtonSolidPrimaryText};  
  background-color: ${DesignTokens.ColorButtonSolidPrimaryBackground};
  border: 0;

  ${({style}) => !style.loading && css`
    &:hover, &:active {
      background-color: ${DesignTokens.ColorButtonSolidPrimaryBackgroundHover};
    }
  `}

  &:disabled {
    cursor: default;
    color: ${DesignTokens.ColorButtonSolidPrimaryText};
    background-color: ${DesignTokens.ColorButtonSolidPrimaryBackground};
    opacity: 0.32;
  }
`

const primaryLineStyles = css`
  color: ${DesignTokens.ColorButtonOutlinePrimaryText};
  background-color: ${DesignTokens.ColorButtonSolidReverseBackground};
  border: 2px solid ${DesignTokens.ColorButtonOutlinePrimaryBorder};

  ${({style}) => !style.loading && css`
    &:hover, &:active {
      color: ${DesignTokens.ColorButtonOutlinePrimaryTextHover};
      border-color: ${DesignTokens.ColorButtonOutlinePrimaryBorderHover};
    }
  `}

  &:disabled {
    cursor: default;
    ${DesignTokens.ColorButtonSolidPrimaryText};
    border: 2px solid ${DesignTokens.ColorButtonOutlinePrimaryBorder};
    opacity: 0.32;
  }
`

const secondaryStyles = css`
  color: ${DesignTokens.ColorButtonSolidSecondaryText};  
  background-color: ${DesignTokens.ColorButtonSolidSecondaryBackground};
  border: 0;

  

  ${({style}) => !style.loading && css`
    &:hover, &:active {
      background-color: ${DesignTokens.ColorButtonSolidSecondaryBackgroundHover};    
    } 
  `}

  &:disabled {
    cursor: default;
    color: ${DesignTokens.ColorButtonSolidSecondaryText};
    background-color: ${DesignTokens.ColorButtonSolidSecondaryBackground};
    opacity: 0.32;
  }
`

const secondaryLineStyles = css`
  color: ${DesignTokens.ColorButtonOutlineSecondaryText};
  border: 2px solid ${DesignTokens.ColorButtonOutlineSecondaryBorder};

  ${({style}) => !style.loading && css`
    &:hover, &:active {
      color: ${DesignTokens.ColorButtonOutlineSecondaryTextHover};
      border-color: ${DesignTokens.ColorButtonOutlineSecondaryBorderHover};
    }
  `}

  &:disabled {
    cursor: default;
    color: ${DesignTokens.ColorButtonOutlineSecondaryText};
    border: 2px solid ${DesignTokens.ColorButtonOutlineSecondaryBorder};
    opacity: 0.32;
  }
`

const linkStyles = css`
  color: ${({ theme }) => theme.colors.primary};
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 1px 0;
  text-decoration: underline;
  font-weight: 700;
  max-width: none;

  &:hover {
    text-decoration: none;
  }

  &:active {
    font-weight: 600;
  }
  
  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
    color: ${({theme}) => theme.colors.disabled};
    border-color: ${({theme}) => theme.colors.disabled};
  }
`

const errorStyles = css`
  color: ${({theme}) => theme.colors.attention};
  background-color: ${({theme}) => theme.colors.white};
  border: 1px solid ${({theme}) => theme.colors.attention};

  ${({style}) => !style.loading && css`
    &:hover, &:active {
      background-color: ${({theme}) => theme.colors.attentionDarken};
      color: ${({theme}) => theme.colors.white};
    }
  `}

  &:disabled {
    cursor: default;
    color: ${({theme}) => theme.colors.white};
    background-color: ${({theme}) => theme.colors.attentionDisabled};
  }
`

export default styled.button`
  ${({link}) => !link && css`
    height: 3rem;
    width: 100%;
    padding: 0.75rem;
    border-radius: ${({theme}) => theme.borderRadius};
  `}
  max-width: ${({fullWidth}) => fullWidth ? 'none' : '19.75rem'};
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  transition: ${({theme}) => `background-color ${theme.animationSpeed} ease, border ${theme.animationSpeed} ease, color ${theme.animationSpeed} ease`};
  pointer-events: ${({style}) => style.loading ? 'none' : 'unset'};
  transition-duration: ${({style, theme}) => style.loading ? '0s' : theme.animationSpeed};

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    max-width: none;
  }

  &:hover > div {
    &:before {
      opacity: 1;
    }

    &:after {
      opacity: 0;
    }
  }

  ${({ primary }) => primary && primaryStyles};
  ${({ primaryLine }) => primaryLine && primaryLineStyles};
  ${({ secondary }) => secondary && secondaryStyles};
  ${({ secondaryLine }) => secondaryLine && secondaryLineStyles};
  ${({ link }) => link && linkStyles};
  ${({ error }) => error && errorStyles};
`

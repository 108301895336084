import { OccupancyFilterContext } from '../Models/OccupancyFilterContext'
import { Property } from '../Models/Property'


export const filterPropertiesByRequestedInfantsOccupancy = (context: OccupancyFilterContext) : OccupancyFilterContext => {
  const filteredProperties = filterPropertiesByRequiredInfantCount(context)

  if (filteredProperties.length === 0) {
    return { ongoingPropertyCollection: filteredProperties, occupancyToFilterOn: context.occupancyToFilterOn }
  }

  return { ongoingPropertyCollection: filteredProperties, occupancyToFilterOn: context.occupancyToFilterOn }
}

const filterPropertiesByRequiredInfantCount = (context: OccupancyFilterContext): Property[] => {
  const updatedCollection = context.ongoingPropertyCollection
  .filter((property) => {
    return (
      property.propertyCapacity.maxInfants + property.propertyCapacity.maxChildren + property.propertyCapacity.maxAdults >= 
      context.occupancyToFilterOn.requiredInfants + context.occupancyToFilterOn.requiredChildren + context.occupancyToFilterOn.requiredAdults
    )
  })

return updatedCollection
}

import styled from 'styled-components'

const Discount = styled.span`
    color: ${({theme}) => theme.colors.error};
    font-weight: bold;
`

const StyledContainer = styled.div`
    img {
        width: 10rem;
        margin-right: 1rem;
        margin-bottom: 1rem;

        &.oue {
            width: 15rem;
            margin-top: 1rem;
            margin-bottom: 2rem;
            border-radius: ${({ theme }) => theme.borderRadius};
        }
    }

    p {
        font-size: 1rem;
        margin-bottom: 1rem;

        b {
            font-weight: bold;
        }

        &.terms {
            font-size: 0.8rem;
        }
    }

    span.mr {
        margin-right: 1rem;
    }

    ${({ theme }) => `${theme.mediaQueries.phoneOnly} {
        h2 {
            font-size: 1.2rem;
        }

        p, ul {
            font-size: 0.8rem;
        }
    }`}
`

const Partner = styled.div`
    padding: 1rem;
    margin: 2rem 0;
    border-radius: ${({ theme }) => theme.borderRadius};
    background-color: ${({ theme }) => theme.colors.white};

    ul {
        list-style: disc;
        margin-left: 2rem;
        margin-bottom: 1rem;

        li {
            &::before {
                content: none
            }

            text-indent: 0;
        }
    }
`

const PartnerHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${({ theme }) => `${theme.mediaQueries.phoneOnly} {
        margin-bottom: 1rem;
        flex-direction: column-reverse;
    }`}
`

const ImageWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    ${({ theme }) => `${theme.mediaQueries.phoneOnly} {
        margin-bottom: 2rem;

        img.oue {
            margin-bottom: 0;
        }
    }`}
`

export { Discount, StyledContainer, Partner, PartnerHeader, ImageWrap }

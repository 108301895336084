export const getInitialValues = (account) => {
  const values = [ 'address', 'town', 'county', 'postcode', 'country', 'phoneNo', 'mobileNo', 'officeNo' ]
  const initialValues = {}
  values.forEach((val) => { initialValues[val] = account && account.accountDetail && account.accountDetail[val] ? account.accountDetail[val] : '' })

  return initialValues
}

export const sortValues = (values) => {
  let country = ''
  if (typeof values.country === 'string' && values.country !== '') country = values.country
  if (typeof values.country === 'object' && values.country.value) country = values.country.value

  return {
    ...values,
    country
  }
}

import styled from 'styled-components'

import { Text } from '@awaze/design-system.components.text'
import { Box } from '@awaze/design-system.components.box'

export const StyledText = styled(Text)`
  text-align: center;
`
export const StyledNoReviewBox = styled(Box)`
  background-color: ${({ theme }) => theme.colors.white};
`

import styled from 'styled-components'

const Container = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
`

const Icon = styled.div`
  position: relative;
  width: 22px;
  height: 22px;

  &:before, &:after {
    content: '';
  }

  ${({enabled, theme}) => enabled ? `
    transform: rotate(45deg);

    &:before, &:after {
      background-color: ${theme.colors.primary};
    }

    &:before {
      position: absolute;
      width: 2px;
      height: 13px;
      left: 13px;
      top: 3px;
    }

    &:after {
      position: absolute;
      width: 5px;
      height: 2px;
      left: 8px;
      top: 14px;
    }
  ` : `
    &:before, &:after {
      position: absolute;
      top: 3px;
      left: 10px;
      content: ' ';
      height: 16px;
      width: 2px;
      background-color: ${theme.colors.error};
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  `
}
`

const Label = styled.div`
  margin-left: 0.5rem;
`

export { Container, Icon, Label }

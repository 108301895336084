import styled from 'styled-components'

export const StickyFooter = styled.div`
  position: fixed;
  left: 4rem;
  bottom: 1.4rem;  

  display: flex;
  justify-content: start;

`

import React from 'react'
import dayjs from 'dayjs'
import Link from '@/components/Link'

const FIELD_DURATION = {
  label: 'Duration',
  placeholder: 'Choose duration',
  required: 'Please choose a duration',
  options: [
    { label: '2 nights', value: 2 },
    { label: '3 nights', value: 3 },
    { label: '5 nights', value: 5 },
    { label: '7 nights', value: 7 }
  ]
}

const FIELD_PROPERTY = {
  label: 'Property',
  type: 'property',
  required: 'Please choose an accommodation'
}

const FIELD_BOOKING_REF = {
  label: 'Booking reference (if known)',
  name: 'bookingReference',
  placeholder: 'Write booking reference',
  type: 'text'
}

const FIELD_START_DATE = {
  label: 'Start date',
  type: 'date',
  required: 'Please choose a start date'
}

const FIELD_END_DATE = {
  label: 'End date',
  type: 'date',
  required: 'Please choose an end date'
}

const PRICING_SECONDARY_QUESTION = {
  fields: [
    [ FIELD_PROPERTY, null ],
    [
      { label: 'Week commencing date', name: 'weekCommencing', type: 'date', required: 'Please select a date' },
      FIELD_DURATION
    ],
    { label: 'Comment/question', name: 'comment', type: 'textarea', required: 'Please enter a comment' }
  ]
}

export default [
  {
    id: 1,
    question: 'I would like to create, amend or cancel an Owner booking',
    response: (
      <p>
        Did you know that you can easily create and cancel Owner bookings directly within your Owner Portal on the Booking Calendar?<br />
        <Link to="/bookings/calendar">Click here</Link> to access your Booking Calendar and select any available date to begin.
      </p>
    ),
    salesforceType: 'Owner Bookings / Booking Patterns',
    salesforceSubType: 'Make / cancel or amend an owner booking',
    formOptions: {
      fields: [
        [ FIELD_PROPERTY, FIELD_BOOKING_REF ],
        [ FIELD_START_DATE, FIELD_DURATION ],
        {
          label: 'Your request / additional information',
          name: 'request',
          type: 'textarea',
          required: 'Please enter your request'
        }
      ],
      title: 'Please fill in the form and we will be in touch shortly'
    }
  },
  {
    id: 2,
    hideButton: true,
    showForm: true,
    question: 'I need to update my accommodation open / close dates',
    secondaryQuestions: {
      title: 'Please choose the type of pricing enquiry you have',
      label: 'Enquiry type',
      questions: [
        {
          question: 'Open rates',
          salesforceType: 'Availability calendar',
          salesforceSubType: 'Open rates',
          formOptions: {
            fields: [
              [ FIELD_PROPERTY, null ],
              [ FIELD_START_DATE, FIELD_END_DATE ],
              { label: 'Reason you need to update', name: 'reason', type: 'textarea', required: 'Please enter a reason' }
            ],
            title: 'Please fill in the details about the period and why you need to update it'
          }
        },
        {
          question: 'Close rates',
          salesforceType: 'Availability calendar',
          salesforceSubType: 'Close rates',
          formOptions: {
            fields: [
              [ FIELD_PROPERTY, null ],
              [ FIELD_START_DATE, FIELD_END_DATE ],
              { label: 'Reason you need to update', name: 'reason', type: 'textarea', required: 'Please enter a reason' }
            ],
            title: 'Please fill in the details about the period and why you need to update it'
          }
        }
      ]
    }
  },
  {
    id: 3,
    question: 'I would like a copy of a recent statement',
    response: (
      <p>
        Did you know you can access your Statements on the Owner Statements page?
        If you have previously opted out of Online statements you can also <Link to="/financial/statements">activate them here</Link>.
      </p>
    ),
    salesforceType: 'Documentation / Communications',
    salesforceSubType: 'Resend statement',
    formOptions: {
      fields: [
        [ FIELD_PROPERTY, null ],
        [
          { label: 'Start month', type: 'date-month', required: 'Please enter a start month', dateRange: {minDate: dayjs().subtract(5, 'year'), maxDate: dayjs()} },
          { label: 'End month', type: 'date-month', required: 'Please enter an end month', dateRange: {minDate: dayjs().subtract(5, 'year'), maxDate: dayjs()} }
        ],
        { label: 'Comment/additional information', name: 'info', type: 'textarea', required: 'Please enter a comment' }
      ],
      title: 'Please fill in the form and we will send your statement(s)'
    }
  },
  {
    id: 4,
    question: 'I want to check the details of an upcoming guest booking',
    response: (
      <p>
        Did you know you can review, print and export the details of upcoming guest bookings including contact details on the <Link to="/bookings/list">Booking list</Link>?
      </p>
    ),
    salesforceType: 'Bookings',
    salesforceSubType: 'Request guest info i.e. address etc',
    formOptions: {
      fields: [
        [ FIELD_PROPERTY, FIELD_BOOKING_REF ],
        [ FIELD_START_DATE, { label: 'Customer name (if known)', name: 'customerName', placeholder: 'Enter customer name', type: 'text' } ],
        { label: 'Question', type: 'textarea', required: 'Please enter your question' }
      ],
      title: 'Please fill in the form and we will be in touch shortly about the guest bookings'
    }
  },
  {
    id: 5,
    question: 'I would like to update my accommodation details, features or imagery',
    response: (
      <p>
        {/* To review and update your existing property description <Link to="/property-amends">click here</Link>.<br /> */}
        To review and update your existing accommodation features <Link to="/property-amends">click here</Link>.<br />
        To review and update your existing arrival description <Link to="/property-amends">click here</Link>.<br />
        To review and update your existing accommodation imagery <Link to="/property-amends">click here</Link>.<br />
      </p>
    ),
    salesforceType: 'The Property',
    salesforceSubType: 'Amending details',
    formOptions: {
      fields: [
        [ FIELD_PROPERTY, null ],
        { label: 'Request', type: 'textarea', required: 'Please enter your request' }
      ],
      title: 'Please fill in the form about your accommodation update'
    }
  },
  {
    id: 6,
    hideButton: true,
    showForm: true,
    question: 'I need to update my personal details',
    response: (
      <p>
        Did you know you can review and update your existing personal and contact details <Link to="/personal/my-details">here</Link>?
        However if you would like to update your email address please update the following form.
      </p>
    ),
    salesforceType: 'The Owner',
    salesforceSubType: 'Change / update personal details',
    formOptions: {
      fields: [
        [
          FIELD_PROPERTY,
          { label: 'New email address', placeholder: 'Enter email address', type: 'email', required: 'Please enter your email address' }
        ],
        { label: 'Additional info', name: 'info', type: 'textarea', required: 'Please enter additional info' }
      ],
      title: 'Update your email address below'
    }
  },
  {
    id: 8,
    question: 'I want to check my owner booking allowances, usage or season dates',
    response: (
      <p>
        Did you know that you can check your Owner booking allowances, usage and season dates <Link to="/financial/booking-allowance">here</Link>?
      </p>
    ),
    salesforceType: 'Availability calendar',
    salesforceSubType: 'Query regarding booking patterns / profile',
    formOptions: {
      fields: [
        [
          FIELD_PROPERTY,
          {
            label: 'Season',
            required: 'Please choose a season',
            placeholder: 'Select a season',
            options: [
              { label: 'Winter', value: 'Winter' },
              { label: 'Peak', value: 'Peak' },
              { label: 'Low', value: 'Low' },
              { label: 'Summer', value: 'Summer' }
            ]
          }
        ],
        { label: 'Question', type: 'textarea', required: 'Please enter your question' }
      ],
      title: 'Please fill in the form and we will be in touch shortly'
    }
  },
  {
    id: 9,
    question: 'I want to respond to a Guest review',
    response: (
      <p>
        Did you know that you can respond to Guest reviews directly on our <Link to="/property/reviews">Guest reviews page</Link>? Just find the review you want and click {`'Respond'`}.
        We recommend you do respond to reviews, but ensure to keep all responses factual and pleasant.
      </p>
    ),
    salesforceType: 'Review',
    salesforceSubType: 'General query re scores / Review / process',
    formOptions: {
      fields: [
        [ FIELD_PROPERTY, { label: 'Review date', type: 'date', required: 'Please choose a date', dateRange: {minDate: dayjs().subtract(3, 'year'), maxDate: dayjs()} } ],
        { label: 'Feedback/response', name: 'feedback', type: 'textarea', required: 'Please add feedback' }
      ],
      title: 'Please fill in the form about the guest review'
    }
  },
  {
    id: 10,
    hideButton: true,
    question: 'I would like to discuss or update my pricing',
    secondaryQuestions: {
      name: 'enquiry',
      title: 'Please choose the type of pricing enquiry you have',
      label: 'Enquiry type',
      questions: [
        {
          question: 'General discussion',
          salesforceType: 'COTTAGES - Pricing',
          salesforceSubType: 'Price query - no action taken',
          formOptions: {
            fields: [
              [ FIELD_PROPERTY, null ],
              { label: 'Comment/question', name: 'comment', type: 'textarea', required: 'Please enter a comment' }
            ]
          }
        },
        {
          question: 'Change my start prices',
          salesforceType: 'COTTAGES - Pricing',
          salesforceSubType: 'Midline change',
          formOptions: PRICING_SECONDARY_QUESTION
        },
        {
          question: 'Pricing is incorrect',
          salesforceType: 'COTTAGES - Pricing',
          salesforceSubType: 'Internal pricing error',
          formOptions: PRICING_SECONDARY_QUESTION
        },
        {
          question: 'Change my max % increase/decrease',
          salesforceType: 'COTTAGES - Pricing',
          salesforceSubType: 'Corridor change',
          formOptions: PRICING_SECONDARY_QUESTION
        }
      ]
    }
  },
  {
    id: 12,
    question: 'I have a question relating to Health & Safety at my accommodation',
    response: (
      <p>
        Please refer to our Health & Safety Frequently asked question <Link to='/health-and-safety'>here</Link>.<br /> If you have an urgent query please contact Owner Services directly on <a href='tel:0345 268 1075'>0345 268 1075</a>.
      </p>
    ),
    hideButton: true
  },
  {
    id: 11,
    hideButton: true,
    showForm: true,
    question: 'Other enquiries',
    salesforceType: 'Bookings',
    salesforceSubType: 'Request guest info i.e. address etc',
    formOptions: {
      fields: [
        [ FIELD_PROPERTY, null ],
        { label: 'Your enquiry', name: 'enquiry', type: 'textarea', required: 'Please enter your enquiry' }
      ],
      title: 'Please let us know how we can help you by filling out the form'
    }
  }
]

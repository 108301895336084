import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { apiFetch } from '@/hooks/useQuery/helpers'

// CONTRACT: https://github.com/awazevr/awz-owner--api--contracts/blob/main/contracts/facilitator-contracts/bookings/bookingDurations.yml
interface Duration {
  id: string;
  duration: number;
  isValidBookingDuration: boolean;
  durationValidityCode?: string;
}

interface BookingDurationsQueryParams {
  propertyId: string;
  unitId: string;
  fromDate: string;
  maxDuration: number;
}

export function useQueryBookingDurations (
  { propertyId, unitId, fromDate, maxDuration }: BookingDurationsQueryParams,
  options?: UseQueryOptions<Duration[]>
) {
  const queryKey = ['booking-durations', propertyId, unitId, fromDate, maxDuration]

  const queryFn = async () => {
    let url = `/v1/properties/${propertyId}/bookings/owner/durations/bookability`

    const params = new URLSearchParams()
    params.append('unitId', unitId)
    params.append('fromDate', fromDate)
    params.append('maxDuration', maxDuration.toString())

    url += `?${params.toString()}`

    return apiFetch(url)
  }

  return useQuery<Duration[]>(queryKey, queryFn, {
    cacheTime: 0,
    ...options
  })
}

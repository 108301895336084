import styled, { css } from 'styled-components'
import { FormikField, PropertyDropdown, TextInput } from '@/components/__Inputs'

const selectStyles = css`
    height: 3rem !important;
    max-width: unset !important;

    width: 100%;

    .react_select__value-container {
        justify-content: flex-start;
    }

    input {
        max-width: unset !important;
    }

`

const Container = styled.div`
    margin: 1rem;
    padding: 1rem;

    img{
        display: none;
    }

    form {

        label {
            text-align: left;
            margin: 1rem 0 .5rem;
        }

        input[type="text"] {
            margin-bottom: 0;
        }

        button[type="submit"] {
            transition: ${({ theme }) => `width ${theme.animationSpeed} ease, background-color ${theme.animationSpeed} ease`};

            &:disabled {
                background-color: ${({ theme }) => theme.colors.disabled};
            }

            &:active {
                color: ${({ theme }) => theme.colors.white};
            }

            &:focus {
                outline: none;
            }

            &.isLoading {
                width: 4rem;
            }
        }
    }

    ${({ theme }) => `${theme.mediaQueries.phoneOnly} {
        img{
            display: none;
        }
        
    }`}
`

const Title = styled.h2`
    margin-bottom: 2rem;
    ${({ theme }) => theme.fonts.Heading100}

    ${({ theme }) => `${theme.mediaQueries.phoneOnly} {
        font-size: 1rem;
    }`}
`

const FormRow = styled.div`

    & > * {
        width: 100%;
    }

    ${({ theme }) => `${theme.mediaQueries.phoneOnly} {
        flex-direction: column;

        & > *:first-child {
            margin-right: 0;
            margin-bottom: 1rem;
        }
    }`}
`

const TextareaContainer = styled.div`
    margin: 1rem 0;
    margin-top: 2rem;

    span {
        text-align: left;
    }
`

const StyledTextInput = styled(TextInput)`
    span {
        bottom: -0.1rem;
        text-align: left;
        position: relative;
    }
`

const StyledFormikField = styled(FormikField)`${selectStyles}`

const StyledPropertyDropdown = styled(PropertyDropdown)`${selectStyles}`

export { Container, Title, FormRow, StyledPropertyDropdown, StyledFormikField, TextareaContainer, StyledTextInput }

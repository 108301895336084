import React from 'react'
import { useParams } from 'react-router-dom'
import { ReviewResponseForm } from '@/components/ReviewResponse/ReviewResponseForm'
import { PageWrapper, ReviewResponseGlobalStyles } from './ReviewResponsePage.styles'

const ReviewResponsePage = () => {
  const { propertyId, reviewId } = useParams()

  return (
    <>
      <ReviewResponseGlobalStyles />
      <PageWrapper>
        <ReviewResponseForm
          propertyId={propertyId}
          reviewId={reviewId}
        />
      </PageWrapper>
    </>
  )
}

export default ReviewResponsePage

import React, { useMemo } from 'react'
import { useQueryUserPropertyEntitlements } from '@/hooks/useQuery'
import { StyledLink, ResponseContainer } from '../ReviewList.styles'
import { useTranslation } from 'react-i18next'

interface RespondReviewProps {
  propertyId: string;
  reviewId: string;
}

const RespondReview = ({ propertyId, reviewId }: RespondReviewProps) => {
  const { data: authorisations } = useQueryUserPropertyEntitlements({ propertyId: propertyId })
  const { t } = useTranslation()

  const canRespondPermission = useMemo(() => {
    return !!(authorisations?.length && authorisations.find(({ name }) => name === 'respondToReviews'))
  }, [authorisations])

  if (!canRespondPermission) {
    return null
  }

  return (
    <ResponseContainer>
      <StyledLink to={`/property/reviews/${propertyId}/${reviewId}`} >
        {t('respond')}
      </StyledLink>
    </ResponseContainer>
  )
}

export default RespondReview

import { useState } from 'react'
import { graphQlFetch } from './helpers'
import useDeepEffect from '@/hooks/useDeepEffect'
import useDeepMemo from '@/hooks/useDeepMemo'

const useGraphQl = ({
  query,
  variables = {},
  defaultValue = null,
  enabled = true,
  asObject,
  onSuccess,
  onError
}) => {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: Array.isArray(query) ? Array(query.length).fill(defaultValue) : defaultValue
  })

  const callGraphQl = useDeepMemo(() => async (callback) => {
    if (!Object.keys(variables).some(k => variables[k] === null) && enabled) {
      setState(s => ({ ...s, loading: true }))
      await graphQlFetch({ query, variables })
        .then(response => {
          const results = callback ? callback(response) : response
          setState(s => ({ ...s, loading: false, error: false, data: results }))
          onSuccess && onSuccess(results)
        })
        .catch(err => {
          setState(s => ({ ...s, error: true }))
          onError && onError(err)
        })
        .finally(() => setState(s => ({ ...s, loading: false })))
    }
  }, [query, variables, enabled, onSuccess, onError])

  useDeepEffect(callGraphQl, [variables, enabled])

  return asObject ? { ...state, execute: callGraphQl } : [state.data, state.loading, callGraphQl]
}

export default useGraphQl

import { useMutation, useQueryClient, UseMutationOptions } from '@tanstack/react-query'
import { apiFetch } from '@/hooks/useQuery/helpers'
import { baseKeyBookings } from '../queries'

interface ResponseDataInput {
    propertyId: string;
    reviewId: string;
    response: ReviewResponseRequest;
  }

interface ReviewResponseRequest {
  response: string;
  moderationChecksConfirmed: boolean;
}

interface ReviewResponseResponse {
  success: boolean;
  profanityWords: string[];
  legalWords: string[];
}

export function useMutationRespondToReview (options?: UseMutationOptions<ReviewResponseResponse, Error, ResponseDataInput>) {
  const client = useQueryClient()

  const mutationFn = async ({ propertyId, reviewId, response }: ResponseDataInput): Promise<ReviewResponseResponse> => {
    return apiFetch(`/v1/properties/${propertyId}/reviews/${reviewId}`, {
      method: 'PATCH',
      body: response
    })
  }

  return useMutation<ReviewResponseResponse, Error, ResponseDataInput>(mutationFn, {
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, variables, context)
      }

      client.invalidateQueries({ queryKey: baseKeyBookings })
    }
  })
}

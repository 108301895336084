import React from 'react'
import PropTypes from 'prop-types'
import StyledButton from './Button.styles'
import Spinner from '@/components/__UI/Spinner'
import { updateDataLayer } from '@/helpers/gtm/gtm'

const Button = ({
  onClick,
  children,
  type = 'button',
  loading,
  trackable,
  ...props
}) => {
  const handleOnClick = () => {
    if (trackable) {
      updateDataLayer({
        event: 'GAEvent',
        eventCategory: trackable.category || null,
        eventAction: trackable.action || null,
        eventLabel: trackable.label || trackable.action || null,
        eventValue: trackable.value || null
      })
    }

    if (onClick) {
      onClick()
    }
  }

  return (
    <StyledButton
      onClick={handleOnClick}
      type={type}
      style={{ loading }}
      {...props}
    >
      {loading ? <Spinner spinnerColor="#000000"/> : children}
    </StyledButton>
  )
}

Button.displayName = 'Button'

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  loading: PropTypes.bool,
  trackable: PropTypes.exact({
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string
  })
}

export default Button

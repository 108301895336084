import styled from 'styled-components'
import { SelectDropdown } from '@/components/__Inputs'
import theme from '@/global/Theme'

const Title = styled.h2`
  text-align: center;
  margin: 1rem 0 2rem;
`

const SubTitle = styled.h2`
  text-align: center;
  margin: 2rem 0;
`

const CenterWrapper = styled.div`
  ${({ theme }) => theme.mediaQueries.tabletPortraitUp} {
    display: flex;
    justify-content: 'space-between';

    & > *:first-child {
      flex: 1;
      margin-right: 0.5rem;
    }
    & > *:nth-child(2n) {
      flex: 1;
      margin-left: 0.5rem;
    }
  }
`

const ButtonSection = styled.div`
  display: flex;
  justify-content: ${({ isEdit }) => isEdit ? 'space-between' : 'flex-end'};

  
  button {
    flex: 1;
    max-width: 9rem;

    &:first-child {
      margin-right: 0.5rem;
    }
    &:nth-child(2n) {
      margin-left: 0.5rem;
    }
  }
`

const StyledSelectDropdown = styled(SelectDropdown)`
  margin-bottom: 1.5rem;
  max-width: none !important;
`

const PermissionsSection = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: inherit;
  margin-bottom: 2rem;
  margin-left: 0;
`

const PermissionItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 47%;
  height: 3rem;
  padding: .75rem;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 1rem 0;
  border-radius: ${({theme}) => theme.borderRadius};
  border: 1px solid ${({theme}) => theme.colors.greyThree};
  color: ${theme.colors.black}; 

  label{
    width: 100%;
    font-size: 0.938rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: ${theme.colors.black}; 
  }

    ${({theme}) => theme.mediaQueries.phoneOnly} {
      width: 100%;
    }
`

export { Title, SubTitle, CenterWrapper, ButtonSection, StyledSelectDropdown, PermissionsSection, PermissionItem }

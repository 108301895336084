import { PricingV2ResponseRows } from '@/hooks/useQuery';
import { Season, SeasonIndicator } from './columns.styles';

interface SeasonCellProps {
  value: PricingV2ResponseRows['season'];
}

export function SeasonCell({ value }: SeasonCellProps) {
  return (
    <Season sizes={200}>
      <SeasonIndicator $season={value.value} /> {value.value}
    </Season>
  );
}

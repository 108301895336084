import styled from 'styled-components'
import SelectDropdown from '@/components/__Inputs/SelectDropdown'

const Header = styled.div`
  position: relative;
  margin-top: 2rem;

  ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
    & > * {
      display: inline-block;
      vertical-align: middle;
      margin-right: 1rem;
    }
  }
`

const PropertyDropdownContainer = styled.div`
  ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
    width: 15rem;
  }
`

const StyledDropdown = styled(SelectDropdown)`
  ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
    width: ${({width}) => width || '180px'};
  }
`

const DropdownContainer = styled.div``

const Section = styled.div`
  margin-top: 1rem;
  background-color: ${({theme}) => theme.colors.white};
  padding: 1rem;
`

export { Header, StyledDropdown, DropdownContainer, PropertyDropdownContainer, Section }

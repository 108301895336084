import { PricingV2ResponseRows } from '@/hooks/useQuery';
import { useTranslation } from 'react-i18next';
import {
  ExpandedGrid,
  ExpandedLabel,
} from './columns.styles';
import { usePricingTableDataVisibility } from './columnDefinitions';
import CurrentPriceCell from './CurrentPriceCell';
import { SeasonCell } from './SeasonCell';
import { IncomeCell } from './IncomeCell';
import BookingsDetailsCell from './BookingsDetailsCell';

export interface ExpandedRowProps {
  row: PricingV2ResponseRows;
}

export function ExpandedRow({ row }: ExpandedRowProps) {
  const { t } = useTranslation();
  const dataToShow = usePricingTableDataVisibility();
  return (
    <ExpandedGrid>
      <ExpandedLabel>{t('season')}</ExpandedLabel>
      <SeasonCell value={row.season} />
      <ExpandedLabel>{t('startPrice')}</ExpandedLabel>
      <span>{row.basePrice.value}</span>
      {dataToShow.currentPrice && row.currentPrice && (
        <>
          <ExpandedLabel>{t('dynamicPrice')}</ExpandedLabel>
          <CurrentPriceCell value={row.currentPrice} />
        </>
      )}
      {dataToShow.income && row.income && (
        <>
          <ExpandedLabel>{t('yourIncome')}</ExpandedLabel>
          <IncomeCell value={row.income} />
        </>
      )}
      <ExpandedLabel>{t('bookingDetails')}</ExpandedLabel>
      <BookingsDetailsCell value={row.bookingDetails} />
    </ExpandedGrid>
  );
}

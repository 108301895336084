import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { PropertyImageEditModal, PropertyAmendSuccessModal } from '@/components/__Modals'
import { ItemContainer, Caption, Image, StyledButton } from './PropertyImagerySection.styles'
import defaultImage from '@/assets/images/default-image.jpg'

const PropertyImagerySection = ({item, propertyId}) => {
  const [showModal, setShowModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [imageItem, setImageItem] = useState(item)

  const { id, caption, url } = imageItem

  return (
    <>
      <ItemContainer key={id} submittedChanges={item !== imageItem} >
        <Image src={url === 'Remove this Image' ? defaultImage : url} />
        <Caption>{caption}</Caption>
        <StyledButton secondaryLine onClick={() => setShowModal(!showModal)}>Edit</StyledButton>
      </ItemContainer>
      {showModal && <PropertyImageEditModal propertyId={propertyId} onClose={setShowModal} setShowSuccessModal={setShowSuccessModal} setSubmittedChanges={setImageItem} item={item} />}
      {showSuccessModal && <PropertyAmendSuccessModal onClose={setShowSuccessModal} />}
    </>
  )
}

PropertyImagerySection.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    caption: PropTypes.string,
    url: PropTypes.string
  }),
  setCurrentImage: PropTypes.func,
  propertyId: PropTypes.string
}

export default PropertyImagerySection

import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { apiFetch } from '@/hooks/useQuery/helpers'
import { getExportBookingStateKey, getAdultCountTextDisplayForBooking, getChildCountTextDisplayForBooking, getInfantCountTextDisplayForBooking, getAttributeCountTextDisplayForBooking } from '@/helpers/bookings'
import { useTranslation } from 'react-i18next'
import { pascalToCamelCase } from '@/helpers/stringUtils'
import { getBookingStateText } from '@/helpers/translations/translations'

type GraphQLBookingsResponse = {
  bookings: any[];
}

type BookingSearchInput = {
  searchBy?: string;
  startDate?: string;
  endDate?: string;
  siteId?: string;
  propertyId?: string;
  unitId?: string;
  searchType?: string;
}
export const baseKeyBookings = ['bookings']
export const queryKeyBookings = (input: BookingSearchInput) => [...baseKeyBookings, input]
export const queryFnBookings = (input: BookingSearchInput, t = key => key) => {
  return apiFetch(`v1/properties/bookinglist?${getSearchParameters(input)}`)
    .then(bookingResponse => mapBookingResponseWithTranslations(bookingResponse, t))
}

export function useQueryBookings (
  parameters: BookingSearchInput,
  options?: UseQueryOptions<GraphQLBookingsResponse>) {
  const { t } = useTranslation()

  const queryKey = queryKeyBookings(parameters)
  const queryFn = () => queryFnBookings(parameters, t)

  return useQuery<GraphQLBookingsResponse>(queryKey, queryFn, {
    staleTime: 4000,
    cacheTime: 0,
    retry: 0,
    ...options
  })
}

function getSearchParameters (parameters: BookingSearchInput) {
  const search = new URLSearchParams()

  if (parameters.searchBy) search.append('searchBy', parameters.searchBy)
  if (parameters.startDate) search.append('fromDate', parameters.startDate)
  if (parameters.endDate) search.append('toDate', parameters.endDate)
  if (parameters.siteId) search.append('siteId', parameters.siteId)
  if (parameters.propertyId) search.append('propertyId', parameters.propertyId)
  if (parameters.unitId) search.append('unitId', parameters.unitId)
  if (parameters.searchType) search.append('searchType', parameters.searchType)

  return search.toString()
}

function mapBookingResponseWithTranslations (bookingsResponse, t) {
  const mapped = {
    ...bookingsResponse,
    bookings: bookingsResponse.bookings.map(booking => {
      const stateForTranslation = pascalToCamelCase(booking.state)
      return {
        ...booking,
        translatedUserFriendlyBookingState: getBookingStateText(t, booking.state),
        translatedExportBookingState: t(getExportBookingStateKey(stateForTranslation)),
        translatedPartyDescription: mapPartyProfileTextForBooking(booking, t)
      }
    })
  }

  return mapped
}

function mapPartyProfileTextForBooking (booking, t) {
  const adultCountText = getAdultCountTextDisplayForBooking(booking.adultsCount, t('adult'), t('adults'))
  const childCountText = getChildCountTextDisplayForBooking(booking.childrenCount, t('child'), t('children'))
  const infantCountText = getInfantCountTextDisplayForBooking(booking.infantsCount, t('infant'), t('infants'))
  const petCountText = getAttributeCountTextDisplayForBooking(booking.petsCount, t('pet'), t('pets'))

  return `${adultCountText}${childCountText}${infantCountText}${petCountText}`
}

import { useQuery } from '@tanstack/react-query'
import { apiFetch } from '@/hooks/useQuery/helpers'
import { useQueryPropertyPricingSummary } from './useQueryPropertyPricingSummary'

interface ColumnHeader {
  columnIndex: number;
  prefixText: string;
  yearText: string;
  suffixText: string;
}

interface RecommendationRow {
  weekNumber: number;
  season: string;
  startWeekDate: string;
  endWeekDate: string;
  isoStartWeekDate: string;
  isoEndWeekDate: string;
  data: string[];
}

interface PricingHeadersData {
  duration: number;
  columnCount: number;
  currency: string;
  columnHeaders: ColumnHeader[];
  recommendations: RecommendationRow[];
}

export function useQueryPricingData (
  property: { propertyId: string },
  year: { value: string },
  duration: number,
  maxRetries = 3,
  retryDelay = 1000
) {
  const propertyPricingSummaryQuery = useQueryPropertyPricingSummary()

  const isHidePricingDurations =
    propertyPricingSummaryQuery.data?.isHidePricingDurations || false

  const endpointBase = `v1/pricing/${property?.propertyId}/recommendations?year=${year?.value}`
  const shouldIncludeDuration = !isHidePricingDurations
  const endpoint = shouldIncludeDuration
    ? `${endpointBase}&duration=${duration}`
    : endpointBase

  const queryKey = ['pricing', property?.propertyId, year.value, duration]

  const queryResult = useQuery<PricingHeadersData>(
    queryKey,
    () => apiFetch(endpoint),
    {
      staleTime: Infinity,
      retry: maxRetries,
      retryDelay: retryDelay
    }
  )

  if (!property || !year || !duration) {
    return {
      headersData: undefined,
      recommendationsData: undefined,
      isLoading: false,
      isError: false
    }
  }

  return {
    headersData: queryResult.data,
    recommendationsData: queryResult.data?.recommendations,
    isLoading: queryResult.isLoading,
    isError: queryResult.isError,
    currency: queryResult.data?.currency
  }
}

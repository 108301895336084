import theme from '@/global/Theme'
import * as BookingTypes from '@/constants/bookings'
import { splitCamelCase } from '@/helpers/stringUtils'

const getBookingState = (booking) => {
  if (booking) {
    const state = booking.reservationState || booking.bookingState
    if (typeof state === 'string') {
      return state
    }
    switch (state) {
      case 1:
        return BookingTypes.BOOKING_STATE_CONFIRMED
      case 2:
        return BookingTypes.BOOKING_STATE_CANCELLED
      case 3:
        return BookingTypes.BOOKING_STATE_EXPIRED
      case 4:
        return BookingTypes.BOOKING_STATE_PROVISIONAL
      default:
        return state
    }
  }
}

const getBookingType = (booking) => {
  const type = booking && booking.reservationType
  if (typeof type === 'string') {
    return type
  }
  switch (type) {
    case 1:
      return BookingTypes.BOOKING_TYPE_OWNER
    case 2:
      return BookingTypes.BOOKING_TYPE_CUSTOMER
    case 5:
      return BookingTypes.BOOKING_TYPE_OWNER_CLEAN
    default:
      return BookingTypes.BOOKING_TYPE_CANCELLED
  }
}

const getBookingText = (booking) => {
  const state = getBookingState(booking)
  return state !== 'Confirmed' ? state : getBookingType(booking)
}

const isOwner = (type, state) => {
  if (state) {
    switch (state) {
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER:
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER_WITH_CLEAN:
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_UNCHECKED_OWNER:
        return true
      default:
        return false
    }
  }

  return (
    type === BookingTypes.BOOKING_TYPE_OWNER_CLEAN ||
    type === BookingTypes.BOOKING_TYPE_OWNER ||
    type === BookingTypes.BOOKING_STATE_OWNER_UNCHECKED
  )
}
const isCustomer = (type, state) => {
  if (state) {
    switch (state) {
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_CUSTOMER:
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_CUSTOMER_WITH_CLEAN:
        return true
      default:
        return false
    }
  }

  return type === BookingTypes.BOOKING_TYPE_CUSTOMER
}

const isCustomerConfirmed = (booking) => {
  if (booking?.state) {
    return booking.state === BookingTypes.CONSOLIDATED_BOOKING_STATE_CUSTOMER
  }

  const state = booking && booking.bookingState
  const type = booking && booking.reservationType
  return state === BookingTypes.BOOKING_STATE_CONFIRMED && isCustomer(type)
}

const isOwnerConfirmed = (booking) => {
  if (booking?.state) {
    return booking.state === BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER
  }

  const state = booking && booking.bookingState
  const type = booking && booking.reservationType
  return state === BookingTypes.BOOKING_STATE_CONFIRMED && isOwner(type)
}
const isOwnerCommissionable = (type, state) => {
  if (state) {
    return state === BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER_COMMISSION
  }

  return type === BookingTypes.BOOKING_STATE_OWNER_COMMISSIONABLE
}
const isOwnerReserved = (type, state) => {
  if (state) {
    return state === BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER_RESERVED
  }

  return type === BookingTypes.BOOKING_STATE_OWNER_RESERVED
}
const isOwnerUnchecked = (type, state) => {
  if (state) {
    return state === BookingTypes.CONSOLIDATED_BOOKING_STATE_UNCHECKED_OWNER
  }

  return type === BookingTypes.BOOKING_STATE_OWNER_UNCHECKED
}
const isOwnerProvisional = (type, state) => {
  if (state) {
    return state === BookingTypes.CONSOLIDATED_BOOKING_STATE_PROVISIONAL
  }

  return type === BookingTypes.BOOKING_STATE_PROVISIONAL
}

const isCancelled = (type, state) => {
  if (state) {
    return state === BookingTypes.CONSOLIDATED_BOOKING_STATE_CANCELLED
  }

  return type === BookingTypes.BOOKING_STATE_CANCELLED
}

const isExpired = (type, state) => {
  if (state) {
    return state === BookingTypes.CONSOLIDATED_BOOKING_STATE_EXPIRED
  }

  return type === BookingTypes.BOOKING_STATE_EXPIRED
}

const getTypeName = (reservationType) => {
  const type = Object.values(BookingTypes).find((x) => x === reservationType)

  return type || splitCamelCase(reservationType)
}

const hasFinancialAuthorisations = (auth) => {
  return !!(auth && auth.find((a) => a.name === 'viewBookingFinancials'))
}

const getBookingColor = (bookingType) => {
  switch (bookingType) {
    case BookingTypes.BOOKING_TYPE_CUSTOMER:
    case BookingTypes.BOOKING_TYPE_CUSTOMER_CLEAN:
      return theme.colors.customer
    case BookingTypes.BOOKING_TYPE_OWNER:
    case BookingTypes.BOOKING_TYPE_OWNER_CLEAN:
    case BookingTypes.BOOKING_STATE_OWNER_COMMISSIONABLE:
    case BookingTypes.BOOKING_STATE_OWNER_RESERVED:
    case BookingTypes.BOOKING_STATE_OWNER_UNCHECKED:
      return theme.colors.owner
    case 'closed':
      return theme.colors.closed
    case BookingTypes.BOOKING_STATE_PROVISIONAL:
      return theme.colors.provisional
    case BookingTypes.BOOKING_TYPE_CANCELLED:
      return theme.colors.cancelled
    case BookingTypes.BOOKING_TYPE_OTHER:
      return theme.gradient.candyCane

    default:
      break
  }
}

const getExportBookingStateKey = (bookingState) => {
  switch (bookingState) {
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_CUSTOMER:
      return BookingTypes.CONSOLIDATED_BOOKING_STATE_GUEST_CONFIRMED
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER:
      return BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER_CONFIRMED
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_OTHER:
      return BookingTypes.CONSOLIDATED_BOOKING_STATE_OTHER
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_CHANGEOVER:
      return BookingTypes.CONSOLIDATED_BOOKING_STATE_CHANGEOVER
    case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER_AVAILABLE:
      return BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER_AVAILABLE
    default:
      return bookingState
  }
}

const getAdultCountTextDisplayForBooking = (
  adultCount,
  singleAdultText,
  pluralAdultText
) => {
  if (adultCount === 1) {
    return `${adultCount} ${singleAdultText}`
  } else if (adultCount > 1) {
    return `${adultCount} ${pluralAdultText}`
  }

  return ''
}

const getChildCountTextDisplayForBooking = (
  childCount,
  singleChildText,
  pluralChildText
) => {
  if (childCount === 1) {
    return `, ${childCount} ${singleChildText}`
  } else if (childCount > 1) {
    return `, ${childCount} ${pluralChildText}`
  }

  return ''
}

const getInfantCountTextDisplayForBooking = (
  infantCount,
  singleInfantText,
  pluralInfantText
) => {
  if (infantCount === 1) {
    return `, ${infantCount} ${singleInfantText}`
  } else if (infantCount > 1) {
    return `, ${infantCount} ${pluralInfantText}`
  }

  return ''
}

const getAttributeCountTextDisplayForBooking = (
  attributeCount,
  singleText,
  pluralText
) => {
  if (attributeCount === 1) {
    return `, ${attributeCount} ${singleText}`
  } else if (attributeCount > 1) {
    return `, ${attributeCount} ${pluralText}`
  }

  return ''
}

const getConsolidatedBookingColor = (bookingState) => {
  if (bookingState) {
    switch (bookingState) {
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_CUSTOMER:
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_CUSTOMER_WITH_CLEAN:
        return theme.colors.customer
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER:
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER_WITH_CLEAN:
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER_COMMISSION:
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER_RESERVED:
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_UNCHECKED_OWNER:
        return theme.colors.owner
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_PROVISIONAL:
        return theme.colors.provisional
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_CANCELLED:
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_EXPIRED:
        return theme.colors.cancelled
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_OTHER:
        return theme.gradient.candyCane
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_CHANGEOVER:
        return theme.colors.changeOver
      case BookingTypes.CONSOLIDATED_BOOKING_STATE_OWNER_AVAILABLE:
        return theme.colors.owner
      default:
        break
    }
  }
}

export {
  getBookingState,
  getBookingType,
  getBookingText,
  isOwner,
  getTypeName,
  getBookingColor,
  isCustomer,
  isOwnerCommissionable,
  isOwnerProvisional,
  isCustomerConfirmed,
  isOwnerConfirmed,
  isOwnerReserved,
  isOwnerUnchecked,
  isCancelled,
  isExpired,
  hasFinancialAuthorisations,
  getConsolidatedBookingColor,
  getExportBookingStateKey,
  getAdultCountTextDisplayForBooking,
  getChildCountTextDisplayForBooking,
  getInfantCountTextDisplayForBooking,
  getAttributeCountTextDisplayForBooking
}

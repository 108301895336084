import React, { useCallback } from 'react'
import i18n from '../../i18n'
import { getLocale, setLocale } from '@/helpers/locale'

const locales = ['en-GB', 'da', 'es', 'fr', 'hr', 'it', 'nb', 'sv', 'de', 'nl', 'pl', 'sl', 'debug']

export function LanguageSwitcher () {
  const handleChange = useCallback((event) => {
    const changeLocale = async () => {
      const locale = event.target.value
      await setLocale(locale)
      i18n.changeLanguage(locale)
    }

    changeLocale()
  }, [])

  return (
    <select defaultValue={getLocale()} onChange={handleChange}>
      {locales.map(locale => (
        <option key={locale} value={locale}>{locale}</option>
      ))}
    </select>
  )
}

import dayjs from 'dayjs'
import { createEvents } from 'ics'
import { saveAs } from 'file-saver'
import { CONSOLIDATED_BOOKING_STATE_CUSTOMER } from '@/constants/bookings'
import { getLocale } from '@/helpers/locale'

const createICSFile = (filename, events) => {
  const calendarEvents = createEvents(events)

  if (calendarEvents.error) return { error: calendarEvents.error }

  try {
    const file = { filename: `${filename}.ics`, content: calendarEvents.value }
    const mimeType = 'text/calendar'
    const blob = file.content instanceof Blob ? file.content : new Blob([file.content], { type: `${mimeType}` })

    return { file: blob, download: () => saveAs(blob, filename) }
  } catch (e) {
    return { error: e.message }
  }
}

const formatDateICS = (d) => {
  const date = dayjs(d)
  return [date.year(), date.month() + 1, +date.format('D'), 12, 0]
}

const createICSEventFromBooking = (booking, siteUser, t, isUserBrandNovasol) => {
  const getLocationDisplayNameForBooking = (booking) => {
    if (siteUser) {
      return booking.siteName + ', ' + booking.propertyName
    }

    return booking.propertyName
  }

  const bookingType = booking.state

  const getSpecialRequirements = () => {
    if (bookingType === CONSOLIDATED_BOOKING_STATE_CUSTOMER) {
      const hasItems = Array.isArray(booking.specialRequirements) && !!booking.specialRequirements.length
      const noItems = booking.specialRequirements === true ? t('export:ics:hasSpecialRequirements') : t('export:ics:noSpecialRequirements')
      return `\n${t('export:ics:specialRequirements')}: ${hasItems ? booking.specialRequirements.join(', ') : noItems}`
    } else {
      return ``
    }
  }

  const getContactDetails = () => {
    if (bookingType === CONSOLIDATED_BOOKING_STATE_CUSTOMER) {
      const { leadGuest, email, mobile, telephone, translatedPartyDescription } = booking

      return `\n${t('name')}: ${leadGuest}` +
      `\n${t('emailAddress')}: ${email || ``}` +
      `\n${t('mobile')}: ${mobile || ``}` +
      `\n${t('telephone')}: ${telephone || ``}` +
      `\n${t('party')}: ${translatedPartyDescription}`
    } else {
      return ``
    }
  }

  const getRepeatBooker = () => {
    if (booking.isRepeatLeadGuest) {
      return `\n${t('export:repeatBooker')}`
    } else {
      return ``
    }
  }

  const getAmountWithCurrency = (key) => booking[key] ? `${booking[key].toLocaleString(getLocale())} ${booking.currency}` : 'none'

  const getPaymentDetails = (siteUser) => {
    if (bookingType === CONSOLIDATED_BOOKING_STATE_CUSTOMER && !siteUser) {
      return `\n${t('export:ics:paymentDue')}: ${getAmountWithCurrency('ownerIncome')}`
    } else {
      return ``
    }
  }

  const getExcessCharge = (siteUser, booking) => {
    if (!siteUser && !booking.hideBookingFinancialDetail && booking.ownerChargeAmount && booking.ownerChargeAmount !== 0) {
      return `${t('export:ics:excessAmountDue')}: ${getAmountWithCurrency('ownerChargeAmount')}`
    }
    return ''
  }

  const getBookingExtras = () => {
    const extras = booking.extras

    if (!extras || extras.length === 0) {
      return `${t('export:ics:bookingExtras')}: ${(t('export:ics:noExtras'))}`
    }

    const extrasDescription = extras
      .filter(({ quantity }) => quantity > 0)
      .map(({ name, quantity, cost, id }) => {
        const translationKey = id ? `extras${id}` : name
        const translatedName = t(translationKey, { defaultValue: name })
        const costDisplay = cost ? ` - ${cost} ${booking.currency}` : ''

        return `${translatedName} - ${quantity}${costDisplay}`
      })
      .join(', ')

    return `${t('export:ics:bookingExtras')}: ${extrasDescription}`
  }

  const getBookingCosts = (siteUser, booking) => {
    if (siteUser && !booking.hideBookingFinancialDetail) {
      return `${t('export:ics:accommodationCost')} : ${booking.holidayPrice.toLocaleString(getLocale())} ${booking.currency}` +
      `\n${t('export:ics:extrasCost')} : ${booking.extrasSellPrice.toFixed(2)} ${booking.currency}\n`
    }

    return ''
  }

  const getThirdPartyReference = (booking) => {
    if (!booking.pmsReference) { return '' }

    return `${t('export:ics:pmsBookingRef')}: ${booking.pmsReference}`
  }

  const nightLabel = booking.nights > 1 ? t('export:ics:nights') : t('export:ics:night')
  const bookingNights = `${booking.nights} ${nightLabel}`

  const constructBookingMadeString = (booking) => {
    if (!booking.bookedOnDate || isNaN(Date.parse(booking.bookedOnDate))) {
      return ''
    }

    const formattedDate = dayjs(booking.bookedOnDate).format('Do MMMM YYYY')
    const leadGuestText = booking.leadGuest ? t('export:ics:bookingMadeBy', { name: booking.leadGuest }) : ''

    return `${formattedDate}${leadGuestText}`
  }

  const bookingMade = constructBookingMadeString(booking)

  return {
    title: `${!isNaN(booking.bookingId) ? booking.bookingId + ' - ' : ''} ${(bookingType === CONSOLIDATED_BOOKING_STATE_CUSTOMER && booking.leadGuest) ? booking.leadGuest : `${booking.translatedUserFriendlyBookingState} ${t('booking')}`}`,
    start: formatDateICS(booking.startDate),
    end: formatDateICS(booking.endDate),
    startOutputType: 'local',
    location: getLocationDisplayNameForBooking(booking),
    description: [
      !isNaN(booking.bookingId) ? `${t('bookingRef')}: ${booking.bookingId}` : '',
      siteUser || isUserBrandNovasol ? `${t('export:unitCode')}: ${booking.unitCode}` : '',
      t('export:ics:bookingMadeFor', { duration: bookingNights }),
      bookingMade ? `${t('export:ics:bookingMadeOn', { date: bookingMade })}` : '',
      getContactDetails() || '',
      getRepeatBooker() || '',
      getSpecialRequirements() || '',
      getPaymentDetails(siteUser) || '',
      getExcessCharge(siteUser, booking) || '',
      getBookingExtras() || '',
      getBookingCosts(siteUser, booking) || '',
      getThirdPartyReference(booking) || ''
    ].filter(line => line.trim() !== '').join('\n')
  }
}

export { createICSFile, createICSEventFromBooking, formatDateICS }

import React from 'react'
import PropTypes from 'prop-types'
import {
  Section,
  Year,
  SeasonsContainer,
  SeasonItem,
  SeasonType,
  SeasonContent,
  SeasonContentItem,
  SeasonContentItemTitle,
  SeasonContentItemText
} from './BookingAllowanceListItem.styles'

const BookingAllowanceListItem = ({item}) => {
  const { year, seasons } = item
  return (
    <Section>
      {year && <Year>{year}</Year>}
      <SeasonsContainer>
        {seasons && seasons.map(({seasonType, overallAllowance, totalUsed, amountRemaining}, key) => (
          <SeasonItem key={key}>
            <SeasonType type={seasonType.toLowerCase()}>{seasonType}</SeasonType>
            <SeasonContent>
              {overallAllowance &&
                <SeasonContentItem>
                  <SeasonContentItemTitle>Overall Allowance:</SeasonContentItemTitle>
                  <SeasonContentItemText>{overallAllowance}</SeasonContentItemText>
                </SeasonContentItem>
              }
              {totalUsed &&
                <SeasonContentItem>
                  <SeasonContentItemTitle>Total Used:</SeasonContentItemTitle>
                  <SeasonContentItemText>{totalUsed}</SeasonContentItemText>
                </SeasonContentItem>
              }
              {amountRemaining && (overallAllowance && overallAllowance.toLowerCase() !== 'unlimited') &&
                <SeasonContentItem>
                  <SeasonContentItemTitle>Amount Remaining:</SeasonContentItemTitle>
                  <SeasonContentItemText>{amountRemaining}</SeasonContentItemText>
                </SeasonContentItem>
              }
            </SeasonContent>
          </SeasonItem>
        ))}
      </SeasonsContainer>
    </Section>
  )
}

BookingAllowanceListItem.propTypes = {
  item: PropTypes.shape({
    year: PropTypes.string.isRequired,
    seasons: PropTypes.arrayOf(PropTypes.shape({
      seasonType: PropTypes.string.isRequired,
      overallAllowance: PropTypes.string,
      totalUsed: PropTypes.string,
      amountRemaining: PropTypes.string
    }))
  })
}

export default BookingAllowanceListItem

import React from 'react'
import PropTypes from 'prop-types'
import { StyledTableCollapse } from '../Table.styles'

const TableCollapse = ({ children, className = '', isOpen }) => (
  <StyledTableCollapse className={`table-collapse ${className}`} isOpen={isOpen}>
    <div className="table-collapse-inner">
      {children}
    </div>
  </StyledTableCollapse>
)

TableCollapse.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool
}

export default TableCollapse

import React, { createContext, useContext, useState } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { addDaysToDate } from '@/helpers/dateUtils'

const START_DATE_OFFSET = -2
const END_DATE_OFFSET = 32

export const BookingChartDatesContext = createContext({
  highlightedDate: {},
  startDate: {},
  numberOfDays: {},
  setStartDate: Function,
  dateForBoundaries: {},
  todaysDate: {}
})
export const useBookingChartDatesContext = () => useContext(BookingChartDatesContext)

export const BookingChartDatesProvider = ({ children }) => {
  const today = dayjs()
  const defaultStartDate = dayjs().add(START_DATE_OFFSET, 'day')
  const todaysDate = new Date(today.year(), today.month(), today.date())

  const [highlightedDate, setHighlightedDate] = useState(new Date(today.year(), today.month(), today.date()))
  const [startDate, setStartDate] = useState(new Date(defaultStartDate.year(), defaultStartDate.month(), defaultStartDate.date()))
  const [numberOfDays] = useState(END_DATE_OFFSET)
  const [dateForBoundaries] = useState(new Date(defaultStartDate.year(), defaultStartDate.month(), defaultStartDate.date()))

  const onChangeStartDate = (newStartDate) => {
    const newDate = new Date(newStartDate)
    setHighlightedDate(newDate)
    setStartDate(addDaysToDate(newDate, START_DATE_OFFSET))
  }

  return (
    <BookingChartDatesContext.Provider value={{
      highlightedDate: highlightedDate,
      todaysDate: todaysDate,
      startDate: startDate,
      numberOfDays: numberOfDays,
      setStartDate: onChangeStartDate,
      dateForBoundaries: dateForBoundaries
    }}
    >
      {children}
    </BookingChartDatesContext.Provider>
  )
}

BookingChartDatesProvider.propTypes = {
  children: PropTypes.object
}

import React from 'react'
import PropTypes from 'prop-types'
import { Container, Spinner } from '@/components/__UI'
import { PropertyImagerySection } from '@/components/__PropertyAmendsComponents'
import { ItemsContainer } from './PropertyImagery.style'
import useGraphQl, { GET_PROPERTY_IMAGERY } from '@/hooks/useGraphQl'

const PropertyImagery = ({propertyId}) => {
  const [ imageValues, loadingImageryValues ] = useGraphQl({
    query: GET_PROPERTY_IMAGERY,
    variables: {
      propertyId
    }
  })

  if (loadingImageryValues) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  if (!imageValues) {
    return <p>{`Unable to find this property's images.`}</p>
  }

  return (
    imageValues && imageValues.length > 0
      ? <ItemsContainer>
        {imageValues.sort((a, b) => a.order - b.order).map((item, x) => <PropertyImagerySection key={x} item={item} propertyId={propertyId} />)}
      </ItemsContainer>
      : <p>{`This property currently has no images.`}</p>
  )
}

PropertyImagery.propTypes = {
  propertyId: PropTypes.string,
  showEdit: PropTypes.bool
}

export default PropertyImagery

/* eslint-disable react/prop-types */
import React from 'react'
import { Oval } from 'react-loader-spinner'
import { consts } from '../../../global/Theme'

interface OvalLoadingSpinnerProps {
  size?: 'small' | 'standard';
  style?: React.CSSProperties;
  className?: string;
}

const OvalLoadingSpinner: React.FunctionComponent<OvalLoadingSpinnerProps> = ({
  size = 'standard',
  style,
  className
}) => {
  const sizeAttributes = size === 'small' ? { height: 24, width: 24 } : { height: 32, width: 32 }

  return (
    <div
      className={className}
      data-testid="spinner"
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        ...style
      }}
    >
      <Oval
        visible={true}
        color={consts.colors.buttonSolidPrimaryHover}
        secondaryColor="lightgrey"
        ariaLabel="oval-loading"
        {...sizeAttributes}
      />
    </div>
  )
}

export default OvalLoadingSpinner

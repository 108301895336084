import PropTypes from 'prop-types'
import TableRow from './components/TableRow'
import TableCell from './components/TableCell'
import Spinner from '@/components/__UI/Spinner'
import TableHeader from './components/TableHeader'
import { LoadArea, StyledTable } from './Table.styles'
import TableHeadCell from './components/TableHeadCell'
import React, { createContext, useContext, useState } from 'react'

const TableContext = createContext(null)

const useTable = () => {
  const context = useContext(TableContext)
  if (context === undefined) {
    throw new Error('`useTable` hook must be used within a Table component')
  }

  return context
}

const Table = ({ children, className = '', columns, data, isLoading, noItemsText, unsortedData }) => {
  const [ sortedID, setSortedID ] = useState(null)
  const [ hasCollapsible, setHasCollapsible ] = useState(false)

  if (isLoading) {
    return (
      <LoadArea>
        <Spinner large />
      </LoadArea>
    )
  }

  if (data && data.length === 0) {
    return (
      <StyledTable>
        {noItemsText || <p>No items found</p>}
      </StyledTable>
    )
  }

  return (
    <TableContext.Provider value={{
      columns,
      sortedID,
      setSortedID,
      hasCollapsible,
      setHasCollapsible,
      unsortedData
    }}>
      <StyledTable className={`${className} cols-${columns}`}>
        {children}
      </StyledTable>
    </TableContext.Provider>
  )
}

Table.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  columns: PropTypes.number.isRequired,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  noItemsText: PropTypes.node,
  unsortedData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

Table.Row = TableRow
Table.Cell = TableCell
Table.Header = TableHeader
Table.HeadCell = TableHeadCell

export { useTable }
export default Table

import { memoize } from '@/helpers/memoize'
import dayjs from 'dayjs'

export const generateUserFriendlyDateFormatMemoized = memoize(generateUserFriendlyDateFormat)

export function generateUserFriendlyDateFormat(isoDate) {
  const date = dayjs(isoDate)
  return date.format('DD-MMM-YYYY')
}

export function formatShortMonthDate(dateStr) {
  return dayjs(dateStr).format('DD MMM YYYY')
}

export function formatToNumericDate(dateStr) {
  return dayjs(dateStr).format('DD/MM/YYYY')
}


export function getIsoDateWithoutTimezoneMapping(date) {
  const isoDate = dayjs(date)
  return isoDate.format('YYYY-MM-DD')
}

export function getIsoDate(date) {
  return dayjs(date).format('YYYY-MM-DD')
}

export function removeTimeFromIsoDateString(dateString) {
  return dayjs(dateString).format('YYYY-MM-DD')
}


export function isToday(date) {
  return dayjs(date).isSame(dayjs(), 'day')
}

export function addDaysToDate(date, daysToAdd) {
  return dayjs(date).add(daysToAdd, 'day').toDate()
}

export function deductDaysFromDate(date, daysToDeduct) {
  return dayjs(date).subtract(daysToDeduct, 'day').toDate()
}


export function getUserFriendlyDayFromDate(date) {
  return dayjs(date).format('dd')
}


export function getUserFriendlyMonthFromDate(date) {
  return dayjs(date).format('MMM')
}

export function getUserFriendlyDateNumberFromDate(date) {
  return dayjs(date).format('DD')
}

import styled from 'styled-components'
import { getConsolidatedBookingColor, getBookingColor } from '@/helpers/bookings'
import IconClosed from '@/assets/icons/IconClosed.svg'
import { Heading } from '@awaze/design-system.components.text'

const BaseBookingType = styled.div<{
  bookingType?: string;
  bookingState?: string;
}>`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: 0.5rem;
  position: relative;
`;

export const BookingType = styled(BaseBookingType)`
  background: ${({ bookingType, bookingState }) =>
    bookingState
      ? getConsolidatedBookingColor(bookingState)
      : getBookingColor(bookingType)};
`;

export const BookingTypeIcon = styled(BaseBookingType)`
  background-image: ${({ bookingType }) =>
    bookingType === 'Closed' ? `url("${IconClosed}")` : 'none'};
  background-size: cover;
`;

export const BookingStateAvailableDays = styled(BaseBookingType)`
  outline: 1px dashed hsla(335, 44%, 37%, 1);
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const ModalContentWrapper = styled.div`
  margin-bottom: 32px;
`
export const StyledHeader = styled(Heading)`
  font-size: 1.5rem;

  @media (max-width: 600px) {
    font-size: 1.25rem; 
  }
`

import React from 'react'

const SheetSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1918_72)">
      <path fillRule="evenodd" clipRule="evenodd" d="M18.5693 1.7129C18.4292 1.57629 18.2411 1.49989 18.0454 1.5L3 1.5C2.80109 1.5 2.61032 1.57902 2.46967 1.71967C2.32902 1.86032 2.25 2.05109 2.25 2.25V21.75C2.25 21.9489 2.32902 22.1397 2.46967 22.2803C2.61032 22.421 2.80109 22.5 3 22.5H21C21.1989 22.5 21.3897 22.421 21.5303 22.2803C21.671 22.1397 21.75 21.9489 21.75 21.75V5.13307C21.75 5.13305 21.75 5.1331 21.75 5.13307C21.75 5.033 21.7299 4.93388 21.691 4.84167C21.6521 4.74944 21.5952 4.66594 21.5235 4.59607L18.5693 1.7129C18.5694 1.71293 18.5693 1.71286 18.5693 1.7129ZM18.045 1.60473e-07C18.6322 -0.00022145 19.1962 0.229094 19.6166 0.639L22.5705 3.52193C22.7855 3.73153 22.9564 3.98205 23.0731 4.25872C23.1898 4.5354 23.25 4.83264 23.25 5.13293V21.75C23.25 22.3467 23.0129 22.919 22.591 23.341C22.169 23.7629 21.5967 24 21 24H3C2.40326 24 1.83097 23.7629 1.40901 23.341C0.987053 22.919 0.75 22.3467 0.75 21.75V2.25C0.75 1.65326 0.987053 1.08097 1.40901 0.65901C1.83097 0.237053 2.40326 1.60473e-07 3 1.60473e-07H18.045C18.0451 1.60473e-07 18.0449 1.60473e-07 18.045 1.60473e-07Z" fill="#506F8B"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.29492 8.25C5.29492 7.83579 5.63071 7.5 6.04492 7.5H18.0449C18.4591 7.5 18.7949 7.83579 18.7949 8.25C18.7949 8.66421 18.4591 9 18.0449 9H6.04492C5.63071 9 5.29492 8.66421 5.29492 8.25Z" fill="#506F8B"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.29492 12.75C5.29492 12.3358 5.63071 12 6.04492 12H18.0449C18.4591 12 18.7949 12.3358 18.7949 12.75C18.7949 13.1642 18.4591 13.5 18.0449 13.5H6.04492C5.63071 13.5 5.29492 13.1642 5.29492 12.75Z" fill="#506F8B"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.29492 17.25C5.29492 16.8358 5.63071 16.5 6.04492 16.5H12.0449C12.4591 16.5 12.7949 16.8358 12.7949 17.25C12.7949 17.6642 12.4591 18 12.0449 18H6.04492C5.63071 18 5.29492 17.6642 5.29492 17.25Z" fill="#506F8B"/>
    </g>
    <defs>
      <clipPath id="clip0_1918_72">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </svg>

)

export default SheetSvg

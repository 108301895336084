import React, { useEffect, useMemo, useState } from 'react'
import { OvalLoadingSpinner } from '@/components/__UI'
import { GridWrapMobile, StyledPreviousButton } from './BookingCalendar.styles'
import dayjs from 'dayjs'
import Calendar from '@/components/Calendar'
import { useInfiniteQueryBookings } from '@/hooks/useQuery'
import { CONSOLIDATED_BOOKING_STATE_CANCELLED } from '@/constants/bookings'
import { InfiniteScrollTrigger } from '@/components/InfiniteScrollTrigger'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useUserBrand } from '@/hooks/useUserBrand/useUserBrand'


export function BookingCalendarMonth ({
  year,
  yearCalendar,
  propertyId,
  unitId,
  setSelectedDay,
  setSelectedBookingID,
  hasAuth,
  setBookings,
  setClosures,
  contractExpiry,
  showWeekNumber,
  showAvailability
}) {
  const { t } = useTranslation()
  const enabled = !yearCalendar && !!propertyId
  const [ hoveredID, setHoveredID ] = useState(null)

  const {
    data,
    fetchPreviousPage,
    fetchNextPage,
    hasPreviousPage,
    isFetchingPreviousPage,
    isFetchingNextPage
  } = useInfiniteQueryBookings({ year, yearCalendar, propertyId, unitId, options: { enabled } })

  const pages = data?.pages
  const closures = useMemo(() => pages?.flatMap(page => page.closures), [pages])
  const availability = useMemo(() => {
    const bookabilityState = pages?.[0]?.availability?.bookabilityState
    const bookableDates = pages?.flatMap(page => page.availability.bookableDates) ?? []
    const availableDays = bookabilityState === 'onlyReturnedDatesBookable'
    return { bookabilityState, bookableDates, availableDays }
  }, [pages])

  const bookings = useMemo(() => {
    let bookings = pages?.flatMap(page => page.bookings.bookings) ?? []
    bookings = bookings.filter(x => x.state !== CONSOLIDATED_BOOKING_STATE_CANCELLED)

    const uniqueBookings = new Map()
    for (const booking of bookings) {
      uniqueBookings.set(booking.bookingId, booking)
    }
    bookings = Array.from(uniqueBookings.values())
    return bookings
  }, [pages])

  useEffect(() => {
    if (enabled) setBookings(bookings)
  }, [enabled, bookings, setBookings])

  useEffect(() => {
    if (enabled) setClosures(closures)
  }, [enabled, closures, setClosures])

  const monthsList = useMemo(() => {
    if (!(pages?.length > 0)) return []
    const startDate = pages[0].bookings?.fromDate
    const endDate = pages[pages.length - 1].bookings.toDate

    let currentMonth = dayjs(startDate).add(1, 'month')
    const lastMonth = dayjs(endDate)

    const monthsList = []

    while (currentMonth.isBefore(lastMonth) || currentMonth.isSame(lastMonth, 'month')) {
      monthsList.push(currentMonth.format('YYYY-MM-DD'))
      currentMonth = currentMonth.add(1, 'month')
    }
    return monthsList
  }, [pages])

  const isUserBrandNovasol = useUserBrand()

  return (
    <>
      { isFetchingPreviousPage ? (
        <OvalLoadingSpinner/>
      ) : (
        hasPreviousPage && (
          <StyledPreviousButton
            iconPosition="left"
            onClick={() => {
              fetchPreviousPage()
            }}
            text={t('showPreviousMonths')}
            colourScheme="brand-primary"
            variant="text"
            hasSwitcher={isUserBrandNovasol}
          />
        )
      )}
      <GridWrapMobile style={{ marginLeft: '-1rem', marginRight: '-1rem' }}>
        {monthsList.map(date => {
          const segments = bookings ? bookings.filter(b => {
            const startDate = dayjs(b.startDate)
            const endDate = dayjs(b.endDate)
            const firstDayOfMonth = dayjs(date).startOf('month')
            const lastDayOfMonth = dayjs(date).endOf('month')

            const overlapsCurrentMonth = startDate.isBefore(lastDayOfMonth, 'day') && endDate.isAfter(firstDayOfMonth, 'day')
            const isSameMonth = startDate.isSame(date, 'month') || endDate.isSame(date, 'month')

            return isSameMonth || overlapsCurrentMonth
          }) : []

          return (
            <div key={date} data-testid={`month-${dayjs(date).format('MMMM')}`}>
              <Calendar
                date={dayjs(date)}
                onDayClick={setSelectedDay}
                onSegmentClick={({ id }) => setSelectedBookingID(id)}
                segments={segments.map(item => ({
                  id: item.bookingId,
                  state: item.state,
                  startDate: item.startDate,
                  endDate: item.endDate
                }))}
                unavailableDates={[...(closures || []), contractExpiry]}
                bookingAvailability={availability}
                disabled={!hasAuth('createBooking')}
                yearCalendar={yearCalendar}
                showWeekNumber={showWeekNumber}
                showAvailability={showAvailability && availability.availableDays}
                hoveredID={hoveredID}
                setHoveredID={setHoveredID}
              />
            </div>
          )
        })}
        {isFetchingNextPage && <OvalLoadingSpinner/>}
        <InfiniteScrollTrigger onVisible={() => fetchNextPage()} style={{ width: '100%' }}/>
      </GridWrapMobile>
    </>
  )
}

BookingCalendarMonth.propTypes = {
  year: PropTypes.number.isRequired,
  yearCalendar: PropTypes.bool,
  propertyId: PropTypes.string,
  unitId: PropTypes.string,
  setSelectedDay: PropTypes.func.isRequired,
  setSelectedBookingID: PropTypes.func.isRequired,
  hasAuth: PropTypes.func.isRequired,
  setBookings: PropTypes.func.isRequired,
  setClosures: PropTypes.func.isRequired,
  contractExpiry: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startDateIso: PropTypes.string,
    endDateIso: PropTypes.string
  }),
  showWeekNumber: PropTypes.bool,
  showAvailability: PropTypes.bool

}

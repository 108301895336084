const CREATE_BOOKING = `mutation CreateBooking($input: CreateBookingInput!) {
    createBooking(input: $input) {
      bookingId
      bookingType
      bookingState
      bookingMadeByName
      bookedOnDate
      startDate
      endDate
      duration
      party {
        partyId
        adultsCount
        childrenCount
        infantsCount
        petsCount
        partyLead {
          customerId
          longName
          contacts {
            channel
            value
          }
        }
      }
      specialRequirements
      salesChannel
      propertyId
      hasNotificationSent
      hasSuppressedNotification
      unitId
    }
  }
  `

const UPDATE_PERMISSIONS = `mutation UpdateCaretakerPermissions($input: UpdateCaretakerPermissionsInput!) {
    updateCaretakerPermissions(input: $input)
  }
`

const CANCEL_BOOKING = `mutation CancelBooking($input: CancelBookingInput!) {
    cancelBooking(input: $input) {
      cancelled
      hasNotificationSent
      hasSuppressedNotification
    }
  }
`

const FEEDBACK_RESPOND = `mutation ReviewResponse($input: ReviewResponseInput!) {
    respondToReview(input: $input) {
      success
      profanityWords
      legalWords
    }
  }
`

const CREATE_USER_CASE = `mutation CreateUserCase($input: CreateUserCaseInput) {
  createUserCase(input: $input) {
    success
  }
}`

const UPDATE_PROPERTY_DESCRIPTION = `mutation UpdatePropertyDescription($input: UpdatePropertyDescriptionInput) {
  updatePropertyDescription(input: $input) {
    success
  }
}`

const UPDATE_PROPERTY_ARRIVAL_DETAILS = `mutation UpdatePropertyArrivalDetails($input: UpdatePropertyArrivalDetailsInput) {
  updatePropertyArrivalDetails(input: $input) {
    success
  }
}`

const UPDATE_PROPERTY_FEATURES = `mutation UpdatePropertyFeatures($input: UpdatePropertyFeaturesInput) {
  updatePropertyFeatures(input: $input) {
    success
  }
}`

const UPDATE_PROPERTY_IMAGERY = `mutation UpdatePropertyImagery($input: UpdatePropertyImageryInput) {
  updatePropertyImagery(input: $input) {
    success
  }
}`

const UPDATE_USER_POSTAL_STATEMENTS = `mutation UpdateUserPostalStatements($input: UpdateUserPostalStatementsInput) {
  updateUserPostalStatements(input: $input) {
    success
  }
}`

const UPDATE_USER_ACCOUNT = `mutation UpdateUserAccount($input: UpdateUserAccountInput) {
  updateUserAccount(input: $input) {
    success
  }
}`

const UPDATE_USER_CONTRACT = `mutation UpdateUserContract($input: UpdateUserContractInput) {
  updateUserContract(input: $input) {
    success
  }
}`

const ADD_USER_ADMIN = `mutation AddUserAdmin($input: AddUserAdminInput) {
  addUserAdmin(input: $input) {
    success
  }
}`

const UPDATE_USER_ADMIN = `mutation UpdateUserAdmin($input: UpdateUserAdminInput) {
  updateUserAdmin(input: $input) {
    success
  }
}`

const DELETE_USER_ADMIN = `mutation deleteUserAdmin($input: DeleteUserAdminInput) {
  deleteUserAdmin(input: $input) {
    success
  }
}`

export {
  CREATE_BOOKING, UPDATE_PERMISSIONS,
  CANCEL_BOOKING, FEEDBACK_RESPOND, CREATE_USER_CASE,
  UPDATE_PROPERTY_DESCRIPTION, UPDATE_PROPERTY_ARRIVAL_DETAILS,
  UPDATE_PROPERTY_FEATURES, UPDATE_PROPERTY_IMAGERY,
  UPDATE_USER_POSTAL_STATEMENTS, UPDATE_USER_ACCOUNT,
  UPDATE_USER_CONTRACT, UPDATE_USER_ADMIN,
  ADD_USER_ADMIN, DELETE_USER_ADMIN
}

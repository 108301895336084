import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import { apiFetch } from '../helpers'

type ClosureSearchInput = {
  propertyId: string;
  unitId: string;
  startDate: string;
  endDate: string;
}

type Closure = {
  startDate: string;
  endDate: string;
  startDateIso: string;
  endDateIso: string;
}

type Closures = Closure[]

export const queryKeyClosures = (input: ClosureSearchInput) => ['closures', input]

export const queryFnClosures = (input: ClosureSearchInput) => {
  if (!input.propertyId || !input.unitId) {
    return []
  }

  const url = getSearchURL(input)
  return apiFetch(url)
}

export function useQueryClosures (input: ClosureSearchInput, options?: UseQueryOptions<Closures>) {
  const queryKey = queryKeyClosures(input)

  const queryFn = () => queryFnClosures(input)

  return useQuery<Closures>(queryKey, queryFn, {
    staleTime: 4000,
    cacheTime: 0,
    retry: 0,
    enabled: input.propertyId != null,
    ...options
  })
}

function getSearchURL (parameters: ClosureSearchInput): string {
  return `v1/properties/${parameters.propertyId}/closures?${getSearchParameters(parameters)}`
}

function getSearchParameters (parameters: ClosureSearchInput): string {
  const search = new URLSearchParams()

  if (parameters.startDate) search.append('fromDate', parameters.startDate)
  if (parameters.endDate) search.append('toDate', parameters.endDate)
  if (parameters.endDate) search.append('unitId', parameters.unitId)

  return search.toString()
}

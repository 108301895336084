import { ChevronLeft, ChevronRight } from '@awaze/design-system.components.icon'
import styled from 'styled-components'

export const YearPickerContainer = styled.div<{ $gap?: string }>`
    display: flex;
    align-items: center;
    gap: ${props => props.$gap ?? '8px'};
`

export const ChevronButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: block;
`

export const StyledChevronLeft = styled(ChevronLeft)`
    display: block;
`
export const StyledChevronRight = styled(ChevronRight)`
    display: block;
`
import ReactModal from 'styled-react-modal'
import styled from 'styled-components'

export const Wrapper = ReactModal.styled`
    background-color: ${({theme}) => theme.colors.white};
    position: relative;
    padding: 1.5rem;
    width: 80vw;
    max-width: 80rem;
    border-radius: ${({theme}) => theme.borderRadius};
    max-height: calc(100dvh - 4rem);
    overflow-y: auto;

    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
    
    
    ${({theme}) => theme.mediaQueries.phoneOnly} {
      width: 100vw;
      padding: 1rem;
      height: auto;
      margin: 4rem 0.5rem 0 0.5rem;
    }

  @media (min-width: 600px) and (max-width: 1199px) {
    height: auto;
    margin: 0 0.5rem;
    padding: 1rem;
    overflow-y: scroll !important;
    
  }
`
export const ButtonContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
`

import dayjs from 'dayjs'
import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './FormikField.styles'
import { useField } from 'formik'
import { useForm } from '@/components/__Inputs/Form'
import { DATE_FORMAT_DISPLAY } from '@/constants/dates'
import { StyledErrorMessage, StyledLabel } from '../input.styles'
import { ErrorCircleSolid } from '@awaze/design-system.components.icon'

const FormikField = ({ component: Component, inputType, onChange, isDesignSystemComponent, showErrorIcon, ...props }) => {
  const { disabled } = useForm()
  const [ field, meta, helpers ] = useField(props)

  const setValue = (value) => {
    if (inputType === 'date') {
      return dayjs(value).format(DATE_FORMAT_DISPLAY)
    } else if (inputType === 'date-month') {
      return dayjs(value).format('MMMM YYYY')
    } else {
      return value
    }
  }

  return (
    <Container>
      {(inputType.indexOf('date') > -1 && props.label) && <StyledLabel>{props.label}</StyledLabel>}
      <Component
        hasError={!!(meta.touched && meta.error)}
        status={(meta.touched && meta.error && 'error')}
        id={props.id || props.name}
        {...field}
        {...props}
        disabled={props.disabled || disabled}
        isDisabled={props.disabled || disabled}
        onChange={(value) => {
          value?.target ? helpers.setValue(setValue(value.target.value)) : helpers.setValue(setValue(value))
          onChange && onChange(value)
        }}
        onBlur={() => helpers.setTouched(true)}
      />
      {(meta.touched && meta.error) && <StyledErrorMessage className="formik-error">{showErrorIcon && <ErrorCircleSolid size="xs" iconColour={'ColorStatusAttention500'}/>} {meta.error?.value || meta.error}</StyledErrorMessage>}
    </Container>
  )
}

FormikField.propTypes = {
  component: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isDesignSystemComponent: PropTypes.bool,
  id: PropTypes.string,
  inputType: PropTypes.oneOf(['select', 'date', 'date-month']),
  label: PropTypes.string,
  name: PropTypes.string,
  showErrorIcon: PropTypes.bool
}

export default FormikField

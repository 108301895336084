import React from 'react'
import PropTypes from 'prop-types'
import { Container, Icon, Label } from './DisplayCheckbox.styles'

const DisplayCheckbox = ({label, enabled}) => {
  return (
    <Container>
      <Icon name='icon' enabled={enabled} />
      <Label>{label}</Label>
    </Container>
  )
}

DisplayCheckbox.propTypes = {
  enabled: PropTypes.bool,
  label: PropTypes.string
}

export default DisplayCheckbox

import React from 'react'
import PropTypes from 'prop-types'
import Button from '@/components/__Inputs/Button'
import { Modal, Divider } from '@/components/__UI'
import { AcceptButtonContainer } from './PropertyAmendSuccessModal.styles'

const PropertyAmendSuccessModal = ({ onClose }) => (
  <Modal open onClose={onClose}>
    <p>You have successfully submitted your amendments to our team.</p>
    <p>Any styling may be removed and reworked by our content management team</p>
    <p>Changes can take a few days to be updated and will not show as updated on this site until then.</p>
    <Divider size={4} />
    <AcceptButtonContainer>
      <Button
        data-testid="acceptAmendmentSuccess"
        primary
        onClick={onClose}
      >
        Accept
      </Button>
    </AcceptButtonContainer>
  </Modal>
)

PropertyAmendSuccessModal.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  property: PropTypes.object,
  type: PropTypes.string
}

export default PropertyAmendSuccessModal

import styled from 'styled-components'

const Content = styled.div`
  position: relative;

  ${({theme}) => theme.mediaQueries.tabletLandscapeUp} {
    padding-left: 370px;
  }
`

const InfoSection = styled.div`
  margin-top: 2rem;
  background-color: ${({theme}) => theme.colors.greyFive};
  border-radius: 6px;
  padding: 1rem;

  p {
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }

  ${({theme}) => theme.mediaQueries.tabletLandscapeUp} {
    position: absolute;
    top: 0;
    left: 0;
    width: 340px;
    margin-top: 0;
  }
`
export { Content, InfoSection }

import React from 'react'
import PropTypes from 'prop-types'
import Button from '@/components/__Inputs/Button'
import { Modal, Divider } from '@/components/__UI'

const SuccessModal = ({ text, onClose }) => (
  <Modal open onClose={onClose}>
    <div style={{padding: '2rem 0'}}>
      {text && <p>{text}</p>}
      <p>Changes can take a few days to be updated and will not show as updated on this site until then.</p>
      <Divider size={4} />
      <Button
        data-testid="successModalAcceptButton"
        primary
        fullWidth
        onClick={onClose}
      >
          Accept
      </Button>
    </div>
  </Modal>
)

SuccessModal.propTypes = {
  text: PropTypes.string,
  onClose: PropTypes.func
}

export default SuccessModal

import React from 'react'
import PropTypes from 'prop-types'
import TableCell from './TableCell'
import { useTable } from '../Table'
import TableCollapse from './TableCollapse'
import { StyledTableRow, StyledChevron } from '../Table.styles'

const TableRow = ({ children, className = '', isExpanded, onRowClick, renderCollapse, ...props }) => {
  const { hasCollapsible, setHasCollapsible } = useTable()

  if (renderCollapse && !hasCollapsible) {
    setHasCollapsible(true)
  }

  return (
    <StyledTableRow className={`table-row ${className} ${hasCollapsible ? 'collapse' : ''}`} {...props}>
      <div className="table-row-inner" onClick={onRowClick}>
        {children}
        {renderCollapse && (
          <TableCell className="no-print" width="2rem">
            <StyledChevron name="toggleExpandingRow" isActive={isExpanded} />
          </TableCell>
        )}
      </div>
      {renderCollapse && (
        <TableCollapse isOpen={isExpanded}>
          {renderCollapse()}
        </TableCollapse>
      )}
    </StyledTableRow>
  )
}

TableRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isExpanded: PropTypes.bool,
  onRowClick: PropTypes.func,
  renderCollapse: PropTypes.func
}

export default TableRow

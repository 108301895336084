import React from 'react'
import PropTypes from 'prop-types'
import {
  SelectDropdownContainer,
  StyledPropertySelect,
  StyledSelect
} from './SelectDropDownContainer.styles'
import { useTranslation } from 'react-i18next'

const SelectDropDownContainer = ({
  filteredProperties,
  setFilteredPropertiesWithNames,
  onPropertiesLoaded,
  onPropertyChange,
  durationData,
  yearData,
  hidePriceDuration,
  ...props
}) => {
  const { t } = useTranslation()

  const propertySelector = () => {
    return (
      <StyledPropertySelect
        label
        filteredProperties={filteredProperties}
        setFilteredPropertiesWithNames={setFilteredPropertiesWithNames}
        onSuccess={onPropertiesLoaded}
        operationID="viewFinancialReports"
        onChange={onPropertyChange}
      />
    )
  }
  const durationSelector = () => {
    return (
      <StyledSelect
        label={t('duration')}
        loadingText={t('loadingDurations')}
        {...durationData}
      />
    )
  }
  const yearSelector = () => {
    return (
      <StyledSelect
        label={t('year')}
        loadingText={t('loading')}
        {...yearData}
      />
    )
  }

  return (
    <SelectDropdownContainer {...props}>
      <div className="property">{propertySelector()}</div>
      {!hidePriceDuration && <div className="duration">{durationSelector()}</div>}
      <div className="year">{yearSelector()}</div>
    </SelectDropdownContainer>
  )
}

const dataType = PropTypes.exact({
  value: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func,
  loading: PropTypes.bool
})

SelectDropDownContainer.propTypes = {
  onPropertiesLoaded: PropTypes.func,
  onPropertyChange: PropTypes.func,
  setFilteredPropertiesWithNames: PropTypes.func,
  durationData: dataType,
  yearData: dataType,
  filteredProperties: PropTypes.array,
  defaultValue: PropTypes.object,
  hidePriceDuration: PropTypes.bool
}

export default SelectDropDownContainer

import { PricingV2ResponseRows } from '@/hooks/useQuery';
import { useTranslation } from 'react-i18next';
import { Link } from '@awaze/design-system.components.link';
import { BookingsCell } from './columns.styles';


interface BookingsDetailsCellProps {
  value: PricingV2ResponseRows['bookingDetails'];
}

function BookingsDetailsCell({ value }: BookingsDetailsCellProps) {
  const { t } = useTranslation();

  const bookingsCount = value?.bookingsCount ?? '0/0';
  const bookingReferences = value?.bookingReferences ?? ['1', '2'];
  
  return (
    <BookingsCell sizes={200}>
      <span>{t('bookingsDetailsCounts', { bookingsCount })}</span>
      {bookingReferences.map((bookingReference) => (
        <Link key={bookingReference} href="">
          Go to booking
        </Link>
      ))}
    </BookingsCell>
  );
}

export default BookingsDetailsCell;
import styled from 'styled-components'
import { LinkButton } from '@/global/GlobalStyle'

const Container = styled.div`
  padding: 1.1rem 1rem 1rem;
  background-color: ${({theme}) => theme.colors.white};
  border-radius: ${({theme}) => theme.borderRadius};
  margin-bottom: 0.5rem;

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    max-height: 300px;
    overflow-y: auto;
  }
`

const Header = styled.div`
  position: relative;
  min-height: 38px;
`

const HeaderInner = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    display: block;
  }
`

const Title = styled.h2`
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  ${({ theme }) => theme.fonts.Heading200}

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    ${({ theme }) => theme.fonts.Heading100}
  }
`

const Icon = styled.img`
  display: inline-block;
  vertical-align: middle;
  margin-right: ${({iconMargin}) => iconMargin || '1rem'};
`

const StyledButton = styled(LinkButton)`
  float: right;
  margin-left: 1rem;

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    display: inline-block;
    vertical-align: middle;
    margin-top: 1rem;
    margin-left: 0;
    float: none;
  }
`

const Content = styled.div`
  padding-top: 1rem;
  padding-left: 2.5rem;

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    padding-left: 0;
  }
`

export { Container, Header, HeaderInner, Title, Icon, Content, StyledButton }

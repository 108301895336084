import React, {useState} from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import useGraphQl, { graphQlFetch, GET_USER_ACCOUNTS, UPDATE_USER_ACCOUNT } from '@/hooks/useGraphQl'
import { Spinner } from '@/components/__UI'
import { SuccessModal } from '@/components/__Modals'
import { useFormikContext } from 'formik'
import { Form, FormikField, SelectDropdown, TextInput, Button, StyledLabel, StyledInputControl } from '@/components/__Inputs'
import { SCHEMA_FIELD_POSTCODE, SCHEMA_FIELD_PHONE_NUMBER, PHONE_NUMBER_REQUIRED } from '@/constants/fields'
import { Container, StaticForm, InputWrapper, StyledTextarea } from './MyAccountForm.styles'
import { getInitialValues, sortValues } from './MyAccountFormFunctions'
import Countries from '@/constants/countries'

const VALIDATION_SCHEMA = Yup.object({
  address: Yup.string().required('Please enter your address'),
  town: Yup.string().required('Please enter your town'),
  postcode: SCHEMA_FIELD_POSTCODE,
  phoneNo: SCHEMA_FIELD_PHONE_NUMBER.required(PHONE_NUMBER_REQUIRED),
  mobileNo: SCHEMA_FIELD_PHONE_NUMBER,
  officeNo: SCHEMA_FIELD_PHONE_NUMBER
})

const MyAccountForm = () => {
  const [ loading, setLoading ] = useState(false)
  const [ formError, setFormError ] = useState(null)
  const [ currentAccount, setCurrentAccount ] = useState(null)
  const [ showSuccessModal, setShowSuccessModal ] = useState(null)
  const [ currentCountry, setCurrentCountry ] = useState(null)

  const { loading: loadingAccounts } = useGraphQl({
    query: GET_USER_ACCOUNTS,
    asObject: true,
    onSuccess: (data) => {
      const selected = data[0] || null
      setCurrentAccount(selected)
      getDefaultCountry(selected)
    }
  })

  const getDefaultCountry = (account) => {
    const country = account.accountDetail && account.accountDetail.country

    setCurrentCountry(Countries.find(({value}) => value === country))
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    setFormError(null)
    const newValues = sortValues(values)

    try {
      const data = await graphQlFetch({
        query: UPDATE_USER_ACCOUNT,
        variables: {
          accountId: currentAccount.id,
          values: newValues
        }
      })
      if (data.success) {
        setShowSuccessModal(true)
      } else {
        setFormError('There has been an issue submitting this form.')
      }
    } catch {
      setFormError('There has been an issue submitting this form.')
    } finally {
      setLoading(false)
    }
  }

  if (loadingAccounts) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  if (!currentAccount) {
    return (
      <Container>
        <p>Unable to find your account.</p>
      </Container>
    )
  }

  return (
    <Form
      id="MyAccountForm"
      initialValues={getInitialValues(currentAccount)}
      validationSchema={VALIDATION_SCHEMA}
      handleSubmit={handleSubmit}
      submitError={formError}
      enableReinitialize
    >
      <StyledInputControl>
        <StyledLabel>Full name:</StyledLabel>
        <StaticForm>{currentAccount.accountDetail && currentAccount.accountDetail.name}</StaticForm>
      </StyledInputControl>
      <StyledInputControl>
        <StyledLabel>Email address:</StyledLabel>
        <StaticForm>{currentAccount.accountDetail && currentAccount.accountDetail.email}</StaticForm>
      </StyledInputControl>
      <StyledTextarea
        label='Address*:'
        name="address"
        type='text'
        placeholder='Address'
      />
      <TextInput
        label='Town*:'
        name='town'
        type='text'
        placeholder='Town'
      />
      <TextInput
        label='County:'
        name='county'
        type='text'
        placeholder='County'
      />
      <TextInput
        label='Post code*:'
        name='postcode'
        type='text'
        placeholder='Post code'
      />
      <InputWrapper>
        <FormikField
          component={SelectDropdown}
          inputType="select"
          name='country'
          label='Country:'
          options={Countries}
          value={currentCountry}
          onChange={(value) => setCurrentCountry(value)}
        />
      </InputWrapper>
      <TextInput
        label='Phone number*:'
        name='phoneNo'
        type='tel'
        placeholder='Phone number'
      />
      <TextInput
        label='Mobile number:'
        name='mobileNo'
        type='tel'
        placeholder='Mobile number'
      />
      <TextInput
        label='Office number:'
        name='officeNo'
        type='tel'
        placeholder='Office number'
      />
      <SubmitButton loading={loading} />
      { showSuccessModal &&
        <SuccessModal
          text='You have successfully submitted your new personal details.'
          onClose={setShowSuccessModal}
        />
      }
    </Form>
  )
}

const SubmitButton = ({loading}) => {
  const {dirty, isValid} = useFormikContext()

  return (
    <Button data-testid="myAccountUpdateDetailsButton" primary type="submit" disabled={!(isValid && dirty)} loading={loading} >Update details</Button>
  )
}

SubmitButton.propTypes = {
  loading: PropTypes.bool
}

export default MyAccountForm

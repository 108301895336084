import React from 'react'
import { usePermittedNavigation } from '@/constants/navigation'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { OwnerHeader } from '@awaze/design-system.components.owner-header'
import { HeaderWrapper } from './Header.styles'
import { useBookingSearch } from './useBookingSearch'
import HeaderBookingSearch from './HeaderBookingSearch'
import { useActiveLink } from './useActiveLink'
import { useUser } from '@/context/UserProvider'
import useUserBrand from '@/hooks/useUserBrand'

const Header = () => {
  const { t } = useTranslation()
  const { hasPermission } = useUser()
  const bookingsPermission = hasPermission('Bookings')
  const { isUserBrandNovasol } = useUserBrand()

  const { search, searchErrorMessage, booking, clear, onChange, textValue } = useBookingSearch()

  const showSearchBar = bookingsPermission && !isUserBrandNovasol

  const { NAV_HEADER_ITEMS, NAV_PRE_HEADER_ITEMS } = usePermittedNavigation()

  const activeLink = useActiveLink()

  const headerLinks = NAV_PRE_HEADER_ITEMS.map((item) => ({
    displayName: <span data-testid={item.id}>{item.page}</span>,
    url: item.url || item.href,
    serverRouting: item.href !== undefined,
    nestedLinks: item.links ? item.links.map(link => ({
      url: link.url || link.href,
      isEmail: link.id === 'emailNavHeader',
      displayName: <span data-testid={link.id}>{link.label}</span>,
      serverRouting: link.href !== undefined,
    })) : null
  }))

  const subHeaderLinks = NAV_HEADER_ITEMS.map((item) => ({
    displayName: <span data-testid={item.id}>{item.page}</span>,
    url: item.url,
    nestedLinks: item.links ? item.links.map(link => ({
      displayName: <span data-testid={link.id}>{link.label}</span>,
      url: link.url || link.href,
      serverRouting: link.href !== undefined
    })) : null
  }))

  const searchBarProps = showSearchBar
    ? {
      maxNumberOfSymbols: 10,
      inputType: 'number',
      search: search,
      onChange: onChange,
      errorMessage: searchErrorMessage || t('bookingSearchFailed'),
      isErrorState: searchErrorMessage !== null,
      placeholder: t('searchBookingRef'),
      value: textValue
    } : null

  const menuText = t('menu')

  return (
    <>
      <HeaderWrapper>
        <OwnerHeader
          headerLinks={headerLinks}
          subHeaderLinks={subHeaderLinks}
          LinkComponent={NavLink}
          searchBarProps={ showSearchBar ? searchBarProps : null }
          initialActiveLink={activeLink}
          activeLink={activeLink}
          menuText={menuText}
        />
      </HeaderWrapper>
      <HeaderBookingSearch booking={booking} onClose={clear} />
    </>
  )
}

export default Header

import styled from 'styled-components'

const Container = styled.div`
  padding: 1rem 0;
  border-bottom: 1px solid ${({theme}) => theme.colors.greyThree};

  ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`
const Name = styled.div`
  text-transform: capitalize;
`

export { Container, Name }

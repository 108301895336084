import React from 'react'
import { useParams } from 'react-router-dom'
import { useUser } from '@/context/UserProvider'
import { Container, Spinner } from '@/components/__UI'
import { SpecialIcon } from '@/global/GlobalStyle'
import { PropertyAmendsEditLayout, PropertyArrivalEdit } from '@/components/__PropertyAmendsComponents'
import { ContentContainer, Content, InfoSection } from './PropertyAmendsArrivalPage.styles'

const PropertyAmendsArrivalPage = () => {
  const { id } = useParams()
  const { user, loading, hasPermission } = useUser()
  const isPermitted = hasPermission('Bookings')

  if (!isPermitted && !loading) {
    return (
      <Container>
        <p style={{ textAlign: 'center' }}>You do not have permission to view this page</p>
      </Container>
    )
  }

  if (!user || loading) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  return (
    <PropertyAmendsEditLayout propertyId={id}>
      <h1>Arrival information</h1>
      <ContentContainer>
        <Content>
          <PropertyArrivalEdit propertyId={id} />
        </Content>
        <InfoSection>
          <SpecialIcon />
          <p>Make sure this information is always up to date.</p>
          <p>This information will only be shared with your guests when a booking is confirmed. </p>
        </InfoSection>
      </ContentContainer>
    </PropertyAmendsEditLayout>
  )
}

export default PropertyAmendsArrivalPage

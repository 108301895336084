import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from '@/components/__UI'
import OnlineStatementsItem from '../OnlineStatementsItem'

const OnlineStatementsList = ({currentAccount, selectedYear, accountStatements, loading}) => {
  const [statementList, setStatementList] = useState([])

  useEffect(() => {
    if (accountStatements) {
      filterStatements(accountStatements, selectedYear)
    }
    // eslint-disable-next-line
  }, [accountStatements, selectedYear])

  const filterStatements = (data, filterYear) => {
    const filYear = typeof filterYear === 'string' ? filterYear : filterYear.value
    if (selectedYear && selectedYear.value === 'all') {
      setStatementList(data)
    } else {
      const filteredList = data.filter(({year}) => year === filYear)
      setStatementList(filteredList)
    }
  }

  if (loading || currentAccount === null) {
    return <p style={{padding: '1rem'}}><Spinner /></p>
  }

  if (selectedYear.value !== 'all') {
    const monthlyStatementListToDisplay = statementList[0]
    if (!statementList || (statementList.length <= 0 || (!monthlyStatementListToDisplay.monthlyStatements) || monthlyStatementListToDisplay.monthlyStatements.length < 1)) {
      return <p style={{padding: '1rem'}}>There are no statements for this selection.</p>
    }
  }

  return (
    <div>
      {statementList.map(({monthlyStatements}, id) => {
        return <div key={id.toString() + 'key'}>{monthlyStatements.map((item) => <OnlineStatementsItem key={item.id} item={item} accountId={currentAccount} />)}</div>
      })}
    </div>
  )
}

OnlineStatementsList.propTypes = {
  selectedYear: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  currentAccount: PropTypes.string,
  loading: PropTypes.bool,
  accountStatements: PropTypes.array
}

export default OnlineStatementsList

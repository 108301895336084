import { Text } from '@awaze/design-system.components.text';
import { useTranslation } from 'react-i18next';
import { TableControls, TableControlsRight } from './PricingListTableControls.styles';
import { PricingListTableTooltip } from './PricingListTableTooltip';
import { usePricingTableDataVisibility } from '../PricingListTable/columns/columnDefinitions';
import { usePricingDataContext } from '../context/PricingDataProvider';
import { PricingListTableFilters } from './PricingListTableFilters';
import { PricingListTableYearPicker } from './PricingListTableYearPicker';
  

export function PricingListTableControls() {
    const { t } = useTranslation();
    const { pricingData } = usePricingDataContext()
    const dataToShow = usePricingTableDataVisibility()

    return (
        <TableControls>
          <PricingListTableYearPicker />
          <TableControlsRight>
            <Text sizes={200}>
              {t('currency')}: {pricingData?.currency}
            </Text>
            {/* TODO filters goes here */}
            <PricingListTableTooltip showIncome={dataToShow.income} showDynamicPrice={dataToShow.currentPrice} />
            <PricingListTableFilters/>
          </TableControlsRight>
        </TableControls>

    )
}
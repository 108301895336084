import React from 'react'
import Link from '@/components/Link'

import ChangeBankDetailForm from '@/assets/bankDetailsForms/form-change-bank-details.pdf'
import ChangeBankDetailFormEuro from '@/assets/bankDetailsForms/form-change-bank-details-euros.pdf'
import PhotographGuidelines from '@/assets/photographyGuides/Owner Photography Guidelines.pdf'
import PhotographyForm from '@/assets/photographyGuides/Owner Order Form.pdf'

export default [
  {
    'tags': ['Owner Payments'],
    'title': 'When do I get paid?',
    'content': (<p>You will be paid once a month and will receive monthly statements to confirm the amount.</p>)
  },
  {
    'tags': ['Owner Payments'],
    'title': 'How much will I get paid for a specific booking?',
    'content': (
      <p>Please <Link to='/bookings/calendar'>click here</Link> to view your booking calendar where you will find individual customer booking details including their contact details and any special requirements they may have notified us of.</p>
    )
  },
  {
    'tags': ['Owner Payments'],
    'title': 'What do I need to do if my bank details have changed?',
    'content': (<p>Please <a href={ChangeBankDetailForm} target='_blank' rel='noopener noreferrer'>click here</a> to download the &apos;change of bank details&apos; form for UK sterling bank accounts or <a href={ChangeBankDetailFormEuro} target='_blank' rel='noopener noreferrer'>click here</a> for the &apos;change of bank details&apos; form for Republic of Ireland euro bank accounts.</p>)
  },
  {
    'tags': ['Owner Payments'],
    'title': 'Why have I got a VAT invoice?',
    'content': (<p>As we do not store details of our owners VAT status we provide every owner with the VAT summary. If you are not VAT registered you can ignore this page otherwise, please pass the VAT invoice to those responsible for your financial accounts/tax returns.</p>)
  },
  {
    'tags': ['Owner Payments'],
    'title': 'How do I get copies of previous years statements?',
    'content': (<p>You can ask your Regional Owner Services team, but please be aware that they may take up to two weeks to arrive. <Link to='/contact-us'>click here</Link> to contact your Regional Owner Services team.</p>)
  },
  {
    'tags': ['Dynamic Pricing'],
    'title': 'What is Dynamic Pricing?',
    'content': (<p>Dynamic Pricing is a market-leading system that allows us to continuously fine tune individual property prices with the aim of delivering the best possible occupancy and income for you. We implemented Dynamic Pricing in 2013 and, due to its great success, it has become the standard pricing model throughout our UK cottage business.</p>)
  },
  {
    'tags': ['Dynamic Pricing'],
    'title': 'Why have you adopted this approach to pricing?',
    'content': (<p>Just over half of our customers say that they use the internet to choose and book their holiday. As this figure continues to increase it seems clear that setting brochure prices as far as 18 months in advance is no longer appropriate for the majority of today consumers.</p>)
  },
  {
    'tags': ['Dynamic Pricing'],
    'title': 'What are the benefits of signing up to Dynamic Pricing?',
    'content': (<p>We have the ability to adjust our prices as the market dictates, which allows us to limit the risk of having to estimate demand and being unable to react quickly to unforeseen events. Dynamic-Pricing enables us to discreetly increase prices when demand is high as well as lower them where necessary to generate more bookings.</p>)
  },
  {
    'tags': ['Dynamic Pricing'],
    'title': 'How does Dynamic Pricing work?',
    'content': (<p>Your current agreed prices, which reflect key factors including location, quality and facilities, will be used as the starting rental price (also known as the mid-line price). Our team of pricing analysts - using their expert regional knowledge and our revenue management technology - will then continually assess and manage your property prices. We regularly analyse data such as demand, availability, booking patterns, regional events and historic trends to help make the decision whether or not to fluctuate prices throughout the year. Our regional managers are skilled at recommending the best midline price when they recruit your property, therefore whilst some owners may see occasional weeks sold at these levels, in reality, fluctuations from the mid-line are likely to be fairly small. You can be reassured that all price changes are carefully considered and we will never go beyond the parameters that have been set.</p>)
  },
  {
    'tags': ['Dynamic Pricing'],
    'title': 'Can I add a discount to my property?',
    'content': (<p>Providing you have joined Dynamic Pricing, you can reduce the price of any particular week at any time however this will only be displayed as a discount, i.e. &apos;WAS/NOW&apos; when we run a marketing campaign.</p>)
  },
  {
    'tags': ['Performance'],
    'title': 'I do not seem to be getting as many bookings as normal – what should I do?',
    'content': (
      <><p>First of all you should contact your Regional Owner Services team, please <Link to='/contact-us'>click here</Link> for details. Alternatively, follow the steps below to carry out a property health check:</p><ul><li>Is your {`property's`} description up to date and accurate?</li><li>Are your {`property's`} photos showing your property in the best possible way? Remember, property photos are one of the ways of prompting customers to book your property.</li><li>Check your property Feefo scores and note the customer feedback. Reply to any negative comment where improvements have been made.</li><li>Is the property expensive compared to others of similar location/size?</li><li>Are your {`property's`} features still up to date and accurate?</li></ul><p>You can view your property listings via the {'View my property'} area of the <Link to='/dashboard'>home page</Link> of this site.</p></>
    )
  },
  {
    'tags': ['Customer Bookings'],
    'title': 'How will I know if a customer has any special requirements?',
    'content': (<p>Please <Link to='/bookings/calendar'>click here</Link> to view your booking calendar where you will find individual customer booking details including their contact details and any special requirements they may have notified us of.</p>)
  },
  {
    'tags': ['Customer Bookings'],
    'title': 'What is the procedure for last minute bookings?',
    'content': (<p>We will contact you if a booking is made within 2 days of the start day to give you the details of the customers and so you can ensure that the property is ready for their arrival.</p>)
  },
  {
    'tags': ['Customer Bookings'],
    'title': 'What happens if I cannot accept a booking you notify me of?',
    'content': (<p>If you keep on top of your owner bookings you will avoid having to cancel any customer bookings. Your Agency Agreement will explain the penalties in place if you cannot accept a booking we have made on your behalf.</p>)
  },
  {
    'tags': ['Customer Bookings'],
    'title': 'What information will I receive when a customer booking is confirmed?',
    'content': (<><p>You will receive a booking update with the following information:</p><ul><li>Customer&apos;s booking reference</li><li>Customer name and contact details</li><li>Date of arrival</li><li>Date of departure</li><li>Holiday duration</li><li>Party make up (number of adults, children, infants and pet)</li><li>Any extras that the customer has requested</li><li>The rental amount that you will receive for the booking</li></ul><p>Please <Link to='/bookings/calendar'>click here</Link> to view your booking calendar where you will find individual customer booking details including their contact details and any special requirements they may have notified us of.</p></>)
  },
  {
    'tags': ['Customer Bookings'],
    'title': 'I have lost a customer confirmation – how get I get a copy of it?',
    'content': (<p>Please <Link to='/bookings/calendar'>click here</Link> to view your booking calendar where you will find individual customer booking details including their contact details and any special requirements they may have notified us of.</p>)
  },
  {
    'tags': ['Customer Bookings'],
    'title': 'What information does the customer receive about me/my property and when?',
    'content': (<p>Until the customer has paid for their holiday in full they will only receive your name and telephone number. Once they have paid the final balance they will receive the full address, directions of how to get to the property and arrival contact details along with access details (such as key codes).</p>)
  },
  {
    'tags': ['Owner Bookings'],
    'title': 'What will I be charged if I go over my owner booking allowance?',
    'content': (<p>Please <Link to='/contact-us'>contact us</Link> for information about your owner booking allowance and corresponding charges from the calendar. Note: Owner bookings that fall into 2 seasons will be charged according to the number of days falling into a chargeable period.</p>)
  },
  {
    'tags': ['Owner Bookings'],
    'title': 'When will I be charged for any excess owner bookings I may take?',
    'content': (<p>Any excess charges will be charged in line with your Agency Agreement and charges made will be detailed on your monthly statements.</p>)
  },
  {
    'tags': ['Grading'],
    'title': 'Do you let customers know if my grade is lowered?',
    'content': (<p>If, following a quality assessment, your property&apos;s grade is lowered, we will contact all of our customers to advise them of this and to let them know what has led to the grade being changed. Customers have the option of either continuing with their holiday as booked, amending to an alternative property or cancelling and receiving a full refund. It is for this reason that it&apos;s important that the standards agreed when you first signed your Agency Agreement are maintained each year.</p>)
  },
  {
    'tags': ['Grading'],
    'title': 'Can I display the VisitEngland logo in my property?',
    'content': (<p>If you have arranged for your accommodation to be inspected by the local tourist board (VisitScotland, VisitEngland and VisitWales) then you can display the tourist board grading logo. If your property is inspected by our team of regional managers then you can purchase and display the tourist board plaques from either VisitEngland or VisitScotland (no agreement is currently in place for VisitWales).</p>)
  },
  {
    'tags': ['Grading'],
    'title': 'What do I need to do to get a higher grade?',
    'content': (<p>Each Tourist Board has a number of minimum requirements which must be met depending on the quality grade you are wanting to achieve. The quality and finish of furniture, fixtures, fittings and features also play a large part to the overall quality grade. Please see the VisitEngland &apos;Minimum Entry Requirements&apos; criteria to understand what is required to qualify for each grade band.</p>)
  },
  {
    'tags': ['Grading'],
    'title': 'How is my grade calculated?',
    'content': (<p>Our regional managers have all been trained by the relevant local tourist boards to grade properties based on cleanliness, space, light, heating in specific rooms and décor amongst other factors. In order for a property to achieve a particular grade, every room in the property must achieve that grade.</p>)
  },
  {
    'tags': ['Contacting Us'],
    'title': 'How do I get in touch with my regional manager?',
    'content': (<p>Regional managers are often visiting areas with low or no mobile phone signal. In the first instance you should <Link to='/contact-us'>contact</Link> your Regional Owner Services team. If they are unable to help, they will contact the regional manager on your behalf.</p>)
  },
  {
    'tags': ['Contacting Us'],
    'title': 'How do I get in touch with Owner Services?',
    'content': (<p><Link to='/contact-us'>click here</Link> for details on how to contact your Regional Owner Services team.</p>)
  },
  {
    'tags': ['Contacting Us'],
    'title': 'What is the role of my regional manager?',
    'content': (<p>The regional manager&apos;s role is to recruit new properties into our portfolio and to help owners maximise bookings and revenue on their accommodation. Your regional manager can take new photos to display online and can help resolve customer complaints.</p>)
  },
  {
    'tags': ['Contacting Us'],
    'title': 'Who is my regional manager?',
    'content': (<p>Your regional manager is the normally the representative you met and signed the contract with when you signed up with us.</p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'What is Feefo?',
    'content': (<p>Feefo is a renowned and respected provider of verified customer reviews for businesses across various service industries. We have been collating and displaying independent customer feedback on properties and our brands using Feefo since 2012, and now have over 250,000 on our websites. In addition, over 500,000 clicks are made per month on the Feefo badges we display against our properties.</p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'How do I benefit?',
    'content': (<p>Good reviews speak for themselves, but Feefo also helps us identify any potential areas for improvement. Responses to feedback also proves to the customer that our owners are committed to total customer satisfaction.</p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'How can I respond to a Feefo comment?',
    'content': (<p>We encourage property owners to reply to both positive and negative feedback. If you would like to post a reply to a comment, please email your proposed response to our team at <a href='mailto:RQ@wynvr.co.uk'>RQ@wynvr.co.uk</a>, remembering to include your property reference and the booking you would like to respond to so that we can ensure that your response is attached to the correct comments, and a member of our team will review. Any other questions relating to Feefo should be directed to your Owner Services Team. <Link to='/contact-us'>click here</Link> for contact details.</p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'Can I request to remove a Feefo comment?',
    'content': (<p>We cannot remove or manipulate any comment unless there has been a factually incorrect statement made by the holidaymaker. Our independent customer review process is about the customers experience and represents an honest view of their opinion of the property. The best course of action in event of an unwelcome comment is to post a constructive and positive reply for potential new bookers to see.</p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'Do customer\'s reviews stay on the website indefinitely or is there a limit?',
    'content': (<p>Reviews stay on the website indefinitely. However, reviews older than 12 months will get pushed back to later pages and do not count towards current property scores. </p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'How are the Feefo comments ordered?',
    'content': (<p>Customer comments are ordered by publication date and remain visible on the first page of the website for 12 months. Any customer score without written comments to explain the numerical scores are added to the end of the page, as they are considered to be of little value to customers.</p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'Is there a limit to my response size?',
    'content': (<p>We recommend that responses are kept concise and certainly should be no longer than 10 lines. Responses that are lengthy are rarely read in their entirety so keep them short and succinct.</p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'Why am I not allowed to say what I want when I post a response?',
    'content': (<p>We understand that it is frustrating for owners if they feel a review is unjustified. It is natural to respond passionately, however this can sometimes come across as defensive and does not convey the correct message to potential customers. We always recommend a polite, professional and unemotional response which addresses any issues the customer may have raised. Thanking the customer for their comments and addressing the issues sends a positive message to future bookers and can actually help achieve more bookings.</p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'How long does it take for an owner reply to be posted?',
    'content': (<p>You can expect to see your reply to a review within 2-4 working days.</p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'Can we submit a response to those reviews that display overall score but no comments?',
    'content': (<p>Yes, if you would like to post a reply, please email your proposed response to our team at <a href='mailto:RQ@wynvr.co.uk'>RQ@wynvr.co.uk</a>, remembering to include your property reference and the booking you would like to respond to so that we can ensure that your response is attached to the correct comments, and a member of our team will review.</p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'Can customers who do not have an email address submit a Feefo?',
    'content': (<p>Unfortunately it is not possible for us to collect responses from customers without an email address. You can encourage customers with an email address to complete the emailed Feefo questionnaire. A polite note in the guest book may help.</p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'Can I send in guest book comments to be published?',
    'content': (<p>Unfortunately it is not possible to verify comments from a guest book. Only comments submitted on the official Feefo form can be published.</p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'How is the individual score calculated?',
    'content': (<p>The customers review score is based on what they give for the Overall Score. For example, a customer could score all the other categories a 5 but then leave an Overall Score of 3, which will be the individual score displayed on the property.</p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'How is the overall score calculated?',
    'content': (<p>The Overall Score for a property (as displayed on the property page on the website) is calculated by totalling each customers Overall Score from the preceding 12 months and then dividing that number by the number of reviews submitted.</p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'What is the confirmed guest date that is showing on the website?',
    'content': (<p>The confirmed guest date is the date when the customer left the holiday property. The published date is the date the comments went live on the website.</p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'Is it possible to stop a Feefo questionnaire from being sent?',
    'content': (<p>It is not possible to stop a questionnaire from being sent. The process is automated and if a customer has a confirmed booking and an email address they will receive a questionnaire.</p>)
  },
  {
    'tags': ['Feefo'],
    'title': 'What do I do if I get a negative review or comment?',
    'content': (<p>Surprisingly, negative feedback actually has a positive long-term effect because it gives owners the information they need to improve customer satisfaction. This also leads to more new and returning bookings. Please remember that it&apos;s almost impossible to please everyone so try not to take any negative comments personally. A negative comment might simply be a matter of one person&apos;s taste or judgement and is something readers will take account of.</p>)
  },
  {
    'tags': ['Photography'],
    'title': 'Can I send you images that I have taken on my Smart Phone?',
    'content': (<p>Images must be taken on a digital camera as those taken with mobile devices will not provide the quality and sizes needed. Our Image Team have editorial authority and may reject poor quality photography. <a href={PhotographGuidelines} target='_blank' rel='noopener noreferrer'>click here</a> for our photography guidelines.</p>)
  },
  {
    'tags': ['Photography'],
    'title': 'How long will it take for my new photos to appear on the website?',
    'content': (<p>Our target is within 5 working days. We ask that you bear with us during busy periods as it can take a little longer to process images during our peak times.</p>)
  },
  {
    'tags': ['Photography'],
    'title': 'Can you send me copies of the images that the regional manager took?',
    'content': (<p>Unfortunately not; these images are protected under copyright.</p>)
  },
  {
    'tags': ['Photography'],
    'title': 'Do you offer any other photographic packages that will enhance the look of my property?',
    'content': (<p>Yes, we can offer the following additional services: elevated images, professional photo shoot and showcase videos. Please <a href={PhotographyForm} target='_blank' rel='noopener noreferrer'>click here</a> to download an order form and view the costings.</p>)
  },
  {
    'tags': ['Photography'],
    'title': 'What type and size of image do you require?',
    'content': (<p>Ideally, we need digital images to be supplied at 2598px (width) x 1949px (height) or above. We cannot guarantee that we will use images supplied below these dimensions and we may ask you to re-supply.</p>)
  },
  {
    'tags': ['Photography'],
    'title': 'What type of camera should I use?',
    'content': (<><ul><li>Any Digital camera of 6 mega pixel and above will produce large enough files to meet the above criteria.</li><li>Your camera needs to be set to capture the best quality by selecting the highest pixel count available.</li><li>Please supply all images in their original state. No resizing or manipulation is necessary.</li></ul><p>If we do reject images you have supplied to us, we will let you know why we have done so.</p></>)
  },
  {
    'tags': ['Photography'],
    'title': 'How many images can each property have on the website?',
    'content': (<p>We make our decision based on size of property, features/facilities and key selling points. Please note: we only accept images taken in landscape format. Images taken in portrait format will not be used.</p>)
  },
  {
    'tags': ['Photography'],
    'title': 'Can we use images taken from an owners website?',
    'content': (<p>Yes, providing they are a suitable size, that you own the right to use them and that the images meet our criteria.</p>)
  },
  {
    'tags': ['Photography'],
    'title': 'Can objects be removed/added in an image?',
    'content': (<p>Due to Trading Standards legislation, telegraph poles, post boxes, telephone lines, etc, cannot be removed because they are permanent fixtures. Other editing is possible, such as adding blue skies and removing various objects. It would be appreciated if any unnecessary editing can be avoided by preparing the property before shots are taken (i.e. removing a wheelie bin, keeping family pets out of the photographs).</p>)
  },
  {
    'tags': ['Photography'],
    'title': 'Can location/surrounding area images be used?',
    'content': (<p>Yes, if relevant to the property. Please provide as much information as possible when you submit your photos: location, time of year taken, distance to your property, etc.</p>)
  },
  {
    'tags': ['Photography'],
    'title': 'Can images be captioned?',
    'content': (<p>Yes. Please remember to provide detailed captioning for local/regional/landmarks when images are submitted.</p>)
  },
  {
    'tags': ['Photography'],
    'title': 'Images featuring animals and people?',
    'content': (<p>People and animals are not allowed in images. However, we understand that field shots containing cattle/sheep cannot always be avoided.</p>)
  },
  {
    'tags': ['Photography'],
    'title': 'Do we allow images from professional photographers and other companies?',
    'content': (<p>Due to copyright laws, images from other companies or photographers cannot be used unless we have written consent from the person/company owning the rights.</p>)
  },
  {
    'tags': ['Photography'],
    'title': 'Can local businesses such as pubs be added?',
    'content': (<p>Unfortunately not. We need to avoid possible complaints, e.g. the pub/business could close or if the customer has a bad experience they could see a photo as an endorsement/recommendation.</p>)
  },
  {
    'tags': ['General Advice'],
    'title': 'What should I do if customers damage my property?',
    'content': (<p>First of all ring your Regional Owner Services team (<Link to='/contact-us'>click here</Link> for contact details). Also take photos of the damaged area as your insurance company may want to see details. It is useful to maintain an inventory of the contents.</p>)
  },
  {
    'tags': ['General Advice'],
    'title': 'What should I do if more customers arrive than the maximum occupancy of my property?',
    'content': (<p>You do not have to admit more customers than your property sleeps. Please double check the booking confirmation then discuss  the over-occupancy with the customer. If this fails to resolve the issue then contact your Regional Owner Services team for assistance (<Link to='/contact-us'>click here</Link> for contact details).</p>)
  },
  {
    'tags': ['General Advice'],
    'title': 'Do you have any information that I can display to customers in my property with regards to pets?',
    'content': (<p>Yes, please <Link to='/contact-us'>contact</Link> your Regional Owner Services team to request our &apos;Guide to Pets&apos; leaflet.</p>)
  },
  {
    'tags': ['General Advice'],
    'title': 'My property does not allow pets, what should I do if customers arrive with one?',
    'content': (<p>You do not have to admit a pet if your property advertises otherwise. Please discuss this issue with the customer, and if you still fail to reach a satisfactory arrangement then contact your Regional Owner Services team for assistance (<Link to='/contact-us'>click here</Link> for contact details).</p>)
  },
  {
    'tags': ['General Advice'],
    'title': 'Do you offer property management services?',
    'content': (<p>Yes. <Link to='/contact-us'>Please contact</Link> your Regional Owner Services team to discuss details in your area.</p>)
  },
  {
    'tags': ['General Advice'],
    'title': 'Can you recommend any insurance providers?',
    'content': (<p>We can put you in touch with a number of insurance brokers that we work with. <Link to='/contact-us'>Please contact</Link> your Regional Owner Services team for details.</p>)
  },
  {
    'tags': ['General Advice'],
    'title': 'Can you give me any tax advice?',
    'content': (<p>Unfortunately, we cannot provide tax advice, but we can pass on details of suitable firms that can help you. <Link to='/contact-us'>Please contact</Link> your Regional Owner Services team for details.</p>)
  },
  {
    'tags': ['General Advice'],
    'title': 'What do I do if someone becomes ill or has an accident at my property?',
    'content': (<p>If you are on site then provide any necessary assistance before contacting us. Depending on the circumstances of the illness/accident and the terms of your insurance policy you might have to let your public liability insurers know. For that reason please also consider taking photos of whatever is alleged to have caused the illness/accident.</p>)
  },
  {
    'tags': ['General Advice'],
    'title': 'Can you give me any legal advice?',
    'content': (<p>Unfortunately, we cannot provide legal advice. Check the terms of your insurance policy as legal advice may be provided as a service.</p>)
  },
  {
    'tags': ['General Advice'],
    'title': 'What are my legal obligations?',
    'content': (<p>Your legal obligations are set out in the Agency Agreement. It is your responsibility to understand your legal obligations as the owner of holiday accommodation. However, we can assist you by discussing the terms of the Agreement and providing you with links to resources. <Link to='/contact-us'>click here</Link> to contact your Regional Owner Services team.</p>)
  },
  {
    'tags': ['General Advice'],
    'title': 'My property is not the same as advertised, do I need to do anything?',
    'content': (<p>Your property must be presented as advertised. Please <Link to='/contact-us'>contact us</Link> straight away if you notice a discrepancy in your property&apos;s description or if anything has changed.</p>)
  },
  {
    'tags': ['Property Amends'],
    'title': 'How long will it take for changes to my property to appear on the website?',
    'content': (<p>We aim to have all amends done within 5 days, although some amends can be made immediately.</p>)
  },
  {
    'tags': ['Property Amends'],
    'title': 'What does it mean if I remove a facility from my property?',
    'content': (<p>Removing a facility could have a negative effect on bookings, your pricing and your quality grade. <Link to='/contact-us'>click here</Link> to contact your Regional Owner Services team.</p>)
  },
  {
    'tags': ['Safer Stays'],
    'title': 'Can I keep my normal arrival and departure times?',
    'content': (<p>If you are able to carry out the full cleaning requirements within the time available before the next arrival then you can offer a standard arrival of 3pm and departure of 10am. Please let us know when you opt in so that we can ensure that your arrival instructions are correct.</p>)
  },
  {
    'tags': ['Safer Stays'],
    'title': 'What do you class as non-essential items?',
    'content': (<p>If you have opted into Safer Stays you should take a common-sense decision as to which ‘likely to be handled’ items are genuinely needed by guests during their stay.  Items which can be easily cleaned and/or sanitised between bookings can of course remain.  For example reference books, leaflets or maps could be temporarily removed fairly easily, but if you have a full library it might be more practical to leave the bookings in situ but display a notice advising that they have not been incorporated into your fuller cleaning regime.</p>)
  },
  {
    'tags': ['Safer Stays'],
    'title': 'Should my property description be amended to make guests aware of items which have been removed?',
    'content': (<p>No, this won’t be necessary as our Safer Stays guest information will make reference to the fact that some items may have been removed.</p>)
  },
  {
    'tags': ['Safer Stays'],
    'title': 'Should I ask guests to bring their own bedding and towels?',
    'content': (<p>No, please continue to provide household linen as normal but ensure that everything is washed at the hottest possible temperature at every changeover.</p>)
  }
]

import React from 'react'
import { useCallToArmsContext } from '../context/CallToArmsProvider'
import { PriceRecommendations, RecommendedPricesButton } from './CallToArmsMessage.styles'
import { Link } from 'react-router-dom'

function CallToArmsMessage () {
  const { showRecommendationButton } = useCallToArmsContext()

  return (
    <>
      {
        /* istanbul ignore next */
        showRecommendationButton &&
        <PriceRecommendations>
          <div className='messageRecommendation'>
            <h4>Recommended changes to your Start Prices</h4>
            <p>You are at risk of missing out on bookings. We have evaluated your prices and you have some weeks which are not competitive.</p>
          </div>
          {
            <Link to='/pricing-list/price-recommendations'>
              <RecommendedPricesButton data-testid="maximiseBookingsButton" primary>Maximise bookings</RecommendedPricesButton>
            </Link>
          }

        </PriceRecommendations>
      }
    </>
  )
}

export default CallToArmsMessage

import dayjs from 'dayjs';
import { PricingV2ResponseRows } from '@/hooks/useQuery';
import { useTranslation } from 'react-i18next';
import { Text } from '@awaze/design-system.components.text';

interface WeekCellProps {
  value: PricingV2ResponseRows['week'];
  isExpanded?: boolean;
}

function WeekCell({ value, isExpanded }: WeekCellProps) {
  const { t } = useTranslation();
  const { weekNumber, startDate, endDate } = value;
  const formattedStartDate = dayjs(startDate).format('DD MMM');
  const formattedEndDate = dayjs(endDate).format('DD MMM');
  return (
    <Text sizes={200} type={isExpanded ? 'bold' : 'mid'}>
      {t('pricingPageWeekColumn', {
        weekNumber,
        weekStart: formattedStartDate,
        weekEnd: formattedEndDate,
      })}
    </Text>
  );
}

export default WeekCell;

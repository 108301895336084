import useGraphQl, { GET_USER_PROPERTY_ENTITLEMENTS } from '@/hooks/useGraphQl'
import { useQueryUserProperties } from '@/hooks/useQuery'
import React, { createContext, useContext, useState, useRef, useMemo } from 'react'
import toast from '@/helpers/toast'
import PropTypes from 'prop-types'
import { filterPropertiesByRequiredOccupancy } from '../../__BookingChartComponents/Helpers/OccupancyFilter/Function/OccupancyFilterFunction'

export const bookingChartContextDefaults = {
  isLoadingChart: false,
  allSitesWithProperties: {},
  filteredSite: {},
  partyProfile: {},
  setFilteredSite: Function,
  setPartyProfile: Function
}

export const DEFAULT_PARTY_PROFILE = { adults: 2, children: 0, infants: 0, pets: 0 }
export const BookingChartContext = createContext(bookingChartContextDefaults)
export const useBookingChartContext = () => useContext(BookingChartContext)

export const BookingChartProvider = ({ children }) => {
  const [allSitesWithProperties, setAllSitesWithProperties] = useState(null)
  const [filteredSite, setFilteredSite] = useState({})
  const [partyProfile, setPartyProfile] = useState(DEFAULT_PARTY_PROFILE)

  const authorisations = useRef([])

  const { data: siteProperties, error: sitePropertiesError, isLoading: isLoadingChart, isSuccess: isDoneLoadingChart  } = useQueryUserProperties('viewBookings', true)

  React.useEffect(() => {
    if (siteProperties) {
      setAllSitesWithProperties(siteProperties)

      const filteredSite = siteProperties.sites.sort((a, b) => a.siteName.localeCompare(b.siteName))[0]
      setFilteredSite(filteredSite)
    }
  }, [siteProperties])

  React.useEffect(() => {
    if (sitePropertiesError) {
      toast.error('Failed to load Accommodations', null, sitePropertiesError)
    }
  }, [sitePropertiesError])

  React.useEffect(() => {
    return () => {
      authorisations.current = []
    }
  }, [])


  const filteredProperties = useMemo(() => {
    if (filteredSite?.siteProperties) {
      return filterPropertiesByRequiredOccupancy({
        ongoingPropertyCollection: filteredSite.siteProperties,
        occupancyToFilterOn: {
          requiredAdults: partyProfile.adults,
          requiredChildren: partyProfile.children,
          requiredInfants: partyProfile.infants,
          requiredPets: partyProfile.pets,
        },
      });
    }
    return [];
  }, [filteredSite, partyProfile]);
  
  const noResults = isDoneLoadingChart && filteredProperties.length === 0;

  useGraphQl({
    query: GET_USER_PROPERTY_ENTITLEMENTS,
    enabled:
      !isLoadingChart &&
      filteredSite.siteProperties !== undefined &&
      filteredSite.siteProperties[0] !== undefined &&
      filteredSite.siteProperties[0].propertyId !== undefined,
    variables: { propertyId:
      filteredSite.siteProperties &&
      filteredSite.siteProperties[0] !== undefined &&
      filteredSite.siteProperties[0].propertyId },
    onSuccess: (data) => {
      authorisations.current = data
    }
  })

  return (
    <BookingChartContext.Provider
      value={{
        isLoadingChart,
        allSitesWithProperties: allSitesWithProperties,
        filteredSite: filteredSite,
        setFilteredSite,
        permissions: authorisations.current,
        partyProfile: partyProfile,
        setPartyProfile,
        filteredProperties,
        noResults
      }}
    >
      {children}
    </BookingChartContext.Provider>
  )
}

BookingChartProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

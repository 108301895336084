import React from 'react'

const ExtrasSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.32112 16.3C2.16701 15.4541 3.3143 14.9789 4.51058 14.9789C5.70685 14.9789 6.85414 15.4541 7.70004 16.3C8.54593 17.1459 9.02115 18.2931 9.02115 19.4894C9.02115 20.6857 8.54593 21.833 7.70004 22.6789C6.85414 23.5248 5.70685 24 4.51058 24C3.3143 24 2.16701 23.5248 1.32112 22.6789C0.47522 21.833 0 20.6857 0 19.4894C0 18.2931 0.47522 17.1459 1.32112 16.3ZM4.51058 16.5107C3.72058 16.5107 2.96294 16.8246 2.40433 17.3832C1.84572 17.9418 1.53189 18.6994 1.53189 19.4894C1.53189 20.2794 1.84572 21.0371 2.40433 21.5957C2.96294 22.1543 3.72058 22.4681 4.51058 22.4681C5.30057 22.4681 6.05821 22.1543 6.61682 21.5957C7.17543 21.0371 7.48926 20.2794 7.48926 19.4894C7.48926 18.6994 7.17543 17.9418 6.61682 17.3832C6.05821 16.8246 5.30057 16.5107 4.51058 16.5107Z" fill="#506F8B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.54047 13.5233C2.52682 12.5369 3.8646 11.9828 5.2595 11.9828H8.25521C10.4446 11.9828 12.5444 12.8526 14.0925 14.4007C15.6407 15.9489 16.5104 18.0486 16.5104 20.238V21.7359C16.5104 22.3363 16.2719 22.9121 15.8474 23.3366C15.4228 23.7612 14.847 23.9997 14.2466 23.9997H4.51058C3.3143 23.9997 2.16701 23.5245 1.32112 22.6786C0.47522 21.8327 0 20.6854 0 19.4891V17.2423C0 15.8474 0.554124 14.5096 1.54047 13.5233ZM5.2595 13.5147C4.27088 13.5147 3.32275 13.9074 2.62369 14.6065C1.92462 15.3056 1.53189 16.2537 1.53189 17.2423V19.4891C1.53189 20.2791 1.84572 21.0367 2.40433 21.5953C2.96294 22.154 3.72058 22.4678 4.51058 22.4678H14.2466C14.4407 22.4678 14.6269 22.3907 14.7641 22.2534C14.9014 22.1162 14.9785 21.93 14.9785 21.7359V20.238C14.9785 18.4549 14.2702 16.7448 13.0093 15.4839C11.7484 14.2231 10.0383 13.5147 8.25521 13.5147H5.2595Z" fill="#506F8B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.9739 20.2377C17.9739 19.8147 18.3168 19.4718 18.7398 19.4718H20.2377C21.2353 19.4718 22.1921 19.8681 22.8976 20.5736C23.603 21.279 23.9993 22.2358 23.9993 23.2335C23.9993 23.4366 23.9186 23.6314 23.775 23.7751C23.6313 23.9187 23.4365 23.9994 23.2334 23.9994H20.2377C19.6373 23.9994 19.0615 23.7609 18.6369 23.3363C18.2124 22.9118 17.9739 22.336 17.9739 21.7356V20.2377ZM19.5058 21.0037V21.7356C19.5058 21.9297 19.5829 22.1159 19.7201 22.2531C19.8574 22.3904 20.0436 22.4675 20.2377 22.4675H22.3317C22.2213 22.1657 22.046 21.8884 21.8144 21.6568C21.3962 21.2386 20.829 21.0037 20.2377 21.0037H19.5058Z" fill="#506F8B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.807 1.32112C12.6529 0.47522 13.8001 0 14.9964 0C16.1927 0 17.34 0.47522 18.1859 1.32112C19.0318 2.16701 19.507 3.3143 19.507 4.51058V20.238C19.507 20.661 19.1641 21.004 18.741 21.004C18.318 21.004 17.9751 20.661 17.9751 20.238V4.51058C17.9751 3.72058 17.6613 2.96294 17.1027 2.40433C16.5441 1.84572 15.7864 1.53189 14.9964 1.53189C14.2064 1.53189 13.4488 1.84572 12.8902 2.40433C12.3316 2.96294 12.0177 3.72058 12.0177 4.51058C12.0177 5.26704 12.4984 5.79736 13.2912 6.59021C13.313 6.61198 13.3351 6.634 13.3574 6.65627C14.0581 7.35546 15.0134 8.30876 15.0134 9.75306C15.0134 10.5518 14.7209 11.4244 14.3649 12.2098C14.0013 13.012 13.5335 13.8039 13.1071 14.463C12.8774 14.8181 12.4032 14.9198 12.048 14.69C11.6928 14.4602 11.5912 13.986 11.821 13.6308C12.2254 13.0057 12.6493 12.2843 12.9697 11.5774C13.2977 10.8537 13.4815 10.2215 13.4815 9.75306C13.4815 8.9966 13.0009 8.46627 12.208 7.67342C12.1863 7.65166 12.1642 7.62964 12.1419 7.60737C11.4412 6.90818 10.4858 5.95488 10.4858 4.51058C10.4858 3.3143 10.9611 2.16701 11.807 1.32112Z" fill="#506F8B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4858 17.2428C10.4858 16.8198 10.8288 16.4768 11.2518 16.4768H15.1182C15.5413 16.4768 15.8842 16.8198 15.8842 17.2428C15.8842 17.6658 15.5413 18.0087 15.1182 18.0087H11.2518C10.8288 18.0087 10.4858 17.6658 10.4858 17.2428Z" fill="#506F8B"/>
  </svg>

)

export default ExtrasSvg

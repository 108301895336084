
import Theme from '@/global/Theme'
import React from 'react'

const KidsSvg = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.992 14.8433C6.93667 14.8433 5.88133 14.5773 4.93933 14.0747C3.514 13.314 2.43533 12.068 1.88933 10.5553C0.801333 10.3787 0 9.45133 0 8.33533C0 7.25133 0.765333 6.33533 1.80933 6.12867C2.03133 4.69667 2.81133 3.398 4.02 2.45667C5.166 1.564 6.61667 1.052 8 1.052C9.384 1.052 10.834 1.564 11.98 2.45667C13.1887 3.39867 13.9687 4.69667 14.1907 6.12867C15.2347 6.33533 16 7.25133 16 8.33533C16 9.436 15.1827 10.3813 14.1107 10.5567C13.4153 12.4747 11.848 13.9647 9.89 14.56C9.27067 14.748 8.632 14.8433 7.992 14.8433ZM8 2.052C5.65333 2.052 2.982 3.80067 2.758 6.62467C2.73733 6.88267 2.51867 7.08534 2.25933 7.08534C1.56467 7.08534 1 7.646 1 8.33533C1 9.02467 1.56067 9.58533 2.25 9.58533C2.474 9.58533 2.674 9.73133 2.738 9.94C3.16533 11.3453 4.11467 12.5007 5.41 13.1927C6.20733 13.6187 7.10067 13.8433 7.99267 13.8433C8.534 13.8433 9.07467 13.7627 9.59933 13.6027C11.358 13.068 12.7273 11.6993 13.262 9.94C13.3267 9.728 13.5187 9.58533 13.7407 9.58533C14.4353 9.58533 15 9.02467 15 8.33533C15 7.646 14.4393 7.08534 13.75 7.08534C13.4813 7.08534 13.2627 6.88333 13.2427 6.62467C13.0747 4.50467 11.522 3.04467 9.89667 2.416C9.96467 2.61934 10 2.83334 9.99933 3.05267C9.99933 3.58667 9.79133 4.08933 9.41333 4.46667C9.03533 4.844 8.53333 5.052 7.99933 5.052C7.15333 5.052 6.39533 4.516 6.114 3.71867C6.06933 3.59267 6.07667 3.45734 6.134 3.33667C6.19133 3.216 6.29267 3.12533 6.41867 3.08067C6.472 3.062 6.52867 3.052 6.58533 3.052C6.79667 3.052 6.986 3.186 7.05667 3.386C7.19733 3.78467 7.57667 4.052 7.99933 4.05267C8.26667 4.05267 8.51733 3.94867 8.70667 3.76C8.89533 3.57134 9 3.32 9 3.05333C9 2.786 8.896 2.53467 8.70733 2.346C8.518 2.156 8.26667 2.052 8 2.052Z" fill={Theme.ColorGrey600}/>
    <path d="M8 12.334C7.39 12.334 6.80267 12.1127 6.34667 11.7107C6.24667 11.622 6.18667 11.5 6.17867 11.3667C6.17067 11.2333 6.21467 11.1047 6.30267 11.0047C6.398 10.8973 6.53467 10.8353 6.678 10.8353C6.8 10.8353 6.91733 10.88 7.00867 10.9607C7.282 11.2013 7.634 11.334 8 11.334C8.366 11.334 8.71867 11.2013 8.992 10.96C9.08333 10.8793 9.20067 10.8347 9.32267 10.8347C9.466 10.8347 9.60267 10.896 9.69733 11.0033C9.786 11.104 9.83 11.2327 9.822 11.3653C9.81333 11.4987 9.754 11.6207 9.654 11.7093C9.19733 12.1127 8.61 12.334 8 12.334Z" fill={Theme.ColorGrey600}/>
    <path d="M10.5 8.83533C9.67267 8.83533 9 8.16267 9 7.33534C9 7.05934 9.224 6.83534 9.5 6.83534C9.776 6.83534 10 7.06 10 7.33534C10 7.61067 10.224 7.83533 10.5 7.83533C10.776 7.83533 11 7.61134 11 7.33534C11 7.05934 11.224 6.83534 11.5 6.83534C11.776 6.83534 12 7.06 12 7.33534C12 8.16267 11.3273 8.83533 10.5 8.83533Z" fill={Theme.ColorGrey600}/>
    <path d="M5.5 8.83533C4.67267 8.83533 4 8.16267 4 7.33534C4 7.05934 4.224 6.83534 4.5 6.83534C4.776 6.83534 5 7.05934 5 7.33534C5 7.61067 5.224 7.83533 5.5 7.83533C5.776 7.83533 6 7.61134 6 7.33534C6 7.05934 6.224 6.83534 6.5 6.83534C6.776 6.83534 7 7.05934 7 7.33534C7 8.16267 6.32733 8.83533 5.5 8.83533Z" fill={Theme.ColorGrey600}/>
  </svg>

)

export default KidsSvg

import { getEnvironment } from '@/helpers/environment'

export function getLoginUrl () {
  return '/login'
}

export function getLogoutUrl () {
  return '/logout'
}

export function getAuthUrl () {
  switch (getEnvironment()) {
    case 'localhost':
    case 'dev':
      return 'https://common.dev.owners.awaze.com/api'
    case 'ppe':
      return 'https://common.ppe.owners.awaze.com/api'
    default:
      return 'https://owners.awaze.com/api'
  }
}

export function getAuthSigninUrl () {
  const authUrl = getAuthUrl()

  return authUrl + '/auth/login'
}

export function getAuthRefreshUrl () {
  const authUrl = getAuthUrl()

  return authUrl + '/auth/refresh'
}

export function getAccessToken (username, password) {
  return fetch(getAuthSigninUrl(), {
    method: 'post',
    headers: {
      Authorization: 'None',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username,
      password
    })
  })
}

export function refreshAccessToken (refreshToken, accessToken) {
  return fetch(getAuthRefreshUrl(), {
    method: 'post',
    headers: {
      Authorization: 'None',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ accessToken, refreshToken })
  })
}

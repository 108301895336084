import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { PageWrapper, Spinner } from '@/components/__UI'
import { useMappedUrl } from '@/hooks/useUrlMappings'
import { useTranslation } from 'react-i18next'
import { redirectTo, hasUrlParam } from '@/helpers/redirect/redirect'

const NotPermittedPage = ({ permittedByPermissions, permittedByBrand, permittedByProductMarket, brand, path }) => {
  const { t } = useTranslation()

  const shouldRedirect = useMemo(() =>
    // IF permitted by permissions
    // AND but not by brand or product market
    // AND novasol user (not awaze)
    // THEN redirect to novasol login page
    permittedByPermissions &&
      (
        !permittedByBrand ||
        !permittedByProductMarket
      ) &&
      brand !== 'awaze'
  , [permittedByPermissions, permittedByBrand, permittedByProductMarket, brand])

  const isAlreadyRedirected = hasUrlParam('isRedirect')
  const willRedirect = shouldRedirect && !isAlreadyRedirected

  const { url, isLoading } = useMappedUrl(path, brand)

  useEffect(() => {
    if (willRedirect && isLoading === false) {
      redirectTo(url)
    }
  }, [willRedirect, url, isLoading, path])

  if (willRedirect) {
    return (
      <PageWrapper>
        <Spinner />
      </PageWrapper>
    )
  }

  return (
    <PageWrapper>
      <p data-testid="generalNotPermittedError">{t('unauthorizedAccess')}</p>
    </PageWrapper>
  )
}

NotPermittedPage.propTypes = {
  permittedByPermissions: PropTypes.bool,
  permittedByBrand: PropTypes.bool,
  permittedByProductMarket: PropTypes.bool,
  brand: PropTypes.string,
  path: PropTypes.string
}

export default NotPermittedPage

import styled from 'styled-components'

const BulbSvg = styled.svg`
  width: ${({width}) => width || '27px'};
  height: ${({height}) => height || '40px'};

  g {
    fill: ${({fill, theme}) => fill || theme.colors.blackTwo};
  }
`

const FaqItemWrap = styled.div`
  position: relative;
  padding: ${({smallBar}) => smallBar ? `1.5rem` : `1.5rem 4rem 1.5rem 2rem`};
  background-color: ${({theme}) => theme.colors.greySix};
  margin-bottom: 2px;

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    padding: 1.5rem 1rem;
  }
`

const FaqCollapseIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 1.5rem;
  height: 1.5rem;
  transition: transform ${({theme}) => theme.animationSpeed} ease;

  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 1px;
    background-color: ${({theme}) => theme.colors.blackTwo};
    transition: transform ${({theme}) => theme.animationSpeed} ease;
  }

  &:after {    
    transform: rotate(90deg);
    
    ${({isOpen}) => isOpen && `
      transform: rotate(0deg);
    `}
  }
`

const FaqItemTitle = styled.div`
  position: relative;
  font-weight: 600;
  cursor: pointer;
  padding-right: 2rem;

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    padding-right: 1.5rem;
  }
`

const FaqContainer = styled.div`
  padding-top: 1rem;
`

const FaqList = styled.div`
  position: relative;
  padding: ${({smallBar}) => smallBar ? `1rem` : `2rem`};
  background-color: ${({theme}) => theme.colors.white};

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    padding: 1rem;
  }
`

const FaqListDesc = styled.div`
  padding-bottom: 2rem;

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    padding-bottom: 1rem;
  }
`

const SearchMatches = styled.p`
  text-align: center;
  margin-bottom: 1rem;
`

export {
  BulbSvg,
  FaqItemWrap,
  FaqCollapseIcon,
  FaqItemTitle,
  FaqList,
  FaqContainer,
  FaqListDesc,
  SearchMatches
}

import { PricingV2ResponseRows } from '@/hooks/useQuery';
import { Text } from '@awaze/design-system.components.text';

interface IncomeCellProps {
    value: PricingV2ResponseRows['income'];
    bold?: boolean;
}

export function IncomeCell({ value, bold }: IncomeCellProps) {

    if (!value) return null;

    return (<Text sizes={200} type={bold ? 'bold' : 'regular'}>{value.value}</Text>);
}
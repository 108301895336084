import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { performBookingSearch } from '@/hooks/useQuery'

export const useBookingSearch = () => {
  const { t } = useTranslation()

  const [searchErrorMessage, setSearchErrorMessage] = useState(null)
  const [booking, setBooking] = useState(null)
  const [textValue, setTextValue] = useState('')

  const search = async (value) => {
    const data = await performBookingSearch(value)
    if (data) {
      setBooking(data)
    } else {
      const errorMessage = t('bookingSearchFailed')
      setSearchErrorMessage(errorMessage)
      throw new Error(t('bookingSearchFailed'))
    }
  }

  const clear = () => {
    setBooking(null)
    setSearchErrorMessage(null)
    setTextValue('')
  }

  const onChange = (value) => {
    setSearchErrorMessage(null)
    setTextValue(value)
  }

  return {
    search,
    searchErrorMessage,
    booking,
    clear,
    onChange,
    textValue
  }
}

import styled from 'styled-components'

const ContentContainer = styled.div`
  position: relative;
  background-color: ${({theme}) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.desktopUp} {
    display: flex;
    flex-wrap: nowrap;

    & > * {
      flex: 1;
    }
  }
`

const Sidebar = styled.div`
  padding: 1rem 2rem;
  flex: none;

  ${({ theme }) => theme.mediaQueries.desktopUp} {
    width: 300px;
    padding: 2rem 1rem;
  }

  
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    padding: 1rem;
  }
`

const InfoBox = styled.div`
  padding: 1rem;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.colors.greyFive};
  margin-top: 2rem;
  font-size: 0.9rem;

  p:not(:last-of-type) {
    font-size: inherit;
    margin-bottom: 1rem;
  }
`

export { ContentContainer, Sidebar, InfoBox }

import React from 'react'
import PropTypes from 'prop-types'

const NavigationWarningModal = ({ blockNavigation }) => {
  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (blockNavigation) {
        event.preventDefault()
        // This custom message might not be shown in modern browsers.
        // Instead, a default message might be shown.
        event.returnValue = 'You have unsaved changes, are you sure you want to leave?'
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [blockNavigation])

  return null
}

NavigationWarningModal.propTypes = {
  blockNavigation: PropTypes.bool.isRequired
}

export default NavigationWarningModal

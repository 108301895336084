import styled from 'styled-components'
import Button from '@/components/__Inputs/Button'

const PriceRecommendations = styled.div`
  /* display: none !important; */
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  border-radius: .2rem;
  background-color: ${({ theme }) => theme.colors.messageBackground};
  border: 1px solid ${({ theme }) => theme.colors.messageBorder};

  position: sticky;
  top: 0;
  z-index: 5;

  .messageRecommendation{
    flex-direction: column;

    h4{
      margin-bottom: 1rem;
      font-weight: 600;
    }
  }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    position: sticky;
    top: 4rem;
    z-index: 5;
  }
`
const RecommendedPricesButton = styled(Button)`
  height: 3.7125rem;
  width: 9vw;
  border: none;

  a:link{
    text-decoration: none; 
    font-size: 8vw;
  }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    margin-top: 1rem;
    width: 40vw;
  }

  @media (min-width: 1236px) and (max-width: 1373px) {
    width: 10vw;
    margin-left: 1rem;
  }
  @media (min-width: 1030px) and (max-width: 1235px) {
    width: 12vw;
    margin-left: 1rem;
  }
  @media (min-width: 824px) and (max-width: 1030px) {
    width: 15vw;
    margin-left: 1rem;
  }
  @media (min-width: 619px) and (max-width: 824px) {
    width: 20vw;
    margin-left: 1rem;
  }
  @media (min-width: 600px) and (max-width: 619px) {
    width: 22vw;
    margin-left: 1rem;
  }
`

export { PriceRecommendations, RecommendedPricesButton }

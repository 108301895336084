import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import toast from '@/helpers/toast'
import { stripStartAndEndQuotationsFromString } from '@/helpers/stringUtils'
import useGraphQl, {
  graphQlFetch,
  UPDATE_PROPERTY_DESCRIPTION,
  GET_PROPERTY_DESCRIPTION
} from '@/hooks/useGraphQl'
import { Form, Button } from '@/components/__Inputs'
import { PropertyAmendSuccessModal } from '@/components/__Modals'
import { Container, Spinner } from '@/components/__UI'
import { PropertyDescriptionSection } from '@/components/__PropertyAmendsComponents'
import { PropertyDescriptionTimeSelect } from './PropertyDescriptionTimeSelect'
import { PropertyDescriptionEpcSelect } from './PropertyDescriptionEpcSelect'
import { ErrorText, BtnContainer } from './PropertyDescriptionEdit.styles'
import { EditableDivFormik } from '../../__UI/EditableDiv/EditableDiv'

const PropertyDescriptionEdit = ({ propertyId }) => {
  const [hasError, setHasError] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [submitDisabled, setSubmitDisabled] = useState(false)

  const [descriptionValues, loadingDescriptionValues] = useGraphQl({
    query: GET_PROPERTY_DESCRIPTION,
    variables: {
      propertyId
    }
  })

  const formElements = useMemo(() => [
    { id: 'At-a-Glance', sectionName: 'At a glance' },
    { id: 'Rooms-description', sectionName: 'Room description' },
    { id: 'Property-services', sectionName: 'Accommodation services' },
    { id: 'Property-description', sectionName: 'Accommodation description' },
    { id: 'Check-in-time', sectionName: 'Check in time', type: 'time' },
    { id: 'Check-out-time', sectionName: 'Check out time', type: 'time' },
    { id: 'EPC-Rating', sectionName: 'EPC rating', type: 'epc' },
    { id: 'Scottish-Letting-Licence', sectionName: 'Scottish letting licence' },
    { id: 'Location-services', sectionName: 'Location services' },
    { id: 'Comments-section', sectionName: 'Comments section', placeholder: 'Please write any additional comments here' }
  ], [])

  const initialValues = useMemo(() => {
    const values = {}
    if (descriptionValues) {
      descriptionValues.forEach(({ id, description }) => {
        values[id] = description
      })
    }
    return values
  }
  , [descriptionValues])

  const getChangedValues = (values) => {
    return formElements.map(element => {
      const originalData = descriptionValues.find(x => x.id === element.id)

      const value = values[element.id]?.value ?? values[element.id]
      const originalDescription = originalData?.description || ''
      const updatedDescription = stripStartAndEndQuotationsFromString(value || '')

      return {
        id: element.id,
        sectionName: element.sectionName,
        description: updatedDescription,
        originalDescription
      }
    }).filter(x => x.description !== x.originalDescription)
  }

  const handleSubmit = async (values) => {
    setHasError(false)
    setSubmitDisabled(true)

    const changedValues = getChangedValues(values)

    if (changedValues.length > 0) {
      try {
        const data = await graphQlFetch({
          query: UPDATE_PROPERTY_DESCRIPTION,
          variables: {
            propertyId,
            description: changedValues
          }
        })
        if (data.success) {
          setShowModal(true)
        } else {
          setHasError(true)
          setSubmitDisabled(false)
        }
      } catch (error) {
        setHasError(true)
        setSubmitDisabled(false)
      }
    } else {
      toast.info('No Changes detected.')
      setSubmitDisabled(false)
    }
  }

  if (loadingDescriptionValues) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  return (
    <>
      <Form
        id="PropertyDescriptionForm"
        initialValues={initialValues}
        handleSubmit={handleSubmit}
      >
        {formElements.map(item => (
          <PropertyDescriptionSection key={item.id} id={item.id} defaultValue={item.description} item={item}>
            {(item.type === 'time') && (
              <PropertyDescriptionTimeSelect id={item.id} />
            )}
            {(item.type === 'epc') && (
              <PropertyDescriptionEpcSelect id={item.id} />
            )}
            {(!item.type) && (
              <EditableDivFormik id={item.id} defaultValue={item.description} placeholder={`type in your ${item.sectionName.toLowerCase()}`} />
            )}
          </PropertyDescriptionSection>
        ))}
        {hasError && (
          <ErrorText>
            There has been an issue submitting your response.
          </ErrorText>
        )}
        <BtnContainer>
          <Button data-testid="submitAccDescBtn" primary disabled={submitDisabled} type="submit">
            Submit
          </Button>
        </BtnContainer>
      </Form>
      {showModal && <PropertyAmendSuccessModal onClose={setShowModal} />}
    </>
  )
}

PropertyDescriptionEdit.propTypes = {
  propertyId: PropTypes.string,
  disabled: PropTypes.bool
}

export default PropertyDescriptionEdit

import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'

function Spinner ({ large, spinnerColor, ...restProps }: HTMLAttributes<SVGElement> & { large?: boolean; spinnerColor?: string }) {
  return (
    <svg
      data-name='spinner'
      data-testid='spinner'
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      {...restProps}
    >
      <path d="M10 50A40 40 0 0 0 90 50A40 43.5 0 0 1 10 50" >
        <animateTransform attributeName="transform" type="rotate" dur="1.333333333333333s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51.75;360 50 51.75"></animateTransform>
      </path>
    </svg>
  )
}

const StyledSpinner = styled(Spinner)<{
  large?: boolean;
  spinnerColor?: string;
}>`
  ${({ large, spinnerColor, theme }) => `
    display:block;
    margin:auto;
    background: transparent;
    width: ${large ? 48 : 32}px;
    height: ${large ? 48 : 32}px;

    path {
      fill: ${spinnerColor || theme.colors.blackTwo};
      stroke: none;
      transform: rotate(358.001 50.0009 51.7509);
    }
  `}
`

export default StyledSpinner

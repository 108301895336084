import * as Yup from 'yup'
import React, { useState, useEffect } from 'react'
import { useUser } from '@/context/UserProvider'
import { createCookie } from '@/helpers/cookies'
import { getAccessToken } from '@/helpers/authorisations'
import { CenterWrapper } from '@/global/GlobalStyle'
import { ToggleButton, LoginForm } from './SignInLocalhost.styles'
import { Form, TextInput, Button } from '@/components/__Inputs'
import { SCHEMA_FIELD_USERNAME, SCHEMA_FIELD_PASSWORD } from '@/constants/fields'

const SignInLocalhost = () => {
  const [ loading, setLoading ] = useState(false)
  const [ formError, setFormError ] = useState(null)
  const [showLogin, setShowLogin] = useState(false)

  const { login } = useUser()

  useEffect(() => {
    return () => {
      setLoading(false)
      setFormError(null)
    }
  }, [])

  const handleSubmit = async (values) => {
    const username = values.username.toLowerCase()
    const password = values.password

    setLoading(true)
    setFormError(null)

    try {
      const response = await getAccessToken(username, password)
      let errorMessage = null

      switch (response.status) {
        case 500:
          errorMessage = 'There was a problem. Please try again'
          break
        case 401: // Username / password invalid
          errorMessage = 'Incorrect username or password. Please try again or contact Owner services.'
          break
        case 400: // missing param, should not be possible with form validation
          errorMessage = 'Could not log you in with those details. please try again.'
          break
        case 205:
          errorMessage = 'Password reset is necessary'
          break
        case 200:
        case 201:
          const tokens = await response.json()
          const oneYear = new Date()

          oneYear.setFullYear(oneYear.getFullYear() + 1)

          // remove before live
          createCookie('idToken', tokens.idToken, oneYear, '/')
          createCookie('accessToken', tokens.accessToken, oneYear, '/')
          createCookie('refreshToken', tokens.refreshToken, oneYear, '/')
          createCookie('tokenExpires', tokens.expiresIn, oneYear, '/')

          login(username)
          setLoading(false)

          break
        default:
          errorMessage = 'An unknown error occurred, please try again.'
      }

      setLoading(false)
      setFormError(errorMessage)
    } catch (err) {
      setLoading(false)
      setFormError('There was an error please try again')
    }
  }

  return (

    <>
      <ToggleButton onClick={() => setShowLogin(!showLogin)} hidden={showLogin}>Toggle Login</ToggleButton>
      <LoginForm show={showLogin} data-testid='login-form'>
        <Form
          id="SignInForm"
          initialValues={{ username: '', password: '' }}
          validationSchema={Yup.object({
            username: SCHEMA_FIELD_USERNAME,
            password: SCHEMA_FIELD_PASSWORD
          })}
          handleSubmit={handleSubmit}
          submitError={formError}
        >
          <TextInput
            name="username"
            placeholder='Email address'
            type='email'
            autoComplete='email'
          />

          <TextInput
            name='password'
            type='password'
            placeholder='Password'
          />

        </Form>

        <CenterWrapper mb="1em">
          <Button primary type="submit" form="SignInForm" loading={loading}>Sign in</Button>
        </CenterWrapper>
      </LoginForm>
    </>
  )
}

export default SignInLocalhost

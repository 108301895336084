import styled from 'styled-components'

const allowanceLimit = '1100px'

const Section = styled.div`
  margin-top: 1rem;
  background-color: ${({theme}) => theme.colors.white};
  padding: 1rem 1rem 2rem;
`

const Year = styled.h3`
  ${({ theme }) => theme.fonts.Heading100}
  text-align: center;
`

const SeasonsContainer = styled.div`
  ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  ${() => `@media screen and (min-width: ${allowanceLimit})`} {
    flex-wrap: nowrap;
  }
`

const SeasonItem = styled.div`
  margin: 1rem 0 0;
  border: 1px solid ${({theme}) => theme.colors.greyThree};
  border-radius: ${({theme}) => theme.borderRadius};
  min-width: 250px;

  ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
    min-width: 300px;
    flex: 1;
    margin: 1rem 1rem 0;
  }

  ${() => `@media screen and (min-width: ${allowanceLimit})`} {
    min-width: auto;
  }
`

const SeasonType = styled.div`
  text-align: center;
  font-weight: 600;
  padding: 0.5rem;
  background-color: ${({type, theme}) => {
    switch (type) {
      case 'winter':
        return theme.colors.seasonWinter
      case 'peak':
        return theme.colors.seasonPeak
      case 'low':
        return theme.colors.seasonLow
      case 'summer':
        return theme.colors.seasonSummer
      default:
        break
    }
  }};
`

const SeasonContent = styled.div`
  padding: 1rem 1.5rem;
`

const SeasonContentItem = styled.div`
  display: flex;
  padding: 0.5rem 0;
  font-size: 0.85rem;
`

const SeasonContentItemTitle = styled.span`
  flex: 2;
  font-weight: 600;
`
const SeasonContentItemText = styled.span`
  flex: 1;
  padding-left: 0.5rem;
`

export { Section, Year, SeasonsContainer, SeasonItem, SeasonType, SeasonContent, SeasonContentItem, SeasonContentItemTitle, SeasonContentItemText }

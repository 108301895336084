import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { saveAs } from 'file-saver'
import { FileDownloader, Spinner, Error } from '@/components/__UI'
import { GET_FINANCIAL_STATEMENT_FILE } from '@/hooks/useGraphQl'
import { graphQlFetch } from '@/hooks/useGraphQl/helpers'
import { Container } from './OnlineStatementDownload.styles'
import convertBase64toFile from '@/helpers/files/Base64ToFile/convertBase64toFile'

const OnlineStatementDownload = ({filename, id, type, accountId, templateId}) => {
  const [hasError, setHasError] = useState(false)
  const [loadingStatement, setLoadingStatement] = useState(false)

  const createFile = (file) => {
    if (typeof file === 'string') {
      if (type.toLowerCase() === 'pdf') {
        const pdfFile = convertBase64toFile(file, 'pdf')
        return { filename, file, download: () => saveAs(pdfFile, filename) }
      }

      if (type.toLowerCase() === 'excel') {
        const excelFile = convertBase64toFile(file, 'vnd.ms-excel')
        return { filename, file, download: () => saveAs(excelFile, filename) }
      }
    }

    return 'Error'
  }

  const downloadFile = async () => {
    setLoadingStatement(true)
    setHasError(false)

    await graphQlFetch({
      query: GET_FINANCIAL_STATEMENT_FILE,
      variables: {
        accountId,
        paymentRunId: id,
        templateId,
        documentType: type
      }
    })
      .then(({fileStream}) => {
        const file = createFile(fileStream)
        if (typeof file === 'string') {
          setHasError(true)
        } else {
          file.download()
        }
      })
      .catch((err) => {
        console.error(err)
        setHasError(true)
      })
      .finally(() => {
        setLoadingStatement(false)
      })
  }

  if (loadingStatement) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  return (
    <Container>
      <FileDownloader
        type={type}
        title={`${type.toUpperCase()} Statement`}
        download={() => downloadFile()}
        iconStyle={{height: '32px'}}
      />
      {hasError && <Error data-testid="statementDownloadError">There was an issue downloading this statement.</Error>}
    </Container>
  )
}

OnlineStatementDownload.propTypes = {
  filename: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export default OnlineStatementDownload

import React from 'react'
import PropTypes from 'prop-types'
import { Container, Icon, DownloadLink } from './FileDownloader.style'

import IconWord from '@/assets/icons/icon-word.png'
import IconExcel from '@/assets/icons/icon-excel.png'
import IconPdf from '@/assets/icons/icon-acrobat.png'

const FileDownloader = ({file, type, title, download, iconStyle}) => {
  const getIcon = (type) => {
    const fileType = type || file.match(/\.[0-9a-z]+$/i)[0].substring(1)

    switch (fileType.toLowerCase()) {
      case 'pdf':
        return IconPdf
      case 'doc':
        return IconWord
      case 'docx':
        return IconWord
      case 'xls':
        return IconExcel
      case 'excel':
        return IconExcel
      default:
        return IconPdf
    }
  }

  return (
    <Container>
      <Icon style={iconStyle} src={getIcon(type)} alt='File Icon' />
      {download
        ? <DownloadLink onClick={() => download()}>{title}</DownloadLink>
        : <DownloadLink href={file} target='_blank'>{title}</DownloadLink>
      }
    </Container>
  )
}

FileDownloader.propTypes = {
  title: PropTypes.string.isRequired,
  file: PropTypes.string,
  type: PropTypes.string,
  download: PropTypes.func,
  iconStyle: PropTypes.object
}

export default FileDownloader

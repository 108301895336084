import { useUser } from '@/context/UserProvider'
import { useTranslation } from 'react-i18next'

export const useQuickNavigationLinks = () => {
  const { t } = useTranslation()
  const { hasPermission } = useUser()

  const hasBookings = hasPermission('Bookings')
  const hasChart = hasPermission('Chart')
  const hasCalendar = hasPermission('Calendar')
  const hasCreateBookings = hasPermission('CreateBooking')
  const hasFinancial = hasPermission('Financial')
  const hasNews = hasPermission('News')

  const links = [
    {
      name:  t('createBooking'),
      url: '/bookings/calendar',
      permission: hasCalendar && hasCreateBookings
    },{
      name: t('createBooking'),
      url: '/bookings/chart',
      permission: hasChart && hasCreateBookings
    },{
      name: t('seeMyBookings'),
      url: '/bookings/list',
      permission: hasBookings
    },{
      name: t('myStatements'),
      url: '/financial/statements',
      permission: hasFinancial
    },{
      name: t('latestNews'),
      url: '/news',
      permission: hasNews,
      serverRouting: true
    }
  ]

  const filteredLinks = links.filter((link) => link.permission)

  return filteredLinks.length === 0 ? null : filteredLinks
}

export default useQuickNavigationLinks

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@awaze/design-system.components.box'
import { Text } from '@awaze/design-system.components.text'
import { Reviews } from '@awaze/design-system.components.reviews'
import { Middot } from '@awaze/design-system.components.icon'
import { ReviewHeader, ReviewerInfo, TextWithMargin, TextWithFlex, StyledBox, StyledFlag, NoWrap } from './ReviewList.styles'
import { formatShortMonthDate, formatToNumericDate } from '@/helpers/dateUtils'
import TranslateReview from './sections/TranslateReview'
import RespondReview from './sections/RespondReview'

interface Review {
  id: string;
  ownerResponse: {
    text: string;
    responseDateTime: string;
    responseState: string;
  };
  reviewedBy: {
    name?: string;
    location?: string;
  };
  score?: number;
  review?: string;
  reviewedOn?: string;
  holidayDate?: string;
  language?: string;
  canTranslate: boolean;
  canRespond: boolean;
}

interface ReviewListProps {
  property: {
    propertyCode: string;
    propertyId: string;
  };
  targetLanguage: string;
  reviews: Review[];
}

const ReviewList = ({ reviews, property, targetLanguage }: ReviewListProps) => {
  const { t } = useTranslation()

  const showResponseSection = (ownerResponse) => {
    return ownerResponse.text || ownerResponse.responseState === 'PendingPublication' || ownerResponse.responseState === 'ModeratedSubmission'
  }

  const textColor = (responseState) => {
    return responseState === 'LiveResponseExists' ? undefined : 'ColorGrey600'
  }

  return (
    <>
      {reviews.map((review) => (
        <Box
          key={review.id}
          colourScheme="white"
          shadow={1}
          margin={[24, 16, 16]}
          padding={[16, 16, 16]}
        >
          <ReviewHeader>
            <ReviewerInfo>
              {review.reviewedBy.name && (
                <Text sizes={300} type="bold" data-testid='reviewer-name'>
                  {review.reviewedBy.name}
                </Text>
              )}
              {(review.reviewedBy.location || review.holidayDate || review.language) && (
                <TextWithFlex sizes={100} data-testid='reviewer-details' style={{ flexWrap: 'wrap' }}>
                  {review.language && (<StyledFlag lang={review.language} />)}
                  {(review.language && review.reviewedBy.location) && <Middot iconColour='ColorGrey500' size='medium'/>}
                  {review.reviewedBy.location}

                  <NoWrap>
                    {(review.language || review.reviewedBy.location) &&
                    <Middot iconColour='ColorGrey500' size="medium" />}
                    {t('confirmedGuest')}
                    {review.holidayDate && ` ${formatToNumericDate(review.holidayDate)}`}
                  </NoWrap>
                </TextWithFlex>
              )}
            </ReviewerInfo>

            {review.score && (
              <div data-testid='review-score' style={{ minWidth: '3rem' }}> {/* Until DS in place */}
                <Reviews
                  score={review.score}
                  translations={{
                    outOf: t('outOf'),
                    rating: 'Feefo rating'
                  }}
                />
              </div>
            )}

          </ReviewHeader>
          {review.review && (
            <TextWithMargin sizes={300} data-testid='review-message'>
              {review.review}
            </TextWithMargin>
          )}
          {review.reviewedOn && (
            <Text sizes={100} data-testid='review-date'>
              {t('published')} {formatShortMonthDate(review.reviewedOn)}
            </Text>
          )}
          {
            review.review && (
              <>
                <TranslateReview
                  propertyCode={property.propertyCode}
                  reviewId={review.id}
                  targetLanguage={targetLanguage}
                  canTranslate={review.canTranslate}
                />
                {review.canRespond && (
                  <RespondReview
                    propertyId={property.propertyId}
                    reviewId={review.id}
                  />)}
                {showResponseSection(review.ownerResponse) && (
                  <StyledBox padding={[
                    [8, 8, 8, 8],
                    [8, 8, 8, 8],
                    [8, 8, 8, 8]
                  ]} colourScheme="darken10">
                    <Text type="bold" mb={8} color={textColor(review.ownerResponse.responseState)} sizes={300}>{t('yourResponse')}</Text>
                    <Text sizes={300} color={textColor(review.ownerResponse.responseState)} mb={8}>{review.ownerResponse.text}</Text>
                    {review.ownerResponse.responseState === 'PendingPublication' && (
                      <Text sizes={100}>
                        {t('pendingPublicationMessage', {
                          reviewDate: formatShortMonthDate(review.ownerResponse.responseDateTime)
                        })}
                      </Text>
                    )}
                    {review.ownerResponse.responseState === 'ModeratedSubmission' && (
                      <Text sizes={100}>
                        {t('pendingPublicationMessage', {
                          reviewDate: formatShortMonthDate(review.ownerResponse.responseDateTime)
                        })}
                      </Text>
                    )}

                    {review.ownerResponse.responseState === 'LiveResponseExists' && (
                      <Text sizes={100}>
                        {t('published')} {`${formatShortMonthDate(review.ownerResponse.responseDateTime)}`}
                      </Text>)}
                  </StyledBox>
                )}
              </>
            )
          }

        </Box>
      ))}
    </>
  )
}

export default ReviewList


import React from 'react'
import styled from 'styled-components'
import Table from '@/components/Table'
import Button from '@/components/__Inputs/Button'

const StyledRow = styled(Table.Row)`
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    .table-row-inner {
        padding: 0.5rem;
    }
  }
`

const EditButton = styled(props => (<Button secondary {...props} />))`
  width: 10rem;
  display: block;
  margin-left: auto;

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    margin: auto;
    margin-top: 1rem;
    width: 4.5rem;
  }
`
const EditButtonEmpty = styled.div`
  width: 10rem;
  display: block;
  margin-left: auto;
  border: none;
  background: ${({theme}) => theme.colors.white};

  ${({theme}) => theme.mediaQueries.phoneOnly} {
      margin: auto;
      margin-top: 1rem;
  }
`

const StyledTableCell = styled(Table.Cell)`
  justify-content: flex-start;
  text-align: left;
  overflow-wrap: anywhere;
  & > img {
    height : 2rem;
  }
`

const MobileCell = styled.div`
  width: 90%;
  font-size: 1rem;
  padding-top: 1rem;
  margin-right: 0.5rem;

  & > div {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.7rem;
    align-items: center;
    justify-content: space-between;

    .data{
      flex: 1;
      width: 30%;
      margin-right: 0.7rem;
      padding-top: 0.8rem;
    }
    
    .value{
      flex: 2;
      width: 65%;
      word-wrap: break-word;
      padding-top: 0.8rem;
    }
  }

  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    margin: 0;
    padding: 0;
  }
`

const PopupSite = styled.p`
  font-size: 1rem;
  margin: 1rem 0.5rem;
`

export { StyledRow, EditButton, MobileCell, StyledTableCell, PopupSite, EditButtonEmpty }

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import toast from '@/helpers/toast'
import { Button } from '@awaze/design-system.components.button'
import { ModalBody, ModalContent } from '@awaze/design-system.components.modal'
import { queryKeyPropertyPricingSummary, queryKeyUserProperties, useMutationRecommendedPricingUpdate } from '@/hooks/useQuery'
import { ButtonSection, PropertyList } from '../AcceptanceModal.styles'
import { getLocale } from '@/helpers/locale'
import { ButtonDS } from '@/components/__Inputs'

function groupByYear (filteredProperties) {
  const propertiesByYear = {}

  for (const pr of filteredProperties) {
    if (pr.years?.length > 0) {
      for (const { year } of pr.years) {
        if (propertiesByYear[year] == null) {
          propertiesByYear[year] = []
        }
        propertiesByYear[year].push({
          propertyId: pr.propertyId,
          propertyCode: pr.propertyCode,
          recommendationState: pr.isAccepted ? 'approved' : 'rejected'
        })
      }
    }
  }

  return propertiesByYear
}

interface ConfirmationStepProps {
    filteredProperties: any;
    gotoNextStep: () => void;
    closeModal: () => void;
}

export function ConfirmationStep ({ filteredProperties, gotoNextStep, closeModal }: ConfirmationStepProps) {
  const { t } = useTranslation()

  const queryClient = useQueryClient()
  const { mutateAsync: updateRecommendedPricing, isLoading } = useMutationRecommendedPricingUpdate()

  const sendPropertiesState = async () => {
    const propertiesByYear = groupByYear(filteredProperties)

    try {
      const queries = Object.entries<any>(propertiesByYear).map(([year, approvedOrRejectedProperties]) => {
        return updateRecommendedPricing({
          year: Number(year),
          language: getLocale(),
          referenceDataToUpdate: approvedOrRejectedProperties
        })
      })

      const response = await Promise.all(queries)
      const success = response.every(resp => resp.success)

      if (success) {
        queryClient.invalidateQueries(queryKeyPropertyPricingSummary)
        queryClient.invalidateQueries(queryKeyUserProperties('viewFinancialReports'))
        gotoNextStep()
      } else {
        toast.error(t('errorMessageSomethingWentWrong'))
      }
    } catch {
      toast.error(t('errorMessageSomethingWentWrong'))
    }
  }

  return (
    <ModalContent header={t('areYouSurePleaseConfirm')}>
      <ModalBody>
        <PropertyList>
          {filteredProperties.map((item) => (
            <li key={item.propertyId}>
              {item.propertyName}:{' '}
              {item.isAccepted
                ? <span className='accepted'>{t('accepted')}</span>
                : <span className='rejected'>{t('rejected')}</span>}
            </li>
          )
          )}
        </PropertyList>
        <ButtonSection>
          <Button data-testid="acceptanceModalCancelButton" text={t('cancel')} onClick={closeModal} variant="outline" colourScheme='brand-primary' />
          <ButtonDS data-testid="confirmBtn" text={t('confirm')} onClick={sendPropertiesState} colourScheme='brand-primary' loading={isLoading} />
        </ButtonSection>
      </ModalBody>
    </ModalContent>
  )
}

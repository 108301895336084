import React from 'react'
import PropTypes from 'prop-types'
import { Container, Spinner } from '@/components/__UI'
import { PropertyDescriptionSection } from '@/components/__PropertyAmendsComponents'
import { Content } from './PropertyArrival.styles'
import useGraphQl, { GET_PROPERTY_ARRIVAL_DETAILS } from '@/hooks/useGraphQl'

const PropertyArrival = ({propertyId}) => {
  const [ arrivalValues, loadingArrivalValues ] = useGraphQl({
    query: GET_PROPERTY_ARRIVAL_DETAILS,
    variables: {
      propertyId
    }
  })

  if (loadingArrivalValues) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  if (!arrivalValues) {
    return (
      <p>{`Unable to find this property's arrival details.`}</p>
    )
  }

  if (arrivalValues && arrivalValues.length < 1) {
    return (
      <p>{`This property currently has no arrival details.`}</p>
    )
  }

  return (
    arrivalValues.map((item, x) => (
      <PropertyDescriptionSection key={x} item={item}>
        <Content dangerouslySetInnerHTML={{__html: item.description}} />
      </PropertyDescriptionSection>
    ))
  )
}

PropertyArrival.propTypes = {
  propertyId: PropTypes.string,
  showEdit: PropTypes.bool
}

export default PropertyArrival

import React from 'react'
import { PageWrapper } from '@/components/__UI'
import { MyAccountForm } from '@/components/__MyAccount'
import { Content, InfoSection } from './MyAccountPage.styles'

const MyAccountPage = () => {
  return (
    <PageWrapper
      title='Manage account details'
    >
      <Content>
        <MyAccountForm />
        <InfoSection>
          <p>{`The information we currently hold for you is shown in this form.
          If any of your contact details have changed, please let us know by amending them in the form and clicking the SAVE
          CHANGES button. We will then update our records accordingly and be sure to use them next time we need to contact
          you.`}</p>
          <p>We&apos;ll send all your booking updates and owner booking confirmations to you by email
          as well as any ongoing commercial updates which you need to be aware of. From time to time, we&apos;ll also send you
          offers from carefully selected third party suppliers who can offer products and services to add value to your
          holiday accommodation. If you would prefer not to receive these offers, please email <a href="mailto:ownermarketing@cottages.com">ownermarketing@cottages.com</a>. Please be assured that we will
          never pass your details on to third parties without your permission.</p>
        </InfoSection>
      </Content>
    </PageWrapper>
  )
}

export default MyAccountPage

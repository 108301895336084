import dayjs from 'dayjs'

export const isChangeoverDay = (segments) => {
  const formattedDates = segments.map(segment =>
    ({
      startDay: dayjs(segment.startDate).format('YYYY-MM-DD'),
      endDay: dayjs(segment.endDate).format('YYYY-MM-DD')
    })
  )

  const datesSet = new Set()

  for (const { startDay, endDay } of formattedDates) {
    if (datesSet.has(startDay)) {
      return true
    }
    datesSet.add(endDay)
  }

  return false
}

export const isDateInPast = (currentDate) => {
  return currentDate.isBefore(dayjs(), 'day')
}

export const isDateWithinUnavailableRanges = (currentDate, unavailableDates) => {
  if (unavailableDates?.length > 0) {
    return !!unavailableDates.find(({ startDateIso, endDateIso }) => {
      const today = dayjs().startOf('day')
      const isTodayUnavailable = dayjs(today).isBetween(dayjs(startDateIso), dayjs(endDateIso), 'day', '[]')

      let effectiveStartDate = dayjs(startDateIso)
      if (isTodayUnavailable && dayjs(currentDate).isSame(today, 'day')) {
        effectiveStartDate = today
      }

      return dayjs(currentDate).isSameOrAfter(today) &&
               dayjs(currentDate).isBetween(effectiveStartDate, dayjs(endDateIso), 'day', '[]')
    })
  }
  return false
}
export function isDateAvailable (state, date) {
  if (state === undefined) {
    return false
  }

  if (state.bookabilityState === 'allDatesBookable') {
    return true
  }

  if (state.bookabilityState === 'noDatesBookable') {
    return false
  }

  if (state.bookabilityState === 'onlyReturnedDatesBookable') {
    return state.bookableDates.includes(date)
  }

  throw new Error('Invalid bookability state')
}

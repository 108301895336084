import React, { useState } from 'react';
import { Table, SpinnerContainer, ForwardArrowContainer } from './Table.styles';
import TableHead from '../table_head/TableHead';
import TableBody from '../table_body/TableBody';
import { Spinner } from '@/components/__UI';
import { useBookingChartBookingsContext } from '../../context/Bookings/BookingChartBookingsProvider';
import ForwardArrow from '../../ForwardArrow/ForwardArrow';
import useWindowSize from '@/hooks/useWindowSize';

const ChartTable = () => {
  const { bookingsByUnit } = useBookingChartBookingsContext();
  const { width } = useWindowSize();
  const isDesktop = width > 1199;
  const fixedArrowPositionForLArgerUnits = 318;
  const tableHeightDivision = 2;
  const minimumTableHeight = 1000;

  const [height, setHeight] = useState(0);

  const getHeightFromTop = (tableHeight) => {
    /* istanbul ignore next */
    if (tableHeight < minimumTableHeight) {
      return tableHeight / tableHeightDivision;
    }
    /* istanbul ignore next */
    return fixedArrowPositionForLArgerUnits;
  };

  const measuredRef = React.useCallback(node => {
    if (node !== null) {
      setHeight(getHeightFromTop(node.getBoundingClientRect().height));
    }
  }, []);

  if (bookingsByUnit === null) {
    return (
      <SpinnerContainer>
        <Spinner large />
      </SpinnerContainer>
    );
  }

  return (
      <>
        {/* istanbul ignore next */}
        {isDesktop && (
          <ForwardArrowContainer style={{ top: height + 'px' }}>
            <ForwardArrow />
          </ForwardArrowContainer>
        )}
        <Table ref={measuredRef}>
          <TableHead />
          <TableBody />
        </Table>
      </>
    )};

export default ChartTable;

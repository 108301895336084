import React, { useEffect } from 'react'
import { getEnvironment } from '@/helpers/environment'
import { useLocation } from 'react-router-dom'
import { PageWrapper, Spinner } from '@/components/__UI/'

export const OwnerServiceRedirect = () => {
  const location = useLocation()

  const shouldRedirect = true
  const isLocalhost = getEnvironment() === 'localhost'
  const willRedirect = shouldRedirect && !isLocalhost

  useEffect(() => {
    if (willRedirect) {
      window.location.replace(location.pathname)
    }
  }, [willRedirect, location.pathname])

  return (
    <PageWrapper>
      <Spinner />
    </PageWrapper>
  )
}

import styled from 'styled-components'

export const TableControls = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 1rem;
`

export const TableControlsRight = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`

import React from 'react';
import { OwnerFooter } from '@awaze/design-system.components.owner-footer';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useQuickNavigationLinks from './quickNavLinks/useQuickNavigationLinks';
import CompanyInformation from './companyInfo/CompanyInfo';
import { FooterWrapper } from './Footer.styles';
import {
  getAppStoreBadgeLocalized,
  getAppStoreUrlsLocalized,
} from './appStoreHelpers/appStoreHelpers';
import {
  getGooglePlayBadgeLocalized,
  getGooglePlayUrlsLocalized,
} from './googlePlayHelpers/googlePlayHelpers';
import {useUserBrand} from '@/hooks/useUserBrand/useUserBrand'

const Footer = () => {
  const { t } = useTranslation();
  const links = useQuickNavigationLinks();
  const companyInformation = CompanyInformation();
  const quickNavigationTitle = (
    <span data-testid="quick-nav-title">{t('quickNavigation')}</span>
  );
  const privacyLink = {
    name: t('privacyNotice'),
    url: 'https://www.awaze.com/privacy-notice/',
  };
  const appDownloadTitle = t('downloadApp');
  const appDownload = [
    {
      img: getAppStoreBadgeLocalized(),
      url: getAppStoreUrlsLocalized(),
      alt: 'App Store',
    },
    {
      img: getGooglePlayBadgeLocalized(),
      url: getGooglePlayUrlsLocalized(),
      alt: 'Google Play',
    },
  ];

  const userProductMarkets = useUserBrand().userProductMarkets != 'UKHoseasons'

  return (
    <FooterWrapper>
      <OwnerFooter
        quickNavigationTitle={quickNavigationTitle}
        privacyLink={privacyLink}
        quickNavigationLinks={links}
        companyInformation={companyInformation}
        LinkComponent={NavLink}
        appDownloadTitle={userProductMarkets && appDownloadTitle}
        appDownload={userProductMarkets && appDownload}
      />
    </FooterWrapper>
  );
};

export default Footer;

import React, { createContext, useContext, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import useGraphQl, { LIST_BOOKING_BY_ID } from '@/hooks/useGraphQl'
import toast from '@/helpers/toast'

export const BookingChartBookingByIdContext = createContext({
  bookingId: null,
  loadingBookingById: true
})

export const useBookingChartBookingByIdContext = () => useContext(BookingChartBookingByIdContext)

export const BookingChartBookingByIdProvider = ({ children }) => {
  const [bookingByIdParameters, setBookingByIdParameters] = useState({})
  const contextBookingById = useRef({})
  const [cancelledBookingId, setCancelledBookingId] = useState(null)
  const [cancelledPropertyAndUnit, setCancelledPropertyAndUnit] = useState(null)

  const [, isLoadingBooking] = useGraphQl({
    query: LIST_BOOKING_BY_ID,
    enabled: bookingByIdParameters &&
              bookingByIdParameters.bookingId !== undefined &&
              bookingByIdParameters.propertyId !== undefined,
    variables: {
      Id: bookingByIdParameters.bookingId,
      propertyId: bookingByIdParameters.propertyId
    },
    onSuccess: (data) => {
      contextBookingById.current = data
    },
    onError: (err) => {
      toast.error('Failed to load booking', null, err)
    }
  })

  return (
    <BookingChartBookingByIdContext.Provider value={{
      bookingId: bookingByIdParameters.bookingId,
      setBookingByIdParameters: setBookingByIdParameters,
      contextBookingById: contextBookingById.current,
      isLoadingBooking: isLoadingBooking,
      bookingByIdParameters: bookingByIdParameters,
      cancelledBookingId: cancelledBookingId,
      setCancelledBookingId: setCancelledBookingId,
      cancelledPropertyAndUnit: cancelledPropertyAndUnit,
      setCancelledPropertyAndUnit: setCancelledPropertyAndUnit
    }}>
      {children}
    </BookingChartBookingByIdContext.Provider>
  )
}

BookingChartBookingByIdProvider.propTypes = {
  children: PropTypes.object
}

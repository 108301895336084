import React from 'react';
import PropertyList from '../propertyList/PropertyList';
import ColorLegend from '../colorLegend/ColorLegend';
import ChartTable from '@/components/__BookingChartComponents/unit_chart_table/table/Table'
import { useBookingChartContext } from '@/components/__BookingChartComponents/context/BookingChartProvider'
import { UnitsAndSitesContainer, ColorLegendPageContainer, MainTableUnitContainer, ScrollTableContainer
} from './PropertyTableLayout.styles';

export const PropertyTableLayout = () => {
    const { noResults } = useBookingChartContext();
  
    if (noResults){
      return null
    }

return (
      <MainTableUnitContainer>
        <UnitsAndSitesContainer>
          <PropertyList />
          <ColorLegendPageContainer>
            <ColorLegend />
          </ColorLegendPageContainer>
        </UnitsAndSitesContainer>
        <ScrollTableContainer>
          <ChartTable />
        </ScrollTableContainer>
      </MainTableUnitContainer>
    )}

    export default PropertyTableLayout;
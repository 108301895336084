import styled from 'styled-components'

const Content = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  border-radius: ${({theme}) => theme.borderRadius};
  padding: 2rem;
`

const SideBarTitle = styled.h3`
  ${({ theme }) => theme.fonts.Heading100}
  padding: 1rem 1rem 0;
  margin: 0;
`

export { Content, SideBarTitle }

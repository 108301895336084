import React from 'react'
import { CheckboxLabel } from './PermissionsCheckbox.styles'
import { Checkbox } from '@/components/__Inputs'
import theme from '@/global/Theme'
import PropTypes from 'prop-types'

const PermissionsCheckbox = ({name, label, disabled, value, right, isPermissionsCheckBox, isPermissionSet, registerTemporarySelection}) => {
  return (
    <Checkbox
      name={name}
      value={value}
      label={<CheckboxLabel>{label}</CheckboxLabel>}
      color={theme.colors.secondary}
      tickColor={theme.colors.white}
      borderColor={theme.colors.greyThree}
      disabled={disabled}
      right={right}
      isPermissionsCheckBox={isPermissionsCheckBox}
      isPermissionSet={isPermissionSet}
      registerTemporarySelection={registerTemporarySelection}
    />
  )
}

PermissionsCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  isPermissionsCheckBox: PropTypes.bool,
  right: PropTypes.bool,
  setPermissionChangeRegistered: PropTypes.func,
  registerTemporarySelection: PropTypes.func,
  isPermissionSet: PropTypes.bool
}

export default PermissionsCheckbox

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { PageWrapper } from '@/components/__UI'
import { StyledContainer } from './HumanRightsEthicsPage.styles'

const HumanRightsEthicsPage = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <PageWrapper
      title={`Human rights & ethics`}
    >
      <StyledContainer>
        <div>
          <h2>Human rights & ethics guidance: Modern Day Slavery</h2>
          <p>This guidance document will help you understand what modern slavery is, how the Modern Slavery Act 2015 can impact your business and how to take steps to address any areas of concern.</p>
          <ol>
            <li>What is Modern Slavery?</li>
            <li>Signs of Modern Slavery</li>
            <li>What does Modern Slavery mean within the travel and tourism industry?</li>
            <li>Why is this important to you?</li>
            <li>Working Conditions</li>
            <li>Contractual Terms of Employment</li>
            <li>Protection of Children</li>
            <li>What to do if you suspect Modern Slavery</li>
            <li>Further Guidance</li>
          </ol>
        </div>

        <div>
          <h3>1. What is Modern Slavery?</h3>
          <p>Modern slavery is very broadly defined to cover all forms of slavery, servitude, forced and compulsory labour, human trafficking and exploitation. It is often hidden and some victims don’t self-identify, which means it can be hard to quantify. The number of people identified as victims of modern slavery has been rising each year, with over 10,000 people referred to authorities in the UK in 2019. The real number of people trapped in slavery is estimated to be much higher.</p>
          <p>There are a few different types of exploitation that victims may experience. In the travel and tourism industry, this is most likely to be labour exploitation, which is the most common type of modern slavery in the UK. Some relevant examples in our industry could be:</p>
          <ul>
            <li>a handyman that is forced to work for little or low wages</li>
            <li>a cleaner that has been trafficked to the UK to work</li>
            <li>where an owner is forced to hand over their property income to another party</li>
            <li>further down in the supply chain; for example, a supplier of ours may be using slaves or child labour to produce items they sell to us or services they provide to us</li>
          </ul>
          <p>Victims are controlled by force, threats, coercion, abduction, fraud and deception.</p>
        </div>

        <div>
          <h3>2. Signs of Modern Slavery</h3>
          <p>Modern slavery can be hard to spot, but there are certain warning signs to look out for to identify whether someone is in slavery, for example where a person:</p>
          <ul>
            <li>appears to be under the control of someone else and is reluctant to interact with others</li>
            <li>does not have access to their passports or identity documents or has false identity documents</li>
            <li>has few personal belongings, wears the same clothes every day or wears unsuitable clothes for work</li>
            <li>is not be able to move around freely</li>
            <li>shows signs that their movements are being controlled</li>
            <li>believes that they are obliged to work without pay</li>
            <li>works with little or low payment, or has no access to their earnings</li>
            <li>is charged for services they don’t want or need</li>
            <li>is reluctant to talk to strangers or the authorities</li>
            <li>has limited contact with their families or with people outside of their immediate environment</li>
            <li>is in a situation of dependence-depends on their employer for work, transport and accommodation without any choice</li>
            <li>appears frightened, withdrawn, or shows signs of physical or psychological abuse</li>
          </ul>
        </div>

        <div>
          <h3>3. What does Modern Slavery mean within the travel and tourism industry?</h3>
          <p>By its very nature, the travel and tourism industry is based on highly complex supply chains operating across national borders and jurisdictions. Whilst it is not unique in this structure, the very public nature of the services being supplied mean that any concerns about employment or other human rights abuses can very quickly and seriously adversely consumer confidence in the tourism sector.</p>
          <p>You may think that modern slavery does not exist in your line of work or in this industry. While we very much hope that is the case, modern slavery is a broad term and we must all be aware of exactly what it is and what the warning signs are. Increased awareness and reporting helps law enforcement to tackle the perpetrators of modern slavery as well as protecting victims and allows the Awaze business to comply with its regulatory obligations. Awaze is committed to protecting human rights in its sphere of influence by ensuring that modern day slavery does not exist in any part of our business or in the businesses of the suppliers and owners that we work with.</p>
        </div>

        <div>
          <h3>4. Why is this important to you?</h3>
          <p>Not only is following and promoting ethical business practices the right thing to do, it also increases consumer confidence in a company.</p>
          <p>Offences under the Act apply to everyone. Ensure that you are aware of the signs of modern slavery and report anything suspicious (see section 8 of this guidance). In addition, ensure that you observe good working practices (see parts 5-7 of this guidance) which apply to hotel staff, ground handlers, excursion providers, excursion venues, transport providers, airport staff, airline crew, resort staff, restaurants, bars, craft venues, entertainment providers, crew on board ships, staff recruitment agencies and anyone working in the tourism supply chain. All organisations should examine their operations and address any potential human rights issues immediately.</p>
          <p>In addition, if your organisation has an annual turnover of £36 million or more you are required to publish a statement of your actions and approach to combating modern slavery. Find out more <a href='https://www.gov.uk/guidance/publish-an-annual-modern-slavery-statement' target='_blank' rel='noopener noreferrer'>here</a>.</p>
        </div>

        <div>
          <h3>5. Working Conditions</h3>
          <p>There are certain practical steps you can take to ensure that staff in your supply chain and working environment are safe. Ensure that:</p>
          <ul>
            <li>Staff have a good working environment (think about light, space, temperature, cleanliness, and compliance with international health & safety standards)</li>
            <li>You have a fair and just relationship with your staff, and you observe the same in your supply chain</li>
            <li>Staff are respected</li>
            <li>Passports or identity documents are not retained by employers unless mutually agreed for safekeeping. They should immediately be returned upon request of the staff</li>
            <li>Staff work a healthy number of working hours with adequate rest breaks</li>
            <li>There is transparency in your supply chain to prevent exploitation</li>
            <li>there are measures in place to gather feedback from customers and a robust complaints handling procedure.</li>
          </ul>
        </div>

        <div>
          <h3>6. Contractual Terms of Employment</h3>
          <p>Here are some practical steps regarding HR and employment that you, and employers in your supply chain, should be following:</p>
          <ul>
            <li>Implement a human resources system to manage and support employees</li>
            <li>Employees should be paid a legal minimum wage or in the absence of legislation, a wage that enables them to maintain an acceptable standard of living</li>
            <li>Staff should be provided a contract of employment incorporating terms of employment and clear description of duties, providing job security, protection of rights and benefits and social protection for families</li>
            <li>Staff should have the freedom to enter and leave their place of employment when they wish, without penalty, in accordance with their contract</li>
            <li>Staff should not be discriminated on the basis of their gender, age, race, religion, disability and sexual orientation</li>
            <li>Staff should be paid overtime or given time back in lieu in line with legislation, unless their signed contract states otherwise</li>
            <li>Staff should have the right to belong to a trade union (where legally permitted to do so)</li>
            <li>Staff should be given healthcare provisions in line with legislation e.g., company sick pay and maternity/paternity pay</li>
            <li>Employers should keep documents and be able to produce them, to show evidence of compliance in these areas</li>
          </ul>
        </div>

        <div>
          <h3>7. Protection of Children</h3>
          <p>All companies should have measures in place to protect against child labour and to ensure that children attend school. Ideally, no children under the age of 14 should be employed by an organisation. Where children are employed, special working conditions must be in place to safeguard their welfare in the workplace. These include but are not limited to: restricting hours and giving adequate rest periods. Their work should not impact on their access to education.</p>
        </div>

        <div>
          <h3>8. What to do if you suspect Modern Slavery</h3>
          <p>Awaze has zero tolerance to modern slavery and it is imperative that you raise any concerns you have as soon as possible with the police if you suspect that someone is in slavery, or has been trafficked or if you feel uncomfortable about a certain situation that you think gives rise to slavery or human trafficking concerns.</p>
          <p>Of course, if you think that someone is at imminent risk, please call 999 and ask for the police. Please DO NOT speak to the individual first, as this may put them or others at risk.</p>
        </div>

        <div>
          <h3>9. Further Guidance</h3>
          <p>More information is available from the following resources:</p>
          <ul>
            <li>The International Labour Organisation (ILO):
              <ul>
                <li>ILO Declaration on Fundamental Principles and Rights at Work (<a href='https://www.ilo.org/declaration/lang--en/index.html' target='_blank' rel='noopener noreferrer'>here</a>)</li>
                <li>Combating Forced Labour - A Handbook for Employers & Business (<a href='https://www.ilo.org/global/topics/forced-labour/publications/WCMS_101171/lang--en/index.htm' target='_blank' rel='noopener noreferrer'>here</a>). Special Action Programme to Combat Forced Labour – published 25th June 2015 (<a href='https://www.ilo.org/wcmsp5/groups/public/---ed_norm/---declaration/documents/publication/wcms_101171.pdf' target='_blank' rel='noopener noreferrer'>here</a>)</li>
              </ul>
            </li>
            <li>The Code: The Code is an organisation that provides tools and guidance to help businesses in the tourism industry manage their supply chain in regards to sexual exploitation of children in tourism (<a href='https://www.thecode.org/' target='_blank' rel='noopener noreferrer'>here</a>).</li>
            <li>ECPAT: End Child Prostitution Child Pornography & Trafficking of Children for sexual purposes (ECPAT) is a global network dedicated to protecting children. It is a useful resource for training, country reports and latest news for countries worldwide (<a href='https://www.ecpat.net/%20for%20more%20information' target='_blank' rel='noopener noreferrer'>here</a>).</li>
            <li>Further guidance is also available on the GOV.UK website.</li>
          </ul>
        </div>
      </StyledContainer>
    </PageWrapper>
  )
}

export default HumanRightsEthicsPage

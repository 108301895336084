import Theme from '@/global/Theme'
import styled from 'styled-components'

const TermsContent = styled.div`
  height: 500px;
  max-height: 50vh;
  overflow: auto;

  p {
    margin: 0.25rem 0;
  }

  b {
    font-weight: 600;
  }

  ul, ol {
    padding-left: 0.5rem;
    font-size: 100%;

    li {
      text-indent: unset;

      &:before {
        content: none;
      } 
    }
  } 
`

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  & > button {
    max-width: 45%;
  }
`

const Container = styled.div`
  width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  `

const ContractContainer = styled.div`
    width: 36.375rem; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-color: rgba(255,255,255,1);
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.51);
    margin: 0 auto;
    padding: 1.5rem;

    ${Theme.mediaQueries.phoneOnly} {
        width: 100%; 
    }
`

export { TermsContent, ButtonContainer, Container, ContractContainer }

import styled from 'styled-components'
import { Button } from '../__Inputs'

const ToggleButton = styled(Button)`
  position: fixed;
  bottom: 10px;
  left: 10px;
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    width: 95%;
  }
`

const LoginForm = styled.div`
  width: 23.3%;
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  display: ${({ show }) => (show ? 'block' : 'none')};
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    width: 95%;
  }
`

export { ToggleButton, LoginForm }

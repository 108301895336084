import { apiFetch } from '@/hooks/useQuery/helpers'

export const createCookie = (name, value, expires, path, domain) => {
  let cookie = name + '=' + escape(value) + ';'

  if (expires) {
    if (expires instanceof Date) {
      // check date is valid
      if (isNaN(expires.getTime())) {
        expires = new Date()
      }
    } else {
      expires = new Date(new Date().getTime() + parseInt(expires) * 1000 * 60 * 60 * 24)
    }

    cookie += 'expires=' + expires.toGMTString() + ';'
  }

  if (path) { cookie += 'path=' + path + ';' }
  if (domain) { cookie += 'domain=' + domain + ';' }

  document.cookie = cookie
}

export const getCookies = (c) => {
  const cookies = document.cookie.split('; ').reduce((prev, current) => {
    const [name, value] = current.split('=')
    prev[name] = value
    return prev
  }, {})

  return c ? (cookies[c] || null) : cookies
}

export const deleteCookie = (name, path, domain) => {
  if (getCookies(name)) {
    createCookie(name, '', -1, path, domain)
  }
}

export const checkExpiryCookie = () => {
  const expiresDateCookie = getCookies('tokenExpires')
  const now = new Date()
  now.setHours(0, 0, 0, 0)

  if (expiresDateCookie === null || expiresDateCookie < now) {
    return false
  } else {
    return true
  }
}

export const getUserGuidCookieValue = async () => {
  let userGuid = getCookies('userGuid')
  if (userGuid === null) {
    const nextYearsDate = new Date()
    nextYearsDate.setFullYear(nextYearsDate.getFullYear() + 1)
    userGuid = 'No User Guid'
    try {
      const response = await apiFetch('v1/user')

      createCookie('userId', response.guid, nextYearsDate, '/')
      userGuid = response.guid
    } catch (err) {
      createCookie('userId', userGuid, nextYearsDate, '/')
    }
  }
  return userGuid
}

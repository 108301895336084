const DATE_FORMAT_DEFAULT = 'YYYY-MM-DD'
const DATE_FORMAT_DISPLAY = 'DD/MM/YYYY'
const DATE_FORMAT_INCLUDE_DAY = 'dddd D. MMMM YYYY'
const DATE_FORMAT_DAY_ABBR_MONTH_YEAR = 'DD MMM YYYY'

export {
  DATE_FORMAT_DEFAULT,
  DATE_FORMAT_DISPLAY,
  DATE_FORMAT_INCLUDE_DAY,
  DATE_FORMAT_DAY_ABBR_MONTH_YEAR }

import { getCookies } from '@/helpers/cookies'
import { triggerLogout } from '@/context/UserProvider'
import { checkRefreshToken } from '@/helpers/checkRefreshToken'
import { getEnvironment } from '@/helpers/environment'

const getGraphQlUrl = () => {
  switch (getEnvironment()) {
    case 'localhost':
    case 'dev':
      return 'https://common.dev.owners.awaze.com/v1/graphql'
    case 'ppe':
      return 'https://common.ppe.owners.awaze.com/v1/graphql'
    default:
      return 'https://owners.awaze.com/v1/graphql'
  }
}

const graphQlFetch = async ({ query, variables = {} }) => {
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }

  if (getEnvironment() === 'localhost') {
    await checkRefreshToken(() => triggerLogout())
    headers.Authorization = `Bearer ${getCookies('accessToken')}`
  }

  const body = Array.isArray(query) ? buildGqlBody(query, variables) : {query, variables: {input: variables}}

  let json
  try {
    const response = await fetch(getGraphQlUrl(), {
      headers,
      method: 'POST',
      body: JSON.stringify(body)
    })

    if (response.status === 401) {
      triggerLogout()
      window.location.replace('/logout')
    }

    json = await response.json()
  } catch (err) {
    throw new Error(`[useGraphQl] ${err.message}`)
  }
  const { data, errors } = json

  // Has error(s), reject
  if (errors) {
    const error = Array.isArray(errors) ? errors[0] : errors
    throw new Error(`[useGraphQl] ${error.message}`)
  }

  // No errors, returned data on multiple query items
  if (Object.keys(data).length > 1) {
    return [...Object.keys(data).map(k => data[k])]
  }

  // Returned data for one query item
  return data[Object.keys(data)[0]]
}

const buildGqlBody = (queries, variables) => {
  const keys = ['one', 'two', 'three', 'four', 'five']
  const vars = {}

  const queryParts = queries.map(q => {
    const parts = [...q.replace(/\r?\n|\r/g, '').split('{').map(str => str.trim())]
    const typeRgx = new RegExp(/(\$input: )([^\W]+)/g).exec(parts[0])

    return {
      name: parts[1].split('(')[0],
      type: (typeRgx && typeRgx.length === 3) ? typeRgx[2] : null,
      args: parts[2].split(/\W/g).filter(x => x !== '')
    }
  })

  if (Array.isArray(variables)) {
    variables.forEach((v, i) => {
      vars[keys[i]] = v
    })
  } else {
    for (let i = 0; i < queryParts.length; i++) {
      vars[keys[i]] = variables
    }
  }

  const mappedQueries = queryParts.map(({ type }, i) => `$${keys[i]}: ${type}`).join(', ')

  const mappedArguments = queryParts.map(({ name, args }, i) => `${name}(input: $${keys[i]}) {
    ${args.join(',')}
  }`).join('')

  return {
    query: `query (${mappedQueries}) {${mappedArguments}}`,
    variables: vars
  }
}

export {
  getGraphQlUrl,
  graphQlFetch,
  buildGqlBody
}

import { useField } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { HiddenInput } from '../input.styles'
import { CheckboxArea, StyledCheckbox, ErrorMessage } from './Checkbox.styles'

const Checkbox = ({ className, label, onChange, disabled, right, value, registerTemporarySelection, isPermissionsCheckBox, isPermissionSet, ...props }) => {
  const [field, meta] = useField(props)
  const fieldValue = field.value
  const [ isChecked, setIsChecked ] = useState(fieldValue)

  useEffect(() => {
    setIsChecked(isPermissionsCheckBox ? isPermissionSet : fieldValue)
  }, [fieldValue, isPermissionsCheckBox, isPermissionSet])

  const handleOnChange = (e) => {
    setIsChecked(e.target.checked)
    if (isPermissionsCheckBox) {
      registerTemporarySelection(e.target.value, e.target.checked)
    }
    if (!isPermissionsCheckBox) {
      field.onChange(e)
      onChange && onChange(e.target.checked)
    }
  }

  const hasError = !!(meta.touched && meta.error)

  return (
    <CheckboxArea className={className} disabled={disabled}>
      {!right && <StyledCheckbox checked={isChecked} error={hasError} {...props} />}
      <div className="checkbox-inner">
        <HiddenInput
          {...field}
          type="checkbox"
          className={className}
          disabled={disabled}
          checked={isChecked || false}
          value={value || fieldValue}
          onChange={handleOnChange}
          id={props.id || props.name}
        />
        {label && <span>{label}</span>}
        {hasError && <ErrorMessage>{meta.error}</ErrorMessage>}
      </div>
      {right && <StyledCheckbox checked={isChecked} error={hasError} {...props} />}
    </CheckboxArea>
  )
}

Checkbox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  right: PropTypes.bool,
  isPermissionsCheckBox: PropTypes.bool,
  registerTemporarySelection: PropTypes.func,
  isPermissionSet: PropTypes.bool
}

export default Checkbox

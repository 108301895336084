import { useQuery } from '@tanstack/react-query'
import { apiFetch } from '@/hooks/useQuery/helpers'

export type UnitChartBookingsInput = { siteId: string; fromDate: string; toDate: string }

export interface UnitChartBooking {
    unitId: string;
    bookings: {
      bookingId: number;
      state: string;
      startDate: string;
      endDate: string;
    }[];
}

export const queryKeyUnitChartBookings = (input: UnitChartBookingsInput) => ['unit-chart-bookings', input]

async function queryFnUnitChartBookings ({ siteId, fromDate, toDate }: UnitChartBookingsInput) {
  const params = new URLSearchParams({ fromDate, toDate })
  const url = `/v1/site/${siteId}/bookings/chart?${params.toString()}`
  return apiFetch<UnitChartBooking[]>(url)
}

export function useQueryUnitChartBookings (input: UnitChartBookingsInput) {
  const queryKey = queryKeyUnitChartBookings(input)
  const queryFn = () => queryFnUnitChartBookings(input)
  const hasInput = (input.siteId != null && input.fromDate != null && input.toDate != null)

  return useQuery({
    queryKey,
    queryFn,
    enabled: hasInput,
    staleTime: 4000,
    cacheTime: 0
  })
}

import React from 'react'

const GuestInfoSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.5 1.53191C5.62006 1.53191 4.125 3.04933 4.125 4.88511C4.125 6.72089 5.62006 8.2383 7.5 8.2383C9.37994 8.2383 10.875 6.72089 10.875 4.88511C10.875 3.04933 9.37994 1.53191 7.5 1.53191ZM2.625 4.88511C2.625 2.171 4.82359 0 7.5 0C10.1764 0 12.375 2.171 12.375 4.88511C12.375 7.59921 10.1764 9.77021 7.5 9.77021C4.82359 9.77021 2.625 7.59921 2.625 4.88511Z" fill="#506F8B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.20269 11.9286C3.61044 10.5228 5.51625 9.73604 7.5 9.73604H7.51354L7.51354 9.73616C7.79316 9.74132 8.07226 9.76287 8.34939 9.80071C8.75995 9.85677 9.04828 10.2421 8.99338 10.6614C8.93849 11.0807 8.56117 11.3752 8.15061 11.3191C7.93267 11.2894 7.71317 11.2723 7.49322 11.268C5.89901 11.2697 4.37377 11.9031 3.25137 13.0239C2.12795 14.1457 1.5 15.6635 1.5 17.2424C1.5 17.6654 1.16421 18.0084 0.75 18.0084C0.335786 18.0084 0 17.6654 0 17.2424C0 15.246 0.794368 13.3349 2.20269 11.9286Z" fill="#506F8B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.75 12.017C12.3198 12.017 12 12.3608 12 12.7489V21.7362C12 22.1243 12.3198 22.4681 12.75 22.4681H21.75C22.1802 22.4681 22.5 22.1243 22.5 21.7362V12.7489C22.5 12.3608 22.1802 12.017 21.75 12.017H12.75ZM10.5 12.7489C10.5 11.4825 11.5233 10.4851 12.75 10.4851H21.75C22.9767 10.4851 24 11.4825 24 12.7489V21.7362C24 23.0026 22.9767 24 21.75 24H12.75C11.5233 24 10.5 23.0026 10.5 21.7362V12.7489Z" fill="#506F8B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.5 14.247C13.5 13.824 13.8358 13.4811 14.25 13.4811H20.25C20.6642 13.4811 21 13.824 21 14.247C21 14.67 20.6642 15.013 20.25 15.013H14.25C13.8358 15.013 13.5 14.67 13.5 14.247Z" fill="#506F8B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.5 17.243C13.5 16.82 13.8358 16.4771 14.25 16.4771H20.25C20.6642 16.4771 21 16.82 21 17.243C21 17.666 20.6642 18.009 20.25 18.009H14.25C13.8358 18.009 13.5 17.666 13.5 17.243Z" fill="#506F8B"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.5 20.238C13.5 19.815 13.8358 19.4721 14.25 19.4721H16.5C16.9142 19.4721 17.25 19.815 17.25 20.238C17.25 20.6611 16.9142 21.004 16.5 21.004H14.25C13.8358 21.004 13.5 20.6611 13.5 20.238Z" fill="#506F8B"/>
  </svg>

)

export default GuestInfoSvg

import styled from 'styled-components'
import { Input } from '@awaze/design-system.components.input'
import { Button } from '@awaze/design-system.components.button'
import { Link } from 'react-router-dom'

export const AdminBarContainer = styled.div`
  background-color: ${({theme}) => theme.colors.black};
  padding: 0px 10px;
  color: ${({theme}) => theme.colors.white};
  margin-bottom: 0.2rem;
`

export const SearchSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

export const SearchIcon = styled.div`
  margin-right: 10px;
`

export const SearchInput = styled(Input)`
  padding: 4px 10px;
  width: 100px; /* Fixed width for consistent look */
  border: none;
  border-radius: 1px;
  font-size: 16px;
  margin-right: 10px;
  margin-top: 1rem;
`

export const SearchButton = styled(Button)`
  padding: 4px 20px;
  background-color: #0093ef;
  border: none;
  border-radius: 16px;
  color: #fff;
  font-weight: bold;
  margin-right: 10px;

  &:hover, &:active, &:focus {
    background-color: #0093ef; 
    outline: none;        
  }
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    padding: 2px 8px;
  }
  
`

export const ButtonLink = styled(Link)`
  padding: 4px 20px;
  background-color: ${({theme}) => theme.colors.grey};
  border: none;
  border-radius: 16px;
  color: #fff;
  font-weight: bold;
  text-decoration: none;

${({ theme }) => theme.mediaQueries.phoneOnly} {
padding: 2px 8px; 
}
`

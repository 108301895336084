import React from 'react'
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'

function downloadFile (file, filename) {
  if (!isDeviceThatRequiresNewWindowDownload()) {
    saveAs(file, filename)
  } else {
    downloadPdfInNewBrowserWindow(file, filename)
  }
}

function isDeviceThatRequiresNewWindowDownload () {
  return navigator.userAgent.match('CriOS')
}

function downloadPdfInNewBrowserWindow (file, filename) {
  const fileURL = URL.createObjectURL(file)
  const a = document.createElement('a')
  a.setAttribute('href', fileURL)
  a.setAttribute('download', filename || '')
  a.setAttribute('target', '_blank')
  document.body.appendChild(a)
  a.onclick = function () { setTimeout(function () { document.body.removeChild(a) }, 100) }
  a.click()
}

function createPdfDocumentForDownload (Component) {
  return pdf(<Component />).toBlob()
}

const createPDFFile = async (filename, Component) => {
  try {
    const content = await createPdfDocumentForDownload(Component)
    return { filename, content, download: () => downloadFile(content, filename) }
  } catch (e) {
    return { error: e.message }
  }
}

export { createPDFFile }

import { PricingV2Response, useQueryPricingDataV2 } from '@/hooks/useQuery/queries/useQueryPricingDataV2';
import { useQueryPricingYears, PricingYearsResponse } from '@/hooks/useQuery/queries/useQueryPricingYears';
import {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';

type WeeksToShow = 'all' | 'upcomming';

type PricingDataContextType = {
  propertyID: string | null;
  setPropertyID: (propertyID: string) => void;
  year: number;
  setYear: (year: number) => void;
  duration: number;
  setDuration: (duration: number) => void;
  durationOptions: number[]
  weeksToShow: WeeksToShow;
  setWeeksToShow: (weeksToShow: WeeksToShow) => void;
  pricingData?: PricingV2Response;
  pricingYears?: PricingYearsResponse;
  availableYears: number[];
};

export const PricingDataContext = createContext<PricingDataContextType>(
  null as any
);

interface PricingDataProviderProps {
  children: ReactNode;
}

export function PricingDataProvider({ children }: Readonly<PricingDataProviderProps>) {
  const [propertyID, setPropertyID] = useState<string | null>(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const [ duration, setDuration ] = useState(7)
  const [weeksToShow, setWeeksToShow] = useState<WeeksToShow>('upcomming');

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (year === currentYear) {
      setWeeksToShow('upcomming');
    } else {
      setWeeksToShow('all');
    }
  }, [year, currentYear]);

  const { data: pricingData } = useQueryPricingDataV2({
    propertyID,
    year,
    duration
  });

  const { data: pricingYears } = useQueryPricingYears({
    propertyID,
  });

  const availableYears = useMemo(() => pricingYears?.yearDurations.map((y) => y.year) || [], [pricingYears]);

  // Filter durations based on the selected year
  const durationOptions = useMemo(() => {
    const selectedYearData = pricingYears?.yearDurations?.find(
      (yearData) => yearData.year === year
    );
  
    const options = selectedYearData ? selectedYearData.durations : [7];
  
    if (options && !options.includes(duration)) {
      setDuration(7);
    }
    
    return options;
  }, [pricingYears, year, duration, setDuration]);
  


  // Filter pricing data by week 
  const filterPricingByWeek = (
    pricingData: PricingV2Response,
    weeksToShow: WeeksToShow
  ) => {
    const currentDate = new Date();

    if (weeksToShow === 'upcomming') {
      const filteredRows = pricingData.rows.filter((row) => {
        const weekEndDate = new Date(row.week.endDate);

        return weekEndDate >= currentDate;
      });

      return { ...pricingData, rows: filteredRows };
    }

    return pricingData;
  };

  const filteredPricingData = useMemo(() => {
    if (pricingData) {
      return filterPricingByWeek(pricingData, weeksToShow);
    }
  }, [weeksToShow, pricingData]);

  const value = useMemo<PricingDataContextType>(
    () => ({
      propertyID,
      setPropertyID,
      year,
      setYear,
      duration,
      setDuration,
      durationOptions,
      weeksToShow,
      setWeeksToShow,
      pricingData: filteredPricingData,
      pricingYears: pricingYears,
      availableYears
    }),
    [
      propertyID,
      setPropertyID,
      year,
      setYear,
      duration,
      setDuration,
      weeksToShow,
      setWeeksToShow,
      filteredPricingData,
      pricingYears,
      durationOptions,
      availableYears   
    ]
  );

  return (
    <PricingDataContext.Provider value={value}>
      {children}
    </PricingDataContext.Provider>
  );
}

export function usePricingDataContext() {
  const context = useContext(PricingDataContext);

  if (!context) {
    throw new Error(
      'usePricingDataContext must be used within a PricingDataProvider'
    );
  }

  return context;
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBody, ModalContent } from '@awaze/design-system.components.modal'
import { Button } from '@awaze/design-system.components.button'
import { capitalise } from '@/helpers/stringUtils'
import { ButtonSection } from '../AcceptanceModal.styles'

interface SuccessStepProps {
  gotoNextStep: () => void;
}

export function SuccessStep ({ gotoNextStep }: SuccessStepProps) {
  const { t } = useTranslation()
  return (
    <ModalContent header={t('priceRecommendationsConfirmationHeader')}>
      <ModalBody>
        <p className='confirmationMessage'>{t('priceRecommendationsConfirmationMessage')}</p>
        <ButtonSection>
          <Button
            data-testid="acceptanceModalCloseButton"
            text={capitalise(t('ok'))}
            colourScheme='brand-primary'
            onClick={gotoNextStep}
          />
        </ButtonSection>
      </ModalBody>
    </ModalContent>)
}

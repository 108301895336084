import styled from 'styled-components'
import DesignTokens from '@/global/DesignTokens'

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
  ${({ theme }) => theme.mediaQueries.phoneOnly} {
    display: block;
    max-width: fit-content;
    margin: 0;
    white-space: nowrap;
  }
`
export const SpinnerContainer = styled.div`
   position: absolute;
   top: 47% !important;
   left: 48.5%;
   z-index: 2;
`
export const ForwardArrowContainer = styled.div`
   position: sticky;
`


import React from 'react'
import { SiteCell } from './SiteGridRowData.styles'
import { getGridRowStyle } from '../../shared/ChartFunctions'

const SiteGridRowData = ({numberOfCells, startDate}) => {
  const cellCollection = []
  for (let i = 0; i < numberOfCells; i++) {
    cellCollection.push(i)
  }

  return cellCollection.map((index) => {
    return (<SiteCell key={index} style={getGridRowStyle(index, numberOfCells, startDate, false)}/>)
  })
}

export default SiteGridRowData

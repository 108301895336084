import React from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import Table from '@/components/Table'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { StyledHead } from './UsersAdminListSortingHeader.styles'

dayjs.extend(customParseFormat)

const UsersAdminListSortingHeader = ({ usersList, setUsersList, showLastLoginCell = true }) => {
  const sortByText = (order, key, items) => {
    const newOrder = order
    const newKey = key

    return items.sort((a, b) => {
      const aText = a[newKey] && a[newKey].toLowerCase()
      const bText = b[newKey] && b[newKey].toLowerCase()

      if (newOrder === 'DESC') {
        if (aText > bText) return -1
        if (aText < bText) return 1
      }
      if (newOrder === 'ASC') {
        if (aText > bText) return 1
        if (aText < bText) return -1
      }

      return 0
    })
  }

  const sortByDate = (order, key, items, inputDate = 'YYYY-MM-DD') => {
    const newOrder = order
    const newKey = key

    return items.sort((a, b) => {
      const aDate = dayjs(a[newKey], inputDate)
      const bDate = dayjs(b[newKey], inputDate)
      if (newOrder === 'DESC') {
        if (aDate.isBefore(bDate, 'date')) return -1
        else if (aDate.isAfter(bDate, 'date')) return 1
        else return 0
      } else {
        if (aDate.isAfter(bDate, 'date')) return -1
        else if (aDate.isBefore(bDate, 'date')) return 1
        else return 0
      }
    })
  }

  return (
    <StyledHead>
      <Table.HeadCell
        name="nameButton"
        sorting={{
          key: 'name',
          order: 'OFF',
          func: (order) => sortByText(order, 'name', [...usersList]),
          updateBookings: setUsersList
        }}
      >
        Name
      </Table.HeadCell>

      <Table.HeadCell
        name="emailButton"
        width='13rem'
        sorting={{
          key: 'email',
          order: 'OFF',
          func: (order) => sortByText(order, 'email', [...usersList]),
          updateBookings: setUsersList
        }}
      >
        Email
      </Table.HeadCell>

      <Table.HeadCell>Job title</Table.HeadCell>

      <Table.HeadCell
        name="roleButton"
        sorting={{
          key: 'role',
          order: 'OFF',
          func: (order) => sortByText(order, 'role', [...usersList]),
          updateBookings: setUsersList
        }}
      >
        Admin
      </Table.HeadCell>

      <Table.HeadCell>Sites</Table.HeadCell>

      {showLastLoginCell && <Table.HeadCell
        name="lastLoginButton"
        sorting={{
          key: 'lastLogin',
          order: 'OFF',
          func: (order) => sortByDate(order, 'lastLogin', [...usersList]),
          updateBookings: setUsersList
        }}
      >
        Last login
      </Table.HeadCell>}

      {/* For edit button */}
      <Table.HeadCell width="4rem" />
    </StyledHead>
  )
}

UsersAdminListSortingHeader.propTypes = {
  usersList: PropTypes.array,
  setUsersList: PropTypes.func,
  showLastLoginCell: PropTypes.bool
}

export default UsersAdminListSortingHeader

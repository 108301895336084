import theme from '@/global/Theme'
import styled from 'styled-components'

const FormError = styled.div`
    &::before,
    &::after {
        content: " ";
        position: absolute;
        top: 45%;
        left: 10px;
        width: 20px;
        height: 4px;
        background-color: ${({theme}) => theme.colors.white};
    }

    &::before { transform: rotate(45deg); }
    &::after { transform: rotate(-45deg); }

    position: relative;
    padding: 1em;
    padding-left: 3em;
    margin: 1em 0;
    background-color: ${theme.colors.error};
    color: ${theme.colors.white};
    border-radius: 3px;
    font-size: 0.9em;
`

export { FormError }

import styled from 'styled-components'

const PropertyLink = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 500;

    a{
      color: ${({theme}) => theme.colors.primary};
    }
    a:hover{
      color: ${({theme}) => theme.colors.primaryDarken};
    }
`
const ViewProperty = styled.span`
    padding-right: .5rem;
`

export { PropertyLink, ViewProperty }

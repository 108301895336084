import { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import qs from 'qs'
import { Link as RouterLink } from 'react-router-dom'

interface LinkInnerProps extends HTMLAttributes<HTMLAnchorElement> {
  to: string
  queryParams?: Record<string, string>
}
const LinkInner = ({ to, queryParams, ...props }: LinkInnerProps) => (
  <RouterLink
    to={{
      pathname: to,
      search: queryParams ? `?${qs.stringify(queryParams)}` : ''
    }}
    {...props}
  />
)


interface LinkProps {
  disabled?: boolean
  primary?: boolean
  small?: boolean
  to: string
  queryParams?: Record<string, string>
}
const Link = styled(LinkInner)<LinkProps>`
  ${({ disabled, primary, theme, small }) => `
    cursor: pointer;
    text-decoration: underline;
    color: ${theme.colors.blackTwo};

    &:hover {
      text-decoration: none;
    }

    &:active {
      font-weight: 600;
    }

    ${disabled && css`
      pointer-events: none;
      color: ${theme.colors.disabled};
    `}

    ${small && css`
      font-size: .75em;
      line-height: 1.2;
    `}

    ${primary && css`
      color: ${theme.colors.secondary}
    `}
  `}
`

Link.displayName = 'Link'

export default Link

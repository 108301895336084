import React from 'react';
import { ColorLegendContainer, ColorLegendContainerMobile } from './ColorLegend.styles';
import { Legend } from '@/components/__UI';
import theme from '@/global/Theme';
import { useBookingChartBookingsContext } from '../context/Bookings/BookingChartBookingsProvider';
import { useBookingChartContext } from '../context/BookingChartProvider';
import useWindowSize from '@/hooks/useWindowSize';

const ColorLegend = () => {
  const { width } = useWindowSize();
  const isDesktop = width > 599;

  const { loadingBookings } = useBookingChartBookingsContext();
  const { isLoadingChart, noResults } = useBookingChartContext(); 

  // Hide the legend if there are no results
  if (noResults) {
    return null;
  }

  return (
    <>
      {isDesktop ? (
        <ColorLegendContainer>
          {!loadingBookings && !isLoadingChart && (
            <div className='infoContainer'>
              <div className='legend'>
                <Legend
                  items={[
                    { label: 'Closed', color: theme.colors.closed },
                    { label: 'Customer', color: theme.colors.customer },
                    { label: 'Owner', color: theme.colors.owner },
                    { label: 'Provisional', color: theme.colors.provisional }
                  ]}
                  tooltipText='The colours represent different types of bookings.'
                />
              </div>
            </div>
          )}
        </ColorLegendContainer>
      ) : (
        <ColorLegendContainerMobile>
          {!loadingBookings && !isLoadingChart && (
            <div className='infoContainer'>
              <Legend
                items={[
                  { label: 'Closed', color: theme.colors.closed },
                  { label: 'Customer', color: theme.colors.customer },
                  { label: 'Owner', color: theme.colors.owner },
                  { label: 'Provisional', color: theme.colors.provisional }
                ]}
                tooltipText='The colours represent different types of bookings.'
              />
            </div>
          )}
        </ColorLegendContainerMobile>
      )}
    </>
  );
};

export default ColorLegend;

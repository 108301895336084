import React from 'react'
import PropTypes from 'prop-types'
import { Component } from './Section.styles'

const Section = ({ tag = 'section', backgroundImage, ...props }) => <Component as={tag} backgroundImage={backgroundImage} {...props} />

Section.propTypes = {
  tag: PropTypes.oneOf(['article', 'div', 'section']),
  backgroundImage: PropTypes.shape({
    url: PropTypes.shape({
      webp: PropTypes.string,
      fallback: PropTypes.string
    }).isRequired,
    position: PropTypes.string,
    size: PropTypes.string
  }),
  children: PropTypes.node,
  className: PropTypes.string
}

Section.displayName = 'Section'

export default Section

export function hasUrlParam(param): boolean {
  const hasParam = !!(new URLSearchParams(window.location.search).get(param))
  return hasParam
}

export function addIsRedirectUrlParam(url: URL): URL {
  const redirectUrl = new URL(url)
  redirectUrl.searchParams.set('isRedirect', 'true')
  return redirectUrl;
}

export function redirectTo(path: string): void {
  const url = new URL(path, window.location.origin)
  const redirectedUrl = addIsRedirectUrlParam(url)
  
  if (!hasUrlParam('isRedirect')) {
    window.location.replace(redirectedUrl)
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import Theme from '@/global/Theme'

const ArrowSvg = ({ direction = 0, fill = Theme.ColorThemeSecondary700 }) => (
  <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg" transform={`rotate(${direction})`}>
    <path d="M11.9561 0.345032C12.0461 0.555051 11.9936 0.795073 11.8361 0.960089L6.65311 6.14307C6.4806 6.31558 6.24808 6.41309 6.00055 6.41309C5.75303 6.41309 5.52051 6.31558 5.34799 6.14307L0.165015 0.960089C0.0600055 0.855079 0 0.705065 0 0.562552C0 0.487545 0.0150014 0.420039 0.0450042 0.345032C0.127512 0.135012 0.337531 0 0.562552 0H11.4386C11.6636 0 11.8736 0.135012 11.9561 0.345032Z" fill={fill}/>
  </svg>
)

ArrowSvg.propTypes = {
  direction: PropTypes.number,
  fill: PropTypes.string
}

export default ArrowSvg

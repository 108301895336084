import React from 'react'
import { BulbSvg } from './FAQs.styles'

const BulbIcon = (props) => (
  <BulbSvg {...props} viewBox="0 0 27 40">
    <title>Light bulb</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="0:-Icon-/-Lightbulb" transform="translate(-7.000000, -1.000000)">
        <path d="M20.2500115,1 C12.4995141,1 6.990977,7.446464 7,14.5997704 C7.00452814,18.0166895 8.24219864,21.1334322 10.2793862,23.5155526 C12.2104232,25.7726638 14.0654233,29.4496331 14.2272841,30.6318192 L14.2318011,36.4331738 C14.2318011,36.6762473 14.3025682,36.9131475 14.4335625,37.115323 L16.2787756,39.9581256 C16.5023694,40.3022869 16.8787898,40.5090923 17.2815597,40.5090923 L23.2177105,40.5090923 C23.6212333,40.5090923 23.9976537,40.3022869 24.2204946,39.9581256 L26.0657078,37.115323 C26.1967021,36.9131475 26.2667163,36.6754756 26.2674691,36.4331738 L26.272739,30.6318192 C26.4428811,29.4187666 28.3046567,25.7556873 30.2206369,23.5155526 C32.2615887,21.1288022 33.500012,18.0051146 33.500012,14.5812505 C33.500012,7.08069623 27.5676254,1 20.2500115,1 Z M23.859131,36.0573744 L22.5725258,38.039774 L17.9267444,38.039774 L16.6401393,36.0573744 L16.6393864,35.5704557 L23.859131,35.5704557 L23.859131,36.0573744 Z M23.8621424,33.1011375 L16.6371279,33.1011375 L16.631858,30.6318192 L23.8651537,30.6318192 L23.8621424,33.1011375 Z M28.4093016,21.8873459 C27.3560771,23.1189184 25.6742305,25.5990399 24.6014322,28.1617293 L15.8993438,28.1617293 C14.8257926,25.5982682 13.1446988,23.1189184 12.0914743,21.8873459 C10.3501533,19.8517016 9.41888902,17.2704924 9.41283616,14.5812505 C9.39931515,8.64022505 13.9585199,3.46931827 20.2500115,3.46931827 C26.2275686,3.46931827 31.090921,8.45425452 31.090921,14.5812505 C31.090921,17.2704924 30.1385772,19.8655916 28.4093016,21.8873459 Z M20.2500115,5.93863653 C15.6012188,5.93863653 11.8181931,9.81623787 11.8181931,14.5812505 C11.8181931,15.2633996 12.3572272,15.8159096 13.0227386,15.8159096 C13.68825,15.8159096 14.2272841,15.2633996 14.2272841,14.5812505 C14.2272841,11.1774496 16.9284773,8.4079548 20.2500115,8.4079548 C20.9155229,8.4079548 21.454557,7.85544484 21.454557,7.17329567 C21.454557,6.49114649 20.9155229,5.93863653 20.2500115,5.93863653 Z" id="Shape"></path>
      </g>
    </g>
  </BulbSvg>
)

export default BulbIcon

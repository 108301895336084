import { parseJwt } from '@/context/UserProvider'
import { refreshAccessToken } from '@/helpers/authorisations'
import { createCookie, getCookies } from '@/helpers/cookies'

const getAccessTokenExpiration = () => {
  const token = getCookies('accessToken')
  return token ? new Date(parseJwt(token).exp * 1000) : undefined
}

const checkRefreshToken = async (onExpired) => {
  const accessTokenExpiration = getAccessTokenExpiration()
  const now = new Date(Date.now())

  if (accessTokenExpiration < now || accessTokenExpiration === undefined) {
    const accessToken = getCookies('accessToken')
    const refreshToken = getCookies('refreshToken')

    try {
      const response = await refreshAccessToken(refreshToken, accessToken)

      if (response.status === 200 || response.status === 201) {
        const tokens = await response.json()
        const oneYear = new Date()
        oneYear.setFullYear(oneYear.getFullYear() + 1)

        // set id and access tokens again
        createCookie('idToken', tokens.idToken, oneYear, '/')
        createCookie('accessToken', tokens.accessToken, oneYear, '/')
      } else {
        onExpired()
      }
    } catch (err) {
      console.log(`[refresh token] ${err.message}`)
    }
  }
}

export { checkRefreshToken, getAccessTokenExpiration }

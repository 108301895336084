import styled from 'styled-components'

const Container = styled.div`
  min-width: 150px;
  margin-top: 0.5rem;

  ${({theme}) => theme.mediaQueries.tabletPortraitUp} {
    margin-top: 0;
  }

  a {
    cursor: pointer;
  }
`

export { Container }

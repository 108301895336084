import styled from 'styled-components'

const SiteCell = styled.td`
width: auto;
height: 3rem;
/* MOBILE */
${({ theme }) => theme.mediaQueries.phoneOnly} {
  width: auto;
  height: 3rem;
}
/* iPAD */
@media (min-width: 600px) and (max-width: 1199px) {
  width: auto;
  height: 3rem;
}
`
export { SiteCell }

import { getCookies } from '@/helpers/cookies'
import { triggerLogout } from '@/context/UserProvider'
import { checkRefreshToken } from '@/helpers/checkRefreshToken'
import { getEnvironment } from '@/helpers/environment'

export class ApiFetchError extends Error {
  constructor (
    public message,
    public response: Response
  ) {
    super(message)
    this.name = 'ApiFetchError'
    this.response = response
  }
}

export const getFacilitatorUrl = () => {
  switch (getEnvironment()) {
    case 'localhost':
      return 'https://common.dev.owners.awaze.com'
    default:
      return window.location.origin
  }
}

export interface Options {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  body?: any;
}

function getIdToken () {
  return getCookies('idToken')
}

export function isApiEnabled () {
  const token = getIdToken()
  return !!token
}

export const apiFetch = async <T = any>(path, options?: Options): Promise<T> => {
  const baseUrl = getFacilitatorUrl()
  const url = new URL(path, baseUrl)

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }

  if (getEnvironment() === 'localhost') {
    await checkRefreshToken(() => triggerLogout())
    headers.Authorization = `Bearer ${getCookies('accessToken')}`
  }

  const body = options?.body == null ? options?.body : JSON.stringify(options.body)

  const res = await fetch(url, {
    redirect: 'follow',
    headers,
    ...options,
    body
  })

  if (!res.ok) {
    if (res.status === 401) {
      triggerLogout()
      window.location.replace('/logout')
    }

    throw new ApiFetchError(`Failed to fetch - Status code ${res.status}`, res)
  }

  try {
    const json = await res.json()
    return json
  } catch {
    throw new ApiFetchError('Failed to parse response as JSON', res)
  }
}

import styled from 'styled-components'

const CategorySection = styled.div`
  padding: 0 0.5rem;
  margin-bottom: 0.75rem;
  flex: 0.2;

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    width: 100%;
  }
  
  ${({theme}) => theme.mediaQueries.desktopUp} {
    width: 25%;
  }
`

const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.error};
  margin-bottom: 1rem;
`

const CategorySectionContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;

  .green_category h3{
    color: ${({ theme }) => theme.colors.featureTitle};
  }

  ${({theme}) => theme.mediaQueries.phoneOnly} {
    flex-direction: column;
  }
`

const CategoryTitle = styled.h3`
  ${({ theme }) => theme.fonts.Heading100}
`

export { CategorySection, ErrorText, CategoryTitle, CategorySectionContainer }

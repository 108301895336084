import PropTypes from 'prop-types'
import { useTable } from '../Table'
import React, { useEffect, useState } from 'react'
import { StyledTableHeadCell, StyledSortButton } from '../Table.styles'

const TableHeadCell = ({ children, className = '', name, sorting, sortedBy, ...props }) => {
  useEffect(() => {
    if (sortedBy && sortedBy.name && sortedBy.order) {
      handleSort(sortedBy.name, sortedBy.order)
    }
    // eslint-disable-next-line
  }, [sortedBy])

  const { sortedID, setSortedID, unsortedData } = useTable()
  const [ sortOrder, setSortOrder ] = useState(sorting ? sorting.order : null)

  const sortID = sorting ? sorting.key : ''

  if (sorting && unsortedData === undefined) {
    throw new Error('[Table] Please provide unsortedData ref object to <Table> as prop')
  }

  useEffect(() => {
    if (sortedID) {
      if (sortedID !== sortID) {
        setSortOrder('OFF')
      }
    } else {
      if (sorting && sorting.offState) {
        setSortOrder(sorting.offState)
      }
    }
    // eslint-disable-next-line
  }, [sortID, sorting])

  const handleSort = (name, order) => {
    const { func, updateBookings } = sorting
    const selectedTab = name || sortID
    const currentSortOrder = order || sortOrder
    if (sortedID && sortedID !== selectedTab) {
      setSortOrder('ASC')
      setSortedID(selectedTab)
      updateBookings(func('ASC'))
    } else {
      setSortOrder((currentSortOrder === 'OFF' || currentSortOrder === 'DESC') ? 'ASC' : 'DESC')
      setSortedID(selectedTab)
      updateBookings(func((currentSortOrder === 'OFF' || currentSortOrder === 'DESC') ? 'ASC' : 'DESC'))
    }
  }

  return (
    <StyledTableHeadCell className={`table-head-cell ${className}`} {...props}>
      {sorting
        ? (
          <StyledSortButton className={sortOrder.toLowerCase()} name={name} onClick={handleSort}>
            {children}
          </StyledSortButton>
        )
        : children
      }
    </StyledTableHeadCell>
  )
}

TableHeadCell.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  name: PropTypes.string,
  sorting: PropTypes.exact({
    key: PropTypes.string,
    order: PropTypes.oneOf(['OFF', 'ASC', 'DESC']),
    func: PropTypes.func,
    offState: PropTypes.oneOf(['OFF', 'ASC', 'DESC']),
    updateBookings: PropTypes.func
  }),
  width: PropTypes.string,
  sortedBy: PropTypes.string
}

export default TableHeadCell

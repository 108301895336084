import styled from 'styled-components'

const CheckboxContainer = styled.div`
  & > * {
    width: 50%;

    ${({theme}) => theme.mediaQueries.phoneOnly} {
      width: 100%;
    }

    ${({theme}) => theme.mediaQueries.tabletLandscapeUp} {
      width: 33.33%;
    }
    
    ${({theme}) => theme.mediaQueries.desktopUp} {
      width: 25%;
    }
  }
`

export { CheckboxContainer }
